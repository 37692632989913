import * as React from "react";
import { useSelector } from "react-redux";
import {
  createBrowserRouter,
  RouterProvider,
  Navigate,
} from "react-router-dom";

import { AuthLayout } from "../auth/AuthLayout";
import { ProtectedRoute } from "../auth/ProtectedRoute";

import SidebarLayout from "../layout/SidebarLayout";
import NotFound from "../main/NotFound/NotFound";
import Login from "../main/Login/Login";
import Portal from "../main/R&D/Portal/Portal";
import Profile from "../main/Profile/Profile";
import Team from "../main/Team/Team";
import Contact from "../main/Contact/Contact";
import SurveyParent from "../main/R&D/Survey/SurveyParent";
import { AdminHome } from "../main/AdminHome/AdminHome";
import { DataSpace } from "../main/DataSpace/DataSpace";
import { StateCreditsContainer } from "../main/R&D/StateCredits/StateCreditsContainer";
import { useUsers } from "../rtk/Users/useUsers";
import { OperationsPortalContainer } from "../main/Energy";
import { EnergyModelerPortalContainer } from "../main/Energy/EnergyModelerPortal/EnergyModelerPortalContainer";
import { CertificationPortalContainer } from "../main/Energy/CertificationPortal/CertificationPortalContainer";
import { DeliverablesPortalContainer } from "../main/R&D/DeliverablesPortal/DeliverablesPortalContainer";
import { ReportsContainer } from "../main/Energy/OperationsPortal/Reports/ReportsContainer";
import DataSpacesProvider from "../rtk/DataSpaces/DataSpacesProvider";
import { EnergyProvider } from "./EnergyProvider";
import { TrackerContainer } from "../main/R&D/Tracker/TrackerContainer";
import { EngagementsAndCompaniesListContainer } from "../main/R&D/Engagements/EngagementsAndCompaniesListContainer";
import { EnergyEngagementsAndCompaniesListContainer } from "../main/Energy/Engagements/EnergyEngagementsAndCompaniesListContainer";
import { DataSpacesAndCompaniesListContainer } from "../main/DataSpace/DataSpaces/DataSpacesAndCompaniesListContainer";
import ConnectHome from "../main/R&D/Connect/ConnectHome";

const isClient = (u) => u?.role === "client";

const isEnergyModeler = (u) =>
  u?.engagementType === "EN" && u?.role === "modeler";

const isEnergyCertifier = (u) =>
  u?.engagementType === "EN" && u?.role === "certifier";

const isSalesUser = (u) => u?.role === "sales";

const isAdmin = (u) => u?.role === "admin";

export default function SPRXRouter() {
  const parentUser = useSelector(({ auth }) => auth.user);
  const providerUser = useUsers();
  const user = {
    ...parentUser,
    ...providerUser,
  };

  const rerouteTo = (role) => {
    switch (role) {
      case "admin":
      case "sales":
        return "/tracker";
      case "modeler":
      case "certifier":
        return "/assignments";
      case "client":
        return "/dataspaces";
      default:
        return "/profile";
    }
  };

  const router = createBrowserRouter([
    {
      element: <AuthLayout />,
      errorElement: <NotFound />,
      children: [
        {
          element: <ProtectedRoute />,
          children: [
            {
              path: "/:companyId?",
              element: <SidebarLayout user={user} />,
              children: [
                {
                  path: "dataspaces",
                  element:
                    isAdmin(user) || isClient(user) ? (
                      <DataSpacesProvider>
                        <DataSpacesAndCompaniesListContainer />
                      </DataSpacesProvider>
                    ) : (
                      <Navigate replace to={rerouteTo(user.role)} />
                    ),
                },
                {
                  path: "assignments",
                  element: isEnergyModeler(user) ? (
                    <EnergyProvider>
                      <EnergyModelerPortalContainer />
                    </EnergyProvider>
                  ) : isEnergyCertifier(user) ? (
                    <EnergyProvider>
                      <CertificationPortalContainer />
                    </EnergyProvider>
                  ) : (
                    <Navigate replace to={rerouteTo(user.role)} />
                  ),
                },
                {
                  path: "rd",
                  element:
                    isAdmin(user) || isClient(user) ? (
                      <EngagementsAndCompaniesListContainer />
                    ) : (
                      <Navigate replace to={rerouteTo(user.role)}></Navigate>
                    ),
                },
                {
                  path: "energy",
                  element:
                    isAdmin(user) || isClient(user) ? (
                      <EnergyEngagementsAndCompaniesListContainer />
                    ) : (
                      <Navigate replace to={rerouteTo(user.role)} />
                    ),
                },
                {
                  path: "profile",
                  element: <Profile />,
                },
                {
                  path: "team",
                  element: (
                    <EnergyProvider>
                      <DataSpacesProvider>
                        <Team />
                      </DataSpacesProvider>
                    </EnergyProvider>
                  ),
                },
                {
                  path: "contact",
                  element: <Contact />,
                },
                {
                  path: "admin-home",
                  element: isAdmin(user) ? (
                    <EnergyProvider>
                      <DataSpacesProvider>
                        <AdminHome />
                      </DataSpacesProvider>
                    </EnergyProvider>
                  ) : (
                    <Navigate replace to={rerouteTo(user.role)} />
                  ),
                },
                {
                  path: "tracker",
                  element:
                    isAdmin(user) || isSalesUser(user) ? (
                      <TrackerContainer />
                    ) : (
                      <Navigate replace to={rerouteTo(user.role)} />
                    ),
                },
              ],
            },
          ],
        },
        {
          path: "/login",
          element: <Login />,
        },
      ],
    },
    {
      path: "/survey/:surveyId",
      element: <SurveyParent />,
    },
    {
      path: "/connect/:cred",
      element: <ConnectHome />,
    }
  ]);

  return <RouterProvider router={router} />;
}
