import React from "react";
import classNames from "classnames";

const Checkboxes = (props) => {
  return (
    <div className="flex justify-center">
      <fieldset
        className={classNames(
          "w-10/12 space-y-5 bg-white elevation-1 rounded-md border p-4 border-gray-300",
          props.className && props.className
        )}
      >
        <h3 className="text-center text-lg leading-6 font-medium text-gray-900">
          Please read each prompt below carefully.
        </h3>
        {/* <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="comments"
              name="comments"
              type="checkbox"
              className="focus:ring-transparent h-4 w-4 text-blue-400 border-gray-300 rounded"
              value={props.valueOne}
              onChange={(e) => props.onChangeOne(!props.valueOne)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              I would like the SPRX.tax Cash Advance
            </label>
            <p className="text-gray-500">
              SPRX.tax will advance you a portion of the benefit next month
              (interest-free) so you can start using the value from your credit
              right away! We will bundle the SPRX.tax cash advance repayment
              with your monthly fee payment. The SPRX.tax Cash Advance is only
              available on a monthly payment plan.
            </p>
          </div>
        </div> */}
        <div className="relative flex items-start">
          <div className="flex items-center h-5">
            <input
              id="comments"
              name="comments"
              type="checkbox"
              className="focus:ring-transparent h-4 w-4 text-blue-400 border-gray-300 rounded"
              value={props.valueTwo}
              onChange={(e) => props.onChangeTwo(!props.valueTwo)}
            />
          </div>
          <div className="ml-3 text-sm">
            <label htmlFor="comments" className="font-medium text-gray-700">
              I agree to have SPRX.tax collect the fees quoted above in
              accordance with the{" "}
              <a
                className={"text-blue-500"}
                href={`https://www.sprx.tax/terms-of-service.pdf`}
                target="_blank"
                rel="noopener noreferrer"
              >
                SPRX.tax services agreement.
              </a>
            </label>
            <p className="text-gray-500">
              This is the fee that SPRX.tax collects for helping to prepare your
              credits. It is based on the delivery type you selected when
              setting up this engagement. If our estimate is off, we'll adjust
              the fees after we finalize your credit and prepare your
              deliverables.
            </p>
          </div>
        </div>
      </fieldset>
    </div>
  );
};

export default Checkboxes;
