import { ContactForm } from "./ContactForm";

function Contact() {
  /**
   * TODO: Migrate Logic To Parent
   */
  return (
    <>
      <ContactForm />
    </>
  );
}

export default Contact;
