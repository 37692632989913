import React from "react";
import classNames from "classnames";

const PaymentTypeToggle = (props) => {
  return (
    <div className="sm:flex sm:flex-col sm:align-center mb-16">
      <h1 className="text-xl font-semibold text-gray-900 sm:text-center">
        Select payment method
      </h1>
      <p className="text-sm font-light text-gray-900 sm:text-center">
        As a note, you will not be charged until the credit is finalized. The
        numbers above reflect an estimate that may or may not change based on
        subsequent activities.
      </p>
      <div className="elevation-1 relative self-center mt-6 bg-sprxTaxDarkGray rounded-lg p-0.5 flex sm:mt-8">
        <button
          type="button"
          className={classNames(
            "relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8",
            props.activeMethod
              ? "bg-white border-gray-200 shadow-sm text-gray-900"
              : "border border-transparent text-white"
          )}
          onClick={() => {
            props.changeMethod(!props.activeMethod);
          }}
        >
          Bank ACH
        </button>
        <button
          type="button"
          className={classNames(
            "ml-0.5 relative w-1/2 rounded-md py-2 text-sm font-medium whitespace-nowrap focus:outline-none focus:z-10 sm:w-auto sm:px-8",
            props.activeMethod
              ? "border border-transparent text-white"
              : "bg-white border-gray-200 shadow-sm text-gray-900"
          )}
          onClick={() => {
            props.changeMethod(!props.activeMethod);
          }}
        >
          Credit Card
        </button>
      </div>
    </div>
  );
};

export default PaymentTypeToggle;
