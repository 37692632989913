import React from "react";
import classNames from "classnames";

const capitalize = (s) => (s && s[0].toUpperCase() + s.slice(1)) || "";

const AdminStatusBadges = (props) => {
  const rowData = props.rowData;

  return (
    <ul className="grid grid-cols-1 gap-5 sm:gap-1 sm:grid-cols-2 lg:grid-cols-1">
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.eligibility?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.eligibility?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.eligibility?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Eligibility:{" "}
        {capitalize(rowData?.engagementStatus?.eligibility?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.upload?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.upload?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.upload?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Upload: {capitalize(rowData?.engagementStatus?.upload?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.states?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.states?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.states?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        States: {capitalize(rowData?.engagementStatus?.states?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.calculate?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.calculate?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.calculate?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Calculate:{capitalize(rowData?.engagementStatus?.calculate?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.payment?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.payment?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.payment?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Payment: {capitalize(rowData?.engagementStatus?.payment?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.review?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.review?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.review?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Review: {capitalize(rowData?.engagementStatus?.review?.status)}
      </span>
      <span
        className={classNames(
          "inline-flex items-center justify-center px-2.5 py-0.5 rounded-md text-sm font-medium",
          rowData?.engagementStatus?.deliverables?.status === "notStarted" &&
            "bg-blue-100 text-blue-800",
          rowData?.engagementStatus?.deliverables?.status === "pending" &&
            "bg-yellow-100 text-yellow-800",
          rowData?.engagementStatus?.deliverables?.status === "complete" &&
            "bg-green-100 text-green-800"
        )}
      >
        Deliverables:{" "}
        {capitalize(rowData?.engagementStatus?.deliverables?.status)}
      </span>
    </ul>
  );
};

export default AdminStatusBadges;
