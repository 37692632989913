import { validateCompletedEligibility } from "./validateCompletedEligibility";

const formatNumber = (currencyString) => {
  const cleanString = currencyString.replace(/[$,]/g, "");
  return parseFloat(cleanString);
};
// Transoforms Form Submission into dB Object
export const formatFormModelForSubmission = (
  model,
  engagementData,
  dirtyFields,
) => {
  const method = model?.method
    ? model.method
    : engagementData?.creditEligibilityData?.completedAnalysis?.method;

  const creditEligibilityIsCompleted = validateCompletedEligibility(
    engagementData,
    dirtyFields,
    model,
  );

  let status = {};
  Object.assign(status, engagementData.status);

  if (creditEligibilityIsCompleted) {
    // Update Status Attributes

    status = {
      ...status,
      "portal-profile": {
        complete: true,
      },
    };

    status.eligibility = {
      ...status.eligibility,
      status: "complete",
    };
    if (status?.upload?.status === "complete") {
      status.states = {
        ...status.states,
        locked: false,
      };
      status.calculate = {
        ...status.calculate,
        locked: false,
      };
    }
  }
  const body = {
    status,
    creditEligibilityData: {
      ...engagementData.creditEligibilityData,
      completed: creditEligibilityIsCompleted,
      completedAnalysis: {
        ...engagementData.creditEligibilityData.completedAnalysis,
        method,
        ...(dirtyFields.hasOwnProperty("FBPercentage") && {
          FBPercentage: model.FBPercentage,
        }),
        ...(dirtyFields.hasOwnProperty("acquisition") && {
          acquisition: model.acquisition ? "yes" : "no",
        }),
        ...(dirtyFields.hasOwnProperty("disposition") && {
          disposition: model.disposition ? "yes" : "no",
        }),
        ...(dirtyFields.hasOwnProperty("shortYear") && {
          shortYear: model.shortYear ? "yes" : "no",
        }),
        qsb: model?.QSB === true ? "yes" : "no",
        "280CElection": model["280CElection"] ? "yes" : "no",
        ...((dirtyFields.hasOwnProperty("BRP") ||
          dirtyFields.hasOwnProperty("brPMT") ||
          dirtyFields.hasOwnProperty("brBase")) && {
          basicResearch: model.BRP ? "yes" : "no",
          brPayment: {
            brPMT: formatNumber(model.brPMT),
            brBase: formatNumber(model.brBase),
          },
        }),
        ...((dirtyFields.hasOwnProperty("ECP") ||
          dirtyFields.hasOwnProperty("ecPMT")) && {
          energyConsortia: model.ECP ? "yes" : "no",
          ecPayment: {
            ecPMT: formatNumber(model.ecPMT),
          },
        }),
        ...((dirtyFields.hasOwnProperty("ascPY1") ||
          dirtyFields.hasOwnProperty("ascPY2") ||
          dirtyFields.hasOwnProperty("ascPY3")) && {
          PriorThreeYearQRE: {
            py1: formatNumber(model.ascPY1),
            py2: formatNumber(model.ascPY2),
            py3: formatNumber(model.ascPY3),
          },
        }),
        ...((dirtyFields.hasOwnProperty("py1GR") ||
          dirtyFields.hasOwnProperty("py2GR") ||
          dirtyFields.hasOwnProperty("py3GR") ||
          dirtyFields.hasOwnProperty("py4GR")) && {
          PYFourYearGR: {
            py1: formatNumber(model.py1GR),
            py2: formatNumber(model.py2GR),
            py3: formatNumber(model.py3GR),
            py4: formatNumber(model.py4GR),
          },
        }),
      },
    },
    calculation: {
      ...engagementData.calculation,
      method,
      ...(dirtyFields.hasOwnProperty("controlledGroup") && {
        controlledGroup: model?.controlledGroup === true ? "yes" : "false",
      }),
      ...(dirtyFields.hasOwnProperty("controlledGroupFiling") && {
        controlledGroupFiling: model?.controlledGroupFiling,
      }),
      ...(dirtyFields.hasOwnProperty("entityName") && {
        entityName: model.entityName,
      }),
      ...(dirtyFields.hasOwnProperty("entityEIN") && {
        entityEIN: model.entityEIN,
      }),
      ...(dirtyFields.hasOwnProperty("entityType") && {
        entityType: model.entityType,
      }),
      reducedCredit: model["280CElection"],
      ...(dirtyFields.hasOwnProperty("line8932") && {
        form8932: formatNumber(model["line8932"]),
      }),
      ...(dirtyFields.hasOwnProperty("businessDescription") && {
        businessDescription: model["businessDescription"],
      }),
      ...(dirtyFields.hasOwnProperty("naicsCode") && {
        naicsCode: model["naicsCode"],
      }),
      ...(dirtyFields.hasOwnProperty("creditK1") && {
        creditK1: formatNumber(model["creditK1"]),
      }),
      ...(dirtyFields.hasOwnProperty("beneficiariesCredit") && {
        creditBeneficiariesEstateTrust: formatNumber(
          model["beneficiariesCredit"],
        ),
      }),
      ...(dirtyFields.hasOwnProperty("FBPercentage") && {
        fixedBasePercentage: model.FBPercentage,
      }),
    },
  };
  return body;
};
