export const WelcomeMessage = () => (
  <div className="flex w-full flex-row justify-between py-2 lg:w-1/2">
    <div className="mr-2 flex w-full flex-col rounded-2xl bg-sprxClientPortalLightTeal p-2">
      <h1 className="text-xl font-semibold text-gray-900">Welcome!</h1>
      <p className="pr-16 text-sm text-gray-900">
        Explore your Research and Development Tax Credit data with our
        deliverables portal. You can view your deliverables, download your
        prepared documentation, view some visualizations, and chat with your
        personalized AI tax assistant.
      </p>
    </div>
  </div>
);
