import * as yup from "yup";

export const getBuildingProfileFormShape = () => {
  return yup.object().shape({
    squareFeet: yup.number().required("Square footage is required."),
    buildingType: yup.string().required("Building type is required"),
    address: yup.string().required("Street address is required"),
    buildingCost: yup.number().required("Building cost is required"),
    ownerName: yup.string().required("Owner name is required"),
    buildingName: yup.string(),
    meetsPrevailingWageRequirements: yup.boolean(),
    meetsApprenticeshipReqirements: yup.boolean(),
    systemsRelated: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Value is required"),
        label: yup.string().required("Label is required"),
      })
    ),
    state: yup.string().required("Related sytems is required"),
  });
};
