import { User } from "../../AdminHome/types/user";
export const getDefaultContactFormFields = (user: User) => {
  return {
    name: user.fullName,
    email: user.email,
    phone: "",
    subject: "",
    message: "",
  };
};
