import { SearchBar } from "./SearchBar";
import { motion } from "framer-motion";
import { EngagementStatus } from "../types";
export const TrackerContainerLoading = () => {
  const columns: any = ["Upload", "Review", "Validation", "Completed"];

  const LoadingPill = () => (
    <div className="inline-flex items-center border border-gray-300 rounded-full px-3 py-1 mr-1 text-sm w-1/3 animate-pulse bg-gray-300">
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-4 h-4 rounded-full"></div>
      </div>
    </div>
  );

  const LoadingTitle = () => (
    <div className="inline-flex items-center border border-gray-300 rounded-full px-3 py-1 mr-1 text-sm w-full animate-pulse bg-gray-300">
      <div className="flex items-center justify-center w-full h-full">
        <div className="w-4 h-2 rounded-full"></div>
      </div>
    </div>
  );
  return (
    <div className="container mx-auto p-4 h-4/5">
      <div className="inline-flex w-1/3 pl-8 ml-6 mb-4 items-center border border-gray-300 h-10 rounded-xl px-3 py-1 animate-pulse bg-gray-300"></div>

      <div className="flex justify-between h-full">
        {columns.map((status: EngagementStatus, index: number) => (
          <div
            className={`flex-1 px-2 ${
              index < 4 - 1 ? "border-r border-gray-300" : ""
            }`}
            key={`tracker-container-loading-${index}`}
          >
            <div className="flex-1 p-2 flex-row">
              <div className="flex-1 flex-col p-2">
                <h2 className="text-xl font-bold mb-4">{status}</h2>

                <motion.div
                  className="flex flex-col justify-around bg-white rounded-lg shadow-md p-4 mb-2 relative border h-40 "
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {}}
                >
                  {Array.from(Array(2)).map((item, index) => (
                    <LoadingTitle key={index} />
                  ))}
                  <div className="flex flex-row justify-between">
                    {Array.from(Array(3)).map((item, index) => (
                      <LoadingPill key={index} />
                    ))}
                  </div>
                </motion.div>
                <motion.div
                  className="flex flex-col justify-around bg-white rounded-lg shadow-md p-4 mb-2 relative border h-40 "
                  whileHover={{ scale: 1.05 }}
                  onClick={() => {}}
                >
                  {Array.from(Array(2)).map((item, index) => (
                    <LoadingTitle key={index} />
                  ))}
                  <div className="flex flex-row justify-between">
                    {Array.from(Array(3)).map((item, index) => (
                      <LoadingPill key={index} />
                    ))}
                  </div>
                </motion.div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};
