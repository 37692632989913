import { useState } from "react";

// import { EngagementContainer } from "./EngagementContainer";
import { WelcomeMessage } from "./components";
import { EngagementContainer } from "./Engagement/EngagementContainer";
import { CertifierEngagementListContainer } from "../components/CertifierEngagementListContainer";
import { CheckCircleIcon } from "@heroicons/react/outline";
interface CertificationPortalProps {
  user: any;
}

export const CertificationPortal = ({ user }: CertificationPortalProps) => {
  const [activeEngagement, setActiveEngagement] = useState(undefined);
  const [open, setOpen] = useState(false);

  return (
    <>
      <main className="flex-1">
        <div className="py-6">
          <div className="mx-4 flex flex-row items-center justify-between px-4">
            <h1
              className={`text-2xl font-semibold text-gray-900 ${
                activeEngagement
                  ? `ml-4 flex flex-row items-center justify-center`
                  : ""
              }`}
            >
              {activeEngagement ? (
                <>
                  {activeEngagement.name}
                  <CheckCircleIcon
                    className={`ml-1 h-6 w-6 align-middle ${
                      activeEngagement?.certification?.status === "COMPLETE"
                        ? "text-sprxGreen"
                        : "text-gray-400"
                    }`}
                  />
                </>
              ) : (
                "Certification Portal"
              )}
            </h1>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="mx-auto h-full px-4 sm:px-6 md:px-8">
            {!activeEngagement ? (
              <>
                <WelcomeMessage name={user.fullName.split(" ")[0]} />
                <CertifierEngagementListContainer
                  setActiveEngagement={setActiveEngagement}
                  type={user.role}
                />
              </>
            ) : (
              <EngagementContainer
                engagement={activeEngagement}
                setActiveEngagement={setActiveEngagement}
              />
            )}
          </div>
        </div>
      </main>
    </>
  );
};
