import { getStatus } from "./getStatus";
import { parseEpochToISO } from "./parseEpochToISO";
import { mapTags } from "./mapTags";
import { getFlagColor } from "./getFlagColor";

export const mapEngagement = (engagement: any, index: number) => {
  const status = getStatus(engagement.tracker);
  const startISO = parseEpochToISO(engagement?.createdAt ?? 0);
  const fedCredit = engagement?.credit?.rdCredit ?? 0;
  const totalCredit =
    fedCredit +
    engagement.stateCredits
      .map((s: any) => s?.form?.credit ?? 0)
      .reduce((a: number, b: number) => a + b, 0);
  return {
    ...engagement,
    engagementID: engagement.engagementID,
    id: index,
    status,
    amount: totalCredit,
    name: engagement.engagementName,
    tags: mapTags(engagement.tracker, status),
    flagColor: getFlagColor(engagement.tracker, startISO),
  };
};
