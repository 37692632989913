import { baseAPI } from "./baseAPI";

const directConnectAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getDirectConnectAccounts: builder.mutation({
      query: (body) => ({
        url: `/rd/direct-connect-accounts`,
        method: "POST",
        body: body,
      }),
    }),
    getDirectConnectBills: builder.mutation({
      query: (body) => ({
        url: `/rd/direct-connect-bills`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetDirectConnectAccountsMutation,
  useGetDirectConnectBillsMutation,
} = directConnectAPI;
