import { InformationGatheringContainer } from "./InformationGathering/InformationGatheringContainer";
import { ReportsContainer } from "./Reports/ReportsContainer";
interface OperationsPortalProps {
  engagement: any;
  setViewEngDetail: (bool: boolean) => void;
}

export const OperationsPortal = ({
  engagement,
  setViewEngDetail,
}: OperationsPortalProps) => {
  if (engagement?.reports?.status === "COMPLETE")
    return <ReportsContainer setViewEngDetail={setViewEngDetail} />;
  return <InformationGatheringContainer setViewEngDetail={setViewEngDetail} />;
};
