import * as yup from "yup";

export const getCreateDataSpaceUserFormShape = (): any => {
    return yup.object().shape({
        email: yup
          .string()
          .email("Please enter a valid email address")
          .required("You must enter a email"),
        fullName: yup.string().required("Please enter a name."),
        message: yup.string(),
    });
};