import React from "react";
import classNames from "classnames";

const AppCard = (props) => {
  return (
    <div
      className={classNames(
        "bg-white min-h-cardHeight w-full overflow-hidden elevation-2 rounded-lg m-1",
        props.disabled && "bg-gray-500 opacity-50 pointer-events-none"
      )}
    >
      <div className="px-4 py-5 sm:p-6">{props.children}</div>
    </div>
  );
};

export default AppCard;
