import { motion, AnimatePresence } from "framer-motion";
import { IntelligentImportContainer } from "../IntelligentImport";
import { useState, useEffect, useMemo } from "react";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { SprxLoading } from "../../../../ui-components";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { performClick } from "../TaxpayerProfile/utils";
import { useLazyListDocumentsLibraryQuery } from "../../../../api/fileAPI";
import { classNames } from "../../utils";
import * as React from "react";
import { ThumbUpIcon as OutlinedThumbUpIcon } from "@heroicons/react/outline";
import { ThumbUpIcon } from "@heroicons/react/solid";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";

export const AccountingUpload = ({ user, markAsTracked }) => {
  const { engagementData, isUpdatingEngagementData } = useEngagementData();
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [tracker, setTracker] = useState(engagementData?.tracker);
  const [
    listDocumentsLibrary,
    { data: documentList, isLoading: isListingDocuments },
  ] = useLazyListDocumentsLibraryQuery();

  // List Documents
  useEffect(() => {
    if (engagementData?.s3Bucket?.name && engagementData?.engagementID) {
      const body = {
        format: true,
        bucket: engagementData.s3Bucket.name,
        key: `${engagementData.engagementID}/DocumentLibrary`,
      };
      listDocumentsLibrary({ body });
    }
  }, [engagementData, listDocumentsLibrary]);

  const actionCards = useMemo(() => {
    const loading = isListingDocuments;
    const hasUploadedDirectConnect = engagementData?.status[
      "portal-accounting-connect"
    ]?.complete
      ? engagementData.status["portal-accounting-connect"].complete
      : false;
    const supplyDocsLength = documentList?.contents?.filter((doc) => {
      return doc.tags.split(",").some((a) => a === "supply");
    }).length;
    const contractDocsLength = documentList?.contents?.filter((doc) => {
      return doc.tags.split(",").some((a) => a === "contract");
    }).length;
    const computerDocsLength = documentList?.contents?.filter((doc) => {
      return doc.tags.split(",").some((a) => a === "computer");
    }).length;

    return [
      {
        id: 1,
        title: "Accounting System Connect",
        description: "Connect directly to your accounting system.",
        icon: "assets/images/AccountingDirectConnect_Icon.svg",
        tags: "direct-connect",
        statusComponent: hasUploadedDirectConnect ? (
          <CheckCircleIcon className="flex-shrink-0 mt-1 h-4 w-4 text-sprxClientPortalDarkGreen" />
        ) : null,
      },
      {
        id: 2,
        title: "Supply Intelligent Import",
        key: "supply",
        description: "Upload any spreadsheet file containing supply detail.",
        icon: "assets/images/Spreadsheet_Icon.svg",
        component: (
          <IntelligentImportContainer
            engagementData={engagementData}
            user={user}
            type={"SUPPLY"}
          />
        ),
        statusComponent: loading ? (
          <SprxLoading />
        ) : supplyDocsLength > 0 ? (
          `${supplyDocsLength} Item${supplyDocsLength > 1 ? "s" : ""}`
        ) : null,
        statusTracker: true,
      },
      {
        id: 3,
        title: "Contract Research Intelligent Import",
        key: "contract",
        description:
          "Upload any spreadsheet file containing contractor detail.",
        icon: "assets/images/Spreadsheet_Icon.svg",
        tags: "contract",
        component: (
          <IntelligentImportContainer
            engagementData={engagementData}
            user={user}
            type={"CONTRACT"}
          />
        ),
        statusComponent: loading ? (
          <SprxLoading />
        ) : contractDocsLength > 0 ? (
          `${contractDocsLength} Item${contractDocsLength > 1 ? "s" : ""}`
        ) : null,
        statusTracker: true,
      },
      {
        id: 4,
        title: "Computer Lease Intelligent Import",
        key: "computer",
        description:
          "Upload any spreadsheet file containing computer lease detail.",
        icon: "assets/images/Spreadsheet_Icon.svg",
        tags: "computer",
        component: (
          <IntelligentImportContainer
            engagementData={engagementData}
            user={user}
            type={"COMPUTER"}
          />
        ),
        statusComponent: loading ? (
          <SprxLoading />
        ) : computerDocsLength > 0 ? (
          `${computerDocsLength} Item${computerDocsLength > 1 ? "s" : ""}`
        ) : null,
        statusTracker: true,
      },
    ];
  }, [documentList, engagementData, isListingDocuments, user]);

  const TrackerIcon = () => {
    const uploadKey = `upload-${selectedID.key}`;
    const uploadStatus = tracker?.[uploadKey]?.status;

    const handleIncompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [uploadKey]: {
          status: "COMPLETE",
        },
      }));
      await markAsTracked("COMPLETE", uploadKey);
    };

    const handleCompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [uploadKey]: { status: "INCOMPLETE" },
      }));
      await markAsTracked("INCOMPLETE", uploadKey);
    };

    return (
      <motion.div className="mr-8">
        {isUpdatingEngagementData ? (
          <div>
            <div className="animate-pulse ml-2 my-2 h-2 bg-sprxGrey rounded w-4"></div>
          </div>
        ) : (
          <>
            {!tracker?.hasOwnProperty(uploadKey) ||
            uploadStatus === "INCOMPLETE" ? (
              <OutlinedThumbUpIcon
                className="h-6 w-6 text-gray-400"
                onClick={handleIncompleteClick}
              />
            ) : (
              <ThumbUpIcon
                className="h-6 w-6 text-sprxGreen"
                onClick={handleCompleteClick}
              />
            )}
          </>
        )}
      </motion.div>
    );
  };

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 py-32 px-8">
      {actionCards.map((item) => (
        <motion.div
          key={item.id}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            if (item.title === "Accounting System Connect") {
              performClick("codat");
            } else {
              setOpen(true);
              setSelectedID(item);
            }
          }}
          className={
            "flex flex-col w-full overflow-hidden rounded-lg shadow-xl border xl:min-w-270 min-h-245 cursor-pointer"
          }
        >
          <motion.div
            className={classNames(
              "flex flex-1 flex-col justify-center items-center bg-white p-4"
            )}
          >
            <img className="h-24 w-24 my-4" src={item.icon} alt={item.title} />
            <motion.h3 className="text-xl text-center font-semibold text-gray-900">
              {item.title}
            </motion.h3>
            <motion.p className="mt-1 text-sm text-center font-light tracking-tight text-text-gray-900">
              {item.description}
            </motion.p>
            <motion.div className="mt-1 text-sm text-center font-light tracking-tight text-text-gray-900">
              {item.statusComponent}
            </motion.div>
          </motion.div>
        </motion.div>
      ))}
      <AnimatePresence>
        {open && selectedID && (
          <Dialog
            className="relative z-40"
            static
            layoutId={selectedID.id.toString()}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            as={motion.div}
            open={open}
            onClose={setOpen}
          >
            <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <motion.div className="fixed inset-0 z-10 overflow-y-auto">
              <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*Modal content */}
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-94vh min-w-95vw max-w-95vw">
                  <motion.div>
                    {/*Modal title, description, and close button */}
                    <motion.div className="hidden sm:block absolute top-0 right-8 pt-4 pr-4">
                      {selectedID.statusTracker ? <TrackerIcon /> : ""}
                    </motion.div>
                    {true && (
                      <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <motion.button
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => {
                            setOpen(false);
                            setSelectedID(null);
                          }}
                        >
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </motion.button>
                      </motion.div>
                    )}
                    <motion.div className="text-center sm:mt-5">
                      <motion.div className="flex flex-row justify-between items-center">
                        <Dialog.Title
                          as="h3"
                          className="text-3xl font-medium leading-6 text-gray-900 text-left p-4"
                        >
                          {selectedID.title}
                        </Dialog.Title>
                      </motion.div>
                      <motion.div className="">
                        <motion.p className="text-sm text-gray-500 px-4 py-2 text-left">
                          {selectedID.description}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                    <motion.div className="mb-16">
                      {/*Modal main content */}
                      {selectedID.component}
                    </motion.div>
                  </motion.div>
                </Dialog.Panel>
              </motion.div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
      <a
        href={process.env.REACT_APP_CODAT}
        id="codat"
        target="_blank"
        rel="noreferrer"
      >
        <span id="Open Connect Accounts Link" />
      </a>
    </div>
  );
};
