export const getID = (type) => {
    switch (type){
        case "Wages":
            return "Employee ID Number";
        case "Projects":
            return "Project ID Number";
        case "Supply":
            return "Supply Account Number";
        case "Contract Research":
            return "Contractor Account Number";
        case "Computer Lease":
            return "Vendor Account Number";

        // Intelligent Import 
        case  "Wages Intelligent Import":
            return "Employee ID Number";
        case "Projects Intelligent Import":
            return "Project ID Number";
        case "Supply Intelligent Import":
            return "Supply Account Number";
        case "Contract Research Intelligent Import":
            return "Contractor Account Number";
        case "Computer Lease Intelligent Import":
            return "Vendor Account Number";
        default:
            throw Error("Invalid Type prop passed to getID");
    };
};