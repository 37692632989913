import { ChatAlt2Icon } from "@heroicons/react/outline";
import { Button, DataTable } from "../../../../ui-components";
import { useState } from "react";
import { CommentForm } from "../../components";
import { CommentsContainer } from "../../components";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";

interface EngagementProps {
  [key: string]: any;
}

export const Engagement = ({
  fields,
  documents,
  onClickSubmit,
  onAddComment,
  isCreatingComment,
  isComplete,
  onUnClaimAssignment,
  setActiveEngagement,
}: EngagementProps) => {
  const [openCreateComment, setOpenCreateComment] = useState(false);
  const [openThreadsView, setOpenThreadsView] = useState(false);
  const CommentFormProps = {
    id: 1,
    title: "Comment",
    description: "Add a comment.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentForm
        isCreatingComment={isCreatingComment}
        onSubmitForm={async (e: any) => {
          await onAddComment(e);
          setOpenCreateComment(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };
  const ThreadsProps = {
    id: 2,
    title: "Comment threads",
    description: "View comment threads.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentsContainer
        onClickAddCallback={() => setOpenCreateComment(true)}
        portalType="MODEL"
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };
  return (
    <div>
      <div className="mx-8 mt-4 flex w-auto justify-end">
        <div className="flex justify-between">
          <Button
            buttonClassName="mr-4"
            onClick={() => {
              onUnClaimAssignment();
              setActiveEngagement(undefined);
            }}
            secondary={true}
          >
            <div className="flex w-full flex-row justify-center">Unclaim</div>
          </Button>
          <Button
            buttonClassName="mr-4"
            onClick={() => setOpenThreadsView(true)}
            secondary={true}
          >
            <div className="flex w-full flex-row justify-center">
              <ChatAlt2Icon className="h-6 w-6" />
            </div>
          </Button>
          <Button
            className="mr-4"
            onClick={() => onClickSubmit()}
            secondary={true}
          >
            Submit
          </Button>
        </div>
      </div>
      <div className="mx-8 mt-4 h-1 min-h-70vh w-auto">
        <DataTable
          columns={fields}
          data={documents}
          isLoading={false}
          onRowUpdate={async (newData: any) => null}
        />
      </div>
      {/* Open Comment Threads Card  */}
      <OpenActionCard
        open={openThreadsView}
        setOpen={setOpenThreadsView}
        selectedID={ThreadsProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={false}
      />
      {/* Create Comment Thread Card  */}
      <OpenActionCard
        open={openCreateComment}
        setOpen={setOpenCreateComment}
        selectedID={CommentFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />
    </div>
  );
};
