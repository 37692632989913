interface TableContainerProps {
  children: JSX.Element[];
}

export const TableContainer = ({ children }: TableContainerProps) => {
  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
          <div className="overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg max-h-128">
            <table className="min-w-full divide-y divide-gray-300">
              {children}
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};
