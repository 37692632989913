import { useEffect, useMemo, useCallback } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDefaultDocumentFormFields,
  getEditDocumentFormShape,
} from "./utils";
import { LightningBoltIcon } from "@heroicons/react/outline";
import Select from "react-select";
import { debounce } from "lodash";

export const EditDocumentForm = (props) => {
  const { selectOptions, document, updateSelectedItem, loading } = props;

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultDocumentFormFields(document, selectOptions);
    }, [document, selectOptions]),
    resolver: yupResolver(getEditDocumentFormShape()),
  });

  useEffect(() => {
    reset(getDefaultDocumentFormFields(props?.document, props?.selectOptions));
  }, [props?.document, props?.selectOptions, reset]);

  const { errors } = formState;

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutoSave = useCallback(
    debounce((data) => {
      updateSelectedItem(data);
    }, 2000),
    [] // Empty dependency array
  );

  // Update Form Values on Text Change
  const handleFieldChange = useCallback(
    (name, value) => {
      setValue(name, value);
      handleSubmit((data) => onAutoSave(data))();
    },
    [setValue, handleSubmit, onAutoSave]
  );

  return (
    <form
      data-test="document-form"
      className="w-full rounded-lg shadow-xl border p-8 ml-4 min-h-full"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-12">
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-6">
              <div className="flex flex-row">
                <label
                  htmlFor="name"
                  className="block text-sm font-black text-gray-700"
                >
                  Document Name
                </label>
              </div>
              <div className="mt-1">
                <motion.input
                  {...field}
                  disabled={true}
                  id="name"
                  data-test="name"
                  name="name"
                  type="text"
                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.name && (
                  <p className=" pl-2 text-sprxOrange">Invalid Document Name</p>
                )}
              </div>
            </div>
          )}
        />
        <Controller
          name="score"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-2">
              <div className="flex flex-row">
                <label
                  htmlFor="l"
                  className="block text-sm font-black text-gray-700"
                >
                  Persuasian Score
                </label>
              </div>
              <div className="mt-1">
                <motion.input
                  {...field}
                  disabled={true}
                  id="score"
                  data-test="score"
                  name="score"
                  type="text"
                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.location && (
                  <p className=" pl-2 text-sprxOrange">
                    Invalid Persuasion Score
                  </p>
                )}
              </div>
            </div>
          )}
        />
        {loading ? (
          <div className="sm:col-span-1">
            <LightningBoltIcon className="w-6 h-6 ml-4 mt-7 animate-pulse" />
          </div>
        ) : null}
        <Controller
          name="summary"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-12">
              <label
                htmlFor="summary"
                className="block text-sm font-black text-gray-700"
              >
                Summary
              </label>
              <div className="mt-1">
                <motion.textarea
                  {...field}
                  id="summary"
                  name="summary"
                  type="text"
                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent min-h-16vh"
                  whileHover={{ scale: 1.01 }}
                  onChange={(e) => handleFieldChange("summary", e.target.value)}
                />
                {errors?.summary && (
                  <p className=" pl-2 text-sprxOrange">Invalid Summary</p>
                )}
              </div>
            </div>
          )}
        />
        <Controller
          name="fourPartTest"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-12">
              <label
                htmlFor="fourPartTest"
                className="block text-sm font-black text-gray-700"
              >
                Four Part Test Justification
              </label>
              <div className="mt-1">
                <motion.textarea
                  {...field}
                  id="fourPartTest"
                  name="fourPartTest"
                  type="text"
                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent min-h-16vh"
                  whileHover={{ scale: 1.01 }}
                  onChange={(e) =>
                    handleFieldChange("fourPartTest", e.target.value)
                  }
                />
                {errors?.fourPartTest && (
                  <p className=" pl-2 text-sprxOrange">
                    Invalid Four Part Test Justification
                  </p>
                )}
              </div>
            </div>
          )}
        />
        <Controller
          name="excerpt"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-12">
              <label
                htmlFor="excerpt"
                className="block text-sm font-black text-gray-700"
              >
                Relevant Excerpt
              </label>
              <div className="mt-1">
                <motion.textarea
                  {...field}
                  id="excerpt"
                  name="excerpt"
                  type="text"
                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent min-h-4vh"
                  whileHover={{ scale: 1.01 }}
                  onChange={(e) => handleFieldChange("excerpt", e.target.value)}
                />
                {errors?.excerpt && (
                  <p className=" pl-2 text-sprxOrange">Invalid Except</p>
                )}
              </div>
            </div>
          )}
        />
        <Controller
          name="project"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-12">
              <div className="flex flex-row">
                <label
                  htmlFor="project"
                  className="block text-sm font-black text-gray-700"
                >
                  Associated Projects
                </label>
              </div>
              <div className="mt-1">
                <Select
                  {...field}
                  onChange={(val) => handleFieldChange("project", val)}
                  options={selectOptions}
                  isMulti
                />
                {errors?.project && (
                  <p className=" pl-2 text-sprxOrange">Invalid Document Name</p>
                )}
              </div>
            </div>
          )}
        />
      </div>
    </form>
  );
};
