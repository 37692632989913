import SurveyProvider from "../../../rtk/R&D/Survey/SurveyProvider";
import SurveyLanding from "./SurveyLanding";

const SurveyParent = () => {
  return (
    <SurveyProvider>
      <SurveyLanding />
    </SurveyProvider>
  );
};

export default SurveyParent;
