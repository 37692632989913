import {
  SprxLoading,
  ModalCard,
  ButtonContainer,
  Button,
  BasicSelectInput,
} from "../../../ui-components";
import { useState } from "react";
import { types } from "./constants";
import { useGetDirectConnectBillsMutation } from "../../../api/directConnectAPI";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { isDev } from "../utils";

const CodatModal = (props) => {
  const { engagementData, updateEngagementData } = useEngagementData();

  const [getDirectConnectBills, { isLoading: isLoadingBills }] =
    useGetDirectConnectBillsMutation();

  const onContinue = async () => {
    const accountIDs = accounts
      .filter((a) => a.type !== "Not Applicable")
      .map((item) => {
        const type =
          item.type === "Contract Research"
            ? "cr"
            : item.type === "Computer Lease"
            ? "computerLease"
            : item.type === "Supply"
            ? "supply"
            : null;
        return {
          ...item,
          costType: type,
        };
      });
    const body = {
      CompanyID: props.companyID,
      engagementID: props.engagementID,
      accountIDs: accountIDs,
    };

    const getBillsResponse: any = await getDirectConnectBills(body);
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ getBillsResponse });

    // Update Status
    let status = {};
    Object.assign(status, engagementData.status);
    status["portal-accounting"] = {
      complete: true,
    };
    status["portal-accounting-connect"] = {
      complete: true,
    };
    let updateEngagementDataPayload = {
      ...engagementData,
      status,
    };
    const updateEngagementDataResponse = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: updateEngagementDataPayload,
    });
    if (isDev) console.log({ updateEngagementDataResponse });

    if (getBillsResponse?.data?.status === true) {
      props?.onClick("Home");
      props?.setCodatRedirect(false);
    }
  };

  const handleSelect = (type, account) => {
    let shallowCopy = accounts;
    shallowCopy[accounts.findIndex((a) => a.accountID === account.accountID)] =
      {
        ...account,
        type,
      };
    setAccounts([...shallowCopy]);
  };

  const [accounts, setAccounts] = useState(
    props?.accounts.map((account, key) => {
      return {
        name: account?.name,
        accountID: account?.id,
        currentBalance: account?.currentBalance,
        fullDescription: account?.description,
        description:
          account?.description?.length > 500
            ? account?.description.substring(0, 100) + " ..."
            : account?.description,
        type: "Not Applicable",
      };
    })
  );
  if (isLoadingBills) {
    return <SprxLoading />;
  } else {
    return (
      <ModalCard
        onClick={() => props.onClick()}
        title={"Direct Connect"}
        mainContent={
          <div className="flex flex-col">
            <div className="-my-2 overflow-x-auto sm:-mx-3 lg:-mx-3 mb-8">
              <div className="py-2 align-middle inline-block min-w-full sm:px-3 lg:px-3">
                <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-200">
                    <thead className="bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Account Name
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Account Balance
                        </th>
                        <th
                          scope="col"
                          className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase"
                        >
                          Classification
                        </th>
                      </tr>
                    </thead>
                    <tbody className="bg-white divide-y divide-gray-200">
                      {accounts.map((account, key) => (
                        <tr key={key} className="">
                          <td className="px-6 py-4">
                            <div className="text-sm text-gray-900">
                              {account?.name}
                            </div>
                            <div className="text-sm text-gray-500">
                              {account?.description}
                            </div>
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">
                            {account?.currentBalance
                              ? "$" + account.currentBalance
                              : "Not Available"}
                          </td>
                          <td className="px-6 py-4 whitespace-nowrap text-gray-500">
                            <BasicSelectInput
                              label="Type"
                              id={key}
                              placeholder={"Type"}
                              value={account?.type}
                              options={types}
                              onChange={(event) =>
                                handleSelect(event.target.value, account)
                              }
                            />
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
        bottomContent={
          <ButtonContainer className="w-full justify-end m">
            <Button
              onClick={() => onContinue()}
              disabled={false}
              primary={true}
              secondary={false}
              buttonClassName={"max-h-12"}
              textClassName=""
            >
              <p>Confirm</p>
            </Button>
          </ButtonContainer>
        }
      />
    );
  }
};
export default CodatModal;
