import { motion } from "framer-motion";
import { useState } from "react";
import { TabNavigator } from "../../R&D/ReviewPortal/Allocations/components";

interface EngagementListProps {
  [key: string]: any;
}

export const EngagementList = ({
  currentTab,
  setCurrentTab,
  setActiveEngagement,
  engagements,
  setFilter,
  filter,
  isSettingActive,
  user,
  onClaimAssignment,
}: EngagementListProps) => {
  const [activeIndex, setActiveIndex] = useState(null);
  return (
    <div className="flex-1">
      <div className="w-100vw">
        {/* Admin engagement table */}
        <div className="mt-2">
          <div className="items-between flex flex-row items-end justify-between">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search studies</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none"></div>
          </div>
          {user.role === "modeler" && (
            <TabNavigator
              currentTab={currentTab}
              setCurrentTab={(tab) => {
                setCurrentTab(tab);
              }}
              tabs={[
                { label: "Ready", value: "Ready" },
                { label: "Completed", value: "Completed" },
              ]}
            ></TabNavigator>
          )}

          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Study Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Assigned Date
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Status
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Set Active</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {!engagements?.length ? (
                        <tr>
                          <td colSpan={5} className="py-3.5 text-center">
                            No studies exist. If that seems incorrect, please
                            contact an SPRX team member.
                          </td>
                        </tr>
                      ) : (
                        <>
                          {engagements?.map((eng: any, index: any) => (
                            <tr key={eng.engagementID}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {eng.name}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {eng.companyName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {eng.assignedDate}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {eng.status}
                              </td>

                              <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                {user?.role === "certifier" ||
                                currentTab.value === "Completed" ||
                                user.claimedAssignments?.includes(
                                  eng.engagementID,
                                ) ? (
                                  <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                    onClick={() => {
                                      setActiveEngagement(eng);
                                      setActiveIndex(index);
                                    }}
                                  >
                                    {isSettingActive &&
                                    activeIndex === index ? (
                                      <div className="w-full">
                                        <p className="h-2 w-8 animate-pulse rounded bg-sprxGrey"></p>
                                      </div>
                                    ) : (
                                      "Open"
                                    )}
                                  </motion.button>
                                ) : (
                                  <motion.button
                                    whileHover={{ scale: 1.1 }}
                                    whileTap={{ scale: 0.95 }}
                                    className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                    onClick={() => {
                                      onClaimAssignment(eng);
                                      setActiveEngagement(eng);
                                      setActiveIndex(index);
                                    }}
                                  >
                                    {isSettingActive &&
                                    activeIndex === index ? (
                                      <div className="w-full">
                                        <p className="h-2 w-8 animate-pulse rounded bg-sprxGrey"></p>
                                      </div>
                                    ) : (
                                      <>
                                        {`${user.claimedAssignments?.length < 3 || !user.claimedAssignments ? "Claim" : ""}`}
                                      </>
                                    )}
                                  </motion.button>
                                )}
                              </td>
                            </tr>
                          ))}
                        </>
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
