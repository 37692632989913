import { BuildingProfile as BuildingProfileFormProps } from "../../../types/buildingProfile";
import { useMemo } from "react";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import {
  getBuildingProfileFormShape,
  getBuildingProfileDefaultFields,
} from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { STATE_OPTS_V2 } from "../../../../R&D/ReviewPortal/utils/constants";
import Select from "react-select";

interface BuildingProfileProps {
  onUpdateBuildingProfile: (model: any) => Promise<void>;
  buildingProfileData: BuildingProfileFormProps | undefined;
  isLoading: boolean;
}

export const BuildingProfile = ({
  onUpdateBuildingProfile,
  buildingProfileData,
  isLoading,
}: BuildingProfileProps) => {
  const schema = getBuildingProfileFormShape();
  const { control, handleSubmit, setValue, formState } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getBuildingProfileDefaultFields(buildingProfileData);
    }, [buildingProfileData]),
    resolver: yupResolver(schema),
  });
  const { errors } = formState;

  return (
    <form
      data-test="building-profile-form"
      className="mt-4 ml-4 mr-4"
      onSubmit={handleSubmit((model) => onUpdateBuildingProfile(model))}
    >
      <div className="flex flex-col justify-between">
        <div className="grid grid-cols-3 gap-y-4 gap-x-4">
          <Controller
            name="address"
            control={control}
            render={({ field }) => (
              <div className="col-span-3">
                <div className="flex flex-row">
                  <label
                    htmlFor="address"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Address
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>

                <div className="mt-1">
                  <motion.input
                    {...field}
                    data-test="address"
                    id="address"
                    name="address"
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.address && (
                    <p className="pl-2 text-sprxOrange">Invalid address</p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="squareFeet"
            control={control}
            render={({ field }) => (
              <div className="col-span-1" data-test="entity-name-div">
                <div className="flex flex-row">
                  <label
                    htmlFor="squareFeet"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Total Square Footage
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>
                <div className="mt-1">
                  <motion.input
                    {...field}
                    id="squareFeet"
                    data-test="squareFeet"
                    name="squareFeet"
                    type="number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.squareFeet && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid square footage
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="buildingType"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="flex flex-row">
                  <label
                    htmlFor="buildingType"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Building Type
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>

                <div className="mt-1">
                  <motion.select
                    {...field}
                    id="buildingType"
                    name="buildingType"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  >
                    <option></option>
                    <option value={"GOVERNMENT"}>Government</option>
                    <option value={"COMMERCIAL"}>Commercial</option>
                  </motion.select>
                </div>
                {errors?.buildingType && (
                  <p className="pl-2 text-sprxOrange">Invalid building type</p>
                )}
              </div>
            )}
          />
          <Controller
            name="state"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="flex flex-row">
                  <label
                    htmlFor="state"
                    className="block text-sm font-medium text-gray-700"
                  >
                    State
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>

                <div className="mt-1">
                  <motion.select
                    {...field}
                    id="buildingType"
                    name="buildingType"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  >
                    {["", ...STATE_OPTS_V2].map((state) => (
                      <option key={state} value={state}>
                        {state}
                      </option>
                    ))}
                  </motion.select>
                  {errors?.state && (
                    <p className="pl-2 text-sprxOrange">Invalid state</p>
                  )}
                </div>
              </div>
            )}
          />

          <Controller
            name="buildingCost"
            control={control}
            render={({ field }) => (
              <div className="col-span-1" data-test="entity-name-div">
                <div className="flex flex-row">
                  <label
                    htmlFor="buildingCost"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Building Cost
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>
                <div className="mt-1">
                  <motion.input
                    {...field}
                    id="buildingCost"
                    data-test="buildingCost"
                    name="buildingCost"
                    type="number"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.buildingCost && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid square footage
                    </p>
                  )}
                </div>
              </div>
            )}
          />

          <Controller
            name="ownerName"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="flex flex-row">
                  <label
                    htmlFor="ownerName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Owner Name
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>

                <div className="mt-1">
                  <motion.input
                    {...field}
                    data-test="ownerName"
                    id="ownerName"
                    name="ownerName"
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.ownerName && (
                    <p className="pl-2 text-sprxOrange">Invalid Owner Name</p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="buildingName"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="flex flex-row">
                  <label
                    htmlFor="buildingName"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Building Name
                  </label>
                  <p className="-mt-1 text-sprxClientPortalRed">*</p>
                </div>

                <div className="mt-1">
                  <motion.input
                    {...field}
                    data-test="buildingName"
                    id="buildingName"
                    name="buildingName"
                    type="text"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.buildingName && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid Building name
                    </p>
                  )}
                </div>
              </div>
            )}
          />
        </div>

        <div className="grid grid-cols-2 gap-y-4 gap-x-4">
          <Controller
            name="systemsRelated"
            control={control}
            render={({ field }) => (
              <div className="col-span-2 mt-4">
                <div className="flex flex-row">
                  <label
                    htmlFor="project"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Associated Systems
                  </label>
                </div>
                <div className="mt-1">
                  <Select
                    {...field}
                    onChange={(val) => setValue("systemsRelated", val as any)}
                    options={
                      [
                        { value: "HVAC", label: "HVAC" },
                        { value: "ELECTRICAL", label: "Electrical" },
                        { value: "ENVELOPE", label: "Envelope" },
                        { value: "LIGHTING", label: "Lighting" },
                        { value: "OTHER", label: "Other" },
                      ] as any
                    }
                    isMulti
                    styles={{
                      menuList: (provided) => ({
                        ...provided,
                        maxHeight: "120px", // Set the maximum height
                        overflowY: "auto", // Enable scrolling
                      }),
                      option: (provided) => ({
                        ...provided,
                        fontSize: "14px", // Set the font size for options
                      }),
                    }}
                  />
                  {errors?.systemsRelated && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid related systems
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="meetsPrevailingWageRequirements"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <motion.input
                      {...field}
                      value={field.value as any}
                      checked={field.value}
                      id="meetsPrevailingWageRequirements"
                      name="meetsPrevailingWageRequirements"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                      whileHover={{ scale: 1.05 }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="meetsPrevailingWageRequirements"
                      className="font-medium text-gray-700"
                    >
                      Meets Prevailing Wage Requirements
                    </label>
                  </div>
                </div>
                {errors?.meetsPrevailingWageRequirements && (
                  <p className="pl-2 text-sprxOrange">Please confirm</p>
                )}
              </div>
            )}
          />

          <Controller
            name="meetsApprenticeshipReqirements"
            control={control}
            render={({ field }) => (
              <div className="col-span-1">
                <div className="relative flex items-start">
                  <div className="flex h-5 items-center">
                    <motion.input
                      {...field}
                      value={field.value as any}
                      checked={field.value}
                      id="meetsApprenticeshipReqirements"
                      name="meetsApprenticeshipReqirements"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                      whileHover={{ scale: 1.05 }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="meetsApprenticeshipReqirements"
                      className="font-medium text-gray-700"
                    >
                      Meets Apprenticeship Requirements
                    </label>
                  </div>
                </div>
                {errors?.meetsPrevailingWageRequirements && (
                  <p className="pl-2 text-sprxOrange">Please confirm</p>
                )}
              </div>
            )}
          />
        </div>
        <motion.div className="mt-12 flex w-full flex-row items-end justify-end">
          <motion.button
            type="submit"
            data-test="Save & Close"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            className="focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm"
          >
            {isLoading ? (
              <div className="flex h-5 w-full flex-col items-center justify-center">
                <p
                  className={"h-2 w-full animate-pulse rounded-md bg-gray-100"}
                ></p>
              </div>
            ) : (
              "Save & Close"
            )}
          </motion.button>
        </motion.div>
      </div>
    </form>
  );
};
