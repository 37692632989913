import { getID } from "../ReviewPortal/utils";
import { v4 as uuidv4 } from "uuid";

export const appendID = (item, type) => {
  const ID = uuidv4();
  return {
    ...item,
    [getID(type)]: ID,
    ID,
  };
};
