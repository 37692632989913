import classNames from "classnames";

const CreateEngagementCard = (props) => {
  return (
    <div className="flex flex-col">
      <div className="w-full mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
        <div className="sm:col-span-6">
          <label
            htmlFor="Engagement-name"
            className="block text-sm font-medium text-gray-700"
          >
            Engagement Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="first-name"
              id="first-name"
              autoComplete="given-name"
              className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
              value={props.engagementName}
              onChange={(event) => props.setEngagementName(event.target.value)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="Company-name"
            className="block text-sm font-medium text-gray-700"
          >
            Company Name
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
              value={props.companyName}
              onChange={(event) => props.setCompanyName(event.target.value)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label
            htmlFor="Company-ein"
            className="block text-sm font-medium text-gray-700"
          >
            Company EIN
          </label>
          <div className="mt-1">
            <input
              type="text"
              name="last-name"
              id="last-name"
              autoComplete="family-name"
              className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
              value={props.companyEIN}
              onChange={(event) => props.setCompanyEIN(event.target.value)}
            />
          </div>
        </div>

        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Tax Year Start Date
          </label>
          <div className="mt-1">
            <input
              id={"taxYearStart"}
              className={classNames(
                "form-input block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
              )}
              type={"text"}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              placeholder={"XX/XX/XXXX"}
              value={props.taxYearStart}
              onChange={(event) => props.setTaxYearStart(event.target.value)}
            />
          </div>
        </div>
        <div className="sm:col-span-6">
          <label className="block text-sm font-medium text-gray-700">
            Tax Year End Date
          </label>
          <div className="mt-1">
            <input
              id={"taxYearEnd"}
              className={classNames(
                "form-input block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
              )}
              type={"text"}
              onFocus={(e) => (e.target.type = "date")}
              onBlur={(e) => (e.target.type = "text")}
              placeholder={"XX/XX/XXXX"}
              value={props.taxYearEnd}
              onChange={(event) => props.setTaxYearEnd(event.target.value)}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default CreateEngagementCard;
