//import { useCallback, useEffect, useState } from "react";
import {
  useQueryChatMutation,
  useCreateChatMutation,
  useDeleteChatMutation,
} from "../../api/chatAPI";
import { isDev } from "../../main/R&D/utils";
import { useState, useMemo, useCallback } from "react";
import ChatContext from "./ChatContext";
import { useUsers } from "../Users/useUsers";
const ChatProvider = ({ children }) => {
  const usersAPI = useUsers();
  const [chatID, setChatID] = useState(null);
  const [engagementID, setEngagementID] = useState(null);

  const [
    createChat,
    {
      isLoading: isCreatingChat,
      isSuccess: isChatCreated,
      isError: isCreatingChatError,
      error: createChatError,
    },
  ] = useCreateChatMutation();

  const [
    deleteChat,
    {
      isLoading: isDeletingChat,
      isSuccess: isChatDeleted,
      isError: isDeletingChatError,
      error: deleteChatError,
    },
  ] = useDeleteChatMutation();

  const [
    queryChat,
    {
      isLoading: isQueryingChat,
      isSuccess: isChatQueried,
      isError: isQueryingChatError,
      error: queryChatError,
    },
  ] = useQueryChatMutation();

  const onCreateChat = useCallback(
    async (knowledgeBaseId) => {
      console.log({ isCreatingChat, knowledgeBaseId });
      if (!isCreatingChat && knowledgeBaseId) {
        const { data } = await createChat({ id: knowledgeBaseId });
        if (isDev) console.log({ onCreateChatResponse: data });
        setChatID(data.data.chatID);
        return data;
      }
    },
    [isCreatingChat, createChat]
  );

  const onDeleteChat = useCallback(async () => {
    if (chatID) {
      const { data } = await deleteChat({ id: chatID });
      if (isDev) console.log({ onDeleteChatResponse: data });
      return data;
    }
  }, [chatID, deleteChat]);

  const onQueryChat = useCallback(
    async (message) => {
      if (chatID) {
        const { data } = await queryChat({
          id: chatID,
          message,
          engagementID: engagementID,
          engagementType: usersAPI.user.engagementType,
        });
        if (isDev) console.log({ queryChatResponse: data });
        return data;
      } else {
        await onCreateChat();
        onQueryChat(message);
      }
    },
    [
      chatID,
      queryChat,
      engagementID,
      usersAPI.user.engagementType,
      onCreateChat,
    ]
  );
  const value = useMemo(
    () => ({
      chatID,
      isCreatingChat,
      isCreatingChatError,
      createChatError,
      isChatCreated,
      isDeletingChat,
      isDeletingChatError,
      deleteChatError,
      isChatDeleted,
      isQueryingChat,
      isQueryingChatError,
      queryChatError,
      isChatQueried,
      onCreateChat,
      onDeleteChat,
      onQueryChat,
      setEngagementID,
    }),
    [
      chatID,
      isCreatingChat,
      isCreatingChatError,
      createChatError,
      isChatCreated,
      isDeletingChat,
      isDeletingChatError,
      deleteChatError,
      isChatDeleted,
      isQueryingChat,
      isQueryingChatError,
      queryChatError,
      isChatQueried,
      onCreateChat,
      onDeleteChat,
      onQueryChat,
      setEngagementID,
    ]
  );

  return <ChatContext.Provider value={value}>{children}</ChatContext.Provider>;
};
export default ChatProvider;
