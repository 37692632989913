import React from "react";
import * as d3 from "d3";
import { IChartProps, QreChartItem } from "./PieChart";
import { groupData } from "./util/groupData";
import { DataDisplay } from "./DataDisplay";

export const TreeMapChart = ({
  lineItems,
  label,
  value,
  title,
}: IChartProps) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const [selectedSlice, setSelectedSlice] = React.useState<number | null>(0);

  const groupedData = React.useMemo(() => {
    return groupData(lineItems, label, value);
  }, [label, lineItems, value]);

  React.useEffect(() => {
    if (!svgRef.current) return;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const width = 250; // Adjust based on container size
    const height = 250; // Adjust based on container size
    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height);

    // Remove any previous drawing
    svg.selectAll("*").remove();

    const root = d3
      .hierarchy({ children: groupedData })
      .sum((d: any) => d.totalCost); // Here we use the 'totalCost' property to determine the size of the box

    d3.treemap().size([width, height]).padding(2)(root);

    const leaf = svg
      .selectAll("g")
      .data(root.leaves())
      .join("g")
      .attr("transform", (d: any) => `translate(${d.x0},${d.y0})`);

    leaf
      .append("rect")
      .attr("width", (d: any) => d.x1 - d.x0)
      .attr("height", (d: any) => d.y1 - d.y0)
      .attr("fill", (d: any) => color(d.data.group))
      .attr("stroke", (d: any) => {
        return d.data.group === groupedData[selectedSlice]?.group
          ? "black"
          : "white";
      })
      .attr("stroke-width", (d: any) => {
        return d.data.group === groupedData[selectedSlice]?.group ? "2" : "";
      })
      .on("click", (event: any, d: any) => {
        const index = groupedData.findIndex(
          (g) => g.group === d.data.group,
          d.data.group
        );
        setSelectedSlice(index);
        svg.selectAll("*").attr("stroke", "").attr("stroke-width", "");
      });
  }, [groupedData, selectedSlice]);

  const group = groupedData[selectedSlice]?.group ?? "N/A";
  const cost = Math.round(groupedData[selectedSlice]?.totalCost ?? 0);

  return (
    <div className="flex flex-row justify-center items-center h-full">
      <div className="flex flex-col items-center h-full">
        <h1 className="font-bold">{title}</h1>
        {/* Chart */}
        <div className="flex flex-row items-center justify-evenly  h-full">
          <svg ref={svgRef}></svg>
          {/* Summary Box */}
          <div className="rounded-lg bg-white w-60 text-center  h-full">
            <div className="flex flex-col w-full justify-center items-center">
              <DataDisplay title={group} value={cost} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
