import { motion } from "framer-motion";
import { useCallback, useRef } from "react";
import { v4 as uuidv4 } from "uuid";
import { FileListPane } from "./FileListPane";
import { useDropzone } from "react-dropzone";
import {
  useUploadFileMutation,
  useDownloadFileMutation,
  useCreateJSONDataFileMutation,
} from "../../../../api/fileAPI";
import {
  useUpdateEngagementDataMutation,
  useExtractTaxW2Mutation,
} from "../../../../api/engagmentAPI";
import { performClick } from "../../utils";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const W2UploadPage = (props) => {
  const inputFileRef = useRef();
  const { engagementData, user, documentList } = props;
  const { engagementID } = engagementData;
  const {
    data: { activeEngagementID },
  } = user;

  const [extractW2, { isLoading: isExtracting }] = useExtractTaxW2Mutation();

  const [createJSONDataFile, { isLoading: isCreatingFile }] =
    useCreateJSONDataFileMutation();

  const [downloadFile, { isLoading: isDownloadingFile }] =
    useDownloadFileMutation();

  const [updateEngagementData, { isLoading: isUpdatingEngagementData }] =
    useUpdateEngagementDataMutation();

  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  // Handle Document Upload Data
  const onDocumentUpload = async (event) => {
    // Parse Documents from Event
    const jobID = uuidv4();
    const { files } = event.target;
    const docs = Object.keys(files)
      .filter((k) => k !== "length")
      .map((k, index) => {
        return {
          file: files[k],
          index,
        };
      });

    // Upload Files to S3
    await Promise.all(
      docs.map((file) =>
        uploadFile({
          bucket: engagementData.s3Bucket.name,
          key: `${engagementData.engagementID}/w2/${jobID}/raw/${file.file.name}`,
          action: "putObject",
          file: file.file,
          type: file.file.type,
        })
      )
    );

    // Create Copy of File Pointers
    let filteredFiles = docs.map((file) => ({
      bucket: engagementData.s3Bucket.name,
      key: `${engagementData.engagementID}/w2/${jobID}/raw/${file.file.name}`,
      action: "putObject",
      file: file.file,
      type: file.file.type,
    }));

    // Add File Pointers to Dynamo Db
    engagementData?.plugins?.w2[jobID]?.files?.forEach((file) => {
      filteredFiles = filteredFiles.filter((item) => item.key !== file.key);
    });
    const body = {
      plugins: {
        w2: {
          ...engagementData?.plugins?.w2,
          [jobID]: {
            files: [...filteredFiles],
            status: "processing",
          },
        },
      },
    };
    await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });

    await extractW2({
      engagementID: engagementData.engagementID,
      w2JobID: jobID,
    });
  };

  const onDrop = useCallback(async (files) => {
    // Parse Documents from Event
    const jobID = uuidv4();
    const docs = Object.keys(files)
      .filter((k) => k !== "length")
      .map((k, index) => {
        return {
          file: files[k],
          index,
        };
      });

    // Upload Files to S3
    await Promise.all(
      docs.map((file) =>
        uploadFile({
          bucket: engagementData.s3Bucket.name,
          key: `${engagementData.engagementID}/w2/${jobID}/raw/${file.file.name}`,
          action: "putObject",
          file: file.file,
          type: file.file.type,
        })
      )
    );

    // Create Copy of File Pointers
    let filteredFiles = docs.map((file) => ({
      bucket: engagementData.s3Bucket.name,
      key: `${engagementData.engagementID}/w2/${jobID}/raw/${file.file.name}`,
      action: "putObject",
      file: file.file,
      type: file.file.type,
    }));

    // Add File Pointers to Dynamo Db
    engagementData?.plugins?.w2[jobID]?.files?.forEach((file) => {
      filteredFiles = filteredFiles.filter((item) => item.key !== file.key);
    });
    const body = {
      plugins: {
        w2: {
          ...engagementData?.plugins?.w2,
          [jobID]: {
            files: [...filteredFiles],
            status: "processing",
          },
        },
      },
    };
    await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });

    await extractW2({
      engagementID: engagementData.engagementID,
      w2JobID: jobID,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onDeleteCallback = async (filename) => {
    // Remove Associated Line Items
    const bucket = engagementData?.s3Bucket?.name;
    const currentYear = engagementData?.taxYearEnd.split("-", 1);
    const downloadKey = `${activeEngagementID}/uploadFiles/${activeEngagementID}_WAGE_DATA_OBJ.json`;
    const body = { bucket, key: downloadKey, action: "getObject" };
    const downloadResponse = await downloadFile({ ...body });
    const filteredData = downloadResponse.data.filter(
      (a) => a.filename !== filename
    );

    // Upload Filtered Data
    const file = await createJSONDataFile([...filteredData]);
    const taxYear = `${currentYear}wageList`; //
    const uploadJSONDataParams = {
      bucket,
      key: downloadKey,
      action: "putObject",
      file: file.data,
      taxYear,
      engagementID,
      type: file.data.type,
    };
    await uploadFile(uploadJSONDataParams);

    let status = {};
    Object.assign(status, engagementData.status);

    // Update W2 Status if Last W2 Doc
    const hasOtherW2s =
      documentList.contents
        .filter((doc) => doc.type === "Wages")
        .filter((doc) => doc.tags.split(",").some((a) => a === "w2")).length >
      0;

    if (!hasOtherW2s) {
      status[`portal-wages-w2`] = {
        complete: false,
      };
    }

    // Update Wage Status Object iff Listing is Empty
    if (filteredData.length === 0) {
      status[`portal-wages`] = {
        complete: false,
      };
    }

    // TODO: Update Plugin Object
    let w2ObjCopy = engagementData.plugins.w2;

    let updateEngagementDataPayload = {
      ...engagementData,
      plugins: {
        ...engagementData.plugins,
        w2: w2ObjCopy,
      },
      status,
    };
    const updateEngagementDataResponse = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: updateEngagementDataPayload,
    });
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ updateEngagementDataResponse });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const loading =
    isUploadingFile ||
    isCreatingFile ||
    isDownloadingFile ||
    isExtracting ||
    isUpdatingEngagementData;

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 px-8 py-8">
      <div className="col-span-1 flex flex-col w-full">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center h-96 border-4 border-dashed border-sprxClientPortalLightBlue rounded-lg py-12">
            <img
              className="xl:h-24 xl:w-24 h-16 w-16 my-4"
              src={"assets/images/Upload_Icon.svg"}
              alt={"Upload"}
            />
            <motion.h3 className="xl:text-xl text-md text-center font-semibold text-gray-900">
              Drag and Drop files to upload
            </motion.h3>
            <motion.p className="mt-1 xl:text-sm text-xs text-center font-light tracking-tight text-text-gray-900">
              or
            </motion.p>
            <motion.button
              className={classNames(
                "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                "inline-flex items-center justify-center w-2/4 mt-2 px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white focus:outline-none focus:ring-none"
              )}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => performClick("file-dialog-mass-import")}
            >
              Browse
            </motion.button>
            <motion.input
              ref={inputFileRef}
              id="file-dialog-mass-import"
              multiple
              type="file"
              style={{ display: "none" }}
              accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                      text/plain,.msg,.json,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.HEIC"
              onChange={(e) => onDocumentUpload(e)}
            />
          </div>
        </div>
      </div>
      <FileListPane
        engagementData={engagementData}
        user={user}
        type={"Wages"}
        loading={loading}
        tagFilter={"w2"}
        deleteCallback={onDeleteCallback}
      />
    </div>
  );
};
