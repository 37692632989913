export const getDefaultDocumentFormFields = (document, projects) => {
  let projectIDs = [];
  if (document.hasOwnProperty("project")) {
    // Handle legacy implementation of project field
    if (Array.isArray(document.project)) {
      projectIDs = document.project.map((a) => a.value);
    } else {
      projectIDs = [document.project];
    }
  }

  const project = projects.filter((project) =>
    projectIDs.includes(project.value)
  );

  const name = document.hasOwnProperty("key")
    ? document.key.split("/").pop()
    : "";
  const summary = document.hasOwnProperty("summary") ? document.summary : "";
  const fourPartTest = document.hasOwnProperty("fourPartTest")
    ? document.fourPartTest
    : "";
  const score = document.hasOwnProperty("score") ? document.score : 0;
  const excerpt = document.hasOwnProperty("excerpt") ? document.excerpt : "";
  return {
    key: document.key,
    name,
    score,
    summary,
    fourPartTest,
    excerpt,
    project,
  };
};
