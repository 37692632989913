export const getModelSubmissionDefaultFields = ({
  efficiencyPercentage,
  hvacEfficiency,
  lightingEfficiency,
  envelopeEfficiency,
}: {
  efficiencyPercentage: number;
  hvacEfficiency: number;
  lightingEfficiency: number;
  envelopeEfficiency: number;
}) => {
  return {
    isAccurate: false,
    efficiencyPercentage: efficiencyPercentage ?? 0,
    hvacEfficiency: hvacEfficiency ?? 0,
    lightingEfficiency: lightingEfficiency ?? 0,
    envelopeEfficiency: envelopeEfficiency ?? 0,
  };
};
