import { stateAbbreviations } from "../../ReviewPortal/utils/constants";

export const sumQreByState = (state: string, data: any[]) => {
  if (data === undefined || data.length === 0) return 0;

  return Math.round(
    data
      .map((a) => {
        const locationKey = Object.keys(a).find((e) => e.includes("Location"));
        if (locationKey === undefined) return 0;
        if (stateAbbreviations[a[locationKey]] === state)
          return Number(a?.qre ?? 0);
        return 0;
      })
      .reduce((a, b) => a + b, 0)
  );
};
