export const states = [
  { value: "AL", label: "AL", alternates: ["Alabama"] },
  { value: "AK", label: "AK", alternates: ["Alaska"] },
  { value: "AZ", label: "AZ", alternates: ["Arizona"] },
  { value: "AR", label: "AR", alternates: ["Arkansas"] },
  { value: "CA", label: "CA", alternates: ["California"] },
  { value: "CO", label: "CO", alternates: ["Colorado"] },
  { value: "CT", label: "CT", alternates: ["Connecticut"] },
  { value: "DE", label: "DE", alternates: ["Delaware"] },
  { value: "DC", label: "DC", alternates: ["Washington D.C"] },
  { value: "FL", label: "FL", alternates: ["Florida"] },
  { value: "GA", label: "GA", alternates: ["Georgia"] },
  { value: "HI", label: "HI", alternates: ["Hawaii"] },
  { value: "ID", label: "ID", alternates: ["Idaho"] },
  { value: "IL", label: "IL", alternates: ["Illinois"] },
  { value: "IN", label: "IN", alternates: ["Indiana"] },
  { value: "IA", label: "IA", alternates: ["Iowa"] },
  { value: "KS", label: "KS", alternates: ["Kansas"] },
  { value: "KY", label: "KY", alternates: ["Kentucky"] },
  { value: "LA", label: "LA", alternates: ["Louisiana"] },
  { value: "ME", label: "ME", alternates: ["Maine"] },
  { value: "MD", label: "MD", alternates: ["Maryland"] },
  { value: "MA", label: "MA", alternates: ["Massachusetts"] },
  { value: "MI", label: "MI", alternates: ["Michigan"] },
  { value: "MN", label: "MN", alternates: ["Minnesota"] },
  { value: "MS", label: "MS", alternates: ["Mississippi"] },
  { value: "MO", label: "MO", alternates: ["Missouri"] },
  { value: "MT", label: "MT", alternates: ["Montana"] },
  { value: "NE", label: "NE", alternates: ["Nebraska"] },
  { value: "NV", label: "NV", alternates: ["Nevada"] },
  { value: "NH", label: "NH", alternates: ["New Hampshire"] },
  { value: "NJ", label: "NJ", alternates: ["New Jersey"] },
  { value: "NM", label: "NM", alternates: ["New Mexico"] },
  { value: "NY", label: "NY", alternates: ["New York"] },
  { value: "NC", label: "NC", alternates: ["North Carolina"] },
  { value: "ND", label: "ND", alternates: ["North Dakota"] },
  { value: "OH", label: "OH", alternates: ["Ohio"] },
  { value: "OK", label: "OK", alternates: ["Oklahoma"] },
  { value: "OR", label: "OR", alternates: ["Oregon"] },
  { value: "PA", label: "PA", alternates: ["Pennsylvania"] },
  { value: "RI", label: "RI", alternates: ["Rhode Island"] },
  { value: "SC", label: "SC", alternates: ["South Carolina"] },
  { value: "SD", label: "SD", alternates: ["South Dakota"] },
  { value: "TN", label: "TN", alternates: ["Tennessee"] },
  { value: "TX", label: "TX", alternates: ["Texas"] },
  { value: "UT", label: "UT", alternates: ["Utah"] },
  { value: "VT", label: "VT", alternates: ["Vermont"] },
  { value: "VA", label: "VA", alternates: ["Virginia"] },
  { value: "WA", label: "WA", alternates: ["Washington"] },
  { value: "WV", label: "WV", alternates: ["West Virginia"] },
  { value: "WI", label: "WI", alternates: ["Wisconsin"] },
  { value: "WY", label: "WY", alternates: ["Wyoming"] },
];

export const fieldOptions = {
  "Computer Lease Intelligent Import": [
    {
      label: "Vendor Account Number",
      key: "Account Number",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Account Name",
      key: "Vendor Account Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Account Description",
      key: "Vendor Account Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Account Department",
      key: "Vendor Account Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Location",
      key: "Vendor Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Cost",
      key: "Vendor Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
  "Wages Intelligent Import": [
    {
      label: "Employee ID Number",
      key: "ID Number",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Name",
      key: "Employee Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Job Title",
      key: "Employee Job Title",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Department",
      key: "Employee Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Location",
      key: "Employee Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee W2 Wages",
      key: "Employee W2 Wages",
      validators: [{ validate: "required" }],
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
    {
      label: "Notes",
      key: "Notes",
    },
  ],
  "Projects Intelligent Import": [
    {
      label: "Project ID Number",
      key: "ID Number",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Name",
      key: "Project Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Description",
      key: "Project Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Uncertainty",
      key: "Project Uncertainty",
    },
    {
      label: "Project Experimentation",
      key: "Project Experimentation",
    },
    {
      label: "Project Department",
      key: "Project Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Location",
      key: "Project Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Cost",
      key: "Project Cost",
      validators: [{ validate: "required" }],
    },
  ],
  "Contract Research Intelligent Import": [
    {
      label: "Contractor Account Number",
      key: "Account Number",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Name",
      key: "Contractor Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Description",
      key: "Contractor Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Department",
      key: "Contractor Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Location",
      key: "Contractor Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Type",
      key: "Contractor Type",
      type: "select",
      options: [
        { value: "", label: "" },
        { value: "Hourly", label: "Hourly", alternates: ["hourly"] },
        { value: "FF", label: "FF", alternates: ["ff"] },
        { value: "T&M", label: "T&M", alternates: ["t&m"] },
      ],
    },
    {
      label: "Contractor Cost",
      key: "Contractor Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
  "Supply Intelligent Import": [
    {
      label: "Supply Account Number",
      key: "Account Number",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Name",
      key: "Supply Account Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Description",
      key: "Supply Account Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Department",
      key: "Supply Account Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Location",
      key: "Supply Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Cost",
      key: "Supply Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
};
