import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { XIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { useNotifyPractitionerMutation } from "../../../api/engagmentAPI";
import {
  UploadGaugeColumn,
  UploadPortalLoadingScreen,
  SubmittedModal,
} from "./components";
import { useParams } from "react-router-dom";
import { useGetDirectConnectAccountsMutation } from "../../../api/directConnectAPI";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompute } from "../../../rtk/R&D/Compute/useCompute";
import CodatModal from "../Codat/CodatModal";
import { isDev, classNames } from "../utils";
import { DocumentUpload } from "./Documentation";
import { WageUpload } from "./WageUpload";
import { IntelligentImportContainer } from "./IntelligentImport";
import { TaxpayerProfile } from "./TaxpayerProfile";
import { AccountingUpload } from "./AccountingUpload";
import { DocumentLibrary } from "./DocumentLibrary";
import { ThumbUpIcon as OutlinedThumbUpIcon } from "@heroicons/react/outline";
import { ThumbUpIcon } from "@heroicons/react/solid";
import { ArrowLeftIcon } from "@heroicons/react/outline";

const UploadPortal = (props) => {
  const user = useSelector(({ auth }) => auth.user);
  const previousState = useRef(false);
  const params = useParams();
  const {
    engagementData,
    refetchEngagementData,
    isLoadingEngagementData,
    updateEngagementData,
    status,
    markAsTracked,
    isUpdatingEngagementData,
  } = useEngagementData();
  const { onSubmitUpload, isComputing } = useCompute();
  const navigate = useNavigate();

  const [completePercentage, setCompletePercentage] = useState(0);
  const [submitActive, setSubmitActive] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [closeVisible, setCloseVisible] = useState(false);
  const [codatRedirect, setCodatRedirect] = useState(false);
  const [accounts, setAccounts] = useState([]);
  const [codatCompanyID, setCodatCompanyID] = useState("");
  const [showSubmitMessage, setSubmitMessage] = useState(true);
  const [tracker, setTracker] = useState(engagementData?.tracker);

  const [getDirectConnectAccounts, { isLoading: isLoadingAccounts }] =
    useGetDirectConnectAccountsMutation();

  const [notifyPractitioner, { isLoading: isNotifyingPractitioner }] =
    useNotifyPractitionerMutation();

  // Get Engagement Status
  useEffect(() => {
    const projects = status["Projects"];
    const wages = status["Wages"];
    const profile = status["Taxpayer Profile"];

    // Update Completion Total
    let completionTotal = 0;
    if (projects) completionTotal += 33;
    if (wages) completionTotal += 33;
    if (profile) completionTotal += 34;
    setCompletePercentage(completionTotal);

    // Update Submit Status
    setSubmitActive(projects && wages && profile);
  }, [status]);

  // Get Codat Accounts on Redirect
  useEffect(() => {
    if (typeof params.companyId !== "undefined") {
      setCodatCompanyID(params.companyId);
      async function getAccounts() {
        const accounts = await getDirectConnectAccounts({
          CompanyID: params.companyId,
        });
        return accounts.data.accountResults;
      }
      getAccounts()
        .then((accounts) => {
          setAccounts(accounts);
          setCodatRedirect(true);
          // Reset route history to home to avoid URL param confusion.
          // props.history.push("/");
        })
        .catch((err) => {
          console.error(err);
          setCodatRedirect(false);
          navigate("/");
        });
    } else {
      // navigate("/");
    }
  }, [getDirectConnectAccounts, navigate, params.companyId, props.history]);

  const onSubmit = async () => {
    const hasApprovalPermissions = user?.role === "admin";

    if (hasApprovalPermissions) {
      await onSubmitUpload();
    } else {
      const notifyPractitionerResponse = await notifyPractitioner({
        body: {
          engagementName: engagementData.engagementName,
          email: user.email,
          phoneNumber: "N/A",
          userName: user.fullName,
          userID: user.userID,
          companyName: engagementData.companyName,
          companyID: engagementData.companyID,
        },
      });
      if (isDev) console.log({ notifyPractitionerResponse });
    }

    const body = {
      status: {
        ...engagementData.status,
        "portal-submitted": {
          complete: true,
        },
        "portal-approved": {
          complete: hasApprovalPermissions,
        },
      },
      tracker: {
        ...engagementData.tracker,
        "upload-complete": {
          status: "COMPLETE",
          uploadedBy: user.fullName,
          lastUpdated: Date.now(),
        },
      },
    };
    const submitResponse = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body,
    });
    if (isDev) console.log({ submitResponse });
  };

  const TrackerIcon = () => {
    const uploadKey = `upload-${selectedID.key}`;
    const uploadStatus = tracker?.[uploadKey]?.status;

    const handleIncompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [uploadKey]: {
          status: "COMPLETE",
        },
      }));
      await markAsTracked("COMPLETE", uploadKey);
    };

    const handleCompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [uploadKey]: { status: "INCOMPLETE" },
      }));
      await markAsTracked("INCOMPLETE", uploadKey);
    };

    return (
      <motion.div className="mr-8">
        {isUpdatingEngagementData ? (
          <div>
            <div className="my-2 ml-2 h-2 w-4 animate-pulse rounded bg-sprxGrey"></div>
          </div>
        ) : (
          <>
            {!tracker?.hasOwnProperty(uploadKey) ||
            uploadStatus === "INCOMPLETE" ? (
              <OutlinedThumbUpIcon
                className="h-6 w-6 text-gray-400"
                onClick={handleIncompleteClick}
              />
            ) : (
              <ThumbUpIcon
                className="h-6 w-6 text-sprxGreen"
                onClick={handleCompleteClick}
              />
            )}
          </>
        )}
      </motion.div>
    );
  };

  useEffect(() => {
    if (open) previousState.current = true;

    async function run() {
      previousState.current = false;
      refetchEngagementData();
    }

    const runEffect = previousState.current === true && open === false;
    if (runEffect) run();
  }, [open, refetchEngagementData]);

  const showLoadingScreen =
    isLoadingEngagementData ||
    isLoadingAccounts ||
    isComputing ||
    isNotifyingPractitioner;

  if (showLoadingScreen) return <UploadPortalLoadingScreen />;

  return (
    <>
      <main className="flex-1 lg:overflow-hidden">
        <div className="">
          {/*Top bar and logo */}

          <div className="flex max-w-7xl flex-row items-center justify-between px-4">
            <div className="flex flex-row items-center">
              <button
                onClick={() => {
                  props.setActivePortalType();
                }}
                className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
              >
                <ArrowLeftIcon className="h-8 w-8" />
              </button>
              <h1 className="ml-2 text-2xl font-semibold text-gray-900">
                {engagementData.engagementName}
              </h1>
            </div>

            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="px-4">
            {/*Welcome message with icon and submit box*/}
            <div className="flex w-full flex-col justify-between py-4 lg:flex-row">
              <div className="flex flex-col rounded-2xl bg-sprxClientPortalLightTeal p-4 lg:w-8/12">
                <h1 className="text-xl font-semibold text-gray-900">
                  Welcome {user.fullName.split(" ")[0]}!
                </h1>
                <p className="mt-2 text-sm text-gray-900">
                  You've entered {completePercentage}% of the data needed to
                  submit your credit! At a minimum, we need you to complete the
                  taxpayer profile, upload wages, and enter at least one reseach
                  project. Of course, the more information you enter now, the
                  faster we'll complete your credit!
                </p>
              </div>
              {/* <div className="-ml-64 flex">
                <img
                  style={{ width: 410.25, height: 136.125 }}
                  src={"assets/images/Dashboard_Illustration.svg"}
                  alt="SPRX.tax"
                />
              </div> */}
              {/*Conditional submit box */}
              {submitActive ? (
                <motion.div
                  className="mt-4 flex flex-col items-center justify-center rounded-2xl border-2 border-sprxClientPortalDarkGreen p-4 pt-6 shadow-xl lg:mt-0 lg:ml-4 lg:w-4/12"
                  whileHover={{ scale: 1.01 }}
                >
                  <h1 className="text-center text-xl font-semibold text-gray-900">
                    Your tax credit can now be submitted!
                  </h1>
                  <motion.button
                    type="button"
                    className="focus:ring-none mt-2 inline-flex w-1/2 items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={async () => await onSubmit()}
                  >
                    Submit
                  </motion.button>
                </motion.div>
              ) : null}
            </div>

            <div className="w-full pb-8 lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="mb-4 lg:col-span-3 lg:mb-0">
                {/*Left column */}
                <UploadGaugeColumn
                  completePercentage={completePercentage}
                  engagementData={engagementData}
                />
              </div>
              {/*Right column */}
              <div className="col-span-9 flex w-full">
                <div className="grid w-full grid-cols-2 gap-4 lg:grid-cols-3">
                  {[
                    {
                      id: 1,
                      title: "Taxpayer Profile",
                      key: "elections",
                      description:
                        "Complete this short profile. Last year's tax return may be helpful.",
                      component: (
                        <TaxpayerProfile
                          onClose={() => {
                            setOpen(false);
                            setSelectedID(null);
                          }}
                          recomputeCredit={false}
                        />
                      ),
                      complete: status["Taxpayer Profile"],
                      hideCloseIcon: true,
                      statusTracker: true,
                    },
                    {
                      id: 2,
                      key: "wages",
                      title: "Wages",
                      description:
                        "Select the most convenient option to upload your wages.",
                      component: (
                        <WageUpload
                          engagementData={engagementData}
                          onClose={() => {
                            setCloseVisible(true);
                            setOpen(false);
                            setSelectedID(null);
                          }}
                          user={user}
                        />
                      ),
                      complete: status["Wages"],
                      hideCloseIcon: false,
                      statusTracker: true,
                    },
                    {
                      id: 3,
                      key: "projects",
                      title: "Projects",
                      description:
                        "Upload your research projects. You need at least one.",
                      component: (
                        <IntelligentImportContainer
                          engagementData={engagementData}
                          type={"PROJECTS"}
                          user={user}
                        />
                      ),
                      complete: status["Projects"],
                      hideCloseIcon: false,
                      statusTracker: true,
                    },
                    {
                      id: 4,
                      title: "Accounting",
                      description:
                        "Select the most convenient option to load your accounting data.",
                      component: (
                        <AccountingUpload
                          engagementData={engagementData}
                          onClose={() => {
                            setCloseVisible(true);
                            setOpen(false);
                            setSelectedID(null);
                          }}
                          markAsTracked={markAsTracked}
                          user={user}
                        />
                      ),
                      complete: status["Accounting"],
                      hideCloseIcon: false,
                      statusTracker: false,
                    },
                    {
                      id: 5,
                      title: "Documents",
                      description:
                        "Upload documents related to your research activities.",
                      component: (
                        <DocumentUpload
                          engagementData={engagementData}
                          user={user}
                        />
                      ),
                      complete: status["Documents"],
                      hideCloseIcon: false,
                      statusTracker: false,
                    },
                    {
                      id: 6,
                      title: "Library",
                      description: "See everything that you've uploaded here.",
                      component: (
                        <DocumentLibrary
                          engagementData={engagementData}
                          user={user}
                        />
                      ),
                      complete: false,
                      hideCloseIcon: false,
                      statusTracker: false,
                    },
                  ].map((item) => (
                    <motion.div
                      key={item.title}
                      whileHover={{ scale: 1.01 }}
                      layoutId={item.id}
                      className={
                        "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                      }
                    >
                      <motion.div
                        className={classNames(
                          item.complete && "bg-sprxClientPortalLightGreen",
                          "flex flex-1 flex-col items-center justify-center bg-white p-4 pt-6 xl:py-4",
                        )}
                      >
                        <motion.h3 className="text-center font-semibold text-gray-900 xl:mb-6 xl:text-xl">
                          {item.title}
                        </motion.h3>
                        {item.complete ? (
                          <CheckCircleIcon className="mt-1 h-12 w-12 flex-shrink-0 text-sprxClientPortalDarkGreen 2xl:h-20 2xl:w-20" />
                        ) : (
                          <motion.p className="text-text-gray-900 mt-1 text-center text-xs font-light tracking-tight xl:text-sm">
                            {item.description}
                          </motion.p>
                        )}

                        <motion.button
                          className={classNames(
                            item.complete
                              ? "bg-sprxGrey hover:bg-sprxTaxGray"
                              : "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                            "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-3/4",
                          )}
                          data-test={`Open ${item.title}`}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                          onClick={() => {
                            setOpen(true);
                            setSelectedID(item);
                            if (item.title !== "Taxpayer Profile") {
                              setCloseVisible(true);
                            }
                          }}
                        >
                          {item.complete ? "Make Changes" : "Start"}
                        </motion.button>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        {codatRedirect ? (
          <CodatModal
            onClick={(nav, data) => console.log("Legacy Redirect")}
            setCodatRedirect={setCodatRedirect}
            accounts={accounts}
            uploadProgress={false}
            companyID={codatCompanyID}
            engagementID={user.data.activeEngagementID}
            engagementData={engagementData}
          />
        ) : null}
        {/*Modal component */}
        {status.Submitted === true && showSubmitMessage ? (
          <SubmittedModal
            setSubmitMessage={setSubmitMessage}
            submitted={status["Submitted"]}
          />
        ) : null}
        {/* Open Action Card */}
        <AnimatePresence>
          {open && selectedID && (
            <Dialog
              className="relative z-40"
              static
              layoutId={selectedID.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              as={motion.div}
              open={open}
              onClose={setOpen}
            >
              <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

              <motion.div className="fixed inset-0 z-10 overflow-y-auto">
                <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  {/*Modal content */}
                  <Dialog.Panel className="relative min-h-94vh min-w-95vw max-w-95vw transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                    <motion.div>
                      {/*Modal title, description, and close button */}
                      <motion.div className="absolute top-0 right-8 hidden pt-4 pr-4 sm:block">
                        {selectedID.statusTracker ? <TrackerIcon /> : ""}
                      </motion.div>
                      {closeVisible && (
                        <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <motion.button
                            type="button"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => {
                              setOpen(false);
                              setSelectedID(null);
                              setCloseVisible(false);
                            }}
                          >
                            <XIcon
                              className="h-6 w-6"
                              aria-hidden={selectedID.hideCloseIcon}
                            />
                          </motion.button>
                        </motion.div>
                      )}

                      <motion.div className="text-center sm:mt-5">
                        <motion.div className="flex flex-row items-center justify-between">
                          <Dialog.Title
                            as="h3"
                            className="p-4 text-left text-3xl font-medium leading-6 text-gray-900"
                          >
                            {selectedID.title}
                          </Dialog.Title>
                        </motion.div>
                        <motion.div className="">
                          <motion.p className="px-4 py-2 text-left text-sm text-gray-500">
                            {selectedID.description}
                          </motion.p>
                        </motion.div>
                      </motion.div>
                      <motion.div className="mb-16">
                        {/*Modal main content */}
                        {selectedID.component}
                      </motion.div>
                    </motion.div>
                  </Dialog.Panel>
                </motion.div>
              </motion.div>
            </Dialog>
          )}
        </AnimatePresence>
      </main>
    </>
  );
};

export default UploadPortal;
