export const getDefaultFormFields = (engagementData) => {
  let election_2c = false;
  if (
    engagementData?.creditEligibilityData?.completedAnalysis?.hasOwnProperty(
      "280CElection",
    )
  ) {
    election_2c =
      engagementData?.creditEligibilityData?.completedAnalysis[
        "280CElection"
      ] === "yes"
        ? true
        : false;
  }
  return {
    entityName: engagementData?.calculation?.entityName,
    entityEIN: engagementData?.calculation?.entityEIN,
    entityType: engagementData?.calculation?.entityType,
    naicsCode: engagementData?.calculation?.naicsCode,
    businessDescription: engagementData?.calculation?.businessDescription,
    controlledGroup: engagementData?.calculation?.controlledGroup
      ? engagementData.calculation.controlledGroup === "yes"
      : false,
    controlledGroupFiling: engagementData?.calculation?.controlledGroupFiling,
    method: engagementData?.calculation?.method,
    "280CElection": election_2c,
    QSB:
      engagementData.creditEligibilityData?.completedAnalysis?.qsb === "yes"
        ? true
        : false,
    BRP:
      engagementData.creditEligibilityData?.completedAnalysis?.basicResearch ===
      "yes"
        ? true
        : false,
    brPMT:
      engagementData.creditEligibilityData?.completedAnalysis?.brPayment?.brPMT,
    brBase:
      engagementData.creditEligibilityData?.completedAnalysis?.brPayment
        ?.brBase,
    ECP:
      engagementData.creditEligibilityData?.completedAnalysis
        ?.energyConsortia === "yes"
        ? true
        : false,
    ecPMT:
      engagementData.creditEligibilityData?.completedAnalysis?.ecPayment?.ecPMT,
    acquisition:
      engagementData.creditEligibilityData?.completedAnalysis?.acquisition ===
      "yes"
        ? true
        : false,
    disposition:
      engagementData.creditEligibilityData?.completedAnalysis?.disposition ===
      "yes"
        ? true
        : false,
    shortYear:
      engagementData.creditEligibilityData?.completedAnalysis?.shortYear ===
      "yes"
        ? true
        : false,
    line8932: engagementData?.calculation?.form8932,
    creditK1: engagementData?.calculation?.creditK1,
    beneficiariesCredit:
      engagementData?.calculation?.creditBeneficiariesEstateTrust,
    ascPY1:
      engagementData.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE
        ?.py1,
    ascPY2:
      engagementData.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE
        ?.py2,
    ascPY3:
      engagementData.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE
        ?.py3,
    FBPercentage:
      engagementData.creditEligibilityData?.completedAnalysis?.FBPercentage,
    py1GR:
      engagementData.creditEligibilityData?.completedAnalysis?.PYFourYearGR
        ?.py1,
    py2GR:
      engagementData.creditEligibilityData?.completedAnalysis?.PYFourYearGR
        ?.py2,
    py3GR:
      engagementData.creditEligibilityData?.completedAnalysis?.PYFourYearGR
        ?.py3,
    py4GR:
      engagementData.creditEligibilityData?.completedAnalysis?.PYFourYearGR
        ?.py4,
  };
};
