import { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { XIcon, UserIcon, BeakerIcon } from "@heroicons/react/outline";
import { ClosedActionCard } from "../UploadPortal/components";
import { ReviewGaugeColumn } from "./components";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { WageCard } from "./Wages/WageCard";
import { AllocationsCard } from "./Allocations";
import { AccountingCard } from "./Accounting";
import { ProjectsCard } from "./Projects";
import { DocumentsCard } from "./Documents";
import { TaxpayerProfile } from "../UploadPortal/TaxpayerProfile";
import { SurveyCard } from "./Survey";
import { isDev } from "../utils";
import { useCompute } from "../../../rtk/R&D/Compute/useCompute";
import { useDataArray } from "../../../rtk/R&D/DataArray/useDataArray";
import { ThumbUpIcon as OutlinedThumbUpIcon } from "@heroicons/react/outline";
import { ThumbUpIcon } from "@heroicons/react/solid";
import { useGenerateDeliverablesMutation } from "../../../api/engagmentAPI";
import { useSelector } from "react-redux";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { StateCreditsContainer } from "../StateCredits/StateCreditsContainer";

export const ReviewPortal = ({ setActivePortalType }) => {
  const {
    engagementData,
    refetchEngagementData,
    markAsTracked,
    isUpdatingEngagementData,
    updateEngagementData,
  } = useEngagementData();
  const {
    computeTaxRDCredit,
    computeTaxStateCredits,
    isComputingFederalCredit,
    isComputingStateCredit,
  } = useCompute();
  const { isDownloadingFile } = useDataArray();

  const [open, setOpen] = useState(false);
  const previousState = useRef(false);
  const [tracker, setTracker] = useState(engagementData?.tracker);
  const [selectedID, setSelectedID] = useState(null);
  const [closeVisible, setCloseVisible] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [viewStateCredits, setViewStateCredits] = useState(false);
  const user = useSelector(({ auth }) => auth.user);
  const [generateDeliverables] = useGenerateDeliverablesMutation();

  // Compute On Modal Close
  useEffect(() => {
    if (open) previousState.current = true;

    async function run() {
      previousState.current = false;

      const fedComputeResponse = await computeTaxRDCredit();
      if (isDev) console.log({ fedComputeResponse });

      const stateComputeResponse = await computeTaxStateCredits();
      if (isDev) console.log({ stateComputeResponse });
    }

    const runEffect = previousState.current === true && open === false;
    if (runEffect) run();
  }, [computeTaxRDCredit, computeTaxStateCredits, open]);

  const isLoading =
    isComputingFederalCredit || isComputingStateCredit || isDownloadingFile;

  const TrackerIcon = () => {
    const reviewKey = `review-${selectedID.key}`;
    const reviewStatus = tracker?.[reviewKey]?.status;

    const handleIncompleteOrInProgressClick = async () => {
      const markAsValue =
        reviewStatus === "INCOMPLETE" ? "IN_PROGRESS" : "COMPLETE";
      setTracker((prevTracker) => ({
        ...prevTracker,
        [reviewKey]: {
          status: markAsValue,
        },
      }));
      await markAsTracked(markAsValue, reviewKey);
    };

    const handleCompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [reviewKey]: { status: "INCOMPLETE" },
      }));
      await markAsTracked("INCOMPLETE", reviewKey);
    };

    return (
      <motion.div className="mr-8">
        {isUpdatingEngagementData ? (
          <div>
            <div className="my-2 ml-2 h-2 w-4 animate-pulse rounded bg-sprxGrey"></div>
          </div>
        ) : (
          <div>
            {!tracker?.hasOwnProperty(reviewKey) ||
            reviewStatus === "INCOMPLETE" ||
            reviewStatus === "IN_PROGRESS" ? (
              <OutlinedThumbUpIcon
                className={`h-6 w-6 ${
                  reviewStatus === "INCOMPLETE"
                    ? "text-gray-400"
                    : "text-sprxYellow"
                }`}
                onClick={handleIncompleteOrInProgressClick}
              />
            ) : (
              <ThumbUpIcon
                className="h-6 w-6 text-sprxGreen"
                onClick={handleCompleteClick}
              />
            )}
          </div>
        )}
      </motion.div>
    );
  };
  const generateDocuments = async () => {
    setIsGenerating(true);
    setTimeout(() => {
      refetchEngagementData();
      setIsGenerating(false);
    }, 60000);

    const body = { engagementID: engagementData.engagementID, isDraft: true };
    const updateEngagementBody = {
      tracker: {
        ...engagementData.tracker,
        "review-complete": {
          status: "COMPLETE",
          uploadedBy: user.fullName,
          lastUpdated: Date.now(),
        },
      },
    };
    await generateDeliverables(body);
    await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: updateEngagementBody,
    });
  };

  return (
    <>
      {/*Mobile side bar menu*/}
      <main className="flex-1">
        <div className={`flex-1 ${user.role === "client" ? "pt-6" : ""}`}>
          {/*Top bar and logo */}

          <div className="flex flex-row items-center justify-between px-4">
            <div className="flex flex-row items-center">
              <button
                onClick={() => {
                  setActivePortalType();
                }}
                className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
              >
                <ArrowLeftIcon className="h-8 w-8" />
              </button>
              <h1 className="ml-2 text-2xl font-semibold text-gray-900">
                {engagementData.engagementName} Review
              </h1>
            </div>

            <div className="flex flex-row items-center justify-center">
              <motion.button
                type="button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="focus:ring-none mr-4 inline-flex w-3/4 min-w-150 items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
                onClick={() =>
                  setViewStateCredits(viewStateCredits ? false : true)
                }
              >
                {viewStateCredits ? "Hide State Credits" : "State Credits"}
              </motion.button>
              <motion.button
                type="button"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className="focus:ring-none mr-4 inline-flex w-3/4 min-w-150 items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
                onClick={() => generateDocuments()}
              >
                {isGenerating || false ? (
                  <div className="flex h-5 w-full flex-col items-center justify-center">
                    <p
                      className={
                        "h-2 w-full animate-pulse rounded-md bg-gray-100"
                      }
                    ></p>
                  </div>
                ) : (
                  <>
                    {engagementData.status["portal-deliverables"]?.complete ===
                    true
                      ? "Re-generate Reports"
                      : "Generate Reports"}
                  </>
                )}
              </motion.button>
              <img
                style={{ width: 85, height: 36 }}
                src={"assets/images/logos/Logo@SVG.svg"}
                alt="SPRX.tax"
              />
            </div>
          </div>
          {viewStateCredits ? (
            <div>
              <StateCreditsContainer />
            </div>
          ) : (
            <div className="grid min-h-90vh w-full grid-cols-1 p-4 lg:grid-cols-4 lg:gap-4">
              <ReviewGaugeColumn height="85vh" />
              <div className="col-span-3 flex w-full">
                <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3">
                  {[
                    {
                      id: 1,
                      title: "Elections",
                      description: "See what’s driving the calculations.",
                      extendedDescription: "",
                      icon: <BeakerIcon />,
                      component: (
                        <TaxpayerProfile
                          onClose={() => {
                            setOpen(false);
                            setSelectedID(null);
                          }}
                          recomputeCredit={true}
                        />
                      ),
                      hideCloseIcon: true,
                    },
                    {
                      id: 2,
                      title: "Projects",
                      description: "Check out what our AI wrote.",
                      extendedDescription: "",
                      component: <ProjectsCard />,
                      hideCloseIcon: false,
                    },

                    {
                      id: 3,
                      key: "allocation",
                      title: "Allocations",
                      description: "Tell us who worked on each project.",
                      extendedDescription: "",
                      component: <AllocationsCard />,
                      hideCloseIcon: false,
                      statusTracker: true,
                    },
                    {
                      id: 4,
                      title: "Wages",
                      key: "qre",
                      description: "Adjust employee Q% if you’d like.",
                      extendedDescription: "",
                      component: <WageCard />,
                      icon: <UserIcon />,
                      hideCloseIcon: false,
                      statusTracker: true,
                    },
                    {
                      id: 5,
                      title: "Supply",
                      description: "Be sure you have all the supplies.",
                      extendedDescription: "",
                      component: <AccountingCard type={"SUPPLY_REVIEW"} />,
                      hideCloseIcon: false,
                    },
                    {
                      id: 6,
                      title: "Contract Research",
                      description: "These folks can increase your credit.",
                      extendedDescription: "",
                      component: <AccountingCard type={"CONTRACT_REVIEW"} />,
                      hideCloseIcon: false,
                    },
                    {
                      id: 7,
                      title: "Computer Lease",
                      description: "Be sure you found all the servers.",
                      extendedDescription: "",
                      component: <AccountingCard type={"COMPUTER_REVIEW"} />,
                      hideCloseIcon: false,
                    },
                    {
                      id: 8,
                      title: "Documents",
                      description:
                        "Be sure you’ve included the best documents.",
                      extendedDescription: "",
                      component: <DocumentsCard />,
                      hideCloseIcon: false,
                    },
                    {
                      id: 9,
                      title: "SME Survey",
                      description: "If you are not sure, ask someone.",
                      extendedDescription: "",
                      component: <SurveyCard />,
                      hideCloseIcon: false,
                    },
                  ].map((item) => (
                    <ClosedActionCard
                      key={`${item.title}-review-action-card`}
                      item={item}
                      setOpen={setOpen}
                      setSelectedID={setSelectedID}
                      setCloseVisible={setCloseVisible}
                      isLoading={isLoading}
                    />
                  ))}
                </div>
              </div>
            </div>
          )}
        </div>
        {/*Modal component */}
        <AnimatePresence>
          {open && selectedID && (
            <Dialog
              className="relative z-40"
              static
              layoutId={selectedID.id}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              as={motion.div}
              open={open}
              onClose={setOpen}
            >
              <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

              <motion.div className="fixed inset-0 z-10 overflow-y-auto">
                <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  {/*Modal content */}
                  <Dialog.Panel className="relative min-w-80vw max-w-95vw transform overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all">
                    <motion.div>
                      {/*Modal title, description, and close button */}
                      <motion.div className="absolute top-0 right-8 hidden pt-4 pr-4 sm:block">
                        {selectedID.statusTracker ? <TrackerIcon /> : ""}
                      </motion.div>
                      {closeVisible && (
                        <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                          <motion.button
                            type="button"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                            onClick={() => {
                              setOpen(false);
                              setSelectedID(null);
                              setCloseVisible(false);
                            }}
                          >
                            <XIcon
                              className="h-6 w-6"
                              aria-hidden={selectedID.hideCloseIcon}
                            />
                          </motion.button>
                        </motion.div>
                      )}

                      <motion.div className="text-center sm:mt-5">
                        <motion.div className="flex flex-row items-center justify-between">
                          <Dialog.Title
                            as="h3"
                            className="p-4 text-left text-3xl font-medium leading-6 text-gray-900"
                          >
                            {selectedID.title}
                          </Dialog.Title>
                        </motion.div>
                        <motion.div className="">
                          <motion.p className="px-4 py-2 text-left text-sm text-gray-500">
                            {selectedID?.extendedDescription}
                          </motion.p>
                        </motion.div>
                      </motion.div>
                      <motion.div className=" ">
                        {/*Modal main content */}
                        {selectedID.component}
                      </motion.div>
                    </motion.div>
                  </Dialog.Panel>
                </motion.div>
              </motion.div>
            </Dialog>
          )}
        </AnimatePresence>
      </main>
    </>
  );
};
