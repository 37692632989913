export const DocumentLibraryLoading = () => {
    return (
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="mt-8 flex flex-col">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
                <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6">
                          Name
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Type
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Uploaded By
                        </th>
                        <th scope="col" className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900">
                          Upload Date
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Download</span>
                        </th>
                        <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Delete</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                      {Array.from(Array(12))?.map((item, index) => (
                            <tr key={`doc-table-loading-${index}`}>
                                <td className="px-3 py-4">
                                        <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                                </td>
                                <td className="px-3 py-4">
                                        <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                                </td>
                                <td className="px-3 py-4">
                                        <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                                </td>
                                <td className="px-3 py-4">
                                        <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                                </td>
                            </tr>                        
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
}