import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Cookies } from "react-cookie";
import { useNavigate, useLocation } from "react-router-dom";

import Lottie from "lottie-react";

import AuthContext from "./AuthContext";

import { setUserData, userLoggedOut } from "./rtk/userSlice";

import {
  useGetSessionMutation,
  useGetLoginCodeMutation,
  useLoginWithCodeMutation,
  useLogoutMutation,
} from "../api/loginAPI";

import { useGetTaxUserMutation } from "../api/userAPI";

import * as animationData from "../ui-components/loadingAnimation.json";
import { isDev } from "../main/R&D/utils";

const cookies = new Cookies();

export const AuthProvider = ({ children }) => {
  const dispatch = useDispatch();

  const navigate = useNavigate();

  const location = useLocation();

  const [token, setToken] = useState(null);

  const [waitAuthCheck, setWaitAuthCheck] = useState(true);

  const [getUser] = useGetTaxUserMutation();

  const [getSession] = useGetSessionMutation();

  const [getLoginCode] = useGetLoginCodeMutation();

  const [loginWithCode] = useLoginWithCodeMutation();

  const [logoutUser] = useLogoutMutation();

  useEffect(() => {
    async function sessionCheck() {
      const userRes = await sessionIDCheck();
      return userRes;
    }

    sessionCheck().then((result) => {
      if (result?.data?.userID) {
        setToken(result?.data?.userID);

        let origin = location?.pathname || "/";

        if (origin === "/login" || origin === "/") {
          if (
            result?.data?.role === "admin" ||
            result?.data?.role === "sales"
          ) {
            origin = "/tracker";
          } else {
            origin = "/dataspaces";
          }
        }

        setWaitAuthCheck(false);

        navigate(origin, { replace: true });
      } else {
        setWaitAuthCheck(false);

        navigate("/login");
      }
    });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function sessionIDCheck() {
    //get session-id cookie
    const sessionID = cookies.get("session-id");

    if (typeof sessionID === "undefined") {
      return "No Active Session";
    } else {
      // check if session-id is valid. If yes, return userID asscociated with session-id. If no, return error
      try {
        const res = await getSession({ sessionID });

        // If session-id is valid and userID is returned, get the user info with the returned userID.
        // TODO: Add Sentry session data

        if (res?.data?.status === true && res?.data?.isActive) {
          const user = await getUser(res.data.userID);

          dispatch(setUserData(user.data));

          return user;
        }
      } catch (e) {
        throw e;
      }
    }
  }

  const handleLoginCode = async (model) => {
    try {
      const res = await getLoginCode({
        email: model.email,
      });

      if (res.error) {
        throw res.error;
      }

      if (res?.data?.body?.status === true) {
        return res?.data?.body?.status;
      }
    } catch (e) {
      throw e;
    }
  };

  const handleLogin = async (model) => {
    try {
      const res = await loginWithCode({
        email: model.email,
        password: model.code,
      });

      if (res.error) {
        throw res.error;
      }

      if (res?.data?.status === true) {
        setToken(res?.data?.userID);

        const user = await getUser(res.data.userID);

        dispatch(setUserData(user.data));

        let origin = location?.pathname || "/";

        if (origin === "/login") {
          if (user.data.role === "admin" || user.data.role === "sales") {
            origin = "/tracker";
          } else if (
            user.data.role === "certifier" ||
            user.data.role === "modeler"
          ) {
            origin = "/assignments";
          } else {
            origin = "/dataspaces";
          }
        }

        navigate(origin, { replace: true });
      } else {
        throw Error("There was an issue getting the user data");
      }
    } catch (e) {
      throw e;
    }
  };

  const handleLogout = async (user) => {
    setToken(null);
    const logoutResponse = await logoutUser({ userID: user.userID });
    if (isDev) console.log({ logoutResponse });
    if (logoutResponse.data.status === true) {
      dispatch(userLoggedOut());
      setToken(null);
    }
    cookies.remove("session-id");
  };

  const value = {
    token,
    onLoginCode: handleLoginCode,
    onLogin: handleLogin,
    onLogout: handleLogout,
  };

  return waitAuthCheck ? (
    <div className="bg-gradient-login flex min-h-screen flex-col content-center justify-center">
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: "10%", width: "10%", alignSelf: "center" }}
      />
      <h2 className="ml-4 mt-6 text-center text-3xl font-extralight text-white">
        Loading...
      </h2>
    </div>
  ) : (
    <AuthContext.Provider value={value}>{children}</AuthContext.Provider>
  );
};
