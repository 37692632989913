import { configureStore } from "@reduxjs/toolkit";
import { combineReducers } from "redux";
import auth from "../app/auth/rtk";
import { baseAPI } from "../app/api/baseAPI";

const reducer = combineReducers({
  auth,
  [baseAPI.reducerPath]: baseAPI.reducer,
});

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }).concat(baseAPI.middleware),
});

export default store;
