import * as React from "react";
import { classNames } from "../main/R&D/utils";

type Tab = {
  label: string;
  value: string;
};

type TabNavigatorProps = {
  tabs: Tab[];
  setCurrentTab: (tab: Tab) => void;
  currentTab: Tab;
};

function getWidth(tabs: Tab[]) {
  switch (tabs.length) {
    case 1:
      return "w-full";
    case 2:
      return "w-1/2";
    case 3:
      return "w-1/3";
    case 4:
      return "w-1/4";
    case 5:
      return "w-1/5";
    case 6:
      return "w-1/6";
    case 7:
      return "w-1/7";
    default:
      return "w-1/5";
  }
}

export const TabNavigator = ({
  tabs,
  setCurrentTab,
  currentTab,
}: TabNavigatorProps) => {
  return (
    <div className="flex w-full flex-row justify-evenly">
      <div className={`w-4/5`}>
        <div className="hidden sm:block">
          <div className="border-b border-gray-200">
            <nav
              className="-mb-px flex flex-row justify-center"
              aria-label="Tabs"
            >
              {tabs.map((tab: Tab) => (
                <div
                  onClick={() => setCurrentTab(tab)}
                  key={tab.value}
                  className={classNames(
                    tab.value === currentTab.value
                      ? "border-indigo-500 text-indigo-600"
                      : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                    "border-b-2 py-4 px-4 text-center text-sm font-medium",
                    getWidth(tabs),
                  )}
                >
                  {tab.label}
                </div>
              ))}
            </nav>
          </div>
        </div>
      </div>
    </div>
  );
};
