import classNames from "classnames";
import { RadioGroup } from "@headlessui/react";

const EngagmentPricing = (props) => {
  return (
    <RadioGroup value={props.selected} onChange={props.setSelected}>
      <RadioGroup.Label className="sr-only">
        Economic Information
      </RadioGroup.Label>
      <div className="flex flex-row space-x-4 justify-center mt-4">
        {props.plans.map((plan) => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            disabled={plan.enabled ? false : true}
            className={({ active }) =>
              classNames(
                active ? "" : "",
                plan.enabled
                  ? "bg-white"
                  : "bg-sprxGrey opacity-50 cursor-not-allowed",
                "relative block w-1/3 rounded-lg border border-gray-300 shadow-sm px-6 py-4 cursor-pointer hover:border-gray-400 sm:flex sm:justify-between focus:outline-none"
              )
            }
          >
            {({ checked }) => (
              <>
                <div className="flex items-center">
                  <div className="text-base space-y-2">
                    <RadioGroup.Label
                      as="p"
                      className="text-center font-medium text-xl text-sprxGrey"
                    >
                      {plan.name}
                    </RadioGroup.Label>
                    <RadioGroup.Description as="div" className="text-gray-500">
                      <div className="flex justify-center">
                        <img
                          src={plan.image}
                          alt={plan.name}
                          className="flex self-center h-52 w-52"
                        />
                      </div>

                      <div className="mt-4 mb-4 flex items-baseline text-2xl font-extrabold justify-center">
                        {plan.price}%
                        <span className="ml-1 text-base font-medium text-gray-500">
                          of total credit
                        </span>
                      </div>
                      <div className="">
                        <p className="sm:inline">{plan.description}</p>
                      </div>
                    </RadioGroup.Description>
                  </div>
                </div>

                <div
                  className={classNames(
                    checked ? "border-sprxBlue border-4" : "border-transparent",
                    "absolute -inset-px rounded-lg border-2 pointer-events-none"
                  )}
                  aria-hidden="true"
                />
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};

export default EngagmentPricing;
