import { PaperAirplaneIcon } from "@heroicons/react/solid";

export const NumberInput = ({setMessage}) => {
    return (
        <div className="flex w-full justify-center">
        <input
            className="bg-transparent focus:outline-none focus:border-none border-none focus:ring-0 flex-1 disabled:cursor-not-allowed disabled:text-gray-300"
            type="number"
            placeholder="Input a whole number between 0 and 100"
            onChange={(e) => setMessage(e.target.value)}
        />
        <button
            type="submit"
            className="bg-sprxClientPortalDarkGreen hover:opacity-50 font-bold text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
        >
            <PaperAirplaneIcon className="h-4 w-4 rotate-45" />
        </button>
        </div>
    );
};