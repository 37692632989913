import { ActivityItem } from "./components/ActivityItem";
import { CommentInputForm } from "./components/CommentInputForm";
import { FlatlistSelectionPane } from "../../ReviewPortal/components";
import { useState, useEffect } from "react";
interface CommentsProps {
  isAdmin: boolean;
  threads: any;
  height?: string;
  resolvedPanelItems: any;
  openPanelItems: any[];
  onDeleteComment: (commentID: string) => void;
  onAddNote: (commentID: string, comment: string) => void;
  onResolveComment: (commentID: string) => void;
  isAddingNote: boolean;
  isResolvingComment: boolean;
  isDeletingComment: boolean;
  onClickAddCallback?: () => void;
}

export const Comments = ({
  isAdmin,
  threads,
  height,
  onDeleteComment,
  onResolveComment,
  onAddNote,
  onClickAddCallback,
  openPanelItems,
  resolvedPanelItems,
  isAddingNote,
  isResolvingComment,
  isDeletingComment,
}: CommentsProps) => {
  const initialState = openPanelItems[0]?.commentID ?? undefined;
  const [selectedThread, setSelectedThread] = useState(initialState);

  useEffect(() => {
    if (openPanelItems.length > 0) {
      const newInitialState = openPanelItems[0].commentID;
      setSelectedThread(newInitialState);
    }
  }, [openPanelItems]);

  const viewedThread = [
    ...(threads?.find((thread: any) => {
      return thread[0]?.commentID === selectedThread;
    }) ?? []),
  ];

  const isOpen =
    viewedThread.length === 0 ? false : viewedThread[0].status !== "RESOLVED";
  const ID = viewedThread.length === 0 ? undefined : viewedThread[0].commentID;

  const h = height ?? "h-80vh";

  return (
    <div className="grid grid-cols-12 rows-1 gap-x-4 mt-2 h-full">
      {/* Nav List Display*/}
      <div className={`col-span-3 ${h} overflow-auto border rounded-lg`}>
        <FlatlistSelectionPane
          searchTitle={"Threads"}
          items={openPanelItems}
          setSelectedCallback={(item: any) => {
            setSelectedThread(item.commentID);
          }}
          selectedItem={selectedThread}
          nameKey={"title"}
          idKey={"commentID"}
          width={"w-full"}
          secondaryItems={resolvedPanelItems}
          secondaryItemsTitle={"Resolved"}
          primaryItemsTitle={"Open"}
          onClickAddCallback={onClickAddCallback}
        />
      </div>
      {/* Comment Thread Display*/}
      <div className={`col-span-9 ml-8 mt-2 ${h} overflow-auto`}>
        {viewedThread?.map((thread: any, parentIndex: number) => {
          const isLast = parentIndex === viewedThread?.length - 1;
          return (
            <div key={ID + parentIndex}>
              <div
                className={ID + parentIndex}
                key={ID + parentIndex + "activity-item"}
              >
                <ActivityItem activityItem={{ ...thread, isLast }} />
              </div>
            </div>
          );
        })}
        {isOpen ? (
          <div key={ID + "-input"}>
            <CommentInputForm
              isAddingNote={isAddingNote}
              commentID={ID}
              onAddNote={onAddNote}
              buttons={
                isAdmin ? (
                  <div className="w-full flex flex-row justify-center h-10">
                    <div className="flex flex-row justify-evenly h-10">
                      <button
                        onClick={async () => {
                          onDeleteComment(ID);
                        }}
                        className="w-20 px-4 h-10 mr-4 text-sm font-medium text-white bg-sprxClientPortalRed border border-transparent rounded-md shadow-sm hover:bg-sprxClientPortalDarkRed focus:outline-none"
                      >
                        {isDeletingComment ? (
                          <div className="animate-pulse mx-2 h-2 bg-white rounded w-8"></div>
                        ) : (
                          "Delete"
                        )}
                      </button>
                      <button
                        onClick={async () => onResolveComment(ID)}
                        className="w-20 px-4 h-10 text-sm font-medium text-white bg-indigo-600 border  border border-transparent rounded-md shadow-sm hover:bg-indigo-700  focus:outline-none"
                      >
                        {isResolvingComment ? (
                          <div className="animate-pulse mx-2 h-2 bg-white rounded w-8"></div>
                        ) : (
                          "Resolve"
                        )}
                      </button>
                    </div>
                  </div>
                ) : null
              }
            />
          </div>
        ) : null}
      </div>
    </div>
  );
};
