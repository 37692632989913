export const WelcomeMessage = ({ name }: { name: string }) => {
  return (
    <div className="flex w-full py-4">
      <div className="flex flex-col w-8/12 rounded-2xl p-4 bg-sprxClientPortalLightTeal">
        <h1 className="text-xl font-semibold text-gray-900">Welcome {name}!</h1>
        <p className="text-sm mt-2 pr-40 text-gray-900">
          We'll need you to update the building profile, upload some
          documentation, and send out an Allocation Letter if the building is
          government owned. You can also chat with your energy modeler and
          certifier to answer any questions they may have.
        </p>
      </div>
      <div className="flex -ml-64">
        <img
          style={{ width: 410.25, height: 136.125 }}
          src={"assets/images/Dashboard_Illustration.svg"}
          alt="SPRX.tax"
        />
      </div>
      {/*Conditional submit box */}
    </div>
  );
};
