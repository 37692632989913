import { BuildingProfile } from "../../../../types/buildingProfile";
// squareFeet: buildingProfile.squareFeet || "",
// buildingType: buildingProfile.buildingType || "",
// address: buildingProfile.address || "",
// systemsRelated: buildingProfile.systemsRelated || [],
// state: buildingProfile.state || "",
// buildingCost: buildingProfile.buildingCost || 0,
// ownerName: buildingProfile.ownerName || "",
// buildingName: buildingProfile.buildingName || "",
// meetsPrevailingWageRequirements:
//   buildingProfile.meetsPrevailingWageRequirements || false,
// meetsApprenticeshipReqirements:
//   buildingProfile.meetsApprenticeshipReqirements || false,

const isNotEmptyString = (value: string) => value.trim().length > 0;

export const hasRequiredBuildingProfileFields = (
  buildingProfile: BuildingProfile
) => {
  return (
    buildingProfile.squareFeet > 0 &&
    buildingProfile.buildingCost > 0 &&
    buildingProfile.systemsRelated.length > 0 &&
    isNotEmptyString(buildingProfile.buildingType) &&
    isNotEmptyString(buildingProfile.address) &&
    isNotEmptyString(buildingProfile.ownerName) &&
    isNotEmptyString(buildingProfile.state) &&
    buildingProfile.meetsPrevailingWageRequirements !== undefined &&
    buildingProfile.meetsApprenticeshipReqirements !== undefined
  );
};
