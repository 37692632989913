export const FlagIcon: React.FC<{ color: string }> = ({ color }) => (
  <div className="z-10">
    <div
      className="absolute top-0 right-4 w-0 h-0 border-t-[20px] border-l-[20px]"
      style={{
        borderTopColor: color,
        borderLeftColor: "transparent",
      }}
    />
    <div
      className="absolute top-2 right-4 w-0 h-0 border-t-[20px] border-l-[20px]"
      style={{
        borderTopColor: color,
        borderLeftColor: "transparent",
      }}
    />
    <div
      className="absolute top-0 right-5 w-0 h-0 border-t-[20px] border-l-[20px]  transform scale-x-[-1]"
      style={{
        borderTopColor: color,
        borderLeftColor: "transparent",
      }}
    />
    <div
      className="absolute top-2 right-5 w-0 h-0 border-t-[20px] border-l-[20px]  transform scale-x-[-1]"
      style={{
        borderTopColor: color,
        borderLeftColor: "transparent",
      }}
    />
  </div>
);
