export const AdminEngagementListLoading = () => {
  return (
    <main className="flex-1 lg:overflow-hidden">
      <div className="py-6">
        {/* Team member table */}
        <div className="px-4 sm:px-6 lg:px-8 mt-8">
          <div className="sm:flex sm:items-center">
            <div className="sm:flex-auto"></div>
          </div>
          <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <p className="animate-pulse bg-sprxGrey rounded h-2 w-" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <p className="animate-pulse bg-sprxGrey rounded h-2 w-" />
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <p className="animate-pulse bg-sprxGrey rounded h-2 w-" />
                  </th>
                  <th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                    <p className="animate-pulse bg-sprxGrey rounded h-2 w-" />
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Array.from(Array(10))?.map((item, index) => (
                  <tr key={index}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                      <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only sm:hidden">Name</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                    </td>

                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
};
