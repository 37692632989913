export const getCreateDataSpaceFormDefaultFields = ({
  company,
  currentYear,
}: {
  company: any;
  currentYear: number;
}) => {
  return {
    companyID: company.companyID,
    companyName: "",
    dataSpaceName: "",
    startYear: currentYear - 2,
    endYear: currentYear - 1,
    type: "RD",
  };
};
