import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  Button,
  SprxLoading,
  ModalCard,
  FinchButton,
  FlatlistButton,
  BottomButtonContainer,
} from "../../../ui-components";
import {
  useDecryptConnectServerCredentialsMutation,
  useGetConnectEngagementDataMutation,
  useUpdateConnectEngagementDataMutation,
  useConnectSentAdditionalPayrollMutation,
  useCheckConnectEmployeeLocationMutation,
  useSetConnectSessionIDMutation,
  useRemoveConnectSessionIDMutation,
} from "../../../api/sendConnectAPI";
import {
  ConnectError,
  ConnectIntroductionHeader,
  ConnectComplete,
  ConnectInactive,
} from "./components";

function ConnectHome(props) {
  const params = useParams();

  const [showLoading, setShowLoading] = useState(true);
  const [showError, setShowError] = useState(false);
  const [password, setPassword] = useState("");
  const [engagementData, setEngagementData] = useState(null);
  const [activeSurvey, setActiveSurvey] = useState({});

  const [
    decryptConnectServerCredentials,
    { isLoading: isDecryptingServerCredentials },
  ] = useDecryptConnectServerCredentialsMutation();

  const [setConnectSessionID, { isLoading: isSettingConnectSessionID }] =
    useSetConnectSessionIDMutation();

  const [removeConnectSessionID, { isLoading: isRemovingConnectSessionID }] =
    useRemoveConnectSessionIDMutation();

  const [
    getConnectEngagementData,
    { isLoading: isLoadingConnectEngagementData },
  ] = useGetConnectEngagementDataMutation();

  const [
    updateConnectEngagementData,
    { isLoading: isUpdatingConnectEngagementData },
  ] = useUpdateConnectEngagementDataMutation();

  const [checkConnectEmployeeLocation, { isLoading: isCheckingLocation }] =
    useCheckConnectEmployeeLocationMutation();

  const [
    connectAdditionalPayroll,
    { isLoading: isConnectingAdditionalPayroll },
  ] = useConnectSentAdditionalPayrollMutation();

  useEffect(() => {
    if (typeof params.cred !== "undefined") {
      setShowLoading(false);
    } else {
      setShowLoading(false);
    }
  }, [params.cred]);

  async function decryptCreds(password) {
    const creds = await decryptConnectServerCredentials({
      cred: params.cred,
      password: password,
    });

    if (creds?.error?.name === "Error") {
      setShowError(true);
    } else {
      var expDate = new Date(creds.data.expires);
      var currentDate = new Date();
      let currentCredentials;

      if (currentDate > expDate) {
        setShowError(true);
      } else {
        currentCredentials = creds.data.credentials;
        await setConnectSessionID({ sessionID: currentCredentials });
      }

      if (creds.data) {
        const engagementData = await getConnectEngagementData(
          creds.data.engagementID
        );

        setEngagementData(engagementData.data);
        const connectType =
          creds.data.type === "wages" ? "wageList" : "supplyList";
        console.log({connectType, creds, engagementData})


        const survey = engagementData?.data?.QREData[
          connectType
        ].connectLinks.filter((a) => a?.connectURL === window.location.href)[0];
        console.log({survey})
        setActiveSurvey({ ...survey });
      }
    }
  }

  const onFinchSuccess = async (code) => {

    await connectAdditionalPayroll({
      engagementID: engagementData.engagementID,
      body: { code },
    });

    await checkConnectEmployeeLocation({
      engagementID: engagementData.engagementID,
      body: {},
    });

    const connectType =
      activeSurvey.connectType === "wages" ? "wageList" : "supplyList";

    let survey = activeSurvey;

    survey.connectStatus = "done";

    const otherSurveys = engagementData?.QREData[
      connectType
    ].connectLinks.filter((a) => a?.connectURL !== window.location.href);

    const newSurveys = [survey, ...otherSurveys];

    let body = {
      [connectType]: {
        ...engagementData.QREData[connectType],
        connectLinks: newSurveys,
      },
    };

    const updateStatus =
      engagementData?.QREData.projectList.data.data.length > 0;

    if (updateStatus) {
      body.status = {
        ...engagementData.status,
        upload: {
          ...engagementData.status.upload,
          status: "complete",
        },
      };

      const updateUnlockStatus =
        engagementData?.status.eligibility.status === "complete";

      if (updateUnlockStatus) {
        body.status = {
          ...engagementData.status,
          ...body.status,
          calculate: {
            ...engagementData.status.calculate,
            locked: false,
          },
          states: {
            ...engagementData.status.states,
            locked: false,
          },
        };
      }
    }

    await updateConnectEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });

    setActiveSurvey({ ...survey });

    await removeConnectSessionID();
  };

  console.log({activeSurvey})

  if (
    showLoading ||
    isDecryptingServerCredentials ||
    isRemovingConnectSessionID ||
    isSettingConnectSessionID ||
    isLoadingConnectEngagementData ||
    isUpdatingConnectEngagementData ||
    isConnectingAdditionalPayroll ||
    isCheckingLocation
  ) {
    return (
      <ModalCard
        mainContent={
          <div className={"mt-4"}>
            <SprxLoading />
          </div>
        }
        bottomContent={
          <BottomButtonContainer
            className={"mt-16 items-center justify-between"}
          ></BottomButtonContainer>
        }
      />
    );
  } else {
    return (
      <ModalCard
        tour={false}
        title={
          activeSurvey?.connectStatus === "done" ||
          activeSurvey?.active !== true
            ? ""
            : activeSurvey.connectType === "wages"
            ? `Payroll Connect`
            : "Accounting System Direct Connect"
        }
        mainContent={
          activeSurvey?.connectStatus === "done" ? (
            <ConnectComplete />
          ) : activeSurvey?.active !== true &&
            Object.keys(activeSurvey).length !== 0 ? (
            <ConnectInactive />
          ) : engagementData ? (
            <div className="flex flex-col min-h-70vh items-center justify-start">
              <p className="mt-1 mb-8 text-sm text-center text-gray-500">
                {activeSurvey.connectType === "wages"
                  ? "Use the button below to connect directly to your payroll system via Payroll Connect."
                  : "Use the button below to connect directly to your accounting system via Accounting System Direct Connect."}
              </p>
              <ul className="mt-6 border-t border-b border-gray-200 divide-y divide-gray-200">
                {activeSurvey.connectType === "wages" ? (
                  <FinchButton onSuccess={onFinchSuccess}>
                    <FlatlistButton
                      title={"Payroll Connect"}
                      subtext={"Connect directly to your payroll system"}
                      status={false}
                      className={"direct-connect"}
                    />
                  </FinchButton>
                ) : null}
              </ul>
            </div>
          ) : (
            <div className={"flex flex-col items-center"}>
              <div className="w-full">
                <ConnectIntroductionHeader />
                <div className="">
                  <div className="mt-8">
                    <input
                      // type="password"
                      type="text"
                      name="password"
                      id="password"
                      readOnly={
                        typeof params.cred === "undefined" ? true : false
                      }
                      className="shadow-sm block w-full sm:text-sm border-gray-500 rounded-md focus:ring-transparent focus:border-gray-500 focus:outline-none"
                      value={password}
                      onChange={(event) => setPassword(event.target.value)}
                    />
                    {showError ? <ConnectError /> : null}
                  </div>
                </div>
              </div>
            </div>
          )
        }
        bottomContent={
          activeSurvey?.surveyStatus !== "done" ? (
            engagementData && activeSurvey?.active === true ? (
              <BottomButtonContainer
                className={"mt-16 items-center justify-end"}
              ></BottomButtonContainer>
            ) : (
              <BottomButtonContainer
                className={"mt-16 items-center justify-end"}
              >
                {typeof params.cred === "undefined" ||
                (activeSurvey?.active !== true &&
                  Object.keys(activeSurvey).length !== 0) ? (
                  <div></div>
                ) : (
                  <Button
                    primary
                    onClick={() => decryptCreds(password)}
                    disabled={password === ""}
                  >
                    Submit
                  </Button>
                )}
              </BottomButtonContainer>
            )
          ) : null
        }
      />
    );
  }
}

export default ConnectHome;
