import { Controller } from "react-hook-form";
import { motion } from "framer-motion";

export const CheckboxInputField = ({
  id,
  control,
  label,
  subtext,
  errors,
  ...rest
}) => {
  const span = rest?.spanOverride ? rest.spanOverride : "sm:col-span-12";
  const hasError = errors?.hasOwnProperty(id) ? true : false;
  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => (
        <div
          className={`${span} flex flex-row justify-between mb-2 `}
          data-test={`line-${id}-div`}
        >
          <div className="flex flex-col">
            <div className="flex flex-row">
              <label
                htmlFor={`${id}`}
                className="block text-sm font-medium text-gray-700"
              >
                {label}
              </label>
            </div>
            <p className="block text-sm font-sm text-gray-500">
              {subtext ? subtext : null}
            </p>
          </div>
          <div className="mt-1">
            <motion.input
              {...field}
              value={field.value}
              checked={field.value}
              id={id}
              name={id}
              disabled={rest?.disabled ? rest.disabled : false}
              type="checkbox"
              className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
              whileHover={{ scale: 1.05 }}
            />
          </div>
          {hasError ? errors[id] : null}
        </div>
      )}
    />
  );
};
