export const getDepartment = (type) => {
  switch (type) {
    case "projectList":
      return "Project Department";
    case "crList":
      return "Contractor Department";
    case "computerLeaseList":
      return "Vendor Account Department";
    case "wageList":
      return "Employee Department";
    case "supplyList":
      return "Supply Account Department";
    default:
      return undefined;
  }
};
