import * as yup from "yup";

export const getTaxpayerDetailFormShape = () => {
  return yup.object().shape({
    entityName: yup.string(),
    entityEIN: yup.string(),
    entityType: yup.string(),
    method: yup.string(),
    "280CElection": yup.boolean(),
    controlledGroup: yup.boolean(),
    controlledGroupFiling: yup.string(),
    QSB: yup.boolean(),
    BRP: yup.boolean(),
    brPMT: yup.string(),
    brBase: yup.string(),
    ECP: yup.boolean(),
    ecPMT: yup.string(),
    acquisition: yup.boolean(),
    disposition: yup.boolean(),
    shortYear: yup.boolean(),
    line8932: yup.string(),
    creditK1: yup.string(),
    beneficiariesCredit: yup.string(),
    ascPY1: yup.string(),
    ascPY2: yup.string(),
    ascPY3: yup.string(),
    FBPercentage: yup.number(),
    py1GR: yup.string(),
    py2GR: yup.string(),
    py3GR: yup.string(),
    py4GR: yup.string(),
    naicsCode: yup.string(),
    businessDescription: yup.string(),
  });
};
