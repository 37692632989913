import { useMemo, useState, useEffect, useCallback } from "react";
import { Dispatch, SetStateAction } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import {
  getVapiDefaultFields,
  getVapiFormShape,
  formatAllocationEditForSubmission,
} from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { debounce } from "lodash";
import { InboxIcon, PhoneIcon } from "@heroicons/react/outline";
import { AllocationLetter } from "../../../types/engagement";
import { BuildingProfile } from "../../../types/buildingProfile";

interface VapiProps {
  dispatchCall: () => Promise<void>;
  isDispatching: boolean;
  sendAllocationLetter: () => Promise<void>;
  isSendingAllocationLetter: boolean;
  allocationLetter: any;
  buildingProfile: any;
  updateAllocationLetterAndBuildingProfile: any;
  setAllocation: Dispatch<SetStateAction<AllocationLetter>>;
}

export const Vapi = ({
  dispatchCall,
  isDispatching,
  sendAllocationLetter,
  isSendingAllocationLetter,
  allocationLetter,
  buildingProfile,
  updateAllocationLetterAndBuildingProfile,

  setAllocation,
}: VapiProps) => {
  const schema = getVapiFormShape();
  const { control, handleSubmit, setValue, formState, reset } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getVapiDefaultFields({
        ...allocationLetter,
        buildingName: buildingProfile.buildingName,
        ownerName: buildingProfile.ownerName,
        buildingCost: buildingProfile.buildingCost,
      });
    }, [allocationLetter, buildingProfile]),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(
      getVapiDefaultFields({
        ...allocationLetter,
        buildingName: buildingProfile.buildingName,
        ownerName: buildingProfile.ownerName,
        buildingCost: buildingProfile.buildingCost,
      })
    );
  }, [allocationLetter, buildingProfile, reset]);

  const { errors } = formState;

  // Update Form Values on Text Change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutoSave = useCallback(
    debounce((data) => {
      const formattedData = formatAllocationEditForSubmission(data);
      updateAllocationLetterAndBuildingProfile(formattedData);
    }, 2000),
    [] // Empty dependency array
  );

  // Update Form Values on Text Change
  const handleFieldChange = useCallback(
    (key, value) => {
      setValue(key, value);
      handleSubmit((data) => {
        onAutoSave(data);
      })();
    },
    [setValue, handleSubmit, onAutoSave]
  );

  return (
    <form
      data-test="building-profile-form"
      className="mt-4 ml-4 mr-4"
      onSubmit={(e) => e.preventDefault()}
    >
      <div className="flex flex-col justify-between">
        <div className="flex flex-row justify-center">
          <div className="w-1/2 border-r pr-5 border-gray-300">
            <div className="flex flex-row">
              <label className="block text-sm font-black text-gray-700 pb-2">
                Caller
              </label>
            </div>
            <div className="flex flex-row justify-between">
              <Controller
                name="submittedDate"
                control={control}
                render={({ field }) => (
                  <div className="pb-4">
                    <div className="flex flex-row">
                      <label
                        htmlFor="transcript"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Last Called Date
                      </label>
                      <p className="text-sprxClientPortalRed -mt-1">*</p>
                    </div>

                    <div className="mt-3">
                      <motion.input
                        {...field}
                        data-test="submittedDate"
                        id="submittedDate"
                        name="submittedDate"
                        readOnly
                        placeholder="N/A"
                      />
                    </div>
                  </div>
                )}
              />
              <Controller
                name="phoneNumber"
                control={control}
                render={({ field }) => (
                  <div className="pb-4">
                    <div className="flex flex-row">
                      <label
                        htmlFor="phoneNumber"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Owner Phone Number
                      </label>
                      <p className="text-sprxClientPortalRed -mt-1">*</p>
                    </div>

                    <div className="flex flex-row items-center mt-1">
                      <motion.input
                        {...field}
                        data-test="phoneNumber"
                        id="phoneNumber"
                        name="phoneNumber"
                        type="number"
                        className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent mr-2"
                        whileHover={{ scale: 1.01 }}
                        onChange={(e) =>
                          handleFieldChange("phoneNumber", e.target.value)
                        }
                      />
                      <motion.button
                        type="submit"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="inline-flex w-2/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
                        onClick={async () => {
                          dispatchCall();
                        }}
                      >
                        {isDispatching || allocationLetter?.callInProgress ? (
                          <div className="flex flex-col justify-center items-center h-5 w-full ">
                            <p
                              className={
                                "animate-pulse h-2 w-full bg-gray-100 rounded-md"
                              }
                            ></p>
                          </div>
                        ) : (
                          <PhoneIcon className="h-5 w-5" />
                        )}
                      </motion.button>
                    </div>
                  </div>
                )}
              />
            </div>
            <Controller
              name="transcript"
              control={control}
              render={({ field }) => (
                <div>
                  <div className="flex flex-row">
                    <label
                      htmlFor="transcript"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Transcript
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="mt-1">
                    <motion.textarea
                      {...field}
                      data-test="transcript"
                      id="transcript"
                      name="transcript"
                      readOnly
                      rows={18}
                      className="shadow-sm block w-full sm:text-sm border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent w-full"
                      // whileHover={{ scale: 1.01 }}
                    />
                  </div>
                </div>
              )}
            />
          </div>
          <div className="w-1/2 pl-5">
            <div className="flex flex-row">
              <label className="block text-sm font-black text-gray-700 pb-2">
                Building Details
              </label>
            </div>
            <Controller
              name="buildingName"
              control={control}
              render={({ field }) => (
                <div className="pb-4">
                  <div className="flex flex-row">
                    <label
                      htmlFor="buildingName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Building Name
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="flex flex-row items-center mt-1">
                    <motion.input
                      {...field}
                      data-test="buildingName"
                      id="buildingName"
                      name="buildingName"
                      type="text"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent mr-2"
                      whileHover={{ scale: 1.01 }}
                      onChange={(e) =>
                        handleFieldChange("buildingName", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            />
            <Controller
              name="buildingCost"
              control={control}
              render={({ field }) => (
                <div className="pb-4">
                  <div className="flex flex-row">
                    <label
                      htmlFor="buildingCost"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Building Cost
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="flex flex-row items-center mt-1">
                    <motion.input
                      {...field}
                      data-test="buildingCost"
                      id="buildingCost"
                      name="buildingCost"
                      type="number"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent mr-2"
                      whileHover={{ scale: 1.01 }}
                      onChange={(e) =>
                        handleFieldChange("buildingCost", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            />
            <Controller
              name="ownershipPercentage"
              control={control}
              render={({ field }) => (
                <div className="pb-4">
                  <div className="flex flex-row">
                    <label
                      htmlFor="ownershipPercentage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Claim Percentage
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="flex flex-row items-center mt-1">
                    <motion.input
                      {...field}
                      data-test="ownershipPercentage"
                      id="ownershipPercentage"
                      name="ownershipPercentage"
                      type="number"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent mr-2"
                      whileHover={{ scale: 1.01 }}
                      onChange={(e) =>
                        handleFieldChange("ownershipPercentage", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            />
            <Controller
              name="clientName"
              control={control}
              render={({ field }) => (
                <div className="pb-4">
                  <div className="flex flex-row">
                    <label
                      htmlFor="clientName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Client Company Name
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="flex flex-row items-center mt-1">
                    <motion.input
                      {...field}
                      data-test="clientName"
                      id="clientName"
                      name="clientName"
                      type="text"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent mr-2"
                      whileHover={{ scale: 1.01 }}
                      onChange={(e) =>
                        handleFieldChange("clientName", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            />
            <Controller
              name="ownerName"
              control={control}
              render={({ field }) => (
                <div className="pb-4">
                  <div className="flex flex-row">
                    <label
                      htmlFor="ownerName"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Owner Name
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>

                  <div className="flex flex-row mt-1">
                    <motion.input
                      {...field}
                      data-test="ownerName"
                      id="ownerName"
                      name="ownerName"
                      type="text"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent  mr-2"
                      whileHover={{ scale: 1.01 }}
                      onChange={(e) =>
                        handleFieldChange("ownerName", e.target.value)
                      }
                    />
                  </div>
                </div>
              )}
            />
            <div className="flex flex-row">
              <label className="block text-sm font-black text-gray-700">
                Allocation Letter
              </label>
            </div>
            <div className="flex flex-row pb-4 justify-between w-100">
              <Controller
                name="sentDate"
                control={control}
                render={({ field }) => (
                  <div>
                    <div className="flex flex-row">
                      <label
                        htmlFor="sentDate"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Letter Sent Date
                      </label>
                      <p className="text-sprxClientPortalRed -mt-1">*</p>
                    </div>

                    <div className="mt-3">
                      <motion.input
                        {...field}
                        data-test="sentDate"
                        id="submittesentDatedDate"
                        name="sentDate"
                        readOnly
                        placeholder="N/A"
                      />
                    </div>
                  </div>
                )}
              />
              <Controller
                name="clientEmail"
                control={control}
                render={({ field }) => (
                  <div>
                    <div className="flex flex-row">
                      <label
                        htmlFor="clientEmail"
                        className="block text-sm font-medium text-gray-700formatAllocationEditForSubmission"
                      >
                        Client Email
                      </label>
                      <p className="text-sprxClientPortalRed -mt-1">*</p>
                    </div>

                    <div className="flex flex-row mt-1">
                      <motion.input
                        {...field}
                        data-test="clientEmail"
                        id="clientEmail"
                        name="clientEmail"
                        type="text"
                        className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent  mr-2"
                        whileHover={{ scale: 1.01 }}
                        onChange={(e) =>
                          handleFieldChange("clientEmail", e.target.value)
                        }
                      />
                      {errors?.email && (
                        <p className=" pl-2 text-sprxOrange">
                          Invalid email address
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <div>
                    <div className="flex flex-row">
                      <label
                        htmlFor="email"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Owner Email
                      </label>
                      <p className="text-sprxClientPortalRed -mt-1">*</p>
                    </div>

                    <div className="flex flex-row mt-1">
                      <motion.input
                        {...field}
                        data-test="email"
                        id="email"
                        name="email"
                        type="text"
                        className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent  mr-2"
                        whileHover={{ scale: 1.01 }}
                        onChange={(e) =>
                          handleFieldChange("email", e.target.value)
                        }
                      />
                      <motion.button
                        type="submit"
                        data-test="Save & Close"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="inline-flex w-2/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
                        onClick={async () => sendAllocationLetter()}
                      >
                        {isSendingAllocationLetter ? (
                          <div className="flex flex-col justify-center items-center h-5 w-full ">
                            <p
                              className={
                                "animate-pulse h-2 w-full bg-gray-100 rounded-md"
                              }
                            ></p>
                          </div>
                        ) : (
                          <InboxIcon className="h-5 w-5" />
                        )}
                      </motion.button>
                      {errors?.email && (
                        <p className=" pl-2 text-sprxOrange">
                          Invalid email address
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};
