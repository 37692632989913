import { motion } from "framer-motion";

export const SubmitButton = (props) => {
    return (
        <motion.div className="flex flex-row justify-center col-span-12 mt-2">
            <motion.button
                type="submit"
                data-test="state-compute"
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                className=" w-full inline-flex justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
            >
                Compute
            </motion.button>
      </motion.div>
    )
}