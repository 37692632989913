import { motion } from "framer-motion";
import { StarIcon as OutlinedStar } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { useState } from "react";

interface TableItemProps {
  eng: any;
  index: number;
  setEditOpen: (bool: boolean) => void;
  setEditIndex: (num: number) => void;
  onSetEngagementActive: (id: string) => Promise<void>;
  editIndex: number | null;
  activeEngagement: string;
  onFavoriteEngagement: (type: string, id: string) => Promise<void>;
  favoriteEngagements: string[];
  editID: number;
  setEditID: (num: number) => void;
}

export const TableItem = ({
  eng,
  index,
  setEditOpen,
  setEditIndex,
  onSetEngagementActive,
  editIndex,
  editID,
  setEditID,
  activeEngagement,
  onFavoriteEngagement,
  favoriteEngagements,
}: TableItemProps) => {
  const isActive = eng?.engagementID === activeEngagement;
  const [isFavoriting, setIsFavoriting] = useState<boolean>(false);
  const [isSettingActive, setIsSettingActive] = useState<boolean>(false);
  const handleFavoriteClick = async (index, eng) => {
    setIsFavoriting(true);
    setEditIndex(index);
    await onFavoriteEngagement("EN", eng.engagementID);
    setEditIndex(null);
    setIsFavoriting(false);
  };
  return (
    <tr key={eng?.engagementID ?? ""} className={isActive ? "bg-gray-100" : ""}>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {eng?.name ?? ""}
      </td>
      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
        {eng?.companyName ?? ""}
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="text-sprxClientPortalDarkGreen hover:text-green-400"
          onClick={async () => {
            setEditID(eng?.engagementID);
            setEditOpen(true);
          }}
        >
          Edit
        </motion.button>
      </td>
      <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
        <motion.button
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
          onClick={async () => {
            setIsSettingActive(true);
            setEditIndex(index);
            await onSetEngagementActive(eng?.engagementID ?? "");
            setEditIndex(null);
            setIsSettingActive(false);
          }}
        >
          <>
            {isSettingActive && index === editIndex ? (
              <div className="w-full">
                <p className="h-2 w-16 animate-pulse rounded bg-sprxGrey"></p>
              </div>
            ) : (
              "Set Active"
            )}
          </>
        </motion.button>
      </td>
      <td className="px-3 py-4">
        {isFavoriting && index === editIndex ? (
          <div className="w-full">
            <p className="h-2 w-6 animate-pulse rounded bg-sprxGrey"></p>
          </div>
        ) : (
          <>
            {favoriteEngagements?.includes(eng.engagementID) ? (
              <StarIcon
                className={`h-7 w-7 align-middle text-sprxYellow`}
                onClick={async () => {
                  await handleFavoriteClick(index, eng);
                }}
              />
            ) : (
              <OutlinedStar
                className={`ml-0.5 h-6 w-6 align-middle text-sprxTaxLightGray`}
                onClick={async () => {
                  await handleFavoriteClick(index, eng);
                }}
              />
            )}
          </>
        )}
      </td>
    </tr>
  );
};
