import { ClosedActionCard } from "../../../R&D/UploadPortal/components";
import { useState } from "react";
import { WelcomeMessage } from "./components";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";
import { DocumentationContainer } from "./Documentation/DocumentationContainer";
import { BuildingProfileContainer } from "./BuildingProfile/BuildingProfileContainer";
import { EnergyModelContainer } from "./EnergyModel/EnergyModelContainer";
import { VapiContainer } from "./Vapi/VapiContainer";
import { CommentForm } from "../../components";
import { onCreateCommentParams } from "../../../../rtk/Energy/Comments/EnergyCommentsContext";
import { hasRequiredBuildingProfileFields } from "./BuildingProfile/utils/hasRequiredBuildingProfileFields";
import { Certification } from "./Certification/Certification";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";
import { PaperAirplaneIcon } from "@heroicons/react/outline";
import { PaperAirplaneIcon as SolidPaperPlaneIcon } from "@heroicons/react/solid";

interface InformationGatheringProps {
  engagement: any;
  user: any;
  isSubmitting: boolean;
  submitEngagement: () => Promise<void>;
  isCreatingComment: boolean;
  onCreateComment: (e: onCreateCommentParams) => Promise<void>;
  setViewEngDetail: (bool: boolean) => void;
  onClickSendModel: () => void;
  modelIsSent: boolean;
  isMarkingModelStatus: boolean;
}

export const InformationGathering = ({
  engagement,
  user,
  isSubmitting,
  submitEngagement,
  isCreatingComment,
  onCreateComment,
  setViewEngDetail,
  onClickSendModel,
  modelIsSent,
  isMarkingModelStatus,
}: InformationGatheringProps) => {
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState<any>(0);
  const [closeVisible, setCloseVisible] = useState(false);
  const [openCreateComment, setOpenCreateComment] = useState(false);

  const small = selectedID?.id === 1;
  const isGovernmentEngagement =
    engagement?.buildingProfile?.buildingType === "GOVERNMENT";
  const ENERGY_MODEL_TITLE = "Energy Model";
  const isReadyForCertifier =
    engagement?.certification?.status === "READY" ||
    engagement?.certification?.status === "COMPLETE";
  const isReadyForSubmission =
    engagement?.certification?.status === "COMPLETE" &&
    engagement?.model?.status === "COMPLETE";

  const ACTION_CARDS: any = [
    {
      id: 1,
      title: "Building Profile",
      description: "Update the building profile",
      extendedDescription: "",
      component: (
        <BuildingProfileContainer
          onClose={() => {
            setOpen(false);
            setSelectedID(null);
          }}
        />
      ),
      hideCloseIcon: true,
      complete: hasRequiredBuildingProfileFields(
        engagement?.buildingProfile ?? {},
      ),
      isLoading: false,
    },
    {
      id: 2,
      title: ENERGY_MODEL_TITLE,
      description: "Send the study to your energy modeler",
      extendedDescription: "",
      component: <EnergyModelContainer />,
      hideCloseIcon: true,
      isLoading: false,
      complete: engagement?.model?.status === "COMPLETE",
      completeText: "Chat with Modeler",
    },
    {
      id: 3,
      title: "Documentation",
      description: "Upload your documents",
      extendedDescription: "",
      component: <DocumentationContainer />,
      hideCloseIcon: true,
      complete: engagement?.documents?.length > 0,
      isLoading: false,
    },
  ];

  if (isGovernmentEngagement) {
    ACTION_CARDS.push({
      id: 4,
      title: "Government Caller",
      description: "Initiate a call with the building owner",
      extendedDescription: "",
      component: <VapiContainer />,
      hideCloseIcon: true,
      complete: engagement?.allocationLetter?.status === "COMPLETE",
      isLoading: false,
    });
  }

  if (isReadyForCertifier) {
    ACTION_CARDS.push({
      id: 5,
      title: "Certification",
      description: "Talk to your certifier",
      extendedDescription: "",
      component: (
        <Certification
          engagement={engagement}
          setOpenCreateComment={setOpenCreateComment}
        />
      ),
      hideCloseIcon: true,
      complete: engagement?.certification?.status === "COMPLETE",
      isLoading: false,
      completeText: "Chat with Certifier",
    });
  }

  if (isReadyForSubmission) {
    ACTION_CARDS.push({
      id: 6,
      title: "Submit",
      description: "Start generating your reports",
      extendedDescription: "",
      component: null,
      hideCloseIcon: true,
      complete: false,
      isLoading: isSubmitting,
      openText: "Submit",
    });
  }

  const CommentFormProps = {
    id: 1,
    title: "Comment",
    description: "Add a comment.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentForm
        isCreatingComment={isCreatingComment}
        onSubmitForm={async (e: any) => {
          await onCreateComment({
            title: e.title,
            comment: e.comment,
            role: "certifier",
          });
          setOpenCreateComment(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };
  
  const DispatchEnergyModelIcon = () => {
    return                 (
    <motion.div className="hidden sm:block absolute top-0 right-12 pt-4 pr-4">
    <motion.button
      type="button"
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
      onClick={() => onClickSendModel()}
    >
      {isMarkingModelStatus ? (
        <div className="animate-pulse h-4 mx-1 my-1 bg-sprxGrey rounded-xl w-4"></div>
      ) :  modelIsSent ? (
        <SolidPaperPlaneIcon className={"h-6 w-6 text-sprxGreen"}/>
      ) : (
        <PaperAirplaneIcon className={"h-6 w-6"}/>
      )}
    </motion.button>
  </motion.div>)
  };

  return (
    <>
      <main className="flex-1 lg:overflow-hidden">
        <div className={`flex-1 ${user.role === "client" ? "pt-6" : ""}`}>
          {/*Top bar and logo */}
          <div className="mx-auto flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
            <div className="flex flex-row items-center">
              <button
                onClick={() => {
                  setViewEngDetail(false);
                }}
                className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
              >
                <ArrowLeftIcon className="h-8 w-8" />
              </button>
              <h1 className="ml-2 text-2xl font-semibold text-gray-900">
                {engagement.name}
              </h1>
            </div>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            {/*Welcome message with icon and submit box*/}
            <WelcomeMessage name={user.fullName.split(" ")[0]} />

            <div className="col-span-3 flex w-full">
              <div className="mb-6 grid w-full grid-cols-2 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3">
                {ACTION_CARDS.map((item) => {
                  const isSubmissionCard = item.id === 6;
                  return (
                    <ClosedActionCard
                      key={`${item.title}-review-action-card`}
                      item={item}
                      setOpen={isSubmissionCard ? submitEngagement : setOpen}
                      setSelectedID={isSubmissionCard ? null : setSelectedID}
                      setCloseVisible={
                        isSubmissionCard ? false : setCloseVisible
                      }
                      isLoading={item.isLoading}
                      completeText={item?.completeText ?? "Make Changes"}
                      openText={item?.openText ?? "Start"}
                    />
                  );
                })}
              </div>
            </div>
          </div>
        </div>
        {/*Modal Component */}
        <OpenActionCard
          open={open}
          setOpen={setOpen}
          selectedID={selectedID}
          setSelectedID={setSelectedID}
          closeVisible={closeVisible}
          setCloseVisible={setCloseVisible}
          setCommentOpen={undefined}
          disableOnClickOutsideClose={false}
          secondaryPanel={false}
          topRightPanel={selectedID?.id === 2 ? <DispatchEnergyModelIcon /> : null}
          small={small}
        />
        {/*Comment Form */}
        <OpenActionCard
          open={openCreateComment}
          setOpen={setOpenCreateComment}
          selectedID={CommentFormProps}
          setSelectedID={() => null}
          closeVisible={true}
          setCloseVisible={() => null}
          setCommentOpen={undefined}
          disableOnClickOutsideClose={false}
          secondaryPanel={false}
          small={true}
        />
      </main>
    </>
  );
};
