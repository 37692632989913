import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { EngagementList } from "./EngagementList";
import { AdminEngagementListLoading } from "../components/AdminEngagementListLoading";
import { useUsers } from "../../../rtk/Users/useUsers";
import { isDev } from "../../R&D/utils";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { useState, useEffect } from "react";

export const EnergyEngagementListContainer = () => {
  const energyEngagementAPI = useEnergyEngagement();
  const userAPI = useUsers();
  const dispatch = useDispatch();
  const { companies } = useCompanyData();
  const loading =
    !energyEngagementAPI ||
    !userAPI ||
    energyEngagementAPI.allEngagementsLoading;

  if (loading) return <AdminEngagementListLoading />;

  const onCreateEngagement = async (model: any): Promise<void> => {
    const createEngagementPayload = {
      ...model,
      companyName: model.companyName.label,
      companyID: model.companyName.value,
      createdBy: userAPI.user?.userID,
    };
    const createEngagementResponse = await energyEngagementAPI.createEngagement(
      createEngagementPayload,
    );
    if (isDev) console.log({ createEngagementResponse });
    const newEngagementID =
      createEngagementResponse?.data?.params?.engagementID;
    if (newEngagementID) onSetEngagementActive(newEngagementID);
  };

  const onSetEngagementActive = async (id: string): Promise<void> => {
    const body = {
      activeEnergyEngagementID: id,
      engagementType: "EN",
      isUsingDataSpace: false,
    };
    const userID = userAPI.user.userID;
    const updateUserReponse = await userAPI.updateTaxUser({
      userID: userID,
      body: body,
    });
    if (isDev) console.log({ updateUserReponse });
    const updatedUser = await userAPI.getTaxUser(userID); // Grab this from updateTaxUser
    dispatch(setUserData(updatedUser.data));
  };

  const onFavoriteEngagement = async (type, id) => {
    // setIsFavoriting(true);
    // setEditIndex(index);
    const userID = userAPI?.user?.userID;

    let favorites = [
      ...(userAPI.user?.favorites?.hasOwnProperty(type)
        ? userAPI.user?.favorites[type]
        : []),
    ];

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      favorites.splice(index, 1);
    } else {
      favorites.push(id);
    }
    const body = {
      favorites: {
        ...userAPI.user.favorites,
        [type]: favorites,
      },
    };

    const updateUserResponse = await userAPI.updateTaxUser({ userID, body });
    const updatedUser = await userAPI.getTaxUser(userAPI.user.userID);
    dispatch(setUserData(updatedUser.data));
    // setEditIndex(null);
    // setIsFavoriting(false);
  };

  const onUpdateEngagement = async (data: any): Promise<void> => {};

  return (
    <EngagementList
      engagements={energyEngagementAPI?.allEngagements}
      onCreateEngagement={onCreateEngagement}
      onSetEngagementActive={onSetEngagementActive}
      onUpdateEngagement={onUpdateEngagement}
      onFavoriteEngagement={onFavoriteEngagement}
      activeEngagement={userAPI?.user?.data?.activeEnergyEngagementID ?? ""}
      favoriteEngagements={userAPI?.user?.favorites?.EN}
    />
  );
};
