import { states } from "../../../../ui-components/FlatfileButtonSelectFieldOptions";

export const getUploadFlatfileFields = {
  "Computer Lease Intelligent Import": [
    {
      label: "Vendor Account Name",
      key: "Vendor Account Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Account Description",
      key: "Vendor Account Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Account Department",
      key: "Vendor Account Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Location",
      key: "Vendor Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Vendor Cost",
      key: "Vendor Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
  "Wages Intelligent Import": [
    {
      label: "Employee Name",
      key: "Employee Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Job Title",
      key: "Employee Job Title",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Department",
      key: "Employee Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Location",
      key: "Employee Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee W2 Wages",
      key: "Employee W2 Wages",
      validators: [{ validate: "required" }],
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
    {
      label: "Email",
      key: "email",
    },
  ],
  "Projects Intelligent Import": [
    {
      label: "Project Name",
      key: "Project Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Description",
      key: "Project Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Location",
      key: "Project Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
  ],
  "Contract Research Intelligent Import": [
    {
      label: "Contractor Name",
      key: "Contractor Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Description",
      key: "Contractor Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Department",
      key: "Contractor Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Location",
      key: "Contractor Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Type",
      key: "Contractor Type",
      type: "select",
      options: [
        { value: "", label: "" },
        { value: "Hourly", label: "Hourly", alternates: ["hourly"] },
        { value: "FF", label: "FF", alternates: ["ff"] },
        { value: "T&M", label: "T&M", alternates: ["t&m"] },
      ],
    },
    {
      label: "Contractor Cost",
      key: "Contractor Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
  "Supply Intelligent Import": [
    {
      label: "Supply Account Name",
      key: "Supply Account Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Description",
      key: "Supply Account Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Department",
      key: "Supply Account Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Location",
      key: "Supply Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Cost",
      key: "Supply Cost",
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
    },
  ],
};
