import { useEnergyEngagement } from "../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useEnergyComments } from "../../../../rtk/Energy/Comments/useEnergyComments";
import { InformationGathering } from "./InformationGathering";
import { useState } from "react";
import { InformationGatheringLoading } from "./components";

export const InformationGatheringContainer = ({ setViewEngDetail }) => {
  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();
  const commentsAPI = useEnergyComments();
  const [isSubmitting, setIsSubmitting] = useState(false);

  if (
    !energyEngagementAPI ||
    !usersAPI ||
    energyEngagementAPI.engagementLoading
  )
    return <InformationGatheringLoading />;

  const submitEngagement = async () => {
    setIsSubmitting(true);
    const engagementID = energyEngagementAPI.engagement.engagementID;
    const body = {
      reports: {
        status: "COMPLETE",
        isDraft: true,
      },
    };
    await energyEngagementAPI.updateEngagement({ engagementID, body });
    await energyEngagementAPI.generateEnergyDeliverables({
      engagementID,
      body,
    });
    setIsSubmitting(false);
  };

  return (
    <InformationGathering
      setViewEngDetail={setViewEngDetail}
      engagement={energyEngagementAPI.engagement}
      user={usersAPI.user}
      submitEngagement={submitEngagement}
      isSubmitting={isSubmitting}
      isCreatingComment={commentsAPI.isCreatingComment}
      onCreateComment={commentsAPI.onCreateComment}
      onClickSendModel={energyEngagementAPI.markModelStatusAsReady}
      modelIsSent={energyEngagementAPI.engagement.model.status === "READY"}
      isMarkingModelStatus={
        energyEngagementAPI.updateEngagementData.isLoading ||
        energyEngagementAPI.engagementLoading ||
        energyEngagementAPI.engagementFetching
      }
    />
  );
};
