import React, { useEffect, useMemo } from "react";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "../../ui-components";
import { useUsers } from "../../rtk/Users/useUsers";
import { isDev, classNames } from "../R&D/utils";
import {
  getCreateDataSpaceUserDefaultValues,
  getCreateDataSpaceUserFormShape,
} from "./utils";
//import { User } from "../AdminHome/types/user";

// interface CreateDataSpaceFormProps {
//   open: boolean;
//   setOpen: (open: boolean) => void;
//   user: User;
//   dataSpace: any;
// }

export const CreateDataSpaceUserForm = ({ open, setOpen, user, dataSpace }) => {
  const { createUser, createUserError, isCreatingUser } = useUsers();

  const { control, formState, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return getCreateDataSpaceUserDefaultValues();
    }, [user]),
    resolver: yupResolver(getCreateDataSpaceUserFormShape()),
  });

  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const onClickSend = async (model) => {
    // Get Current User Data
    const {
      data: { activeDataSpaceID, activeEngagementID },
    } = user;

    // Get DataSpace Data
    const { companyID, companyName } = dataSpace;

    // Get Form Data
    const { email, fullName, message } = model;

    // Format Create User Request Body
    const body = {
      fullName,
      companyName,
      email,
      activeEngagementID,
      activeDataSpaceID,
      companyID,
      message,
      displayName: fullName.split(" ")[0],
      role: "client",
      isUsingDataSpace: true,
      baseURL: "portal.sprx.tax",
      engagementType: "DS",
    };

    const createUserResponse = await createUser({ body });
    if (isDev) console.log({ createUserResponse });
    if (createUserError === undefined) setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-40"
          static
          layoutId={"1234"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-32vh min-w-30vw">
                <motion.div className="">
                  {/*Modal title, description, and close button */}
                  <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <h1 className=" mt-6 w-full text-3xl text-center font-bold">
                    Invite User
                  </h1>
                  <form
                    className="mt-5 sm:flex sm:items-center sm:flex-col"
                    onSubmit={handleSubmit((model) => onClickSend(model))}
                  >
                    <Controller
                      name="fullName"
                      control={control}
                      render={({ field }) => (
                        <div className="w-full sm:max-w-xs text-left">
                          <label htmlFor="fullName">Name</label>
                          <input
                            {...field}
                            type="text"
                            name="fullName"
                            id="fullName"
                            required
                            autoComplete="fullName"
                            className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="Jane Doe"
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <div className="mt-4 w-full sm:max-w-xs text-left">
                          <label htmlFor="email">Email</label>
                          <input
                            {...field}
                            type="email"
                            name="email"
                            id="email"
                            required
                            autoComplete="email"
                            className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                            placeholder="you@example.com"
                          />
                        </div>
                      )}
                    />
                    <Controller
                      name="message"
                      control={control}
                      render={({ field }) => (
                        <div className="mt-4 w-full sm:max-w-xs text-left">
                          <label htmlFor="message">Email Message</label>
                          <div className="mt-1">
                            <motion.textarea
                              {...field}
                              id="message"
                              name="message"
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              whileHover={{ scale: 1.01 }}
                            />
                          </div>
                        </div>
                      )}
                    />
                    <Button
                      secondary={true}
                      buttonClassName={classNames(
                        "mt-5 sm:mt-6 inline-flex justify-center w-1/5 rounded-md border border-transparent shadow-sm px-4 py-2 bg-sprxTaxBlue text-base font-medium text-white hover:bg-sprxTaxDarkBlue focus:outline-none sm:text-sm",
                        _.isEmpty(dirtyFields) &&
                          "bg-sprxTaxBlue opacity-50 cursor-not-allowed",
                        !isValid &&
                          "bg-sprxTaxBlue opacity-50 cursor-not-allowed"
                      )}
                      disabled={_.isEmpty(dirtyFields) || !isValid}
                    >
                      {isCreatingUser ? (
                        <div className="w-2/3">
                          <p className="animate-pulse bg-white rounded h-2 mt-2 w-12"></p>
                        </div>
                      ) : (
                        "Add"
                      )}
                    </Button>
                    {createUserError && (
                      <p className="mt-2 text-sm text-red-600">
                        {createUserError?.data?.error ??
                          "Encountered an error, please try again later."}
                      </p>
                    )}
                  </form>
                </motion.div>
              </Dialog.Panel>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
