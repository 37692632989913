import * as yup from "yup";

export const getContactFormShape = () => {
  return yup.object().shape({
    name: yup.string().required(),
    email: yup.string().email().required(),
    phone: yup.string(),
    subject: yup.string().required(),
    message: yup.string().required(),
  });
};
