export const EngagementLoading = () => {
  return (
    <div>
      <div className="flex justify-end w-auto mx-8 mt-4">
        <div className="flex justify-between">
          <button className="mr-4 inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
          </button>
          <button className="mr-4 inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
          </button>
        </div>
      </div>
      <div className="w-auto h-1 min-h-70vh mx-8 mt-4">
        <div className="mt-2">
          <div className="flex flex-row items-between justify-between items-end"></div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg max-h-128">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="bg-gray-50 sticky top-0">
                      <tr>
                        <th className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                        </th>
                        <th className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                        </th>
                        <th className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                        </th>
                        <th className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                          <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                        </th>
                        <th className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell"></th>
                      </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white h-16">
                      {Array.from(Array(4))?.map((item, index) => (
                        <tr key={index}>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                          </td>
                          <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
