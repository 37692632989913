import { motion } from "framer-motion";
import { FileListPane } from "../WageUpload/FileListPane";
import { FlatfileSpace } from "../../../../ui-components/FlatfileSpace";
import * as React from "react";
import { ImportType } from "../../../../ui-components/FlatfileSpace";
import { getNamespaceType } from "../utils/getNamespaceType";

export const IntelligentImportChild = ({
  engagementData,
  user,
  type,
  loading,
  deleteCallback,
  engagementID,
  onCloseCallback,
  setShowSpace,
  showSpace,
  manualRefresh,
}: {
  engagementData: any;
  user: any;
  loading: boolean;
  deleteCallback: any;
  engagementID: string;
  onCloseCallback: () => void;
  setShowSpace: React.Dispatch<React.SetStateAction<boolean>>;
  showSpace: boolean;
  type: ImportType;
  manualRefresh: boolean;
}) => {
  return (
    <>
      <FlatfileSpace
        showSpace={showSpace}
        setShowSpace={setShowSpace}
        namespace={getNamespaceType(type)}
        engagementID={engagementID}
        companyName={engagementData?.companyName}
        type={type}
        name={user.fullName}
        onCloseCallback={onCloseCallback}
      />
      <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 px-8 py-8 z-40">
        <div className="col-span-1 flex flex-col w-full">
          <div className="flex flex-col items-start justify-start h-96 w-full">
            <div className="w-2/3">
              <motion.div
                whileHover={{ scale: 1.05 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setShowSpace(true)}
                className={
                  "flex flex-col overflow-hidden rounded-lg shadow-xl border w-full xl:min-h-245 cursor-pointer"
                }
              >
                <motion.div
                  className={
                    "flex flex-1 flex-col justify-center items-center bg-white p-4"
                  }
                >
                  <img
                    className="xl:h-24 xl:w-24 h-16 w-16 my-4"
                    src={"assets/images/Spreadsheet_Icon.svg"}
                    alt={"Import"}
                  />
                  <motion.h3 className="xl:text-xl text-md text-center font-semibold text-gray-900">
                    Intelligent Import
                  </motion.h3>
                  <motion.p className="mt-1 xl:text-sm text-xs text-center font-light tracking-tight text-text-gray-900">
                    Upload any spreadsheet file.
                  </motion.p>
                </motion.div>
              </motion.div>
            </div>
          </div>
        </div>
        <FileListPane
          engagementData={engagementData}
          user={user}
          type={type}
          loading={loading || manualRefresh}
          deleteCallback={deleteCallback}
          tagFilter={"intelligent-import"}
        />
      </div>
    </>
  );
};
