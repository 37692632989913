import { useEffect, useState } from "react";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { DataTable } from "../../../../ui-components";
import { FlatfileSpace } from "../../../../ui-components/FlatfileSpace";
import { getNamespaceType } from "../../UploadPortal/utils/getNamespaceType";
import { getDownloadFileName } from "../utils";
import { getAllocationColumns, mapProjectToAllocationColumn } from "./utils";
import { EXCLUDED_FIELDS } from "../utils/constants";
import { TabNavigator } from "./components";

interface Tab {
  label: string;
  value: any;
  dataSlug?: string;
}
const TABS: Tab[] = [
  {
    label: "Wages",
    value: "WAGES_ALLOCATIONS",
    dataSlug: "wageData",
  },
  {
    label: "Supply",
    value: "SUPPLY_ALLOCATIONS",
    dataSlug: "supplyData",
  },
  {
    label: "Computer Lease",
    value: "COMPUTER_ALLOCATIONS",
    dataSlug: "computerLeaseData",
  },
  {
    label: "Contract Research",
    value: "CONTRACT_ALLOCATIONS",
    dataSlug: "contractData",
  },
];

export const AllocationsCard = () => {
  const dataAPI = useDataArray();
  const engagementAPI = useEngagementData();
  const fileAPI = useFile();
  const userAPI = useUsers();

  const [currentTab, setCurrentTab] = useState<Tab>(TABS[0]);
  const [showFlatfileSpace, setShowFlatfileSpace] = useState<boolean>(false);

  const [columnDef, setColumnDef] = useState([
    ...getAllocationColumns(currentTab),
    ...dataAPI.projectData.map((project) => {
      return mapProjectToAllocationColumn(project);
    }),
  ]);

  const data = dataAPI[currentTab.dataSlug];

  useEffect(() => {
    setColumnDef([
      ...getAllocationColumns(currentTab.value),
      ...dataAPI.projectData.map(mapProjectToAllocationColumn),
    ]);
  }, [dataAPI, currentTab]);

  const onRowUpdate = async (newData) => {
    // Update Data
    let formattedNewItem = { ...newData };
    const oldData = data.find((e) => e.ID === newData.ID);

    // Update Completions Object iff Employee Item
    if (currentTab.label === "Wages") {
      formattedNewItem = {
        ...oldData,
        ...newData,
        completions: {},
      };
      Object.keys(formattedNewItem)
        .filter((a) => EXCLUDED_FIELDS.indexOf(a) === -1)
        ?.forEach((k) => {
          // Add New Project to Completions Object (should only add one at a time)
          if (
            !formattedNewItem.completions.hasOwnProperty(k) &&
            Number(formattedNewItem[k]) > 0
          ) {
            formattedNewItem.completions = {
              [`${k}`]: { isLoading: true },
            };
          }
        });
    }
    await dataAPI.updateLineItem(formattedNewItem, currentTab.value);
  };

  const onCloseFlatfileSpace = async () => {
    await dataAPI.refreshData(currentTab.value);
    setShowFlatfileSpace(false);
  };

  const gridOptions =
    currentTab.label === "Wages"
      ? {
          "rag-red-outer": (params) => {
            return params.data.status !== "";
          },
        }
      : {};

  const loading =
    !dataAPI ||
    dataAPI.isDownloadingFile ||
    dataAPI.isCreatingJSONDataFile ||
    dataAPI.isUploadingFile;

  return (
    <div className="px-8 pb-8 z-40">
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-row justify-end w-full">
          <button
            className="mr-8 mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
            onClick={() => setShowFlatfileSpace(true)}
          >
            Upload
          </button>
          <button
            className="mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
            onClick={() =>
              fileAPI.onDownloadDocument(getDownloadFileName(currentTab.value))
            }
          >
            {fileAPI.isGettingBulkEditFile ? (
              <div className="w-full">
                <p className="animate-pulse bg-white rounded h-2 w-16"></p>
              </div>
            ) : (
              "Download"
            )}
          </button>
        </div>
        <TabNavigator
          tabs={TABS}
          currentTab={currentTab}
          setCurrentTab={setCurrentTab}
        />
        <div className="w-full h-1 min-h-70vh">
          <DataTable
            columns={columnDef}
            data={data}
            isLoading={loading}
            onRowUpdate={(newData) => onRowUpdate(newData)}
          />
        </div>
      </div>
      <FlatfileSpace
        showSpace={showFlatfileSpace}
        setShowSpace={setShowFlatfileSpace}
        namespace={getNamespaceType(currentTab.value)}
        engagementID={engagementAPI?.engagementData?.engagementID}
        companyName={engagementAPI?.engagementData?.companyName}
        type={currentTab.value}
        name={userAPI.user.fullName}
        onCloseCallback={onCloseFlatfileSpace}
        gridOptions={gridOptions}
      />
    </div>
  );
};
