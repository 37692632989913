import { calculateDaysDifference } from "../components/Progression";
export const getFlagColor = (tracker: any, startDate: string) => {
  const hasUploaded = tracker["upload-complete"]?.status === "COMPLETE";
  const now = new Date().toISOString();
  if (!hasUploaded) {
    const differenceInDays = calculateDaysDifference(startDate, now);
    if (differenceInDays > 10) return "yellow";
    return undefined;
  }
  const hasReviewed = tracker["review-complete"]?.status === "COMPLETE";
  if (!hasReviewed) {
    const differenceInDays = calculateDaysDifference(
      new Date(tracker["upload-complete"].lastUpdated).toISOString(),
      now
    );
    if (differenceInDays > 10) return "yellow";
    return undefined;
  }
  const hasValidated = tracker["reports-complete"]?.status === "COMPLETE";
  if (!hasValidated) {
    const differenceInDays = calculateDaysDifference(
      new Date(tracker["review-complete"].lastUpdated).toISOString(),
      now
    );
    if (differenceInDays > 10) return "yellow";
    return undefined;
  }
  return undefined;
};
