import React, { useRef, useEffect, useState } from "react";
import * as d3 from "d3";
import { IChartProps, QreChartItem } from "./PieChart";
import { DataDisplay } from "./DataDisplay";

export const GaugeChart = ({ lineItems, label, value, title }: IChartProps) => {
  const svgRef = useRef<SVGSVGElement | null>(null);
  const [selectedSlice, setSelectedSlice] = useState<number | null>(0);

  const groupedData = React.useMemo(() => {
    const data = lineItems.map((item: any) => {
      const group = item[label] || "Unknown";
      const cost = item[value] || 0;

      return {
        group,
        cost,
      };
    });

    const groups = Array.from(new Set(data.map((d) => d.group)));

    const groupedData = groups
      .map((group) => {
        const values = data.filter((d) => d.group === group);
        const totalCost = d3.sum(values, (d: any) => d.cost);

        return {
          group,
          totalCost,
        };
      })
      .sort((a, b) => b.totalCost - a.totalCost);
    return groupedData;
  }, [label, lineItems, value]);

  useEffect(() => {
    if (!svgRef.current) return;

    const width = 250;
    const height = 250; // Half the width to make a semi-circle gauge
    const thickness = 15;
    const radius = Math.min(width, height * 2) / 2;

    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const path = d3
      .arc()
      .outerRadius(radius)
      .innerRadius(radius - thickness);

    const outlinePath = d3
      .arc()
      .outerRadius(radius + 5)
      .innerRadius(radius - thickness);

    const pie = d3
      .pie<QreChartItem>()
      .value((d: any) => d.totalCost)
      .sort(null)
      .startAngle(-90 * (Math.PI / 180))
      .endAngle(90 * (Math.PI / 180));

    const arc = d3
      .arc<d3.PieArcDatum<QreChartItem>>()
      .innerRadius(radius - thickness)
      .outerRadius(radius);

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .append("g")
      .attr(
        "viewBox",
        `-${thickness} -${thickness} ${width + thickness * 6} ${
          height + thickness * 2
        }`
      )
      // Center the group horizontally and position it at the bottom half of the SVG
      .attr("transform", `translate(${width / 2}, ${height - thickness * 4})`);

    const arcs = svg
      .selectAll(".arc")
      .data(pie(groupedData))
      .enter()
      .append("g")
      .attr("class", "arc");

    arcs
      .append("path")
      .attr("d", arc)
      .attr("fill", (d: any, i: any) => color(i.toString()))
      .on("click", (event: any, d: any) => {
        const index = groupedData.findIndex((g) => g.group === d.data.group);
        setSelectedSlice(index);
      });

    if (selectedSlice !== null) {
      d3.select(svgRef.current)
        .selectAll("path")
        .attr("stroke", (d: any, i: any) =>
          d.index === selectedSlice ? "none" : "white"
        );
      /*.attr("stroke-width", (d: any, i: any) =>
          d.index === selectedSlice ? 2 : 0
        )
        .attr("d", (d: any, i: any) =>
          d.index === selectedSlice ? outlinePath(d) : path(d)
        );*/
    }

    // Optional: Add text labels or other elements inside the arcs
  }, [groupedData, selectedSlice]);

  const group = groupedData[selectedSlice]?.group ?? "N/A";
  const cost = Math.round(groupedData[selectedSlice]?.totalCost ?? 0);

  return (
    <div className="flex flex-row justify-center items-center h-full">
      <div className="flex flex-col items-center">
        <h1 className="font-bold">{title}</h1>
        {/* Chart */}
        <div className="flex flex-row items-center justify-evenly ">
          <div className="flex flex-col justify-center items-center ">
            <svg ref={svgRef}></svg>
          </div>
          {/* Summary Box */}
          <div className="rounded-lg bg-white w-60 text-center">
            <div className="flex flex-col w-full justify-center items-center">
              <DataDisplay title={group} value={cost} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
