import * as React from "react";

interface FinalizeDeliverablesrProps {
  onClickFinalize: () => void;
  isFinalized: boolean;
  isFinalizing: boolean;
}

export const FinalizeDeliverables = ({
  onClickFinalize,
  isFinalized,
  isFinalizing,
}: FinalizeDeliverablesrProps) => {
  return (
    <div className="flex flex-grow items-center justify-end w-1/3 pl-8">
      <div className="flex flex-col justify-center items-center w-full py-4 rounded-2xl border shadow-xl h-12vh">
        <h1 className="text-xl text-center font-semibold text-gray-900  overflow-hidden">
          {!isFinalizing ? (
            <>{isFinalized ? "Revert Deliverables" : "Finalize Deliverables"}</>
          ) : (
            <div className="animate-pulse ml-2 my-2 h-2 bg-sprxGrey rounded w-24"></div>
          )}
        </h1>
        <button
          type="button"
          className="inline-flex items-center justify-center w-1/2 mt-2 px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-xl shadow-sm text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none"
          onClick={async () => onClickFinalize()}
        >
          {!isFinalizing ? (
            <>{isFinalized ? "Revert" : "Finalize"}</>
          ) : (
            <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
          )}
        </button>
      </div>
    </div>
  );
};
