import { motion } from "framer-motion";

export const DocumentLibraryTable = (props) => {
  const { documents, onDownload, onDelete } = props;
  return (
    <div className="px-4 sm:px-6 lg:px-8">
      <div className="mt-8 flex flex-col">
        <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle md:px-6 lg:px-8">
            <div className="overflow-hidden shadow ring-1 ring-black ring-opacity-5 md:rounded-lg">
              <table className="min-w-full divide-y divide-gray-300">
                <thead className="bg-gray-50">
                  <tr>
                    <th
                      scope="col"
                      className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                    >
                      Name
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Type
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Uploaded By
                    </th>
                    <th
                      scope="col"
                      className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                    >
                      Upload Date
                    </th>
                    <th
                      scope="col"
                      className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                    >
                      <span className="sr-only">Download</span>
                    </th>
                    {/*<th scope="col" className="relative py-3.5 pl-3 pr-4 sm:pr-6">
                          <span className="sr-only">Delete</span>
                        </th>*/}
                  </tr>
                </thead>
                <tbody className="divide-y divide-gray-200 bg-white">
                  {documents?.map((doc) => (
                    <tr key={doc?.key}>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {doc?.fileName}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {doc?.type}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {doc?.user}
                      </td>
                      <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                        {doc?.uploaded}
                      </td>
                      <td className="flex flex-row whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6">
                        <motion.img
                          className="h-6 w-6 mr-16"
                          src={"assets/images/Download_Icon.svg"}
                          alt={"Download"}
                          onClick={async () => await onDownload(doc)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        />
                        <motion.img
                          className="h-6 w-6"
                          src={"assets/images/Trash_Icon.svg"}
                          alt={"Delete"}
                          onClick={async () => await onDelete(doc)}
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
