/* Determines whether or not profile has necessary items for calculation
   - Dirty fields (most recent changes) take priority in determining 
   subsequent necessary fields (method --> ascPY1, brPayments, etc. )
*/

const checkRegularMethod = (engagementData, formFields) => {
  // Ensure Fixed Base Percentage Exists
  const formHasFbPercentage = formFields.includes("FBPercentage");
  const hasStaleFbPercentage =
    engagementData?.creditEligibilityData?.completedAnalysis?.hasOwnProperty(
      "FBPercentage"
    );
  const hasFbPercentage = formHasFbPercentage || hasStaleFbPercentage;
  if (process.env.REACT_APP_STAGE === "dev" && !hasFbPercentage) console.log("Missing fixed base percentage");
  if (!hasFbPercentage) return false;

  // Ensure Prior Year Gross Receipts Exist

  // -> Prior Year 1
  const formHasPy1 = formFields.includes("py1GR");
  const hasStalePy1 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PYFourYearGR?.hasOwnProperty(
      "py1"
    );
  const hasPy1 = formHasPy1 || hasStalePy1;
  if (process.env.REACT_APP_STAGE === "dev" && !hasPy1) console.log("Missing prior year 1");
  if (!hasPy1) return false;

  // -> Prior Year 2
  const formHasPy2 = formFields.includes("py2GR");
  const hasStalePy2 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PYFourYearGR?.hasOwnProperty(
      "py2"
    );
  const hasPy2 = formHasPy2 || hasStalePy2;
  if (process.env.REACT_APP_STAGE === "dev" && !hasPy2) console.log("Missing prior year 2");
  if (!hasPy2) return false;

  // -> Prior Year 3
  const formHasPy3 = formFields.includes("py3GR");
  const hasStalePy3 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PYFourYearGR?.hasOwnProperty(
      "py3"
    );
  const hasPy3 = formHasPy3 || hasStalePy3;
  if (process.env.REACT_APP_STAGE === "dev" && !hasPy3) console.log("Missing prior year 3");
  if (!hasPy3) return false;

  // -> Prior Year 4
  const formHasPy4 = formFields.includes("py4GR");
  const hasStalePy4 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PYFourYearGR?.hasOwnProperty(
      "py4"
    );
  const hasPy4 = formHasPy4 || hasStalePy4;
  if (process.env.REACT_APP_STAGE === "dev" && !hasPy4) console.log("Missing prior year 3");
  if (!hasPy4) return false;

  return true;
};

const checkAscMethod = (engagementData, formFields) => {
  // Ensure Prior Year 1 Exists
  const formHasPy1 = formFields.includes("ascPY1");
  const hasStalePy1 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE?.hasOwnProperty(
      "py1"
    );
  const hasPy1 = formHasPy1 || hasStalePy1;
  if (!hasPy1) return false;

  // Ensure Prior Year 2 Exists
  const formHasPy2 = formFields.includes("ascPY2");
  const hasStalePy2 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE?.hasOwnProperty(
      "py2"
    );
  const hasPy2 = formHasPy2 || hasStalePy2;
  if (!hasPy2) return false;

  // Ensure Prior Year 3 Exists
  const formHasPy3 = formFields.includes("ascPY3");
  const hasStalePy3 =
    engagementData?.creditEligibilityData?.completedAnalysis?.PriorThreeYearQRE?.hasOwnProperty(
      "py3"
    );
  const hasPy3 = formHasPy3 || hasStalePy3;
  if (!hasPy3) return false;

  return true;
};

export const validateCompletedEligibility = (
  engagementData,
  dirtyFields,
  model
) => {
  const formFields = Object.keys(dirtyFields);

  // Ensure Method Exists
  const hasStaleMethod = engagementData?.calculation.hasOwnProperty("method");
  const formHasMethod = formFields.includes("method");
  const hasMethod = hasStaleMethod || formHasMethod;
  if (process.env.REACT_APP_STAGE === "dev" && !hasMethod) console.log("Missing method");
  if (!hasMethod) return false;

  // Ensure 280C Exists
  const formHas280C = formFields.includes("280CElection");
  const hasStale280C =
    engagementData?.creditEligibilityData?.completedAnalysis?.hasOwnProperty(
      "280CElection"
    );
  const has280CElection = formHas280C || hasStale280C;
  if (process.env.REACT_APP_STAGE === "dev" && !has280CElection) console.log("Missing 280C election");

  // Determine Necessary Fields By Method
  const method = formHasMethod
    ? model.method
    : engagementData?.calculation?.method;
  if (process.env.REACT_APP_STAGE === "dev") console.log({method});
  if (method === "regular") {
    return checkRegularMethod(engagementData, formFields);
  } else if (method === "asc") {
    return checkAscMethod(engagementData, formFields);
  } else if (method === "both") {
    return (
      checkRegularMethod(engagementData, formFields) &&
      checkAscMethod(engagementData, formFields)
    );
  } else {
    return false;
  }
};
