import React from "react";

export const SurveyComplete = (props) => {
    return (
        <div className="min-h-screen bg-gradient-login flex flex-col justify-center py-12 sm:px-6 lg:px-8">
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">
              <img
                className="mx-auto h-20 w-auto"
                src="assets/images/logos/sprxWhite.svg"
                alt="SPRX.tax"
              />
              <h2 className="text-white text-3xl font-bold ml-4">SME Survey</h2>

            </div>
            <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
              <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
                <div className={"flex flex-col items-center"}>
                  <div className="w-full">
                    <div className="text-center">
                      <p>Your survey has already been completed. Thanks!</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
};