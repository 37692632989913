import { motion } from "framer-motion";
import { performClick } from "../TaxpayerProfile/utils";
import { FileListPane } from "../WageUpload";
import { useRef, useCallback } from "react";
import { useDropzone } from "react-dropzone";
import {
  useUploadFileMutation,
  useUploadDirectoryFileMutation,
} from "../../../../api/fileAPI";
import { useUpdateEngagementDataMutation } from "../../../../api/engagmentAPI";
import { parseUploadedDocuments } from "./utils";
import { isDev, classNames } from "../../utils";

export const DocumentUpload = (props) => {
  const { engagementData, user } = props;
  const { engagementID } = engagementData;
  const inputFileRef = useRef();

  const [updateEngagementData, { isLoading: isUpdatingEngagementData }] =
    useUpdateEngagementDataMutation();

  const [uploadDirectoryFile, { isLoading: isUploadingDirectoryFile }] =
    useUploadDirectoryFileMutation();

  const [uploadFile, { isLoading: isUploadingFile }] = useUploadFileMutation();

  // Upload Data & Update Engagement Data
  const onDrop = useCallback(async (files) => {
    const userName = user.fullName;

    // Upload to S3 & Update Pointers for Extraction
    const { uploadParameters, updateDocs } = parseUploadedDocuments(
      files,
      engagementData
    );

    await Promise.all(
      uploadParameters.map((uploadParam) => {
        return uploadFile(uploadParam);
      })
    );

    // Update Documentation Engagement Data Pointers
    var filteredDocs = updateDocs;
    engagementData.documentation.documents.forEach((doc) => {
      filteredDocs = filteredDocs.filter((item) => item.key !== doc.key);
    });
    const body = {
      documentation: {
        documents: [...engagementData.documentation.documents, ...filteredDocs],
      },
      status: {
        ...engagementData.status,
        "portal-documentation": {
          complete: true,
        },
      },
    };
    const doucmentationUpdate = await updateEngagementData({
      engagementID: engagementID,
      body: body,
    });
    if (isDev) console.log({ doucmentationUpdate });

    // Upload File to Documents Library
    await Promise.all(
      uploadParameters.map(async (uploadParam) => {
        const body = {
          ...uploadParam,
          user: userName,
          folder: "Documentation",
        };
        return uploadDirectoryFile({ body });
      })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Upload Data & Update Engagement Data
  const onDocumentUpload = async (event) => {
    const { files } = event.target;
    const userName = user.fullName;

    // Upload to S3 & Update Pointers for Extraction
    const { uploadParameters, updateDocs } = parseUploadedDocuments(
      files,
      engagementData
    );

    await Promise.all(
      uploadParameters.map((uploadParam) => {
        return uploadFile(uploadParam);
      })
    );

    // Update Documentation Engagement Data Pointers
    var filteredDocs = updateDocs;
    engagementData.documentation.documents.forEach((doc) => {
      filteredDocs = filteredDocs.filter((item) => item.key !== doc.key);
    });
    const body = {
      documentation: {
        documents: [...engagementData.documentation.documents, ...filteredDocs],
      },
      status: {
        ...engagementData.status,
        "portal-documentation": {
          complete: true,
        },
      },
    };
    const doucmentationUpdate = await updateEngagementData({
      engagementID: engagementID,
      body: body,
    });
    if (isDev) console.log({ doucmentationUpdate });

    // Upload File to Documents Library
    await Promise.all(
      uploadParameters.map(async (uploadParam) => {
        const body = {
          ...uploadParam,
          user: userName,
          folder: "Documentation",
        };
        return uploadDirectoryFile({ body });
      })
    );
  };

  // Remove Document Pointers from Engagement Data
  const deleteCallback = async (fileName) => {
    const filteredDocuments = engagementData.documentation.documents.filter(
      (doc) => doc.key.split("/").pop() !== fileName
    );
    const body = {
      documentation: {
        documents: [...filteredDocuments.map((o) => ({ ...o }))],
      },
      status: {
        ...engagementData.status,
        "portal-documentation": {
          complete: filteredDocuments.length > 0,
        },
      },
    };
    await updateEngagementData({
      engagementID: engagementID,
      body: body,
    });
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const loading =
    isUploadingFile || isUploadingDirectoryFile || isUpdatingEngagementData;

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-3 px-8 py-8">
      <div className="col-span-1 flex flex-col w-full">
        <div {...getRootProps()}>
          <input {...getInputProps()} />
          <div className="flex flex-col items-center h-96 border-4 border-dashed border-sprxClientPortalLightBlue rounded-lg py-12">
            <img
              className="h-24 w-24 my-4 "
              src={"assets/images/Upload_Icon.svg"}
              alt={"Upload"}
            />
            <motion.h3 className="text-xl text-center font-semibold text-gray-900">
              Drag and Drop files to upload
            </motion.h3>
            <motion.p className="mt-1 text-sm text-center font-light tracking-tight text-text-gray-900">
              or
            </motion.p>
            <motion.button
              className={classNames(
                "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                "inline-flex items-center justify-center w-2/4 mt-2 px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white focus:outline-none focus:ring-none"
              )}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              onClick={() => performClick("file-dialog-mass-import")}
            >
              Browse
            </motion.button>
            <motion.input
              ref={inputFileRef}
              id="file-dialog-mass-import"
              multiple
              type="file"
              style={{ display: "none" }}
              accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain,.msg,.json,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.HEIC"
              onChange={(e) => onDocumentUpload(e)}
            />
          </div>
        </div>
      </div>
      <FileListPane
        engagementData={engagementData}
        tagFilter={"documentation"}
        user={user}
        type={"Documentation"}
        loading={loading}
        deleteCallback={deleteCallback}
      />
    </div>
  );
};
