import { PaperAirplaneIcon } from "@heroicons/react/solid";
import * as React from "react";

interface IChatInputProps {
  message: string;
  setMessage: (event: any) => void;
  handleSend: () => void;
  handleKeyPress: (event: any) => void;
}

export const ChatInput = ({
  message,
  setMessage,
  handleSend,
  handleKeyPress,
}: IChatInputProps) => {
  return (
    <div className="mt-4 relative">
      <textarea
        className="block w-full pl-4 pr-16 py-2 border rounded-lg text-wrap resize-none overflow-hidden"
        placeholder="Type a message..."
        value={message}
        onChange={(e) => setMessage(e)}
        onKeyPress={handleKeyPress}
        rows={1}
        style={{ maxHeight: "72px" }}
      />
      <button
        className="absolute right-2 bottom-0 mb-1 bg-blue-500 hover:bg-blue-600 text-white rounded-md p-2"
        onClick={handleSend}
      >
        <PaperAirplaneIcon className="h-4 w-4 rotate-45" />
      </button>
    </div>
  );
};
