import * as React from "react";
import { ImportTypeMapKeys } from ".";

import {
  EmployeeLineItem,
  SupplyLineItem,
  ComputerLeaseLineItem,
  ContractorLineItem,
  LineItem,
} from "../../../main/R&D/types/RdData";

import { ImportType } from "../../../ui-components/FlatfileSpace";

// wageData,
// supplyData,
// computerLeaseData,
// contractData,
// projectData,

// // Functions
// updateLineItem,
// setBypassCalculation,
// refreshData,
// deleteProject: async (projectID: string) =>
//   await onDeleteProject(projectID),
// updateData,

// // Booleanxss
// isDownloadingFile: isDownloadingFile && !isRefreshing,
// isCreatingJSONDataFile,
// isUploadingFile: isUploadingFile,
// isDeletingProject,
// bypassCalculation,

// // Errors
// dataArrayErrors: errors,

export type DataArrayType = {
  // Object Arrays
  wageData: EmployeeLineItem[];
  supplyData: SupplyLineItem[];
  computerLeaseData: ComputerLeaseLineItem[];
  contractData: ContractorLineItem[];
  projectData: any[];

  // Functions
  setBypassCalculation: (bool: boolean) => void; // Replace with the actual function signature if different
  refreshData: (str: ImportType | string) => Promise<void>;
  deleteProject: (projectID: string) => Promise<void>;
  updateData: (arr: LineItem[], type: ImportType | string) => Promise<void>;
  updateLineItem: (
    item: LineItem,
    type: ImportType | ImportTypeMapKeys
  ) => Promise<void>;

  // Booleans
  isDownloadingFile: boolean;
  isCreatingJSONDataFile: boolean;
  isUploadingFile: boolean;
  isDeletingProject: boolean;
  bypassCalculation: boolean;

  // Errors
  dataArrayErrors: any; // Replace with the actual error type if known
};

const DataArrayContext = React.createContext<DataArrayType | null>(null);
export default DataArrayContext;
