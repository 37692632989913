import { motion } from "framer-motion";

export const StateCreditsWelcomeMessage = (props) => {
    return (
        <div className="flex w-full py-4">
            <div className="flex flex-col w-8/12 rounded-2xl p-4 bg-sprxClientPortalLightTeal">
                {!props.loading ? (
                  <>
                    <p className="text-sm mt-2 pr-40 text-gray-900">
                      Welcome {props.name},
                    </p>
                    <p className="text-sm mt-2 pr-40 text-gray-900">
                      You've entered data to compute {props.stateCount} state credits.
                    </p>      
                    <p className="text-sm mt-2 pr-40 text-gray-900">
                      Use the tiles below to enter information for states you would like to compute credits for. As a note, tile will only display for states in which we havve identified QREs.
                    </p>
                  </>
                ): (
                  <>
                    <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
                    <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-3/5"></div>
                    <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-2/3"></div>
                    <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-2/3"></div>
                    <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
                  </>
                )}
            </div>
            <div className="flex -ml-64">
                <img
                style={{ width: 410.25, height: 136.125 }}
                src={"assets/images/Dashboard_Illustration.svg"}
                alt="SPRX.tax"
                />
            </div>
            <motion.div
                  className="flex flex-col justify-center items-center w-3/12 ml-4 rounded-2xl border-2 border-sprxClientPortalDarkGreen shadow-xl"
                  whileHover={{ scale: 1.01 }}
                >
                  <h1 className="text-xl text-center font-semibold text-gray-900">
                    Click here to {props?.showAll ? "show only relevant forms." : "show all state credit forms."}
                  </h1>
                  <motion.toggle
                    type="button"
                    className="inline-flex items-center justify-center w-1/2 mt-2 px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-xl shadow-sm text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none"
                    whileHover={{ scale: 1.1 }}
                    whileTap={{ scale: 0.95 }}
                    onClick={async () => props.onClick()}
                  >
                    {props?.showAll ? "Hide" : "Show"}
                  </motion.toggle>
            </motion.div>
        </div>
    );
};