import DataSpacesContext from "./DataSpacesContext";
import {
  useListDataSpacesQuery,
  useCreateDataSpaceMutation,
  useGetDataSpaceQuery,
  useUpdateDataSpaceMutation,
  useDeleteDataSpaceMutation,
  useListAdminDataSpacesQuery,
  useSendDataSpaceFileUpdateMutation,
} from "../../api/dataSpacesAPI";
import { useUsers } from "../Users/useUsers";

const DataSpacesProvider = ({ children }) => {
  const { user } = useUsers();
  const isLoadingUser = user === undefined || user?.companyID === undefined;

  const { companyID } = user;

  const [
    deleteDataSpace,
    { isLoading: isDeletingDataSpace, error: deleteDataSpaceError },
  ] = useDeleteDataSpaceMutation();

  const [
    updateDataSpace,
    { isLoading: isUpdatingDataSpace, error: updateDataSpaceError },
  ] = useUpdateDataSpaceMutation();

  const {
    data: adminDataSpacesList,
    isLoading: isListingAdminDataSpaces,
    error: listingAdminDataSpaceError,
  } = useListAdminDataSpacesQuery();

  const {
    data: dataSpacesList,
    isLoading: isListingDataSpaces,
    error: listingDataSpaceError,
  } = useListDataSpacesQuery({ companyID });

  const {
    data: dataSpace,
    isLoading: isGettingDataSpace,
    error: getDataSpaceError,
  } = useGetDataSpaceQuery(user?.data?.activeDataSpaceID);

  const [
    createDataSpace,
    { isLoading: isCreatingDataSpace, error: createDataSpaceError },
  ] = useCreateDataSpaceMutation();

  const [sendDataSpaceFileUpdate] = useSendDataSpaceFileUpdateMutation();

  let errors = {};
  const hasError = (i) => i !== undefined;
  if (hasError(deleteDataSpaceError))
    errors = { ...errors, deleteDataSpaceError };
  if (hasError(updateDataSpaceError))
    errors = { ...errors, updateDataSpaceError };
  if (hasError(listingDataSpaceError) && !isLoadingUser)
    errors = { ...errors, listingDataSpaceError };
  if (hasError(getDataSpaceError) && !isLoadingUser)
    errors = { ...errors, getDataSpaceError };
  if (hasError(createDataSpaceError))
    errors = { ...errors, createDataSpaceError };
  if (hasError(listingAdminDataSpaceError))
    errors = { ...errors, listingAdminDataSpaceError };
  Object?.keys(errors)?.forEach((i) => console.error(i, errors[i]));

  const value = {
    dataSpace,
    dataSpacesList,
    adminDataSpacesList,
    createDataSpace,
    updateDataSpace,
    deleteDataSpace,
    sendDataSpaceFileUpdate,

    isDeletingDataSpace: isDeletingDataSpace || isLoadingUser,
    isUpdatingDataSpace: isUpdatingDataSpace || isLoadingUser,
    isListingAdminDataSpaces: isListingAdminDataSpaces || isLoadingUser,
    isListingDataSpaces: isListingDataSpaces || isLoadingUser,
    isGettingDataSpace: isGettingDataSpace || isLoadingUser,
    isCreatingDataSpace: isCreatingDataSpace || isLoadingUser,
    dataSpaceErrors: errors,
  };

  return (
    <DataSpacesContext.Provider value={value}>
      {children}
    </DataSpacesContext.Provider>
  );
};
export default DataSpacesProvider;
