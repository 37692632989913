import React, { useState, useCallback } from "react";
import { usePlaidLink } from "react-plaid-link";

import { useExchangePlaidTokenMutation } from "../api/paymentsAPI";

import { Button, SprxLoading } from ".";

const PlaidLinkComponent = ({
  token,
  onLinkSuccess,
  setAccountInfo,
  setBankToken,
}) => {
  const [exchangePlaidToken, { isLoading: isExchangingPlaidLinkToken }] =
    useExchangePlaidTokenMutation();

  const onSuccess = useCallback(async (public_token, metadata) => {
    const response = await exchangePlaidToken({
      publicToken: public_token,
      bankAccountID: metadata.accounts[0].id,
    });

    if (process.env.REACT_APP_STAGE === "dev") console.log({exchangePlaidTokenResponse: response});

    if (response.data.status === true) {
      setBankToken(response.data.bankAccountToken);
      setAccountInfo(metadata);
      onLinkSuccess(true);
    };
    
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onExit = useCallback((data) => {
    // console.log("🚀 ~ onExit ~ data", data);
  }, []);

  const onEvent = useCallback((data) => {
    // console.log("🚀 ~ onEvent ~ data", data);
  }, []);

  const config = {
    token,
    onSuccess,
    onExit,
    onEvent,
  };

  const { open, ready } = usePlaidLink(config);

  return isExchangingPlaidLinkToken ? (
    <div className="sm:flex sm:flex-col sm:align-center">
      <div className="sm:w-2/12 w-full relative self-center flex">
        <SprxLoading />
      </div>
    </div>
  ) : (
    <div className="flex justify-center">
      <Button
        onClick={() => open()}
        disabled={!ready}
        primary={true}
        secondary={false}
        buttonClassName={"w-64"}
        textClassName=""
      >
        <p>Connect a bank account</p>
      </Button>
    </div>
  );
};

const PlaidLink = (props) => {
  const [token] = useState(props.token);

  const [linkSuccess, setLinkSuccess] = useState(false);
  const [accountInfo, setAccountInfo] = useState(null);
  const [bankToken, setBankToken] = useState(null);

  return token === null ? (
    <div className="sm:flex sm:flex-col sm:align-center">
      <div className="sm:w-2/12 w-full relative self-center flex">
        <SprxLoading />
      </div>
    </div>
  ) : linkSuccess ? (
    <div className="sm:flex flex-col sm:align-center">
      <div className="sm:w-2/12 w-full relative self-center flex flex-col ">
        <p className="text-center">{`Bank Account:`}</p>
        <p className="text-center">{`${accountInfo.institution.name} ${accountInfo.accounts[0].name}`}</p>
        <div className="flex justify-center mt-4">
          <Button
            onClick={() => props.onSubmit(bankToken)}
            disabled={bankToken === null ? true : false}
            primary={false}
            secondary={true}
            buttonClassName={"w-64"}
            textClassName=""
          >
            <p>Submit</p>
          </Button>
        </div>
      </div>
    </div>
  ) : (
    <PlaidLinkComponent
      token={token}
      onLinkSuccess={(val) => setLinkSuccess(val)}
      setAccountInfo={(val) => setAccountInfo(val)}
      setBankToken={(val) => setBankToken(val)}
    />
  );
};

export default PlaidLink;
