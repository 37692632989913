import { baseAPI } from "./baseAPI";

const paymentsAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getPlaidLinkToken: builder.mutation({
      query: (body) => ({
        url: `/rd/create-plaid-link`,
        method: "POST",
        body: body,
      }),
    }),
    exchangePlaidToken: builder.mutation({
      query: (body) => ({
        url: `/rd/exchange-plaid-token`,
        method: "POST",
        body: body,
      }),
    }),
    createBillingCustomer: builder.mutation({
      query: (body) => ({
        url: `/rd/create-billing-customer`,
        method: "POST",
        body: body,
      }),
    }),
    chargeBillingCustomer: builder.mutation({
      query: (body) => ({
        url: `/rd/charge-billing-customer`,
        method: "POST",
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetPlaidLinkTokenMutation,
  useExchangePlaidTokenMutation,
  useCreateBillingCustomerMutation,
  useChargeBillingCustomerMutation,
} = paymentsAPI;
