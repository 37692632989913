export const Pill = ({
  title,
  value,
}: {
  title: string;
  value: string | number;
}) => {
  const formattedValue =
    typeof value === "number" ? `$${value.toLocaleString()}` : value;

  return (
    <div className="my-1 mr-1 inline-flex items-center rounded-full border border-gray-300 px-3 py-1 text-sm">
      <span className="mr-3 font-semibold text-gray-700">{title}</span>
      <div className="mx-1 h-full border-l border-gray-300"></div>
      <span className="text-gray-500">{formattedValue}</span>
    </div>
  );
};

// Pulsating Pill Loading
export const LoadingPill = () => (
  <div className="my-1 mr-1 inline-flex w-28 animate-pulse items-center rounded-full border border-gray-300 bg-gray-300 px-3 py-1 text-sm">
    <div className="flex h-full w-full items-center justify-center">
      <div className="h-4 w-4 rounded-full"></div>
    </div>
  </div>
);
