import { Documents } from "./Documents";
import * as React from "react";

export const DocumentsContainer = () => {
  return (
    <div>
      <Documents />
    </div>
  );
};
