import { useState, useEffect } from "react";
import { ChatHistory } from "./ChatHistory";
import { type Message } from "./ChatHistory";
import { ChatInput } from "./ChatInput";

interface IChatProps {
  onSendQuery: (message: string) => void;
  messages: Message[];
  isThinking: boolean;
}

export const Chat = ({ onSendQuery, messages, isThinking }: IChatProps) => {
  const [message, setMessage] = useState<string>("");

  // Send message on enter key press
  const handleKeyPress = (event: any) => {
    if (message.length !== 0 && event.key === "Enter") handleSend();
  };

  // Remove new line character on first key press
  useEffect(() => {
    if (message.length === 1 && message === "\n") setMessage("");
  }, [message]);

  // Shared send message logic
  const handleSend = () => {
    onSendQuery(message);
    setMessage("");
  };

  // Adjust height of textarea
  const adjustHeight = (e: any) => {
    if (e.target.value !== "\n") {
      e.target.style.height = "inherit";
      e.target.style.height = `${e.target.scrollHeight}px`;
      // This line of code will help keep the height in check
      if (e.target.scrollHeight >= 72) {
        // Assuming 24px line-height
        e.target.style.height = "72px"; // Maximum height for 3 lines
        e.target.style.overflowY = "auto"; // Allow scrolling
      } else {
        e.target.style.overflowY = "hidden"; // Hide scrollbar when not needed
      }
    }
  };

  // Handle message input
  const handleMessageInput = (event: any) => {
    setMessage(event.target.value);
    adjustHeight(event);
  };

  return (
    <div className="flex flex-col h-78vh p-4">
      <ChatHistory messages={messages} isThinking={isThinking} />
      <ChatInput
        message={message}
        setMessage={handleMessageInput}
        handleSend={handleSend}
        handleKeyPress={handleKeyPress}
      />
    </div>
  );
};
