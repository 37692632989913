import { EngagementsList } from "./EngagementsList";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useListEngagementsFromCompanyIdQuery } from "../../../api/engagmentAPI";
import { useListCompaniesQuery } from "../../../api/companyAPI";
import { useState } from "react";
import { ListViewLoading } from "../../../ui-components/ListViewLoading";
import { EngagementListEmpty } from "../../../ui-components/EngagementListEmpty";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { CompaniesList } from "../../../ui-components/CompaniesList";
import { ListViewError } from "../../../ui-components/ListViewError";

export const EngagementsAndCompaniesListContainer = ({
  providedCompanyID,
  providedCompanyName,
  closeEngagement,
}: {
  providedCompanyID?: string;
  providedCompanyName?: string;
  closeEngagement?: () => void;
}) => {
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const usersAPI: any = useUsers();
  const companyDataAPI = useCompanyData();

  const selectedCompany = providedCompanyID ?? usersAPI?.user?.companyID;

  const isSprxEmployee =
    selectedCompany === process.env.REACT_APP_DEFAULT_COMPANY &&
    usersAPI?.user?.role === "admin";

  const {
    data: engagements,
    isLoading: isListingEngagements,
    error: listEngagementsError,
  } = useListEngagementsFromCompanyIdQuery(selectedCompany);

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useListCompaniesQuery(companyNameFilter);

  const isLoading =
    !usersAPI ||
    // usersAPI.isGettingUser ||
    isListingEngagements ||
    (isLoadingCompanies && isSprxEmployee);

  const hasError = listEngagementsError || companiesError;

  if (isLoading) return <ListViewLoading />;

  if (hasError) return <ListViewError type="R&D" />;

  if (isSprxEmployee && !providedCompanyID)
    return (
      <CompaniesList
        type="R&D"
        companies={companies}
        companyNameFilter={companyNameFilter}
        setCompanyNameFilter={setCompanyNameFilter}
        ListContainer={EngagementsAndCompaniesListContainer}
      />
    );
  if (engagements?.length === 0)
    return (
      <EngagementListEmpty
        type="Engagement"
        companyName={
          usersAPI.user.role === "admin"
            ? providedCompanyName
            : companyDataAPI.companyData.companyName
        }
        closeEngagement={closeEngagement ? closeEngagement : null}
      />
    );

  return (
    <EngagementsList
      companyName={
        usersAPI.user.role === "admin"
          ? providedCompanyName
          : companyDataAPI.companyData.companyName
      }
      engagements={engagements}
      closeEngagement={closeEngagement ? closeEngagement : null}
    />
  );
};
