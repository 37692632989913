import { useState } from "react";
import { motion } from "framer-motion";
import { PhoneIcon, MailIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { useSelector } from "react-redux";
import { useCreateSupportTicketMutation } from "../../api/engagmentAPI";
import { getDefaultContactFormFields, getContactFormShape } from "./utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useEngagementData } from "../../rtk/R&D/EngagementData/useEngagementData";
import { ContactModel } from "./types/contact";
interface SuccessMessageProps {
  onDismiss: () => void;
}

const SuccessMessage = ({ onDismiss }: SuccessMessageProps) => {
  return (
    <div className="rounded-md bg-green-50 p-4">
      <div className="flex">
        <div className="flex-shrink-0">
          <CheckCircleIcon
            className="h-5 w-5 text-green-400"
            aria-hidden="true"
          />
        </div>
        <div className="ml-3">
          <h3 className="text-sm font-medium text-green-800">
            Contact request submitted
          </h3>
          <div className="mt-2 text-sm text-green-700">
            <p>A team member will contact you shortly.</p>
          </div>
          <div className="mt-4">
            <div className="-mx-2 -my-1.5 flex">
              <button
                onClick={() => onDismiss()}
                type="button"
                className="ml-3 rounded-md bg-green-50 px-2 py-1.5 text-sm font-medium text-green-800 hover:bg-green-100 focus:outline-none focus:ring-2 focus:ring-green-600 focus:ring-offset-2 focus:ring-offset-green-50"
              >
                Dismiss
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

/**
 * TODO: Incorporate Empty State & Submitting State Rendering
 * const { isValid, errors } = formState;
 * const [createSupportTicket, { isLoading: isCreatingTicket }] =
 * . const { data: engagementData, isLoading: isLoadingEngagementData } =
 *
 **/

export const ContactForm = () => {
  const user = useSelector(({ auth }: any) => auth.user);
  const { engagementData } = useEngagementData();

  const [submitted, setSubmitted] = useState<boolean>(false);

  const [createSupportTicket] = useCreateSupportTicketMutation();

  // TODO: Include { setError, watch, reset } Methods via useForm
  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: getDefaultContactFormFields(user),
    resolver: yupResolver(getContactFormShape()),
  });

  const { errors } = formState;
  const onSubmit = async (model: ContactModel) => {
    setSubmitted(true);
    const createTicketResponse = await createSupportTicket({
      body: {
        subject: model.subject,
        email: model.email,
        phoneNumber: model.phone,
        content: model.name,
        userID: user.userID,
        companyName: engagementData.companyName,
        companyID: engagementData.companyID,
      },
    });
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ createTicketResponse });
  };

  return (
    <main className="flex-1 lg:overflow-hidden">
      <div className="py-6">
        {/*Top bar and logo */}
        <div className="flex flex-row items-center justify-between mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Contact SPRX</h1>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        <div className="relative bg-white">
          <div className="relative mx-auto lg:grid lg:grid-cols-5">
            <div className="py-8 px-6 lg:col-span-2 lg:px-8 lg:py-16 xl:pr-12">
              <div className="mx-auto max-w-lg">
                <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-3xl">
                  Need assistance?
                </h2>
                <p className="mt-3 text-lg leading-6 text-gray-500">
                  Feel free to give us a call, send us an email, or fill out the
                  contact form with any questions, feedback, or concerns.
                </p>
                <dl className="mt-8 text-base text-gray-500">
                  <div className="mt-6">
                    <dt className="sr-only">Phone number</dt>
                    <dd className="flex">
                      <PhoneIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">(855) 958-5740</span>
                    </dd>
                  </div>
                  <div className="mt-3">
                    <dt className="sr-only">Email</dt>
                    <dd className="flex">
                      <MailIcon
                        className="h-6 w-6 flex-shrink-0 text-gray-400"
                        aria-hidden="true"
                      />
                      <span className="ml-3">support@sprx.tax</span>
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
            <div className="bg-white py-8 px-6 lg:col-span-3 lg:py-16 lg:px-8 xl:pl-12">
              <div className="mx-auto max-w-lg lg:max-w-none">
                {submitted ? (
                  <SuccessMessage onDismiss={() => setSubmitted(false)} />
                ) : (
                  <form
                    onSubmit={handleSubmit((model) => onSubmit(model))}
                    method="submit"
                    className="grid grid-cols-1 gap-y-6"
                  >
                    <div>
                      <label htmlFor="full-name" className="sr-only">
                        Name
                      </label>
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            name="name"
                            id="name"
                            autoComplete="name"
                            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sprxClientPortalLightBlue focus:ring-sprxClientPortalLightBlue"
                          />
                        )}
                      />
                      {errors?.hasOwnProperty("name") ? (
                        <p className=" pl-2 text-sprxOrange">Invalid name</p>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="email" className="sr-only">
                        Email
                      </label>
                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            id="email"
                            name="email"
                            type="email"
                            autoComplete="email"
                            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sprxClientPortalLightBlue focus:ring-sprxClientPortalLightBlue"
                          />
                        )}
                      />
                      {errors?.hasOwnProperty("email") ? (
                        <p className=" pl-2 text-sprxOrange">Invalid email</p>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="phone" className="sr-only">
                        Phone
                      </label>
                      <Controller
                        name="phone"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            name="phone"
                            id="phone"
                            autoComplete="tel"
                            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sprxClientPortalLightBlue focus:ring-sprxClientPortalLightBlue"
                            placeholder="Phone"
                          />
                        )}
                      />
                      {errors?.hasOwnProperty("phone") ? (
                        <p className=" pl-2 text-sprxOrange">
                          Invalid phone number
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="subject" className="sr-only">
                        Subject
                      </label>
                      <Controller
                        name="subject"
                        control={control}
                        render={({ field }) => (
                          <input
                            {...field}
                            type="text"
                            name="subject"
                            id="subject"
                            autoComplete="tel"
                            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sprxClientPortalLightBlue focus:ring-sprxClientPortalLightBlue"
                            placeholder="Subject"
                          />
                        )}
                      />
                      {errors?.hasOwnProperty("phone") ? (
                        <p className=" pl-2 text-sprxOrange">
                          Invalid phone number
                        </p>
                      ) : null}
                    </div>
                    <div>
                      <label htmlFor="message" className="sr-only">
                        Message
                      </label>
                      <Controller
                        name="message"
                        control={control}
                        render={({ field }) => (
                          <textarea
                            {...field}
                            id="message"
                            name="message"
                            rows={4}
                            className="block w-full rounded-md border-gray-300 py-3 px-4 placeholder-gray-500 shadow-sm focus:border-sprxClientPortalLightBlue focus:ring-sprxClientPortalLightBlue"
                          />
                        )}
                      />
                      {errors?.hasOwnProperty("message") ? (
                        <p className=" pl-2 text-sprxOrange">Invalid message</p>
                      ) : null}
                    </div>
                    <div>
                      <motion.button
                        type="submit"
                        className="inline-flex justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue py-3 px-6 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                      >
                        Submit
                      </motion.button>
                    </div>
                  </form>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
