import { baseAPI } from "./baseAPI";

const computeCreditAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    runTaxMLModel: builder.mutation({
      query: (body) => ({
        url: `/rd/run-ml`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    computeTaxRDCredit: builder.mutation({
      query: ({ engagementID }) => ({
        url: `/rd/compute-rd-credit/${engagementID}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    allocateProjectCosts: builder.mutation({
      query: ({ engagementID }) => ({
        url: `/rd/allocate-project-costs/${engagementID}`,
        method: "POST",
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    computeTaxStateCredits: builder.mutation({
      query: (body) => ({
        url: `/rd/compute-state-rd-credit`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    extractTaxSupportingDocuments: builder.mutation({
      query: (body) => ({
        url: `/rd/start-document-extraction`,
        method: "POST",
        body: body,
      }),
    }),
    generateProjectMemos: builder.mutation({
      query: ({ engagementID }) => ({
        url: `/rd/generate-project-memos/${engagementID}`,
        method: "POST",
      }),
    }),
    computeFixedBasePercentage: builder.mutation({
      query: (body) => ({
        url: `/rd/compute-fixed-base`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    computeAcquisitionBase: builder.mutation({
      query: (body) => ({
        url: `/rd/compute-acquisition`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    createProjectCompletions: builder.mutation({
      query: (body) => ({
        url: `/rd/project-completions`,
        method: "POST",
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useRunTaxMLModelMutation,
  useComputeTaxRDCreditMutation,
  useExtractTaxSupportingDocumentsMutation,
  useComputeTaxStateCreditsMutation,
  useAllocateProjectCostsMutation,
  useGenerateProjectMemosMutation,
  useComputeFixedBasePercentageMutation,
  useComputeAcquisitionBaseMutation,
  useCreateProjectCompletionsMutation
} = computeCreditAPI;
