import { useData } from "../hooks/useData";
import { LoadingPill, Pill } from "./Pill";

export const UsersList = ({
  users,
  isLoadingUsers,
}: {
  users: any;
  isLoadingUsers: boolean;
}) => {
  if (isLoadingUsers) {
    return (
      <div>
        <div className="text-lg font-bold">Associated Users</div>
        <div className="flex flex-row flex-wrap justify-start my-2">
          {Array(4)
            .fill(null)
            .map(() => (
              <LoadingPill />
            ))}
        </div>
      </div>
    );
  }
  return (
    <div>
      <div className="text-lg font-bold">Associated Users</div>
      <div className="flex flex-row flex-wrap justify-start my-2">
        {users?.map((user, index) => (
          <Pill key={index} title={user.fullName} value={user.role} />
        ))}
      </div>
    </div>
  );
};
