import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import classNames from "classnames";
import _ from "lodash";
import {
  getCreateEngagementFormDefaultValues,
  getCreateEngagementFormShape,
} from "./util";
import { useCompanyData } from "../../../../../rtk/CompanyData/useCompanyData";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { motion } from "framer-motion";
import { CreateFormWrapper } from "../../../components/CreateFormWrapper";
import Select from "react-select";

interface CreateEngagementFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreateEngagement: (data: any) => Promise<void>;
}

export const CreateEngagementForm = ({
  open,
  setOpen,
  onCreateEngagement,
}: CreateEngagementFormProps) => {
  const { companies } = useCompanyData();
  const energyEngagementAPI = useEnergyEngagement();
  const loading = energyEngagementAPI.createEngagementData.isLoading;

  const { control, formState, handleSubmit, setValue } = useForm({
    mode: "onChange",
    defaultValues: getCreateEngagementFormDefaultValues(),
    resolver: yupResolver(getCreateEngagementFormShape()),
  });

  const { isValid, dirtyFields } = formState;

  return (
    <CreateFormWrapper open={open} setOpen={setOpen} type="Engagement">
      <form
        className="sm:flex sm:flex-col sm:items-center"
        onSubmit={handleSubmit(async (model) => {
          await onCreateEngagement(model);
          setOpen(false);
        })}
      >
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="name" className="text-left">
                Engagement Name <span className="text-sprxOrange">*</span>
              </label>
              <input
                {...field}
                type="text"
                name="Engagement Name"
                id="name"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                placeholder="Construction Corp"
              />
            </div>
          )}
        />
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="companyName" className="text-left">
                Company Name <span className="text-sprxOrange">*</span>
              </label>
              <div className="mt-1">
                <Select
                  {...field}
                  onChange={(val) => setValue("companyName", val as any)}
                  options={companies?.map((company) => ({
                    value: company.companyID,
                    label: company.companyName,
                  }))}
                  styles={{
                    menuList: (provided) => ({
                      ...provided,
                      maxHeight: "300px",
                      overflowY: "auto",
                    }),
                    option: (provided) => ({
                      ...provided,
                      fontSize: "14px",
                    }),
                  }}
                />
              </div>
            </div>
          )}
        />
        <Controller
          name="taxYearStart"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="taxYearStart" className="text-left">
                Tax Year Start Date <span className="text-sprxOrange">*</span>
              </label>
              <input
                id={"taxYearStart"}
                {...field}
                className={classNames(
                  "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                )}
                type={"text"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder={"Tax Year Start Date"}
              />
            </div>
          )}
        />
        <Controller
          name="taxYearEnd"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="taxYearEnd" className="text-left">
                Tax Year End Date <span className="text-sprxOrange">*</span>
              </label>
              <input
                id={"taxYearEnd"}
                {...field}
                className={classNames(
                  "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                )}
                type={"text"}
                onFocus={(e) => (e.target.type = "date")}
                onBlur={(e) => (e.target.type = "text")}
                placeholder={"Tax Year End Date"}
              />
            </div>
          )}
        />

        <motion.div className="mt-12 flex w-full flex-row items-end justify-end">
          <motion.button
            type="submit"
            data-test="Save & Close"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={_.isEmpty(dirtyFields) || !isValid}
            className={
              "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
              (!isValid
                ? "cursor-not-allowed bg-opacity-50"
                : " hover:bg-sprxClientPortalDarkBlue")
            }
          >
            {loading ? (
              <div className="flex h-5 w-full flex-col items-center justify-center">
                <p
                  className={"h-2 w-full animate-pulse rounded-md bg-gray-100"}
                ></p>
              </div>
            ) : (
              "Create"
            )}
          </motion.button>
        </motion.div>
      </form>
    </CreateFormWrapper>
  );
};
