import { useState } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export const Selector = ({ options, selectedOption, onSelectClick }) => {
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen((prev) => !prev);
  };

  const handleFilterClick = (option) => {
    onSelectClick(option);
    setMenuOpen(false);
  };

  return (
    <div className="flex w-full justify-between text-right mr-8 z-40">
      <div className="relative inline-block text-left">
        <div className="flex flex-row w-full items-center h-full">
          <button
            onClick={toggleMenu}
            className="inline-flex h-full w-full justify-center rounded-md pl-2 pr-4 py-2 text-sm font-medium text-white hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75"
          >
            <p className="font-bold text-black text-base">{selectedOption}</p>

            <div className="flex h-full items-center justify-center ml-4">
              {options.length > 0 ? (
                <ChevronDownIcon
                  className={`${
                    menuOpen ? "rotate-180 transform" : ""
                  } h-5 w-5 text-black`}
                />
              ) : null}
            </div>
          </button>
        </div>

        {menuOpen && (
          <div className="absolute right-0 mt-2 w-full origin-top-right divide-y divide-gray-200 rounded-md bg-gray-100 shadow-lg ring-1 ring-black/5 focus:outline-none">
            {options.map((option, index) => {
              return (
                <div key={index} className="px-1 py-1">
                  <button
                    onClick={() => handleFilterClick(option)}
                    className="group flex w-full items-center rounded-md px-2 py-2 text-sm text-gray-900 hover:bg-sprxClientPortalLightBlue hover:text-white"
                  >
                    {option}
                  </button>
                </div>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
