import GaugeChart from "react-gauge-chart";
export const UploadGaugeColumnLoading = (props) => {
  const completePercentage = 0;
  return (
    <div className="col-span-1 flex min-h-64vh w-full flex-col">
      <div className="flex w-full flex-col overflow-hidden rounded-lg shadow-lg">
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <h1 className="text-center text-xl font-semibold text-gray-900">
            Progress
          </h1>
          <GaugeChart
            id="gauge-chart1"
            className={"mt-4 flex max-w-xl items-center justify-center"}
            animate={true}
            arcsLength={[
              completePercentage / 100,
              1 - completePercentage / 100,
            ]}
            colors={["#5BE12C", "#DFF6FF"]}
            needleColor={"#3355FF"}
            needleBaseColor={"#3355FF"}
            nrOfLevels={1}
            hideText={true}
            percent={completePercentage / 100}
          />
          <div className="relative mt-4">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-600" />
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Wage Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
              </dd>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Supply Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
              </dd>
            </div>
          </div>
          <div className="mt-4 flex w-full flex-col overflow-hidden rounded-lg shadow-lg">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Contractor Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
