export const MultiSelectComponent = (props) => {
    const { options, handleOptionSelect, values, disabled } = props;
    return (
        <div className="grid grid-cols-2 gap-2 mb-4">
            {options.map((option, index) => (
                <label key={index} className="flex items-center space-x-2">
                    <input
                        type="checkbox"
                        checked={
                            values.hasOwnProperty(option) ? values[option] : false
                        }
                        className="form-checkbox text-blue-500 focus:ring-2 focus:ring-blue-300"
                        onChange={() => handleOptionSelect(option)}
                        disabled={disabled}
                    />
                    <span>{option}</span>
                </label>
            ))}
        </div>
    );
};