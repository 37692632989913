import { useRef, useEffect } from "react";
import { ChatHistoryStyleOverride } from "./ChatHistoryStyleOverride";
import Markdown from "react-markdown";

interface IChatHistoryProps {
  messages: Message[];
  isThinking: boolean;
}

type Sender = "user" | "bot";

export type Message = {
  message: string;
  sender: Sender;
  documents?: Document[];
};

type Document = {
  title: string;
  signedURL: string;
};

export const ChatHistory = ({ messages, isThinking }: IChatHistoryProps) => {
  const bottomRef = useRef<any>(null);

  useEffect(() => {
    bottomRef.current?.scrollIntoView({ behavior: "smooth" });
  }, [bottomRef, messages]); // Dependency array includes messages, so it runs every time messages update

  return (
    <div className="flex-grow overflow-auto px-3">
      <ChatHistoryStyleOverride />
      <div className="flex flex-col h-full">
        <div className="overflow-auto p-3 flex-grow">
          <div className="absolute top-0 left-0 right-0 h-6 bg-gradient-to-b from-white to-transparent"></div>
          {messages?.map((message: Message, index: number) => {
            return (
              <div
                key={index}
                className={`flex flex-col ${
                  message.sender === "user" ? "items-end" : "items-start"
                }`}
              >
                <div
                  className={`message-bubble max-w-3/4 p-2 my-1 rounded-lg mt-4 ${
                    message.sender === "user"
                      ? "bg-blue-500 text-white user-message"
                      : "bg-gray-200 text-gray-900 other-message"
                  }`}
                >
                  {message.sender === "bot" ? (
                    <Markdown>{message.message}</Markdown>
                  ) : (
                    <p className="text-sm">{message.message}</p>
                  )}
                </div>
                {message?.documents && (
                  <DocumentLinks documents={message.documents} />
                )}
              </div>
            );
          })}
          <div ref={bottomRef} />
          {isThinking && <ThinkingBubble />}
        </div>
      </div>
    </div>
  );
};

const ThinkingBubble = () => {
  return (
    <div className="flex justify-start">
      <div className="p-2 my-1 rounded-lg bg-gray-200 text-gray-900 animate-pulse">
        <div className="h-2 w-2 bg-gray-500 rounded-full mr-1 inline-block"></div>
        <div className="h-2 w-2 bg-gray-500 rounded-full mr-1 inline-block"></div>
        <div className="h-2 w-2 bg-gray-500 rounded-full inline-block"></div>
      </div>
    </div>
  );
};

const DocumentLinks = ({ documents }: { documents: Document[] }) => {
  return (
    <div className="space-y-2 pt-2">
      {documents.map((doc, index) => {
        let docClass =
          "bg-purple-200 hover:bg-purple-300 text-purple-800 py-2 px-4 rounded-lg transition duration-200 ease-in-out ";
        if (index > 0) docClass += "ml-2";
        return (
          <a
            key={index}
            href={doc.signedURL}
            className={docClass}
            download={doc.title}
          >
            {doc.title}
          </a>
        );
      })}
    </div>
  );
};
