export const getDocumentationTableFields = () => {
  return [
    {
      headerName: "Document Name",
      field: "name",
      editable: false,
      filter: "agMultiColumnFilter",
      filterParams: {
        buttons: ["reset", "clear"],
        closeOnApply: true,
      },
    },
    {
      headerName: "File Format",
      field: "format",
      editable: false,
      filter: "agMultiColumnFilter",
      filterParams: {
        buttons: ["reset", "clear"],
        closeOnApply: true,
      },
    },
    {
      headerName: "Upload Date",
      field: "uploadDate",
      editable: false,
      filter: "agMultiColumnFilter",
      filterParams: {
        buttons: ["reset", "clear"],
        closeOnApply: true,
      },
    },
    {
      headerName: "Uploaded By",
      field: "uploadedBy",
      editable: true,
      filter: "agMultiColumnFilter",
      filterParams: {
        buttons: ["reset", "clear"],
        closeOnApply: true,
      },
    },
    {
      headerName: "Related System",
      field: "relatedSystem",
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: [
          "Unassigned",
          "Electrical",
          "Envelope",
          "HVAC",
          "Building Render",
          "Site Visit",
          "Other",
        ],
      },
    },
    {
      headerName: "Summary",
      field: "summary",
      cellEditor: "agLargeTextCellEditor",
      cellEditorPopup: true,
      editable: true,
      maxLength: 3000,
    },
  ];
};
