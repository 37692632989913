import { useState } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Switch } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useEnergyEngagement } from "../../../../rtk/Energy/Engagement/useEnergyEngagement";
import classNames from "classnames";
import { EditEngagementLoadng } from ".";
import { Engagement } from "../../../Energy/types";

interface EditEngagementFormProps {
  open: boolean;
  setOpen: any;
  loading: boolean;
  engagement: Engagement;
}

export const EditEngagementForm = ({
  open,
  setOpen,
  loading,
  engagement,
}: EditEngagementFormProps) => {
  const [status, setStatus] = useState({});

  //   useEffect(() => {
  //     if (engagement?.status) {
  //       const parsedStatus = Object.keys(engagement?.status)
  //         .filter((key) => key.includes("portal"))
  //         .reduce((obj, key) => {
  //           return Object.assign(obj, {
  //             [key]: engagement?.status[key],
  //           });
  //         }, {});
  //       setStatus(parsedStatus);
  //     }
  //   }, [engagement]);

  return (
    <AnimatePresence>
      {open && (
        <motion.div>
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <motion.div className="relative min-h-64vh min-w-64vw transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                <motion.div className="">
                  {/*Modal title, description, and close button */}
                  <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  {loading ? (
                    <EditEngagementLoadng />
                  ) : (
                    <div className="">
                      <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Engagement Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Engagement detail
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {engagement?.name}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Client Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {engagement?.companyName}
                            </dd>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Credit
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              ${engagement?.credit?.total ?? 0}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              S3 Link
                            </dt>
                            <a
                              target="_blank"
                              className="text-blue-400 underline"
                              href={`https://s3.console.aws.amazon.com/s3/buckets/${engagement?.s3Bucket?.name}?region=us-east-2&prefix=${engagement?.engagementID}/`}
                              rel="noreferrer"
                            >
                              {`${engagement?.s3Bucket?.name}`}
                            </a>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Data Table Entry
                            </dt>
                            <a
                              target="_blank"
                              className="text-blue-400 underline"
                              href={`https://us-east-2.console.aws.amazon.com/dynamodbv2/home?region=us-east-2#edit-item?table=${process.env.REACT_APP_STAGE}-sprx-tax-api-energy-engagements&itemMode=2&pk=${engagement?.engagementID}&sk&route=ROUTE_ITEM_EXPLORER`}
                              rel="noreferrer"
                            >
                              {`${engagement?.engagementID}`}
                            </a>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Current Status
                            </dt>
                            <div className="col-span-3 grid w-full grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-5 lg:grid-rows-4">
                              {Object.keys(status).map((key) => (
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                  key={key}
                                >
                                  <Switch
                                    checked={status[key]?.complete}
                                    onChange={async () => {
                                      //   await updateEngagementData({
                                      //     engagementID:
                                      //       engagement.engagementID,
                                      //     body,
                                      //   });
                                    }}
                                    className={classNames(
                                      status[key]?.complete
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        status[key]?.complete
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                                      )}
                                    />
                                  </Switch>
                                  <Switch.Label as="span" className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      {key.substr(7)}
                                    </p>
                                  </Switch.Label>
                                </Switch.Group>
                              ))}
                            </div>
                          </div>
                        </dl>
                      </div>
                    </div>
                  )}
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
