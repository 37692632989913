import axios from "axios";

import { baseAPI } from "./baseAPI";

const fileAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createJSONDataFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const file = new Blob([JSON.stringify(arg)], {
          type: "application/json",
        });
        return { data: file };
      },
    }),
    uploadFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: {
            key: arg.key,
            bucket: arg.bucket,
            action: "putObject",
            type: arg.type,
            metaData: arg?.metaData ?? {},
          },
        });
        const options = {
          headers: {
            "Content-Type": arg.type,
          },
        };
        if (!signedURL?.data?.data?.signedRequest || signedURL?.data?.data?.url)
          console.log({ signedURL });
        const signedRequest = signedURL?.data?.data?.signedRequest;
        const itemURL = signedURL.data.data.url;
        const uploadResults = await axios.put(signedRequest, arg.file, options);
        if (uploadResults.statusText === "OK") {
          let fileObj = {
            results: uploadResults,
            url: itemURL,
            // taxYear: arg.taxYear,
          };
          return { data: fileObj };
        }
      },
    }),
    uploadItem: builder.mutation({
      query: (body) => ({
        url: `/rd/upload-item`,
        method: "POST",
        body: body,
      }),
    }),
    getSignedURL: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: {
            key: arg.key,
            bucket: arg.bucket,
            expires: arg.expires,
            action: "getObject",
          },
        });
        return signedURL;
      },
    }),
    downloadFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: arg,
        });
        const response = await (
          await fetch(signedURL.data.data.signedRequest)
        ).json();
        return { data: response };
      },
    }),
    downloadDocument: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: arg,
        });
        const response = await fetch(signedURL.data.data.signedRequest);
        return { data: response };
      },
    }),
    downloadGuideFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: arg,
        });
        const response = await fetch(signedURL.data.data.signedRequest);
        return { data: response };
      },
    }),
    getDropZoneSignedURL: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: {
            key: arg.key,
            bucket: arg.bucket,
            action: "putObject",
            type: arg.type,
          },
        });
        return { data: signedURL };
      },
    }),
    uploadTaxpayerProfile: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/upload-taxpayer-profile-organizer/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    listDocumentsLibrary: builder.query({
      query: ({ body }) => ({
        url: `/list-documents-folder`,
        method: "POST",
        body: body,
      }),
      providesTags: (result, error) => [{ type: "documentList" }],
    }),
    uploadDirectoryFile: builder.mutation({
      query: ({ body }) => ({
        url: `/upload-directory-file`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error) => [{ type: "documentList" }],
    }),
    deleteFile: builder.mutation({
      query: ({ body }) => ({
        url: `/delete-file`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error) => [{ type: "documentList" }],
    }),

    generateBulkEditFile: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/generate-bulk-edit-file/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    generateTaxpayerProfileOrganizer: builder.mutation({
      query: (engagementID) => ({
        url: `/rd/generate-taxpayer-profile-organizer/${engagementID}`,
        method: "POST",
      }),
    }),
    generatePartialReport: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/generate-partial-report/${engagementID}`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response.link;
      },
      transformErrorResponse: (response) => {
        if (process.env.REACT_APP_STAGE === "dev") console.error({ response });
        return response;
      },
    }),
    generatePartialEnergyReport: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/energy/generate-partial-report/${engagementID}`,
        method: "POST",
        body: body,
      }),
      transformResponse: (response) => {
        return response.signedURL;
      },
      transformErrorResponse: (response) => {
        if (process.env.REACT_APP_STAGE === "dev") console.error({ response });
        return response;
      },
    }),
  }),
  overrideExisting: true,
});

export const {
  useGenerateBulkEditFileMutation,
  useGenerateTaxpayerProfileOrganizerMutation,
  useGetSignedURLMutation,
  useCreateJSONDataFileMutation,
  useUploadFileMutation,
  useDownloadFileMutation,
  useDownloadGuideFileMutation,
  useGetDropZoneSignedURLMutation,
  useDownloadDocumentMutation,
  useUploadTaxpayerProfileMutation,
  useUploadItemMutation,
  useLazyListDocumentsLibraryQuery,
  useUploadDirectoryFileMutation,
  useDeleteFileMutation,
  useGeneratePartialReportMutation,
  useGeneratePartialEnergyReportMutation,
} = fileAPI;
