import { ReviewGaugeColumnLoading } from "../../ReviewPortal/components/ReviewGaugeColumnLoading";
import { motion } from "framer-motion";
import { classNames } from "../../utils";
import { useUsers } from "../../../../rtk/Users/useUsers";

export const DeliverablesPortalLoading = () => {
  const userAPI = useUsers();
  return (
    <main className="flex-1">
      <div className="py-6">
        {/* Top bar and logo */}
        <div className="flex flex-row justify-between pr-8">
          <div className="ml-2 h-2 w-1/5 animate-pulse rounded bg-sprxGrey"></div>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        {/* Main Content */}
        <div className="flex px-4">
          <div className="w-full">
            <div className="flex w-full flex-col justify-evenly lg:flex-row">
              <div className="flex w-full flex-row justify-between py-2 lg:w-1/2">
                <div className="mr-2 flex w-full flex-col rounded-2xl bg-sprxClientPortalLightTeal p-2">
                  <div className="mt-2 mb-2 h-2 w-1/4 animate-pulse rounded bg-sprxGrey"></div>
                  <div className="mt-2 mb-2 h-2 w-full animate-pulse rounded bg-sprxGrey"></div>
                  <div className="mt-2 mb-2 h-2 w-full animate-pulse rounded bg-sprxGrey"></div>
                </div>
              </div>

              <div className="mr-4 flex w-full flex-row justify-between py-2 lg:w-1/2">
                {userAPI.user.role === "admin" ? (
                  <div className="flex w-1/3 flex-grow items-center justify-end pl-8">
                    <div className="h-12vh flex w-full flex-col items-center justify-center rounded-2xl border py-4 shadow-xl">
                      <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                      <motion.button
                        className={classNames(
                          "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                          "focus:ring-none mt-4 inline-flex w-1/3 items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-1/2",
                        )}
                      ></motion.button>
                    </div>
                  </div>
                ) : (
                  <div className="w-1/2"></div>
                )}
                <div className="flex w-1/3 flex-grow items-center justify-end pl-8">
                  <div className="h-12vh flex w-full flex-col items-center justify-center rounded-2xl border py-4 shadow-xl">
                    <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                    <motion.button
                      className={classNames(
                        "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                        "focus:ring-none mt-4 inline-flex w-1/3 items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-1/2",
                      )}
                    ></motion.button>
                  </div>
                </div>
              </div>
            </div>
            <div className="w-full pb-8 lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="lg:col-span-3">
                <ReviewGaugeColumnLoading height={"70vh"} />
              </div>
              <div className="col-span-9 flex w-full">
                <div className={"grid w-full grid-cols-3 gap-4"}>
                  {Array.from(Array(6)).map((item, index) => (
                    <motion.div
                      key={`upload-loading-item-${index}`}
                      className={
                        "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                      }
                    >
                      <motion.div
                        className={classNames(
                          "flex flex-1 flex-col items-center justify-center bg-white px-4 xl:py-4",
                        )}
                      >
                        <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                        <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                        <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                        <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                        <motion.button
                          className={classNames(
                            "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                            "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-3/4",
                          )}
                        ></motion.button>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
