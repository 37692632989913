import * as yup from "yup";

export const getVapiFormShape = () => {
  return yup.object().shape({
    email: yup.string(),
    clientEmail: yup.string(),
    phoneNumber: yup.string(),
    transcript: yup.string(),
    buildingCost: yup.number(),
    ownershipPercentage: yup.number(),
    submittedDate: yup.string(),
    sentDate: yup.string(),
    buildingName: yup.string(),
    clientName: yup.string(),
    ownerName: yup.string(),
  });
};
