export const LoadingState = () => {
    return (
        <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex flex-col items-center justify-center">
                <div className="flex flex-row items-center justify-center">
                    <div className="animate-spin rounded-full h-32 w-32 border-t-2 border-b-2 border-gray-900"></div>
                </div>
                <div className="flex flex-row items-center justify-center">
                    <p className="text-2xl font-bold text-gray-900">Loading ...</p>
                </div>
            </div>
        </div>
    );
};