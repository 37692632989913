export const projectHasCompletions = (project) => {
  if (project === undefined) return true;
  return (
    project?.hasOwnProperty("extendedDescription") &&
    project["extendedDescription"] !== undefined &&
    project?.hasOwnProperty("purpose") &&
    project["purpose"] !== undefined &&
    project?.hasOwnProperty("experimentation") &&
    project["experimentation"] !== undefined &&
    project?.hasOwnProperty("uncertainty") &&
    project["uncertainty"] !== undefined &&
    project?.hasOwnProperty("nature") &&
    project["nature"] !== undefined
  );
};
