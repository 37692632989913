import { ReviewGaugeColumnLoading } from "./ReviewGaugeColumnLoading";
import { motion } from "framer-motion";
import { classNames } from "../../utils";

export const ReviewPortalLoadingScreen = () => {
  return (
    <>
      {/*Mobile side bar menu*/}
      <main className="flex-1 overflow-hidden">
        <div className="py-6">
          {/*Top bar and logo */}
          <div className="flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="grid w-full grid-cols-1 p-4 lg:grid-cols-4 lg:gap-4">
            <ReviewGaugeColumnLoading />
            <div className="col-span-3 flex w-full">
              <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3">
                {Array.from(Array(9)).map((item, index) => (
                  <motion.div
                    key={`upload-loading-item-${index}`}
                    className={
                      "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                    }
                  >
                    <motion.div
                      className={classNames(
                        "flex flex-1 flex-col items-center justify-between bg-white p-4 pt-6",
                      )}
                    >
                      <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                      <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                      <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                      <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                      <motion.button
                        className={classNames(
                          "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                          "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-3/4",
                        )}
                      ></motion.button>
                    </motion.div>
                  </motion.div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
