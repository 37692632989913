import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import {
  useDownloadDocumentMutation,
  useLazyListDocumentsLibraryQuery,
  useDeleteFileMutation,
} from "../../../../api/fileAPI";
import { FileListPaneLoading } from "../components/FileListPaneLoading";
import { isDev } from "../../utils";
/**
 * importType = Wages || Contract Research || Computer Lease || Projects || Supply || Documentation
 * Used to populate key items & make this component reuseable
 *
 */
export const FileListPane = (props) => {
  const { engagementData, user, type, deleteCallback, tagFilter } = props;
  const loadingProp = props?.loading ? props.loading : false;
  const {
    data: { activeEngagementID },
  } = user;

  const [downloadIndex, setDownloadIndex] = useState(null);

  const [downloadDocument, { isLoading: isDownloadingDocument }] =
    useDownloadDocumentMutation();

  const [deleteFile, { isLoading: isDeletingFile }] = useDeleteFileMutation();

  const [
    listDocumentsLibrary,
    { data: documentList, isLoading: isListingDocuments },
  ] = useLazyListDocumentsLibraryQuery();

  // List Documents
  useEffect(() => {
    const body = {
      format: true,
      bucket: engagementData?.s3Bucket?.name,
      key: `${activeEngagementID}/DocumentLibrary`,
    };
    listDocumentsLibrary({ body });
  }, [
    engagementData?.s3Bucket?.name,
    activeEngagementID,
    type,
    listDocumentsLibrary,
    loadingProp,
  ]);

  // Download Document
  const onDownloadDocument = async (item, index) => {
    setDownloadIndex(index);
    const bucket = engagementData?.s3Bucket?.name;
    const key = item.key;
    const body = { bucket, key, action: "getObject" };
    const response = await downloadDocument({ ...body });
    const link = document.createElement("a");
    link.href = response?.data?.url ?? "";
    link.download = key; // This sets the default file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  // Delete Document
  const onDeleteDocument = async (item) => {
    const { fileName } = item;
    const bucket = engagementData?.s3Bucket?.name;

    // Delete Directory File
    const deleteKey = item.key;
    const deleteBody = { bucket, key: deleteKey, action: "getObject" };
    const deleteResponse = await deleteFile({ body: deleteBody });
    if (isDev) console.log({ deleteResponse });
    // TODO: Handle error
    // if (deleteResponse?.status !== 200){
    //   console.error({deleteResponse});
    // };
    await deleteCallback(fileName);
  };

  if (loadingProp || isDeletingFile || isListingDocuments) {
    return <FileListPaneLoading />;
  }

  const items = documentList?.contents
    ?.filter((item) => item.type.toLowerCase().includes(type.toLowerCase()))
    ?.filter((item) => item.tags.split(",").some((a) => a === tagFilter));

  return (
    <div className="col-span-2 flex flex-col w-full">
      <div className="flex flex-col">
        <motion.h3 className="xl:text-xl text-lg text-left font-semibold text-gray-900">
          Uploaded Files
        </motion.h3>
        {items?.map((item, index) => (
          <motion.ul className="py-4" key={`file-list-item-${index}`}>
            <motion.li
              className="flex flex-row justify-between items-center mb-8"
              key={index}
            >
              <motion.div className="flex flex-row items-center w-3/4">
                <img
                  className="h-12 w-12"
                  src={"assets/images/Spreadsheet_Icon.svg"}
                  alt={"Upload"}
                />
                <motion.h3 className="text-xl text-left ml-4 font-light w-full text-gray-900">
                  {item.fileName}
                </motion.h3>
              </motion.div>
              <motion.div className="flex flex-row items-center w-1/4 justify-end space-x-4">
                <motion.img
                  className={
                    "h-6 w-6 " +
                    (isDownloadingDocument && index === downloadIndex
                      ? "animate-bounce"
                      : "")
                  }
                  src={"assets/images/Download_Icon.svg"}
                  alt={"Download"}
                  onClick={async () => await onDownloadDocument(item, index)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                />
                <motion.img
                  className="h-6 w-6"
                  src={"assets/images/Trash_Icon.svg"}
                  alt={"Delete"}
                  onClick={async () => await onDeleteDocument(item)}
                  whileHover={{ scale: 1.05 }}
                  whileTap={{ scale: 0.95 }}
                />
              </motion.div>
            </motion.li>
          </motion.ul>
        ))}
      </div>
    </div>
  );
};
