import { baseAPI } from "./baseAPI";

const payrollConnectAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    connectPayroll: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/connect-payroll/${engagementID}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    //NEW ENDPOINT
    connectAdditionalPayroll: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/connect-additional-payroll/${engagementID}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useConnectPayrollMutation,
  useConnectAdditionalPayrollMutation,
} = payrollConnectAPI;
