import { Controller } from "react-hook-form";
import { motion } from "framer-motion";

export const SelectInputField = ({
    control,
    errors,
    id,
    options,
    label,
    subtext
}) => {
    return (
        <Controller
            name={id}
            control={control}
            render={({ field }) => (
                <div className="flex flex-col sm:col-span-12 justify-between mb-2">
                    <div className="flex flex-col">
                        <label
                            htmlFor={id}
                            className="block text-sm font-medium text-gray-700"
                        >
                            {label}
                        </label>
                        <p className="block text-sm font-sm text-gray-500">
                            {subtext || null}
                        </p>
                    </div>
                    <div className="mt-1">
                        <motion.select
                            {...field}
                            id={label}
                            name={label}
                            className="mt-1 block py-2 text-base focus:border-gray-400 border-gray-300 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm rounded-md"
                            whileHover={{ scale: 1.01 }}
                        >
                            <option></option>
                            {options.map((opt, index) => {
                                return (
                                    <option
                                        value={opt.value}
                                        key={`state-form-option-${label}-${index}`}
                                    >
                                        {opt.label}
                                    </option>
                                )
                            })}
                        </motion.select>
                    </div>
                    {errors?.hasOwnProperty(id) && (
                        <p className=" pl-2 text-sprxOrange">
                            Invalid {label}
                        </p>
                    )}
                </div>
            )}
        />
    );
};