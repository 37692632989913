import * as React from "react";
import { motion } from "framer-motion";
import classNames from "classnames";
import "./SquareButton.css";

interface SquareButtonProps {
  title: string;
  icon: any;
  loading: boolean;
  id: string;
  onClick: () => void;
}

export const SquareButton: React.FC<SquareButtonProps> = ({
  title,
  icon,
  loading,
  id,
  onClick,
}) => {
  return (
    <motion.div
      key={id}
      whileHover={{ scale: 1.05 }}
      whileTap={{ scale: 0.95 }}
      onClick={() => onClick()}
      className={classNames(
        "flex flex-col w-full overflow-hidden rounded-lg shadow-xl border h-72 cursor-pointer",
        { "loading-bounce": loading } // Apply the bounce animation when loading
      )}
    >
      <motion.div
        className={classNames(
          "flex flex-1 flex-col justify-center items-center p-4",
          { "loading-flash": loading } // Apply the blue sliding background when loading
        )}
      >
        {icon}
        <motion.h3 className="mt-4 text-xl text-center font-semibold text-gray-900">
          {title}
        </motion.h3>
      </motion.div>
    </motion.div>
  );
};
