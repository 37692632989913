export const translateImportTypeToFileName = (type) => {
  switch (type) {
    // Legacy Mappings
    case "Computer Lease Intelligent Import":
      return "_COMPLEASE_DATA_OBJ";
    case "Projects Intelligent Import":
      return "_PROJECT_DATA_OBJ";
    case "Contract Research Intelligent Import":
      return "_CR_DATA_OBJ";
    case "Supply Intelligent Import":
      return "_SUPPLY_DATA_OBJ";
    case "Wages Intelligent Import":
      return "_WAGE_DATA_OBJ";

    // New Mappings
    case "Computer Lease":
      return "_COMPLEASE_DATA_OBJ";
    case "Computer":
      return "_COMPLEASE_DATA_OBJ";
    case "Projects":
      return "_PROJECT_DATA_OBJ";
    case "Contract Research":
      return "_CR_DATA_OBJ";
    case "Contract":
      return "_CR_DATA_OBJ";
    case "Supply":
      return "_SUPPLY_DATA_OBJ";
    case "Wages":
      return "_WAGE_DATA_OBJ";

    // Newer Mappings lol
    case "WAGES":
      return "_WAGE_DATA_OBJ";
    case "WAGES_REVIEW":
      return "_WAGE_DATA_OBJ";
    case "WAGES_ALLOCATIONS":
      return "_WAGE_DATA_OBJ";

    case "COMPUTER":
      return "_COMPLEASE_DATA_OBJ";
    case "COMPUTER_REVIEW":
      return "_COMPLEASE_DATA_OBJ";
    case "COMPUTER_ALLOCATIONS":
      return "_COMPLEASE_DATA_OBJ";

    case "CONTRACT":
      return "_CR_DATA_OBJ";
    case "CONTRACT_REVIEW":
      return "_CR_DATA_OBJ";
    case "CONTRACT_ALLOCATIONS":
      return "_CR_DATA_OBJ";

    case "SUPPLY":
      return "_SUPPLY_DATA_OBJ";
    case "SUPPLY_REVIEW":
      return "_SUPPLY_DATA_OBJ";
    case "SUPPLY_ALLOCATIONS":
      return "_SUPPLY_DATA_OBJ";

    case "PROJECTS":
      return "_PROJECT_DATA_OBJ";

    // Capitalization Mappings
    case "CAP_WAGES":
      return "WAGES";
    case "CAP_LEDGER":
      return "LEDGER";
    case "CAP_PROJECTS":
      return "PROJECTS";

    default:
      return "";
  }
};
