import { determineStatesWithQres } from "./determineStatesWithQres";
export const determineRelevantStates = ({
  stateCredits, 
  wageData,
  supplyData,
  contractData,
  computerLeaseData,
  showAll
}) => {
  if (showAll === true) return stateCredits;
  const statesWithQres = determineStatesWithQres({wageData, supplyData, contractData, computerLeaseData});
  return stateCredits.filter((stateCredit) => statesWithQres.includes(stateCredit.state));
};
