import EnergyEngagementContext from "./EnergyEngagementContext";
import {
  useCreateEnergyEngagementMutation,
  useGetEnergyEngagementQuery,
  useLazyGetEnergyEngagementQuery,
  useUpdateEnergyEngagementMutation,
  useListAllEnergyEngagementsQuery,
  useListEnergyEngagementsFromCompanyIDMutation,
  useListModelingEngagementsQuery,
  useListCertificationEngagementsQuery,
  useCompute179DMutation,
  useDigestDocumentsMutation,
  useGenerateEnergyDeliverablesMutation,
  useDispatchCallMutation,
  useSendAllocationLetterMutation,
} from "../../../api/energy/engagementAPI";

import { useMemo } from "react";
import { useUsers } from "../../Users/useUsers";

const EnergyEngagementProvider = ({ children }) => {
  const { user } = useUsers();
  const userID = user?.userID ?? "";
  // const companyID = user?.companyID ?? "";
  const energyEngagementID = user?.data?.activeEnergyEngagementID ?? "";

  const [createEngagement, createEngagementData] =
    useCreateEnergyEngagementMutation();

  const [compute179D, compute179DData] = useCompute179DMutation();

  const [updateEngagement, updateEngagementData] =
    useUpdateEnergyEngagementMutation();

  const [generateEnergyDeliverables, generateEnergyDeliverablesData] =
    useGenerateEnergyDeliverablesMutation();

  const [dispatchCall, { isLoading: isDispatching, error: dispatchingError }] =
    useDispatchCallMutation();

  const [
    sendAllocationLetter,
    {
      isLoading: isSendingAllocationLetter,
      error: sendingAllocationLetterError,
    },
  ] = useSendAllocationLetterMutation();

  const [
    getEngagement,
    { data: getEngagementData, error: getEngagementError },
  ] = useLazyGetEnergyEngagementQuery();

  // const {
  //   data: certificationEngagements,
  //   error: certificationEngagementsError,
  //   isLoading: certificationEngagementsLoading,
  //   isFetching: certificationEngagementsFetching,
  // } = useListCertificationEngagementsQuery({ userID });

  const {
    data: engagement,
    error: engagementError,
    isLoading: engagementLoading,
    isFetching: engagementFetching,
  } = useGetEnergyEngagementQuery(energyEngagementID);

  // const {
  //   data: modelingEngagements,
  //   error: modelingEngagementsError,
  //   isLoading: modelingEngagementsLoading,
  //   isFetching: modelingEngagementsFetching,
  //   refetch: refetchModelingEngagements,
  // } = useListModelingEngagementsQuery({});

  const {
    data: allEngagements,
    error: allEngagementsError,
    isLoading: allEngagementsLoading,
  } = useListAllEnergyEngagementsQuery({});

  const [
    engagementsFromCompanyID,
    {
      data: engagementsFromCompanyIDData,
      error: engagementsFromCompanyIDError,
      isLoading: engagementsFromCompanyIDLoading,
    },
  ] = useListEnergyEngagementsFromCompanyIDMutation();

  const [digestDocuments, digestDocumentsData] = useDigestDocumentsMutation();

  const markModelStatusAsReady = async () => {
    const engagementID = engagement.engagementID;
    const isSubmittedToEnergyModeler = engagement?.model?.status === "READY";
    const body = {
      model: {
        assignedDate: new Date().toLocaleDateString(),
        status: isSubmittedToEnergyModeler ? "NOT_READY" : "READY",
      },
    };
    await updateEngagement({
      engagementID,
      body,
    });
  };

  const value = useMemo(
    () => ({
      // certificationEngagements,
      // certificationEngagementsError,
      // certificationEngagementsLoading,
      // certificationEngagementsFetching,
      // modelingEngagements,
      // modelingEngagementsError,
      // modelingEngagementsLoading,
      // modelingEngagementsFetching,
      createEngagementData,
      updateEngagementData,
      createEngagement,
      updateEngagement,
      getEngagement,
      engagementsFromCompanyID,
      compute179D,
      // refetchModelingEngagements,
      markModelStatusAsReady,
      compute179DData,
      getEngagementData,
      getEngagementError,
      allEngagements,
      allEngagementsError,
      allEngagementsLoading,
      engagementsFromCompanyIDData,
      engagementsFromCompanyIDError,
      engagementsFromCompanyIDLoading,
      engagement,
      engagementError,
      engagementLoading,
      digestDocuments,
      digestDocumentsData,
      generateEnergyDeliverables,
      generateEnergyDeliverablesData,
      dispatchCall,
      isDispatching,
      dispatchingError,
      sendAllocationLetter,
      isSendingAllocationLetter,
      sendingAllocationLetterError,
      engagementFetching,
    }),
    [
      // certificationEngagements,
      // certificationEngagementsError,
      // certificationEngagementsLoading,
      // certificationEngagementsFetching,
      // modelingEngagements,
      // modelingEngagementsError,
      // modelingEngagementsLoading,
      // modelingEngagementsFetching,
      createEngagementData,
      updateEngagementData,
      createEngagement,
      updateEngagement,
      getEngagement,
      engagementsFromCompanyID,
      compute179D,
      // refetchModelingEngagements,
      markModelStatusAsReady,
      compute179DData,
      getEngagementData,
      getEngagementError,
      allEngagements,
      allEngagementsError,
      allEngagementsLoading,
      engagementsFromCompanyIDData,
      engagementsFromCompanyIDError,
      engagementsFromCompanyIDLoading,
      engagement,
      engagementError,
      engagementLoading,
      digestDocuments,
      digestDocumentsData,
      generateEnergyDeliverables,
      generateEnergyDeliverablesData,
      dispatchCall,
      isDispatching,
      dispatchingError,
      sendAllocationLetter,
      isSendingAllocationLetter,
      sendingAllocationLetterError,
      engagementFetching,
    ],
  );

  return (
    <EnergyEngagementContext.Provider value={value}>
      {children}
    </EnergyEngagementContext.Provider>
  );
};
export default EnergyEngagementProvider;
