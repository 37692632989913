import { useCallback, useEffect, useState, useMemo } from "react";
import { isDev, translateImportTypeToFileName } from "../../../main/R&D/utils";
import { useDeleteProjectMutation } from "../../../api/engagmentAPI";
import { useEngagementData } from "../EngagementData/useEngagementData";
import { deepMerge } from "./util/deepMerge";
import {
  useDownloadFileMutation,
  useCreateJSONDataFileMutation,
  useUploadFileMutation,
  useUploadItemMutation,
} from "../../../api/fileAPI";
import DataArrayContext from "./DataArrayContext";
import { useUsers } from "../../Users/useUsers";
import { ImportType, LineItem } from "../../../main/R&D/types/RdData";
import { EXCLUDED_FIELDS } from "../../../main/R&D/ReviewPortal/utils/constants";
import { appendAllocationCheck } from "./util/appendAllocationCheck";

const typeToImportTypeMap = {
  Wages: "WAGES",
  WAGES_REVIEW: "WAGES_REVIEW",
  WAGES_ALLOCATIONS: "WAGES_ALLOCATIONS",

  Supply: "SUPPLY",
  SUPPLY_REVIEW: "SUPPLY_REVIEW",
  SUPPLY_ALLOCATIONS: "SUPPLY_ALLOCATIONS",

  "Contract Research": "CONTRACT",
  CONTRACT_REVIEW: "CONTRACT_REVIEW",
  CONTRACT_ALLOCATIONS: "CONTRACT_ALLOCATIONS",

  "Computer Lease": "COMPUTER",
  COMPUTER_REVIEW: "COMPUTER_REVIEW",
  COMPUTER_ALLOCATIONS: "COMPUTER_ALLOCATIONS",

  Projects: "PROJECTS",
  PROJECTS_REVIEW: "PROJECTS_REVIEW",
};

const DataArrayProvider = ({ children }) => {
  const { user } = useUsers();
  const { engagementData, isLoadingEngagementData } = useEngagementData();

  const [bypassCalculation, setBypassCalculation] = useState(false);
  const [wageData, setWageData] = useState([]);
  const [supplyData, setSupplyData] = useState([]);
  const [computerLeaseData, setComputerLeaseData] = useState([]);
  const [contractData, setContractData] = useState([]);
  const [projectData, setProjectData] = useState([]);
  const [isRefreshing, setIsRefreshing] = useState(false);

  const typeToSetFunctionMap = useMemo(() => {
    return {
      Wages: setWageData,
      Supply: setSupplyData,
      "Contract Research": setContractData,
      "Computer Lease": setComputerLeaseData,
      Projects: setProjectData,

      WAGES: setWageData,
      WAGES_REVIEW: setWageData,
      WAGES_ALLOCATIONS: setWageData,

      SUPPLY: setSupplyData,
      SUPPLY_REVIEW: setSupplyData,
      SUPPLY_ALLOCATIONS: setSupplyData,

      CONTRACT: setContractData,
      CONTRACT_REVIEW: setContractData,
      CONTRACT_ALLOCATIONS: setContractData,

      COMPUTER: setComputerLeaseData,
      COMPUTER_REVIEW: setComputerLeaseData,
      COMPUTER_ALLOCATIONS: setComputerLeaseData,

      PROJECTS: setProjectData,
      PROJECTS_REVIEW: setProjectData,
    };
  }, []);

  const isRD = user?.engagementType === "RD";

  const [
    downloadFile,
    { isLoading: isDownloadingFile, error: downloadFileError },
  ] = useDownloadFileMutation();

  const [
    createJSONDataFile,
    { isLoading: isCreatingJSONDataFile, error: createJSONDataFileError },
  ] = useCreateJSONDataFileMutation();

  const [uploadFile, { isLoading: isUploadingFile, error: uploadFileError }] =
    useUploadFileMutation();

  const [
    deleteProject,
    { isLoading: isDeletingProject, error: deleteProjectError },
  ] = useDeleteProjectMutation();

  const [uploadItem, { isLoading: isUploadingItem, error: uploadItemError }] =
    useUploadItemMutation();

  const getParams = useCallback(
    (type: string, action: string) => {
      if (engagementData === undefined) return;
      const { s3Bucket, engagementID } = engagementData;
      const bucket = s3Bucket.name;
      const fileSlug = translateImportTypeToFileName(type);
      const key = isRD
        ? `${engagementID}/uploadFiles/${engagementID}${fileSlug}.json`
        : `${engagementID}/uploadFiles/${fileSlug}.json`;

      return {
        bucket,
        action,
        key,
        type: "application/json",
      };
    },
    [engagementData, isRD],
  );

  const getDataFromType = useCallback(
    (type: string) => {
      switch (type) {
        case "Wages":
          return wageData;
        case "WAGES":
          return wageData;
        case "WAGES_REVIEW":
          return wageData;
        case "WAGES_ALLOCATIONS":
          return wageData;
        case "Supply":
          return supplyData;
        case "SUPPLY":
          return supplyData;
        case "SUPPLY_REVIEW":
          return supplyData;
        case "SUPPLY_ALLOCATIONS":
          return supplyData;
        case "Contract Research":
          return contractData;
        case "CONTRACT":
          return contractData;
        case "CONTRACT_REVIEW":
          return contractData;
        case "CONTRACT_ALLOCATIONS":
          return contractData;
        case "Computer Lease":
          return computerLeaseData;
        case "COMPUTER":
          return computerLeaseData;
        case "COMPUTER_REVIEW":
          return computerLeaseData;
        case "COMPUTER_ALLOCATIONS":
          return computerLeaseData;
        case "Projects":
          return projectData;
        case "PROJECTS":
          return projectData;
        case "PROJECTS_REVIEW":
          return projectData;
        default:
          return [];
      }
    },
    [computerLeaseData, contractData, projectData, supplyData, wageData],
  );

  const refreshData = useCallback(
    async (type: ImportType) => {
      const body = getParams(type, "getObject");
      try {
        const response = await downloadFile({ ...body });
        if (!("data" in response)) return;
        const arr = response.data;
        const mergedArray = arr.map((a: LineItem) => {
          const oldItem = getDataFromType(type).find((b) => b.ID === a.ID);
          if (oldItem) {
            return deepMerge(oldItem, a);
          } else {
            return a;
          }
        });
        if (typeToSetFunctionMap[type]) {
          typeToSetFunctionMap[type](mergedArray);
        } else {
          console.error(`Unhandled type: ${type}`);
        }
      } catch (err) {
        console.error(err);
      }
    },
    [downloadFile, getDataFromType, getParams, typeToSetFunctionMap],
  );

  const onDeleteProject = useCallback(
    async (projectID: string) => {
      const deleteProjectResponse = await deleteProject({
        body: {
          projectID: projectID,
          engagementID: engagementData?.engagementID,
        },
      });
      if (isDev) console.log({ deleteProjectResponse });
      await refreshData("PROJECTS");
    },
    [deleteProject, engagementData?.engagementID, refreshData],
  );

  // Refresh Data Every Minute
  useEffect(() => {
    setIsRefreshing(true);
    const dataTypes = [
      "Wages",
      "Supply",
      "Contract Research",
      "Computer Lease",
      "Projects",
    ];
    const interval = setInterval(async () => {
      const refreshPromises = dataTypes.map(async (type: ImportType) => {
        return await refreshData(type);
      });
      await Promise.all(refreshPromises);
      setIsRefreshing(false);
    }, 60000);
    return () => {
      clearInterval(interval);
    };
  }, [refreshData, typeToSetFunctionMap]);

  // Initial Data Fetch
  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();

    if (isLoadingEngagementData) return;

    async function getData(body: any) {
      if (isMounted === false) return [];
      try {
        const response = await downloadFile({
          ...body,
          signal: abortController.signal,
        });
        if (!("data" in response)) return [];
        return response.data;
      } catch (err) {
        if (isDev) console.error(err);
        return [];
      }
    }

    async function fetchData() {
      const items = [
        "Wages",
        "Supply",
        "Contract Research",
        "Computer Lease",
        "Projects",
      ];

      for (const item of items) {
        if (!isMounted) break;
        try {
          const data = await getData(getParams(item, "getObject"));
          if (!isMounted) break;

          const arr = data.map((a) => {
            if (item === "Wages") return appendAllocationCheck(a);
            return { ...a };
          });

          typeToSetFunctionMap[item](arr);
        } catch (err) {
          console.error(err);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [engagementData?.engagementID]);

  // Optimistic Update
  const updateLineItem = useCallback(
    async (item: LineItem, type: ImportType) => {
      if (typeToSetFunctionMap[type]) {
        // Update Local State
        typeToSetFunctionMap[type]((prev) => {
          const arr = prev.map((a) => ({ ...a }));
          const index = arr.findIndex((a) => a.ID === item.ID);
          arr[index] = deepMerge(arr[index], item);
          if (type === "WAGES_ALLOCATIONS" || "WAGES_REVIEW") {
            arr[index] = appendAllocationCheck(arr[index]);
          }
          return arr;
        });
        // Dispatch Queue Update
        const body = {
          item,
          type: typeToImportTypeMap[type],
          engagementID: engagementData?.engagementID,
        };
        const uploadResponse = await uploadItem(body);
        if (isDev) console.log({ uploadResponse });
      } else {
        console.error(`Unhandled type: ${type}`);
      }
    },
    [engagementData?.engagementID, typeToSetFunctionMap, uploadItem],
  );

  // Update Line Item Data & Update S3
  const updateData = useCallback(
    async (arr: LineItem[], type: ImportType) => {
      // Check if the type exists in the mapping
      if (typeToSetFunctionMap[type]) {
        typeToSetFunctionMap[type](arr.map((a) => ({ ...a })));
      } else {
        console.error(`Unhandled type: ${type}`);
      }

      // Create JSON File
      const file = await createJSONDataFile(arr);

      // Upload Data
      const uploadParams = getParams(type, "putObject");
      if (isDev) console.log({ uploadParams });

      if (!("data" in file)) return;
      const fileUploadResponse = await uploadFile({
        ...uploadParams,
        file: file.data,
      });
      if (isDev) console.log({ fileUploadResponse });
    },
    [createJSONDataFile, getParams, typeToSetFunctionMap, uploadFile],
  );

  let errors: any = useMemo(() => ({}), []);
  const hasError = (i: any | undefined) => i !== undefined;
  if (hasError(downloadFileError)) errors = { ...errors, downloadFileError };
  if (hasError(createJSONDataFileError))
    errors = { ...errors, createJSONDataFileError };
  if (hasError(uploadFileError)) errors = { ...errors, uploadFileError };
  if (hasError(deleteProjectError)) errors = { ...errors, deleteProjectError };
  Object?.keys(errors)?.forEach((i) => {
    console.error(i, errors[i]);
  });

  const value = useMemo(
    () => ({
      // Object[]
      wageData,
      supplyData,
      computerLeaseData,
      contractData,
      projectData,

      // Functions
      updateLineItem,
      setBypassCalculation,
      refreshData,
      deleteProject: async (projectID: string) =>
        await onDeleteProject(projectID),
      updateData,

      // Booleanxss
      isDownloadingFile: isDownloadingFile && !isRefreshing,
      isCreatingJSONDataFile,
      isUploadingFile: isUploadingFile,
      isDeletingProject,
      bypassCalculation,

      // Errors
      dataArrayErrors: errors,
    }),
    [
      bypassCalculation,
      computerLeaseData,
      contractData,
      errors,
      isCreatingJSONDataFile,
      isDeletingProject,
      isDownloadingFile,
      isRefreshing,
      isUploadingFile,
      onDeleteProject,
      projectData,
      refreshData,
      supplyData,
      updateData,
      updateLineItem,
      wageData,
    ],
  );

  return (
    <DataArrayContext.Provider value={value}>
      {children}
    </DataArrayContext.Provider>
  );
};
export default DataArrayProvider;
