import * as React from "react";
import * as d3 from "d3";
import { LineItem } from "../../../types/RdData";
import { groupData } from "./util/groupData";
import "./BubbleChart.css";
import { DataDisplay } from "./DataDisplay";

interface IBubbleChartProps {
  lineItems: LineItem[];
  label: string;
  value: string;
  title: string;
}

export const BubbleChart = ({
  lineItems,
  label,
  value,
  title,
}: IBubbleChartProps) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const [selectedSlice, setSelectedSlice] = React.useState<any>(0);

  const groupedData = React.useMemo(() => {
    return groupData(lineItems, label, value);
  }, [label, lineItems, value]);

  React.useEffect(() => {
    if (!svgRef.current) return;

    const width = 250;
    const height = width;
    const margin = 1;

    const format = d3.format(",d");
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pack = d3
      .pack()
      .size([width - margin * 2, height - margin * 2])
      .padding(3);

    const root = pack(
      d3.hierarchy({ children: groupedData }).sum((d: any) => d.totalCost)
    );

    const svg = d3
      .select(svgRef.current)
      .attr("width", width)
      .attr("height", height)
      .attr("viewBox", [-margin, -margin, width, height])
      .attr("style", "max-width: 100%; height: auto; font: 10px sans-serif;")
      .attr("text-anchor", "middle");
    // .classed("full-width-svg", true); // Add a class to the SVG element

    const node = svg
      .append("g")
      .selectAll()
      .data(root.leaves())
      .join("g")
      .attr("transform", (d: any) => `translate(${d.x},${d.y})`);

    node
      .append("title")
      .text((d: any) => `${d.data.group}\n$${format(d.data.totalCost)}`);

    node
      .append("circle")
      .attr("fill-opacity", 0.7)
      .attr("fill", (d: any) => color(d.data.group))
      .attr("r", (d: any) => d.r)
      .attr("stroke", (d: any) => {
        return d.data.group === groupedData[selectedSlice]?.group
          ? "black"
          : "white";
      })
      .attr("stroke-width", (d: any) => {
        return d.data.group === groupedData[selectedSlice]?.group ? "2" : "";
      });

    // Add a click event listener to each node
    node.on("click", (event: any, d: any) => {
      const index = groupedData.findIndex((g: any) => g.group === d.data.group);
      setSelectedSlice(index);
      svg.selectAll("circle").attr("stroke", "").attr("stroke-width", "");
    });
  }, [groupedData, selectedSlice]);

  if (groupedData.length === 0) return null;

  const lineItemType = () => {
    const item = lineItems[0];
    if ("Employee Location" in item) return "Employee";
    if ("Vendor Location" in item) return "Vendor";
    if ("Supply Location" in item) return "Supply";
    if ("Contractor Location" in item) return "Contractor";
    return "Unknown";
  };

  const type = lineItemType();

  if (type === "Unknown") return null;

  const group = groupedData[selectedSlice]?.group ?? "N/A";
  const cost = Math.round(groupedData[selectedSlice]?.totalCost ?? 0);

  return (
    <div className="flex flex-row justify-center items-center h-full">
      <div className="flex flex-col items-center h-full">
        <h1 className="font-bold">{title}</h1>
        {/* Chart */}
        <div className="flex flex-row items-center justify-evenly  h-full">
          <svg ref={svgRef}></svg>
          {/* Summary Box */}
          <div className="rounded-lg bg-white w-60 text-center  h-full">
            <div className="flex flex-col w-full justify-center items-center">
              <DataDisplay title={group} value={cost} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
