import { ModelerEngagementListContainer } from "../components/ModelerEngagementListContainer";
import { EngagementContainer } from "./Engagement/EngagementContainer";
import { WelcomeMessage } from "./components";
import { useState } from "react";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { User } from "../../AdminHome/types/user";
import { Engagement } from "../types";

interface EnergyModelerPortalProps {
  user: User;
}

export const EnergyModelerPortal = ({ user }: EnergyModelerPortalProps) => {
  const [activeEngagement, setActiveEngagement] = useState(undefined);
  return (
    <>
      <main className="flex-1">
        <div className="py-6">
          {/*Top bar and logo */}
          <div className="mx-auto flex flex-row items-center justify-between px-8">
            <div className="flex flex-row items-center">
              {activeEngagement ? (
                <button
                  onClick={() => setActiveEngagement(undefined)}
                  className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
                >
                  <ArrowLeftIcon className="h-8 w-8" />
                </button>
              ) : null}
              <h1
                className={`text-2xl font-semibold text-gray-900 ${
                  activeEngagement
                    ? `ml-4 flex flex-row items-center justify-center`
                    : ""
                }`}
              >
                {activeEngagement ? (
                  <>
                    {activeEngagement.name}
                    <CheckCircleIcon
                      className={`ml-1 h-6 w-6 align-middle ${
                        activeEngagement?.model?.status === "COMPLETE"
                          ? "text-sprxGreen"
                          : "text-gray-400"
                      }`}
                    />
                  </>
                ) : (
                  "Energy Modeler Portal"
                )}
              </h1>
            </div>
            <img
              style={{ width: 85, height: 36, marginLeft: 16 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          {!activeEngagement ? (
            <div className="mx-auto px-4 sm:px-6 md:px-8">
              <WelcomeMessage name={user.fullName.split(" ")[0]} />
              <ModelerEngagementListContainer
                setActiveEngagement={setActiveEngagement}
                type={user.role}
              />
            </div>
          ) : (
            <EngagementContainer
              engagement={activeEngagement}
              setActiveEngagement={setActiveEngagement}
            />
          )}
        </div>
      </main>
    </>
  );
};
