
import { motion } from "framer-motion";
import { Dialog } from "@headlessui/react";

export const SurveyChatContainer = (props) => {
    return (
        <Dialog
                className="relative z-40"
                static
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                as={motion.div}
                open={true}
                onClose={() => null}
        >
            <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
                <motion.div className="fixed inset-0 z-10 overflow-y-auto">
                    <motion.div className="flex min-h-full items-end justify-center text-center sm:items-center sm:p-0">
                        <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all min-h-94vh min-w-95vw max-w-95vw">
                            <motion.div className="flex flex-col bg-gray-600 min-h-94vh max-h-94vh">
                                {props.children}
                            </motion.div>
                        </Dialog.Panel>
                    </motion.div>
                </motion.div>
        </Dialog>
    );
};