export const ListViewError = ({ type }) => (
  <div className="flex h-full flex-col items-center justify-center">
    <h1 className="text-xl font-semibold text-gray-900">
      Error fetching your {type} data.
    </h1>
    <p>
      Refresh the page and contact the SPRX team if you are still having issues.
    </p>
  </div>
);
