import React from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { Button } from "./";
import { BottomButtonContainer, Heading, ProgressBubbles } from "./index";

const SurveyModel = (props) => {
  // const [nextButtonText, setNextButtonText] = useState("Next");

  var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "black";
  defaultThemeColors["$border-color"] = "white";
  defaultThemeColors["$inputs-background-color"] = "#DCDCDC";

  // defaultThemeColors["$main-color"] = "#ffffff";
  // defaultThemeColors["$main-hover-color"] = "#4AA0D5";
  // defaultThemeColors["$text-color"] = "#000000";
  // defaultThemeColors["$body-container-background-color"] = "#f8f8f8";
  // defaultThemeColors["$header-background-color"] = "red";
  // defaultThemeColors["$body-container-background-color"] = "green";
  // defaultThemeColors["$main-hover-color"] = "lightblue";
  // defaultThemeColors["$body-background-color"] = "cyan";
  // defaultThemeColors["$inputs-background-color"] = "magenta";
  // defaultThemeColors["$text-color"] = "black";
  // defaultThemeColors["$header-color"] = "lime";
  // defaultThemeColors["$disable-color"] = "#dbdbdb";
  // defaultThemeColors["$progress-text-color"] = "#9d9d9d";
  // defaultThemeColors["$disabled-label-color"] = "rgba(64, 64, 64, 0.5)";
  // defaultThemeColors["$slider-color"] = "white";
  // defaultThemeColors["$disabled-switch-color"] = "#9f9f9f";
  // defaultThemeColors["$disabled-slider-color"] = "#cfcfcf";

  Survey.StylesManager.applyTheme();

  var surveyJSON = props.surveyData.analysis;

  var model = new Survey.Model(surveyJSON);

  if (props.surveyData.completed) {
    model.data = props.surveyData.completedAnalysis;
  }

  model.onUpdatePageCssClasses.add(function (survey, options) {});

  model.onUpdatePanelCssClasses.add(function (survey, options) {});

  model.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;
    classes.item = "flex font-normal mb-4";
    classes.mainRoot = "flex flex-col min-w-full items-center justify-between";
    classes.title = " text-lg font-normal text-center ml-16 mb-32";
    classes.controlLabel = "ml-2";
    // TODO: Center title text span w flexbox - leveraging margin hack ATM
    // console.log(Object.keys(classes));
  });

  if (props.surveyData) {
    model.data = props.surveyData.completedAnalysis;
  }

  function onComplete(survey, options) {
    props.submit(survey);
  }

  return (
    <div className="flex flex-col">
      <Heading className={"mb-8"}>{props.surveyTitle}</Heading>

      <Survey.Survey
        model={model}
        showNavigationButtons={false}
        showTitle={false}
        onComplete={onComplete}
        // onCurrentPageChanged={onCurrentPageChanged} <-- potential for animation
      />

      <BottomButtonContainer className={"mt-16 items-center justify-between"}>
        <Button
          secondary
          onClick={() => {
            model.isFirstPage ? props.onBack() : model.prevPage();
          }}
        >
          Back
        </Button>
        <ProgressBubbles />
        <Button
          primary
          onClick={() => {
            model.isLastPage ? model.completeLastPage() : model.nextPage();
          }}
        >
          Next
        </Button>
      </BottomButtonContainer>
    </div>
  );
};

export default SurveyModel;
