export const getIntelligentImportTitle = (type) => {
  switch (type) {
    case "Survey":
      return "SME Email Intelligent Import";
    case "Computer Lease":
      return "Computer Lease Intelligent Import";
    case "Projects":
      return "Projects Intelligent Import";
    case "Contract Research":
      return "Contract Research Intelligent Import";
    case "Supply":
      return "Supply Intelligent Import";
    case "Wages":
      return "Wages Intelligent Import";
    default:
      return "";
  }
};
