import { useState } from "react";

const dataIn = [
  {
    "Project ID Number": "Test_ID_1",
    "Project Name": "Test_Name_1",
    "Project Description": "Test_Desc_1",
    "Project Department": "Test_Dpt_1",
    "Project Location": "Test_Loc_1",
    "Project Cost": "$1",
  },
  {
    "Project ID Number": "Test_ID_2",
    "Project Name": "Test_Name_2",
    "Project Description": "Test_Desc_2",
    "Project Department": "Test_Dpt_2",
    "Project Location": "Test_Loc_2",
    "Project Cost": "$2",
  },
];

const ResponsiveTable = () => {
  const [editIndex, setEditIndex] = useState(null);
  const [data, setData] = useState(dataIn);

  const handleChange = (val, index, element) => {
    let tempData = data;
    tempData[index] = {
      ...tempData[index],
      [element]: val,
    };
    setData(tempData);
  };

  return (
    <div className="flex flex-col">
      <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
        <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
          <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
            <table className="min-w-full divide-y divide-gray-200">
              <thead className="bg-gray-50">
                <tr>
                  {Object.keys(data[0]).map((title, key) => {
                    return (
                      <th
                        key={`${key}-header`}
                        scope="col"
                        className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider"
                      >
                        {title}
                      </th>
                    );
                  })}
                  <th scope="col" className="relative px-6 py-3">
                    <span className="sr-only">Edit</span>
                  </th>
                </tr>
              </thead>
              <tbody>
                {data.map((item, key) => {
                  const i = data.indexOf(item);
                  const bg = i % 2 === 0 ? "bg-white" : "bg-gray-50";
                  return (
                    <tr className={bg} key={`${key}-body`}>
                      {Object.keys(item).map((element, key) => {
                        if (editIndex === i) {
                          return (
                            <td
                              key={`${key}-${element}-input-row`}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              <input
                                key={`${key}-${element}-input`}
                                value={data[i][element]}
                                onChange={(e) =>
                                  handleChange(e.target.value, i, element)
                                }
                              />
                            </td>
                          );
                        } else {
                          return (
                            <td
                              key={`${key}-${element}-render-row`}
                              className="px-6 py-4 whitespace-nowrap text-sm font-medium text-gray-900"
                            >
                              {data[i][element]}
                            </td>
                          );
                        }
                      })}

                      <td className="px-6 py-4 whitespace-nowrap text-right text-sm font-medium">
                        <button
                          onClick={() =>
                            setEditIndex(editIndex === i ? null : i)
                          }
                          className="text-indigo-600 hover:text-indigo-900"
                        >
                          {editIndex === i && "Save"}
                          {editIndex !== i && "Edit"}
                        </button>
                      </td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResponsiveTable;
