export const getDsUploadParameters = (files: File[], dataSpace: any) => {
    return Object.keys(files)
        .filter((k) => k !== "length")
        .map((k) => {
        const file = files[k];
        return {
            fileName: file.name,
            bucket: dataSpace.s3Bucket.name,
            key: `${dataSpace.s3Bucket.path}/${file.name}`,
            action: "putObject",
            file: file,
            type: file.type,
            uploadType: "dataSpace",
            tags: "dataSpace",
        };
    });
};