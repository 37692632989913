import { Engagement } from "./Engagement";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { getDocumentationTableFields } from "../../OperationsPortal/InformationGathering/Documentation/utils";
import { FileActionButton } from "../../OperationsPortal/InformationGathering/Documentation/components/FileActionButton";
import { useState } from "react";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";
import { useEnergyEngagement } from "../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { ModelSubmissionForm } from "./ModelSubmissionForm";
import { formatModelDocumentKey, formatDocumentKey } from "../../utils/format";
import { useEnergyComments } from "../../../../rtk/Energy/Comments/useEnergyComments";
import { EngagementLoading } from "../components/EngagementLoading";
import { setUserData } from "../../../../auth/rtk/userSlice";
import { useDispatch } from "react-redux";

interface EngagementContainerProps {
  [key: string]: any;
}

export const EngagementContainer = ({
  engagement,
  setActiveEngagement,
}: EngagementContainerProps) => {
  const fileAPI = useFile();
  const usersAPI = useUsers();
  const energyAPI = useEnergyEngagement();
  const commentsAPI = useEnergyComments();
  const [open, setOpen] = useState(false);
  const [isUploadingFile, setIsUploadingFile] = useState(false);
  const { engagementID } = engagement;
  const dispatch = useDispatch();

  if (!fileAPI || !energyAPI || energyAPI.engagementLoading)
    return <EngagementLoading />;

  const onDownloadDocument = async (doc: any) => {
    const key = formatDocumentKey(doc.name, engagementID);
    const body = { bucket: engagement.bucket, key, action: "getObject" };
    const response = await fileAPI.downloadDocument({ ...body });
    const url = response?.data?.url ?? "";

    try {
      const fileResponse = await fetch(url);
      if (!fileResponse.ok) throw new Error("Failed to download file");

      const blob = await fileResponse.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = key;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const hasEnergyModelFile = (stub: string) => {
    return engagement.model.files[stub];
  };

  const onDownloadEnergyModel = async (stub: string) => {
    if (!hasEnergyModelFile(stub)) return;
    const energyModelFile = engagement.model.files[stub].name;
    const key = formatModelDocumentKey(engagementID, energyModelFile);
    const body = { bucket: engagement.bucket, key, action: "getObject" };
    const response = await fileAPI.downloadDocument({ ...body });
    const link = document.createElement("a");
    link.href = response?.data?.url ?? "";
    link.download = key; // This sets the default file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onUploadEnergyModel = async (document: File, stub: string) => {
    setIsUploadingFile(true);
    const { name } = document;
    const key = formatModelDocumentKey(engagementID, name);
    const uploadParams = {
      file: document,
      action: "putObject",
      key,
      type: "energy",
      bucket: engagement.bucket,
    };
    await fileAPI.uploadFile(uploadParams);
    setActiveEngagement((prev: any) => ({
      ...prev,
      model: {
        ...prev.model,
        files: {
          ...prev.model.files,
          [stub]: {
            key,
            name,
          },
        },
      },
    }));
    setIsUploadingFile(false);
  };

  const onSubmit = async ({
    efficiencyPercentage,
    hvacEfficiency,
    lightingEfficiency,
    envelopeEfficiency,
    isAccurate,
  }: {
    efficiencyPercentage: number;
    isAccurate: boolean;
    hvacEfficiency: number;
    lightingEfficiency: number;
    envelopeEfficiency: number;
  }) => {
    const body = {
      model: {
        ...engagement.model,
        efficiencyPercentage,
        hvacEfficiency,
        lightingEfficiency,
        envelopeEfficiency,
        status: "COMPLETE",
        isAccurate: isAccurate,
        submittedDate: new Date().toLocaleDateString(),
        submittedBy: usersAPI.user.fullName,
        submittedByUserID: usersAPI.user.userID,
      },
      certification: {
        ...engagement.certification,
        status: "READY",
        assignedDate: new Date().toLocaleDateString(),
      },
    };
    const userBody = {
      claimedAssignments: usersAPI.user.claimedAssignments.filter(
        (id: number) => id !== engagementID,
      ),
    };
    const updateUserReponse = await usersAPI.updateTaxUser({
      userID: usersAPI.user.userID,
      body: userBody,
    });
    const updatedUser = await usersAPI.getTaxUser(usersAPI.user.userID); // Grab this from updateTaxUser
    dispatch(setUserData(updatedUser.data));
    setActiveEngagement((prev: any) => ({ ...prev, ...body }));
    const updateParams = {
      engagementID,
      body,
    };
    await energyAPI.updateEngagement(updateParams);
    setOpen(false);
    await energyAPI.compute179D({ engagementID });

    setActiveEngagement((prev: any) => ({
      ...prev,
      model: {
        ...prev.model,
        ...body.model,
      },
      certification: { ...prev.certification, ...body.certification },
    }));
  };

  const onUnClaimAssignment = async () => {
    const userBody = {
      claimedAssignments: usersAPI.user.claimedAssignments.filter(
        (id: number) => id !== engagementID,
      ),
    };
    const updateUserReponse = await usersAPI.updateTaxUser({
      userID: usersAPI.user.userID,
      body: userBody,
    });
    const updatedUser = await usersAPI.getTaxUser(usersAPI.user.userID); // Grab this from updateTaxUser
    dispatch(setUserData(updatedUser.data));
    const engagementBody = {
      model: {
        ...engagement.model,
        claimed: false,
        claimedBy: "",
      },
    };

    const updateEngagementResponse = await energyAPI.updateEngagement({
      engagementID: engagement.engagementID,
      body: engagementBody,
    });
  };

  const fields = [
    ...getDocumentationTableFields(),
    {
      headerName: "",
      field: "Download",
      width: 64,
      // Employee Drill Down Button
      cellRenderer: (rowData: any) => {
        return (
          <FileActionButton
            type={"DOWNLOAD"}
            onClick={async () => await onDownloadDocument(rowData.data)}
          />
        );
      },
    },
  ].map((field) => ({ ...field, editable: false }));

  const documents = engagement?.documents?.map((document: any) => ({
    ...document,
    ID: document.name,
  }));

  const SubmissionFormProps = {
    id: 1,
    title: "Submit",
    description:
      "Submit your energy model to an SPRX operator. Enter the efficiency percentage and upload the model for each related system.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <ModelSubmissionForm
        onUploadEnergyModel={onUploadEnergyModel}
        onSubmit={onSubmit}
        onDownloadEnergyModel={onDownloadEnergyModel}
        isUploadingFile={isUploadingFile}
        isComplete={engagement.model.status === "COMPLETE"}
        files={engagement?.model?.files ?? {}}
        isSubmitting={energyAPI.updateEngagementData.isLoading}
        efficiencyPercentage={engagement?.model?.efficiencyPercentage ?? 0}
        hvacEfficiency={engagement?.model?.hvacEfficiency ?? 0}
        lightingEfficiency={engagement?.model?.lightingEfficiency ?? 0}
        envelopeEfficiency={engagement?.model?.envelopeEfficiency ?? 0}
        taxYearStart={Number(engagement?.taxYearStart?.split("-")[0])}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };

  const onAddComment = async ({ title, comment }) => {
    await commentsAPI.onCreateComment({ title, comment });
  };

  return (
    <>
      <Engagement
        onUnClaimAssignment={onUnClaimAssignment}
        isComplete={engagement?.model?.status === "COMPLETE"}
        setActiveEngagement={setActiveEngagement}
        fields={fields}
        documents={documents}
        onClickSubmit={() => setOpen(true)}
        onAddComment={onAddComment}
        isCreatingComment={commentsAPI.isCreatingComment}
      />
      <OpenActionCard
        open={open}
        setOpen={setOpen}
        selectedID={SubmissionFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />
    </>
  );
};
