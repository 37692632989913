import { motion } from "framer-motion";

export const ListViewLoading = () => {
  return (
    <main className="flex-1 lg:overflow-hidden">
      <div className="py-6">
        {/*Top bar and logo */}
        <div className="flex flex-row items-center justify-between px-4 sm:px-6 lg:px-8">
          <p className="h-2 w-64 animate-pulse rounded bg-gray-300" />
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        {/* Team member table */}
        <div className="mt-8 px-4 sm:px-6 lg:px-8">
          <div className="sm:flex sm:items-center">
            {/* <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <motion.div
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
              >
                <p className="m-2 h-2 w-16 animate-pulse rounded bg-gray-300" />
              </motion.div>
            </div> */}
            <div className="inline-flex h-10 w-1/5 animate-pulse items-center rounded-xl border border-gray-300 bg-gray-300 px-3 py-1 pl-8"></div>
          </div>
          <div className="-mx-4 mt-8 overflow-hidden shadow ring-1 ring-black ring-opacity-5 sm:-mx-6 md:mx-0 md:rounded-lg">
            <table className="min-w-full divide-y divide-gray-300">
              <thead className="bg-gray-50">
                <tr>
                  <th
                    scope="col"
                    className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                  >
                    <p className="w- h-2 animate-pulse rounded bg-gray-300" />
                  </th>
                  <th
                    scope="col"
                    className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                  >
                    <p className="w- h-2 animate-pulse rounded bg-gray-300" />
                  </th>
                  <th
                    scope="col"
                    className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                  >
                    <p className="w- h-2 animate-pulse rounded bg-gray-300" />
                  </th>
                </tr>
              </thead>
              <tbody className="divide-y divide-gray-200 bg-white">
                {Array.from(Array(10))?.map((item, index) => (
                  <tr key={index}>
                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                      <div className="w- h-2 animate-pulse rounded bg-gray-300"></div>
                      <dl className="font-normal lg:hidden">
                        <dt className="sr-only sm:hidden">Name</dt>
                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                          <div className="w- h-2 animate-pulse rounded bg-gray-300"></div>
                        </dd>
                      </dl>
                    </td>
                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                      <div className="w- h-2 animate-pulse rounded bg-gray-300"></div>
                    </td>
                    <td className="px-3 py-4 text-sm text-gray-500">
                      <div className="w- h-2 animate-pulse rounded bg-gray-300"></div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </main>
  );
};
