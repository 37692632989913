import { baseAPI } from "./baseAPI";

const dataSpacesAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createDataSpace: builder.mutation({
      query: ({ body }) => ({
        url: `/ds/data-space`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error) => [{ type: "DataSpacesList" }],
    }),
    getDataSpace: builder.query({
      query: (id) => ({
        url: `/ds/data-space/${id}`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "DataSpace" }],
    }),
    listDataSpaces: builder.query({
      query: ({ companyID }) => ({
        url: `/ds/list-firm-data-spaces/${companyID}`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "DataSpacesList" }],
    }),
    listAdminDataSpaces: builder.query({
      query: () => ({
        url: `/ds/list-data-spaces`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "DataSpacesList" }],
    }),
    deleteDataSpace: builder.mutation({
      query: (id) => ({
        url: `/ds/data-space/${id}`,
        method: "DELETE",
      }),
      invalidatesTags: (result, error) => [{ type: "DataSpacesList" }],
    }),
    updateDataSpace: builder.mutation({
      query: ({ id, body }) => ({
        url: `/ds/data-space/${id}`,
        method: "PUT",
        body,
      }),
      invalidatesTags: (result, error) => [{ type: "DataSpacesList" }],
    }),
    sendDataSpaceFileUpdate: builder.mutation({
      query: ({ id, body }) => ({
        url: `/ds/send-data-space-file-update/${id}`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useListDataSpacesQuery,
  useListAdminDataSpacesQuery,
  useGetDataSpaceQuery,
  useCreateDataSpaceMutation,
  useDeleteDataSpaceMutation,
  useUpdateDataSpaceMutation,
  useSendDataSpaceFileUpdateMutation,
} = dataSpacesAPI;
