import { Fragment } from "react";
import { motion } from "framer-motion";
import { UploadGaugeColumnLoading } from "./UploadGaugeColumnLoading";
import { classNames } from "../../utils";
import { ReviewGaugeColumnLoading } from "../../ReviewPortal/components";

export const UploadPortalLoadingScreen = () => {
  return (
    <>
      <main className="flex-1 lg:overflow-hidden">
        <div className="py-6">
          {/*Top bar and logo */}
          <div className="flex max-w-7xl flex-row items-center justify-between px-4">
            <div className="ml-2 h-2 w-1/5 animate-pulse rounded bg-sprxGrey"></div>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="px-4">
            {/*Welcome message with icon and submit box*/}
            <div className="flex w-full flex-col justify-between py-4 lg:flex-row">
              <div className="flex flex-col rounded-2xl bg-sprxClientPortalLightTeal p-4 lg:w-8/12">
                <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-1/2 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-1/2 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-1/2 animate-pulse rounded bg-sprxGrey"></div>
              </div>
            </div>

            <div className="w-full pb-8 lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="mb-4 lg:col-span-3 lg:mb-0">
                {/*Left column */}
                <UploadGaugeColumnLoading />
              </div>
              {/*Right column */}
              <div className="col-span-9 flex w-full">
                <div className="grid w-full grid-cols-2 gap-4 lg:grid-cols-3">
                  {Array.from(Array(6)).map((item) => (
                    <motion.div
                      whileHover={{ scale: 1.01 }}
                      className={
                        "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                      }
                    >
                      <motion.div
                        className={classNames(
                          "flex flex-1 flex-col items-center justify-center bg-white p-4 pt-6 xl:py-4",
                        )}
                      >
                        <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                        <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>

                        <motion.button
                          className={classNames(
                            "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none xl:w-3/4",
                          )}
                          whileHover={{ scale: 1.1 }}
                          whileTap={{ scale: 0.95 }}
                        >
                          <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                        </motion.button>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
    </>
  );
};
