// Creates a new object by deeply merging obj1 with obj2, where obj2's properties take precedence
export function deepMerge(target: any, source: any): any {
  // Helper function to check if a value is an object
  const isObject = (obj: any) => obj && typeof obj === "object";

  // Ensure both parameters are objects
  if (!isObject(target) || !isObject(source))
    throw new Error("Both target and source must be objects");

  // Iterate over source properties
  Object.keys(source).forEach((key) => {
    const targetValue = target[key];
    const sourceValue = source[key];

    // If both values are objects, recurse
    if (isObject(targetValue) && isObject(sourceValue)) {
      target[key] = deepMerge({ ...targetValue }, sourceValue);
    }
    // If sourceValue is an array, merge or overwrite depending on your needs
    else if (Array.isArray(targetValue) && Array.isArray(sourceValue)) {
      // This will concatenate arrays, change logic if you want different behavior
      target[key] = Array.from(new Set([...targetValue, ...sourceValue]));
    }
    // Else, use source value (overwrites target value)
    else {
      target[key] = sourceValue;
    }
  });

  return target;
}
