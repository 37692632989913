import React from "react";
import { CheckIcon } from "@heroicons/react/solid";

const TimeSurveySteps = (props) => {
  return (
    <nav aria-label="Progress">
      <ol className="2xl:mx-4 border border-gray-300 rounded-md divide-y divide-gray-300 md:flex md:divide-y-0">
        {props.steps.map((step, stepIdx) => (
          <li key={step.name} className="relative md:flex-1 md:flex">
            {step.status === "complete" ? (
              <button
                onClick={() => {
                  /*let newSteps = props.steps;

                  let oldCurrentStepIndex = newSteps.findIndex(
                    (x) => x.status === "current"
                  );

                  let oldCurrentStep = {
                    ...newSteps[oldCurrentStepIndex],
                    status: "upcoming",
                  };

                  let newStep = {
                    ...newSteps[stepIdx],
                    status: "current",
                  };

                  newSteps[stepIdx] = newStep;

                  newSteps[oldCurrentStepIndex] = oldCurrentStep;

                  props.setActiveStep(newStep.id);

                  props.setSteps([...newSteps]);*/
                }}
                className="group flex items-center w-full focus:outline-none"
              >
                <span className="px-3 py-2 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center bg-green-400 rounded-full group-hover:bg-green-500">
                    <CheckIcon
                      className="w-3 h-3 text-white"
                      aria-hidden="true"
                    />
                  </span>
                  <span className="ml-2 text-xs font-medium text-gray-900">
                    {step.name}
                  </span>
                </span>
              </button>
            ) : step.status === "current" ? (
              <button
                className="px-3 py-2 flex items-center text-sm font-medium focus:outline-none"
                aria-current="step"
              >
                <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border border-sprxBlue rounded-full">
                  <span className="text-xs text-sprxBlue">{step.id}</span>
                </span>
                <span className="ml-2 text-xs font-medium text-sprxBlue">
                  {step.name}
                </span>
              </button>
            ) : (
              <button className="group flex items-center">
                <span className="px-3 py-2 flex items-center text-sm font-medium">
                  <span className="flex-shrink-0 w-5 h-5 flex items-center justify-center border border-gray-300 rounded-full group-hover:border-gray-400">
                    <span className="text-xs text-gray-500 group-hover:text-gray-900">
                      {step.id}
                    </span>
                  </span>
                  <span className="ml-2 text-xs font-medium text-gray-500 group-hover:text-gray-900">
                    {step.name}
                  </span>
                </span>
              </button>
            )}

            {stepIdx !== props.steps.length - 1 ? (
              <>
                <div
                  className="hidden md:block absolute top-0 right-0 h-full w-5"
                  aria-hidden="true"
                >
                  <svg
                    className="h-full w-full text-gray-300"
                    viewBox="0 0 22 80"
                    fill="none"
                    preserveAspectRatio="none"
                  >
                    <path
                      d="M0 -2L20 40L0 82"
                      vectorEffect="non-scaling-stroke"
                      stroke="currentcolor"
                      strokeLinejoin="round"
                    />
                  </svg>
                </div>
              </>
            ) : null}
          </li>
        ))}
      </ol>
    </nav>
  );
};

export default TimeSurveySteps;
