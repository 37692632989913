import { states } from "../../../../ui-components/FlatfileButtonSelectFieldOptions";

export const getReviewFlatfileFields = {
  "SME Email Intelligent Import": [
    {
      label: "SPRX ID",
      key: "ID",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Name",
      key: "Employee Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Employee Department",
      key: "Employee Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Email",
      key: "email",
    },
  ],
  "Projects Intelligent Import": [
    {
      label: "SPRX ID",
      key: "ID",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Name",
      key: "Project Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Description",
      key: "Project Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Project Location",
      key: "Project Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
  ],
  "Contract Research Intelligent Import": [
    {
      label: "SPRX ID",
      key: "ID",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Name",
      key: "Contractor Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Description",
      key: "Contractor Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Department",
      key: "Contractor Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Location",
      key: "Contractor Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Contractor Cost",
      key: "Contractor Cost",
      validators: [{ validate: "required" }],
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
      validators: [{ validate: "required" }],
    },
  ],
  "Supply Intelligent Import": [
    {
      label: "SPRX ID",
      key: "ID",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Name",
      key: "Supply Account Name",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Description",
      key: "Supply Account Description",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Account Department",
      key: "Supply Account Department",
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Location",
      key: "Supply Location",
      type: "select",
      options: states,
      validators: [{ validate: "required" }],
    },
    {
      label: "Supply Cost",
      key: "Supply Cost",
      validators: [{ validate: "required" }],
    },
    {
      label: "QRE Percentage",
      key: "qPercentage",
      validators: [{ validate: "required" }],
    },
  ],
};
