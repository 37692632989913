import * as yup from "yup";

export const getCertificationFormShape = () => {
  return yup.object().shape({
    isAccurate: yup
      .boolean()
      .isTrue()
      .required("Please confirm the model is accurate."),
  });
};
