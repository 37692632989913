import React, {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useImperativeHandle,
} from "react";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

const AgGridDatePickerEditor = forwardRef((props, ref) => {
  console.log("editor", { props });
  const updatedDate =
    props.value.split("/")[1] +
    "/" +
    props.value.split("/")[0] +
    "/" +
    props.value.split("/")[2];
  const refDatePicker = useRef();
  const [date, setDate] = useState(moment(new Date(updatedDate)).toDate());
  const [editing, setEditing] = useState(true);

  useEffect(() => {
    if (!editing) {
      props.api.stopEditing();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [editing]);

  useImperativeHandle(ref, () => {
    return {
      getValue() {
        return moment(date).format("DD/MM/YYYY");
      },
    };
  });

  const onChange = (selectedDate) => {
    setDate(selectedDate);
    setEditing(false);
  };

  console.log({ date });

  return (
    <div className="ag-input-wrapper">
      <DatePicker
        ref={refDatePicker}
        portalId="root"
        popperClassName="ag-custom-component-popup"
        selected={date}
        dateFormat="dd/MM/yyyy"
        onChange={onChange}
      />
    </div>
  );
});

export default AgGridDatePickerEditor;
