import * as yup from "yup";

export const getEmployeeProjectFormShape = () => {
  return yup.object().shape({
    actOne: yup.string(),
    actTwo: yup.string(),
    actThree: yup.string(),
    discovery: yup.string(),
  });
};
