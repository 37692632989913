export const SUPPLY_NAMESPACE = "supply";
export const SUPPLY = "SUPPLY";
export const SUPPLY_REVIEW = "SUPPLY_REVIEW";
export const SUPPLY_REVIEW_NAMESPACE = "supply_review";
export const SUPPLY_ALLOCATIONS = "SUPPLY_ALLOCATIONS";
export const SUPPLY_ALLOCATIONS_NAMESPACE = "dynamic";

export const COMPUTER_NAMESPACE = "computer";
export const COMPUTER = "COMPUTER";
export const COMPUTER_REVIEW = "COMPUTER_REVIEW";
export const COMPUTER_REVIEW_NAMESPACE = "computer_review";
export const COMPUTER_ALLOCATIONS = "COMPUTER_ALLOCATIONS";
export const COMPUTER_ALLOCATIONS_NAMESPACE = "dynamic";

export const CONTRACT_NAMESPACE = "contract";
export const CONTRACT = "CONTRACT";
export const CONTRACT_REVIEW = "CONTRACT_REVIEW";
export const CONTRACT_REVIEW_NAMESPACE = "contract_review";
export const CONTRACT_ALLOCATIONS = "CONTRACT_ALLOCATIONS";
export const CONTRACT_ALLOCATIONS_NAMESPACE = "dynamic";

export const WAGES_NAMESPACE = "wages";
export const WAGES = "WAGES";
export const WAGES_REVIEW = "WAGES_REVIEW";
export const WAGES_REVIEW_NAMESPACE = "wages_review";
export const WAGES_ALLOCATIONS = "WAGES_ALLOCATIONS";
export const WAGES_ALLOCATIONS_NAMESPACE = "dynamic";

export const PROJECTS = "PROJECTS";
export const PROJECTS_REVIEW = "PROJECTS_REVIEW";
export const PROJECTS_REVIEW_NAMESPACE = "projects_review";
export const PROJECTS_NAMESPACE = "project";

export const ALLOCATIONS_NAMESPACE = "dynamic";
