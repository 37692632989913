import * as d3 from "d3";

export const groupData = (lineItems: any[], label: string, value: string) => {
  const data = lineItems.map((item: any) => {
    const group = item[label] || "Unknown";
    const cost = item[value] || 0;

    return {
      group,
      cost,
    };
  });

  const groups = Array.from(new Set(data.map((d) => d.group)));

  const groupedData = groups
    .map((group) => {
      const values = data.filter((d) => d.group === group);
      const totalCost = d3.sum(values, (d: any) => d.cost);

      return {
        group,
        totalCost,
      };
    })
    .sort((a, b) => b.totalCost - a.totalCost);
  return groupedData;
};
