export interface Thread {
  date: string | Date;
  type: "created" | "commented" | "resolved";
  person: {
    name: string;
    imageUrl: string;
  };
  title: string;
  commentID?: string;
  comment?: string;
  status?: string;
  portalType?: string;
}

export const transposeComments = (comment: any): Thread[] => {
  if (!comment) return null;
  let thread = [
    {
      type: "created",
      portalType: comment?.type ?? "",
      person: { name: comment?.createdBy, imageUrl: "" },
      title: comment?.title ?? "", // Adjust according to your actual data structure
      date: comment?.createdAt ?? "",
      commentID: comment?.commentID ?? "",
      status: comment?.status ?? "",
    },
    ...comment?.notes?.map((note: any, index: number) => {
      return {
        type: "commented",
        person: { name: note.createdBy ?? "", imageUrl: "" },
        comment: note.content ?? "", // Adjust according to your actual data structure
        date: note.createdAt ?? "",
        title: comment.title ?? "",
      };
    }),
  ];
  if (comment.status === "RESOLVED") {
    thread.push({
      type: "resolved",
      person: { name: comment?.resolvedBy ?? "", imageUrl: "" },
      title: comment?.title ?? "",
      date: comment?.resolvedAt ?? "",
      commentID: comment?.commentID ?? "",
    });
  }
  return thread;
};
