import { CloudDownloadIcon, TrashIcon } from "@heroicons/react/outline";

interface FileActionButtonProps {
  onClick: () => Promise<void>;
  type: "DELETE" | "DOWNLOAD";
}

export const FileActionButton = ({ onClick, type }: FileActionButtonProps) => {
  const buttonClass = "w-6 h-6 inline-block";
  const icon =
    type === "DELETE" ? (
      <TrashIcon className={buttonClass} />
    ) : (
      <CloudDownloadIcon className={buttonClass} />
    );
  return (
    <div className="hidden sm:block absolute ">
      <button
        type="button"
        className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
        onClick={async () => await onClick()}
      >
        {icon}
      </button>
    </div>
  );
};
