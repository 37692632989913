import React from "react";
import { useForm, Controller } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

// Form validation schema
const schema = yup.object({
  title: yup.string().required("Title is required"),
  comment: yup.string().required("Comment is required"),
});

interface CommentFormProps {
  onSubmitForm: (data: any) => void | Promise<void>;
  isCreatingComment: boolean;
}

export const CommentForm = ({
  onSubmitForm,
  isCreatingComment,
}: CommentFormProps) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(schema),
  });

  const onSubmit = ({ title, comment }: { title: string; comment: string }) => {
    onSubmitForm({ title, comment });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="p-4">
      <div className="mb-4">
        <label
          htmlFor="title"
          className="block text-sm font-medium text-gray-700"
        >
          Title
        </label>
        <Controller
          name="title"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <input
              {...field}
              type="text"
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sprxClientPortalBlue focus:border-sprxClientPortalBlue sm:text-sm"
            />
          )}
        />
        {errors.title && (
          <p className="text-red-500 text-xs italic">Invalid title</p>
        )}
      </div>

      <div className="mb-6">
        <label
          htmlFor="comment"
          className="block text-sm font-medium text-gray-700"
        >
          Comment
        </label>
        <Controller
          name="comment"
          control={control}
          defaultValue=""
          render={({ field }) => (
            <textarea
              {...field}
              rows={4}
              className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-sprxClientPortalBlue focus:border-sprxClientPortalBlue sm:text-sm"
            />
          )}
        />
        {errors.comment && (
          <p className="text-red-500 text-xs italic">Invalid message</p>
        )}
      </div>
      <div className="flex flex-row justify-end w-full">
        <button
          type="submit"
          className="inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue"
        >
          {isCreatingComment ? (
            <div className="animate-pulse mx-2 mt-2 my-2 h-2 bg-white rounded w-8"></div>
          ) : (
            "Submit"
          )}
        </button>
      </div>
    </form>
  );
};
