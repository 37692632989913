import * as React from "react";
import * as d3 from "d3";
import { LineItem } from "../../../types/RdData";
import { DataDisplay } from "./DataDisplay";
import "./BubbleChart.css";

export type QreChartItem = {
  key: string;
  value: number;
};

export interface IChartProps {
  lineItems: LineItem[] | QreChartItem[];
  label: string;
  value: string;
  title: string;
}

export const PieChart = ({ lineItems, label, value, title }: IChartProps) => {
  const svgRef = React.useRef<SVGSVGElement | null>(null);
  const [selectedSlice, setSelectedSlice] = React.useState<any>(0);

  const groupedData = React.useMemo(() => {
    const data = lineItems.map((item: any) => {
      const group = item[label] || "Unknown";
      const cost = item[value] || 0;

      return {
        group,
        cost,
      };
    });
    const groups = Array.from(new Set(data.map((d) => d.group)));

    const groupedData = groups
      .map((group) => {
        const values = data.filter((d) => d.group === group);
        const totalCost = d3.sum(values, (d: any) => d.cost);

        return {
          group,
          totalCost,
        };
      })
      .sort((a, b) => b.totalCost - a.totalCost);
    return groupedData;
  }, [label, lineItems, value]);

  React.useEffect(() => {
    const svg = d3
      .select(svgRef.current)
      .attr("width", 250)
      .attr("height", 250)
      .append("g")
      .attr("transform", "translate(125,125)");

    const radius = Math.min(200, 200) / 2;
    const color = d3.scaleOrdinal(d3.schemeCategory10);

    const pie = d3.pie().value((d: any) => {
      return d.totalCost;
    });
    const path = d3.arc().outerRadius(radius).innerRadius(0);
    const outlinePath = d3
      .arc()
      .outerRadius(radius + 5)
      .innerRadius(0);

    const arc = svg
      .selectAll(".arc")
      .data(pie(groupedData))
      .enter()
      .append("g")
      .attr("class", "arc");

    arc
      .append("path")
      .attr("d", path)
      .attr("fill", (d: any) => {
        return color(d.data.group);
      })
      .attr("stroke", "none")
      .on("click", (event: any, d: any) => {
        setSelectedSlice(d.index);
      });

    // Add a white stroke to the selected slice
    d3.select(svgRef.current)
      .selectAll("path")
      .attr("stroke", (d: any, i: any) =>
        d.index === selectedSlice ? "white" : "none"
      )
      .attr("stroke-width", (d: any, i: any) =>
        d.index === selectedSlice ? 2 : 0
      )
      .attr("d", (d: any, i: any) =>
        d.index === selectedSlice ? outlinePath(d) : path(d)
      );
  }, [groupedData, selectedSlice]);

  if (groupedData.length === 0) return null;

  const lineItemType = () => {
    const item = lineItems[0];
    if ("Employee Location" in item) return "Employee";
    if ("Vendor Location" in item) return "Vendor";
    if ("Supply Location" in item) return "Supply";
    if ("Contractor Location" in item) return "Contractor";
    if ("key" in item) return "key";
    return "Unknown";
  };

  if (lineItemType() === "Unknown") return null;

  const group = groupedData[selectedSlice]?.group ?? "N/A";
  const cost = Math.round(groupedData[selectedSlice]?.totalCost ?? 0);

  return (
    <div className="flex flex-row justify-center items-center">
      <div className="flex flex-col items-center">
        <h1 className="font-bold">{title}</h1>
        {/* Chart */}
        <div className="flex flex-row items-center justify-evenly ">
          <svg ref={svgRef}></svg>
          {/* Summary Box */}
          <div className="rounded-lg bg-white w-60 text-center">
            <div className="flex flex-col w-full justify-center items-center">
              <DataDisplay title={group} value={cost} />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
