import { Tag } from "./Tag";

const REQUIRED_TAGS = ["Wages", "Projects", "Elections", "QRE", "Allocations"];

export const StatusItem = ({
  status,
  title,
  uploadDate,
  uploadedBy,
}: {
  status: string;
  title: string;
  uploadDate: string | undefined;
  uploadedBy: string | undefined;
}) => {
  return (
    <div className="flex items-center justify-start my-4">
      <div className="w-40">
        <Tag name={title} color={status} />
      </div>
      {uploadDate && uploadedBy ? (
        <div className="text-gray-500 ml-8">
          {status === "yellow" ? "Last reviewed on " : "Last approved on "}
          {new Date(uploadDate).toLocaleDateString()} by {uploadedBy}
        </div>
      ) : null}
    </div>
  );
};
