import { EnergyModelerPortal } from "./EneryModelerPortal";
import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../rtk/Users/useUsers";
import { PortalContainerLoading } from "../components/PortalContainerLoading";

export const EnergyModelerPortalContainer = () => {
  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();

  if (!energyEngagementAPI || !usersAPI) return <PortalContainerLoading />;
  return <EnergyModelerPortal user={usersAPI.user} />;
};
