import { useState, useEffect } from "react";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { FlatlistSelectionPane } from "../components";
import { EditProjectForm } from ".";
import { AiLoadingAnimation } from "../../../../ui-components";
import { isDev } from "../../utils";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { projectHasCompletions } from "./utils";
import { FlatfileSpace } from "../../../../ui-components/FlatfileSpace";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { NoItemsFound } from "../components/NoItemsFound";
import { SprxLoading } from "../../../../ui-components";

export const ProjectsCard = (props) => {
  const [selectedProject, setSelectedProject] = useState(null);
  const [mappedCosts, setMappedCosts] = useState([]);
  const [isMappingCosts, setIsMappingCosts] = useState(false);
  const [isRunningCompletions, setIsRunningCompletions] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [showSpace, setShowSpace] = useState(false);
  const [projectsLoadingCount, setProjectsLoadingCount] = useState(0);

  const { user } = useUsers();

  const {
    wageData,
    supplyData,
    contractData,
    computerLeaseData,
    projectData,
    updateData,
    refreshData,
    deleteProject,
    updateLineItem,
    isDeletingProject,
    isFetching,
  } = useDataArray();

  const dataAPI = useDataArray();

  const { engagementData } = useEngagementData();

  const { createProjectCompletions } = useCompute();

  // Append New Data & Update s3
  const updateSelectedItem = async (model) => {
    setIsSaving(true);
    const index = projectData.findIndex((e) => e.ID === model.ID);
    const lineItem = {
      ...projectData[index],
      ...model,
    };
    await updateLineItem(lineItem, "PROJECTS_REVIEW");
    setIsSaving(false);
  };

  // Regenerate Project Completions -- invokes useEffect that handles API calls
  const regenerateProjectCompletions = async () => {
    const index = projectData.findIndex((e) => e.ID === selectedProject.ID);
    let tmpData = projectData;
    tmpData[index] = {
      ...projectData[index],
      extendedDescription: undefined,
      purpose: undefined,
      experimentation: undefined,
      uncertainty: undefined,
      nature: undefined,
    };
    setSelectedProject(tmpData[index]);
    await updateData(tmpData, "Projects");
  };

  // Initialize Project Index to 0 onMount
  useEffect(() => {
    if (projectData.length === 0 || selectedProject !== null) return;
    setSelectedProject(projectData[0]);
  }, [projectData, selectedProject]);

  // Determine Mapped Project Costs
  useEffect(() => {
    if (projectData.length === 0) return;
    setIsMappingCosts(true);
    const mappedCostsTmp = projectData.map((project) => {
      let mappedCost = 0;
      [wageData, supplyData, computerLeaseData, contractData].forEach((arr) => {
        arr.forEach((e) => {
          const qre = Number(e.qre);
          const hasProjectAllocation = e.hasOwnProperty(project.ID);
          if (hasProjectAllocation) {
            mappedCost += (e[project.ID] / 100) * qre;
          }
        });
      });
      return {
        ID: project.ID,
        mappedCost,
      };
    });
    setMappedCosts(mappedCostsTmp);
    setIsMappingCosts(false);
  }, [computerLeaseData, contractData, projectData, supplyData, wageData]);

  // Delete Project
  const onDeleteProject = async (projectID) => {
    if (projectData.length === 1) {
      alert(
        "You must have at least one project. Add another project before deleting this one.",
      );
    } else {
      await deleteProject(projectID);
    }
  };

  // Regenerates Completions if they are missing, onMount
  useEffect(() => {
    async function regenerateCompletions() {
      const completions_response = await createProjectCompletions();
      if (isDev) console.log({ completions_response });
      return completions_response;
    }
    const errorCount = projectData.filter(
      (project) => !projectHasCompletions(project),
    ).length;
    setProjectsLoadingCount(errorCount);

    let hasCompletionError = !isRunningCompletions && errorCount > 0;

    const isRegeneratingCompletions =
      engagementData.isGeneratingProjectCompletions;

    if (hasCompletionError && !isRegeneratingCompletions) {
      console.log("Regenerating completions ...");
      setIsRunningCompletions(true);
      regenerateCompletions().catch((err) => {
        if (isDev) console.log({ err });
      });
    }
  }, [
    createProjectCompletions,
    engagementData.isGeneratingProjectCompletions,
    isRunningCompletions,
    projectData,
  ]);

  const onCloseCallback = async () => {
    await refreshData("Projects");
    setShowSpace(false);
  };

  // TODO: Create Empty Loading State
  // const loading =
  //  isDownloadingFile || isLoadingEngagementData || isDeletingProject;

  return (
    <div className="z-40 px-8 pb-8">
      {projectsLoadingCount > 0 ? (
        <div>
          <p>
            {projectsLoadingCount} Project{projectsLoadingCount > 1 ? "s" : ""}{" "}
            Processing
          </p>
        </div>
      ) : null}
      <div className="flex h-76vh w-full flex-row items-start justify-between">
        <FlatlistSelectionPane
          searchTitle={"Project Name"}
          items={projectData.map((item, index) => ({
            ...item,
            index,
            showIcon: !projectHasCompletions(item),
          }))}
          setSelectedItem={(item) => {
            const project = projectData.find((e) => e.ID === item);
            setSelectedProject(project);
          }}
          selectedItem={selectedProject?.ID}
          onClickAddCallback={() => setShowSpace(true)}
          nameKey={"Project Name"}
          idKey={"ID"}
          nameIcon={<p>++</p>}
        />
        <div className="flex h-full w-3/4 items-center justify-center">
          {isFetching ? (
            <div className="flex h-800px items-center justify-center">
              <SprxLoading />
            </div>
          ) : (
            <>
              {projectData.length === 0 ? (
                <NoItemsFound type={"Projects"} />
              ) : (
                <>
                  {!projectHasCompletions(selectedProject) ? (
                    <div className="flex h-85vh w-full flex-col items-center justify-center">
                      <AiLoadingAnimation />
                    </div>
                  ) : (
                    // {projectData.length===0 ? }
                    <EditProjectForm
                      project={selectedProject}
                      isMappingCosts={isMappingCosts}
                      mappedCost={
                        mappedCosts?.find((a) => a.ID === selectedProject?.ID)
                          ?.mappedCost ?? 0
                      }
                      updateSelectedItem={updateSelectedItem}
                      onDeleteProject={onDeleteProject}
                      isDeletingProject={isDeletingProject}
                      regenerateCompletions={regenerateProjectCompletions}
                      loading={isSaving}
                    />
                  )}
                </>
              )}
            </>
          )}

          <FlatfileSpace
            showSpace={showSpace}
            setShowSpace={setShowSpace}
            namespace={"project"}
            engagementID={engagementData?.engagementID}
            companyName={engagementData?.companyName}
            type={"PROJECTS"}
            name={user.fullName}
            onCloseCallback={onCloseCallback}
          />
        </div>
      </div>
    </div>
  );
};
