import { useState } from "react";
import { CheckCircleIcon } from "@heroicons/react/outline";
import { CommentForm } from "../../components";
import { CommentsContainer } from "../../components/CommentsContainer";
import { ClosedActionCard } from "../../../R&D/UploadPortal/components";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";
import { Engagement } from "../../types";
import { WelcomeMessage } from "./components/WelcomeMessage";
import { DocumentMemosContainer } from "./Documents/DocumentMemosContainer";
import { FinalizeDeliverables } from "./components/FinalizeDeliverables";
import { InviteUser } from "./components/InviteUser";
import { AddUserForm } from "../../../../ui-components/AddUserForm";
import { ReviewGaugeColumn } from "./components/ReviewGaugeColumn";
import { ExecutiveReportContainer } from "./ExecutiveReport/ExecutiveReportContainer";
import ChatProvider from "../../../../rtk/Chat/ChatProvider";
import { ChatContainer } from "../../../R&D/DeliverablesPortal/Chat/ChatContainer";
import { onCreateCommentParams } from "../../../../rtk/Energy/Comments/EnergyCommentsContext";
import { ArrowLeftIcon } from "@heroicons/react/outline";

interface ReportProps {
  engagement: Engagement;
  user: any;
  onFinalizeDeliverables: any;
  isFinalizing: boolean;
  isCreatingComment: boolean;
  isGeneratingDeliverables: boolean;
  onCreateComment: (e: onCreateCommentParams) => Promise<void>;
  setViewEngDetail: (bool: boolean) => void;
}

export const Reports = ({
  engagement,
  user,
  onFinalizeDeliverables,
  isFinalizing,
  isCreatingComment,
  isGeneratingDeliverables,
  onCreateComment,
  setViewEngDetail,
}: ReportProps) => {
  const [openCreateComment, setOpenCreateComment] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(undefined);
  const [inviteOpen, setInviteOpen] = useState(false);
  const isFinalized = !engagement.reports.isDraft;

  const cards = [
    {
      id: 1,
      title: "Executive Report",
      description: "Take a look at your executive report",
      extendedDescription: "",
      icon: null,
      component: <ExecutiveReportContainer />,
      hideCloseIcon: true,
      openText: "Open",
      isLoading: isGeneratingDeliverables,
    },
    {
      id: 2,
      title: "Documents",
      description: "Review & download your documents.",
      extendedDescription: "",
      icon: null,
      component: <DocumentMemosContainer />,
      hideCloseIcon: true,
    },
    {
      id: 3,
      title: "Chat",
      description: "Chat with your personal AI chat assistant",
      extendedDescription: "",
      icon: null,
      component: (
        <ChatProvider>
          <ChatContainer
            knowledgeBaseID={
              engagement?.integrations?.superpowered.knowledgeBaseId
            }
            engagementID={engagement.engagementID}
          />
        </ChatProvider>
      ),
      hideCloseIcon: true,
    },
    {
      id: 4,
      title: "Comments",
      description: "View comments from your clients.",
      extendedDescription: "",
      icon: null,
      component: (
        <CommentsContainer
          onClickAddCallback={() => setOpenCreateComment(true)}
          portalType="REVIEW"
        />
      ),
      hideCloseIcon: true,
      openText: "Open",
    },
  ];

  const CommentFormProps = {
    id: 1,
    title: "Comment",
    description: "Add a comment.",
    extendedDescription: "If you have any questions, please reach out to us",
    component: (
      <CommentForm
        isCreatingComment={isCreatingComment}
        onSubmitForm={async (e: any) => {
          await onCreateComment({
            title: e.title,
            comment: e.comment,
            role: user.role,
          });
          setOpenCreateComment(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };
  return (
    <main className="flex-1 overflow-hidden">
      <div className={`flex-1 ${user.role === "client" ? "pt-6" : ""}`}>
        {/*Top bar and logo */}
        <div className="mx-auto flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
          <div className="flex flex-row items-center">
            <button
              onClick={() => {
                setViewEngDetail(false);
              }}
              className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              <ArrowLeftIcon className="h-8 w-8" />
            </button>
            <h1 className="ml-2 flex flex-row items-center justify-center text-2xl font-semibold text-gray-900">
              {engagement.name}
              <CheckCircleIcon
                className={`ml-1 h-6 w-6 align-middle ${
                  isFinalized ? "text-sprxGreen" : "text-gray-400"
                }`}
              />
            </h1>
          </div>

          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        <div className="flex px-4">
          <div className="w-full">
            <div className="flex w-full flex-row justify-evenly">
              <WelcomeMessage name={user.fullName.split(" ")[0]} />
              <div className="mr-4 flex w-1/2 flex-row justify-evenly">
                {user.role === "admin" ? (
                  <FinalizeDeliverables
                    onClickFinalize={onFinalizeDeliverables}
                    isFinalizing={isFinalizing}
                    isFinalized={isFinalized}
                  />
                ) : (
                  <div className="w-1/2"></div>
                )}

                <InviteUser onClickInvite={() => setInviteOpen(true)} />
              </div>
            </div>
            <div className="grid w-full grid-cols-12 gap-4 pb-16">
              <div className="col-span-3">
                <ReviewGaugeColumn engagement={engagement} />
              </div>
              <div className="col-span-9 flex w-full">
                <div className={"grid w-full grid-cols-2 gap-4"}>
                  {cards.map((item) => (
                    <ClosedActionCard
                      key={`${item.title}-deliverables-action-card`}
                      item={item}
                      setOpen={setOpen}
                      openText={item?.openText ?? "Open"}
                      setSelectedID={setSelectedID}
                      setCloseVisible={null}
                      isLoading={item.isLoading || false}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpenActionCard
        selectedID={selectedID}
        open={open}
        setOpen={setOpen}
        setSelectedID={setSelectedID}
        closeVisible={true}
        setCloseVisible={() => null}
        small={false}
      />
      <OpenActionCard
        open={openCreateComment}
        setOpen={setOpenCreateComment}
        selectedID={CommentFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />

      <AddUserForm
        open={inviteOpen}
        setOpen={setInviteOpen}
        engagementData={engagement}
        engagementType="EN"
      />
    </main>
  );
};
