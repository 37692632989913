import { Fragment, useEffect, useState } from "react";
import { motion } from "framer-motion";
import { Dialog, Transition } from "@headlessui/react";
import { MenuIcon, XIcon } from "@heroicons/react/outline";
import { Outlet, NavLink } from "react-router-dom";
import { useIntercom } from "react-use-intercom";
import { DefaultPhoto } from "../main/Profile/DefaultPhoto";
import { useGetSignedURLMutation } from "../api/fileAPI";
import { classNames } from "../main/R&D/utils";
import { buildNavigationArray } from "./utils";
import { useUsers } from "../rtk/Users/useUsers";
import { useGetEngagementDataQuery } from "../api/engagmentAPI";
import { useGetEnergyEngagementQuery } from "../api/energy/engagementAPI";

const getSidebarColor = (type, light) => {
  switch (type) {
    case "CAP":
      if (light) return "bg-sprxClientPortalLightGreen";
      return "bg-sprxClientPortalDarkGreen";
    case "RD":
      if (light) return "bg-sprxClientPortalLightBlue";
      return "bg-sprxClientPortalDarkBlue";
    case "DS":
      if (light) return "bg-sprxClientPortalLightBlue";
      return "bg-sprxClientPortalDarkBlue";
    case "EN":
      if (light) return "bg-sprxClientPortalLightBlue";
      return "bg-sprxClientPortalDarkBlue";
    default:
      if (light) return "bg-sprxClientPortalLightBlue";
      return "bg-sprxClientPortalDarkBlue";
  }
};

const SidebarLayout = (props) => {
  const { user } = useUsers();
  const { data: engagementData, isLoading: isLoadingEngagementData } =
    useGetEngagementDataQuery(user?.data?.activeEngagementID ?? "");

  const { data: energyEngagementData, isLoading: isLoadingEnergyEngagaement } =
    useGetEnergyEngagementQuery(user?.data?.activeEnergyEngagementID ?? "");

  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [authedImage, setAuthedImage] = useState("");
  const [getSignedURL, { loading: isGettingURL }] = useGetSignedURLMutation();
  const [openDropdown, setOpenDropdown] = useState(false);

  const { boot } = useIntercom();

  const navigation = buildNavigationArray(
    user,
    engagementData,
    energyEngagementData,
    isLoadingEngagementData || isLoadingEnergyEngagaement,
  );

  const handleToggleDropdown = (name) => {
    setOpenDropdown(openDropdown === name ? null : name);
  };

  useEffect(() => {
    boot({ name: props?.user?.fullName ? props.user.fullName : "User" });
  }, [props?.user?.fullName, boot]);

  // Load User Profile Photo
  useEffect(() => {
    async function getURL() {
      const body = {
        bucket: engagementData?.s3Bucket?.name ?? "",
        key: user?.photoUrl ?? "",
        expires: 86400,
      };
      const signedURLResponse = await getSignedURL(body);
      return signedURLResponse;
    }

    if (
      engagementData?.s3Bucket?.name !== undefined &&
      user?.photoUrl !== undefined
    ) {
      getURL().then((response) => {
        if (response?.data?.error || response?.error) return;
        setAuthedImage(response.data.data.signedRequest);
      });
    }
  }, [
    getSignedURL,
    user.photoUrl,
    isLoadingEngagementData,
    engagementData?.s3Bucket?.name,
    user.role,
  ]);

  return (
    <>
      <div>
        {/*Mobile side bar menu*/}
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-40 md:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
            </Transition.Child>

            <div className="fixed inset-0 z-40 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel
                  className={
                    "relative flex w-full max-w-xs flex-1 flex-col " +
                    getSidebarColor(user.engagementType, true)
                  }
                >
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute top-0 right-0 -mr-12 pt-2">
                      <motion.button
                        type="button"
                        whileTap={{ scale: 0.95 }}
                        className="ml-1 flex h-10 w-10 items-center justify-center rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </motion.button>
                    </div>
                  </Transition.Child>
                  <div className="h-0 flex-1 overflow-y-auto pt-5 pb-4">
                    <div className="flex flex-shrink-0 flex-col items-center px-4 py-2">
                      <span
                        className={classNames(
                          "inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100",
                          isLoadingEngagementData || isGettingURL
                            ? "animate-pulse"
                            : "",
                        )}
                      >
                        {user?.photoUrl &&
                        authedImage.length > 0 &&
                        isGettingURL !== true ? (
                          <img alt="uploaded-profile" src={authedImage} />
                        ) : (
                          <DefaultPhoto />
                        )}
                      </span>
                      <p className="mt-2 border-t border-gray-300 text-lg font-medium text-white">
                        {props?.user?.fullName ? props?.user?.fullName : "User"}
                      </p>
                    </div>
                    <nav className="mt-5 space-y-1 px-2">
                      {navigation.map((item) => (
                        <NavLink to={item.path} key={item.name}>
                          {({ isActive }) => (
                            <motion.button
                              key={item.name}
                              data-test={`sidebar-nav-${item.name}`}
                              whileTap={{ scale: 0.95 }}
                              className={classNames(
                                isActive
                                  ? `text-white ${getSidebarColor(
                                      user.engagementType,
                                      false,
                                    )}`
                                  : `text-white hover:${getSidebarColor(
                                      user.engagementType,
                                      false,
                                    )} hover:text-white`,
                                "group my-1 flex w-full items-center rounded-md px-2 py-2 text-base font-medium",
                              )}
                            >
                              <item.icon
                                className={classNames(
                                  isActive
                                    ? "text-white"
                                    : "text-white group-hover:text-white",
                                  "mr-4 h-6 w-6 flex-shrink-0",
                                )}
                                aria-hidden="true"
                              />
                              {item.name}
                            </motion.button>
                          )}
                        </NavLink>
                      ))}
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
              <div className="w-14 flex-shrink-0">
                {/* Force sidebar to shrink to fit close icon */}
              </div>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="relative z-0 hidden md:fixed md:inset-y-0 md:flex md:w-64 md:flex-col">
          <div className="flex min-h-0 flex-1 flex-col border-r border-gray-200 bg-white">
            <div
              className={
                "flex flex-1 flex-col overflow-y-auto pt-5 pb-4 " +
                getSidebarColor(user.engagementType, true)
              }
            >
              <div
                className={
                  "mr-5 flex flex-shrink-0 flex-col items-center px-4 py-2 " +
                  getSidebarColor(user.engagementType, true)
                }
              >
                <span className="inline-block h-20 w-20 overflow-hidden rounded-full bg-gray-100">
                  {user?.photoUrl &&
                  authedImage.length > 0 &&
                  isGettingURL !== true ? (
                    <img alt="uploaded-profile" src={authedImage} />
                  ) : (
                    <DefaultPhoto />
                  )}
                </span>
                <p className="mt-2 border-t border-gray-300 text-lg font-medium text-white">
                  {props?.user?.fullName ? props?.user?.fullName : "User"}
                </p>
              </div>
              <nav
                className={
                  "mt-5 flex-1 space-y-1 px-2 " +
                  getSidebarColor(user.engagementType, true)
                }
              >
                {navigation.map((item) => (
                  <NavLink to={item.path} key={item.name}>
                    {({ isActive }) => (
                      <motion.button
                        key={item.name}
                        whileHover={{ scale: 1.02 }}
                        data-test={`sidebar-nav-${item.name}`}
                        whileTap={{ scale: 0.95 }}
                        className={classNames(
                          isActive
                            ? `text-white ${getSidebarColor(
                                user.engagementType,
                                false,
                              )}`
                            : `text-white hover:${getSidebarColor(
                                user.engagementType,
                                false,
                              )} hover:text-white`,
                          "group my-1 flex w-full items-center rounded-2xl px-2 py-2 text-sm font-medium",
                        )}
                      >
                        <item.icon
                          className={classNames(
                            isActive
                              ? "text-white"
                              : "text-white group-hover:text-white",
                            "mr-3 h-6 w-6 flex-shrink-0",
                          )}
                          aria-hidden="true"
                        />
                        {item.name}
                      </motion.button>
                    )}
                  </NavLink>
                ))}
              </nav>
            </div>
          </div>
        </div>

        <div className="relative z-40 flex h-full min-h-screen flex-1 flex-col rounded-l-3xl bg-white md:ml-58.75 lg:h-screen lg:overflow-hidden">
          {/*Mobile sidebar overlay and close button */}
          <div className="sticky top-0 bg-gray-100 pl-1 pt-1 sm:pl-3 sm:pt-3 md:hidden">
            <motion.button
              type="button"
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="focus:ring-none -ml-0.5 -mt-0.5 inline-flex h-12 w-12 items-center justify-center rounded-md text-gray-500 hover:text-gray-900 focus:outline-none"
              onClick={() => setSidebarOpen(true)}
            >
              <span className="sr-only">Open sidebar</span>
              <MenuIcon className="h-6 w-6" aria-hidden="true" />
            </motion.button>
          </div>
          <Outlet />
        </div>
      </div>
    </>
  );
};

const NavLinkItem = ({ item, handleToggleDropdown, user, openDropdown }) => (
  <div key={item.name}>
    {item.children ? (
      <NavLinkWithChild
        item={item}
        handleToggleDropdown={handleToggleDropdown}
        user={user}
        openDropdown={openDropdown}
      />
    ) : (
      <NavLinkWithoutChild item={item} user={user} />
    )}
  </div>
);

const NavLinkWithChild = ({
  item,
  handleToggleDropdown,
  user,
  openDropdown,
}) => (
  <>
    <button
      onClick={() => handleToggleDropdown(item.name)}
      className={classNames(
        `text-white hover:${getSidebarColor(
          user.engagementType,
          false,
        )} hover:text-white`,
        "group my-1 flex w-full items-center rounded-2xl px-2 py-2 text-sm font-medium",
      )}
    >
      <item.icon
        className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
        aria-hidden="true"
      />
      {item.name}
      <svg
        className={classNames(
          "ml-auto mr-4 h-5 w-5",
          openDropdown === item.name ? "rotate-180 transform" : "",
        )}
        xmlns="http://www.w3.org/2000/svg"
        viewBox="0 0 20 20"
        fill="currentColor"
        aria-hidden="true"
      >
        <path
          fillRule="evenodd"
          d="M5.23 7.21a.75.75 0 011.06 0L10 10.94l3.71-3.73a.75.75 0 011.06 1.06l-4.24 4.25a.75.75 0 01-1.06 0L5.23 8.27a.75.75 0 010-1.06z"
          clipRule="evenodd"
        />
      </svg>
    </button>
    {item.children && openDropdown === item.name && (
      <div className="ml-6 space-y-1">
        {item.children.map((subItem) => (
          <NavLink to={subItem.path} key={subItem.name}>
            {({ isActive }) => (
              <motion.button
                key={subItem.name}
                whileHover={{ scale: 1.02 }}
                data-test={`sidebar-nav-${subItem.name}`}
                whileTap={{ scale: 0.95 }}
                className={classNames(
                  isActive
                    ? `text-white ${getSidebarColor(
                        user.engagementType,
                        false,
                      )}`
                    : `text-white hover:${getSidebarColor(
                        user.engagementType,
                        false,
                      )} hover:text-white`,
                  "group my-1 flex w-full items-center rounded-2xl px-2 py-2 text-sm font-medium",
                )}
              >
                <subItem.icon
                  className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
                  aria-hidden="true"
                />
                {subItem.name}
              </motion.button>
            )}
          </NavLink>
        ))}
      </div>
    )}
  </>
);

const NavLinkWithoutChild = ({ item, user }) => (
  <NavLink
    to={item.path}
    className={classNames(
      `text-white hover:${getSidebarColor(
        user.engagementType,
        false,
      )} hover:text-white`,
      "group my-1 flex w-full items-center rounded-2xl px-2 py-2 text-sm font-medium",
    )}
  >
    <item.icon
      className="mr-3 h-6 w-6 flex-shrink-0 text-white group-hover:text-white"
      aria-hidden="true"
    />
    {item.name}
  </NavLink>
);

export default SidebarLayout;
