import { TeamPage } from "./TeamPage";

function Team() {
  return (
    <div>
      <TeamPage />
    </div>
  );
}

export default Team;
