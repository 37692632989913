import React from "react";

const AgGridCheckbox = (props) => {
  const checkedHandler = (event) => {
    let checked = event.target.checked;
    let colId = props.column.colId;
    props.node.setDataValue(colId, checked);
  };

  // TODO: Figure out why browser is requirig onChange
  return (
    <input
      type="checkbox"
      onClick={(event) => checkedHandler(event)}
      checked={props.value}
      onChange={() => null}
    />
  );
};

export default AgGridCheckbox;
