import { useEffect, useMemo, useCallback } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { states } from "../../../../ui-components/FlatfileButtonSelectFieldOptions";
import {
  getEditProjectFormShape,
  getDefaultEditProjectFormFields,
  formatProjectEditForSubmission,
} from "./utils";
import { classNames } from "../../utils";
import { LightningBoltIcon } from "@heroicons/react/outline";
import { debounce } from "lodash";

export const EditProjectForm = (props) => {
  const { project, updateSelectedItem, loading } = props;

  const { control, formState, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultEditProjectFormFields(project);
    }, [project]),
    resolver: yupResolver(getEditProjectFormShape()),
  });

  useEffect(() => {
    reset(getDefaultEditProjectFormFields(project));
  }, [project, reset]);

  const { errors } = formState;

  // Update Form Values on Text Change
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onAutoSave = useCallback(
    debounce((data) => {
      updateSelectedItem(formatProjectEditForSubmission(data));
    }, 2000),
    [] // Empty dependency array
  );

  // Update Form Values on Text Change
  const handleFieldChange = useCallback(
    (name, value) => {
      setValue(name, value);
      handleSubmit((data) => onAutoSave(data))();
    },
    [setValue, handleSubmit, onAutoSave]
  );

  return (
    <div className="w-full rounded-lg shadow-xl border p-8 ml-4 min-h-full">
      <form
        data-test="edit-project-form"
        className="w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-12">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-4">
                <div className="flex flex-row">
                  <label
                    htmlFor="name"
                    className="block text-sm font-black text-gray-700"
                  >
                    Project Name
                  </label>
                </div>
                <div className="mt-1">
                  <motion.input
                    {...field}
                    id="name"
                    data-test="name"
                    name="name"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) => handleFieldChange("name", e.target.value)}
                  />
                  {errors?.name && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Project Name
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <div className="sm:col-span-2">
            <div className="flex flex-row">
              <label
                htmlFor="l"
                className="block text-sm font-black text-gray-700"
              >
                Qualified Cost
              </label>
            </div>
            <div className="mt-1">
              <motion.input
                disabled={true}
                id="mappedCost"
                onChange={null}
                data-test="mappedCost"
                name="mappedCost"
                type="text"
                value={`$${Math.round(
                  isNaN(Number(props?.mappedCost))
                    ? 0
                    : Number(props?.mappedCost)
                ).toLocaleString()}`}
                className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                whileHover={{ scale: 1.01 }}
              />
            </div>
          </div>
          <Controller
            name="location"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-2">
                <div className="flex flex-row">
                  <label
                    htmlFor="l"
                    className="block text-sm font-black text-gray-700"
                  >
                    Project Location
                  </label>
                </div>
                <div className="mt-1">
                  <motion.select
                    {...field}
                    id="location"
                    name="location"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("location", e.target.value)
                    }
                  >
                    <option></option>
                    {states.map((state, index) => {
                      return (
                        <option
                          id={state.value}
                          key={state.value + index}
                          value={state.value}
                        >
                          {state.value}
                        </option>
                      );
                    })}
                  </motion.select>
                  {errors?.location && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Project Location
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <div className="sm:col-span-2">
            <button
              className={classNames(
                "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                "w-full justify-end px-2 py-2 mt-6 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white text-center focus:outline-none focus:ring-none"
              )}
              onClick={async () => {
                props.regenerateCompletions();
              }}
            >
              {props.isDeletingProject ? (
                <div className="w-full px-12 py-1">
                  <p className="animate-pulse bg-white rounded h-2 w-16"></p>
                </div>
              ) : (
                "Re-Generate"
              )}
            </button>
          </div>
          <div className="sm:col-span-1">
            <button
              className={classNames(
                "bg-sprxClientPortalRed",
                "w-full justify-end px-2 py-2 mt-6 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white text-center focus:outline-none focus:ring-none"
              )}
              onClick={async () => {
                props.onDeleteProject(props.project.ID);
              }}
            >
              {props.isDeletingProject ? (
                <div className="w-full px-2 py-1">
                  <p className="animate-pulse bg-white rounded h-2 w-full"></p>
                </div>
              ) : (
                "Delete"
              )}
            </button>
          </div>
          {loading ? (
            <div className="sm:col-span-1">
              <LightningBoltIcon className="w-6 h-6 ml-4 mt-7 animate-pulse" />
            </div>
          ) : null}
          <Controller
            name="extendedDescription"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="extendedDescription"
                  className="block text-sm font-black text-gray-700"
                >
                  Extended Description
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    id="extendedDescription"
                    name="extendedDescription"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent  min-h-20vh"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("extendedDescription", e.target.value)
                    }
                  />
                  {errors?.extendedDescription && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Extended Description
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="purpose"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="purpose"
                  className="block text-sm font-black text-gray-700"
                >
                  Permitted Purpose
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    id="purpose"
                    name="purpose"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("purpose", e.target.value)
                    }
                  />
                  {errors?.purpose && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Permitted Purpose
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="experimentation"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="experimentation"
                  className="block text-sm font-black text-gray-700"
                >
                  Process of Experimentation
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    id="experimentation"
                    name="experimentation"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("experimentation", e.target.value)
                    }
                  />
                  {errors?.purpose && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Process of Experimentation
                    </p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="uncertainty"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="uncertainty"
                  className="block text-sm font-black text-gray-700"
                >
                  Uncertainty
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    id="uncertainty"
                    name="uncertainty"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("uncertainty", e.target.value)
                    }
                  />
                  {errors?.purpose && (
                    <p className=" pl-2 text-sprxOrange">Invalid Uncertainty</p>
                  )}
                </div>
              </div>
            )}
          />
          <Controller
            name="nature"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="nature"
                  className="block text-sm font-black text-gray-700"
                >
                  Technological in Nature
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    id="nature"
                    name="nature"
                    type="text"
                    className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.01 }}
                    onChange={(e) =>
                      handleFieldChange("nature", e.target.value)
                    }
                  />
                  {errors?.purpose && (
                    <p className=" pl-2 text-sprxOrange">
                      Invalid Technological in Nature
                    </p>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      </form>
    </div>
  );
};
