import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { BuildingProfile } from "./BuildingProfile";

export const BuildingProfileContainer = ({ onClose }) => {
  const energyEngagementAPI = useEnergyEngagement();

  const loading =
    !energyEngagementAPI || energyEngagementAPI.allEngagementsLoading;
  if (loading) return <>BuildingProfileLoadingState</>;

  const engagementID: string = energyEngagementAPI.engagement.engagementID;

  const onUpdateBuildingProfile = async (model: any) => {
    const body = { buildingProfile: model };
    await energyEngagementAPI.updateEngagement({
      engagementID,
      body,
    });
    const computeResponse = await energyEngagementAPI.compute179D({
      engagementID,
    });
    onClose();
  };
  const isLoading = energyEngagementAPI.updateEngagementData.isLoading;
  return (
    <BuildingProfile
      onUpdateBuildingProfile={onUpdateBuildingProfile}
      buildingProfileData={energyEngagementAPI.engagement.buildingProfile}
      isLoading={isLoading}
    />
  );
};
