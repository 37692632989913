import { useState, useEffect, useRef } from "react";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { AiLoadingAnimation } from "../../../../ui-components";
import { isDev, performClick } from "../../utils";
import { EditDocumentForm } from ".";
import { FlatlistSelectionPane } from "../components";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { parseUploadedDocuments } from "../../UploadPortal/Documentation/utils";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { buildProjectSelectOptions } from "./utils";
import { NoItemsFound } from "../components/NoItemsFound";

export const DocumentsCard = () => {
  const inputFileRef = useRef();
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [isRunningCompletions, setIsRunningCompletions] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const { engagementData, updateEngagementData, isUpdatingEngagementData } =
    useEngagementData();

  const { user } = useUsers();

  const { projectData, isDownloadingFile } = useDataArray();

  const { uploadDirectoryFile, uploadFile } = useFile();

  const { extractTaxSupportingDocuments } = useCompute();

  // Initializes Selection
  useEffect(() => {
    if (
      engagementData.documentation.documents.length !== 0 &&
      selectedDocument === null
    ) {
      setSelectedDocument(engagementData.documentation.documents[0]);
    }
  }, [
    engagementData.documentation.documents,
    selectedDocument,
    isUpdatingEngagementData,
  ]);

  // Handles Text Area Edit
  const updateSelectedItem = async (model) => {
    setIsSaving(true);
    const tmpDocs = engagementData.documentation.documents.map((d) => d);
    const editIndex = engagementData.documentation.documents.findIndex((d) => {
      return d.key === model.key;
    });
    tmpDocs[editIndex] = {
      ...engagementData.documentation.documents[editIndex],
      ...model,
    };
    setSelectedDocument(tmpDocs[editIndex]);

    const body = {
      documentation: {
        documents: tmpDocs,
      },
    };
    const documentationUpdate = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });

    if (isDev) console.log({ documentationUpdate });
    setIsSaving(false);
  };

  // Handles Document Upload
  const onDocumentUpload = async (event) => {
    setIsSaving(true);
    const { files } = event.target;
    const userName = user.fullName;

    // Upload to S3 & Update Pointers for Extraction
    const { uploadParameters, updateDocs } = parseUploadedDocuments(
      files,
      engagementData
    );

    await Promise.all(
      uploadParameters.map((uploadParam) => {
        return uploadFile(uploadParam);
      })
    );

    var filteredDocs = updateDocs;
    engagementData.documentation.documents.forEach((doc) => {
      filteredDocs = filteredDocs.filter((item) => item.key !== doc.key);
    });
    const body = {
      documentation: {
        documents: [...engagementData.documentation.documents, ...filteredDocs],
      },
      isGeneratingDocumentsCompletions: false,
    };
    const doucmentationUpdate = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });
    if (isDev) console.log({ doucmentationUpdate });

    // Upload File to Documents Library
    await Promise.all(
      uploadParameters.map(async (uploadParam) => {
        const body = {
          ...uploadParam,
          user: userName,
          folder: "Documentation",
        };
        return uploadDirectoryFile({ body });
      })
    );

    const extractTaxSupportingDocumentsResponse =
      await extractTaxSupportingDocuments();
    if (isDev) console.log({ extractTaxSupportingDocumentsResponse });
    setIsSaving(false);
  };

  // Invokes Completions Job on Incomplete Completions (bad error handling)
  useEffect(() => {
    async function generateCompletions() {
      const extractTaxSupportingDocumentsResponse =
        await extractTaxSupportingDocuments();
      if (isDev) console.log({ extractTaxSupportingDocumentsResponse });
      return extractTaxSupportingDocumentsResponse;
    }

    const shouldRunCompletions =
      !engagementData?.isGeneratingDocumentsCompletions &&
      !isRunningCompletions &&
      selectedDocument?.analyzed !== true;

    if (shouldRunCompletions) {
      setIsRunningCompletions(true);
      generateCompletions()
        .then((response) => {
          // setIsRunningCompletions(false);
        })
        .catch((err) => {
          console.error({ generateCompletionsError: err });
        });
    }
  }, [
    engagementData?.engagementID,
    engagementData?.isGeneratingDocumentsCompletions,
    extractTaxSupportingDocuments,
    isRunningCompletions,
    selectedDocument?.analyzed,
    updateEngagementData,
  ]);

  const renderNoDocuments =
    selectedDocument === null || engagementData.documentation.length === 0;

  const renderEditDocumentForm =
    selectedDocument?.analyzed === true ||
    isDownloadingFile ||
    projectData === undefined;

  const flatlistItems = engagementData.documentation.documents.map((d) => {
    return {
      ...d,
      name: d.key.split("/").pop(),
    };
  });

  return (
    <div className="px-8 pb-8 z-40">
      <div className="flex flex-row w-full items-start justify-between h-76vh">
        <FlatlistSelectionPane
          searchTitle={"Documents"}
          items={flatlistItems}
          setSelectedCallback={(item) => setSelectedDocument(item)}
          selectedItem={selectedDocument?.key}
          onClickAddCallback={() => performClick("file-dialog-mass-import")}
          nameKey={"name"}
          idKey={"key"}
        />
        <div className="flex w-3/4 items-start justify-center h-76vh">
          {renderNoDocuments ? (
            <NoItemsFound type={"Documents"} />
          ) : renderEditDocumentForm ? (
            <EditDocumentForm
              loading={isUpdatingEngagementData || isSaving}
              updateSelectedItem={updateSelectedItem}
              document={selectedDocument}
              projects={projectData}
              selectOptions={buildProjectSelectOptions(projectData)}
            />
          ) : (
            <AiLoadingAnimation />
          )}
          <input
            ref={inputFileRef}
            hidden={true}
            id="file-dialog-mass-import"
            multiple
            type="file"
            style={{ display: "none" }}
            accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                      text/plain,.msg,.json,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.HEIC"
            onChange={(e) => onDocumentUpload(e)}
          />
        </div>
      </div>
    </div>
  );
};
