import React, { useState, useMemo } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";

import useResponsiveFontSize from "./useResponsiveFontSize";
import { Button } from ".";

const useOptions = () => {
  const fontSize = useResponsiveFontSize();
  const options = useMemo(
    () => ({
      style: {
        base: {
          fontSize,
          color: "#424770",
          letterSpacing: "0.025em",
          fontFamily: "Source Code Pro, monospace",
          "::placeholder": {
            color: "#aab7c4",
          },
        },
        invalid: {
          color: "#9e2146",
        },
      },
    }),
    [fontSize]
  );

  return options;
};

const CreditCardForm = (props) => {
  const stripe = useStripe();
  const elements = useElements();
  const options = useOptions();

  const [error, setError] = useState(null);

  const [loading, setLoading] = useState(false);
  const [cardComplete, setCardComplete] = useState(false);

  const handleSubmit = async (event) => {
    setLoading(true);

    event.preventDefault();

    if (!stripe || !elements) {
      return;
    }

    if (error) {
      elements.getElement("card").focus();
      return;
    }

    const payload = await stripe.createPaymentMethod({
      type: "card",
      card: elements.getElement(CardElement),
    });

    console.log("[PaymentMethod]", payload);

    if (payload.error) {
      setError(payload.error);
      setLoading(false);
    } else {
      setLoading(false);

      props.onSubmit(payload.paymentMethod.id);
    }
  };

  return (
    <form
      className="flex flex-col w-full justify-center self-center items-center mb-16"
      onSubmit={handleSubmit}
    >
      <CardElement
        options={options}
        className={
          "bg-white  elevation-1 rounded-md border border-gray-300 w-10/12 p-2"
        }
        onReady={() => {
          // console.log("CardElement [ready]");
        }}
        onChange={(event) => {
          // console.log("CardElement [change]", event);
          if (event.complete === true) {
            setCardComplete(true);
          }
        }}
        onBlur={() => {
          // console.log("CardElement [blur]");
        }}
        onFocus={() => {
          // console.log("CardElement [focus]");
        }}
      />
      {error && (
        <div className="flex felx-row  w-10/12 mt-4 items-center justify-center text-red-600">
          {error.message}
        </div>
      )}

      {cardComplete ? (
        <Button
          onClick={() => handleSubmit}
          disabled={loading === false ? false : true}
          primary={true}
          secondary={false}
          buttonClassName={"w-64 mt-4"}
          textClassName=""
        >
          <p>Submit</p>
        </Button>
      ) : null}
    </form>
  );
};

export default CreditCardForm;
