export const WelcomeMessage = ({ name }: { name: string }) => {
  return (
    <div className="flex w-full py-4">
      <div className="flex flex-col w-8/12 rounded-2xl p-4 bg-sprxClientPortalLightTeal">
        <h1 className="text-xl font-semibold text-gray-900">Welcome {name}!</h1>
        <p className="text-sm mt-2 pr-40 text-gray-900">
          View your assigned 179D studies. Open each study to view the site
          visit, download documents and energy models, chat with the SPRX team,
          and verify accuracy.
        </p>
      </div>
      <div className="flex -ml-64">
        <img
          style={{ width: 410.25, height: 136.125 }}
          src={"assets/images/Dashboard_Illustration.svg"}
          alt="SPRX.tax"
        />
      </div>
    </div>
  );
};
