import EnergyCommentsProvider from "../rtk/Energy/Comments/EnergyCommentsProvider";
import EnergyEngagementProvider from "../rtk/Energy/Engagement/EnergyEngagementProvider";

export const EnergyProvider = ({ children }) => {
  return (
    <EnergyEngagementProvider>
      <EnergyCommentsProvider>{children}</EnergyCommentsProvider>
    </EnergyEngagementProvider>
  );
};
