import React from "react";
import { MetaData, StatusItem } from "../components";
import { CostsList } from "../components/CostsList";
import { UsersList } from "../components/UsersList";
import { Pill } from "../components";

export const ExpandedTrackerCard = ({
  engagement,
  credit,
  assignedUsers,
  isLoadingUsers,
}: {
  engagement: any;
  credit?: any;
  assignedUsers: any;
  isLoadingUsers: boolean;
}) => {
  return (
    <div className="mx-4">
      <MetaData engagement={engagement} />
      {/* Divider */}
      <div className="my-4 h-1 border-b"></div>
      {/* Cost Totals */}
      {/* Cost Totals */}
      {engagement.status !== "Upload" ? (
        <>
          <CostsList engagement={engagement} type="credits" />
          <div className="my-4 h-1 border-b"></div>
          <CostsList engagement={engagement} type="QRES" />
          <div className="my-4 h-1 border-b"></div>
        </>
      ) : null}
      <CostsList engagement={engagement} type="costs" />
      <div className="my-4 h-1 border-b"></div>
      <UsersList users={assignedUsers} isLoadingUsers={isLoadingUsers} />
      {engagement.status !== "Completed" && engagement.status !== "Hidden" ? (
        <>
          <div className="my-4 h-1 border-b"></div>
          <div>
            <div className="text-lg font-bold">
              {engagement.status === "Upload" ? "Uploads" : "Approvals"}
            </div>
            {engagement.tags.map((tag: any, index: number) => (
              <StatusItem
                key={index}
                title={tag.name}
                // status={tag.color === "green" ? true : false}
                status={tag.color}
                uploadDate={tag?.lastUpdated ?? undefined}
                uploadedBy={tag?.uploadedBy ?? undefined}
              />
            ))}
          </div>
        </>
      ) : null}
    </div>
  );
};
