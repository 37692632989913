import * as yup from "yup";

export const getCreateEngagementFormShape = () => {
  return yup.object().shape({
    name: yup.string().required("Please enter an engagement name."),
    companyName: yup.object().shape({
      value: yup.string().required("Value is required"),
      label: yup.string().required("Label is required"),
    }),
    taxYearStart: yup.string().required("Please enter a tax year start date."),
    taxYearEnd: yup.string().required("Please enter a tax year end date."),
  });
};
