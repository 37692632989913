import { TabNavigator } from "../../ReviewPortal/Allocations/components";
import { EmployeeActivitiesContainer } from "./EmployeeActivities/EmployeeActivitiesContainer";
import { ProjectMemosContainer } from "./ProjectMemos/ProjectMemosContainer";
import { DocumentMemosContainer } from "./DocumentMemos/DocumentMemosContainer";
import { ExpenseSchedulesContainer } from "./ExpenseSchedules/ExpenseSchedulesContainer";
import { ExecutiveMemoContainer } from "./ExecutiveMemo/ExecutiveMemoContainer";
import { Form6767Container } from "./Form6765/Form6765Container";
import { StateCreditsContainer } from "./StateCredits/StateCreditsContainer";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";
import * as React from "react";

export const Documents = () => {
  const commentsAPI = useComments();
  const [currentTab, setCurrentTab] = React.useState({
    label: "Executive Memo",
    value: "Executive Memo",
  });

  const SelectedComponent = {
    "Executive Memo": <ExecutiveMemoContainer />,
    "Form 6765": <Form6767Container />,
    "State Forms": <StateCreditsContainer />,
    "Employee Activities": <EmployeeActivitiesContainer />,
    "Project Memos": <ProjectMemosContainer />,
    "Document Index": <DocumentMemosContainer />,
    "Expense Schedules": <ExpenseSchedulesContainer />,
  }[currentTab.value];

  React.useEffect(() => {
    commentsAPI.setCommentTitle(currentTab.label);
  }, [commentsAPI, currentTab]);

  return (
    <div>
      <TabNavigator
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          setCurrentTab(tab);
        }}
        tabs={[
          { label: "Executive Memo", value: "Executive Memo" },
          { label: "Form 6765", value: "Form 6765" },
          { label: "State Forms", value: "State Forms" },
          { label: "Employee Activities", value: "Employee Activities" },
          { label: "Project Memos", value: "Project Memos" },
          { label: "Document Index", value: "Document Index" },
          { label: "Expense Schedules", value: "Expense Schedules" },
        ]}
      />
      {SelectedComponent}
    </div>
  );
};
