import getDocumentProperties from "office-document-properties";

export const parseUploadedDocuments = (files, engagementData) => {
  const engagementID = engagementData.engagementID;
  const bucketName = engagementData.s3Bucket.name;
  const currentYear = engagementData?.taxYearEnd?.split("-", 1) ?? [
    new Date().getFullYear(),
  ];

  var uploadParameters = [];
  var updateDocs = [];
  console.log({ files });
  Object.keys(files)
    .filter((k) => k !== "length")
    .forEach((k) => {
      const file = files[k];

      /**
          user: userName,
          folder: type.split(" ")[0],
         */
      uploadParameters.push({
        fileName: file.name,
        bucket: bucketName,
        key: `${engagementID}/documentation/raw/${file.name}`,
        action: "putObject",
        file: file,
        taxYear: currentYear[0],
        engagementID,
        type: file.type,
        uploadType: "documentation",
        tags: "documentation",
      });

      var isAudio = file.type.substring(0, file.type.indexOf("/")) === "audio";

      var isVideo = file.type.substring(0, file.type.indexOf("/")) === "video";

      var isMSG = file.name.substring(file.name.length - 4) === ".msg";

      if (file.type === "application/pdf") {
        const reader = new FileReader();
        const fileInfo = file;
        if (fileInfo) {
          reader.readAsBinaryString(file);
          reader.onloadend = () => {
            updateDocs.push({
              key: `${engagementID}/documentation/raw/${file.name}`,
              analyzed: false,
              extracted: false,
              processing: false,
              type: "N/A",
              score: 0,
              scores: {
                pp: 0,
                tu: 0,
                tn: 0,
                poe: 0,
              },
              project: "N/A",
              employees: [],
              test: "N/A",
              sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
              reportLink: "N/A",
              sourceText: "N/A",
              description: "N/A",
              numberOfPages: reader.result.match(/\/Type[\s]*\/Page[^s]/g)
                .length,
              lengthInSeconds: 0,
              fileType: file.type,
            });
          };
        }
      } else if (isAudio) {
        var audio = document.createElement("audio");
        audio.preload = "metadata";

        audio.onloadedmetadata = function () {
          window.URL.revokeObjectURL(audio.src);

          updateDocs.push({
            key: `${engagementID}/documentation/raw/${file.name}`,
            analyzed: false,
            extracted: false,
            processing: false,
            type: "N/A",
            score: 0,
            scores: {
              pp: 0,
              tu: 0,
              tn: 0,
              poe: 0,
            },
            project: "N/A",
            employees: [],
            test: "N/A",
            sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
            reportLink: "N/A",
            sourceText: "N/A",
            description: "N/A",
            numberOfPages: 0,
            lengthInSeconds: audio.duration,
            fileType: file.type,
          });
        };

        audio.src = URL.createObjectURL(file);
      } else if (isVideo) {
        var video = document.createElement("video");
        video.preload = "metadata";

        video.onloadedmetadata = function () {
          window.URL.revokeObjectURL(video.src);

          updateDocs.push({
            key: `${engagementID}/documentation/raw/${file.name}`,
            analyzed: false,
            extracted: false,
            processing: false,
            type: "N/A",
            score: 0,
            scores: {
              pp: 0,
              tu: 0,
              tn: 0,
              poe: 0,
            },
            project: "N/A",
            employees: [],
            test: "N/A",
            sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
            reportLink: "N/A",
            sourceText: "N/A",
            description: "N/A",
            numberOfPages: 0,
            lengthInSeconds: video.duration,
            fileType: file.type,
          });
        };

        video.src = URL.createObjectURL(file);
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
      ) {
        const reader = new FileReader();
        const fileInfo = file;
        if (fileInfo) {
          reader.readAsArrayBuffer(file);
          reader.onloadend = () => {
            var filebuffer = Buffer.from(reader.result);
            getDocumentProperties.fromBuffer(filebuffer, function (err, data) {
              if (err) {
                console.log(err);
                throw err;
              }

              updateDocs.push({
                key: `${engagementID}/documentation/raw/${file.name}`,
                analyzed: false,
                extracted: false,
                processing: false,
                type: "N/A",
                score: 0,
                scores: {
                  pp: 0,
                  tu: 0,
                  tn: 0,
                  poe: 0,
                },
                project: "N/A",
                employees: [],
                test: "N/A",
                sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
                reportLink: "N/A",
                sourceText: "N/A",
                description: "N/A",
                numberOfPages: data.pages,
                lengthInSeconds: 0,
                fileType: file.type,
              });
            });
          };
        }
      } else if (
        file.type ===
        "application/vnd.openxmlformats-officedocument.presentationml.presentation"
      ) {
        const reader = new FileReader();
        const fileInfo = file;
        if (fileInfo) {
          reader.readAsArrayBuffer(file);
          reader.onloadend = () => {
            var filebuffer = Buffer.from(reader.result);

            getDocumentProperties.fromBuffer(filebuffer, function (err, data) {
              if (err) {
                console.log(err);
                throw err;
              }

              updateDocs.push({
                key: `${engagementID}/documentation/raw/${file.name}`,
                analyzed: false,
                extracted: false,
                processing: false,
                type: "N/A",
                score: 0,
                scores: {
                  pp: 0,
                  tu: 0,
                  tn: 0,
                  poe: 0,
                },
                project: "N/A",
                employees: [],
                test: "N/A",
                sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
                reportLink: "N/A",
                sourceText: "N/A",
                description: "N/A",
                numberOfPages: data.slides,
                lengthInSeconds: 0,
                fileType: file.type,
              });
            });
          };
        }
      } else if (
        file.type ===
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" ||
        file.type === "application/vnd.ms-excel.sheet.macroenabled.12"
      ) {
        const reader = new FileReader();
        const fileInfo = file;
        if (fileInfo) {
          reader.readAsArrayBuffer(file);
          reader.onloadend = () => {
            var filebuffer = Buffer.from(reader.result);

            getDocumentProperties.fromBuffer(filebuffer, function (err, data) {
              if (err) {
                console.log(err);
                throw err;
              }

              updateDocs.push({
                key: `${engagementID}/documentation/raw/${file.name}`,
                analyzed: false,
                extracted: false,
                processing: false,
                type: "N/A",
                score: 0,
                scores: {
                  pp: 0,
                  tu: 0,
                  tn: 0,
                  poe: 0,
                },
                project: "N/A",
                employees: [],
                test: "N/A",
                sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
                reportLink: "N/A",
                sourceText: "N/A",
                description: "N/A",
                numberOfPages: 1,
                lengthInSeconds: 0,
                fileType: file.type,
              });
            });
          };
        }
      } else if (isMSG) {
        const fileInfo = file;
        if (fileInfo) {
          updateDocs.push({
            key: `${engagementID}/documentation/raw/${file.name}`,
            analyzed: false,
            extracted: false,
            processing: false,
            type: "N/A",
            score: 0,
            scores: {
              pp: 0,
              tu: 0,
              tn: 0,
              poe: 0,
            },
            project: "N/A",
            employees: [],
            test: "N/A",
            sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
            reportLink: "N/A",
            sourceText: "N/A",
            description: "N/A",
            numberOfPages: 1,
            lengthInSeconds: 0,
            fileType: "application/vnd.ms-outlook",
          });
        }
      } else {
        updateDocs.push({
          key: `${engagementID}/documentation/raw/${file.name}`,
          analyzed: false,
          extracted: false,
          processing: false,
          type: "N/A",
          score: 0,
          scores: {
            pp: 0,
            tu: 0,
            tn: 0,
            poe: 0,
          },
          project: "N/A",
          employees: [],
          test: "N/A",
          sourceLink: `https://${bucketName}.s3.amazonaws.com/documentation/raw/${file.name}`,
          reportLink: "N/A",
          sourceText: "N/A",
          description: "N/A",
          numberOfPages: 1,
          lengthInSeconds: 0,
          fileType: file.type,
        });
      }
    });
  return { uploadParameters, updateDocs };
};
