import { classNames } from "../../utils";

export const LoadingBubble = () => {
  return (
    <div className={classNames("py-5 text-white", "bg-gray-700")}>
      <div className="ms-auto flex max-w-2xl space-x-5 px-10">
        <img
          className="h-6 w-auto"
          src="assets/images/logos/logo.png"
          alt="SPRX.tax"
        />
        <div className="flex items-center space-x-2">
          <div className="h-2 w-2 animate-pulse rounded-full bg-gradient-to-r from-gray-400 to-gray-600"></div>
          <div className="h-2 w-2 animate-pulse rounded-full bg-gradient-to-r from-gray-400 to-gray-600 delay-75"></div>
          <div className="h-2 w-2 animate-pulse rounded-full bg-gradient-to-r from-gray-400 to-gray-600 delay-150"></div>
        </div>
      </div>
    </div>
  );
};
