import { motion } from "framer-motion";

interface ListHeaderProps {
  setFilter: (str: string) => void;
  setOpen: (bool: boolean) => void;
  filter: string;
}

export const ListHeader = ({ setFilter, setOpen, filter }: ListHeaderProps) => {
  return (
    <div className="flex flex-row items-between justify-between items-end">
      <div className="mt-4 sm:mt-0 flex flex-col">
        <p className={"text-sm mb-2 font-bold"}>Search Engagement</p>
        <motion.input
          type="text"
          className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
          onChange={(e) => {
            setFilter(e.target.value);
          }}
          value={filter}
        />
      </div>
      <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
        <motion.button
          type="button"
          className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => setOpen(true)}
        >
          Create Engagement
        </motion.button>
      </div>
    </div>
  );
};
