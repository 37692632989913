export const getCostKey = (type) => {
  switch (type) {
    case "Wages":
      return "Employee W2 Wages";
    case "Supply":
      return "Supply Cost";
    case "Contract Research":
      return "Contractor Cost";
    case "Contract":
      return "Contractor Cost";
    case "Computer Lease":
      return "Vendor Cost";
    case "Computer":
      return "Vendor Cost";
    case "Projects":
      return "Project Cost";
    default:
      return "";
  }
};
