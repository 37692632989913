import React, { useRef } from "react";
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "../../../../ui-components";
import { classNames } from "../../utils";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";

// interface AddCommentFormProps {
//   open: boolean;
//   setOpen: (open: boolean) => void;
// }

export const AddCommentForm = ({ open, setOpen }) => {
  const commentsAPI = useComments();
  const textAreaRef = useRef(null);
  const [message, setMessage] = React.useState("");

  const onClickSend = async () => {
    // Get Current Engagement Data
    await commentsAPI.onCreateComment(message);
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-50"
          static
          layoutId={"add-comment"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <Dialog.Panel>
            <motion.div
              id="parent-add-comment"
              className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"
            />
            <motion.div className="fixed inset-0 overflow-y-auto">
              <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*Modal content */}
                <motion.div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-32vh min-w-30vw">
                  <motion.div className="">
                    {/*Modal title, description, and close button */}
                    <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <motion.button
                        type="button"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 "
                        onClick={() => setOpen(false)}
                      >
                        <XIcon className="h-6 w-6" aria-hidden={false} />
                      </motion.button>
                    </motion.div>
                    <h1
                      id="add-comment-header"
                      className="add-comment-header mt-6 w-full text-3xl text-center font-bold"
                    >
                      Add a Comment
                    </h1>
                    <motion.div className="w-full mt-1">
                      <motion.textarea
                        ref={textAreaRef}
                        id="message"
                        name="message"
                        placeholder={"Enter your comment here..."}
                        className="message shadow-sm h-40  block w-full sm:text-sm border-gray-300 rounded-md"
                        whileHover={{ scale: 1.01 }}
                        onChange={(e) => setMessage(e.target.value)}
                      />
                    </motion.div>
                    <div id="add-comment-placeholder"></div>
                    <div className="flex w-full justify-center items-center">
                      <Button
                        secondary={true}
                        buttonClassName={classNames(
                          "mt-5 sm:mt-6 inline-flex justify-end w-1/5 rounded-md border border-transparent shadow-sm px-4 py-2 bg-sprxTaxBlue text-base font-medium text-white hover:bg-sprxTaxDarkBlue focus:outline-none sm:text-sm",
                          message.length === 0 &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed",
                          message.length === 0 &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed"
                        )}
                        disabled={message.length === 0}
                        onClick={() => onClickSend()}
                      >
                        {commentsAPI.isCreatingComment ? (
                          <div className="w-2/3">
                            <p className="animate-pulse bg-white rounded h-2 mt-2 w-12"></p>
                          </div>
                        ) : (
                          "Add"
                        )}
                      </Button>
                    </div>

                    {false && (
                      <p className="mt-2 text-sm text-red-600">
                        {false ??
                          "Encountered an error, please try again later."}
                      </p>
                    )}
                  </motion.div>
                </motion.div>
              </motion.div>
            </motion.div>
          </Dialog.Panel>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
