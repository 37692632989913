import { useState, useRef, useEffect } from "react";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useChat } from "../../../../rtk/Chat/useChat";
import { Chat } from "./Chat";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";

export const ChatContainer = ({
  knowledgeBaseID,
  engagementID,
}: {
  knowledgeBaseID: string;
  engagementID: string;
}) => {
  const [initialLoad, setInitialLoad] = useState<boolean>(true);
  const [messages, setMessages] = useState<any>([]);
  const mounted = useRef<boolean>(false);

  const chatAPI = useChat();
  const commentsAPI = useComments();

  // Initialize Chat Session on Mount
  useEffect(() => {
    if (chatAPI && chatAPI.chatID === null && !chatAPI.isCreatingChat) {
      chatAPI.setEngagementID(engagementID);
      chatAPI.onCreateChat(knowledgeBaseID);
      commentsAPI.setCommentTitle("AI Chat");
      setTimeout(() => {
        setInitialLoad(false);
        // Prevent Duplicate Welcome Message
        if (messages.length === 0 && !mounted.current) {
          mounted.current = true;
          setMessages((messages: any) => [
            ...messages,
            {
              message:
                "Welcome to SPRX.tax! I'm Bolt, your AI powered tax assistant. How can I help you?",
              sender: "bot",
            },
          ]);
        }
      }, 2000);
    }
    // Clean Up Chat Session on Unmount
    return () => {
      if (chatAPI) chatAPI.onDeleteChat();
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Handle Chat Error
  useEffect(() => {
    const errorMessage =
      "I'm sorry, I'm currently experiencing technical difficulties. Please try again later.";
    const alreadyHasErrorMessage = messages.some(
      (message: any) =>
        message.sender === "bot" && message.message === errorMessage
    );
    if (
      chatAPI &&
      !alreadyHasErrorMessage &&
      (chatAPI.createChatError ||
        chatAPI.queryChatError ||
        chatAPI.deleteChatError)
    ) {
      setMessages((messages: any) => [
        ...messages,
        {
          message: errorMessage,
          sender: "bot",
        },
      ]);
    }
  }, [chatAPI, messages]);

  if (chatAPI === null) return <div> loading ...</div>;

  // Send message on enter / click
  const onSendQuery = async (message: string) => {
    // Set user message
    const formattedUserMessage = {
      message: message,
      sender: "user",
    };
    setMessages((messages: any) => [...messages, formattedUserMessage]);

    // Query chat
    const queryResponse = await chatAPI.onQueryChat(message);

    if (chatAPI.queryChatError) return;

    // Set bot message
    const formattedBotMessage = {
      message: queryResponse.data.chatText,
      documents: queryResponse.data.relatedDocuments,
      sender: "bot",
    };
    setMessages((messages: any) => [...messages, formattedBotMessage]);
  };

  return (
    <Chat
      onSendQuery={onSendQuery}
      messages={messages}
      isThinking={chatAPI.isQueryingChat || initialLoad}
    />
  );
};
