import * as React from "react";
import { SerializedError } from "@reduxjs/toolkit";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query/react";
import { Engagement } from "../../../main/Energy/types/engagement";

interface MutationState<T> {
  data?: T;
  error?: FetchBaseQueryError | SerializedError;
  isLoading: boolean;
  isUninitialized: boolean;
  isSuccess: boolean;
  isError: boolean;
}

interface UpdateEngagementParams {
  engagementID: string;
  body: any;
}

interface generateDeliverablesParams {
  engagementID: string;
  body: any;
}

interface EnergyEngagementContextType {
  // certificationEngagements: any;
  // certificationEngagementsError?: FetchBaseQueryError | SerializedError;
  // certificationEngagementsLoading: boolean;
  // certificationEngagementsFetching: boolean;
  // modelingEngagements: any;
  // modelingEngagementsError?: FetchBaseQueryError | SerializedError;
  // modelingEngagementsLoading: boolean;
  // modelingEngagementsFetching: boolean;
  createEngagement: any;
  createEngagementData: MutationState<Engagement>;
  updateEngagement: ({
    engagementID,
    body,
  }: UpdateEngagementParams) => Promise<
    { data: any } | { error: SerializedError | { status: any; data: any } }
  >;
  markModelStatusAsReady: any;
  updateEngagementData: MutationState<Engagement>;
  // refetchModelingEngagements: any;
  getEngagement: any;
  getEngagementData?: Engagement;
  getEngagementError?: FetchBaseQueryError | SerializedError;
  allEngagements: Engagement[] | undefined;
  allEngagementsError?: FetchBaseQueryError | SerializedError;
  allEngagementsLoading: boolean;
  engagementsFromCompanyID: any;
  engagementsFromCompanyIDData: Engagement[] | undefined;
  engagementsFromCompanyIDError?: FetchBaseQueryError | SerializedError;
  engagementsFromCompanyIDLoading: boolean;
  engagement: Engagement | undefined;
  engagementError?: FetchBaseQueryError | SerializedError;
  engagementLoading: boolean;
  compute179D: any;
  compute179DData: any;
  digestDocuments: any;
  digestDocumentsData: MutationState<any>;
  dispatchCall: any;
  isDispatching: boolean;
  dispatchingError: any;
  sendAllocationLetter: any;
  isSendingAllocationLetter: boolean;
  sendingAllocationLetterError: any;
  generateEnergyDeliverables: ({
    engagementID,
    body,
  }: generateDeliverablesParams) => Promise<
    { data: any } | { error: SerializedError | { status: any; data: any } }
  >;
  generateEnergyDeliverablesData: MutationState<any>;
  engagementFetching: boolean;
}
const EnergyEngagementContext =
  React.createContext<EnergyEngagementContextType | null>(null);

export default EnergyEngagementContext;
