export const getStatusUpdateSlug = (type) => {
  switch (type) {
    case "Wages":
      return "wages";
    case "WAGES":
      return "wages";
    case "Supply":
      return "accounting";
    case "SUPPLY":
      return "accounting";
    case "Contract Research":
      return "accounting";
    case "CONTRACT":
      return "accounting";
    case "Computer Lease":
      return "accounting";
    case "COMPUTER":
      return "accounting";
    case "Projects":
      return "projects";
    case "PROJECTS":
      return "projects";
    default:
      return "";
  }
};
