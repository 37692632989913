import * as React from "react";
import { useFile } from "../../../../../rtk/R&D/File/useFile";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { VirtualizedList } from "../../../../R&D/DeliverablesPortal/Documents/components/VirtualizedList";

export const DocumentMemosContainer = () => {
  const energyEngagementAPI = useEnergyEngagement();
  const fileAPI = useFile();
  const engagement = energyEngagementAPI?.engagement;
  const onClickDownload = async (items: string[]) => {
    if (items.length === 0) {
      alert("Please select at least one document.");
      return;
    }
    // Generate link
    const isDraft = engagement.reports.isDraft;
    const link = await fileAPI.generatePartialEnergyReport({
      engagementID: engagement.engagementID,
      body: {
        reportType: "DOCUMENTS",
        IDs: items,
        isDraft: isDraft,
      },
    });

    // Handler Error
    if (fileAPI?.errors?.generatePartialReportError) {
      console.error(fileAPI.errors.generatePartialReportError);
      return;
    }
    // Open link
    window.open(link.data, "_blank");
  };

  const apisAreLoading = !energyEngagementAPI || !fileAPI;
  if (apisAreLoading) return <div> Loading ... </div>;

  const items = energyEngagementAPI.engagement.documents.map(
    (document: any) => {
      const summary = document?.summary ?? "Invalid file type";
      const shortSummary = summary.slice(0, 100);
      return {
        ...document,
        "Document Title": document.name,
        ID: document.name,
        "Document Summary": shortSummary,
      };
    }
  );

  return (
    <VirtualizedList
      items={items}
      onClickDownload={onClickDownload}
      keys={{
        "Document Title": "Document Title",
        "Document Summary": "Document Summary",
      }}
      isLoading={fileAPI.isGeneratingPartialEnergyReport}
    />
  );
};
