import {
  ViewGridIcon,
  UserIcon,
  UserGroupIcon,
  QuestionMarkCircleIcon,
  ViewListIcon,
  GlobeIcon,
  DocumentTextIcon,
  ClockIcon,
  BeakerIcon,
  LightningBoltIcon,
} from "@heroicons/react/outline";
import { INavigation } from "../SidebarLayout";

type EngagementType = "DS" | "RD" | "CAP" | "EN";
type UserRole =
  | "admin"
  | "sales"
  | "client"
  | "modeler"
  | "certifier"
  | "partner";

interface User {
  engagementType: EngagementType;
  role: UserRole;
  isUsingDataSpace?: boolean;
}

interface EngagementData {
  status?: {
    "portal-approved"?: { complete: boolean };
    "portal-submitted"?: { complete: boolean };
    "portal-deliverables"?: { complete: boolean };
  };
}

const DASH_TEXT: Record<EngagementType, string> = {
  DS: "Data Space",
  RD: "Review",
  CAP: "Review",
  EN: "Review",
};

const isStatusComplete = (data: EngagementData, status: string): boolean =>
  data?.status?.[status]?.complete === true;

export const buildNavigationArray = (user: User): INavigation[] => {
  const navigation: INavigation[] = [];

  const isReportsUser =
    DASH_TEXT[user.engagementType] === "Review" && user.role === "client";

  const isModelingUser = user.role === "modeler";
  const isCertificationUser = user.role === "certifier";
  const isSalesUser = user.role === "sales";
  const isAdmin = user.role === "admin";
  const isClient = user.role === "client";

  if (isSalesUser || isAdmin) {
    navigation.push({
      name: "Tracker",
      icon: ClockIcon,
      path: "tracker",
    });
  }

  if (isModelingUser || isCertificationUser) {
    navigation.push({
      name: "Assignments",
      icon: ClockIcon,
      path: "assignments",
    });
  }

  if (isAdmin || isClient) {
    navigation.push({
      name: "Data Spaces",
      icon: DocumentTextIcon,
      path: "dataspaces",
    });
  }

  if (isAdmin || isClient) {
    navigation.push({ name: "R & D", path: "/rd", icon: BeakerIcon });
    navigation.push({
      name: "Energy",
      path: "/energy",
      icon: LightningBoltIcon,
    });
  }

  // Add the standard navigation items.
  navigation.push(
    { name: "Team", icon: UserGroupIcon, path: "team" },
    { name: "Profile", icon: UserIcon, path: "profile" },
    { name: "Contact SPRX", icon: QuestionMarkCircleIcon, path: "contact" },
  );

  if (user.role === "admin") {
    navigation.push({
      name: "Admin",
      icon: ViewListIcon,
      path: "admin-home",
    });
  }

  return navigation;
};
