import { useState } from "react";
import { classNames } from "../../utils";
import { PlusIcon } from "@heroicons/react/solid";

const Divider = ({ keyProp }) => {
  return (
    <div key={keyProp} className="relative w-full">
      <div
        key={keyProp}
        className="inset-0 flex items-center justify-center py-1"
        aria-hidden="true"
      >
        <div key={keyProp} className="w-4/5 border-t border-gray-200" />
      </div>
    </div>
  );
};

const SearchBar = ({
  searchTitle,
  searchedItem,
  setSearchedItem,
  onClickAddCallback,
}) => {
  const renderWithButton = onClickAddCallback !== undefined;

  if (renderWithButton)
    return (
      <div className="my-2 mr-1 flex items-center">
        <div className="relative mr-2 flex-grow">
          <label
            htmlFor={searchTitle}
            className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
          >
            {searchTitle}
          </label>
          <input
            value={searchedItem}
            onChange={(e) => setSearchedItem(e.target.value)}
            type="text"
            name={searchTitle}
            id={searchTitle}
            className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
          />
        </div>
        <button
          onClick={onClickAddCallback}
          className="rounded-md bg-indigo-600 px-4 py-2 text-white hover:bg-indigo-700 focus:bg-indigo-800 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
        >
          <PlusIcon className="h-5 w-5" />
        </button>
      </div>
    );

  return (
    <div className="relative my-2">
      <label
        htmlFor={searchTitle}
        className="absolute -top-2 left-2 inline-block bg-white px-1 text-xs font-medium text-gray-900"
      >
        {searchTitle}
      </label>
      <input
        value={searchedItem}
        onChange={(e) => setSearchedItem(e.target.value)}
        type="text"
        name="projectName"
        id="projectName"
        className="block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6"
      />
    </div>
  );
};

export const FlatlistSelectionPane = (props) => {
  const {
    searchTitle,
    items,
    setSelectedItem,
    selectedItem,
    onClickAddCallback,
    nameKey,
    idKey,
    primaryItemsTitle,
    secondaryItems,
    secondaryItemsTitle,
  } = props;
  const [searchedItem, setSearchedItem] = useState("");
  const renderDivider = (i, els) => i !== 0 && i !== els.length;

  const selectCallback = (item) => {
    if (props?.setSelectedCallback) {
      props.setSelectedCallback(item);
    } else {
      setSelectedItem(item[idKey]);
    }
  };

  const width = props?.width ? props.width : "w-1/4";
  const nameIcon = props?.nameIcon ? props.nameIcon : null;

  const renderSecondaryItems =
    secondaryItems !== undefined &&
    secondaryItems.length > 0 &&
    secondaryItemsTitle !== undefined;

  const renderPrimaryItems =
    items !== undefined && items.length > 0 && primaryItemsTitle !== undefined;

  return (
    <div
      className={`flex flex-col ${width} h-full overflow-y-auto rounded-lg border p-8 shadow-xl`}
    >
      <nav className="h-full overflow-y-auto pr-4" aria-label="flatlist">
        <SearchBar
          searchTitle={searchTitle}
          searchedItem={searchedItem}
          setSearchedItem={setSearchedItem}
          onClickAddCallback={onClickAddCallback}
        />
        {renderPrimaryItems ? (
          <div className="flex items-center justify-between py-2">
            <div className="flex-grow border-t border-gray-300"></div>
            <span className="mx-4 bg-white px-2 text-sm text-gray-600">
              {primaryItemsTitle}
              {nameIcon}
            </span>
            <div className="flex-grow border-t border-gray-300"></div>
          </div>
        ) : null}
        <div className="overflow-auto">
          {items
            .filter((item) => item[nameKey]?.toString().includes(searchedItem))
            .map((item, index) => {
              return (
                <ul className="relative z-0" key={"ul-" + index}>
                  {renderDivider(index, items) ? (
                    <Divider keyProp={"project-divider-" + index} />
                  ) : null}
                  <li
                    key={item[idKey] + index}
                    className="bg-white"
                    onClick={() => selectCallback(item)}
                  >
                    <div
                      className={classNames(
                        "relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:rounded-lg hover:bg-gray-50",
                        item[idKey] === selectedItem
                          ? "rounded-lg bg-gray-200"
                          : "",
                      )}
                    >
                      <div className="min-w-0 flex-1">
                        <div className="focus:outline-none">
                          {/* Extend touch target to entire panel */}
                          <span
                            className="absolute inset-0"
                            aria-hidden="true"
                          />
                          <p className="text-sm font-medium">{item[nameKey]}</p>
                        </div>
                      </div>
                    </div>
                  </li>
                </ul>
              );
            })}
          {renderSecondaryItems ? (
            <>
              <div className="flex items-center justify-between py-2">
                <div className="flex-grow border-t border-gray-300"></div>
                <span className="mx-4 bg-white px-2 text-sm text-gray-600">
                  {secondaryItemsTitle}
                </span>
                <div className="flex-grow border-t border-gray-300"></div>
              </div>
              <>
                {secondaryItems
                  .filter((item) => item[nameKey].includes(searchedItem))
                  .map((item, index) => {
                    return (
                      <ul className="relative z-0" key={"ul-" + index}>
                        {renderDivider(index, secondaryItems) ? (
                          <Divider keyProp={"project-divider-" + index} />
                        ) : null}
                        <li
                          key={item[idKey] + index}
                          className="bg-white"
                          onClick={() => selectCallback(item)}
                        >
                          <div
                            className={classNames(
                              "relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:rounded-lg hover:bg-gray-50",
                              item[idKey] === selectedItem
                                ? "rounded-lg bg-gray-200"
                                : "",
                            )}
                          >
                            <div className="min-w-0 flex-1">
                              <div className="focus:outline-none">
                                {/* Extend touch target to entire panel */}
                                <span
                                  className="absolute inset-0"
                                  aria-hidden="true"
                                />
                                <p className="text-sm font-medium">
                                  {item[nameKey]}
                                </p>
                              </div>
                            </div>
                          </div>
                        </li>
                      </ul>
                    );
                  })}
              </>
            </>
          ) : null}
        </div>
      </nav>
    </div>
  );
};
