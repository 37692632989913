export const getDefaultEmployeeProjectFields = (e) => {
    const actOne = e.hasOwnProperty("activity-1") ? e["activity-1"] : "";
    const actTwo = e.hasOwnProperty("activity-2") ? e["activity-2"] : "";
    const actThree = e.hasOwnProperty("activity-3") ? e["activity-3"] : "";
    const discovery = e.hasOwnProperty("discovery") ? e["discovery"] : "";
    return {
        actOne,
        actTwo,
        actThree,
        discovery
    };
};