export const mapProjectToAllocationColumn = (project) => {
    const projectName = project["Project Name"];
    const ID = project.ID;
    return {
      headerName: projectName,
      field: ID,
      type: "numericColumn",
      editable: true,
      filter: "agMultiColumnFilter",
      filterParams: {
        buttons: ["reset", "clear"],
        closeOnApply: true,
      },
      valueGetter: (rowData) => {
        if (rowData.data[ID] === undefined) return 0;
        return rowData.data[ID];
      },
      valueParser: (rowData) => {
        if (isNaN(Number(rowData.newValue))) return 0;
        return Number(rowData.newValue);
      },
      valueFormatter: (rowData) => {
        if (rowData.data[ID] === undefined) return "0%";
        return `${rowData.data[ID]}%`;
      },
    };
};