export const FileListPaneLoading = (props) => {
  return (
    <div className="col-span-2 flex flex-col w-full">
      <div className="flex flex-col">
        <h3 className="xl:text-xl text-lg text-left font-semibold text-gray-900">
          Uploaded Files
        </h3>
          <ul className="animate-pulse  py-4">
            <li
              className="flex flex-row justify-between items-center mb-8"
              key={"loading_state_1"}
            >
              <div className="flex flex-row items-center w-3/4">
                <div className="rounded-full bg-sprxGrey h-10 w-11"></div>
                <h3 className="text-xl text-left ml-4 font-light w-full text-gray-900">
                    <div className="h-2 bg-sprxGrey rounded w-full"></div>
                </h3>
              </div>
              <div className="flex flex-row items-center w-1/4 justify-end space-x-4">
                <div className="rounded-full bg-sprxGrey h-10 w-10"></div>
                <div className="rounded-full bg-sprxGrey h-10 w-10"></div>
              </div>
            </li>
          </ul>
      </div>
    </div>
  );
};
