import { User } from "../../AdminHome/types/user";
export const getDefaultProfileFormFields = (user: User) => {
  const splitName = user.fullName.split(" ");
  const firstName = splitName[0];
  let lastName = splitName[1];

  // Account for Last Names with Spaces -- cannot handle first names with spaces
  if (splitName.length > 2) {
    lastName = splitName.slice(1).join(" ");
  }

  return {
    firstName: firstName,
    lastName: lastName,
    email: user.email,
  };
};
