const WAGE_ALLOCATIONS_BASE_COLUMNS = [
  {
    headerName: "Employee Name",
    field: "Employee Name",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Employee Job Title",
    field: "Employee Job Title",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Employee Department",
    field: "Employee Department",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },

  {
    headerName: "Qualified Percentage",
    field: "qPercentage",
    editable: false,
    hide: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Total Allocation",
    field: "sum",
    editable: false,
    hide: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Status",
    field: "status",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    cellRenderer: (params) => {
      const status = params.data.status;
      const bg = status === "Accurate" ? "bg-green-500" : "bg-red-500";
      return (
        <div
          className={
            "flex flex-row items-center justify-start rounded-lg p-2" + bg
          }
        >
          <div className={`w-2 h-2 rounded-full mr-2 ${bg}`}></div>
          {params.data.status}
        </div>
      );
    },
  },
];

const SUPPLY_ALLOCATIONS_BASE_COLUMNS = [
  {
    headerName: "Supply Account Name",
    field: "Supply Account Name",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Supply Account Description",
    field: "Supply Account Description",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Supply Account Department",
    field: "Supply Account Department",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
];

const COMPUTER_ALLOCATIONS_BASE_COLUMNS = [
  {
    headerName: "Vendor Account Name",
    field: "Vendor Account Name",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Vendor Account Description",
    field: "Vendor Account Description",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Vendor Account Department",
    field: "Vendor Account Department",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
];

const CONTRACT_ALLOCATIONS_BASE_COLUMNS = [
  {
    headerName: "Contractor Name",
    field: "Contractor Name",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Contractor Description",
    field: "Contractor Description",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Contractor Department",
    field: "Contractor Department",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
];

export const getAllocationColumns = (type) => {
  switch (type) {
    case "Wages":
      return WAGE_ALLOCATIONS_BASE_COLUMNS;
    case "WAGES_ALLOCATIONS":
      return WAGE_ALLOCATIONS_BASE_COLUMNS;

    case "Supply":
      return SUPPLY_ALLOCATIONS_BASE_COLUMNS;
    case "SUPPLY_ALLOCATIONS":
      return SUPPLY_ALLOCATIONS_BASE_COLUMNS;

    case "Contract Research":
      return CONTRACT_ALLOCATIONS_BASE_COLUMNS;
    case "CONTRACT_ALLOCATIONS":
      return CONTRACT_ALLOCATIONS_BASE_COLUMNS;

    case "Computer Lease":
      return COMPUTER_ALLOCATIONS_BASE_COLUMNS;
    case "COMPUTER_ALLOCATIONS":
      return COMPUTER_ALLOCATIONS_BASE_COLUMNS;
    default:
      return [];
  }
};
