import { baseAPI } from "../baseAPI";

interface CreateCommentInput {
  engagementID: string;
  companyID: string;
  createdBy: string;
  createdAt: string;
  title: string;
}

const energyCommentsAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createEnergyComment: builder.mutation({
      query: (body: CreateCommentInput) => ({
        url: `/energy/comments`,
        method: "POST",
        body: body,
      }),
    }),
    getEnergyComment: builder.query({
      query: (commentID: string) => ({
        url: `/energy/comments/${commentID}`,
        method: "GET",
        body: {},
      }),
      providesTags: (result, error, id) => [{ type: "Comment", id }],
    }),
    updateEnergyComment: builder.mutation({
      query: ({ commentID, body }) => ({
        url: `/energy/comments/${commentID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { commentID }) => [
        { type: "EnergyComment", commentID },
      ],
    }),
    deleteEnergyComment: builder.mutation({
      query: ({ commentID }) => ({
        url: `/energy/comments/${commentID}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: (result, error, { commentID }) => [
        { type: "EnergyComment", commentID },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEnergyCommentMutation,
  useGetEnergyCommentQuery,
  useUpdateEnergyCommentMutation,
  useLazyGetEnergyCommentQuery,
  useDeleteEnergyCommentMutation,
} = energyCommentsAPI;
