interface EngagementListStyleParentProps {
  children: JSX.Element[];
}

export const EngagementListStyleParent = ({
  children,
}: EngagementListStyleParentProps) => {
  return (
    <div className="flex-1">
      <div className="">
        <div className="px-4 sm:px-6 lg:px-8 mt-2">{children}</div>
      </div>
    </div>
  );
};
