export const translateListType = (type) => {
  switch (type) {
    case "projectList":
      return "Projects";
    case "crList":
      return "Contract Research";
    case "computerLeaseList":
      return "Computer Lease";
    case "wageList":
      return "Wages";
    case "supplyList":
      return "Supply";
    default:
      return undefined;
  }
};
