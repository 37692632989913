import { ArrowLeftIcon } from "@heroicons/react/outline";
import { motion } from "framer-motion";

export const EngagementListEmpty = ({ closeEngagement, companyName, type }) => {
  return (
    <div className={`flex-1 ${closeEngagement ? "" : "pt-6"}`}>
      <div className="">
        {/* Admin engagement table */}
        <div className="px-4 sm:px-6 lg:px-8">
          <div className="flex flex-row items-center justify-between">
            <div className="flex flex-row items-center justify-between">
              {closeEngagement ? (
                <button
                  onClick={() => closeEngagement()}
                  className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
                >
                  <ArrowLeftIcon className="h-8 w-8" />
                </button>
              ) : null}
              <h1
                className={`${closeEngagement ? "ml-2" : ""} text-2xl font-semibold text-gray-900`}
              >
                {companyName} {type}s
              </h1>
            </div>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="items-between mt-2 flex flex-row items-end justify-start">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search {type}</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={() => {}}
              />
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          {type} Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <span className="sr-only">Open</span>
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Favorite</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      <tr>
                        <td colSpan={5} className="py-3.5 text-center">
                          No {type}s exist. If that seems incorrect, please
                          contact an SPRX team member.
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
