import { useRef, useEffect, useState } from "react";

import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useUsers } from "../../../../rtk/Users/useUsers";
import SelectorHeader from "../../../../ui-components/SelectorHeader";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../auth/rtk/userSlice";

export const HeaderContainer = () => {
  const fetchedRef = useRef(false);
  const [engagementList, setEngagementList] = useState<any>([]);
  const [isLoading, setIsLoading] = useState(false);
  const engagementDataAPI = useEngagementData();
  const usersAPI = useUsers();
  const dispatch = useDispatch();

  const loading =
    !engagementDataAPI ||
    !usersAPI ||
    engagementDataAPI.isLoadingEngagementData ||
    usersAPI.isUpdatingUser ||
    isLoading;

  useEffect(() => {
    async function fetchData() {
      const engagements =
        await engagementDataAPI.onListEngagementsFromCompanyID();
      setEngagementList(engagements);
      await engagementDataAPI.engagementData;
    }
    if (!fetchedRef.current && engagementDataAPI) {
      setIsLoading(true);
      fetchedRef.current = true;
      fetchData();
      setIsLoading(false);
    }
  }, [engagementDataAPI, loading]);

  if (loading) {
    return (
      <div className="mt-4 flex w-full flex-row items-center justify-between px-4 pt-2">
        <div className="ml-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
      </div>
    );
  }

  const onEngagementSelect = async (id: string) => {
    setIsLoading(true);
    const body = {
      activeEngagementID: id,
      isUsingDataSpace: false,
      engagementType: "RD",
    };
    await usersAPI.updateTaxUser({
      userID: usersAPI.user.userID,
      body: body,
    });
    await usersAPI.getTaxUser(usersAPI.user.userID).then((res: any) => {
      dispatch(setUserData(res.data));
      fetchedRef.current = false;
    });
    setIsLoading(false);
  };

  if (engagementList.length === 1) {
    return (
      <SelectorHeader
        currentItem={engagementDataAPI.engagementData.engagementName}
        items={[]}
        onSelectItem={(id: string) => onEngagementSelect(id)}
        type="report"
      />
    );
  }

  const nonActiveEngaggements = engagementList.filter((engagement: any) => {
    return engagement.id !== engagementDataAPI.engagementData.engagementID;
  });
  return (
    <SelectorHeader
      currentItem={engagementDataAPI.engagementData.engagementName}
      items={nonActiveEngaggements}
      onSelectItem={(id: string) => onEngagementSelect(id)}
      type="report"
    />
  );
};
