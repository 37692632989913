import { useRef, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { motion } from "framer-motion";
import { useDropzone } from "react-dropzone";
import { PlusIcon } from "@heroicons/react/solid";

import { isDev, performClick } from "../R&D/utils";
import { useDataSpaces } from "../../rtk/DataSpaces/useDataSpaces";
import { useFile } from "../../rtk/R&D/File/useFile";
import { useUsers } from "../../rtk/Users/useUsers";
import { DataSpaceListLoading } from "./components";
import { getDsUploadParameters, getRequiredItems } from "./utils";
import { CreateDataSpaceUserForm } from "./CreateDataSpaceUserForm";
import { RequiredDocumentItem } from "./components/RequiredDocumentItem";
import { DataSpaceHeaderContainer } from "./DataSpaceHeaderContainer";
import { ArrowLeftIcon } from "@heroicons/react/outline";

export const DataSpace = ({ setViewDataSpaceDetail }) => {
  const inputFileRef = useRef();
  const [downloadIndex, setDownloadIndex] = useState(null);
  const [open, setOpen] = useState(false);

  const { dataSpace, isGettingDataSpace, sendDataSpaceFileUpdate } =
    useDataSpaces();

  const { user } = useUsers();

  const {
    documentList,
    listDocumentsLibrary,
    isListingDocuments,
    uploadFile,
    isUploadingFile,
    uploadDirectoryFile,
    isUploadingDirectoryFile,
    downloadDocument,
    isDownloadingDocument,
    deleteFile,
  } = useFile();

  const uploadedFiles = [...(documentList?.contents || [])]?.sort((a, b) => {
    return new Date(b?.uploaded) - new Date(a?.uploaded);
  });

  const isDeliverablesClientUser = (u) =>
    u?.engagementType === "RD" && u.role === "client";

  useEffect(() => {
    if (!isGettingDataSpace) {
      const body = {
        format: false,
        bucket: dataSpace?.s3Bucket?.name,
        key: dataSpace?.s3Bucket?.path,
      };
      if (isDev) console.log({ dsListDocumentsBody: body });
      listDocumentsLibrary({ body });
    }
  }, [
    dataSpace?.s3Bucket?.name,
    dataSpace?.s3Bucket?.path,
    isGettingDataSpace,
    listDocumentsLibrary,
  ]);

  const onDownloadDocument = async (item, index) => {
    setDownloadIndex(index);
    const bucket = dataSpace?.s3Bucket?.name;
    const key = item.Key;
    const body = { bucket, key, action: "getObject" };
    const response = await downloadDocument({ ...body });
    const link = document.createElement("a");
    link.href = response?.data?.url ?? "";
    link.download = key; // This sets the default file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const onDeleteDocument = async (item) => {
    const bucket = dataSpace?.s3Bucket?.name;
    const deleteKey = item.Key;
    const deleteBody = { bucket, key: deleteKey, action: "getObject" };
    const deleteResponse = await deleteFile({ body: deleteBody });
    if (isDev) console.log({ deleteResponse });
  };

  const uploadDocuments = useCallback(
    async (files) => {
      const name = user.fullName;
      const uploadParams = getDsUploadParameters(files, dataSpace);
      const uploadResponseArray = await Promise.all(
        uploadParams.map((uploadParam) => {
          return uploadFile(uploadParam);
        }),
      );
      if (isDev) console.log({ uploadResponseArray });

      const uploadDirectoryResponseArray = await Promise.all(
        uploadParams.map(async (uploadParam) => {
          const body = {
            ...uploadParam,
            user: name,
            folder: "Data Space",
          };
          return uploadDirectoryFile({ body });
        }),
      );
      if (isDev) console.log({ uploadDirectoryResponseArray });
      const body = {
        filesUpdated: files.map((f) => f.name),
        uploadedBy: user.fullName,
      };
      await sendDataSpaceFileUpdate({ id: dataSpace.id, body });
    },
    [dataSpace, uploadDirectoryFile, uploadFile, user.fullName],
  );

  const onDrop = useCallback(
    async (files) => {
      await uploadDocuments(files);
    },
    [uploadDocuments],
  );

  const onDocumentUpload = async (event) => {
    await uploadDocuments(event.target.files);
  };

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const loading =
    isGettingDataSpace ||
    isListingDocuments ||
    isUploadingFile ||
    isUploadingDirectoryFile;

  const role = user?.role ?? "client";
  const hasDownloadPrivileges = user?.hasDownloadPrivileges ?? false;
  const canDownloadDocuments = role !== "client" || hasDownloadPrivileges;

  const documents = getRequiredItems(
    dataSpace?.type ?? "RD",
    dataSpace?.startYear ?? 2021,
    dataSpace?.endYear ?? 2022,
  );

  if (!dataSpace && !isGettingDataSpace) {
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="text-xl font-semibold text-gray-900">
          You have not been invited to any Data Spaces.
        </h1>
        <p>
          Refresh the page and contact the SPRX team if you are still having
          issues.
        </p>
      </div>
    );
  }

  return (
    <main className="flex-1 lg:overflow-hidden">
      <div className={`flex-1 ${user.role === "client" ? "pt-6" : ""}`}>
        <div className="mx-auto flex max-h-full max-w-full flex-row items-center justify-between px-4">
          {/* <h1 className="text-2xl font-semibold text-gray-900">
            {dataSpace?.dataSpaceName ?? "..."}
          </h1> */}
          <div className="flex w-1/2 flex-row items-center">
            <button
              onClick={() => {
                setViewDataSpaceDetail(false);
              }}
              className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              <ArrowLeftIcon className="h-8 w-8" />
            </button>

            <DataSpaceHeaderContainer />
          </div>
          <div className="flex w-1/3 items-center justify-end space-x-4">
            <motion.button
              type="button"
              className="focus:ring-none inline-flex w-1/3 items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              onClick={async () => setOpen(true)}
            >
              Invite
            </motion.button>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
        </div>
        {/*Divider */}
        <div className="relative mx-4 mt-6">
          <div
            className="absolute inset-0 flex items-center"
            aria-hidden="true"
          >
            <div className="w-full border-t border-gray-600" />
          </div>
        </div>
        <div className="mx-auto h-auto max-w-full">
          {/*File Upload Box*/}
          <div {...getRootProps()} className="flex w-full py-4 px-4">
            <input {...getInputProps()} />
            <div className="flex w-full flex-col items-center justify-center rounded-xl border-4 border-dashed border-sprxClientPortalLightBlue bg-sprxClientPortalLightTeal p-4">
              <img
                className="h-8 w-8"
                src={"assets/images/Upload_Icon.svg"}
                alt={"Upload"}
              />
              <motion.h3 className="mt-2 text-center text-lg font-semibold text-gray-900">
                Drag and Drop files to upload
              </motion.h3>
              <motion.p className="text-text-gray-900 text-center text-sm font-light tracking-tight">
                or
              </motion.p>
              <motion.button
                className={
                  "focus:ring-none mt-2 inline-flex w-32 items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
                }
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => performClick("file-dialog-mass-import-ds")}
                disabled={dataSpace?.s3Bucket?.path === undefined}
              >
                Browse
              </motion.button>
              <motion.input
                ref={inputFileRef}
                id="file-dialog-mass-import-ds"
                multiple
                type="file"
                style={{ display: "none" }}
                accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain,.msg,.json,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.HEIC"
                onChange={(e) => onDocumentUpload(e)}
                disabled={dataSpace?.s3Bucket?.path === undefined}
              />
            </div>
          </div>

          <div className="grid w-full grid-cols-1 gap-4 p-4 sm:grid-cols-1 sm:gap-5 lg:grid-cols-8">
            {/*Left column */}
            <div className="col-span-2 flex max-h-104 w-full flex-col overflow-hidden rounded-lg border bg-white px-6 py-4 shadow-xl">
              <h1 className="text-md text-center font-semibold text-gray-900">
                Required Documents
              </h1>
              <div className="relative mt-2">
                <div
                  className="absolute inset-0 flex items-center"
                  aria-hidden="true"
                >
                  <div className="w-full border-t border-gray-600" />
                </div>
              </div>
              <div className="flex flex-col overflow-y-auto">
                <div className="mt-2 flex w-full font-semibold">
                  To Get Started
                </div>
                <div className="flex w-full">
                  <ul className="z-0 flex w-full flex-col">
                    {documents
                      .filter((doc) => doc?.required)
                      .map((item, index) => (
                        <RequiredDocumentItem
                          item={item}
                          index={`required-${index}`}
                          key={`required-${index}`}
                        />
                      ))}
                  </ul>
                </div>
                <div className="flex w-full font-semibold">We May Require</div>
                <div className="flex">
                  <ul className="z-0 flex w-full flex-col">
                    {documents
                      .filter((doc) => !doc?.required)
                      .map((item, index) => (
                        <RequiredDocumentItem
                          item={item}
                          index={`optional-${index}`}
                          key={`optional-${index}`}
                        />
                      ))}
                  </ul>
                </div>
              </div>
            </div>
            {/*Right column */}
            {loading ? (
              <div className="col-span-6 flex w-full justify-start">
                <DataSpaceListLoading />
              </div>
            ) : (
              <div className="col-span-6 flex w-full">
                <div className="flow-root w-full shadow-xl sm:rounded-lg">
                  <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                    <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                      <div className="max-h-104 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                        <table className="min-w-full divide-y divide-gray-300">
                          <thead className="bg-gray-50">
                            <tr>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:pl-6 lg:pl-8"
                              >
                                Name
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter sm:table-cell"
                              >
                                Uploaded By
                              </th>
                              <th
                                scope="col"
                                className="sticky top-0 z-10 hidden border-b border-gray-300 bg-white bg-opacity-75 px-3 py-3.5 text-left text-sm font-semibold text-gray-900 backdrop-blur backdrop-filter lg:table-cell"
                              >
                                Upload Date
                              </th>

                              <th
                                scope="col"
                                className="sticky top-0 z-10 border-b border-gray-300 bg-white bg-opacity-75 py-3.5 pr-4 pl-3 backdrop-blur backdrop-filter sm:pr-6 lg:pr-8"
                              ></th>
                            </tr>
                          </thead>
                          <tbody className="divide-y divide-gray-200 bg-white">
                            {uploadedFiles?.map((doc, index) => (
                              <tr key={index}>
                                <td className="whitespace-nowrap py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:pl-6">
                                  {doc.Key.split("/").pop()}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {doc.user}
                                </td>
                                <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                  {doc.uploaded}
                                </td>
                                <td className="flex flex-row justify-end whitespace-nowrap py-4 text-right text-sm font-medium sm:pr-6">
                                  <div className="flex flex-row space-x-8">
                                    {canDownloadDocuments ? (
                                      <motion.img
                                        className={
                                          "h-4 w-4 " +
                                          (isDownloadingDocument &&
                                          index === downloadIndex
                                            ? "animate-bounce"
                                            : "")
                                        }
                                        src={"assets/images/Download_Icon.svg"}
                                        alt={"Download"}
                                        whileHover={{ scale: 1.05 }}
                                        whileTap={{ scale: 0.95 }}
                                        onClick={() =>
                                          onDownloadDocument(doc, index)
                                        }
                                      />
                                    ) : null}
                                    <motion.img
                                      className="h-4 w-4"
                                      src={"assets/images/Trash_Icon.svg"}
                                      alt={"Delete"}
                                      whileHover={{ scale: 1.05 }}
                                      whileTap={{ scale: 0.95 }}
                                      onClick={() => onDeleteDocument(doc)}
                                    />
                                  </div>
                                </td>
                              </tr>
                            ))}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <CreateDataSpaceUserForm
        open={open}
        setOpen={setOpen}
        user={user}
        dataSpace={dataSpace}
      />
    </main>
  );
};
