import { EnergyModel } from "./EnergyModel";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
export const EnergyModelContainer = () => {
  const energyEngagementAPI = useEnergyEngagement();
  return (
    <EnergyModel
      // onClick={energyEngagementAPI.markModelStatusAsReady}
      // isSent={energyEngagementAPI.engagement.model.status === "READY"}
      // isMarkingModelStatus={energyEngagementAPI.updateEngagementData.isLoading || energyEngagementAPI.engagementLoading || energyEngagementAPI.engagementFetching }
    />
  );
};
