import * as React from "react";

export const NoItemsFound = ({ type }) => {
  return (
    <div className="flex flex-col items-center justify-center w-full h-full">
      <div
        className="flex flex-col items-center justify-center w-full h-full"
        style={{ height: "80vh" }}
      >
        No {type} Found
      </div>
    </div>
  );
};
