export const YES_MESSAGE = "Yes.";
export const NO_MESSAGE = "No.";

export const YesNoChoice = ({setMessage}) => {
    return (
        <div className="flex w-full justify-between">
            <button
                type="submit"
                className="bg-sprxClientPortalRed w-1/3 hover:opacity-50 font-bold text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                onClick={() => setMessage(NO_MESSAGE)}
            >
                No
            </button>
            <button
                type="submit"
                className="bg-sprxClientPortalDarkGreen w-1/3 hover:opacity-50 font-bold text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                onClick={() => setMessage(YES_MESSAGE)}
            >
                Yes
            </button>
        </div>
    );
};