export const DoneButton = ({ setMessage, copy }) => {
    return (
        <div className="flex w-full justify-center">
            <button
                type="submit"
                className="bg-sprxClientPortalDarkGreen w-1/3 focus:ring-0 focus:border-none hover:opacity-50 font-bold text-white px-4 py-2 rounded disabled:bg-gray-300 disabled:cursor-not-allowed"
                onClick={() => {
                    setMessage(copy);
                }}
            >
                I'm done
            </button>
        </div>
    );
};