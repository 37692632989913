import { ClipboardListIcon } from "@heroicons/react/solid";

const ProgressBubbles = (props) => {
  let autoProps = [
    {
      text: "Prop1",
      icon: [<ClipboardListIcon className="h-5 w-5 text-gray-500" />],
      progress: "COMPLETED",
    },
    {
      text: "Prop1",
      icon: [<ClipboardListIcon className="h-5 w-5 text-gray-500" />],
      progress: "INCOMPLETE",
    },
    {
      text: "Prop1",
      icon: [<ClipboardListIcon className="h-5 w-5 text-gray-500" />],
      progress: "PENDING",
    },
  ];
  return (
    <div className="flow-root">
      <ul className="-mb-8 flex flex-row">
        {autoProps.map((props) => {
          const spanRender = autoProps.indexOf(props) < autoProps.length - 1;
          const bgColor =
            props.progress === "COMPLETED"
              ? "bg-green-300"
              : props.progress === "INCOMPLETE"
              ? "bg-blue-300"
              : "bg-gray-300";
          return (
            <li key={Math.random()}>
              <div className="relative pr-8">
                {spanRender && (
                  <span
                    className="absolute right-2 top-4 ml-8 h-0.5 w-full bg-gray-200"
                    aria-hidden="true"
                  />
                )}
                <div className="relative flex space-x-3">
                  <div>
                    <span
                      className={
                        "h-8 w-8 rounded-full " +
                        bgColor +
                        " flex items-center justify-center ring-8 ring-white"
                      }
                    >
                      <ClipboardListIcon className="h-5 w-5 text-gray-500" />
                    </span>
                  </div>
                </div>
              </div>
            </li>
          );
        })}
      </ul>
    </div>
  );
};

export default ProgressBubbles;
