import { useState } from "react";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { getReviewColumns, getDownloadFileName } from "../utils";
import { DataTable } from "../../../../ui-components";
import { FlatfileSpace } from "../../../../ui-components/FlatfileSpace";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { getNamespaceType } from "../../UploadPortal/utils/getNamespaceType";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { ImportType, LineItem } from "../../types/RdData";

const TYPE_TO_DATA_KEY_MAP = {
  SUPPLY_REVIEW: "supplyData",
  COMPUTER_REVIEW: "computerLeaseData",
  CONTRACT_REVIEW: "contractData",
};

export const AccountingCard = ({ type }: { type: ImportType }) => {
  const [columnDef] = useState(getReviewColumns(type));
  const [showFlatfileModal, setShowFlatfileModal] = useState(false);
  const userAPI = useUsers();
  const dataAPI = useDataArray();
  const engagementAPI = useEngagementData();
  const fileAPI = useFile();
  // Update Line Item
  const onRowUpdate = async (newData: LineItem) =>
    await dataAPI.updateLineItem(newData, type);

  // Update Local Data After Flatfile Server Exchange
  const onCloseFlatfileSpace = async () => {
    await dataAPI.refreshData(type);
    setShowFlatfileModal(false);
  };

  if (dataAPI === null || engagementAPI === null) return null;

  // Get Data from DataArray
  const dataSourceKey = TYPE_TO_DATA_KEY_MAP[type];
  const data = dataAPI[dataSourceKey];

  return (
    <div className="z-40 px-8 pb-8">
      <div className="flex w-full flex-col items-center justify-center">
        <div className="flex w-full flex-row justify-end">
          <button
            className="focus:ring-none mr-8 mb-4 rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm"
            onClick={() => setShowFlatfileModal(true)}
          >
            Upload
          </button>
          <button
            className="focus:ring-none mb-4 rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm"
            onClick={() =>
              fileAPI.onDownloadDocument(getDownloadFileName(type))
            }
          >
            {fileAPI.isGettingBulkEditFile ? (
              <div className="w-full">
                <p className="h-2 w-16 animate-pulse rounded bg-white"></p>
              </div>
            ) : (
              "Download"
            )}
          </button>
        </div>
        <div className="h-1 min-h-70vh w-full">
          <DataTable
            columns={columnDef}
            data={data}
            isLoading={
              dataAPI === null ||
              engagementAPI === null ||
              dataAPI.isDownloadingFile
            }
            onRowUpdate={(newData: LineItem) => {
              onRowUpdate(newData);
            }}
            renderUpload={true}
          />
        </div>
      </div>
      {engagementAPI !== null && (
        <FlatfileSpace
          showSpace={showFlatfileModal}
          setShowSpace={setShowFlatfileModal}
          namespace={getNamespaceType(type)}
          engagementID={engagementAPI?.engagementData?.engagementID}
          companyName={engagementAPI?.engagementData?.companyName}
          type={type}
          name={userAPI?.user?.fullName}
          onCloseCallback={onCloseFlatfileSpace}
        />
      )}
    </div>
  );
};
