export const STATE_OPTS = [
  { name: " ", key: " " },
  { name: "AL", key: "AL" },
  { name: "AK", key: "AK" },
  { name: "AZ", key: "AZ" },
  { name: "AR", key: "AR" },
  { name: "CA", key: "CA" },
  { name: "CO", key: "CO" },
  { name: "CT", key: "CT" },
  { name: "DE", key: "DE" },
  { name: "DC", key: "DC" },
  { name: "FL", key: "FL" },
  { name: "GA", key: "GA" },
  { name: "HI", key: "HI" },
  { name: "ID", key: "ID" },
  { name: "IL", key: "IL" },
  { name: "IN", key: "IN" },
  { name: "IA", key: "IA" },
  { name: "KS", key: "KS" },
  { name: "KY", key: "KY" },
  { name: "LA", key: "LA" },
  { name: "ME", key: "ME" },
  { name: "MD", key: "MD" },
  { name: "MA", key: "MA" },
  { name: "MI", key: "MI" },
  { name: "MN", key: "MN" },
  { name: "MS", key: "MS" },
  { name: "MO", key: "MO" },
  { name: "MT", key: "MT" },
  { name: "NE", key: "NE" },
  { name: "NV", key: "NV" },
  { name: "NH", key: "NH" },
  { name: "NJ", key: "NJ" },
  { name: "NM", key: "NM" },
  { name: "NY", key: "NY" },
  { name: "NC", key: "NC" },
  { name: "ND", key: "ND" },
  { name: "OH", key: "OH" },
  { name: "OK", key: "OK" },
  { name: "OR", key: "OR" },
  { name: "PA", key: "PA" },
  { name: "RI", key: "RI" },
  { name: "SC", key: "SC" },
  { name: "SD", key: "SD" },
  { name: "TN", key: "TN" },
  { name: "TX", key: "TX" },
  { name: "UT", key: "UT" },
  { name: "VT", key: "VT" },
  { name: "VA", key: "VA" },
  { name: "WA", key: "WA" },
  { name: "WV", key: "WV" },
  { name: "WI", key: "WI" },
  { name: "WY", key: "WY" },
];

export const STATE_OPTS_V2 = [
  "N/A",
  "AL",
  "AK",
  "AZ",
  "AR",
  "CA",
  "CO",
  "CT",
  "DC",
  "DE",
  "FL",
  "GA",
  "HI",
  "ID",
  "IL",
  "IN",
  "IA",
  "KS",
  "KY",
  "LA",
  "ME",
  "MD",
  "MA",
  "MI",
  "MN",
  "MS",
  "MO",
  "MT",
  "NE",
  "NV",
  "NH",
  "NJ",
  "NM",
  "NY",
  "NC",
  "ND",
  "OH",
  "OK",
  "OR",
  "PA",
  "RI",
  "SC",
  "SD",
  "TN",
  "TX",
  "UT",
  "VT",
  "VA",
  "WA",
  "WV",
  "WI",
  "WY",
];

export const pageOpts = {
  pageSize: 10,
  pageSizeOptions: [],
  addRowPosition: "first",
  columnsButton: false,
};

export const qualifiedProjects = [
  "New or improved product for general market",
  "New or improved product for single customer",
  "New or Improved process",
  "New or Improved technique",
  "New or Improved formula",
  "New or improved software for general market",
  "New or improved software for single customer",
  "New or improved software for internal use",
  "R&D or engineering services",
  "Other Qualified Research",
];

export const unqualifiedProjects = [
  "Funded research",
  "Foreign research",
  "Market research",
  "Commercial production activity",
  "Production quality control",
  "Customer support activities",
  "Regulatory compliance",
  "Other Not Qualified Activities",
];

export const EXCLUDED_FIELDS = [
  // Computer Lease Fields
  "Vendor Account Name",
  "Vendor Account Description",
  "Vendor Account Department",
  "Vendor Location",
  "Vendor Cost",

  // Contractor Fields
  "Contractor Name",
  "Contractor Description",
  "Contractor Department",
  "Contractor Location",
  "Contractor Cost",

  // Supply Fields
  "Supply Account Name",
  "Supply Account Description",
  "Supply Account Department",
  "Supply Location",
  "Supply Cost",

  // Wage Fields
  "Employee Department",
  "Employee ID Number",
  "Employee Job Title",
  "Employee Location",
  "Employee Name",
  "Employee W2 Wages",
  "Notes",
  "activities",
  "filename",
  "projects",
  "completions",
  "email",
  "send",
  "surveyStatus",

  // Shared Fields
  "ID",
  "originalQPercentage",
  "originalQPercenatge",
  "qPercentage",
  "qre",
  "userProvided",
  "spaceID",
  "sum",
];

export const stateAbbreviations = {
  AL: "Alabama",
  AK: "Alaska",
  AZ: "Arizona",
  AR: "Arkansas",
  CA: "California",
  CO: "Colorado",
  CT: "Connecticut",
  DE: "Delaware",
  FL: "Florida",
  GA: "Georgia",
  HI: "Hawaii",
  ID: "Idaho",
  IL: "Illinois",
  IN: "Indiana",
  IA: "Iowa",
  KS: "Kansas",
  KY: "Kentucky",
  LA: "Louisiana",
  ME: "Maine",
  MD: "Maryland",
  MA: "Massachusetts",
  MI: "Michigan",
  MN: "Minnesota",
  MS: "Mississippi",
  MO: "Missouri",
  MT: "Montana",
  NE: "Nebraska",
  NV: "Nevada",
  NH: "New Hampshire",
  NJ: "New Jersey",
  NM: "New Mexico",
  NY: "New York",
  NC: "North Carolina",
  ND: "North Dakota",
  OH: "Ohio",
  OK: "Oklahoma",
  OR: "Oregon",
  PA: "Pennsylvania",
  RI: "Rhode Island",
  SC: "South Carolina",
  SD: "South Dakota",
  TN: "Tennessee",
  TX: "Texas",
  UT: "Utah",
  VT: "Vermont",
  VA: "Virginia",
  WA: "Washington",
  WV: "West Virginia",
  WI: "Wisconsin",
  WY: "Wyoming",
};
