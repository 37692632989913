import React from "react";
import { Engagement, EngagementStatus } from "../types";
import { Column } from "./Column";

export const Board: React.FC<{
  engagements: Engagement[];
  filter: EngagementStatus[];
}> = ({ engagements, filter }) => {
  const filterOrder: EngagementStatus[] = [
    "Hidden",
    "Upload",
    "Review",
    "Validation",
    "Completed",
  ];
  const orderedFilter = filterOrder.filter((f) => filter.includes(f));

  return (
    <div className="flex h-full justify-between">
      {orderedFilter.map((status, index) => {
        const isLast = index === filter.length - 1;
        return (
          <div
            className={`flex-1 px-2 ${
              index < filter.length - 1 ? "border-r border-gray-300" : ""
            }`}
            key={`tracker-container-${index}`}
          >
            <Column
              key={status}
              title={status}
              engagements={engagements?.filter((e) => e.status === status)}
              isLast={isLast}
            />
          </div>
        );
      })}
    </div>
  );
};
