export const StateFormColumnHeader = ({title}) => {
    return (
        <div
              id="computed-credit-column-header"
              className="flex flex-col justify-center items-start mb-4"
            >
              <div className="text-2xl font-md">
                {title}
              </div>
        </div>
    );
}