export const getS3BucketAndKey = (type, engagementData) => {
  let [key, bucket] = [undefined, undefined];
  switch (type) {
    case "projectList":
      key = engagementData?.QREData?.projectList?.data?.data;
      bucket = engagementData?.QREData?.projectList?.bucket;
      break;
    case "crList":
      key = engagementData?.QREData?.crList?.data?.data;
      bucket = engagementData?.QREData?.crList?.bucket;
      break;
    case "computerLeaseList":
      key = engagementData?.QREData?.computerLeaseList?.data?.data;
      bucket = engagementData?.QREData?.computerLeaseList?.bucket;
      break;
    case "wageList":
      key = engagementData?.QREData?.wageList?.data?.data;
      bucket = engagementData?.QREData?.wageList?.bucket;
      break;
    case "supplyList":
      key = engagementData?.QREData?.supplyList?.data?.data;
      bucket = engagementData?.QREData?.supplyList?.bucket;
      break;
    default:
      break;
  }
  return [key, bucket];
};
