import { Progression } from "./Progression";
import { ArrowRightIcon } from "@heroicons/react/solid";

export const MetaData = ({ engagement }: { engagement: any }) => {
  const createdAt = new Date(engagement.createdAt);
  createdAt.setFullYear(createdAt.getFullYear() + 1);

  const displayFee =
    engagement?.tracker["upload-complete"]?.status === "COMPLETE" &&
    engagement?.tracker["fee"];
  const feeType =
    engagement?.tracker?.fee?.type === "FIXED" ? "Fixed" : "Percentage";
  const feeValue =
    feeType === "Fixed"
      ? `$${Number(engagement?.tracker?.fee?.value).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`
      : `$${(
          Number(
            engagement?.tracker?.fee?.value * engagement?.credit?.rdCredit
          ) / 100
        ).toLocaleString(undefined, {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2,
        })}`;
  return (
    <>
      <Progression
        startDate={new Date(engagement.createdAt).toISOString()}
        uploadedDate={
          engagement?.tracker["upload-complete"]?.lastUpdated
            ? new Date(
                engagement?.tracker["upload-complete"]?.lastUpdated
              ).toISOString()
            : undefined
        }
        reviewedDate={
          engagement?.tracker["review-complete"]?.lastUpdated
            ? new Date(
                engagement?.tracker["review-complete"]?.lastUpdated
              ).toISOString()
            : undefined
        }
        validatedDate={
          engagement?.tracker["reports-complete"]?.lastUpdated
            ? new Date(
                engagement?.tracker["reports-complete"]?.lastUpdated
              ).toISOString()
            : undefined
        }
      />
      <div className="grid grid-cols-4">
        <div className="col-span-1">
          <div className="text-md font-bold my-4">Company Name</div>
          <div className="text-md font-bold mt-4">Sales Representative</div>
          <div className="text-md font-bold mt-4">CPA</div>
        </div>
        <div className="col-span-1">
          <div className="text-md  my-4">{engagement.companyName}</div>

          <div className="text-md mt-4">
            {engagement?.salesRep?.name ?? "Unassigned"}
          </div>
          <div className="text-md mt-4">
            {engagement?.CPAName ?? "Unassigned"}
          </div>
        </div>
        <div className="col-span-1 ">
          <div className="text-md font-bold my-4">Tax Year</div>
          <div className="text-md font-bold mt-4">Due Date</div>
          {displayFee ? (
            <div className="text-md font-bold mt-4">
              {feeType} Fee{" "}
              {feeType === "Percentage"
                ? `- ${engagement?.tracker?.fee?.value}%`
                : ""}
            </div>
          ) : (
            ""
          )}
        </div>
        <div className="col-span-1">
          <div className="text-md  my-4">
            {new Date(engagement.taxYearStart).toLocaleDateString()}
            <ArrowRightIcon className="w-6 h-6 inline-block mx-2" />
            {new Date(engagement.taxYearEnd).toLocaleDateString()}
          </div>
          <div className="text-md mt-4">
            {engagement?.tracker?.dueDate ?? createdAt.toLocaleDateString()}
          </div>

          {displayFee ? <div className="text-md mt-4">{feeValue}</div> : ""}
        </div>
      </div>
    </>
  );
};
