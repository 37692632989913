import * as React from "react";
import { VirtualizedList } from "../components/VirtualizedList";
import { useEngagementData } from "../../../../../rtk/R&D/EngagementData/useEngagementData";
import { useDataArray } from "../../../../../rtk/R&D/DataArray/useDataArray";
import { useFile } from "../../../../../rtk/R&D/File/useFile";
import { SprxLoading } from "../../../../../ui-components";

export const ProjectMemosContainer = () => {
  const engagementDataAPI = useEngagementData();
  const dataAPI = useDataArray();
  const fileAPI = useFile();

  const onClickDownload = async (items: string[]) => {
    const isFinalized = engagementDataAPI.engagementData.status.hasOwnProperty(
      "is-finalized"
    )
      ? engagementDataAPI.engagementData.status["is-finalized"]
      : false;
    if (items.length === 0) {
      alert("Please select at least one project.");
      return;
    }
    // Generate link
    const link = await fileAPI.generatePartialReport({
      engagementID: engagementDataAPI.engagementData.engagementID,
      body: {
        reportType: "PROJECTS",
        IDs: items,
        isDraft: !isFinalized,
      },
    });

    // Handler Error
    if (fileAPI?.errors?.generatePartialReportError) {
      console.error(fileAPI.errors.generatePartialReportError);
      return;
    }
    // Open link
    window.open(link.data, "_blank");
  };

  const apisAreLoading =
    engagementDataAPI === null || dataAPI === null || fileAPI === null;

  console.log(dataAPI);

  if (apisAreLoading || dataAPI.projectData.length === 0)
    return (
      <div className="flex justify-center items-center h-800px">
        <SprxLoading />
      </div>
    );

  // if (dataAPI.projectData.length === 0) {
  //   return (
  //     <div className="flex justify-center items-center h-800px">
  //       <h2 className="text-xl text-gray-400">
  //         No projects exist on this engagement. If that seems incorrect, please
  //         contact an SPRX team member.
  //       </h2>
  //     </div>
  //   );
  // }

  return (
    <VirtualizedList
      items={dataAPI.projectData}
      onClickDownload={onClickDownload}
      keys={{
        "Project Name": "Title",
        "Project Location": "Location",
      }}
      isLoading={fileAPI.isGeneratingPartialReport}
    />
  );
};
