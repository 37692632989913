import { Fragment } from "react";
import classNames from "classnames";
import { Menu, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

const SplitButtonSelector = (props) => {
  return (
    <span
      className={classNames(
        props.className && props.className,
        "relative z-50 inline-flex shadow-sm rounded-md w-64 px-1"
      )}
    >
      <span
        type="button"
        className="relative truncate w-full inline-flex items-center px-4 py-2 rounded-l-md bg-sprxBlue text-sm font-medium text-white justify-center focus:z-10 focus:outline-none"
      >
        {props.selectedTitle}
      </span>
      <Menu as="span" className="-ml-px relative block">
        {({ open }) => (
          <>
            <Menu.Button className="relative inline-flex items-center px-2 py-2 rounded-r-md border-l border-gray-300 bg-sprxBlue text-sm font-medium text-white hover:bg-blue-400 focus:z-10 focus:outline-none">
              <span className="sr-only">Open options</span>
              <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Menu.Button>
            <Transition
              show={open}
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items
                static
                className="origin-top-right absolute right-0 mt-2 w-64 -mx-1 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none"
              >
                <div className="py-1">
                  {props.items.map((item) => (
                    <Menu.Item key={item.engagementName}>
                      {({ active }) => (
                        <button
                          className={classNames(
                            active
                              ? "bg-gray-100 text-gray-900"
                              : "text-gray-700",
                            "flex w-full px-4 py-2 text-sm truncate"
                          )}
                          onClick={item.onClick}
                        >
                          {item.engagementName}
                        </button>
                      )}
                    </Menu.Item>
                  ))}
                </div>
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </span>
  );
};

export default SplitButtonSelector;
