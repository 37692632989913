import * as yup from "yup";

export const getEditProjectFormShape = () => {
  return yup.object().shape({
    name: yup.string(),
    location: yup.string(),
    extendedDescription: yup.string(),
    purpose: yup.string(),
    experimentation: yup.string(),
    uncertainty: yup.string(),
    nature: yup.string(),
    ID: yup.string(),
  });
};
