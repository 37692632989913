import { EngagementList } from "./EngagementList";
import { useState, useEffect } from "react";
import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { EngagementListLoading } from "./EngagementListLoading";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { useEnergyComments } from "../../../rtk/Energy/Comments/useEnergyComments";
import { StudyListEmpty } from "./StudyListEmpty";
import { useListModelingEngagementsQuery } from "../../../api/energy/engagementAPI";

interface activeEnagement {
  [key: string]: any;
}

interface EngagementListContainerProps {
  setActiveEngagement: (eng: activeEnagement) => void;
  type: string;
}

export const ModelerEngagementListContainer = ({
  setActiveEngagement,
  type,
}: EngagementListContainerProps) => {
  const energyEngagementAPI = useEnergyEngagement();
  const userAPI = useUsers();

  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [currentTab, setCurrentTab] = useState({
    label: "Ready",
    value: "Ready",
  });

  const {
    data: completeModelingEngs,
    isLoading: isLoadingCompleteModelingEngs,
  } = useListModelingEngagementsQuery({
    status: "COMPLETE",
    submittedBy: userAPI.user.fullName,
  });
  const {
    data: incompleteModelingEngs,
    isLoading: isLoadingIncompleteModelingEngs,
  } = useListModelingEngagementsQuery({
    status: "READY",
  });

  if (
    !energyEngagementAPI ||
    isLoadingCompleteModelingEngs ||
    isLoadingIncompleteModelingEngs ||
    !completeModelingEngs ||
    !incompleteModelingEngs
  )
    return <EngagementListLoading />;

  const engagements =
    currentTab.value === "Ready"
      ? incompleteModelingEngs.filter(
          (e) =>
            !e.model?.claimed || e.model?.claimedBy === userAPI.user.fullName,
        )
      : completeModelingEngs;

  const filteredEngagements = engagements?.filter(
    (engagement: any) =>
      engagement.name.toLowerCase().includes(filter.toLowerCase()) ||
      engagement.companyName.toLowerCase().includes(filter.toLowerCase()),
  );

  const onSetEngagementActive = async (engagement: any): Promise<void> => {
    setIsSettingActive(true);
    const body = {
      activeEnergyEngagementID: engagement.engagementID,
      engagementType: "EN",
      isUsingDataSpace: false,
    };
    const userID = userAPI.user.userID;
    const updateUserReponse = await userAPI.updateTaxUser({
      userID: userID,
      body: body,
    });

    const updatedUser = await userAPI.getTaxUser(userID); // Grab this from updateTaxUser
    dispatch(setUserData(updatedUser.data));
    setActiveEngagement(engagement);
    setIsSettingActive(false);
  };

  const onClaimAssignment = async (engagement: any): Promise<void> => {
    const user = userAPI.user;
    let claimedAssignments = user.claimedAssignments || [];

    const updateUserBody = {
      claimedAssignments: [...claimedAssignments, engagement.engagementID],
    };
    const updateUserReponse = await userAPI.updateTaxUser({
      userID: user.userID,
      body: updateUserBody,
    });
    const updatedUser = await userAPI.getTaxUser(userAPI.user.userID);
    dispatch(setUserData(updatedUser.data));

    const engagementBody = {
      model: {
        ...engagement.model,
        claimed: true,
        claimedBy: user.fullName,
      },
    };

    const updateEngagementResponse = await energyEngagementAPI.updateEngagement(
      {
        engagementID: engagement.engagementID,
        body: engagementBody,
      },
    );
  };

  return (
    <EngagementList
      currentTab={currentTab}
      setCurrentTab={setCurrentTab}
      user={userAPI.user}
      engagements={filteredEngagements?.map((eng: any) => {
        const status = eng?.model?.status;

        const assignedDate = eng?.model?.assignedDate;

        return {
          ...eng,
          assignedDate,
          status: {
            NOT_READY: "Not Ready",
            READY: "Ready",
            COMPLETE: "Complete",
          }[status],
        };
      })}
      setFilter={setFilter}
      filter={filter}
      setActiveEngagement={onSetEngagementActive}
      isSettingActive={isSettingActive}
      onClaimAssignment={onClaimAssignment}
    />
  );
};
