import React from "react";
import {
  differenceInDays,
  parseISO,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";

interface ProgressionProps {
  startDate: string;
  uploadedDate?: string;
  reviewedDate?: string;
  validatedDate?: string;
}

interface Stage {
  date?: string;
  label: string;
}

export const calculateDaysDifference = (start: string, end: string) => {
  const startDate = parseISO(start);
  const endDate = parseISO(end);
  return differenceInDays(endDate, startDate);
};

const calculateTimeDifference = (start: string, end?: string) => {
  if (!end) return null;
  const startDate = parseISO(start);
  const endDate = parseISO(end);

  const days = differenceInDays(endDate, startDate);
  if (days > 0) return { value: days, unit: "day" };

  const hours = differenceInHours(endDate, startDate);
  if (hours > 0) return { value: hours, unit: "hour" };

  const minutes = differenceInMinutes(endDate, startDate);
  return { value: minutes, unit: "minute" };
};

const renderElapsedTimeText = (difference: { value: number; unit: string }) => {
  const { value, unit } = difference;
  return `${value} ${unit}${value !== 1 ? "s" : ""}`;
};

const ProgressionStage: React.FC<{
  isFirst: boolean;
  isInProgress: boolean;
  elapsedTime: string;
}> = ({ isFirst, isInProgress, elapsedTime }) => {
  const baseClass = `h-8 ${
    isInProgress ? "bg-blue-600" : "bg-blue-400"
  } text-white flex items-center justify-center px-2 w-full`;
  const skewClass = `h-8 w-8 ${
    isInProgress ? "bg-blue-600" : "bg-blue-400"
  } transform -skew-x-12`;

  return (
    <div className="flex items-center w-1/4 mx-1">
      {!isFirst && <div className={`${skewClass} -mr-2`}></div>}
      <div className={`${baseClass} ${isFirst ? "rounded-l-full" : ""}`}>
        {elapsedTime}
      </div>
      <div className={`${skewClass} -ml-1`}></div>
    </div>
  );
};

export const Progression: React.FC<ProgressionProps> = ({
  startDate,
  uploadedDate,
  reviewedDate,
  validatedDate,
}) => {
  const stages: Stage[] = [
    { date: uploadedDate, label: "Uploaded" },
    { date: reviewedDate, label: "Reviewed" },
    { date: validatedDate, label: "Verified" },
  ];

  const isComplete = stages.every((stage) => stage.date);

  let previousDate = startDate;
  let isInProgress = false;

  const progressions = stages.map((stage, index) => {
    if (!stage.date && !isInProgress) {
      isInProgress = true;
      const difference = calculateTimeDifference(
        previousDate,
        new Date().toISOString()
      );
      const elapsedTime = difference ? renderElapsedTimeText(difference) : "";
      return (
        <ProgressionStage
          key={index}
          isFirst={index === 0}
          isInProgress={true}
          elapsedTime={elapsedTime}
        />
      );
    } else if (!stage.date && isInProgress) {
      return null;
    }

    const difference = calculateTimeDifference(previousDate, stage.date);
    const elapsedTime = difference ? renderElapsedTimeText(difference) : "";
    previousDate = stage.date;

    return (
      <ProgressionStage
        key={index}
        isFirst={index === 0}
        isInProgress={false}
        elapsedTime={elapsedTime}
      />
    );
  });

  return (
    <div className="flex items-center w-full py-2 mt-2">
      {progressions}
      <div
        className={`h-8 w-8 transform -skew-x-12 -mr-2 ml-1 ${
          isComplete ? "bg-green-500" : "bg-gray-200"
        }`}
      ></div>
      <div className="flex-1 h-8 bg-gray-200 rounded-r-full ml-1"></div>
      {isComplete && (
        <div className="flex items-center w-1/4 mx-1">
          <div className="h-8 w-8 bg-green-500 transform -skew-x-12 -mr-2"></div>
          <div className="h-8 bg-green-500 text-white flex items-center justify-center px-2 w-full rounded-r-full">
            <p className="mr-20">Complete!</p>
          </div>
        </div>
      )}
    </div>
  );
};
