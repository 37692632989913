import React, { useEffect } from "react";
import _ from "lodash";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion, AnimatePresence } from "framer-motion";
import {
  getCreateDataSpaceFormDefaultFields,
  getCreateDataSpaceFormShape,
} from "./utils";
import { CreateDataSpaceModel } from "../types/dataSpace";
import { Company } from "../types/company";
import { CreateFormWrapper } from "../components/CreateFormWrapper";
import Select from "react-select";

const currentYear = new Date().getFullYear();

interface CreateDataSpaceFormProps {
  user: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreate: (model: CreateDataSpaceModel) => Promise<void>;
  companies: Company[];
  isAdmin: boolean;
  isCreatingDataSpace: boolean;
}

export const CreateDataSpaceForm = ({
  user,
  open,
  setOpen,
  onCreate,
  companies,
  isAdmin,
  isCreatingDataSpace,
}: CreateDataSpaceFormProps) => {
  const schema = getCreateDataSpaceFormShape();
  const defaultValues = getCreateDataSpaceFormDefaultFields({
    company: companies[0],
    currentYear,
  });

  const {
    control,
    formState,
    handleSubmit,
    watch,
    getValues,
    reset,
    setValue,
  } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, isSubmitSuccessful, errors } = formState;

  useEffect(() => {
    if (isSubmitSuccessful) {
      reset(getValues());
    }
  }, [isSubmitSuccessful, getValues, reset]);

  const onClickSend = async (model) => {
    // Only Submit Company ID if admin
    let { companyID, ...rest } = model;
    companyID = companyID.value;
    let payload = {
      ...rest,
      ...(isAdmin && { companyID }),
    };

    onCreate(payload);
  };

  return (
    <CreateFormWrapper open={open} setOpen={setOpen} type="Data Space">
      <form
        className="mt-5 sm:flex sm:flex-col sm:items-center"
        onSubmit={handleSubmit((model) => onClickSend(model))}
      >
        <motion.div className="flex w-full flex-col items-center">
          {isAdmin ? (
            <Controller
              name="companyID"
              control={control}
              render={({ field }) => (
                <div className="w-full">
                  <label htmlFor="name" className="text-left">
                    Assigned Company <span className="text-sprxOrange">*</span>
                  </label>
                  <div className="mt-1 mb-4">
                    <Select
                      {...field}
                      onChange={(val) => setValue("companyID", val as any)}
                      options={companies.map((company) => ({
                        value: company.companyID,
                        label: company.companyName,
                      }))}
                      styles={{
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "300px",
                          overflowY: "auto",
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: "14px",
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
            />
          ) : null}
          <Controller
            name="companyName"
            control={control}
            render={({ field }) => (
              <div className="w-full text-left">
                <label htmlFor="name" className="text-left">
                  Company Name <span className="text-sprxOrange">*</span>
                </label>
                <input
                  {...field}
                  type="text"
                  name="Company Name"
                  id="companyName"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                  placeholder="Business Corp"
                />
              </div>
            )}
          />
          <Controller
            name="dataSpaceName"
            control={control}
            render={({ field }) => (
              <div className="mt-4 w-full text-left">
                <label htmlFor="name" className="text-left">
                  Data Space Name <span className="text-sprxOrange">*</span>
                </label>
                <input
                  {...field}
                  type="text"
                  name="Data Space Name"
                  id="dataSpaceName"
                  required
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                  placeholder="Business Corp's Data Space"
                />
              </div>
            )}
          />
          <motion.div className="flex w-full flex-row justify-between">
            <Controller
              name="startYear"
              control={control}
              render={({ field }) => (
                <div className="mt-4 mr-2 w-full text-left">
                  <label htmlFor="name" className="text-left">
                    Tax Year Start <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    {...field}
                    type="number"
                    name="Start Year"
                    id="startYear"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                  />
                </div>
              )}
            />
            <Controller
              name="endYear"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left">
                  <label htmlFor="name" className="text-left">
                    Tax Year End <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    {...field}
                    type="number"
                    name="Ending Year"
                    id="endYear"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                  />
                </div>
              )}
            />
          </motion.div>

          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <div className="mt-4 w-full">
                <label htmlFor="name" className="text-left">
                  Type <span className="text-sprxOrange">*</span>
                </label>
                <select
                  {...field}
                  id="type"
                  name="type"
                  className="mb-4 block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                >
                  <option value="none" disabled>
                    Select an Option
                  </option>
                  {[
                    {
                      label: "Research & Development",
                      value: "RD",
                    },
                    { label: "179D", value: "EN" },
                    {
                      label: "Cost Segregation",
                      value: "CS",
                    },
                    {
                      label: "45L",
                      value: "45L",
                    },
                  ]?.map((type) => {
                    return (
                      <option value={type.value} key={type.value}>
                        {type.label}
                      </option>
                    );
                  })}
                </select>
              </div>
            )}
          />
          <motion.div className="mt-6 flex w-full flex-row items-end justify-end">
            <motion.button
              type="submit"
              data-test="Save & Close"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={_.isEmpty(dirtyFields) || !isValid}
              className={
                "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
                (!isValid
                  ? "cursor-not-allowed bg-opacity-50"
                  : " hover:bg-sprxClientPortalDarkBlue")
              }
            >
              {isCreatingDataSpace ? (
                <div className="flex h-5 w-full flex-col items-center justify-center">
                  <p
                    className={
                      "h-2 w-full animate-pulse rounded-md bg-gray-100"
                    }
                  ></p>
                </div>
              ) : (
                "Create"
              )}
            </motion.button>
          </motion.div>
        </motion.div>
      </form>
    </CreateFormWrapper>
  );
};
