import { useState } from "react";
import { motion } from "framer-motion";
import { XIcon, PencilIcon } from "@heroicons/react/outline";
import { OpenActionCardWrapper, DialogTitle } from "./OpenActionCardWrapper";
import * as React from "react";
import { ThumbUpIcon as OutlinedThumbUpIcon } from "@heroicons/react/outline";
import { ThumbUpIcon } from "@heroicons/react/solid";

export const OpenActionCard = ({
  open,
  setOpen,
  selectedID,
  isUpdatingEngagementData,
  setSelectedID,
  closeVisible,
  setCloseVisible,
  setCommentOpen,
  disableOnClickOutsideClose,
  secondaryPanel,
  small,
  medium,
  markAsTracked,
  engagementData,
  trackerType,
  hideEngagementButton,
  topRightPanel,
}: {
  open?: boolean;
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  selectedID?: any;
  isUpdatingEngagementData?: boolean;
  setSelectedID?: React.Dispatch<React.SetStateAction<any>>;
  closeVisible?: boolean;
  setCloseVisible?: React.Dispatch<React.SetStateAction<boolean>>;
  setCommentOpen?: React.Dispatch<React.SetStateAction<boolean>>;
  disableOnClickOutsideClose?: boolean;
  secondaryPanel?: any;
  small?: boolean;
  medium?: boolean;
  markAsTracked?: any;
  engagementData?: any;
  trackerType?: string;
  hideEngagementButton?: any;
  topRightPanel?: any;
}) => {
  const [tracker, setTracker] = useState(engagementData?.tracker);

  const TrackerIcon = () => {
    const reportsKey = `reports-${selectedID.key}`;
    const reportsStatus = tracker?.[reportsKey]?.status;

    const handleIncompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [reportsKey]: {
          status: "COMPLETE",
        },
      }));
      await markAsTracked("COMPLETE", reportsKey);
    };

    const handleCompleteClick = async () => {
      setTracker((prevTracker) => ({
        ...prevTracker,
        [reportsKey]: { status: "INCOMPLETE" },
      }));
      await markAsTracked("INCOMPLETE", reportsKey);
    };

    return (
      <motion.div
        className={`hidden sm:block absolute top-0 ${
          setCommentOpen ? "right-32" : "right-16"
        } pt-4 pr-4`}
      >
        {isUpdatingEngagementData ? (
          <div>
            <div className="animate-pulse ml-2 my-2 h-2 bg-sprxGrey rounded w-4"></div>
          </div>
        ) : (
          <>
            {!tracker?.hasOwnProperty(reportsKey) ||
            reportsStatus === "INCOMPLETE" ? (
              <OutlinedThumbUpIcon
                className="h-6 w-6 text-gray-400"
                onClick={handleIncompleteClick}
              />
            ) : (
              <ThumbUpIcon
                className="h-6 w-6 text-sprxGreen"
                onClick={handleCompleteClick}
              />
            )}
          </>
        )}
      </motion.div>
    );
  };

  return (
    <OpenActionCardWrapper
      open={open}
      setOpen={setOpen}
      selectedID={selectedID}
      disableOnClickOutsideClose={disableOnClickOutsideClose}
      secondaryPanel={secondaryPanel}
    >
      <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
      <motion.div className="fixed inset-0 z-10 overflow-y-auto">
        <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
          {/* Modal content */}
          <motion.div
            className={`relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all ${
              small
                ? "min-h-47vh min-w-47.5vw max-w-47.5vw"
                : medium
                ? "min-h-72vh min-w-72vw max-w-72vw"
                : "min-h-94vh min-w-95vw max-w-95vw"
            }`}
          >
            <motion.div>
              {/* Modal title, description, and close button */}
              {hideEngagementButton ? hideEngagementButton : null}
              {selectedID?.statusTracker ? <TrackerIcon /> : ""}
              {setCommentOpen && (
                <motion.div className="hidden sm:block absolute top-0 right-16 pt-4 pr-4">
                  <motion.button
                    type="button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      setCommentOpen(true);
                    }}
                  >
                    <PencilIcon
                      className="h-6 w-6"
                      aria-hidden={selectedID?.hideCloseIcon ?? false}
                    />
                  </motion.button>
                </motion.div>
              )}
              {topRightPanel ? topRightPanel : null}
              {closeVisible && (
                <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <motion.button
                    type="button"
                    whileHover={{ scale: 1.05 }}
                    whileTap={{ scale: 0.95 }}
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      setOpen(false);
                      setSelectedID(null);
                      setCloseVisible(false);
                    }}
                  >
                    <XIcon
                      className="h-6 w-6"
                      aria-hidden={selectedID?.hideCloseIcon ?? false}
                    />
                  </motion.button>
                </motion.div>
              )}
              <motion.div className="text-center sm:mt-5">
                <DialogTitle title={selectedID?.title ?? ""} />
                <motion.div className="flex flex-row justify-between items-center">
                  <motion.p className="text-sm text-gray-500 px-4 pt-2 text-left">
                    {selectedID?.description ?? ""}
                  </motion.p>
                </motion.div>
              </motion.div>
              <motion.div className="mb-4">
                {/* Modal main content */}
                {selectedID?.component ?? null}
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      </motion.div>
    </OpenActionCardWrapper>
  );
};
