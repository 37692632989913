import { useMemo, useRef } from "react";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import { getCertificationFormShape } from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../../../ui-components";
import { performClick } from "../../../R&D/utils";

interface ModelSubmissionFormProps {
  onSubmit: () => Promise<void>;
  isSubmitting: boolean;
}

export const CertificationForm = ({
  onSubmit,
  isSubmitting,
}: ModelSubmissionFormProps) => {
  const schema = getCertificationFormShape();

  const { control, handleSubmit, formState } = useForm({
    mode: "onSubmit",
    resolver: yupResolver(schema),
  });
  const { errors, isValid } = formState;

  return (
    <>
      <form
        data-test="building-profile-form"
        className="mt-4 ml-4 mr-4"
        onSubmit={handleSubmit(() => onSubmit())}
      >
        <div className="flex flex-col justify-between">
          <div className="grid grid-cols-3 gap-y-8 gap-x-4">
            <Controller
              name="isAccurate"
              control={control}
              render={({ field }) => (
                <div className="sm:col-span-2">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <motion.input
                        {...field}
                        value={field.value as any}
                        checked={field.value}
                        id="isAccurate"
                        name="isAccurate"
                        type="checkbox"
                        className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                        whileHover={{ scale: 1.05 }}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isAccurate"
                        className="font-medium text-gray-700"
                      >
                        Confirm accuracy
                      </label>
                      <p className="text-gray-500">
                        Do you promise that this is accurate?
                      </p>
                    </div>
                  </div>
                  {errors?.isAccurate && (
                    <p className=" pl-2 text-sprxOrange">
                      Please confirm accuracy
                    </p>
                  )}
                </div>
              )}
            />
          </div>
          <motion.div className="flex flex-row w-full justify-end items-end mt-24">
            <motion.button
              type="submit"
              data-test="Save & Close"
              disabled={!isValid}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                "inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-none sm:text-sm " +
                (!isValid
                  ? "bg-opacity-50 cursor-not-allowed"
                  : " hover:bg-sprxClientPortalDarkBlue")
              }
            >
              {isSubmitting ? (
                <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
              ) : (
                "Save & Close"
              )}
            </motion.button>
          </motion.div>
        </div>
      </form>
    </>
  );
};
