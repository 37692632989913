import { useRef, useEffect, useState } from "react";

import { useDataSpaces } from "../../rtk/DataSpaces/useDataSpaces";
import { useUsers } from "../../rtk/Users/useUsers";
import SelectorHeader from "../../ui-components/SelectorHeader";
import { useDispatch } from "react-redux";
import { setUserData } from "../../auth/rtk/userSlice";
import { useFile } from "../../rtk/R&D/File/useFile";

export const DataSpaceHeaderContainer = () => {
  const fetchedRef = useRef(false);
  const dataSpaceAPI = useDataSpaces();
  const usersAPI = useUsers();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();

  const loading =
    !dataSpaceAPI ||
    !usersAPI ||
    usersAPI.isUpdatingUser ||
    dataSpaceAPI.isListingAdminDataSpaces ||
    dataSpaceAPI.isListingDataSpaces ||
    dataSpaceAPI.isGettingDataSpace ||
    usersAPI.isGettingUser ||
    isLoading;

  if (loading) {
    return (
      <div className="flex w-full flex-row items-center justify-between px-4">
        <div className="ml-2 h-2 w-1/2 animate-pulse rounded bg-sprxGrey"></div>
      </div>
    );
  }

  const dataSpaces =
    usersAPI.user.role === "admin"
      ? dataSpaceAPI.adminDataSpacesList
      : dataSpaceAPI.dataSpacesList;

  const onDataSpaceSelect = async (id: string) => {
    setIsLoading(true);
    const body = {
      activeDataSpaceID: id,
      isUsingDataSpace: true,
      engagementType: "DS",
    };
    await usersAPI.updateTaxUser({
      userID: usersAPI.user.userID,
      body: body,
    });
    await usersAPI.getTaxUser(usersAPI.user.userID).then((res: any) => {
      dispatch(setUserData(res.data));
      fetchedRef.current = false;
    });
    setIsLoading(false);
  };

  if (
    usersAPI.user.companyID === process.env.REACT_APP_DEFAULT_COMPANY &&
    usersAPI.user.role === "client"
  ) {
    return (
      <h1 className="ml-2 text-2xl font-semibold text-gray-900">
        {dataSpaceAPI.dataSpace?.dataSpaceName}
      </h1>
    );
  }

  if (dataSpaces?.length === 1) {
    return (
      <SelectorHeader
        currentItem={dataSpaceAPI?.dataSpace?.dataSpaceName}
        items={[]}
        onSelectItem={(id: string) => onDataSpaceSelect(id)}
        type="dataSpace"
      />
    );
  }

  const nonActiveDataSpaces = dataSpaces?.filter((dataSpace: any) => {
    return dataSpace.id !== dataSpaceAPI.dataSpace.id;
  });

  return (
    <SelectorHeader
      currentItem={dataSpaceAPI?.dataSpace?.dataSpaceName}
      items={nonActiveDataSpaces}
      onSelectItem={(id: string) => onDataSpaceSelect(id)}
      type="dataSpace"
    />
  );
};
