const CreateEngagementReview = (props) => {
  let today = new Date();
  let dd = today.getDate();

  let mm = today.getMonth() + 1;
  const yyyy = today.getFullYear();
  if (dd < 10) {
    dd = `0${dd}`;
  }

  if (mm < 10) {
    mm = `0${mm}`;
  }

  today = `${mm}/${dd}/${yyyy}`;

  return (
    <div className="px-4 py-5 sm:px-6">
      <dl className="grid grid-cols-1 gap-x-4 gap-y-8 sm:grid-cols-2">
        <div className="sm:col-span-1 ">
          <dt className="text-sm font-medium text-gray-500">Engagement Name</dt>
          <dd className="mt-1 text-sm text-gray-900">{props.engagementName}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Company Name</dt>
          <dd className="mt-1 text-sm text-gray-900">{props.companyName}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Tax Year Start</dt>
          <dd className="mt-1 text-sm text-gray-900">{props.taxYearStart}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">Tax Year End</dt>
          <dd className="mt-1 text-sm text-gray-900">{props.taxYearEnd}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Engagment Delivery Type
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{props.engagementType}</dd>
        </div>
        <div className="sm:col-span-1">
          <dt className="text-sm font-medium text-gray-500">
            Engagement Creation Date
          </dt>
          <dd className="mt-1 text-sm text-gray-900">{today}</dd>
        </div>
      </dl>
    </div>
  );
};

export default CreateEngagementReview;
