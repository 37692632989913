import EngagementDataContext from "./EngagementDataContext";
import {
  useGetEngagementDataQuery,
  useUpdateEngagementDataMutation,
  useListTaxEngagementsQuery,
  useCreateEngagementDataMutation,
  useLazyGetEngagementDataQuery,
  useListEngagementsFromCompanyIDMutation,
  useReassignCompanyMutation,
} from "../../../api/engagmentAPI";
import { useEffect, useState } from "react";
import { useUsers } from "../../Users/useUsers";

const EngagementDataProvider = ({ children }) => {
  const { user } = useUsers();
  const isLoadingUser = user?.data?.activeEngagementID === undefined;
  const [status, setStatus] = useState({});
  const [isGettingStatus, setIsGettingStatus] = useState(false);

  const [
    lazyGetEngagementData,
    {
      data: lazyEngagementData,
      isLoading: isLazyLoadingEngagementData,
      error: lazyGetEngagementDataError,
    },
  ] = useLazyGetEngagementDataQuery();

  const {
    data: engagementData,
    isLoading: isLoadingEngagementData,
    error: getEngagementDataError,
    refetch: refetchEngagementData,
  } = useGetEngagementDataQuery(user.data.activeEngagementID);

  const [
    listEngagementsFromCompanyID,
    { isLoading: isLoadingCompanyEngagementList },
  ] = useListEngagementsFromCompanyIDMutation();

  const [
    createEngagementData,
    { isLoading: isCreatingEngagementData, error: createEngagementDataError },
  ] = useCreateEngagementDataMutation();

  const {
    data: engagementList,
    isLoading: isLoadingEngagementList,
    error: listEngagementsError,
  } = useListTaxEngagementsQuery();

  const [
    updateEngagementData,
    { isLoading: isUpdatingEngagementData, error: updateEngagementDataError },
  ] = useUpdateEngagementDataMutation();

  const [
    reassignCompany,
    { isLoading: isReassigningCompany, error: reassignCompanyError },
  ] = useReassignCompanyMutation();

  useEffect(() => {
    setIsGettingStatus(true);

    const profile = engagementData?.status.hasOwnProperty("portal-profile")
      ? engagementData.status["portal-profile"].complete
      : false;
    const wages = engagementData?.status.hasOwnProperty("portal-wages")
      ? engagementData.status["portal-wages"].complete
      : false;
    const projects = engagementData?.status.hasOwnProperty("portal-projects")
      ? engagementData.status["portal-projects"].complete
      : false;
    const accounting = engagementData?.status.hasOwnProperty(
      "portal-accounting",
    )
      ? engagementData.status["portal-accounting"].complete
      : false;
    const documents = engagementData?.status.hasOwnProperty(
      "portal-documentation",
    )
      ? engagementData.status["portal-documentation"].complete
      : false;
    const submitted = engagementData?.status.hasOwnProperty("portal-submitted")
      ? engagementData.status["portal-submitted"].complete
      : false;
    const approved = engagementData?.status.hasOwnProperty("portal-approved")
      ? engagementData.status["portal-approved"].complete
      : false;
    setStatus({
      "Taxpayer Profile": profile,
      Wages: wages,
      Accounting: accounting,
      Projects: projects,
      Documents: documents,
      Submitted: submitted,
      Statistics: false,
      Approved: approved,
    });
    setIsGettingStatus(false || engagementData?.status === undefined);
  }, [engagementData?.status]);

  const onListEngagementsFromCompanyID = async () => {
    const companyID =
      user.role === "admin" ? engagementData.companyID : user.companyID;
    const listEngagementsFromCompanyIDResponse =
      await listEngagementsFromCompanyID(companyID);
    const data = listEngagementsFromCompanyIDResponse?.data ?? [];
    const companyEngagements = data
      .filter((engagement) => {
        return (
          engagement?.status?.hasOwnProperty("portal-deliverables") &&
          engagement?.status["portal-deliverables"]?.hasOwnProperty(
            "complete",
          ) &&
          engagement?.status["portal-deliverables"].complete === true
        );
      })
      .map((engagement) => {
        return {
          id: engagement.engagementID,
          name: engagement.engagementName,
        };
      });
    return companyEngagements;
  };

  const markAsTracked = async (value, key) => {
    const body = {
      tracker: {
        ...engagementData?.tracker,
        [key]: {
          status: value,
          uploadedBy: value === "INCOMPLETE" ? "" : user.fullName,
          lastUpdated: value === "INCOMPLETE" ? "" : Date.now(),
        },
      },
    };

    await updateEngagementData({
      engagementID: engagementData?.engagementID,
      body,
    });
  };

  let errors = {};
  const hasError = (i) => i !== undefined;
  if (hasError(getEngagementDataError) && !isLoadingUser)
    errors = { ...errors, getEngagementDataError };
  if (hasError(listEngagementsError))
    errors = { ...errors, listEngagementsError };
  if (hasError(updateEngagementDataError))
    errors = { ...errors, updateEngagementDataError };
  if (hasError(createEngagementDataError))
    errors = { ...errors, createEngagementDataError };
  if (hasError(lazyGetEngagementDataError))
    errors = { ...errors, lazyGetEngagementDataError };

  const value = {
    // Data
    status,
    engagementData,
    lazyEngagementData,
    engagementList,

    // Functions
    refetchEngagementData,
    createEngagementData,
    updateEngagementData,
    lazyGetEngagementData,
    onListEngagementsFromCompanyID,
    markAsTracked,
    reassignCompany,

    // Loading States

    isLoadingEngagementData: isLoadingEngagementData || isLoadingUser,
    isLoadingCompanyEngagementList,
    isLazyLoadingEngagementData,
    isCreatingEngagementData,
    isUpdatingEngagementData,
    isLoadingEngagementList,
    isGettingStatus: isLoadingEngagementData || isGettingStatus,
    isReassigningCompany,

    // Errors
    engagementDataErrors: errors,
  };

  return (
    <EngagementDataContext.Provider value={value}>
      {children}
    </EngagementDataContext.Provider>
  );
};
export default EngagementDataProvider;
