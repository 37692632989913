export const TaxpayerProfileLoading = () => {
  return (
    <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
        <div className={"rounded-md p-4 max-w-sm w-full mx-auto sm:col-span-3 sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-sprxGrey rounded"></div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 max-w-sm w-full mx-auto sm:col-span-3 sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-sprxGrey rounded"></div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 max-w-sm w-full mx-auto sm:col-span-3 sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-sprxGrey rounded"></div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 max-w-sm w-full mx-auto sm:col-span-3 sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-sprxGrey rounded"></div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-6"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="h-2 bg-sprxGrey rounded"></div>
                    <div className="grid grid-cols-6 gap-2">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                    <div className="grid grid-cols-6 gap-2">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-2"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-2"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-2"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto col-span-6"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-4 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-6"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-6"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-6"></div>
                    </div>
                </div>
            </div>
        </div>
        
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-3"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-2"></div>

                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
        
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
        <div className={"rounded-md p-4 w-full w-full mx-auto sm:col-span-4"}>
            <div className="animate-pulse flex space-x-4">
                <div className="flex-1 space-y-6 py-1">
                    <div className="grid grid-cols-3 gap-4">
                          <div className="h-2 bg-sprxGrey rounded col-span-1"></div>
                          <div className="h-2 bg-sprxGrey rounded col-span-3"></div>

                    </div>
                </div>
            </div>
        </div>
  </div>
 ); 
}
