import * as React from "react";
import { SquareButton } from "../../components/SquareButton";
import PeopleIcon from "@mui/icons-material/People";
import InventoryIcon from "@mui/icons-material/Inventory";
import PermContactCalendarIcon from "@mui/icons-material/PermContactCalendar";
import PersonalVideoIcon from "@mui/icons-material/PersonalVideo";

interface ExpenseSchedulesProps {
  isDownloading: boolean;
  onDownloadSchedule: (templateName: string) => void;
}

const iconClassName = "h-20 w-20 text-blue-500";

const TEMPLATES = [
  {
    title: "Wages",
    icon: <PeopleIcon className={iconClassName} sx={{ fontSize: 60 }} />,
    template: "Wage Review Template.xlsx",
  },
  {
    title: "Supply",
    icon: <InventoryIcon className={iconClassName} sx={{ fontSize: 60 }} />,
    template: "Supply Review Template.xlsx",
  },
  {
    title: "Contract Research",
    icon: (
      <PermContactCalendarIcon
        className={iconClassName}
        sx={{ fontSize: 60 }}
      />
    ),
    template: "CR Review Template.xlsx",
  },
  {
    title: "Computer Lease",
    icon: <PersonalVideoIcon className={iconClassName} sx={{ fontSize: 60 }} />,
    template: "Comp Lease Review Template.xlsx",
  },
];
export const ExpenseSchedules: React.FC<ExpenseSchedulesProps> = ({
  isDownloading,
  onDownloadSchedule,
}) => {
  const [downloadIndex, setDownloadIndex] = React.useState<number>(0);
  return (
    <div className="h-72vh grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 py-32 px-8 ">
      {TEMPLATES.map((template, index) => {
        return (
          <SquareButton
            key={index}
            id={index.toString() + template.title}
            title={template.title}
            icon={template.icon}
            loading={isDownloading && downloadIndex === index}
            onClick={() => {
              setDownloadIndex(index);
              onDownloadSchedule(template.template);
            }}
          />
        );
      })}
    </div>
  );
};
