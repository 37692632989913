export const getDefaultEditProjectFormFields = (project) => {
  const name = project?.hasOwnProperty("Project Name")
    ? project["Project Name"]
    : "";
  const location = project?.hasOwnProperty("Project Location")
    ? project["Project Location"]
    : "NJ";
  const extendedDescription = project?.hasOwnProperty("extendedDescription")
    ? project.extendedDescription
    : "";
  const purpose = project?.hasOwnProperty("purpose") ? project.purpose : "";
  const experimentation = project?.hasOwnProperty("experimentation")
    ? project.experimentation
    : "";
  const uncertainty = project?.hasOwnProperty("uncertainty")
    ? project.uncertainty
    : "";
  const nature = project?.hasOwnProperty("nature") ? project.nature : "";
  const ID = project?.hasOwnProperty("ID") ? project.ID : "";

  return {
    name,
    location,
    extendedDescription,
    purpose,
    experimentation,
    uncertainty,
    nature,
    ID,
  };
};
