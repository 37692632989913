import { AdminEngagementListLoading } from "../components/AdminEngagementListLoading";
import { motion } from "framer-motion";
import { useState } from "react";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { CreateDataSpaceForm } from "./CreateDataSpaceForm";
import { useDataSpaces } from "../../../rtk/DataSpaces/useDataSpaces";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { isDev } from "../../R&D/utils";
import { CreateDataSpaceModel } from "../types/dataSpace";
import { StarIcon as OutlinedStar } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";
import { EditDataSpaceForm } from "./EditDataSpaceForm";

export const DataSpacesTable = () => {
  const dispatch = useDispatch();
  const [filter, setFilter] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editID, setEditID] = useState<number | null>(null);
  const [isFavoriting, setIsFavoriting] = useState<boolean>(false);
  const [isSettingActive, setIsSettingActive] = useState<boolean>(false);
  const {
    adminDataSpacesList,
    isListingAdminDataSpaces,
    createDataSpace,
    dataSpacesErrors,
    isCreatingDataSpace,
    dataSpace,
  } = useDataSpaces();

  const { companies, isListingCompanies } = useCompanyData();

  const { user, updateTaxUser, getTaxUser } = useUsers();

  const { engagementData, isGettingEngagementData, engagementDataErrors } =
    useEngagementData();

  const onCreateDataSpace = async (model: CreateDataSpaceModel) => {
    const { userID } = user;
    const bucketName = engagementData?.s3Bucket?.name;
    const body = {
      ...model,
      bucketName,
      userID,
    };
    const createDataSpaceResponse = await createDataSpace({ body });
    if (isDev) console.log({ createDataSpaceResponse });
    if (!dataSpacesErrors?.createDataSpaceError) {
      const body = {
        activeDataSpaceID: createDataSpaceResponse.data.params.id,
        isUsingDataSpace: true,
        engagementType: "DS",
      };
      await updateTaxUser({
        userID: user.userID,
        body: body,
      });
      const updatedUser = await getTaxUser(user.userID);
      dispatch(setUserData(updatedUser.data));
    } else {
      // TODO: Handle error
    }
    setOpen(false);
  };

  const ErrorMessage = () => (
    <div className="flex h-3/5 min-h-80vh flex-col items-center justify-center">
      <h1 className="text-xl font-semibold text-gray-900">
        Error fetching your data spaces.
      </h1>
      <p>
        Refresh the page and contact the SPRX team if you are still having
        issues.
      </p>
    </div>
  );
  if (Object.keys(dataSpacesErrors || {}).length > 0) return <ErrorMessage />;
  if (Object.keys(engagementDataErrors || {}).length > 0)
    return <ErrorMessage />;
  if (dataSpacesErrors?.listingAdminDataSpaceError) return <ErrorMessage />;

  const loading =
    isListingAdminDataSpaces || isGettingEngagementData || isListingCompanies;
  if (loading) return <AdminEngagementListLoading />;

  const activeDataSpace = adminDataSpacesList?.find(
    (a) => a?.id === user?.data?.activeDataSpaceID,
  );

  const favoritedDataSpaces = adminDataSpacesList?.filter(
    (a) => user?.favorites?.DS?.includes(a?.id) && a?.id !== activeDataSpace.id,
  );

  const renderedDataSpaceList = [
    ...([activeDataSpace] ?? []),
    ...([...favoritedDataSpaces] ?? []),
    ...adminDataSpacesList.filter(
      (a) =>
        a?.id !== user?.data?.activeDataSpaceID &&
        !user?.favorites?.DS?.includes(a?.id),
    ),
  ];

  const onFavoriteEngagement = async (type, id) => {
    const userID = user.userID;
    let favorites = [
      ...(user?.favorites?.hasOwnProperty(type) ? user?.favorites[type] : []),
    ];

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      favorites.splice(index, 1);
    } else {
      favorites.push(id);
    }
    const body = {
      favorites: {
        ...user.favorites,
        [type]: favorites,
      },
    };

    const updateUserResponse = await updateTaxUser({ userID, body });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  const handleSetActiveClick = async (index, ds) => {
    setIsSettingActive(true);
    setEditIndex(index);
    const body = {
      activeDataSpaceID: ds?.id,
      isUsingDataSpace: true,
      engagementType: "DS",
    };
    await updateTaxUser({
      userID: user.userID,
      body: body,
    });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
    setEditIndex(null);
    setIsSettingActive(false);
  };

  const handleFavoriteClick = async (index, ds) => {
    setIsFavoriting(true);
    setEditIndex(index);
    await onFavoriteEngagement("DS", ds.id);
    setEditIndex(null);
    setIsFavoriting(false);
  };

  return (
    <div className="flex-1">
      <div className="">
        {/* Admin engagement table */}
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="items-between flex flex-row items-end justify-between">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search Data Space</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <motion.button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpen(true)}
              >
                Create Data Space
              </motion.button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Data Space Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Client Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Assigned Company
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Favorite</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {renderedDataSpaceList
                        ?.map((a) => ({ ...a }))
                        ?.filter((a) => {
                          const k = filter.toLowerCase();
                          return a?.dataSpaceName?.toLowerCase()?.includes(k);
                        })
                        ?.map((u, index) => (
                          <tr
                            key={u.id}
                            className={
                              u?.id === user?.data?.activeDataSpaceID
                                ? "bg-gray-100"
                                : ""
                            }
                          >
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u?.dataSpaceName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u?.companyName}
                            </td>
                            <th
                              scope="col"
                              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                              {
                                companies.find(
                                  (c) => c.companyID === u?.companyID,
                                )?.companyName
                              }
                            </th>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkGreen hover:text-green-400"
                                onClick={async () => {
                                  setEditID(u.id);
                                  setEditOpen(true);
                                }}
                              >
                                Edit
                              </motion.button>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                onClick={async () => {
                                  await handleSetActiveClick(index, u);
                                }}
                              >
                                {isSettingActive && index === editIndex ? (
                                  <div className="w-full">
                                    <p className="h-2 w-16 animate-pulse rounded bg-sprxGrey"></p>
                                  </div>
                                ) : (
                                  "Set Active"
                                )}
                              </motion.button>
                            </td>
                            <td className="px-3 py-4">
                              {isFavoriting && index === editIndex ? (
                                <div className="w-full">
                                  <p className="h-2 w-6 animate-pulse rounded bg-sprxGrey"></p>
                                </div>
                              ) : (
                                <>
                                  {user?.favorites?.DS?.includes(u.id) ? (
                                    <StarIcon
                                      className={`h-7 w-7 align-middle text-sprxYellow`}
                                      onClick={async () => {
                                        await handleFavoriteClick(index, u);
                                      }}
                                    />
                                  ) : (
                                    <OutlinedStar
                                      className={`ml-0.5 h-6 w-6 align-middle text-sprxTaxLightGray`}
                                      onClick={async () => {
                                        await handleFavoriteClick(index, u);
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <EditDataSpaceForm
            open={editOpen}
            setOpen={setEditOpen}
            user={user}
            dataSpace={adminDataSpacesList?.find((d) => d.id === editID)}
          />
          <CreateDataSpaceForm
            isCreatingDataSpace={isCreatingDataSpace}
            user={user}
            open={open}
            companies={companies}
            setOpen={setOpen}
            onCreate={onCreateDataSpace}
            isAdmin={true}
          />
        </div>
      </div>
    </div>
  );
};
