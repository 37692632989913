import { FlatlistSelectionPane } from "../../ReviewPortal/components";

interface EmptyCommentsProps {
  height?: string;
  onClickAddCallback?: () => void;
}

export const EmptyComments = ({
  height,
  onClickAddCallback,
}: EmptyCommentsProps) => {
  const h = height ?? "h-80vh";

  return (
    <div className="grid grid-cols-12 rows-1">
      {/* Nav List Display*/}
      <div className={`col-span-3 ${h} overflow-auto border rounded-lg`}>
        <FlatlistSelectionPane
          searchTitle={"Threads"}
          items={[]}
          setSelectedCallback={(item: any) => null}
          selectedItem={undefined}
          nameKey={"title"}
          idKey={"commentID"}
          width={"w-full"}
          primaryItemsTitle={"Open"}
          onClickAddCallback={onClickAddCallback}
        />
      </div>
      {/* Comment Thread Display*/}
      <div className={`col-span-9 ml-8 pr-2 mt-2 ${h} overflow-auto`}>
        <div className="flex h-full justify-center items-center">
          <>There are no comments at this time</>
        </div>
      </div>
    </div>
  );
};
