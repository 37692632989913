import { XIcon } from "@heroicons/react/outline";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useState, useMemo } from "react";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { getReviewColumns, getDownloadFileName } from "../utils";
import { DataTable } from "../../../../ui-components";
import { FlatfileSpace } from "../../../../ui-components/FlatfileSpace";
import { EditEmployeeProjects } from "./EditEmployeeProjects";
import { getNamespaceType } from "../../UploadPortal/utils/getNamespaceType";
import { useCallback } from "react";
import * as React from "react";

const WAGES_REVIEW = "WAGES_REVIEW";

export const WageCard = () => {
  const userAPI = useUsers();
  const dataAPI = useDataArray();
  const engagementDataAPI = useEngagementData();
  const fileAPI = useFile();

  const [open, setOpen] = useState(false);
  const [showFlatfileModal, setShowFlatfileModal] = useState(false);
  const [selectedID, setSelectedID] = useState(null);

  const [columnDef] = useState([
    {
      headerName: "",
      field: "ID",
      width: 64,
      // Employee Drill Down Button
      cellRenderer: (rowData) => {
        return (
          <div className="hidden sm:block absolute top-2 ">
            <button
              data-tip="project-memo-upload"
              type="button"
              className="rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
              onClick={() => {
                // Set Render Index for Employee Drill Down Component
                setSelectedID(rowData.value);
                setOpen(true);
              }}
            >
              {/** COULDNT FIND ICON */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 24 24"
                fill="currentColor"
                className="w-6 h-6"
              >
                <path
                  fillRule="evenodd"
                  d="M15.75 2.25H21a.75.75 0 01.75.75v5.25a.75.75 0 01-1.5 0V4.81L8.03 17.03a.75.75 0 01-1.06-1.06L19.19 3.75h-3.44a.75.75 0 010-1.5zm-10.5 4.5a1.5 1.5 0 00-1.5 1.5v10.5a1.5 1.5 0 001.5 1.5h10.5a1.5 1.5 0 001.5-1.5V10.5a.75.75 0 011.5 0v8.25a3 3 0 01-3 3H5.25a3 3 0 01-3-3V8.25a3 3 0 013-3h8.25a.75.75 0 010 1.5H5.25z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </div>
        );
      },
    },
    ...getReviewColumns("Wages"),
  ]);

  const completionsCount = useMemo(() => {
    return (
      dataAPI.wageData
        .flatMap((employee) => Object.values(employee?.completions ?? {}))
        .filter((completion) => completion?.isLoading)?.length ?? 0
    );
  }, [dataAPI.wageData]);

  const onRowUpdate = useCallback(
    async (newData) => {
      await dataAPI.updateLineItem(newData, "WAGES_REVIEW");
    },
    [dataAPI]
  );

  return (
    <div className="px-8 pb-8 z-40">
      <div className="flex flex-col w-full items-center justify-center">
        <div
          className={`flex flex-row ${
            completionsCount > 0 ? "justify-between" : "justify-end"
          } items-center w-full`}
        >
          {completionsCount > 0 ? (
            <p>
              {completionsCount} completion{completionsCount > 1 ? "s" : ""}{" "}
              processing
            </p>
          ) : null}
          <div className="flex flex-row justify-end w-full">
            <button
              className="mr-8 mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
              onClick={() => setShowFlatfileModal(true)}
            >
              Upload
            </button>
            <button
              className="mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
              onClick={() =>
                fileAPI.onDownloadDocument(getDownloadFileName("Wages"))
              }
            >
              {fileAPI.isGettingBulkEditFile ? (
                <div className="w-full">
                  <p className="animate-pulse bg-white rounded h-2 w-16"></p>
                </div>
              ) : (
                "Download"
              )}
            </button>
          </div>
          {/* <div className="flex flex-row justify-end w-full">
            <button
              className="min-h-full mr-8 mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
              onClick={() => setShowFlatfileModal(true)}
            >
              {showFlatfileModal ? (
                <div className="w-full">
                  <p className="animate-pulse bg-white rounded h-2 w-16"></p>
                </div>
              ) : (
                "Upload"
              )}
            </button>
            <button
              className="min-h-full mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
              onClick={() =>
                fileAPI.onDownloadDocument(getDownloadFileName("Wages"))
              }
            >
              {fileAPI.isGettingBulkEditFile || true ? (
                <p className="animate-pulse bg-white rounded h-2 w-16"></p>
              ) : (
                "Download"
              )}
            </button>
          </div> */}
        </div>
        <div className="w-full h-1 min-h-70vh">
          <DataTable
            columns={columnDef}
            data={dataAPI.wageData.map((o) => ({
              ...o,
              processCount: Object.values(o?.completions ?? {}).filter(
                (completion) => completion?.isLoading
              )?.length,
            }))}
            isLoading={false}
            onRowUpdate={(newData) => onRowUpdate(newData)}
          />
        </div>
      </div>
      <FlatfileSpace
        showSpace={showFlatfileModal}
        setShowSpace={setShowFlatfileModal}
        namespace={getNamespaceType(WAGES_REVIEW)}
        engagementID={engagementDataAPI?.engagementData?.engagementID}
        companyName={engagementDataAPI?.engagementData?.companyName}
        type={WAGES_REVIEW}
        name={userAPI.user.fullName}
        onCloseCallback={async () => {
          await dataAPI.refreshData(WAGES_REVIEW);
          setShowFlatfileModal(false);
        }}
      />
      <AnimatePresence>
        {open && selectedID && (
          <Dialog
            className="relative z-40"
            static
            layoutId={selectedID.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            as={motion.div}
            open={open}
            onClose={setOpen}
          >
            <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <motion.div className="fixed inset-0 z-10 overflow-y-auto">
              <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*Modal content */}
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all min-h-94vh min-w-95vw max-w-95vw">
                  <motion.div>
                    {/*Modal title, description, and close button */}
                    <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                      <motion.button
                        type="button"
                        whileHover={{ scale: 1.05 }}
                        whileTap={{ scale: 0.95 }}
                        className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                        onClick={() => {
                          setOpen(false);
                          setSelectedID(null);
                        }}
                      >
                        <XIcon
                          className="h-6 w-6"
                          aria-hidden={selectedID?.hideCloseIcon}
                        />
                      </motion.button>
                    </motion.div>

                    <motion.div className="text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-3xl font-medium leading-6 text-gray-900 text-left p-4"
                      >
                        AI Generated Activities
                      </Dialog.Title>
                      <motion.div className="text-sm text-gray-500 px-4 py-2 text-left" />
                    </motion.div>
                    <motion.div className="py-8">
                      {/*Modal main content */}
                      <EditEmployeeProjects id={selectedID} />
                    </motion.div>
                  </motion.div>
                </Dialog.Panel>
              </motion.div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </div>
  );
};
