import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import * as React from "react";

export const OpenActionCardWrapper = ({
  open,
  setOpen,
  selectedID,
  disableOnClickOutsideClose,
  secondaryPanel,
  children,
}) => {
  return (
    <AnimatePresence>
      {open && selectedID && (
        <Dialog
          className="relative z-40"
          static
          layoutId={selectedID.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={
            disableOnClickOutsideClose ? () => null : () => setOpen(false)
          }
          onClick={(event) => {
            // Prevents click from closing the modal if disableOnClickOutsideClose is true
            if (disableOnClickOutsideClose) {
              event.stopPropagation();
            }
          }}
        >
          {/* Overlay */}
          <Dialog.Overlay
            as={motion.div}
            className="fixed inset-0 bg-gray-500 bg-opacity-75"
            onClick={(event) => {
              // Prevents click from closing the modal if disableOnClickOutsideClose is true
              if (disableOnClickOutsideClose) {
                event.stopPropagation();
              }
            }}
          />
          <Dialog.Panel>{children}</Dialog.Panel>
          {secondaryPanel ? secondaryPanel : null}
        </Dialog>
      )}
    </AnimatePresence>
  );
};

export const DialogTitle = ({ title }) => {
  return (
    <Dialog.Title
      as="h3"
      className="text-3xl font-medium leading-6 text-gray-900 text-left p-4"
    >
      {title}
    </Dialog.Title>
  );
};
