import { EngagementListLoading } from "./EngagementListLoading";

export const PortalContainerLoading = () => {
  return (
    <main className="flex-1 overflow-hidden">
      <div className="pb-6">
        {/*Top bar and logo */}
        <div className="flex flex-row items-center justify-between mx-auto pt-2 px-4 mt-4">
          <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>

        <div className="mx-auto pl-4">
          {/*Welcome message with icon and submit box*/}
          <div className="flex w-1/2 py-4">
            <div className="flex flex-col w-full rounded-2xl p-4 bg-sprxClientPortalLightTeal">
              <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/4"></div>
              <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-full"></div>
              <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-full"></div>
              <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
            </div>
          </div>
          {/*Divider */}

          <EngagementListLoading />
        </div>
      </div>
    </main>
  );
};
