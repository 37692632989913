import UsersContext from "./UsersContext";
import { isDev } from "../../main/R&D/utils";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "../../auth/rtk/userSlice";
import {
  useListUsersQuery,
  useCreateUserMutation,
  useCreateDeliverablesUserMutation,
  useUpdateTaxUserMutation,
  useGetTaxUserMutation,
} from "../../api/userAPI";

const UsersProvider = ({ children }) => {
  const dispatch = useDispatch();
  const user = useSelector(({ auth }) => auth.user);
  const [inGodMode, setInGodMode] = useState(false);
  const [impersonatedUser, setImpersonatedUser] = useState(null);

  const {
    data: users,
    isLoading: isListingUsers,
    error: listUsersError,
  } = useListUsersQuery();

  const [createUser, { isLoading: isCreatingUser, error: createUserError }] =
    useCreateUserMutation();

  const [
    createDeliverablesUser,
    {
      isLoading: isCreatingDeliverablesUser,
      error: createDeliverablesUserError,
    },
  ] = useCreateDeliverablesUserMutation();

  const [updateTaxUser, { isLoading: isUpdatingUser, error: updateUserError }] =
    useUpdateTaxUserMutation();

  const [getTaxUser, { error: getTaxUserError, isLoading: isGettingUser }] =
    useGetTaxUserMutation();

  const impersonate = (user) => {
    dispatch(setUserData(user));
    setImpersonatedUser({
      ...user,
      GOD_MODE: true,
    });
    setInGodMode(true);
  };

  let errors = {};
  if (getTaxUserError) errors.getTaxUserError = getTaxUserError;
  if (updateUserError) errors.updateUserError = updateUserError;
  if (createUserError) errors.createUserError = createUserError;
  if (createDeliverablesUserError)
    errors.createDeliverablesUserError = createDeliverablesUserError;
  if (listUsersError) errors.listUsersError = listUsersError;
  Object.keys(errors).length > 0 &&
    isDev &&
    console.error({ UsersProvider: errors });

  const value = {
    user,
    users,
    isListingUsers,
    impersonatedUser,

    createUser: inGodMode ? null : createUser,
    createDeliverablesUser: inGodMode ? null : createDeliverablesUser,
    updateTaxUser,
    getTaxUser,
    setInGodMode,
    impersonate,

    isCreatingUser,
    isUpdatingUser,
    isGettingUser,
    isCreatingDeliverablesUser,
    inGodMode,

    createUserError,
    updateUserError,
    getTaxUserError,
    listUsersError,
    createDeliverablesUserError,
    UserProviderErrors: errors,
  };

  return (
    <UsersContext.Provider value={value}>{children}</UsersContext.Provider>
  );
};
export default UsersProvider;
