import { motion } from "framer-motion";
import { classNames } from "../../../../R&D/utils";
export const InformationGatheringLoading = () => {
  return (
    <>
      <main className="flex-1 lg:overflow-hidden">
        <div className="">
          {/*Top bar and logo */}
          <div className="mx-auto flex justify-between px-4 sm:px-6 md:px-8">
            <div className="ml-2 mt-2 mb-2 h-2 w-1/5 animate-pulse rounded bg-sprxGrey"></div>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          <div className="mx-auto px-4 sm:px-6 md:px-8">
            {/*Welcome message with icon and submit box*/}

            <div className="flex w-full py-4">
              <div className="flex w-8/12 flex-col rounded-2xl bg-sprxClientPortalLightTeal p-4">
                <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
              </div>
              <div className="-ml-64 flex">
                <img
                  style={{ width: 410.25, height: 136.125 }}
                  src={"assets/images/Dashboard_Illustration.svg"}
                  alt="SPRX.tax"
                />
              </div>
              {/*Conditional submit box */}
            </div>

            {/* <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4 w-full p-4"> */}
            {/*Left column */}
            {/*Right column */}
            <div className="col-span-3 flex w-full">
              <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-3">
                {Array.from(Array(6)).map((item, index) => (
                  <motion.div
                    key={`upload-loading-item-${index}`}
                    className={
                      "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                    }
                  >
                    <motion.div
                      className={classNames(
                        "flex flex-1 flex-col items-center justify-between bg-white p-4 pt-6",
                      )}
                    >
                      <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                      <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                      <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>

                      <motion.button
                        className={classNames(
                          "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                          "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-3/4",
                        )}
                      ></motion.button>
                    </motion.div>
                  </motion.div>
                ))}
              </div>
            </div>
            {/* </div> */}
          </div>
        </div>
      </main>
    </>
  );
};
