interface DataSpaceDefaultValues {
  email: string;
  fullName: string;
  message: string;
}

export const getCreateDataSpaceUserDefaultValues =
  (): DataSpaceDefaultValues => {
    return {
      email: "",
      fullName: "",
      message: "",
    };
  };
