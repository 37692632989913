import React from "react";
import classNames from "classnames";

const ButtonContainer = (props) => {
  return (
    <div
      className={classNames(
        "flex flex-row justify-center w-40",
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export default ButtonContainer;
