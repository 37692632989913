import { baseAPI } from "../baseAPI";

interface SendAllocationLetterParams {
  engagementID: string;
  body: {
    senderEmail: string;
  };
}

const energyEngagementAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createEnergyEngagement: builder.mutation({
      query: (body) => ({
        url: `/energy/engagements`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: ["EnergyEngagementList"],
    }),
    getEnergyEngagement: builder.query({
      query: (engagementID: string) => ({
        url: `/energy/engagements/${engagementID}`,
        method: "GET",
        body: {},
      }),
      providesTags: (result, error, id) => ["EnergyEngagementList"],
    }),
    updateEnergyEngagement: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/energy/engagements/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: ["EnergyEngagementList", "EnergyEngagement"],
    }),
    listAllEnergyEngagements: builder.query({
      query: () => ({
        url: `/energy/list-engagements`,
        method: "GET",
        body: {},
      }),
      providesTags: ["EnergyEngagementList"],
    }),
    listModelingEngagements: builder.query({
      query: (queryParams) => {
        const params = new URLSearchParams();
        Object.keys(queryParams).forEach((key) => {
          const value = queryParams[key];
          if (value) params.append(key, value);
        });
        return {
          url: `/energy/list-modeling-engagements?${params.toString()}`,
          method: "GET",
          body: {},
        };
      },
      providesTags: (result, error, id) => ["EnergyEngagementList"],
    }),
    listCertificationEngagements: builder.query({
      query: ({ userID }: { userID: string }) => ({
        url: `/energy/list-certification-engagements/${userID}`,
        method: "GET",
        body: {},
      }),
      providesTags: (result, error, id) => ["EnergyEngagementList"],
    }),
    listEnergyEngagementsFromCompanyID: builder.mutation({
      query: ({ companyID, body }: { companyID: string; body: any }) => ({
        url: `/energy/list-from-company-id/${companyID}`,
        method: "GET",
        body: body,
      }),
      invalidatesTags: (result, error, { companyID }) => [
        { type: "EnergyEngagementList", companyID },
      ],
    }),
    listEnergyEngagementsFromCompanyId: builder.query({
      query: ({ companyID }: { companyID: string }) => ({
        url: `/energy/list-from-company-id/${companyID}`,
        method: "GET",
        body: {},
      }),
      // invalidatesTags: (result, error, { companyID }) => [
      //   { type: "EnergyEngagementList", companyID },
      // ],
    }),
    compute179D: builder.mutation({
      query: ({ engagementID }: { engagementID: string }) => ({
        url: `/energy/compute-179D/${engagementID}`,
        method: "PUT",
        body: {},
      }),
      invalidatesTags: ["EnergyEngagementList"],
    }),
    digestDocuments: builder.mutation({
      query: ({ engagementID }: { engagementID: string }) => ({
        url: `/energy/digest-documentation/${engagementID}`,
        method: "PUT",
        body: {},
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EnergyEngagementList", engagementID },
      ],
    }),
    generateEnergyDeliverables: builder.mutation({
      query: ({ engagementID, body }: { engagementID: string; body: any }) => ({
        url: `/energy/generate-deliverables/${engagementID}`,
        method: "PUT",
        body: body,
      }),
    }),
    dispatchCall: builder.mutation({
      query: ({ engagementID }: { engagementID: string }) => ({
        url: `/energy/vapi/initiate-call/${engagementID}`,
        method: "POST",
        body: {},
      }),
    }),
    sendAllocationLetter: builder.mutation({
      query: ({ engagementID, body }: SendAllocationLetterParams) => ({
        url: `/energy/docusign/send-allocation-letter/${engagementID}`,
        method: "POST",
        body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEnergyEngagementMutation,
  useGetEnergyEngagementQuery,
  useLazyGetEnergyEngagementQuery,
  useUpdateEnergyEngagementMutation,
  useListAllEnergyEngagementsQuery,
  useListModelingEngagementsQuery,
  useListEnergyEngagementsFromCompanyIDMutation,
  useListEnergyEngagementsFromCompanyIdQuery,
  useListCertificationEngagementsQuery,
  useCompute179DMutation,
  useDigestDocumentsMutation,
  useGenerateEnergyDeliverablesMutation,
  useDispatchCallMutation,
  useSendAllocationLetterMutation,
} = energyEngagementAPI;
