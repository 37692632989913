import { useEffect, useMemo, useCallback, useRef } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { CompletionsLoading } from "../components";
import { yupResolver } from "@hookform/resolvers/yup";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { LightningBoltIcon } from "@heroicons/react/outline";
import {
  getDefaultEmployeeProjectFields,
  getEmployeeProjectFormShape,
  formatEmployeeProjectForSubmission,
} from "./utils";
import { classNames, isDev } from "../../utils";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { debounce } from "lodash";

const BASE_STYLE =
  "block w-full rounded-md border-0 py-1.5 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-indigo-600 sm:text-sm sm:leading-6";

export const EditEmployeeProjectsForm = (props) => {
  const fetchedRef = useRef(false);
  const { employee, id, updateSelectedItem, isSaving } = props;
  const { generateEmployeeAllocations, isGeneratingEmployeeAllocations } =
    useCompute();
  const { refreshData } = useDataArray();

  // Form State
  const { control, handleSubmit, reset, setValue } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultEmployeeProjectFields(employee?.completions[id]);
    }, [employee?.completions, id]),
    resolver: yupResolver(getEmployeeProjectFormShape()),
  });

  // Update Form Values on Employee Change
  useEffect(() => {
    reset(getDefaultEmployeeProjectFields(employee?.completions[id]));
  }, [employee?.completions, id, reset]);

  // Update Form Values on Text Change
  const onAutoSave = debounce((data) => {
    updateSelectedItem(formatEmployeeProjectForSubmission(data));
  }, 2000);

  // Update Form Values on Text Change
  const handleFieldChange = useCallback(
    (name, value) => {
      setValue(name, value);
      handleSubmit((data) => onAutoSave(data))();
    },
    [setValue, handleSubmit, onAutoSave]
  );

  // Generate Employee Allocations
  useEffect(() => {
    async function wrapper() {
      if (fetchedRef.current) return;
      fetchedRef.current = true;
      await generateEmployeeAllocations();
    }
    if (employee?.completions[id].isLoading === true && !fetchedRef.current) {
      wrapper()
        .then((generateEmployeeAllocationResponse) => {
          if (isDev) console.log({ generateEmployeeAllocationResponse });
        })
        .catch((err) => {
          if (isDev) console.error({ err });
        });
    }
  }, [employee?.completions, generateEmployeeAllocations, id, refreshData]);

  // Loading State
  if (employee?.completions[id].isLoading === true) {
    return <CompletionsLoading />;
  }

  return (
    <div className="w-full rounded-lg shadow-xl border p-8 ml-4 min-h-full">
      <form
        data-test="edit-project-form"
        className="w-full"
        onSubmit={(e) => e.preventDefault()}
      >
        <div className="flex w-full flex-row justify-between">
          <h1 className="sm:col-span-6 font-bold">
            {employee["Employee Name"]}, {employee["Employee Department"]}
          </h1>
          <div className="sm:col-span-6 flex justify-evenly items-center">
            {isSaving ? (
              <LightningBoltIcon className="w-6 h-6 animate-pulse inline-block" />
            ) : (
              <div className="w-6"></div>
            )}
            <button
              className={classNames(
                "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                "sm:col-span-3 justify-end ml-4 px-2 py-2 border border-transparent text-xs leading-4",
                "font-medium rounded-xl shadow-sm text-white text-center focus:outline-none focus:ring-none"
              )}
              onClick={async () => {
                props.regenerateCompletions();
              }}
            >
              {isGeneratingEmployeeAllocations ? (
                <div className="w-full pl-12 py-1">
                  <p className="animate-pulse bg-white rounded h-2 w-16"></p>
                </div>
              ) : (
                "Re-Generate"
              )}
            </button>
          </div>
        </div>
        <div className="grid grid-cols-1 gap-y-1 gap-x-4 sm:grid-cols-12">
          <Controller
            name="actOne"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="extendedDescription"
                  className="block text-sm font-black text-gray-700"
                >
                  Research Activity One
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    onChange={(e) =>
                      handleFieldChange("actOne", e.target.value)
                    }
                    id="actOne"
                    name="actOne"
                    type="text"
                    className={BASE_STYLE + " h-10vh"}
                    whileHover={{ scale: 1.01 }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name="actTwo"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="actTwo"
                  className="block text-sm font-black text-gray-700"
                >
                  Research Activity Two
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    onChange={(e) =>
                      handleFieldChange("actTwo", e.target.value)
                    }
                    id="actTwo"
                    name="actTwo"
                    type="text"
                    className={BASE_STYLE + " h-10vh"}
                    whileHover={{ scale: 1.01 }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name="actThree"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="actThree"
                  className="block text-sm font-black text-gray-700"
                >
                  Research Activity Three
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    onChange={(e) =>
                      handleFieldChange("actThree", e.target.value)
                    }
                    id="actThree"
                    name="actThree"
                    type="text"
                    className={BASE_STYLE + " h-10vh"}
                    whileHover={{ scale: 1.01 }}
                  />
                </div>
              </div>
            )}
          />
          <Controller
            name="discovery"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-12">
                <label
                  htmlFor="discovery"
                  className="block text-sm font-black text-gray-700"
                >
                  Description of Discovery
                </label>
                <div className="mt-1">
                  <motion.textarea
                    {...field}
                    onChange={(e) =>
                      handleFieldChange("discovery", e.target.value)
                    }
                    id="discovery"
                    name="discovery"
                    type="text"
                    className={BASE_STYLE + " h-16vh"}
                    whileHover={{ scale: 1.01 }}
                  />
                </div>
              </div>
            )}
          />
        </div>
      </form>
    </div>
  );
};
