import { Cookies } from "react-cookie";
import { UploadPortalLoadingScreen } from "../UploadPortal/components";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useEffect, useState } from "react";
import { ReviewPortalLoadingScreen } from "../ReviewPortal/components/ReviewPortalLoadingScreen";
import UploadPortal from "../UploadPortal/UploadPortal";
import { ReviewPortal } from "../ReviewPortal/ReviewPortal";
import DataArrayProvider from "../../../rtk/R&D/DataArray/DataArrayProvider";

const cookies = new Cookies();

const Portal = ({ setActivePortalType }) => {
  const initialUploadStatus = cookies.get("sprx-status") !== "false";
  const {
    status,
    isGettingStatus,
    isGettingEngagementData,
    isLoadingEngagementData,
    isLazyLoadingEngagementData,
  } = useEngagementData();
  const [isUpload, setIsUpload] = useState(initialUploadStatus);

  useEffect(() => {
    if (isGettingStatus) return;
    const isUploading =
      status?.Submitted === false || status?.Approved === false;
    setIsUpload(isUploading);
    cookies.set("sprx-status", isUploading);
  }, [isGettingStatus, status]);

  const isLoading =
    isGettingStatus || isGettingEngagementData || isLoadingEngagementData;

  if (isLoading && isUpload) return <UploadPortalLoadingScreen />;
  if (isLoading) return <ReviewPortalLoadingScreen />;

  if (isUpload)
    return (
      <DataArrayProvider>
        <UploadPortal setActivePortalType={setActivePortalType} />
      </DataArrayProvider>
    );
  return (
    <DataArrayProvider>
      <ReviewPortal setActivePortalType={setActivePortalType} />
    </DataArrayProvider>
  );
};

export default Portal;
