import classNames from "classnames";
import Icon from "./Icon";

const SplitButton = (props) => {
  return (
    <span className="relative z-0 inline-flex shadow-sm rounded-md">
      <button
        type="button"
        className={classNames(
          props.firstButtonClassName && props.firstButtonClassName,
          "relative inline-flex border-r border-white items-center px-4 py-2 rounded-l-md text-sm font-medium focus:z-10 focus:outline-none",
          props.firstButtonColor ? `${props.firstButtonColor}` : "bg-sprxBlue",
          props.firstButtonHoverColor
            ? `hover:${props.firstButtonHoverColor}`
            : "hover:bg-blue-400",
          props.firstButtonTextColor ? props.firstButtonTextColor : "text-white"
        )}
        onClick={props.onClickFirstButton}
      >
        <Icon
          className={
            props.firstIconClassName
              ? props.firstIconClassName
              : "-ml-1 mr-2 h-5 w-5 text-white"
          }
          icon={props.firstIcon}
        />

        {props.firstTitle}
      </button>
      <button
        type="button"
        className={classNames(
          props.secondButtonClassName && props.secondButtonClassName,
          "-ml-px relative inline-flex border-l border-white items-center px-3 py-2 rounded-r-md text-xs font-medium focus:z-10 focus:outline-none",
          props.secondButtonColor
            ? `${props.secondButtonColor}`
            : "bg-sprxGrey",
          props.secondButtonHoverColor
            ? `hover:${props.secondButtonHoverColor}`
            : "hover:bg-gray-400",
          props.secondButtonTextColor
            ? props.secondButtonTextColor
            : "text-white"
        )}
        onClick={props.onClickSecondButton}
      >
        <Icon
          className={
            props.secondIconClassName
              ? props.secondIconClassName
              : "-ml-1 mr-2 h-5 w-5 text-white"
          }
          icon={props.secondIcon}
        />
        {props.secondTitle}
      </button>
    </span>
  );
};

export default SplitButton;
