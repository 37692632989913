import { DataTable } from "../../../../../ui-components";
import { performClick } from "../../../../R&D/utils";
import { useRef } from "react";

interface DocumentationProps {
  [key: string]: any;
}

export const Documentation = ({
  fields,
  documents,
  onDocumentUpdate,
  onDocumentUpload,
  isUploading,
  isDeleting,
}: DocumentationProps) => {
  const inputFileRef = useRef();
  return (
    <div>
      <div className="flex w-full flex-row justify-end">
        <div
          className="focus:ring-none mb-4 rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm"
          onClick={() => performClick("energy-file-dialog-mass-import")}
        >
          {isUploading ? (
            <div className="flex h-5 w-12 flex-col items-center justify-center">
              <p
                className={"h-2 w-full animate-pulse rounded-md bg-gray-100"}
              ></p>
            </div>
          ) : (
            "Upload"
          )}
        </div>
        <input
          ref={inputFileRef}
          id="energy-file-dialog-mass-import"
          multiple
          type="file"
          style={{ display: "none" }}
          accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain, .json, .xlsx, .xls, .doc, .docx, .ppt, .pptx, .txt, .HEIC, .eml, text/markdown"
          onChange={(e) => onDocumentUpload(e)}
        />
      </div>
      <div className="h-1 min-h-70vh w-full">
        <DataTable
          columns={fields}
          data={documents}
          onRowUpdate={async (newData: any) => await onDocumentUpdate(newData)}
        />
      </div>
    </div>
  );
};
