import * as React from "react";
import { stateAbbreviations } from "../../../ReviewPortal/utils/constants";

interface IDataDisplayProps {
  title: string;
  value: number;
}

export const DataDisplay = ({ title, value }: IDataDisplayProps) => {
  const displayTitle = stateAbbreviations.hasOwnProperty(title)
    ? stateAbbreviations[title]
    : title;
  return (
    <div key={title} className="">
      <dt className="text-sm font-medium text-gray-500 text-wrap w-36">
        {displayTitle}
      </dt>
      <dd className="mt-1 text-3xl font-semibold tracking-tight text-gray-900">
        {Math.round(value ?? 0).toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
          maximumFractionDigits: 0,
        })}
      </dd>
    </div>
  );
};
