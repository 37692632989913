import { Vapi } from "./Vapi";
import { useEffect, useState } from "react";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../../../rtk/Users/useUsers";
import { AllocationLetter } from "../../../types/engagement";
import { BuildingProfile } from "../../../types/buildingProfile";

export const VapiContainer = () => {
  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();
  const { engagementID } = energyEngagementAPI?.engagement;
  const [allocation, setAllocation] = useState<AllocationLetter>(
    energyEngagementAPI?.engagement?.allocationLetter
  );
  const [buildingProfile, setBuildingProfile] = useState<BuildingProfile>(
    energyEngagementAPI?.engagement?.buildingProfile
  );

  useEffect(() => {
    if (!engagementID || !energyEngagementAPI || !allocation.callInProgress)
      return;

    const interval = setInterval(async () => {
      await energyEngagementAPI.getEngagement(engagementID);
      const updatedAllocation =
        energyEngagementAPI.getEngagementData?.allocationLetter;
      const updatedBuildingProfile =
        energyEngagementAPI.getEngagementData?.buildingProfile;
      if (
        energyEngagementAPI.getEngagementData &&
        (JSON.stringify(updatedAllocation) !== JSON.stringify(allocation) ||
          JSON.stringify(updatedBuildingProfile) !==
            JSON.stringify(buildingProfile))
      ) {
        setAllocation(energyEngagementAPI.getEngagementData.allocationLetter);
        setBuildingProfile(
          energyEngagementAPI.getEngagementData.buildingProfile
        );
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [energyEngagementAPI, allocation, engagementID]);

  const dispatchCall = async () => {
    const body = {
      allocationLetter: {
        ...allocation,
        callInProgress: true,
      },
    };
    setAllocation({
      ...allocation,
      callInProgress: true,
    });
    await energyEngagementAPI.updateEngagement({ engagementID, body });
    await energyEngagementAPI.dispatchCall({
      engagementID,
    });
  };

  const sendAllocationLetter = async () => {
    const sendAllocationLetterResponse: any =
      await energyEngagementAPI.sendAllocationLetter({
        engagementID,
        body: {
          senderEmail: usersAPI.user.email,
        },
      });

    // Get the author URL from the response and open it in a new tab
    if (
      sendAllocationLetterResponse?.data?.message === "UNAUTHORIZED" &&
      sendAllocationLetterResponse?.data?.authorizationUrl
    ) {
      window.open(
        sendAllocationLetterResponse?.data?.authorizationUrl,
        "_blank"
      );
    }
  };

  const updateAllocationLetterAndBuildingProfile = async (data) => {
    const body = {
      buildingProfile: {
        ...buildingProfile,
        ...data.buildingProfile,
      },
      allocationLetter: { ...allocation, ...data.allocationLetter },
    };

    setAllocation({ ...allocation, ...data.allocationLetter });
    setBuildingProfile({ ...buildingProfile, ...data.buildingProfile });

    await energyEngagementAPI.updateEngagement({ engagementID, body });
  };

  return (
    <Vapi
      dispatchCall={dispatchCall}
      isDispatching={energyEngagementAPI.isDispatching}
      sendAllocationLetter={sendAllocationLetter}
      isSendingAllocationLetter={energyEngagementAPI.isSendingAllocationLetter}
      allocationLetter={allocation}
      buildingProfile={buildingProfile}
      setAllocation={setAllocation}
      updateAllocationLetterAndBuildingProfile={
        updateAllocationLetterAndBuildingProfile
      }
    />
  );
};
