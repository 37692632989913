import { useListAssignedUsersQuery } from "../../../../api/userAPI";
import { ExpandedTrackerCard } from "./ExpandedTrackerCard";

export const ExpandedTrackerCardContainer = ({
  engagement,
}: {
  engagement: any;
}) => {
  const { data: assignedUsers, isLoading } = useListAssignedUsersQuery({
    companyID: engagement.companyID,
  });

  const users = assignedUsers?.map((user) => ({
    fullName: user?.fullName,
    role: user?.role,
  }));

  return (
    <ExpandedTrackerCard
      engagement={engagement}
      assignedUsers={users}
      isLoadingUsers={isLoading}
    />
  );
};
