import {
  ChevronRightIcon,
  InformationCircleIcon,
  CheckCircleIcon,
} from "@heroicons/react/solid";
import classNames from "classnames";

const FlatlistButton = (props) => {
  const { title, subtext, status, onClick, className } = props;
  let iconBgClass =
    "inline-flex items-center justify-center h-10 w-10 rounded-lg";
  iconBgClass =
    status === true
      ? iconBgClass + " bg-green-300"
      : iconBgClass + " bg-blue-300";
  return (
    <li
      className={classNames("z-10", className && className)}
      onClick={onClick ? () => onClick() : null}
    >
      <div className="relative group py-4 flex items-start space-x-3 rounded hover:bg-blue-100 p-2">
        <div className="flex-shrink-0">
          <span className={iconBgClass}>
            {status === true && <CheckCircleIcon className="w-5 h-5" />}
            {status === false && <InformationCircleIcon className="w-5 h-5" />}
          </span>
        </div>
        <div className="min-w-0 flex-1">
          <div className="text-sm font-medium text-gray-900">
            <span className="absolute inset-0" aria-hidden="true" />
            {title}
          </div>
          <p className="text-sm text-gray-500">{subtext}</p>
        </div>
        <div className="flex-shrink-0 self-center">
          {status === false && <ChevronRightIcon className="w-5 h-5" />}
        </div>
      </div>
    </li>
  );
};

export default FlatlistButton;
