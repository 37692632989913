import { baseAPI } from "./baseAPI";

const engagmentAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createEngagement: builder.mutation({
      query: (body) => ({
        url: `/rd/engagements`,
        method: "POST",
        body: body,
      }),
    }),
    getEngagement: builder.query({
      query: (engagementID) => ({
        url: `/rd/engagements/${engagementID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Engagement", id }],
    }),
    updateEngagement: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/engagements/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    createEngagementData: builder.mutation({
      query: (body) => ({
        url: `/rd/engagement-data`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { id }) => [
        { type: "FirmEngagementList", id },
        { type: "EngagementList", id },
      ],
    }),
    getEngagementData: builder.query({
      query: (engagementID) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "EngagementData", id }],
    }),
    updateEngagementData: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
        { type: "EngagementList", engagementID },
      ],
    }),
    reassignCompany: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/reassign-company/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
        { type: "EngagementList", engagementID },
      ],
    }),
    logEngagementActivity: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/log-engagement-activity/${engagementID}`,
        method: "PUT",
        body: body,
      }),
    }),
    checkEmployeeLocation: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/check-employee-location/${engagementID}`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    // TODO: SHOULD THIS BE IN RD OR FOUNDATION
    createSupportTicket: builder.mutation({
      query: ({ body }) => ({
        url: "/create-support-ticket",
        method: "POST",
        body: body,
      }),
    }),
    createTaxTeamUser: builder.mutation({
      query: (body) => ({
        url: `/users`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    generateDeliverables: builder.mutation({
      query: (body) => ({
        url: `/rd/generate-deliverables`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    listTaxEngagements: builder.query({
      query: (engagementID) => ({
        url: `/rd/list-tax-engagements`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
      providesTags: (result, error, id) => [{ type: "EngagementList", id }],
    }),
    listEngagementData: builder.query({
      query: (queryParams) => {
        const { reps, companies, dates, engagementName } = queryParams;
        const params = new URLSearchParams();

        if (reps?.length > 0) {
          reps.forEach((rep) => params.append("reps", rep));
        }
        if (companies?.length > 0) {
          companies.forEach((company) => params.append("companies", company));
        }
        if (dates.start) {
          params.append("startDate", dates.start);
        }
        if (engagementName) {
          params.append("engagementName", engagementName);
        }
        if (dates.end) {
          params.append("endDate", dates.end);
        }
        return {
          url: `/rd/list-all-engagementData-items?${params.toString()}`,
          method: "GET",
        };
      },
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
      providesTags: (result, error, id) => [{ type: "EngagementList", id }],
    }),
    listEngagementsFromCompanyId: builder.query({
      query: (companyID) => ({
        url: `/rd/list-from-company-id/${companyID}`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { companyID }) => [
        { type: "EngagementList", companyID },
      ],
    }),
    listEngagementsFromCompanyID: builder.mutation({
      query: (companyID) => ({
        url: `/rd/list-from-company-id/${companyID}`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { companyID }) => [
        { type: "EngagementList", companyID },
      ],
    }),
    listTaxFirmEngagements: builder.query({
      query: (companyID) => ({
        url: `/rd/list-tax-firm-engagements/${companyID}`,
        method: "GET",
      }),
      invalidatesTags: (result, error, { companyID }) => [
        { type: "FirmEngagementList", companyID },
      ],
      providesTags: (result, error, id) => [{ type: "FirmEngagementList", id }],
    }),
    extractTaxW2: builder.mutation({
      query: (body) => ({
        url: `/rd/extract-w2`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    generateBulkEditFile: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/generate-bulk-edit-file/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    generateTaxpayerProfileOrganizer: builder.mutation({
      query: (engagementID) => ({
        url: `/rd/generate-taxpayer-profile-organizer/${engagementID}`,
        method: "POST",
      }),
    }),
    notifyPractitioner: builder.mutation({
      query: ({ body }) => ({
        url: `/rd/notify-practitioner`,
        method: "POST",
        body: body,
      }),
    }),
    generateAllocations: builder.mutation({
      query: ({ body }) => ({
        url: `/rd/employee-allocations`,
        method: "POST",
        body: body,
      }),
    }),
    deleteProject: builder.mutation({
      query: ({ body }) => ({
        url: `/rd/delete-project`,
        method: "POST",
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateEngagementMutation,
  useGetEngagementQuery,
  useCreateEngagementDataMutation,
  useGetEngagementDataQuery,
  useLazyGetEngagementDataQuery,
  useUpdateEngagementDataMutation,
  useReassignCompanyMutation,
  useUpdateEngagementMutation,
  useLogEngagementActivityMutation,
  useCheckEmployeeLocationMutation,
  useCreateSupportTicketMutation,
  useCreateTaxTeamUserMutation,
  useGenerateDeliverablesMutation,
  useListTaxEngagementsQuery,
  useListEngagementsFromCompanyIDMutation,
  useExtractTaxW2Mutation,
  useListTaxFirmEngagementsQuery,
  useGenerateBulkEditFileMutation,
  useGenerateTaxpayerProfileOrganizerMutation,
  useNotifyPractitionerMutation,
  useGenerateAllocationsMutation,
  useDeleteProjectMutation,
  useListEngagementDataQuery,
  useListEngagementsFromCompanyIdQuery,
} = engagmentAPI;
