import { Engagement } from "./Engagement";
import { useEnergyEngagement } from "../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useFile } from "../../../../rtk/R&D/File/useFile";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { formatModelDocumentKey, formatDocumentKey } from "../../utils/format";
import { Engagement as TEngagement } from "../../types";
import { useEnergyComments } from "../../../../rtk/Energy/Comments/useEnergyComments";
import { EngagementLoading } from "../components/EngagementLoading";
import { useState, useEffect } from "react";

interface EngagementContainerProps {
  engagement: any;
  setActiveEngagement: (arg0: any | undefined) => void;
}

export const EngagementContainer = ({
  engagement,
  setActiveEngagement,
}: EngagementContainerProps) => {
  const fileAPI = useFile();
  const usersAPI = useUsers();
  const energyAPI = useEnergyEngagement();
  const commentsAPI = useEnergyComments();
  const [videoUrl, setVideoUrl] = useState("");

  const { engagementID } = engagement;

  useEffect(() => {
    const docs = engagement?.documents ?? [];
    const siteVisitFile = docs.filter(
      (d: any) =>
        d.relatedSystem === "Site Visit" && d.fileType === "video/quicktime"
    )[0];
    async function getURL() {
      const UrlBody = {
        bucket: engagement.s3Bucket.name,
        key: `${engagementID}/documentation/raw/${siteVisitFile.name}`,
        action: "getObject",
      };
      const signedURLResponse = await fileAPI.getSignedURL(UrlBody);
      setVideoUrl(signedURLResponse.data.data.signedRequest);
    }
    if (siteVisitFile) {
      getURL();
    }
  }, [engagement.s3Bucket.name, engagement?.documents, engagementID, fileAPI]);

  if (!fileAPI || !energyAPI || energyAPI.engagementLoading)
    return <EngagementLoading />;

  const onDownloadFiles = async () => {
    const signedLink = await fileAPI.generatePartialEnergyReport({
      engagementID: engagement.engagementID,
      body: {
        reportType: "CERTIFIER",
      },
    });

    // Handler Error
    if (fileAPI?.errors?.generatePartialReportError) {
      console.error(fileAPI.errors.generatePartialReportError);
      return;
    }
    // Open link
    window.open(signedLink.data, "_blank");
  };

  const onSubmit = async () => {
    const body = {
      certification: {
        ...engagement.certification,
        status: "COMPLETE",
        isAccurate: true,
        submittedDate: new Date().toLocaleDateString(),
        submittedBy: usersAPI.user.fullName,
        submittedByUserID: usersAPI.user.userID,
      },
    };
    const updateParams = {
      engagementID,
      body,
    };
    await energyAPI.updateEngagement(updateParams);
    setActiveEngagement((prev: any) => ({
      ...prev,
      certification: {
        ...prev.certification,
        ...body.certification,
      },
    }));
  };

  const onAddComment = async ({ title, comment }) => {
    await commentsAPI.onCreateComment({ title, comment });
  };

  return (
    <Engagement
      setActiveEngagement={setActiveEngagement}
      onDownloadFiles={onDownloadFiles}
      isDownloadingDocuments={fileAPI.isGeneratingPartialEnergyReport}
      onSubmit={onSubmit}
      onAddComment={onAddComment}
      isCreatingComment={commentsAPI.isCreatingComment}
      isSubmitting={energyAPI.updateEngagementData.isLoading}
      videoUrl={videoUrl}
      levels={engagement?.certification?.levels ?? []}
    />
  );
};
