import { stateAbbreviations } from "../../ReviewPortal/utils/constants";

export const determineStatesWithQres = ({
  wageData,
  supplyData,
  contractData,
  computerLeaseData,
}) => {
  return [
    ...new Set(
      [
        ...wageData
          .filter((a) => a.qre > 0)
          .map((wage) => stateAbbreviations[wage["Employee Location"]]),
        ...supplyData
          .filter((a) => a.qre > 0)
          .map((supply) => stateAbbreviations[supply["Supply Location"]]),
        ...contractData
          .filter((a) => a.qre > 0)
          .map(
            (contract) => stateAbbreviations[contract["Contractor Location"]]
          ),
        ...computerLeaseData
          .filter((a) => a.qre > 0)
          .map((computer) => stateAbbreviations[computer["Vendor Location"]]),
      ].flat()
    ),
  ];
};
