import { useState, useCallback, useEffect } from "react";
import SurveyContext from "./SurveyContext";
import {
  useEncryptCredentialsMutation,
  useGenerateSurveyLinkMutation,
  useDecryptServerCredentialsMutation,
  useGetSurveyEngagementDataMutation,
  useUpdateSurveyEngagementDataMutation,
  useDownloadSurveyFileMutation,
  useUploadSurveyFileMutation,
  useCalculateSurveyRDCreditMutation,
  useCalculateSurveyStateCreditMutation,
  useSetSurveySessionIDMutation,
  useRemoveSurveySessionIDMutation,
  useUploadLineItemMutation,
} from "../../../api/surveyAPI";
import { translateImportTypeToFileName } from "../../../main/R&D/utils";

const SurveyProvider = ({ children }) => {
  const [error, setError] = useState(null);
  const [engagementData, setEngagementData] = useState(null);
  const [isAuthed, setIsAuthed] = useState(false);
  const [isUploading, setIsUploading] = useState(false);
  const [employeeID, setEmployeeID] = useState(null);
  const [employee, setEmployee] = useState(null);

  const [wageData, setWageData] = useState([]);
  const [projectData, setProjectData] = useState([]);

  const [
    decryptServerCredentials,
    { isLoading: isDecryptingServerCredentials },
  ] = useDecryptServerCredentialsMutation();

  const [uploadLineItem] = useUploadLineItemMutation();

  const [setSurveySessionID, { isLoading: isSettingSurveySessionID }] =
    useSetSurveySessionIDMutation();

  const [removeSurveySessionID, { isLoading: isRemovingSurveySessionID }] =
    useRemoveSurveySessionIDMutation();

  const [
    getSurveyEngagementData,
    { isLoading: isLoadingSurveyEngagementData },
  ] = useGetSurveyEngagementDataMutation();

  const [
    updateSurveyEngagementData,
    { isLoading: isUpdatingSurveyEngagementData },
  ] = useUpdateSurveyEngagementDataMutation();

  const [downloadSurveyFile, { isLoading: isDownloadingFile }] =
    useDownloadSurveyFileMutation();

  const [uploadSurveyFile, { isLoading: isUploadingFile }] =
    useUploadSurveyFileMutation();

  const [calculateSurveyRDCredit, { isLoading: isCalculatingCredit }] =
    useCalculateSurveyRDCreditMutation();

  const [calculateSurveyStateCredit, { isLoading: isCalculatingStateCredit }] =
    useCalculateSurveyStateCreditMutation();

  const [encryptCredentials, { isLoading: isEncryptingCredentials }] =
    useEncryptCredentialsMutation();

  const [generateSurveyLink, { isLoading: isGeneratingLink }] =
    useGenerateSurveyLinkMutation();

  const getParams = useCallback(
    (type, action) => {
      const { s3Bucket, engagementID } = engagementData;
      const bucket = s3Bucket?.name;
      const fileSlug = translateImportTypeToFileName(type);
      return {
        bucket,
        action,
        key: `${engagementID}/uploadFiles/${engagementID}${fileSlug}.json`,
      };
    },
    [engagementData]
  );

  const getRelevantDataPoints = () => {
    if (!employee) return {};
    if (projectData.length === 0) return {};
    if (!engagementData) return {};
    const { companyName } = engagementData;
    const employeeName = employee["Employee Name"];
    const surveyStatus = employee.surveyStatus;
    const qPercentage = employee.qPercentage;
    const jobTitle = employee["Employee Job Title"];
    const projectNames = projectData.map((p) => p["Project Name"]);
    return {
      companyName,
      employeeName,
      jobTitle,
      qPercentage,
      projectNames,
      surveyStatus,
    };
  };

  const isExpired = (expires) => {
    const expDate = new Date(expires);
    const currentDate = new Date();
    return currentDate > expDate;
  };

  useEffect(() => {
    const employee = wageData.find((w) => w.ID === employeeID);
    setEmployee(employee);
  }, [employeeID, wageData]);

  // Update Employee
  const updateEmployee = async ({
    qualifiedPercentage,
    activities,
    projectPercentages,
  }) => {
    setIsUploading(true);
    const employeeCopy = { ...employee };
    employeeCopy.qPercentage = Number(qualifiedPercentage);
    employeeCopy.activities = activities;
    employeeCopy.surveyStatus = "complete";

    // Map Project Percentages onto Employee Object & Update Completions Sub Object
    Object.keys(projectPercentages).forEach((key) => {
      const projectID = projectData.find((p) => p["Project Name"] === key).ID;
      employeeCopy[projectID] = Number(projectPercentages[key]);
      if (!employeeCopy.hasOwnProperty("completions")) {
        employeeCopy.completions = {};
      }
      if (!employeeCopy.completions.hasOwnProperty(projectID)) {
        employeeCopy.completions = {
          ...employeeCopy.completions,
          [`${projectID}`]: {
            isLoading: true,
          },
        };
      }
    });

    // Update Singular Line Item
    const body = {
      item: employeeCopy,
      engagementID: engagementData.engagementID,
      type: "WAGES",
    };
    await uploadLineItem(body);

    setIsUploading(false);
  };

  // Download Listings
  useEffect(() => {
    if (isAuthed !== true) return;
    async function getData(body) {
      try {
        const response = await downloadSurveyFile({ ...body });
        return response?.data ? response.data : [];
      } catch (err) {
        console.error(err);
        return [];
      }
    }

    getData(getParams("Wages", "getObject"))
      .then((data) => {
        const arr = data.map((a) => ({ ...a }));
        setWageData(arr);
      })
      .catch((err) => console.error(err));

    getData(getParams("Projects", "getObject"))
      .then((data) => {
        const arr = data.map((a) => ({ ...a }));
        setProjectData(arr);
      })
      .catch((err) => console.error(err));
  }, [downloadSurveyFile, getParams, isAuthed]);

  const authenticate = async ({ surveyId, password }) => {
    const { data } = await decryptServerCredentials({
      surveyId: surveyId,
      password: password,
    });

    const { credentials, expires, engagementID, employeeID } = data;
    setEmployeeID(employeeID);

    if (isExpired(expires)) {
      setError("Session Expired");
      return;
    }
    await setSurveySessionID({ sessionID: credentials });

    const engagementDataResponse = await getSurveyEngagementData(engagementID);
    setEngagementData({ ...engagementDataResponse.data });

    setIsAuthed(true);
  };

  const value = {
    employee,
    wageData,
    projectData,
    isAuthed,

    authenticate,
    decryptServerCredentials,
    setSurveySessionID,
    removeSurveySessionID,
    getSurveyEngagementData,
    updateSurveyEngagementData,
    downloadSurveyFile,
    uploadSurveyFile,
    calculateSurveyRDCredit,
    calculateSurveyStateCredit,
    encryptCredentials,
    generateSurveyLink,
    getRelevantDataPoints,
    updateEmployee,

    isDecryptingServerCredentials,
    isSettingSurveySessionID,
    isRemovingSurveySessionID,
    isLoadingSurveyEngagementData,
    isUpdatingSurveyEngagementData,
    isDownloadingFile,
    isUploadingFile,
    isCalculatingCredit,
    isCalculatingStateCredit,
    isEncryptingCredentials,
    isGeneratingLink,
    isUploading,

    error,
  };

  return (
    <SurveyContext.Provider value={value}>{children}</SurveyContext.Provider>
  );
};
export default SurveyProvider;
