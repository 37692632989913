import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useUsers } from "../../../rtk/Users/useUsers";

import { motion } from "framer-motion";
import { useState } from "react";
import { AdminEngagementListLoading } from "../components/AdminEngagementListLoading";
import { isDev } from "../../R&D/utils";
import { CreateUserForm } from "./CreateUserForm";
import { EditUserForm } from "./EditUserForm";

export const UsersTable = () => {
  const {
    user,
    users,
    isListingUsers,
    isUpdatingUser,
    impersonate,
    impersonatedUser,
    inGodMode,
    setInGodMode,
  } = useUsers();

  const { engagementList } = useEngagementData();

  const { companies } = useCompanyData();

  const [filter, setFilter] = useState<string>("");
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);

  if (isListingUsers) return <AdminEngagementListLoading />;

  return (
    <div className="flex-1">
      <div className="">
        {/* Admin engagement table */}
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="items-between flex flex-row items-end justify-between">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search User</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Client Name
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Email
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Role
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Impersonate</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {users
                        ?.map((a) => ({ ...a }))
                        ?.filter((a) => {
                          const k = filter.toLowerCase();
                          return a?.fullName?.toLowerCase()?.includes(k);
                        })
                        ?.map((u, index) => (
                          <tr key={u.userID}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u.fullName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u.companyName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u.email}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {u.role}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-green-400"
                                onClick={() => {
                                  if (
                                    inGodMode &&
                                    impersonatedUser?.userID === u.userID
                                  ) {
                                    setInGodMode(false);
                                  } else {
                                    impersonate(u);
                                  }
                                }}
                              >
                                {inGodMode &&
                                impersonatedUser?.userID === u.userID
                                  ? "Stop"
                                  : "Impersonate"}
                              </motion.button>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-green-400"
                                onClick={() => {
                                  setEditID(u.userID);
                                  setEditOpen(true);
                                }}
                              >
                                Edit
                              </motion.button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <EditUserForm
            user={users?.find((u) => u.userID === editID)}
            open={editOpen}
            setOpen={setEditOpen}
            engagements={engagementList}
            companies={companies}
            isAdmin={true}
            isUpdatingUser={isUpdatingUser}
          />
        </div>
      </div>
    </div>
  );
};
