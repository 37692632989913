import { useState } from "react";
import {
  ListHeader,
  TableItem,
  TableHeader,
  TableContainer,
  EngagementListStyleParent,
  CreateEngagementForm,
} from "./components";
import { EditEngagementForm } from "./components/EditEngagementForm";

interface EngagementListProps {
  activeEngagement: string;
  engagements: any[];
  onCreateEngagement: (model: any) => Promise<void>;
  onSetEngagementActive: (id: string) => Promise<void>;
  onUpdateEngagement: (data: any) => Promise<void>;
  onFavoriteEngagement: (type: string, id: string) => Promise<void>;
  favoriteEngagements: string[];
}

export const EngagementList = ({
  activeEngagement,
  engagements,
  onCreateEngagement,
  onSetEngagementActive,
  onUpdateEngagement,
  onFavoriteEngagement,
  favoriteEngagements,
}: EngagementListProps) => {
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [editID, setEditID] = useState<number | null>(null);

  const activeEngagementObj = engagements?.find(
    (eng) => eng?.engagementID === activeEngagement,
  );

  const favoriteEngagementObjs = engagements?.filter(
    (e) =>
      favoriteEngagements?.includes(e?.engagementID) &&
      e?.engagementID !== activeEngagementObj?.engagementID,
  );

  const filteredEngagements = engagements
    ?.filter(
      (eng) =>
        eng?.engagementID !== activeEngagement &&
        !favoriteEngagements?.includes(eng?.engagementID),
    )
    ?.sort((a, b) => b?.createdAt - a?.createdAt);

  const renderedEngagements = [
    ...([activeEngagementObj] ?? []),
    ...([...favoriteEngagementObjs] ?? []),
    ...filteredEngagements,
  ];

  return (
    <EngagementListStyleParent>
      <ListHeader setFilter={setFilter} setOpen={setOpen} filter={filter} />
      <TableContainer>
        <TableHeader />
        <tbody className="h-16 divide-y divide-gray-200 bg-white">
          {renderedEngagements
            ?.filter((eng) => {
              const k = filter.toLowerCase();
              return eng?.name?.toLowerCase()?.includes(k);
            })
            ?.map((eng, index) => (
              <TableItem
                key={eng?.engagementID ?? ""}
                eng={eng}
                index={index}
                setEditOpen={setEditOpen}
                setEditIndex={setEditIndex}
                editIndex={editIndex}
                editID={editID}
                setEditID={setEditID}
                activeEngagement={activeEngagement}
                onSetEngagementActive={onSetEngagementActive}
                onFavoriteEngagement={onFavoriteEngagement}
                favoriteEngagements={favoriteEngagements}
              />
            ))}
        </tbody>
      </TableContainer>
      <EditEngagementForm
        open={editOpen}
        setOpen={setEditOpen}
        loading={false}
        engagement={renderedEngagements?.find((e) => e.engagementID === editID)}
      />
      <CreateEngagementForm
        open={open}
        setOpen={setOpen}
        onCreateEngagement={onCreateEngagement}
      />
    </EngagementListStyleParent>
  );
};
