import React, { useState } from "react";
import { SearchBar, Board } from "./components";
import { Engagement, EngagementStatus } from "./types";
import { applyFilter } from "./components/Column";

const searchFilter = (eng: Engagement, filter: string) => {
  return eng.name.toLowerCase().includes(filter.toLowerCase());
};

export const Tracker = ({
  engagements,
  query,
  setQuery,
  isQuerying,
  masterFilter,
  setMasterFilter,
}: {
  engagements: any[];
  query: string;
  setQuery: (e: string) => void;
  isQuerying: boolean;
  masterFilter: any;
  setMasterFilter: (e: any) => void;
}) => {
  const [filter, setFilter] = useState<EngagementStatus[]>([
    "Upload",
    "Review",
    "Validation",
    "Completed",
  ]);
  const filteredEngagements = applyFilter(engagements, masterFilter).filter(
    (e) => searchFilter(e, query),
  );
  return (
    <div className="container mx-auto h-4/5 p-4">
      <SearchBar
        setQuery={(e: string) => setQuery(e)}
        isQuerying={isQuerying}
        setFilter={setFilter}
        filter={filter}
        masterFilter={masterFilter}
        setMasterFilter={setMasterFilter}
      />
      <Board engagements={filteredEngagements} filter={filter} />
    </div>
  );
};
