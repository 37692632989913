import React, { Fragment, useRef } from "react";
import * as Survey from "survey-react";
import "survey-react/survey.css";
import { Button } from "./";
import { Dialog, Transition } from "@headlessui/react";

const StateCreditModal = (props) => {
  var defaultThemeColors = Survey.StylesManager.ThemeColors["default"];
  defaultThemeColors["$main-color"] = "#454553";
  defaultThemeColors["$main-hover-color"] = "#4AA0D5";
  defaultThemeColors["$text-color"] = "#000000";
  defaultThemeColors["$body-container-background-color"] = "#f8f8f8";

  Survey.StylesManager.applyTheme();

  var surveyJSON = props.stateData.stateInfo.analysis;

  var model = new Survey.Model(surveyJSON);

  if (props.stateData.completed) {
    model.data = props.stateData.stateInfo.completedAnalysis;
  }

  function onComplete(survey, options) {
    props.submit(survey);
  }

  model.onUpdatePageCssClasses.add(function (survey, options) {});

  model.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;
    classes.item = "flex font-normal mb-1";
    classes.mainRoot = "flex flex-col min-w-full items-center justify-between";
    classes.title = " text-lg font-normal text-center ml-16 mb-32";
    classes.controlLabel = "ml-2";
    classes.itemControl =
      "focus:outline-none focus:ring-0 focus:ring-transparent";
    // TODO: Center title text span w flexbox - leveraging margin hack ATM
    // console.log(Object.keys(classes));
  });

  model.onUpdateQuestionCssClasses.add(function (survey, options) {
    var classes = options.cssClasses;
    classes.item = "flex font-normal mb-1";
    classes.mainRoot = "flex flex-col min-w-full items-center justify-between";
    classes.title = "flex text-lg font-normal text-center ml-16 mb-2";
    classes.controlLabel = "ml-2";
    classes.itemControl =
      "focus:outline-none focus:ring-0 focus:ring-transparent";
    // TODO: Center title text span w flexbox - leveraging margin hack ATM
    // console.log(Object.keys(classes));
  });

  const cancelButtonRef = useRef(null);

  return (
    <Transition.Root show={props.show} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        initialFocus={cancelButtonRef}
        onClose={props.hide}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full sm:p-6">
              <div className="mt-3 text-center sm:mt-5">
                <Dialog.Title
                  as="h3"
                  className="text-lg leading-6 font-medium text-gray-900"
                >
                  {`Additional Information for ${props.stateData.state}`}
                </Dialog.Title>
                <div className="mt-2">
                  <Survey.Survey
                    model={model}
                    showNavigationButtons={false}
                    showTitle={false}
                    onComplete={onComplete}
                    // onCurrentPageChanged={onCurrentPageChanged}
                  />
                </div>
              </div>
              <div className="mt-5 sm:mt-6 sm:grid sm:grid-cols-2 sm:gap-3 sm:grid-flow-row-dense">
                <span className="mt-3 flex w-full rounded-md shadow-sm sm:mt-0 sm:col-start-1">
                  <Button
                    secondary
                    onClick={() => {
                      model.isFirstPage ? props.hide() : model.prevPage();
                    }}
                    buttonClassName="inline-flex justify-center w-full rounded-md px-4 py-2 leading-6 shadow-sm sm:text-sm sm:leading-5"
                  >
                    Back
                  </Button>
                </span>
                <span className="flex w-full rounded-md shadow-sm sm:col-start-2">
                  <Button
                    primary
                    buttonClassName="inline-flex justify-center w-full rounded-md border border-transparent px-4 py-2 text-base leading-6 shadow-sm sm:text-sm sm:leading-5"
                    onClick={() => {
                      model.isLastPage
                        ? model.completeLastPage()
                        : model.nextPage();
                    }}
                  >
                    Next
                  </Button>
                </span>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default StateCreditModal;
