import { useMemo, useRef, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { motion } from "framer-motion";
import {
  getModelSubmissionFormShape,
  getModelSubmissionDefaultFields,
} from "./utils";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../../../ui-components";
import { performClick } from "../../../R&D/utils";

interface ModelSubmissionFormProps {
  taxYearStart: number;
  efficiencyPercentage: number;
  hvacEfficiency: number;
  lightingEfficiency: number;
  envelopeEfficiency: number;
  isUploadingFile: boolean;
  isComplete: boolean;
  isSubmitting: boolean;
  files: any;
  onDownloadEnergyModel: (stub: string) => Promise<void>;
  onUploadEnergyModel: (document: File, stub: string) => Promise<void>;
  onSubmit: ({
    efficiencyPercentage,
  }: {
    efficiencyPercentage: number;
  }) => Promise<void>;
}

export const ModelSubmissionForm = ({
  onUploadEnergyModel,
  onSubmit,
  onDownloadEnergyModel,
  isComplete,
  isUploadingFile,
  efficiencyPercentage,
  hvacEfficiency,
  lightingEfficiency,
  envelopeEfficiency,
  files,
  isSubmitting,
  taxYearStart,
}: ModelSubmissionFormProps) => {
  const inputFileRef = useRef();
  const schema = getModelSubmissionFormShape();
  const [fileUploadStub, setFileUploadStub] = useState<string>("main");

  const { control, handleSubmit, formState, watch } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getModelSubmissionDefaultFields({
        efficiencyPercentage,
        hvacEfficiency,
        lightingEfficiency,
        envelopeEfficiency,
      });
    }, [
      efficiencyPercentage,
      hvacEfficiency,
      lightingEfficiency,
      envelopeEfficiency,
    ]),
    resolver: yupResolver(schema),
  });
  const { errors, isValid } = formState;
  const watchEfficiencyPercentage = watch("efficiencyPercentage");
  const renderSecondaryUploadsAndInputs =
    watchEfficiencyPercentage < 50 && taxYearStart < 2023;
  const mainFileName = files?.main?.name;
  const hvacFileName = files?.hvac?.name;
  const lightingFileName = files?.lighting?.name;
  const envelopeFileName = files?.envelope?.name;

  const hasUpload: boolean = !!mainFileName;

  return (
    <>
      <form
        data-test="building-profile-form"
        className="mt-4 ml-4 mr-4"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      >
        <div className="flex flex-col justify-between">
          <div className="grid grid-cols-12 gap-y-8 gap-x-4">
            <Controller
              name="efficiencyPercentage"
              control={control}
              render={({ field }) => (
                <div className="col-span-2">
                  <div className="flex flex-row">
                    <label
                      htmlFor="efficiencyPercentage"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Total Percentage
                    </label>
                    <p className="text-sprxClientPortalRed -mt-1">*</p>
                  </div>
                  <div className="mt-1">
                    <motion.input
                      {...field}
                      data-test="efficiencyPercentage"
                      id="efficiencyPercentage"
                      name="efficiencyPercentage"
                      type="number"
                      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                      whileHover={{ scale: 1.01 }}
                    />
                    {errors?.efficiencyPercentage && (
                      <p className="pl-2 text-sprxOrange">Invalid</p>
                    )}
                  </div>
                </div>
              )}
            />
            <div className="flex flex-row justify-start items-center mt-10 ml-4 col-span-10">
              <Button
                secondary={true}
                type="button"
                onClick={() => {
                  setFileUploadStub("main");
                  performClick("energy-model-file-upload");
                }}
                buttonClassName="mr-4"
              >
                {isUploadingFile && fileUploadStub === "main" ? (
                  <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
                ) : (
                  "Upload"
                )}
              </Button>
              <button
                onClick={async () => await onDownloadEnergyModel("main")}
                className="text-sprxClientPortalLightBlue hover:text-sprxClientPortalDarkBlue"
              >
                {mainFileName}
              </button>
            </div>
            {renderSecondaryUploadsAndInputs ? (
              <div className="grid grid-cols-12 col-span-12">
                <Controller
                  name="hvacEfficiency"
                  control={control}
                  render={({ field }) => (
                    <div className=" col-span-2 ">
                      <div className="flex flex-row col-span-2 ">
                        <label
                          htmlFor="hvacEfficiency"
                          className="block text-sm font-medium text-gray-700"
                        >
                          HVAC Efficiency
                        </label>
                        <p className="text-sprxClientPortalRed -mt-1">*</p>
                      </div>
                      <div className="mt-1 col-span-2 ">
                        <motion.input
                          {...field}
                          data-test="hvacEfficiency"
                          id="hvacEfficiency"
                          name="hvacEfficiency"
                          type="number"
                          className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.hvacEfficiency && (
                          <p className="pl-2 text-sprxOrange">Invalid</p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <div className="flex flex-row justify-start items-center mt-4 ml-4 col-span-10">
                  <Button
                    secondary={true}
                    type="button"
                    onClick={() => {
                      setFileUploadStub("hvac");
                      performClick("energy-model-file-upload");
                    }}
                    buttonClassName="mr-4"
                  >
                    {isUploadingFile && fileUploadStub === "hvac" ? (
                      <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
                    ) : (
                      "HVAC"
                    )}
                  </Button>
                  <button
                    onClick={async () => await onDownloadEnergyModel("hvac")}
                    className="text-sprxClientPortalLightBlue hover:text-sprxClientPortalDarkBlue"
                  >
                    {hvacFileName}
                  </button>
                </div>
                <Controller
                  name="envelopeEfficiency"
                  control={control}
                  render={({ field }) => (
                    <div className="col-span-2 my-2 ">
                      <div className="flex flex-row">
                        <label
                          htmlFor="envelopeEfficiency"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Envelope Efficiency
                        </label>
                        <p className="text-sprxClientPortalRed -mt-1">*</p>
                      </div>
                      <div className="mt-1">
                        <motion.input
                          {...field}
                          data-test="envelopeEfficiency"
                          id="envelopeEfficiency"
                          name="envelopeEfficiency"
                          type="number"
                          className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.envelopeEfficiency && (
                          <p className=" pl-2 text-sprxOrange">
                            Invalid percentage
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <div className="flex flex-row justify-start items-center mt-10 ml-4 col-span-10 ">
                  <Button
                    secondary={true}
                    type="button"
                    onClick={() => {
                      setFileUploadStub("envelope");
                      performClick("energy-model-file-upload");
                    }}
                    buttonClassName="mr-4"
                  >
                    {isUploadingFile && fileUploadStub === "envelope" ? (
                      <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
                    ) : (
                      "Envelope"
                    )}
                  </Button>

                  <button
                    onClick={async () =>
                      await onDownloadEnergyModel("envelope")
                    }
                    // Add Hyperlink styles
                    className="text-sprxClientPortalLightBlue hover:text-sprxClientPortalDarkBlue"
                  >
                    {envelopeFileName}
                  </button>
                </div>
                <Controller
                  name="lightingEfficiency"
                  control={control}
                  render={({ field }) => (
                    <div className="col-span-2 my-2 ">
                      <div className="flex flex-row">
                        <label
                          htmlFor="lightingEfficiency"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Lighting Efficiency
                        </label>
                        <p className="text-sprxClientPortalRed -mt-1">*</p>
                      </div>
                      <div className="mt-1">
                        <motion.input
                          {...field}
                          data-test="lightingEfficiency"
                          id="lightingEfficiency"
                          name="lightingEfficiency"
                          type="number"
                          className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.lightingEfficiency && (
                          <p className=" pl-2 text-sprxOrange">
                            Invalid percentage
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <div className="flex flex-row justify-start items-center mt-10 ml-4 col-span-10 ">
                  <Button
                    secondary={true}
                    type="button"
                    onClick={() => {
                      setFileUploadStub("lighting");
                      performClick("energy-model-file-upload");
                    }}
                    buttonClassName="mr-4"
                  >
                    {isUploadingFile && fileUploadStub === "lighting" ? (
                      <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
                    ) : (
                      "Lighting"
                    )}
                  </Button>
                  <button
                    onClick={async () =>
                      await onDownloadEnergyModel("lighting")
                    }
                    // Add Hyperlink styles
                    className="text-sprxClientPortalLightBlue hover:text-sprxClientPortalDarkBlue"
                  >
                    {lightingFileName}
                  </button>
                </div>
              </div>
            ) : null}
            <Controller
              name="isAccurate"
              control={control}
              render={({ field }) => (
                <div className="col-span-12">
                  <div className="relative flex items-start">
                    <div className="flex items-center h-5">
                      <motion.input
                        {...field}
                        value={field.value as any}
                        checked={field.value}
                        id="isAccurate"
                        name="isAccurate"
                        type="checkbox"
                        className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                        whileHover={{ scale: 1.05 }}
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isAccurate"
                        className="font-medium text-gray-700"
                      >
                        Confirm accuracy
                      </label>
                      <p className="text-gray-500">
                        Do you promise that this is accurate?
                      </p>
                    </div>
                  </div>
                  {errors?.isAccurate && (
                    <p className=" pl-2 text-sprxOrange">
                      Please confirm accuracy
                    </p>
                  )}
                </div>
              )}
            />
          </div>
          <motion.div className="flex flex-row w-full justify-end items-end mt-12">
            <motion.button
              type="submit"
              data-test="Save & Close"
              disabled={!isValid || !hasUpload}
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className={
                "inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm  focus:outline-none focus:ring-none sm:text-sm " +
                (!isValid || !hasUpload
                  ? "bg-opacity-50 cursor-not-allowed"
                  : " hover:bg-sprxClientPortalDarkBlue")
              }
              onClick={handleSubmit((model) => onSubmit(model))}
            >
              {isSubmitting ? (
                <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
              ) : (
                "Save & Close"
              )}
            </motion.button>
          </motion.div>
        </div>
      </form>
      <input
        ref={inputFileRef}
        id="energy-model-file-upload"
        type="file"
        style={{ display: "none" }}
        accept="image/*,audio/*,video/*,.pdf,application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.openxmlformats-officedocument.presentationml.slideshow, application/msword, application/vnd.ms-excel, application/vnd.ms-powerpoint,
                    text/plain,.msg,.json,.xlsx,.xls,.doc,.docx,.ppt,.pptx,.txt,.HEIC"
        onChange={(e: any) =>
          onUploadEnergyModel(e.target.files[0], fileUploadStub)
        }
      />
    </>
  );
};
