import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import JoyRide, { ACTIONS, EVENTS, STATUS } from "react-joyride";

const Tour = (props) => {
  const dispatch = useDispatch();
  const tour = useSelector(({ sprx }) => {
    switch (props?.type) {
      case "home":
        return sprx.tour;
      case "survey":
        return sprx.surveyTour;
      case "upload":
        return sprx.uploadTour;
      case "review":
        return sprx.reviewTour;
      case "stateCredits":
        return sprx.stateCreditsTour;
      default:
        return null;
    }
  });

  useEffect(() => {
    if (!localStorage.getItem(`tour-${props?.type}`)) {
      dispatch(props.startTour());
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const callback = (data) => {
    const { action, index, type, status } = data;

    if (
      action === ACTIONS.CLOSE ||
      (status === STATUS.SKIPPED && tour.run) ||
      status === STATUS.FINISHED
    ) {
      localStorage.setItem(`tour-${props?.type}`, true);

      dispatch(props.stopTour());
    } else if (type === EVENTS.STEP_AFTER || type === EVENTS.TARGET_NOT_FOUND) {
      dispatch(
        props.nextOrPrevTour({
          stepIndex: index + (action === ACTIONS.PREV ? -1 : 1),
        })
      );
    }
  };

  return (
    <JoyRide
      {...tour}
      callback={callback}
      // debug={true}
      styles={{
        options: {
          primaryColor: "#039be5",
          // textColor: "#000",
          // zIndex: 1000,
        },
      }}
      locale={{
        last: "Complete Tutorial",
      }}
    />
  );
};

export default Tour;
