import React from "react";
import { CheckCircleIcon, InformationCircleIcon } from "@heroicons/react/solid";

const IndividualStatus = (title, status, onClick) => {
  return (
    <div
      key={title}
      className="relative flex justify-center items-center space-x-3 border-transparent border rounded-lg hover:border-gray-400"
    >
      <div className="flex-shrink-0">
        {status === true ? (
          <CheckCircleIcon
            className="flex-shrink-0 self-center h-5 w-5 text-green-500"
            aria-hidden="true"
          />
        ) : (
          <InformationCircleIcon
            className="flex-shrink-0 self-center h-5 w-5 text-yellow-500"
            aria-hidden="true"
          />
        )}
      </div>
      <div className="flex-1 min-w-0">
        <button
          data-test={`progress-${title}`}
          onClick={() => onClick(title)}
          className=""
        >
          <span className="absolute inset-0" aria-hidden="true" />
          <p className="text-sm font-light text-gray-900">{title}</p>
        </button>
      </div>
    </div>
  );
};

const UploadStatus = (props) => {
  return (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-4 my-2">
      {Object.keys(props.progress).map((key) =>
        IndividualStatus(key, props.progress[key], props.onClick)
      )}
    </div>
  );
};

export default UploadStatus;
