import { classNames } from "../../utils";

export const FlatList = (props) => {
  const isSelected = (item, index) => {
    if (props?.isSelected) return props.isSelected(item);
    return index === props.selectedID;
  };

  return (
    <nav
      className={`h-${props?.height ?? "full"} overflow-y-auto`}
      aria-label="flatlist"
    >
      {props.items.map((item, index) => {
        return (
          <ul className="relative z-0">
            {index !== 0 ? (
              <div className="relative w-full">
                <div
                  className="inset-0 flex items-center justify-center py-1"
                  aria-hidden="true"
                >
                  <div className="w-4/5 border-t border-gray-200" />
                </div>
              </div>
            ) : null}
            <li
              key={item.ID}
              className="bg-white"
              onClick={() => props.setSelected(index)}
            >
              <div
                className={classNames(
                  "relative flex items-center space-x-3 px-6 py-2 focus-within:ring-2 focus-within:ring-inset focus-within:ring-indigo-500 hover:rounded-lg hover:bg-gray-50",
                  isSelected(item, index) ? "bg-gray-200 rounded-lg" : ""
                )}
              >
                <div className="min-w-0 flex-1">
                  <div className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium">{item[props.itemKey]}</p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        );
      })}
      {props?.addItemText && props?.addItem ? (
        <div className="relative w-full">
          <div
            className="inset-0 flex items-center justify-center py-1 "
            aria-hidden="true"
          >
            <div className="w-4/5 border-t border-gray-200" />
          </div>
          <ul>
            <li
              key={props.addItemText}
              className=""
              onClick={() => props.addItem()}
            >
              <div
                className={
                  "relative flex items-center space-x-3 px-6 py-2 bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue rounded-lg"
                }
              >
                <div className="min-w-0 flex-1">
                  <div className="focus:outline-none">
                    {/* Extend touch target to entire panel */}
                    <span className="absolute inset-0" aria-hidden="true" />
                    <p className="text-sm font-medium text-white text-center">
                      {props.addItemText}
                    </p>
                  </div>
                </div>
              </div>
            </li>
          </ul>
        </div>
      ) : null}
    </nav>
  );
};
