import { Comments } from "./Comments";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useEffect } from "react";
import { transposeComments } from "./utils/transposeComments";
import { formatTimestamp } from "./components/ActivityItem";
import { SprxLoading } from "../../../../ui-components";

interface CommentsContainerProps {
  onClickAddCallback?: () => void;
}
export const CommentsContainer = ({
  onClickAddCallback,
}: CommentsContainerProps) => {
  const engagementDataAPI = useEngagementData();
  const commentsAPI = useComments();
  const usersAPI = useUsers();

  useEffect(() => {
    if (commentsAPI) commentsAPI.setCommentTitle("Comments");
  }, [commentsAPI]);

  if (!engagementDataAPI || !commentsAPI || commentsAPI.isLoadingComments)
    return (
      <div className="flex flex-auto items-center justify-center h-72vh">
        <div className="flex items-center justify-center w-1/2">
          <SprxLoading />
        </div>
      </div>
    );

  const threads = Array.isArray(commentsAPI?.comments)
    ? commentsAPI.comments
        .map(transposeComments)
        .sort((a, b) => {
          const dateOne = new Date(a[0]?.date).valueOf();
          const dateTwo = new Date(b[0]?.date).valueOf();
          return dateTwo - dateOne;
        })
        .filter((e) => e !== null)
    : [];

  if (threads.length === 0) {
    return (
      <div className="flex h-72vh w-full items-center justify-center">
        <div>There are no comments at this time.</div>
      </div>
    );
  }

  const panelItems = threads.map((thread: any) => {
    return {
      ...thread[0],
      commentID: thread[0].commentID,
      title: `${thread[0].title} - ${formatTimestamp(thread[0].date)}`,
      isResolved: thread[0].status === "RESOLVED",
    };
  });

  const openPanelItems = panelItems.filter((item) => !item.isResolved);
  const resolvedPanelItems = panelItems.filter((item) => item.isResolved);

  return (
    <Comments
      isAdmin={usersAPI.user.role === "admin"}
      threads={threads}
      onDeleteComment={commentsAPI.onDeleteComment}
      onAddNote={commentsAPI.onAddNote}
      isAddingNote={commentsAPI.isAddingNote}
      onResolveComment={commentsAPI.onResolveComment}
      isResolvingComment={commentsAPI.isResolvingComment}
      isDeletingComment={commentsAPI.isDeletingComment}
      openPanelItems={openPanelItems}
      resolvedPanelItems={resolvedPanelItems}
    />
  );
};
