import * as React from "react";

export type CreateEngagementDataType = (data: any) => Promise<void>;

export type UpdateEngagementDataType = (data: any) => Promise<void>;

export type LazyGetEngagementDataType = (data: any) => Promise<void>;

type EngagementDataError = {
  message: string;
  code: number;
} & any;

export type EngagementDataContextType = {
  // Data Arrays
  status: any; // replace 'any' with a more specific type if known
  engagementData: any;
  lazyEngagementData: any;
  engagementList: any[];

  // Functions
  createEngagementData: CreateEngagementDataType; // be more specific if possible
  updateEngagementData: UpdateEngagementDataType; // be more specific if possible
  lazyGetEngagementData: LazyGetEngagementDataType; // be more specific if possible
  onListEngagementsFromCompanyID: () => Promise<void>;
  markAsTracked: (value: string, key: string) => Promise<void>;

  // Booleans
  isLoadingEngagementData: boolean;
  isLazyLoadingEngagementData: boolean;
  isCreatingEngagementData: boolean;
  isUpdatingEngagementData: boolean;
  isLoadingEngagementList: boolean;
  isGettingStatus: boolean;
  isLoadingCompanyEngagementList: boolean;
  isGettingEngagementData: boolean;
  isListingEngagements: boolean;

  // Errors
  engagementDataErrors: EngagementDataError[]; // replace 'any' with a more specific error type if known
};

const defaultEngagementData: EngagementDataContextType = {
  // Data Arrays
  status: null,
  engagementData: null,
  lazyEngagementData: null,
  engagementList: [],

  // Functions
  createEngagementData: () => Promise.resolve(undefined),
  updateEngagementData: () => Promise.resolve(undefined),
  lazyGetEngagementData: () => Promise.resolve(undefined),
  onListEngagementsFromCompanyID: () => Promise.resolve(undefined),
  markAsTracked: () => Promise.resolve(undefined),

  // Booleans
  isLoadingEngagementData: true,
  isLazyLoadingEngagementData: false,
  isCreatingEngagementData: false,
  isUpdatingEngagementData: false,
  isLoadingEngagementList: false,
  isGettingStatus: false,
  isLoadingCompanyEngagementList: false,
  isGettingEngagementData: false,
  isListingEngagements: false,

  // Errors
  engagementDataErrors: [],
};

const EngagementDataContext = React.createContext<EngagementDataContextType>(
  defaultEngagementData
);

export default EngagementDataContext;
