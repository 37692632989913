import * as React from "react";
import { Worker } from "@react-pdf-viewer/core";
import { DownloadIcon } from "@heroicons/react/outline";
import { Viewer } from "@react-pdf-viewer/core";
import "@react-pdf-viewer/core/lib/styles/index.css";

interface IPDFViewerProps {
  docUrl: string;
}

export const PDFViewer = ({ docUrl }: IPDFViewerProps) => {
  const onDownload = () => window.open(docUrl, "_blank");
  return (
    <Worker workerUrl="https://unpkg.com/pdfjs-dist@3.4.120/build/pdf.worker.min.js">
      <div className="flex justify-center items-center p-8 relative">
        {" "}
        {/* Relative positioning context */}
        <div className="w-3/4 h-76vh overflow-auto relative">
          <Viewer fileUrl={docUrl} />
          {/* Footer with download button */}
          <div className="absolute bottom-0 right-16 mb-4 mr-4">
            <button
              className="flex items-center justify-center p-2 text-white bg-blue-600 hover:bg-blue-700 rounded-full shadow-lg"
              onClick={() => onDownload()}
              aria-label="Download"
            >
              <DownloadIcon className="w-6 h-6" />
            </button>
          </div>
        </div>
      </div>
    </Worker>
  );
};
