import { useState } from "react";
import { DefaultPhoto } from "../../../../Profile/DefaultPhoto";

interface CommentInputFormProps {
  onAddNote: (commentID: string, comment: string) => void;
  commentID: string;
  isAddingNote: boolean;
  buttons: React.ReactNode;
}

export const CommentInputForm = ({
  commentID,
  onAddNote,
  isAddingNote,
  buttons,
}: CommentInputFormProps) => {
  const [message, setMessage] = useState<string>("");
  const handleSend = () => {
    onAddNote(commentID, message);
    setMessage("");
  };

  return (
    <div className="mt-6 flex gap-x-3">
      <div className="relative flex-auto">
        <div className="overflow-hidden rounded-lg pb-12 shadow-sm ring-1 ring-inset ring-gray-300 focus-within:ring-2 focus-within:ring-indigo-600">
          <label htmlFor="comment" className="sr-only">
            Add your comment
          </label>
          <textarea
            rows={2}
            name="comment"
            id="comment"
            className="block w-full resize-none border-0 bg-transparent py-1.5 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm sm:leading-6"
            placeholder="Add your comment..."
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className="absolute inset-x-0 bottom-0 flex justify-between py-2 pl-3 pr-2">
          <div>{buttons}</div>
          <button
            disabled={!message}
            onClick={handleSend}
            className="w-24 h-10 inline-flex items-center px-4 py-2 ml-4 text-sm font-medium text-white bg-indigo-600 border border-transparent rounded-md shadow-sm hover:bg-indigo-700 focus:outline-none"
          >
            {isAddingNote ? (
              <div className="animate-pulse ml-2 mt-2 mb-2 h-2 bg-white rounded w-12"></div>
            ) : (
              "Comment"
            )}
          </button>
        </div>
      </div>
    </div>
  );
};
