import { Tracker } from "./Tracker";
import { useListEngagementDataQuery } from "../../../api/engagmentAPI";
import { useState } from "react";
import { TrackerContainerLoading } from "./components/TrackerContainerLoading";
import { useUsers } from "../../../rtk/Users/useUsers";
import { formatDateToYYYYMMDD, mapEngagement } from "./utils";

export const TrackerContainer = () => {
  const { user } = useUsers();
  const tomorrow = new Date(new Date().setDate(new Date().getDate() + 2));
  const startDate = new Date(
    new Date().setFullYear(new Date().getFullYear() - 1),
  );
  const [query, setQuery] = useState("");
  const [masterFilter, setMasterFilter] = useState({
    reps: [user?.role === "sales" ? user?.userID : undefined].filter((u) => u),
    companies: [],
    dates: {
      start: formatDateToYYYYMMDD(startDate),
      end: formatDateToYYYYMMDD(tomorrow),
    },
    datesFilterType: "Created Date",
  });

  const {
    data: engagements,
    error,
    isLoading,
    isFetching,
  } = useListEngagementDataQuery({
    ...masterFilter,
    engagementName: query,
  });

  if (isLoading) return <TrackerContainerLoading />;
  if (error) {
    console.error(error);
    return (
      <div className="flex h-full flex-col items-center justify-center">
        <h1 className="text-xl font-semibold text-gray-900">
          Error fetching your tracker engagements.
        </h1>
        <p>
          Refresh the page and contact the SPRX team if you are still having
          issues.
        </p>
      </div>
    );
  }

  const engagementsWithTrackers = engagements.map((eng: any, index: number) =>
    mapEngagement(eng, index),
  );
  return (
    <Tracker
      engagements={engagementsWithTrackers}
      query={query}
      setQuery={setQuery}
      isQuerying={isFetching}
      masterFilter={masterFilter}
      setMasterFilter={setMasterFilter}
    />
  );
};
