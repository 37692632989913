import { EngagementsList } from "./EngagementsList";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useListEnergyEngagementsFromCompanyIdQuery } from "../../../api/energy/engagementAPI";
import { useListCompaniesQuery } from "../../../api/companyAPI";
import { useState } from "react";
import { CompaniesList } from "../../../ui-components/CompaniesList";
import { ListViewLoading } from "../../../ui-components/ListViewLoading";
import { EngagementListEmpty } from "../../../ui-components/EngagementListEmpty";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { ListViewError } from "../../../ui-components/ListViewError";

export const EnergyEngagementsAndCompaniesListContainer = ({
  providedCompanyID,
  providedCompanyName,
  closeEngagement,
}: {
  providedCompanyID?: string;
  providedCompanyName?: string;
  closeEngagement?: () => void;
}) => {
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const usersAPI: any = useUsers();
  const selectedCompany = providedCompanyID ?? usersAPI?.user?.companyID;
  const isSprxEmployee =
    selectedCompany === process.env.REACT_APP_DEFAULT_COMPANY &&
    usersAPI?.user?.role === "admin";
  const companyDataAPI = useCompanyData();
  const {
    data: engagements,
    isLoading: isListingEngagements,
    error: listEngagementsError,
  } = useListEnergyEngagementsFromCompanyIdQuery({
    companyID: selectedCompany,
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useListCompaniesQuery(companyNameFilter);

  const isLoading =
    !usersAPI ||
    // usersAPI.isGettingUser ||
    isListingEngagements ||
    (isLoadingCompanies && isSprxEmployee);

  const hasError = listEngagementsError || companiesError;

  if (isLoading) return <ListViewLoading />;

  if (hasError) return <ListViewError type="Energy" />;

  if (isSprxEmployee && !providedCompanyID)
    return (
      <CompaniesList
        type="Energy"
        companies={companies}
        companyNameFilter={companyNameFilter}
        setCompanyNameFilter={setCompanyNameFilter}
        ListContainer={EnergyEngagementsAndCompaniesListContainer}
      />
    );

  if (engagements?.length === 0)
    return (
      <EngagementListEmpty
        type="Engagement"
        companyName={
          usersAPI.user.role === "admin"
            ? providedCompanyName
            : companyDataAPI.companyData.companyName
        }
        closeEngagement={closeEngagement ? closeEngagement : null}
      />
    );

  return (
    <EngagementsList
      companyName={
        usersAPI.user.role === "admin"
          ? providedCompanyName
          : companyDataAPI.companyData.companyName
      }
      engagements={engagements}
      closeEngagement={closeEngagement ? closeEngagement : null}
    />
  );
};
