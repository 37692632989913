export const hydrateQuestions = ({
  companyName,
  employeeName,
  jobTitle,
  qPercentage,
}) => {
  return [
    {
      text: `Hello, ${employeeName}, this is the SPRX Virtual Assistant. We are finalizing the R&D Credit for ${companyName} and would like you to help validate some information This should only take about 3 minutes.`,
      role: "assistant",
      type: "text",
      next: 1,
    },
    {
      text: `The first task is to determine if you participate in qualified research. Please select from the list below the activities that you may perform in your role as ${jobTitle}`,
      multiple: true,
      type: "checkbox",
      role: "assistant",
      id: "activities",
      copy: "I'm done.",
      next: 2,
    },
    {
      text: "Great, thanks!",
      type: "text",
      role: "assistant",
      next: 3,
    },
    {
      text: `Next, based on the information that I have, it looks like you spend ${qPercentage}% of your time on those activities. Does that seem right to you?`,
      type: "yesNo",
      role: "assistant",
      id: "qualified_percentage",
      next: {
        yes: 5,
        no: 4,
      },
    },
    {
      text: "No problem!\nEnter a number between 0 and 100 that best represents the time you spent on those activities.",
      type: "number",
      role: "assistant",
      id: "qualified_percentage",
      next: 5,
    },
    {
      text: "Awesome!",
      type: "text",
      role: "assistant",
      next: 6,
    },
    {
      text: `${companyName} provided us with a list of projects. From the list below, select the projects that you worked on.`,
      multiple: true,
      type: "checkbox",
      id: "projects",
      role: "assistant",
      copy: "I'm done.",
      next: 7,
    },
    {
      text: "Almost done!\nWe are going to show you a table of the projects that you selected. Please allocate the time you spent on those projects between them. Enter in the input field a number between 0 and 100 that best represents the percentage of time you spent on each project. Your total allocation should equal 100%.",
      multiple: true,
      type: "number",
      id: "projects_percentage",
      role: "assistant",
      copy: "I'm done.",
      next: 8,
    },
    {
      text: "That’s it! Thank you for your time. All of your responses have been saved. Feel free to close your browser window.",
      role: "assistant",
      type: "text",
      next: undefined,
    },
  ];
};
