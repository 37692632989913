import * as yup from "yup";

export const getCreateEngagementFormShape = () => {
  return yup.object().shape({
    companyName: yup.object().shape({
      value: yup.string().required("Value is required"),
      label: yup.string().required("Label is required"),
    }),
    engagementName: yup.string().required("Please enter an engagement name."),
    clientName: yup.string().required("Please enter a client name."),
    clientEIN: yup.string().required("Please enter a client EIN."),
    CPAName: yup.string(),
    taxYearStart: yup.string().required("Please enter a tax year start date."),
    taxYearEnd: yup.string().required("Please enter a tax year end date."),
    dueDate: yup.string(),
    feeType: yup.string(),
    feeValue: yup.number(),
    isRollover: yup.boolean(),
    engagement: yup.string(),
    entityName: yup.boolean(),
    entityEIN: yup.boolean(),
    entityType: yup.boolean(),
    pyQres: yup.boolean(),
    pyGrs: yup.boolean(),
    elections: yup.boolean(),
    wageQre: yup.boolean(),
    supplyQre: yup.boolean(),
    contractorQre: yup.boolean(),
    computerQre: yup.boolean(),
    projectQre: yup.boolean(),
    salesRepID: yup.object().shape({
      value: yup.string().required("Value is required"),
      label: yup.string().required("Label is required"),
    }),
  });
};
