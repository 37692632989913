import Lottie from "lottie-react";
import * as animationData from "./animations/10836-isometric-tech-animation.json";
import { TextLoop } from "./";


export const AiLoadingAnimation = (props) => {
    const rotatingText = props?.rotatingText ? props.rotatingText : [
        "Pinging skynet",
        "Conferring with the oracle",
        "Accessing the infinite knowledge of the internet",
        "Prodding the singularity"
    ];

    return (
        <div className="flex flex-col items-center">
            <TextLoop texts={rotatingText} />
            <Lottie
                animationData={animationData}
                loop={true}
                style={{
                    height: 400, width: 400,
                }}
            />
        </div>
    );
};