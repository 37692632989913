import { Button } from "../../../../../ui-components";
import { OpenActionCard } from "../../../../../ui-components/OpenActionCard";
import { useState } from "react";
import { CommentsContainer, CommentForm } from "../../../components";
import { useEnergyComments } from "../../../../../rtk/Energy/Comments/useEnergyComments";

export const EnergyModel = () => {
  const [openCreateComment, setOpenCreateComment] = useState(false);
  const commentsAPI = useEnergyComments();

  const CommentFormProps = {
    id: 1,
    title: "Comment",
    description: "Add a comment.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentForm
        isCreatingComment={commentsAPI.isCreatingComment}
        onSubmitForm={async (e: any) => {
          await commentsAPI.onCreateComment({
            title: e.title,
            comment: e.comment,
            role: "modeler",
          });
          setOpenCreateComment(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };

  return (
    <div className="flex flex-col w-full mt-2 pl-4 ">
      <CommentsContainer
        portalType="MODEL"
        onClickAddCallback={() => setOpenCreateComment(true)}
      />
      <OpenActionCard
        open={openCreateComment}
        setOpen={setOpenCreateComment}
        selectedID={CommentFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />
    </div>
  );
};
