import React from "react";
import classNames from "classnames";

import { Button, SprxLoading } from "./";

const ReviewTiles = (props) => {
  const {
    fedRecompute,
    stateRecompute,
    recomputeFedCredit,
    recomputeStateCredit,
    renderSection,
    loading,
    fedCredit,
    stateCredit,
    wageQRE,
    suppyQRE,
    crQRE,
    compLeaseQRE,
  } = props;

  const containerStyle =
    fedRecompute || stateRecompute
      ? "bg-white overflow-hidden elevation-2 rounded-lg h-24"
      : "bg-white overflow-hidden elevation-2 rounded-lg h-20";

  return (
    <div
      className={classNames(
        "my-4 grid grid-cols-1 gap-3",
        renderSection === "Projects" ||
          renderSection === "Elections" ||
          renderSection === "State"
          ? "sm:grid-cols-2"
          : "sm:grid-cols-3"
      )}
    >
      <div className={containerStyle}>
        <div className="px-4 py-5 sm:p-4">
          {fedRecompute ? (
            <dl className="fedCredit">
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Federal Credit
              </dt>
              <dd className="mt-1 h-32">
                <Button primary onClick={() => recomputeFedCredit()}>
                  {loading ? (
                    <div className="flex justify-center items-center h-full w-full">
                      <SprxLoading height={"100%"} width={"6%"} />
                    </div>
                  ) : (
                    "Recompute"
                  )}
                </Button>
              </dd>
            </dl>
          ) : (
            <dl className="fedCredit">
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Federal Credit
              </dt>
              {loading ? (
                <div className="flex h-full w-full">
                  <SprxLoading height={"100%"} width={"6%"} />
                </div>
              ) : (
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                  {"$" +
                    fedCredit
                      ?.toFixed()
                      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              )}
            </dl>
          )}
        </div>
      </div>

      <div className={containerStyle}>
        <div className="stateCredit px-4 py-5 sm:p-4">
          {stateRecompute ? (
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                State Credit
              </dt>
              <dd className="mt-1">
                <Button primary onClick={() => recomputeStateCredit()}>
                  {loading ? (
                    <div className="flex justify-center items-center h-full w-full">
                      <SprxLoading height={"100%"} width={"6%"} />
                    </div>
                  ) : (
                    "Recompute"
                  )}
                </Button>
              </dd>
            </dl>
          ) : (
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                State Credit
              </dt>

              {loading ? (
                <div className="flex h-full w-full">
                  <SprxLoading height={"100%"} width={"6%"} />
                </div>
              ) : (
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                  {"$" +
                    stateCredit
                      ?.toFixed()
                      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              )}
            </dl>
          )}
        </div>
      </div>

      {renderSection === "Wages" && (
        <div className={containerStyle}>
          <div className="qre px-4 py-5 sm:p-4">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Wage QRE
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  wageQRE?.toFixed()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          </div>
        </div>
      )}
      {renderSection === "Supply" && (
        <div className={containerStyle}>
          <div className="px-4 py-5 sm:p-4">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                Supply QRE
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  suppyQRE
                    ?.toFixed()
                    ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          </div>
        </div>
      )}
      {renderSection === "Contract Research" && (
        <div className={containerStyle}>
          <div className="px-4 py-5 sm:p-4">
            <dl>
              <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                CR QRE (65%)
              </dt>
              <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                {"$" +
                  crQRE?.toFixed()?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
              </dd>
            </dl>
          </div>
        </div>
      )}

      {renderSection === "Computer Lease" && (
        <div>
          <div className={containerStyle}>
            <div className="px-4 py-5 sm:p-4">
              <dl>
                <dt className="text-sm leading-5 font-medium text-gray-500 truncate">
                  Computer Rental QRE
                </dt>
                <dd className="mt-1 text-xl leading-9 font-semibold text-gray-900">
                  {"$" +
                    compLeaseQRE
                      ?.toFixed()
                      ?.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,")}
                </dd>
              </dl>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ReviewTiles;
