import { CertificationPortal } from "./CertificationPortal";
import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { PortalContainerLoading } from "../components";

export const CertificationPortalContainer = () => {
  const dispatch = useDispatch();

  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();

  if (!energyEngagementAPI || !usersAPI) return <PortalContainerLoading />;

  return <CertificationPortal user={usersAPI.user} />;
};
