// export const WelcomeMessage = () => (
//   <div className="flex flex-row w-1/2 py-4 justify-between h-16vh">
//     <div className="flex flex-col w-full rounded-2xl p-2 bg-sprxClientPortalLightTeal mr-2">
//       <h1 className="text-xl font-semibold text-gray-900">Welcome!</h1>
//       <p className="text-sm pr-16 text-gray-900 overflow-hidden"></p>
//     </div>
//   </div>
// );

export const WelcomeMessage = ({ name }: { name: string }) => {
  return (
    <div className="flex w-1/2 py-4">
      <div className="flex flex-col w-full rounded-2xl p-4 bg-sprxClientPortalLightTeal">
        <h1 className="text-xl font-semibold text-gray-900">Welcome {name}!</h1>
        <p className="text-sm mt-2 text-gray-900">
          Explore your Energy Deduction data with our Reports portal. You can
          view your deliverables, download prepared documentation, and chat with
          your personalized AI tax assistant.
        </p>
      </div>
      {/* <div className="flex -ml-64">
        <img
          style={{ width: 410.25, height: 136.125 }}
          src={"assets/images/Dashboard_Illustration.svg"}
          alt="SPRX.tax"
        />
      </div> */}
      {/*Conditional submit box */}
    </div>
  );
};
