import { Menu, Transition } from "@headlessui/react";
import { Fragment } from "react";
import { ChevronDownIcon } from "@heroicons/react/solid";

export default function SelectorHeader({
  currentItem,
  items,
  onSelectItem,
  type,
}) {
  return (
    <div className="z-40 mr-8 flex w-full justify-between text-right">
      <Menu as="div" className="relative ml-4 inline-block text-left">
        {({ open }) => (
          <>
            <div className="flex h-full w-full flex-row items-center">
              <Menu.Button className="inline-flex h-full w-full justify-center rounded-md px-4 py-2 text-sm font-medium text-white hover:bg-gray-200 focus:outline-none focus-visible:ring-2 focus-visible:ring-white/75">
                <h1 className="text-2xl font-semibold text-gray-900">
                  {currentItem} {type === "report" ? "Reports" : ""}
                </h1>
                <div className="ml-4 flex h-full items-center justify-center">
                  {items?.length > 0 ? (
                    <ChevronDownIcon
                      className={`${
                        open ? "rotate-180 transform" : ""
                      } h-5 w-5 text-black`}
                    />
                  ) : null}
                </div>
              </Menu.Button>
            </div>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-100"
              enterFrom="transform opacity-0 scale-95"
              enterTo="transform opacity-100 scale-100"
              leave="transition ease-in duration-75"
              leaveFrom="transform opacity-100 scale-100"
              leaveTo="transform opacity-0 scale-95"
            >
              <Menu.Items className="absolute right-0 mt-2 max-h-160 w-full origin-top-right divide-y divide-gray-200 overflow-y-auto rounded-md bg-gray-100 shadow-lg ring-1 ring-black/5 focus:outline-none">
                {items?.map((item, index) => {
                  const { id, name, dataSpaceName } = item;
                  return (
                    <div key={index} className="px-1 py-1">
                      <Menu.Item>
                        {({ active }) => (
                          <button
                            onClick={() => onSelectItem(id)}
                            className={`${
                              active
                                ? "bg-sprxClientPortalLightBlue text-white"
                                : "text-gray-900"
                            } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                          >
                            {type === "report" ? name : dataSpaceName}
                          </button>
                        )}
                      </Menu.Item>
                    </div>
                  );
                })}
              </Menu.Items>
            </Transition>
          </>
        )}
      </Menu>
    </div>
  );
}
