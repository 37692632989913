import { Controller } from "react-hook-form";
import { motion } from "framer-motion";
import { currencyFormatter } from "../../utils";

const roundAccurately = (number) => {
  const decimalPlaces = 4;
  return Number(
    Number(
      Math.round(number + "e" + decimalPlaces) + "e-" + decimalPlaces
    ).toFixed(decimalPlaces)
  );
};

const DisabledNumberField = ({ value, loading, format }) => {
  let val;
  if (typeof value === "boolean") {
    val = value ? "x" : "";
  } else {
    switch (format) {
      case "percentage":
        val = isNaN(Number(value)) ? value : `${roundAccurately(value * 100)}%`;
        break;
      case "year":
        val = value;
        break;
      case "number":
        val = value;
        break;
      default:
        val = isNaN(Number(value))
          ? value
          : currencyFormatter.format(Number(value));
        break;
    }
  }
  return (
    <div
      className={
        "w-32 sm:text-sm rounded-md h-8  border-1 border-black-300 items-center justify-start flex text-gray-500 pl-3"
      }
    >
      {loading ? (
        <div className="animate-pulse">
          <div className="h-2 w-8 bg-gray-500 rounded-md"></div>
        </div>
      ) : (
        val
      )}
    </div>
  );
};

const FieldInput = ({ field, id, loading }) => {
  if (loading) {
    <div
      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
      whileHover={{ scale: 1.01 }}
    >
      <div className="animate-pulse h-8 w-32 bg-gray-100 rounded-md"></div>
    </div>;
  }

  return (
    <motion.input
      {...field}
      id={id}
      data-test={id}
      name={id}
      type="number"
      className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
      whileHover={{ scale: 1.01 }}
    />
  );
};

export const NumberInputField = ({
  id,
  control,
  label,
  errors,
  subtext,
  loading,
  ...rest
}) => {
  const format = rest?.format ? rest.format : "currency";
  const span = rest?.spanOverride ? rest.spanOverride : "col-span-12";
  const disabled = rest?.disabled ? rest.disabled : false;
  const hasError = errors?.hasOwnProperty(id) ? true : false;
  const orientation = disabled ? "flex-row" : "flex-col";

  return (
    <Controller
      name={id}
      control={control}
      render={({ field }) => (
        <div
          className={`flex ${span} ${orientation} justify-between mb-2`}
          data-test={`line-${id}-div`}
        >
          <div className="flex flex-col">
            <div className="flex flex-row">
              <label
                htmlFor={`${id}`}
                className="block text-sm font-medium text-gray-700"
              >
                {label}
              </label>
            </div>
            <p className="block text-sm font-sm text-gray-500">{subtext}</p>
          </div>
          <div className="mt-1">
            {disabled ? (
              <DisabledNumberField
                value={field.value}
                loading={loading}
                format={format}
              />
            ) : (
              <FieldInput field={field} id={id} loading={loading} />
            )}
            {!disabled && hasError && (
              <p className="pl-2 text-sprxOrange">Invalid {label}</p>
            )}
          </div>
        </div>
      )}
    />
  );
};
