import { EXCLUDED_FIELDS } from "../../../../main/R&D/ReviewPortal/utils/constants";

export const appendAllocationCheck = (item: any) => {
  let allocationKeys = undefined;
  if (!allocationKeys) {
    allocationKeys = Object.keys(item).filter(
      (a) => EXCLUDED_FIELDS.indexOf(a) === -1
    );
  }
  const allocations = allocationKeys
    .map((key) => item[key])
    .filter((a) => typeof a === "number");
  const sum =
    allocations.length > 0
      ? Math.round(allocations.reduce((a, b) => a + b, 0))
      : 0;

  const isNaN = typeof sum === "undefined";
  if (item["Employee Name"] === "A PAUL GUTIERREZ") {
    console.log({ allocations, isNaN });
  }
  const isUnderAllocated = sum !== 100 && item.qPercentage !== 0;
  const isOverAllocated = sum > 100 && item.qPercentage !== 0;
  const zeroQRE = sum !== 0 && item.qPercentage === 0;
  const isAccurate =
    !isUnderAllocated && !zeroQRE && !isOverAllocated && !isNaN;
  return {
    ...item,
    status: isAccurate
      ? "Accurate"
      : isNaN
      ? "Error Parsing"
      : zeroQRE
      ? "Zero Q%"
      : isOverAllocated
      ? "Over Allocated"
      : "Under Allocated",
    sum: sum,
  };
};
