import { DataSpacesList } from "./DataSpacesList";
import { useUsers } from "../../../rtk/Users/useUsers";
import { ListViewError } from "../../../ui-components/ListViewError";
import { useListEnergyEngagementsFromCompanyIdQuery } from "../../../api/energy/engagementAPI";
import { useListDataSpacesQuery } from "../../../api/dataSpacesAPI";
import { useListCompaniesQuery } from "../../../api/companyAPI";
import { useState } from "react";
import { CompaniesList } from "../../../ui-components/CompaniesList";
import { ListViewLoading } from "../../../ui-components/ListViewLoading";
import { EngagementListEmpty } from "../../../ui-components/EngagementListEmpty";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { useDataSpaces } from "../../../rtk/DataSpaces/useDataSpaces";

export const DataSpacesAndCompaniesListContainer = ({
  providedCompanyID,
  providedCompanyName,
  closeEngagement,
}: {
  providedCompanyID?: string;
  providedCompanyName?: string;
  closeEngagement?: () => void;
}) => {
  const [companyNameFilter, setCompanyNameFilter] = useState("");
  const usersAPI: any = useUsers();
  const selectedCompany = providedCompanyID ?? usersAPI?.user?.companyID;
  const isSprxEmployee =
    selectedCompany === process.env.REACT_APP_DEFAULT_COMPANY &&
    usersAPI?.user?.role === "admin";
  const companyDataAPI = useCompanyData();

  const {
    data: dataSpaces,
    isLoading: isListingDataSpaces,
    error: listDataSpacesError,
  } = useListDataSpacesQuery({
    companyID: selectedCompany,
  });

  const {
    data: companies,
    isLoading: isLoadingCompanies,
    error: companiesError,
  } = useListCompaniesQuery(companyNameFilter);

  const isLoading =
    !usersAPI ||
    // usersAPI.isGettingUser ||
    isListingDataSpaces ||
    (isLoadingCompanies && isSprxEmployee);

  const hasError = listDataSpacesError || companiesError;

  if (isLoading) return <ListViewLoading />;

  if (hasError) return <ListViewError type="Data Space" />;

  if (isSprxEmployee && !providedCompanyID)
    return (
      <CompaniesList
        type="Data Space"
        companies={companies}
        companyNameFilter={companyNameFilter}
        setCompanyNameFilter={setCompanyNameFilter}
        ListContainer={DataSpacesAndCompaniesListContainer}
      />
    );

  if (dataSpaces?.length === 0)
    return (
      <EngagementListEmpty
        type="Data Space"
        companyName={
          usersAPI.user.role === "admin"
            ? providedCompanyName
            : companyDataAPI.companyData.companyName
        }
        closeEngagement={closeEngagement ? closeEngagement : null}
      />
    );

  return (
    <DataSpacesList
      companyName={
        usersAPI.user.role === "admin"
          ? providedCompanyName
          : companyDataAPI.companyData.companyName
      }
      dataSpaces={dataSpaces}
      closeEngagement={closeEngagement ? closeEngagement : null}
    />
  );
};
