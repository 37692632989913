import React from "react";
import { useFinchConnect } from '@tryfinch/react-connect';

const FinchButton = (props) => {
  const onSuccess = ({ code }) => {
    props.onSuccess(code);
  };
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log("User exited Finch Connect");

  const { open } = useFinchConnect({
    clientId: "d3ccc21f-ef51-4545-bf23-d321c05b79e3",
    products: ["payment", "company", "directory", "individual", "employment", "pay_statement"],
    sandbox: process.env.REACT_APP_STAGE === "prod" ? false : true,
    onSuccess,
    onError,
    onClose,
  });

  return <div onClick={() => open()}>{props.children}</div>;
};

export default FinchButton;
