import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useMemo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "../../../ui-components";
import { isDev, classNames } from "../../R&D/utils";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import Select from "react-select";
import { states } from "../../../ui-components";
import _ from "lodash";

const getDefaultEditUserValues = (user, engagementList) => {
  let engagement =
    engagementList?.find(
      (a) => a?.engagementID === user?.data?.activeEngagementID,
    )?.engagementID ?? undefined;
  return {
    email: user?.email ?? "",
    fullName: user?.fullName ?? "",
    role: user?.role ?? "client",
    hasDownloadPrivileges: user?.hasDownloadPrivileges ?? false,
    engagement,
    engagementType: user?.engagementType,
    companyID: user?.companyID ?? "",
    states: user?.states || [],
  };
};

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("You must enter a email"),
  fullName: yup.string().required("Please enter a name."),
  role: yup.string().required("Please add a role"),
  engagement: yup.string(),
  companyID: yup.string().required("Please select a company"),
  hasDownloadPrivileges: yup.boolean(),
  engagementType: yup.string(),
  states: yup.array().of(
    yup.object().shape({
      value: yup.string(),
      label: yup.string(),
    }),
  ),
});

const USER_ROLES = ["client", "sales", "modeler", "certifier"];

export const EditUserForm = (props) => {
  const { open, setOpen, user } = props;

  const { engagementList } = useEngagementData();
  const companyAPI = useCompanyData();

  const { updateTaxUser, isUpdatingUser } = useUsers();

  const { control, formState, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultEditUserValues(user, engagementList);
    }, [engagementList, user]),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getDefaultEditUserValues(user, engagementList));
    return () => {
      reset(getDefaultEditUserValues(user, engagementList));
    };
  }, [user, reset, engagementList]);

  const { errors, isValid, dirtyFields } = formState;

  const engagementType = watch("engagementType");
  const roleType = watch("role");

  const onSubmit = async (model) => {
    const { userID } = user;
    const activeEngagementID = model.engagement;
    // const createUserResponse = await createUser({ body });
    // if (isDev) console.log({ createUserResponse });
    const body = {
      fullName: model.fullName,
      role: model.role,
      companyID: model.companyID,
      email: model.email,
      activeEngagementID: activeEngagementID,
      hasDownloadPrivileges: model.hasDownloadPrivileges,
      states: model.states,
    };
    const updateUserResponse = await updateTaxUser({ userID, body });
    if (isDev) console.log({ updateUserResponse });
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-40"
          static
          layoutId={1234}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="min-w-1/3 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <Dialog.Panel className="relative min-h-30vh w-1/3 transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                <motion.div>
                  {/*Modal title, description, and close button */}
                  <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <form
                    className="mt-5 w-full sm:flex sm:flex-col sm:items-center"
                    onSubmit={handleSubmit((model) => onSubmit(model))}
                  >
                    <motion.div className="flex w-full flex-col items-center justify-center text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="p-4 text-left text-3xl font-medium leading-6 text-gray-900"
                      >
                        Edit User
                      </Dialog.Title>
                      <motion.div className="flex w-full flex-col items-center justify-center">
                        <Controller
                          name="fullName"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="fullName">Name</label>
                              <input
                                {...field}
                                type="text"
                                name="fullName"
                                id="fullName"
                                required
                                autoComplete="fullName"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                                placeholder="Jane Doe"
                              />
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("fullName") ? (
                          <p className="pl-2 text-sprxOrange">Invalid name</p>
                        ) : null}
                        <Controller
                          name="email"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="email">Email</label>
                              <input
                                {...field}
                                type="text"
                                name="email"
                                id="email"
                                required
                                autoComplete="email"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                                placeholder="Jane Doe"
                              />
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("email") ? (
                          <p className="pl-2 text-sprxOrange">Invalid email</p>
                        ) : null}
                        <Controller
                          name="role"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 mb-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="role">User Role</label>
                              <select
                                {...field}
                                id="role"
                                name="role"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                              >
                                <option value="none" disabled>
                                  Select an Option
                                </option>

                                {USER_ROLES.map((role) => {
                                  return (
                                    <option value={role} key={role}>
                                      {role}
                                    </option>
                                  );
                                })}
                              </select>
                            </div>
                          )}
                        />
                        <Controller
                          name="companyID"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-2 mb-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="companyID">
                                Assigned Company
                              </label>
                              <select
                                {...field}
                                id="companyID"
                                name="companyID"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                              >
                                <option value="none" disabled>
                                  Select an Option
                                </option>
                                {!companyAPI ||
                                companyAPI?.isListingCompanies === true
                                  ? null
                                  : companyAPI?.companies?.map((company) => {
                                      return (
                                        <option
                                          value={company.companyID}
                                          key={company.companyID}
                                        >
                                          {company.companyName}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                          )}
                        />
                        {roleType === "certifier" && engagementType === "EN" ? (
                          <Controller
                            name="states"
                            control={control}
                            render={({ field }) => (
                              <div className="mt-4 w-full text-left sm:max-w-xs">
                                <label htmlFor="states">States</label>

                                <div className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm">
                                  <Select
                                    {...field}
                                    onChange={(val) => setValue("states", val)}
                                    options={states.map((s) => ({
                                      value: s.value,
                                      label: s.label,
                                    }))}
                                    isMulti
                                  />
                                  {errors?.states && (
                                    <p className="pl-2 text-sprxOrange">
                                      Invalid States
                                    </p>
                                  )}
                                </div>
                              </div>
                            )}
                          />
                        ) : (
                          ""
                        )}
                        {engagementType === "RD" &&
                        roleType !== "certifier" &&
                        !roleType !== "modeler" ? (
                          <Controller
                            name="engagement"
                            control={control}
                            render={({ field }) => (
                              <div className="mt-2 w-full text-left sm:max-w-xs">
                                <label htmlFor="engagement">
                                  Assigned Engagement
                                </label>
                                <select
                                  {...field}
                                  id="engagement"
                                  name="engagement"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                                >
                                  <option value="none" disabled>
                                    Select an Option
                                  </option>
                                  {engagementList
                                    ?.filter(
                                      (a) => a.companyID === user.companyID,
                                    )
                                    ?.map((eng) => {
                                      return (
                                        <option
                                          value={eng.engagementID}
                                          key={eng.engagementID}
                                        >
                                          {eng.engagementName}
                                        </option>
                                      );
                                    })}
                                  {engagementList?.filter(
                                    (a) => a.companyID === user.companyID,
                                  )?.length === 0 ? (
                                    <p className="pl-2 text-sprxOrange">
                                      Please create an engagement for this
                                      company before creating a user
                                    </p>
                                  ) : null}
                                </select>
                              </div>
                            )}
                          />
                        ) : (
                          ""
                        )}
                        <Controller
                          name="hasDownloadPrivileges"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 mb-4 w-full text-left sm:max-w-xs">
                              <div className="relative mt-2 flex items-start">
                                <div className="flex h-5 items-center">
                                  <motion.input
                                    {...field}
                                    value={field.value}
                                    checked={field.value}
                                    id="hasDownloadPrivileges"
                                    type="checkbox"
                                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                                    whileHover={{ scale: 1.05 }}
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="hasDownloadPrivileges"
                                    className="font-medium text-gray-700"
                                  >
                                    Has Download Privileges
                                  </label>
                                  <p className="text-gray-500"></p>
                                  {errors?.disposition && (
                                    <p className="pl-2 text-sprxOrange">
                                      Invalid selection
                                    </p>
                                  )}
                                  {field.value === true ? (
                                    <p className="mt-2 block text-sm font-light text-gray-700">
                                      Please be ensure that this user has the
                                      proper permissions to download files
                                    </p>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("role") ? (
                          <p className="pl-2 text-sprxOrange">Invalid role</p>
                        ) : null}

                        <motion.div className="mt-6 flex w-full flex-row items-end justify-end">
                          <motion.button
                            type="submit"
                            data-test="Save & Close"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            className={
                              "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
                              (!isValid
                                ? "cursor-not-allowed bg-opacity-50"
                                : " hover:bg-sprxClientPortalDarkBlue")
                            }
                          >
                            {isUpdatingUser ? (
                              <div className="flex h-5 w-full flex-col items-center justify-center">
                                <p
                                  className={
                                    "h-2 w-full animate-pulse rounded-md bg-gray-100"
                                  }
                                ></p>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </motion.button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </form>
                </motion.div>
              </Dialog.Panel>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
