import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  PhoneIcon,
  MailIcon,
} from "@heroicons/react/outline";


export const SubmittedModal = (props) => {
    const user = useSelector(({ auth }) => auth.user);
    const navigate = useNavigate();
    const { setSubmitMessage, submitted } = props;

    return (
        <AnimatePresence>
            <Dialog
              className="relative z-50"
              static
              layoutId={8}
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              exit={{ opacity: 0 }}
              as={motion.div}
              open={true}
              onClose={() => {
                if (submitted === true){
                  if (user?.role === "admin"){
                    setSubmitMessage(false);
                  }else if (user?.role === "partner"){
                    navigate("/engagement-list");
                  }else{
                    navigate("/team");
                  };
                };
              }}
            >
              <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
              <motion.div className="fixed inset-0 z-10 overflow-y-auto">
                <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                  {/*Modal content */}
                  <Dialog.Panel className="relative transform overflow-hidden rounded-3xl bg-white px-4 pb-4 text-left shadow-xl transition-all w-4/5 h-425">
                    <motion.div className="flex flex-row justify-between items-center min-w-full min-h-46vh p-16">
                      <div className="flex flex-col min-h-full">
                        <p className="text-5xl font-bold text-gray-900">
                          Thanks!
                        </p>
                        <p className="text-1xl text-gray-90 text-wrap w-3/4 mt-8 ">
                          Your engagement has successfully been submitted. A
                          SPRX team member will contact you shortly. If you need
                          to modify your information, please contact SPRX. Feel
                          free to close this window!
                        </p>
                        <dl className="mt-4 text-base text-gray-500">
                          <div>
                            <dt className="sr-only">Phone number</dt>
                            <dd className="flex">
                              <PhoneIcon
                                className="h-6 w-6 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">(855) 958-5740</span>
                            </dd>
                          </div>
                          <div className="mt-3">
                            <dt className="sr-only">Email</dt>
                            <dd className="flex">
                              <MailIcon
                                className="h-6 w-6 flex-shrink-0 text-gray-400"
                                aria-hidden="true"
                              />
                              <span className="ml-3">support@sprx.tax</span>
                            </dd>
                          </div>
                        </dl>
                      </div>
                      <div className="w-1/2">
                        <img
                          style={{ width: 1000, height: 250 }}
                          src={"assets/images/Thanks_illustration.svg"}
                          alt="SPRX.tax"
                        />
                      </div>
                    </motion.div>
                  </Dialog.Panel>
                </motion.div>
              </motion.div>
            </Dialog>
          </AnimatePresence>
    )
};