import {
  qualifiedProjects,
  STATE_OPTS_V2,
  unqualifiedProjects,
} from "./constants";

import { AgGridCheckbox } from "../../../../ui-components";

const WAGE_COLUMNS = [
  {
    headerName: "Name",
    field: "Employee Name",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Job Title",
    field: "Employee Job Title",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Department",
    field: "Employee Department",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "W2 Wages",
    field: "Employee W2 Wages",
    type: "numericColumn",
    editable: true,
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Employee W2 Wages"];
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },
    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage =
          Number(rowData.data.qPercentage) >= 80
            ? 1
            : Number(rowData.data.qPercentage) / 100;

        rowData.data.qre = qPercentage * Number(rowData.newValue);

        rowData.data["Employee W2 Wages"] = rowData.newValue;
        rowData.value = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Qualified Percentage",
    field: "qPercentage",
    type: "numericColumn",
    editable: true,
    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qPercentage !== undefined) {
        value = rowData?.data?.qPercentage;
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      return `${value}%`;
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage =
          Number(rowData.newValue) >= 80 ? 1 : Number(rowData.newValue) / 100;

        rowData.data.qre =
          qPercentage * Number(rowData.data["Employee W2 Wages"]);

        rowData.data.qPercentage = rowData.newValue;

        return true;
      } else {
        return false;
      }
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Qualified Research Expense",
    field: "qre",
    type: "numericColumn",
    valueGetter: (rowData) => {
      if (rowData?.data?.qPercentage === undefined) return "";
      let qPercentage =
        Number(rowData.data.qPercentage) >= 80
          ? 1
          : Number(rowData.data.qPercentage) / 100;

      return qPercentage * Number(rowData.data["Employee W2 Wages"]);
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qre !== undefined) {
        value = rowData.data.qre;
      } else if (rowData?.value !== undefined && rowData?.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Math.round(Number(value));
      return `$${value.toLocaleString()}`;
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: false,
  },
  {
    headerName: "Location",
    field: "Employee Location",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: STATE_OPTS_V2,
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Processing Completions",
    field: "processCount",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
];

const SUPPLY_COLUMNS = [
  {
    headerName: "Account Name",
    field: "Supply Account Name",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Account Description",
    field: "Supply Account Description",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Account Department",
    field: "Supply Account Department",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Location",
    field: "Supply Location",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: STATE_OPTS_V2,
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Cost",
    field: "Supply Cost",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Supply Cost"];
      } else if (rowData?.value !== undefined && rowData?.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },

    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.data.qPercentage) / 100;
        rowData.data.qre = qPercentage * Number(rowData.newValue);
        rowData.data["Supply Cost"] = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Percentage",
    field: "qPercentage",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qPercentage !== undefined) {
        value = rowData?.data?.qPercentage;
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      return `${value}%`;
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.newValue) / 100;
        rowData.data.qre = qPercentage * Number(rowData.data["Supply Cost"]);
        rowData.data.qPercentage = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Research Expense",
    field: "qre",
    type: "numericColumn",
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: false,
    valueGetter: (rowData) => {
      if (rowData?.data?.qPercentage === undefined) return "";
      let value = 0;
      if (rowData?.data !== undefined) {
        let qPercentage = Number(rowData.data.qPercentage) / 100;
        value = qPercentage * Number(rowData.data["Supply Cost"]);
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      }
      return value;
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qre !== undefined) {
        value = rowData.data.qre;
      } else if (rowData?.value !== undefined && rowData.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Math.round(Number(value));
      return `$${value.toLocaleString()}`;
    },
  },
];

const CONTRACT_COLUMNS = [
  {
    headerName: "Name",
    field: "Contractor Name",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Description",
    field: "Contractor Description",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Department",
    field: "Contractor Department",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Location",
    field: "Contractor Location",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: STATE_OPTS_V2,
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Cost",
    field: "Contractor Cost",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Contractor Cost"];
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },

    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.data.qPercentage) / 100;
        rowData.data.qre = qPercentage * Number(rowData.newValue) * 0.65;
        rowData.data["Contractor Cost"] = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Percentage",
    field: "qPercentage",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qPercentage !== undefined) {
        value = rowData?.data?.qPercentage;
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      return `${value}%`;
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.newValue) / 100;
        rowData.data.qre =
          0.65 * (qPercentage * Number(rowData.data["Contractor Cost"]));
        rowData.data.qPercentage = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Research Expense at 65%",
    field: "qre",
    type: "numericColumn",
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: false,
    valueGetter: (rowData) => {
      if (rowData?.data?.qPercentage === undefined) return "";
      let qPercentage = Number(rowData.data.qPercentage) / 100;
      let qre = 0.65 * (qPercentage * Number(rowData.data["Contractor Cost"]));
      return qre;
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qre !== undefined) {
        value = rowData.data.qre;
      } else if (rowData?.value !== undefined && rowData?.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Math.round(Number(value));
      return `$${value.toLocaleString()}`;
    },
  },
];

const COMPUTER_COLUMNS = [
  {
    headerName: "Account Name",
    field: "Vendor Account Name",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Account Description",
    field: "Vendor Account Description",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Account Department",
    field: "Vendor Account Department",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Location",
    field: "Vendor Location",
    editable: true,
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: STATE_OPTS_V2,
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Cost",
    field: "Vendor Cost",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Vendor Cost"];
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },

    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.data.qPercentage) / 100;
        rowData.data.qre = qPercentage * Number(rowData.newValue);
        rowData.data["Vendor Cost"] = rowData.newValue;

        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Percentage",
    field: "qPercentage",
    type: "numericColumn",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueParser: (rowData) => {
      if (isNaN(Number(rowData.newValue))) {
        return 0;
      } else {
        return Number(rowData.newValue);
      }
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qPercentage !== undefined) {
        value = rowData?.data?.qPercentage;
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      return `${value}%`;
    },
    valueSetter: (rowData) => {
      if (rowData.newValue !== rowData.oldValue) {
        let qPercentage = Number(rowData.newValue) / 100;
        rowData.data.qre = qPercentage * Number(rowData.data["Vendor Cost"]);
        rowData.data.qPercentage = rowData.newValue;
        return true;
      } else {
        return false;
      }
    },
  },
  {
    headerName: "Qualified Research Expense",
    field: "qre",
    type: "numericColumn",
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: false,
    valueGetter: (rowData) => {
      if (rowData?.data?.qPercentage === undefined) return "";
      let qPercentage = Number(rowData.data.qPercentage) / 100;
      return qPercentage * Number(rowData.data["Vendor Cost"]);
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qre !== undefined) {
        value = rowData.data.qre;
      } else if (rowData?.value !== undefined && rowData?.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Math.round(Number(value));
      return `$${value.toLocaleString()}`;
    },
  },
];

const PROJECTS_COLUMNS = [
  {
    headerName: "Name",
    field: "Project Name",
    editable: false,
    cellRenderer: "agGroupCellRenderer",
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Description",
    field: "Project Description",
    editable: true,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Department",
    field: "Project Department",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Location",
    field: "Project Location",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: STATE_OPTS_V2,
    },
  },
  {
    headerName: "Classification",
    field: "Project Classification",
    filter: "agMultiColumnFilter",
    cellEditor: "agSelectCellEditor",
    cellEditorParams: {
      values: ["", ...qualifiedProjects, ...unqualifiedProjects],
    },
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: true,
    valueSetter: (rowData) => {
      let isQualified = false;
      isQualified = qualifiedProjects.indexOf(rowData.newValue) > -1;
      rowData.data.qualified = isQualified;
      rowData.data["Project Classification"] = rowData.newValue;
    },
  },
  {
    headerName: "Cost",
    field: "Project Cost",
    type: "numeric",
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Project Cost"];
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },
  },
  {
    headerName: "Mapped Cost",
    field: "Mapped Cost",
    type: "numeric",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data !== undefined) {
        value = rowData.data["Mapped Cost"];
      } else if (rowData?.value !== undefined) {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Number(value);
      return `$${value.toLocaleString()}`;
    },
  },
];

const SURVEY_COLUMNS = [
  {
    headerName: "Send Survey",
    field: "send",
    valueGetter: (rowData) => {
      return rowData?.data?.send || false;
    },
    editable: "true",
    cellRenderer: AgGridCheckbox,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Name",
    field: "Employee Name",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Department",
    field: "Employee Department",
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Qualified Research Expense",
    field: "qre",
    type: "numericColumn",
    valueGetter: (rowData) => {
      if (rowData?.data?.qPercentage === undefined) return "";
      let qPercentage =
        Number(rowData.data.qPercentage) >= 80
          ? 1
          : Number(rowData.data.qPercentage) / 100;

      return qPercentage * Number(rowData.data["Employee W2 Wages"]);
    },
    valueFormatter: (rowData) => {
      let value = 0;
      if (rowData?.data?.qre !== undefined) {
        value = rowData.data.qre;
      } else if (rowData?.value !== undefined && rowData?.value !== "") {
        value = rowData.value;
      } else {
        return "";
      }
      value = isNaN(Number(value)) ? 0 : Math.round(Number(value));
      return `$${value.toLocaleString()}`;
    },
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
    editable: false,
  },
  {
    headerName: "Email",
    field: "email",
    valueGetter: (rowData) => {
      return rowData?.data?.email || "";
    },
    editable: true,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
  {
    headerName: "Status",
    field: "surveyStatus",
    valueGetter: (rowData) => {
      return rowData?.data?.surveyStatus || "";
    },
    editable: false,
    filter: "agMultiColumnFilter",
    filterParams: {
      buttons: ["reset", "clear"],
      closeOnApply: true,
    },
  },
];

export const getReviewColumns = (type) => {
  switch (type) {
    case "Wages":
      return WAGE_COLUMNS;
    case "WAGES_REVIEW":
      return WAGE_COLUMNS;
    case "Supply":
      return SUPPLY_COLUMNS;
    case "SUPPLY_REVIEW":
      return SUPPLY_COLUMNS;
    case "Contract Research":
      return CONTRACT_COLUMNS;
    case "CONTRACT_REVIEW":
      return CONTRACT_COLUMNS;
    case "Computer Lease":
      return COMPUTER_COLUMNS;
    case "COMPUTER_REVIEW":
      return COMPUTER_COLUMNS;
    case "Projects":
      return PROJECTS_COLUMNS;
    case "Survey":
      return SURVEY_COLUMNS;
    default:
      return [];
  }
};
