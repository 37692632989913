import * as React from "react";
import { ExpenseSchedules } from "./ExpenseSchedules";
import { useFile } from "../../../../../rtk/R&D/File/useFile";

type ScheduleFileName =
  | "Wage Review Template.xlsx"
  | "Supply Review Template.xlsx"
  | "CR Review Template.xlsx"
  | "Comp Lease Review Template.xlsx";

export const ExpenseSchedulesContainer = () => {
  const fileAPI = useFile();

  const onDownloadSchedule = async (templateName: ScheduleFileName) => {
    await fileAPI.onDownloadDocument(templateName);
  };

  if (fileAPI === null) return <div>Loading...</div>;

  return (
    <ExpenseSchedules
      onDownloadSchedule={onDownloadSchedule}
      isDownloading={fileAPI.isGettingBulkEditFile}
    />
  );
};
