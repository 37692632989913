import React, { useState } from "react";
import _ from "lodash";
import classNames from "classnames";

import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import Lottie from "lottie-react";

import * as sendAnimationData from "./send.json";
import * as unlockAnimationData from "./unlock.json";

import { useAuth } from "../../auth/useAuth";

import { SprxLoading } from "../../ui-components";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("You must enter a email"),
});

const codeSchema = yup.object().shape({
  code: yup.string().required("Please enter your login code."),
});

function Login() {
  const { onLoginCode, onLogin } = useAuth();

  const [showCodeEntry, setShowCodeEntry] = useState(false);

  const [email, setEmail] = useState("false");

  const [emailError, setEmailError] = useState(null);

  const [codeError, setCodeError] = useState(null);

  const [loading, setLoading] = useState(false);

  const { control, formState, handleSubmit, reset } = useForm(
    showCodeEntry === false
      ? {
          mode: "onChange",
          defaultValues: {
            email: "",
          },
          resolver: yupResolver(schema),
        }
      : {
          mode: "onChange",
          defaultValues: {
            code: "",
          },
          resolver: yupResolver(codeSchema),
        }
  );

  const { isValid, dirtyFields } = formState;

  async function onSubmit(model) {
    try {
      setLoading(true);

      const res = await onLoginCode({
        email: model.email.toLowerCase(),
      });

      if (res === true) {
        setShowCodeEntry(true);

        setEmail(model.email);

        setLoading(false);

        reset({
          code: "",
        });
      }
    } catch (e) {
      setEmailError(e.data.error);
      setLoading(false);
    }
  }

  async function onSubmitCode(model) {
    try {
      setLoading(true);

      await onLogin({
        email: email,
        code: model.code,
      });
      // if (process.env.REACT_APP_STAGE === "dev") console.log({onLoginResponse});
      setLoading(false);
    } catch (e) {
      setCodeError(e.data.error);
      setLoading(false);
    }
  }

  return (
    <div className="min-h-screen bg-gradient-login flex flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto"
          src="assets/images/White@2x.png"
          alt="SPRX.tax"
        />
        {showCodeEntry ? (
          <h2 className="mt-6 text-center text-3xl font-extralight text-white">
            Enter the code sent to your email to finish logging in
          </h2>
        ) : (
          <h2 className="mt-6 text-center text-3xl font-extralight text-white">
            Sign in to your account
          </h2>
        )}
      </div>

      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          {showCodeEntry ? (
            loading ? (
              <Lottie
                animationData={unlockAnimationData}
                loop={true}
                style={{ height: "100%", width: "100%", alignSelf: "center" }}
              />
            ) : (
              <form className="space-y-6" onSubmit={handleSubmit(onSubmitCode)}>
                <Controller
                  name="code"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div>
                        <label
                          htmlFor="code"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Login Code
                        </label>
                        <div className="mt-1">
                          <input
                            {...field}
                            id="code"
                            data-test="login-passcode"
                            name="code"
                            type="text"
                            required
                            className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                          />
                        </div>
                      </div>
                    );
                  }}
                />

                {codeError !== null ? (
                  <div className="rounded-md bg-red-50 p-4 mt-3 mb-3">
                    <div className="flex">
                      <div className="flex-shrink-0">
                        <svg
                          className="h-5 w-5 text-red-400"
                          fill="currentColor"
                          viewBox="0 0 20 20"
                        >
                          <path
                            fillRule="evenodd"
                            d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                            clipRule="evenodd"
                          />
                        </svg>
                      </div>
                      <div className="ml-3">
                        <h3 className="text-sm leading-5 font-medium text-red-800">
                          There was an error with your submission
                        </h3>
                        <div className="mt-2 text-sm leading-5 text-red-700">
                          <ul className="list-disc pl-5">
                            <li key={Math.random()}>{codeError}</li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : null}

                <div>
                  <button
                    type="submit"
                    data-test="login-passcode-submit"
                    className={classNames(
                      "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sprxBlue hover:bg-blue-500 focus:outline-none",
                      _.isEmpty(dirtyFields) &&
                        "bg-sprxGrey opacity-50 cursor-not-allowed",
                      !isValid && "bg-sprxGrey opacity-50 cursor-not-allowed"
                    )}
                    disabled={_.isEmpty(dirtyFields) || !isValid}
                  >
                    {loading ? (
                      <div className="w-6 h-6 mr-2">
                        <SprxLoading
                          width={"10%"}
                          height={"10%"}
                          color={"#454553"}
                        />
                      </div>
                    ) : null}
                    Submit
                  </button>
                </div>
              </form>
            )
          ) : loading ? (
            <Lottie
              animationData={sendAnimationData}
              loop={true}
              style={{ height: "100%", width: "100%", alignSelf: "center" }}
            />
          ) : (
            <form className="space-y-6" onSubmit={handleSubmit(onSubmit)}>
              <Controller
                name="email"
                control={control}
                render={({ field }) => (
                  <div>
                    <label
                      htmlFor="email"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Email address
                    </label>
                    <div className="mt-1">
                      <input
                        {...field}
                        data-test="login-email"
                        id="email"
                        name="email"
                        type="email"
                        autoComplete="email"
                        required
                        className="login-email appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm"
                      />
                    </div>
                  </div>
                )}
              />

              {emailError !== null ? (
                <div className="rounded-md bg-red-50 p-4 mt-3 mb-3">
                  <div className="flex">
                    <div className="flex-shrink-0">
                      <svg
                        className="h-5 w-5 text-red-400"
                        fill="currentColor"
                        viewBox="0 0 20 20"
                      >
                        <path
                          fillRule="evenodd"
                          d="M10 18a8 8 0 100-16 8 8 0 000 16zM8.707 7.293a1 1 0 00-1.414 1.414L8.586 10l-1.293 1.293a1 1 0 101.414 1.414L10 11.414l1.293 1.293a1 1 0 001.414-1.414L11.414 10l1.293-1.293a1 1 0 00-1.414-1.414L10 8.586 8.707 7.293z"
                          clipRule="evenodd"
                        />
                      </svg>
                    </div>
                    <div className="ml-3">
                      <h3 className="text-sm leading-5 font-medium text-red-800">
                        There was an error with your submission
                      </h3>
                      <div className="mt-2 text-sm leading-5 text-red-700">
                        <ul className="list-disc pl-5">
                          <li key={Math.random()}>{emailError}</li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              ) : null}

              <div>
                <button
                  id="login-submit"
                  type="submit"
                  data-test="login-submit"
                  className={classNames(
                    "w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sprxBlue hover:bg-blue-500 focus:outline-none",
                    _.isEmpty(dirtyFields) &&
                      "bg-sprxGrey opacity-50 cursor-not-allowed",
                    !isValid && "bg-sprxGrey opacity-50 cursor-not-allowed"
                  )}
                  disabled={_.isEmpty(dirtyFields) || !isValid}
                >
                  Sign in
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  );
}

export default Login;
