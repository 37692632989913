import React from "react";
export const StateForms = {
    "Alaska": React.lazy(() => import('./Alaska')),
    "Arizona": React.lazy(() => import('./Arizona')),
    "California": React.lazy(() => import('./California')),
    "Colorado": React.lazy(() => import('./Colorado')),
    "Connecticut": React.lazy(() => import('./Connecticut')),
    "Delaware": React.lazy(() => import('./Delaware')),
    "Georgia": React.lazy(() => import('./Georgia')),
    "Idaho": React.lazy(() => import('./Idaho')),
    "Illinois": React.lazy(() => import('./Illinois')),
    "Indiana": React.lazy(() => import('./Indiana')),
    // "Iowa": React.lazy(() => import('./Iowa')),
    "Kansas": React.lazy(() => import('./Kansas')),
    "Maine": React.lazy(() => import('./Maine')),
    "Massachusetts": React.lazy(() => import('./Massachusetts')),
    "Nebraska": React.lazy(() => import('./Nebraska')),
    "New Jersey": React.lazy(() => import('./NewJersey')),
    "North Dakota": React.lazy(() => import('./NorthDakota')),
    "Ohio": React.lazy(() => import('./Ohio')),
    "Pennsylvania": React.lazy(() => import('./Pennsylvania')),
    "Rhode Island": React.lazy(() => import('./RhodeIsland')),
    "South Carolina": React.lazy(() => import('./SouthCarolina')),
    "Texas": React.lazy(() => import('./Texas')),
    "Utah": React.lazy(() => import('./Utah')),
    "Vermont": React.lazy(() => import('./Vermont')),
    "Wisconsin": React.lazy(() => import('./Wisconsin')),
};
