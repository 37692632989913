import { baseAPI } from "./baseAPI";

const userAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    getTaxUser: builder.mutation({
      query: (userID) => {
        return {
          url: `/users/${userID}`,
          method: "GET",
        };
      },
      providesTags: (result, error, id) => [{ type: "User", id }],
    }),
    createUser: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/users`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error) => [{ type: "UserList" }],
    }),
    createDeliverablesUser: builder.mutation({
      query: ({ body }) => {
        return {
          url: `/deliverables-users`,
          method: "POST",
          body: body,
        };
      },
      invalidatesTags: (result, error) => [{ type: "UserList" }],
    }),
    // "PUT /run-migrations"
    migrateUsers: builder.mutation({
      query: () => {
        return {
          url: `/run-migrations`,
          method: "PUT",
          body: {},
        };
      },
    }),
    updateTaxUser: builder.mutation({
      query: ({ userID, body }) => {
        return {
          url: `/users/${userID}`,
          method: "PUT",
          body: body,
        };
      },
      invalidatesTags: (result, error, { id }) => [
        { type: "User", id },
        { type: "UserList", id },
      ],
    }),
    deleteUser: builder.mutation({
      query: ({ userID }) => {
        return {
          url: `/users/${userID}`,
          method: "DELETE",
        };
      },
      invalidatesTags: (result, error, { id }) => [{ type: "UserList", id }],
    }),
    listUsers: builder.query({
      query: () => ({
        url: `/list-users`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "UserList" }],
    }),
    listAssignedUsers: builder.query({
      query: ({ companyID }) => {
        const params = new URLSearchParams();
        params.append("companyID", companyID);
        return {
          url: `/list-all-assignedUsers?${params.toString()}`,
          method: "GET",
        };
      },
      // invalidatesTags: (result, error, { companyID }) => [
      //   { type: "EngagementList", companyID },
      // ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetTaxUserMutation,
  useUpdateTaxUserMutation,
  useCreateUserMutation,
  useCreateDeliverablesUserMutation,
  useDeleteUserMutation,
  useListUsersQuery,
  useMigrateUsersMutation,
  useListAssignedUsersQuery,
} = userAPI;
