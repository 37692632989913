import React from "react";
import { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from ".";

const schema = yup.object().shape({
  number: yup.string().required("Please enter your loan number."),
});

const defaultValues = {
  name: "123456789",
};

const LoanLookupButton = (props) => {
  const [open, setOpen] = useState(false);

  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  const onClickSend = async (model) => {
    props.onSuccess(model);
  };

  return (
    <div onClick={() => setOpen(!open)}>
      {props.children}
      <Transition.Root show={open} as={Fragment}>
        <Dialog
          as="div"
          className="fixed z-50 inset-0 overflow-y-auto"
          onClose={setOpen}
        >
          <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            </Transition.Child>

            <span
              className="hidden sm:inline-block sm:align-middle sm:h-screen"
              aria-hidden="true"
            >
              &#8203;
            </span>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-sm sm:w-full sm:p-6">
                <div>
                  <div className="text-center">
                    <Dialog.Title
                      as="h3"
                      className="text-lg leading-6 font-medium text-gray-900"
                    >
                      PPP Loan Lookup
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-sm text-gray-500">
                        Enter the loan number provided on your forgiveness
                        paperwork.
                      </p>
                      <form
                        className="mt-5 sm:flex sm:items-center sm:flex-col"
                        onSubmit={handleSubmit((model) => onClickSend(model))}
                      >
                        <Controller
                          name="number"
                          control={control}
                          render={({ field }) => (
                            <div className="w-full sm:max-w-xs px-4">
                              <label htmlFor="name" className="sr-only">
                                Loan Number
                              </label>
                              <input
                                {...field}
                                type="string"
                                name="number"
                                id="Loan Number"
                                required
                                autoComplete="Loan Number"
                                className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md  px-2"
                                placeholder="123456789"
                              />
                            </div>
                          )}
                        />
                        <Button
                          secondary={true}
                          buttonClassName={classNames(
                            "mt-5 sm:mt-6 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-sprxTaxBlue text-base font-medium text-white hover:bg-sprxTaxDarkBlue focus:outline-none sm:text-sm",
                            _.isEmpty(dirtyFields) &&
                              "bg-sprxTaxBlue opacity-50 cursor-not-allowed",
                            !isValid &&
                              "bg-sprxTaxBlue opacity-50 cursor-not-allowed"
                          )}
                          disabled={_.isEmpty(dirtyFields) || !isValid}
                        >
                          Search
                        </Button>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </Transition.Child>
          </div>
        </Dialog>
      </Transition.Root>
    </div>
  );
};

export default LoanLookupButton;
