export const getVapiDefaultFields = ({
  email,
  clientEmail,
  phoneNumber,
  transcript,
  buildingCost,
  ownershipPercentage,
  submittedDate,
  sentDate,
  buildingName,
  clientName,
  ownerName,
}): {
  email?: string;
  clientEmail?: string;
  phoneNumber?: string;
  transcript?: string;
  buildingCost?: number;
  ownershipPercentage?: number;
  submittedDate?: string;
  sentDate?: string;
  buildingName?: string;
  clientName?: string;
  ownerName?: string;
} => {
  return {
    email: email ?? "",
    clientEmail: clientEmail ?? "",
    phoneNumber: phoneNumber ?? "",
    transcript: transcript ?? "",
    buildingCost: buildingCost ?? 0,
    ownershipPercentage: ownershipPercentage ?? 0,
    submittedDate: submittedDate ?? "",
    sentDate: sentDate ?? "",
    buildingName: buildingName ?? "",
    clientName: clientName ?? "",
    ownerName: ownerName ?? "",
  };
};
