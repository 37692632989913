import { motion } from "framer-motion";
import { useState } from "react";

export const CompaniesList = ({
  companies,
  companyNameFilter,
  setCompanyNameFilter,
  ListContainer,
  type,
}: {
  companies: any[];
  companyNameFilter: string;
  setCompanyNameFilter: (value: string) => void;
  ListContainer: any;
  type: string;
}) => {
  const [open, setOpen] = useState<boolean>(false);
  const [isSettingActive, setIsSettingActive] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number>(-1);
  const [selectedCompany, setSelectedCompany] = useState<any>({});

  const handleSetActiveClick = async (index: number, company: any) => {
    setIsSettingActive(true);
    setSelectedCompany(company);
    setEditIndex(index);
    setOpen(true);
    // await setActiveEngagement(company);
    setIsSettingActive(false);
  };

  return (
    <div className="flex-1">
      <div className="pt-6">{/*Top bar and logo */}</div>
      {open ? (
        <ListContainer
          providedCompanyID={selectedCompany.companyID}
          providedCompanyName={selectedCompany.companyName}
          closeEngagement={() => setOpen(false)}
        />
      ) : (
        <div className="">
          <div className="flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
            <h1 className="text-2xl font-semibold text-gray-900">
              {type} Companies
            </h1>
            <img
              style={{ width: 85, height: 36 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
          </div>
          {/* Admin engagement table */}
          <div className="mt-2 px-4 sm:px-6 lg:px-8">
            <div className="items-between flex flex-row items-end justify-between">
              <div className="mt-4 flex flex-col sm:mt-0">
                <p className={"mb-2 text-sm font-bold"}>Company Name</p>
                <motion.input
                  type="text"
                  className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                  onChange={(e) => {
                    setCompanyNameFilter(e.target.value);
                  }}
                  value={companyNameFilter}
                />
              </div>
            </div>
            <div className="mt-8 flow-root">
              <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                  <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                    <table className="min-w-full divide-y divide-gray-300">
                      <thead className="sticky top-0 bg-gray-50">
                        <tr>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Company Name
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Company Domain
                          </th>
                          <th
                            scope="col"
                            className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                          >
                            Created At
                          </th>

                          <th
                            scope="col"
                            className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                          >
                            <span className="sr-only">Set Active</span>
                          </th>
                        </tr>
                      </thead>
                      <tbody className="h-16 divide-y divide-gray-200 bg-white">
                        {companies
                          ?.map((a) => ({ ...a }))
                          ?.sort((a, b) => b?.createdAt - a?.createdAt)
                          ?.filter((a) => {
                            const k = companyNameFilter.toLowerCase();
                            return (
                              a?.companyName?.toLowerCase()?.includes(k) ||
                              false
                            );
                          })
                          ?.map((co, index) => (
                            <tr key={co.companyID}>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {co.companyName}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {co.emailDomain.includes("@")
                                  ? co.emailDomain
                                  : "@" + co.emailDomain}
                              </td>
                              <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                                {new Date(co.createdAt).toLocaleDateString()}
                              </td>
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="px-3 py-4 text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                onClick={async () => {
                                  await handleSetActiveClick(index, co);
                                }}
                              >
                                {isSettingActive && index === editIndex ? (
                                  <div className="w-full">
                                    <p className="h-2 w-16 animate-pulse rounded bg-sprxGrey"></p>
                                  </div>
                                ) : (
                                  "View"
                                )}
                              </motion.button>
                            </tr>
                          ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
