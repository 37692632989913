const ConnectInactive = () => {
  return (
    <div className={"flex flex-col items-center"}>
      <div className="w-full">
        <div className="mb-4">
          <div className="flex flex-row items-center justify-center">
            <h3 className="mr-2 text-gray-900 text-4xl font-medium truncate text-center">
              {"This link is no longer active."}
            </h3>
          </div>
          <p className="mt-2 mb-2 text-md text-center text-gray-500">
            {
              "Please contact your team if this Data Connect Workflow needs to be resubmitted."
            }
          </p>
        </div>
      </div>
    </div>
  );
};

export default ConnectInactive;
