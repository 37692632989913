import { Popover } from "@headlessui/react";
import { motion } from "framer-motion";

export const FilterContainer = ({
  filter,
  content,
  activeIcon,
  inactiveIcon,
  shift,
  isLast,
}) => {
  const isActive = filter.length > 0;
  // const isActive = Object.keys(filter)
  //   .map((key) => {
  //     if (Array.isArray(filter[key])) {
  //       return filter[key].length > 0;
  //     } else {
  //       return Object.keys(filter[key])
  //         .map((subKey) => {
  //           return filter[key][subKey] !== "";
  //         })
  //         .every((subKey) => subKey);
  //     }
  //   })
  //   .every((key) => key);
  return (
    <Popover className="relative z-20">
      {({ open }) => (
        <>
          <Popover.Button
            className={`
                ${open ? "" : "text-opacity-90"}
                text-sm font-medium text-gray-900 mt-2
                focus:outline-none
                ${open ? "text-opacity-100" : ""}
            `}
          >
            {isActive ? inactiveIcon : activeIcon}
          </Popover.Button>
          <Popover.Panel
            className={`absolute z-30 w-80 mt-3 transform -translate-x-${
              shift ?? "1/3"
            } ${isLast ? "right-2/3" : "left-1/2"} sm:px-0`}
            style={{ display: open ? "block" : "none" }}
          >
            <div className="overflow-hidden rounded-lg shadow-lg ring-1 ring-black ring-opacity-5">
              <motion.div className="relative grid gap-8 bg-white p-4">
                {content}
              </motion.div>
            </div>
          </Popover.Panel>
        </>
      )}
    </Popover>
  );
};
