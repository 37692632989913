import { useEngagementData } from "../EngagementData/useEngagementData";
import ComputeContext from "./ComputeContext";
import {
  useRunTaxMLModelMutation,
  useComputeTaxStateCreditsMutation,
  useExtractTaxSupportingDocumentsMutation,
  useComputeTaxRDCreditMutation,
  useCreateProjectCompletionsMutation,
} from "../../../api/computeCreditAPI";

import { useGenerateAllocationsMutation } from "../../../api/engagmentAPI";
import { isDev } from "../../../main/R&D/utils";
import { useEffect, useState } from "react";
import { useUsers } from "../../Users/useUsers";

const ComputeProvider = ({ children }) => {
  const [engagementID, setEngagementID] = useState(undefined);
  const { user } = useUsers();

  const { engagementData } = useEngagementData();

  const mlPayload = {
    toEmail: user?.data?.email,
    userName: user?.data?.displayName,
    engagementID,
  };

  useEffect(() => {
    setEngagementID(engagementData?.engagementID);
  }, [engagementData?.engagementID]);

  const [generateEmployeeAllocations, { isLoading: isGeneratingAllocations }] =
    useGenerateAllocationsMutation();

  const [runTaxMLModel, { isLoading: isRunningML }] =
    useRunTaxMLModelMutation();

  const [computeTaxRDCredit, { isLoading: isComputingFederalCredit }] =
    useComputeTaxRDCreditMutation();

  const [computeTaxStateCredits, { isLoading: isComputingStateCredits }] =
    useComputeTaxStateCreditsMutation();

  const [extractTaxSupportingDocuments, { isLoading: isExtractingDocuments }] =
    useExtractTaxSupportingDocumentsMutation();

  const [
    createProjectCompletions,
    { isLoading: isCreatingProjectCompletions },
  ] = useCreateProjectCompletionsMutation();

  const onSubmitUpload = async () => {
    const mlResponse = await runTaxMLModel(mlPayload);
    if (isDev) console.log({ mlResponse });

    const fedComputeResponse = await computeTaxRDCredit({ engagementID });
    if (isDev) console.log({ fedComputeResponse });

    const stateComputeResponse = await computeTaxStateCredits({ engagementID });
    if (isDev) console.log({ stateComputeResponse });

    const documentExtractionResponse = await extractTaxSupportingDocuments({
      engagementID,
    });
    if (isDev) console.log({ documentExtractionResponse });

    const projectCompletionsResponse = await createProjectCompletions({
      engagementID,
    });
    if (isDev) console.log({ projectCompletionsResponse });
  };

  const value = {
    computeTaxRDCredit: async () => await computeTaxRDCredit({ engagementID }),
    computeTaxStateCredits: async () =>
      await computeTaxStateCredits({ engagementID }),
    extractTaxSupportingDocuments: async () =>
      await extractTaxSupportingDocuments({ engagementID }),
    runTaxMLModel: async () => await runTaxMLModel(mlPayload),
    onSubmitUpload: async () => await onSubmitUpload(),
    createProjectCompletions: async () =>
      await createProjectCompletions({ engagementID }),
    generateEmployeeAllocations: async () =>
      await generateEmployeeAllocations({ body: { engagementID } }),
    isComputingFederalCredit,
    isComputingStateCredits,
    isExtractingDocuments,
    isRunningML,
    isComputing:
      isComputingFederalCredit ||
      isComputingStateCredits ||
      isExtractingDocuments ||
      isRunningML ||
      isCreatingProjectCompletions ||
      isGeneratingAllocations,
  };

  return (
    <ComputeContext.Provider value={value}>{children}</ComputeContext.Provider>
  );
};
export default ComputeProvider;
