import { currencyFormatter } from "../../utils";

const loadingTiles = [
  { name: "Wage QRE" },
  { name: "Supply QRE" },
  { name: "Contract QRE" },
  { name: "Computer Lease QRE" },
  { name: "Total QRE" },
  { name: "State Credit" },
];

const LoadingQreBanner = () => {
  return (
    <dl className="mx-3 rounded-sm grid grid-cols-6 gap-px bg-gray-900/5 p-px">
      {loadingTiles.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-col items-baseline justify-between gap-x-4 bg-white pt-2 px-4"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">
            {stat.name}
          </dt>
          <div className="w-2/3 w-full flex-none animate-pulse justify-center">
            <div className="my-4 h-2 bg-sprxGrey rounded col-span-3"></div>
          </div>
        </div>
      ))}
    </dl>
  );
};

export const QreBanner = (props) => {
  const { isLoading, total, wage, supply, contract, computer, credit } = props;

  if (isLoading) return <LoadingQreBanner />;

  const qreStats = [
    { name: "Wage QRE", value: wage },
    { name: "Supply QRE", value: supply },
    { name: "Contract QRE", value: contract },
    { name: "Computer Lease QRE", value: computer },
    { name: "Total QRE", value: total },
    { name: "State Credit", value: credit },
  ];
  /*credit*/
  return (
    <dl className="mx-3 rounded-sm grid grid-cols-6 gap-px bg-gray-900/5 p-px">
      {qreStats.map((stat) => (
        <div
          key={stat.name}
          className="flex flex-col items-baseline justify-between gap-x-4 bg-white pt-2 px-4"
        >
          <dt className="text-sm font-medium leading-6 text-gray-500">
            {stat.name}
          </dt>
          <dd className="w-full flex-none text-2xl font-medium leading-10 tracking-tight text-gray-900">
            {currencyFormatter.format(stat.value)}
          </dd>
        </div>
      ))}
    </dl>
  );
};
