import * as React from "react";

interface FinalizeDeliverablesrProps {
  onClickFinalize: () => void;
  isFinalized: boolean;
}

export const FinalizeDeliverables = ({
  onClickFinalize,
  isFinalized,
}: FinalizeDeliverablesrProps) => {
  return (
    <div className="flex w-1/3 flex-grow items-center justify-end lg:pl-6">
      <div className="h-12vh flex w-full flex-col items-center justify-center rounded-2xl border py-4 shadow-xl">
        <h1 className="overflow-hidden text-center text-xl font-semibold text-gray-900">
          {isFinalized ? "Revert Deliverables" : "Finalize Deliverables"}
        </h1>
        <button
          type="button"
          className="focus:ring-none mt-2 inline-flex w-1/2 items-center justify-center rounded-xl border border-transparent bg-sprxClientPortalLightBlue px-3.5 py-2 text-sm font-medium leading-4 text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none"
          onClick={async () => onClickFinalize()}
        >
          {isFinalized ? "Revert" : "Finalize"}
        </button>
      </div>
    </div>
  );
};
