import {
  useForm,
  useFieldArray,
  Controller,
  SubmitHandler,
} from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Button } from "../../../../../ui-components";
import * as yup from "yup";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { Engagement } from "../../../types";
import { motion } from "framer-motion";
import { performClick } from "../../../../R&D/utils";
import { TrashIcon } from "@heroicons/react/outline";
import { useMemo } from "react";

// Define the form data structure
interface FormData {
  levels: {
    rootKey: string;
    paths: { url: string }[];
    notes: { note: string }[];
  }[];
}

// Define validation schema
const schema = yup.object().shape({
  levels: yup.array().of(
    yup.object().shape({
      rootKey: yup.string().required("Level name is required"),
      paths: yup.array().of(
        yup.object().shape({
          url: yup
            .string()
            .url("Must be a valid URL")
            .required("URL is required"),
        })
      ),
      notes: yup.array().of(
        yup.object().shape({
          note: yup.string(),
        })
      ),
    })
  ),
});

export const SiteVisitLinks = ({ engagement }: { engagement: Engagement }) => {
  const energyEngagementAPI = useEnergyEngagement();

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm<FormData>({
    mode: "onSubmit",
    defaultValues: useMemo(
      () => ({
        levels: engagement?.certification?.levels ?? [
          { rootKey: "", paths: [{ url: "" }], notes: [{ note: "" }] },
        ],
      }),
      [engagement?.certification?.levels]
    ),
    resolver: yupResolver(schema),
  });

  const {
    fields: rootFields,
    append: appendRoot,
    remove: removeRoot,
  } = useFieldArray({
    control,
    name: "levels",
  });

  const handleAddRoot = () => {
    appendRoot({ rootKey: "", paths: [{ url: "" }], notes: [{ note: "" }] });
  };

  const onSubmit: SubmitHandler<FormData> = async (data) => {
    const body = {
      certification: {
        ...engagement.certification,
        levels: data.levels,
      },
    };
    await energyEngagementAPI.updateEngagement({
      engagementID: engagement.engagementID,
      body,
    });
  };

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      {rootFields.map((rootField, rootIndex) => {
        return (
          <div key={rootField.id} className="w-full my-2">
            <Controller
              name={`levels.${rootIndex}.rootKey`}
              control={control}
              render={({ field }) => (
                <div className="flex flex-row items-center my-2">
                  <label className="text-md font-medium text-gray-700 w-32">
                    Level {rootIndex + 1} Name
                  </label>
                  <motion.input
                    {...field}
                    className="shadow-sm block w-80 sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                    id={`levels.${rootIndex}.rootKey`}
                    type="text"
                    whileHover={{ scale: 1.01 }}
                  />
                  {rootFields.length > 1 ? (
                    <TrashIcon
                      className="h-6 w-6 ml-4 text-red-500 cursor-pointer hover:text-red-700"
                      onClick={() => removeRoot(rootIndex)}
                    />
                  ) : null}
                </div>
              )}
            />
            {errors.levels?.[rootIndex]?.rootKey && (
              <p className="pl-2 text-sprxOrange">
                {errors.levels[rootIndex].rootKey.message}
              </p>
            )}
            <NestedPathsAndNotes
              control={control}
              rootIndex={rootIndex}
              errors={errors}
            />
            {/* Add Margin Below for Items with Another Level Below it */}
            {rootIndex !== rootFields.length - 1 && (
              <div className="border-b-2 border-gray-300 my-4"></div>
            )}
          </div>
        );
      })}
      <div className="border-b-2 border-gray-300 my-4"></div>
      <div className="flex flex-row w-full justify-between">
        <Button
          secondary={true}
          onClick={handleAddRoot}
          buttonClassName="my-2 w-40"
        >
          + Level
        </Button>
        <Button type="submit" secondary="true" buttonClassName="w-40">
          {energyEngagementAPI.updateEngagementData.isLoading ? (
            <div className="w-full flex flex-row justify-center">
              <p className="animate-pulse bg-white rounded h-2 w-16 p-1"></p>
            </div>
          ) : (
            <div className="w-full">Submit</div>
          )}
        </Button>
      </div>
    </form>
  );
};

interface NestedPathsProps {
  control: any;
  rootIndex: number;
  errors: any;
}

const NestedPathsAndNotes = ({
  control,
  rootIndex,
  errors,
}: NestedPathsProps) => {
  const {
    fields: paths,
    append: appendPath,
    remove: removePath,
  } = useFieldArray({
    control,
    name: `levels.${rootIndex}.paths`,
  });

  const {
    fields: notes,
    append: appendNote,
    remove: removeNote,
  } = useFieldArray({
    control,
    name: `levels.${rootIndex}.notes`,
  });

  return (
    <div className="grid grid-cols-4 gap-y-2 gap-x-8">
      <div className="col-span-2">
        {paths.map((field: any, index) => (
          <div key={field.id}>
            <Controller
              name={`levels.${rootIndex}.paths.${index}.url`}
              control={control}
              render={({ field }: { field: any }) => (
                <div className="flex flex-row items-center my-2">
                  <label className="text-md font-medium text-gray-700 w-32">
                    Path {index + 1}
                  </label>
                  <motion.input
                    {...field}
                    className="shadow-sm w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0"
                    type="text"
                    id={`levels.${rootIndex}.paths.${index}.url`}
                    whileHover={{ scale: 1.01 }}
                  />
                  {paths.length > 1 ? (
                    <TrashIcon
                      className="h-6 w-6 ml-4 text-red-500 cursor-pointer hover:text-red-700"
                      onClick={() => removePath(index)}
                    />
                  ) : null}
                </div>
              )}
            />
            {errors.levels?.[rootIndex]?.paths?.[index]?.url && (
              <p className=" text-sprxOrange">
                {errors.levels[rootIndex].paths[index].url.message +
                  " for path " +
                  `${index + 1}`}
              </p>
            )}
          </div>
        ))}
        <div className="w-full flex flex-row justify-end">
          <Button
            type="addPath"
            secondary={true}
            onClick={() => appendPath({ url: "" })}
            buttonClassName="ml-4 w-40"
          >
            + Path
          </Button>
        </div>
      </div>
      {/* Add Vertical Divider */}
      <div className="col-span-2">
        {notes.map((field: any, index) => (
          <div key={field.id} className="col-span-2">
            <Controller
              name={`levels.${rootIndex}.notes.${index}.note`}
              control={control}
              render={({ field }: { field: any }) => (
                <div className="flex flex-row items-center my-2">
                  <label className="text-md font-medium text-gray-700 w-32">
                    Note {index + 1}
                  </label>
                  <motion.input
                    {...field}
                    className="shadow-sm w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0"
                    type="text"
                    id={`levels.${rootIndex}.notes.${index}.note`}
                    whileHover={{ scale: 1.01 }}
                  />
                  {notes.length > 1 ? (
                    <TrashIcon
                      className="h-6 w-6 ml-4 text-red-500 cursor-pointer hover:text-red-700"
                      onClick={() => removeNote(index)}
                    />
                  ) : null}
                </div>
              )}
            />
            {errors.levels?.[rootIndex]?.notes?.[index]?.note && (
              <p className=" text-sprxOrange">
                {errors.levels[rootIndex].notes[index].note.message}
              </p>
            )}
          </div>
        ))}
        <div className="w-full flex flex-row justify-end">
          <Button
            type="addPath"
            secondary={true}
            onClick={() => appendNote({ url: "" })}
            buttonClassName="ml-4 w-40"
          >
            + Note
          </Button>
        </div>
      </div>
    </div>
  );
};
