import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useEffect, useState } from "react";
import { EditEmployeeProjectsForm } from "./EditEmployeeProjectsForm";
import { FlatlistSelectionPane } from "../components/FlatlistSelectionPane";

export const EditEmployeeProjects = (props) => {
  const { id } = props;

  const [employee, setEmployee] = useState({});
  const [isSaving, setIsSaving] = useState(false);
  const [availableProjects, setAvailableProjects] = useState([]);
  const [selectedProject, setSelectedProject] = useState(null);

  const { wageData, projectData, updateLineItem } = useDataArray();

  useEffect(() => {
    // Find Selected Employee
    const index = wageData.findIndex((e) => e.ID === id);
    const emp = wageData[index];
    setEmployee(emp);

    // List Allocated Projects & Add Project Name For Listing
    const mappedProjects = Object.keys(emp?.completions ?? {})
      .map((id) => {
        const p_index = projectData.findIndex((project) => project?.ID === id);
        if (p_index === -1) return undefined;
        return {
          id,
          name: projectData[p_index]["Project Name"],
        };
      })
      .filter((a) => a !== undefined);
    setAvailableProjects(mappedProjects);
    if (selectedProject === null && mappedProjects.length > 0) {
      setSelectedProject(mappedProjects[0].id);
    }
  }, [id, projectData, selectedProject, wageData]);

  const regenerateCompletions = async () => {
    const itemToRegenerate = {
      ...wageData.find((e) => e.ID === id),
      completions: {
        [`${selectedProject}`]: {
          "activity-1": "",
          "activity-2": "",
          "activity-3": "",
          discovery: "",
          isLoading: true,
        },
      },
    };
    await updateLineItem(itemToRegenerate, "WAGES_ALLOCATIONS");
  };

  const updateSelectedItem = async (item) => {
    setIsSaving(true);
    const index = wageData.findIndex((e) => e.ID === id);
    const updatedItem = {
      ...wageData[index],
      completions: {
        [`${selectedProject}`]: {
          ...wageData[index].completions[selectedProject],
          ...item,
        },
      },
    };
    await updateLineItem(updatedItem, "Wages");
    setIsSaving(false);
  };

  if (
    availableProjects.length === 0 ||
    !employee.hasOwnProperty("completions") ||
    !employee.completions.hasOwnProperty(selectedProject)
  ) {
    return (
      <div className="flex items-center justify-center h-70vh w-full">
        Please allocate projects to{" "}
        {employee.hasOwnProperty("Employee Name")
          ? employee["Employee Name"]
          : "this employee"}{" "}
        before proceeding.
      </div>
    );
  }

  return (
    <div className="px-8 pb-8 z-40">
      <div className="flex flex-row w-full items-start justify-between h-70vh">
        <FlatlistSelectionPane
          searchTitle={"Project Name"}
          items={availableProjects}
          setSelectedItem={setSelectedProject}
          selectedItem={selectedProject}
          nameKey={"name"}
          idKey={"id"}
        />
        <div className="flex w-3/4 items-center justify-center h-full">
          <EditEmployeeProjectsForm
            employee={employee}
            isSaving={isSaving}
            id={selectedProject}
            updateSelectedItem={updateSelectedItem}
            regenerateCompletions={regenerateCompletions}
          />
        </div>
      </div>
    </div>
  );
};
