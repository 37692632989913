import { createSlice } from "@reduxjs/toolkit";
// import history from "../../history";

export const setUserData = (user) => async (dispatch, getState) => {
  /* You can redirect the logged-in user to a specific route depending on his role */

  // let updatedUser;

  // if (user?.data?.activeEngagementID === "N/A" && user?.role === "firm") {
  //   updatedUser = {
  //     ...user,
  //     redirectUrl: "/client-list",
  //   };
  // } else if (user?.role === "firm") {
  //   updatedUser = {
  //     ...user,
  //     redirectUrl: "/firm-home",
  //   };
  // } else if (user?.data?.activeEngagementID === "N/A") {
  //   updatedUser = {
  //     ...user,
  //     redirectUrl: "/create-engagement",
  //   };
  // } else if (user?.data?.activeEngagementID !== "N/A") {
  //   updatedUser = {
  //     ...user,
  //     redirectUrl: "/",
  //   };
  // } else {
  //   // Dispatch some error here
  // }

  // if (updatedUser?.redirectUrl) {
  //   history.location.state = {
  //     redirectUrl: updatedUser?.redirectUrl,
  //   };
  // }

  // console.log("🚀 ~ setUserData ~ updatedUser", updatedUser);

  dispatch(setUser(user));
};

const initialState = {
  role: ["guest"], // guest
  data: {
    displayName: "John Doe",
    photoURL: "assets/images/avatars/Velazquez.jpg",
    email: "johndoe@test.com",
    shortcuts: ["calendar", "mail", "contacts", "todo"],
  },
};

const userSlice = createSlice({
  name: "auth/user",
  initialState,
  reducers: {
    setUser: (state, action) => action.payload,
    userLoggedOut: (state, action) => initialState,
  },
});

export const { setUser, userLoggedOut } = userSlice.actions;

export default userSlice.reducer;
