import CompanyDataContext from "./CompanyDataContext";
import {
  useGetCompanyQuery,
  useGetUpdatedCompanyMutation,
  useUpdateCompanyMutation,
  useListCompaniesQuery,
  useCreateCompanyMutation,
} from "../../api/companyAPI";
import { useMemo } from "react";
import { useUsers } from "../Users/useUsers";

const CompanyDataProvider = ({ children }) => {
  const { user } = useUsers();
  const isLoadingUser = user === undefined || user?.companyID === undefined;

  const {
    data: companyData,
    isLoading: isLoadingCompanyData,
    error: getCompanyError,
  } = useGetCompanyQuery(user.companyID);

  const [
    createCompany,
    { isLoading: isCreatingCompany, error: createCompanyError },
  ] = useCreateCompanyMutation();

  const {
    data: companies,
    isLoading: isListingCompanies,
    error: listCompaniesError,
  } = useListCompaniesQuery();

  const [
    getUpdatedCompanyData,
    { isLoading: isGettingUpdatedCompany, error: getUpdatedCompanyError },
  ] = useGetUpdatedCompanyMutation();

  const [
    updateCompany,
    { isLoading: isUpdatingCompany, error: updateCompanyError },
  ] = useUpdateCompanyMutation();

  let errors = useMemo(() => {
    let err = {};
    const hasError = (i) => i !== undefined;
    if (hasError(getCompanyError) && !isLoadingUser)
      err = { ...err, getCompanyError };
    if (hasError(createCompanyError)) err = { ...err, createCompanyError };
    if (hasError(listCompaniesError)) err = { ...err, listCompaniesError };
    if (hasError(getUpdatedCompanyError))
      err = { ...err, getUpdatedCompanyError };
    if (hasError(updateCompanyError)) err = { ...err, updateCompanyError };
    Object?.keys(err)?.forEach((i) => console.error(i, err[i]));
    return err;
  }, [
    getCompanyError,
    isLoadingUser,
    createCompanyError,
    listCompaniesError,
    getUpdatedCompanyError,
    updateCompanyError,
  ]);

  const value = useMemo(
    () => ({
      companyData,
      companies,
      createCompany,
      getUpdatedCompanyData,
      updateCompany,
      isLoadingCompanyData: isLoadingCompanyData || isLoadingUser,
      isCreatingCompany: isCreatingCompany || isLoadingUser,
      isListingCompanies: isListingCompanies || isLoadingUser,
      isGettingUpdatedCompany: isGettingUpdatedCompany || isLoadingUser,
      isUpdatingCompany: isUpdatingCompany || isLoadingUser,
      companyErrors: errors,
    }),
    [
      companyData,
      companies,
      createCompany,
      getUpdatedCompanyData,
      updateCompany,
      isLoadingCompanyData,
      isLoadingUser,
      isCreatingCompany,
      isListingCompanies,
      isGettingUpdatedCompany,
      isUpdatingCompany,
      errors,
    ]
  );

  return (
    <CompanyDataContext.Provider value={value}>
      {children}
    </CompanyDataContext.Provider>
  );
};
export default CompanyDataProvider;
