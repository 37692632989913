import * as React from "react";
import { TabNavigator } from "../../../../R&D/ReviewPortal/Allocations/components";
import { SiteVisitLinks } from "./SiteVisitLinks";
import { Engagement } from "../../../types";
import { CommentsContainer } from "../../../components/CommentsContainer";

export const Certification = ({
  engagement,
  setOpenCreateComment,
}: {
  engagement: Engagement;
  setOpenCreateComment: (value: boolean) => void;
}) => {
  const [currentTab, setCurrentTab] = React.useState({
    label: "Site Visit Links",
    value: "Site Visit Links",
  });

  const SelectedComponent = {
    "Site Visit Links": <SiteVisitLinks engagement={engagement} />,
    Comments: (
      <CommentsContainer
        portalType="CERTIFICATION"
        onClickAddCallback={() => setOpenCreateComment(true)}
      />
    ),
  }[currentTab.value];

  return (
    <div>
      <TabNavigator
        currentTab={currentTab}
        setCurrentTab={(tab) => {
          setCurrentTab(tab);
        }}
        tabs={[
          { label: "Site Visit Links", value: "Site Visit Links" },
          { label: "Comments", value: "Comments" },
        ]}
      />
      {SelectedComponent}
    </div>
  );
};
