import React, { useMemo } from "react";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";

import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { ReviewGaugeColumnLoading } from "./ReviewGaugeColumnLoading";

export const SmallDivider = () => (
  <div className="z-1 relative my-4 w-full">
    <div
      className="inset-0 flex items-center justify-center"
      aria-hidden="true"
    >
      <div className="w-1/2 border-t border-sprxClientPortalDarkBlue" />
    </div>
  </div>
);

export const ReviewGaugeColumn = (props) => {
  const height = props?.height || "88vh";

  const { engagementData, isLoadingEngagementData } = useEngagementData();

  const {
    wageData,
    supplyData,
    contractData,
    computerLeaseData,
    isDownloadingFile,
    isRefreshing,
    refreshData,
  } = useDataArray();

  const { isComputingFederalCredit, isComputingStateCredits } = useCompute();

  const calculateTotal = (data) =>
    data
      .map((a) => {
        return isNaN(Number(a?.qre)) ? 0 : Number(a?.qre);
      })
      .reduce((a, b) => a + b, 0);

  const wage = useMemo(() => calculateTotal(wageData), [wageData]);
  const supply = useMemo(() => calculateTotal(supplyData), [supplyData]);
  const contract = useMemo(() => calculateTotal(contractData), [contractData]);
  const computer = useMemo(
    () => calculateTotal(computerLeaseData),
    [computerLeaseData],
  );

  const total = useMemo(
    () => wage + supply + contract + computer,
    [wage, supply, contract, computer],
  );

  const relevantStates = useMemo(() => {
    if (!isLoadingEngagementData) {
      return engagementData.stateCredits.filter(
        (state) => state?.completed && state?.form?.credit > 0,
      );
    }
    return [];
  }, [engagementData, isLoadingEngagementData]);

  const totalCredits = useMemo(() => {
    let runningCredits = 0;

    if (!isLoadingEngagementData) {
      engagementData.stateCredits
        .filter((state) => state?.completed && state?.form?.credit > 0)
        .forEach((state) => {
          runningCredits += state?.form?.credit;
        });
    }

    return runningCredits + engagementData?.credit?.rdCredit;
  }, [engagementData, isLoadingEngagementData]);

  const loading =
    isDownloadingFile ||
    isLoadingEngagementData ||
    isComputingFederalCredit ||
    isComputingStateCredits;
  if (loading) return <ReviewGaugeColumnLoading height={height} />;

  const brPayments =
    engagementData?.creditEligibilityData?.completedAnalysis?.brPayment
      ?.brPMT ?? 0;
  const hasBrPayments = brPayments > 0;

  const ecPayments =
    engagementData?.creditEligibilityData?.completedAnalysis?.ecPayment
      ?.ecPMT ?? 0;
  const hasEcPayments = ecPayments > 0;

  return (
    <div
      className={`flex w-full flex-col overflow-y-scroll rounded-lg border shadow-lg min-h-${height} max-h-${height} mb-4`}
    >
      <div className="flex flex-1 flex-col justify-start bg-white p-6">
        <h1 className="text-center text-xl font-semibold text-gray-900">
          Credit Summary
        </h1>
        <div className="z-1 relative my-4">
          <div className="inset-0 flex items-center" aria-hidden="true">
            <div className="w-full border-t border-sprxClientPortalDarkBlue" />
          </div>
        </div>
        {hasEcPayments && (
          <div className="flex flex-row items-center justify-between">
            <div className="font-bold">Energy Consortia</div>
            <div>
              $
              {Math.round(
                engagementData.creditEligibilityData.completedAnalysis.ecPayment
                  .ecPMT,
              ).toLocaleString()}
            </div>
          </div>
        )}
        {hasBrPayments && (
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="font-bold">Research Payments</div>
            <div>
              $
              {Math.round(
                engagementData.creditEligibilityData.completedAnalysis.brPayment
                  .brPMT,
              ).toLocaleString()}
            </div>
          </div>
        )}
        {(hasBrPayments || hasEcPayments) && <SmallDivider />}
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold">Wage QRE</div>
          <div>${Math.round(wage).toLocaleString()}</div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between">
          <div className="font-bold">Supply QRE</div>
          <div>${Math.round(supply).toLocaleString()}</div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between">
          <div className="font-bold">Contract QRE</div>
          <div>${Math.round(contract).toLocaleString()}</div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between">
          <div className="font-bold">Computer QRE</div>
          <div>${Math.round(computer).toLocaleString()}</div>
        </div>
        <SmallDivider />
        <div className="flex flex-row items-center justify-between">
          <div className="font-bold">Total QRE</div>
          <div>${Math.round(total).toLocaleString()}</div>
        </div>
        <div className="mt-4 flex flex-row items-center justify-between">
          <div className="font-bold">Historical Base</div>
          <div>
            ${Math.round(engagementData.credit.creditBase).toLocaleString()}
          </div>
        </div>
        <SmallDivider />
        <Disclosure>
          {({ open }) => (
            <>
              <Disclosure.Button className="flex flex-row items-center justify-between rounded-md hover:bg-gray-200 focus:outline-none">
                <div className="flex w-full flex-row items-center">
                  <div className="flex w-full flex-row items-center justify-between">
                    <div className="font-bold">Total Credits</div>
                    <div className="mr-1">
                      ${Math.round(totalCredits).toLocaleString()}
                    </div>
                  </div>
                  <ChevronDownIcon
                    className={`${
                      open ? "rotate-180 transform" : ""
                    } h-5 w-5 text-black`}
                  />
                </div>
              </Disclosure.Button>
              <Disclosure.Panel className="px-2 text-sm text-gray-500">
                <div className="mt-4 flex flex-row items-center justify-between">
                  <div className="font-bold">Federal Credit</div>
                  <div>
                    $
                    {Math.round(
                      engagementData?.credit?.rdCredit,
                    ).toLocaleString()}
                  </div>
                </div>
                {relevantStates.map((state) => (
                  <div
                    key={state?.state}
                    className="mt-4 flex flex-row items-center justify-between"
                  >
                    <div className="font-bold">{state?.state} Credit</div>
                    <div>
                      ${Math.round(state?.form?.credit).toLocaleString()}
                    </div>
                  </div>
                ))}
              </Disclosure.Panel>
            </>
          )}
        </Disclosure>
      </div>
    </div>
  );
};
