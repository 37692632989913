import { classNames } from "../../../../R&D/utils";
import { motion } from "framer-motion";
import { ReviewGaugeColumnLoading } from "./ReviewGaugeColumnLoading";
export const ReportsLoading = () => {
  return (
    <main className="flex-1 overflow-hidden">
      <div className="py-6">
        <div className="animate-pulse ml-4 h-2 bg-sprxGrey rounded w-1/5"></div>

        <div className="flex px-4">
          <div className="w-full">
            <div className="w-full flex flex-row justify-evenly">
              <div className="flex flex-row w-1/2 py-4 justify-between h-16vh">
                <div className="flex flex-col w-full rounded-2xl p-2 bg-sprxClientPortalLightTeal mr-2">
                  <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
                  <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/2"></div>
                  <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/2"></div>
                </div>
              </div>
              <div className="w-1/2 flex flex-row justify-evenly mr-4">
                <div className="flex flex-grow items-center justify-end w-1/3 pl-8">
                  <div className="flex flex-col justify-center items-center w-full py-4 rounded-2xl border shadow-xl h-12vh">
                    <h1 className="text-xl text-center font-semibold text-gray-900  overflow-hidden">
                      <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
                    </h1>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-1/2 mt-2 px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-xl shadow-sm text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none"
                    >
                      <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
                    </button>
                  </div>
                </div>

                <div className="flex flex-grow items-center justify-end w-1/3 pl-8">
                  <div className="flex flex-col justify-center items-center w-full py-4 rounded-2xl border shadow-xl h-12vh">
                    <h1 className="text-xl text-center font-semibold text-gray-900  overflow-hidden">
                      <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
                    </h1>
                    <button
                      type="button"
                      className="inline-flex items-center justify-center w-1/2 mt-2 px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-xl shadow-sm text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none"
                    >
                      <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-12 gap-4 w-full pb-16">
              <div className="col-span-3">
                <ReviewGaugeColumnLoading />
              </div>
              <div className="col-span-9 flex w-full">
                <div className={"grid gap-4 w-full grid-cols-2"}>
                  {Array.from(Array(4)).map((item, index) => (
                    <motion.div
                      key={`upload-loading-item-${index}`}
                      className={
                        "flex flex-col w-full overflow-hidden rounded-lg shadow-xl border 2xl:min-w-270"
                      }
                    >
                      <motion.div
                        className={classNames(
                          "flex flex-1 flex-col justify-center items-center bg-white px-4 xl:py-4"
                        )}
                      >
                        <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
                        <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-2/3"></div>
                        <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-2/3"></div>
                        <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
                        <motion.button
                          className={classNames(
                            "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                            "inline-flex items-center justify-center xl:w-3/4 w-full mt-4 px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white focus:outline-none focus:ring-none"
                          )}
                        ></motion.button>
                      </motion.div>
                    </motion.div>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
};
