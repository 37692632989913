import * as React from "react";

interface IGetSignedURL {
  bucket: string;
  key: string;
  action: string;
}

interface DeleteFileParams {
  body: {
    key: string;
    bucket: string;
    action: string;
  };
}

interface FileProviderType {
  // Data
  documentList: Array<any>; // Replace 'any' with the actual type of a document if known

  // Functions
  uploadFile: (file: any) => Promise<void>;
  listDocumentsLibrary: () => Promise<void>;
  onDownloadDocument: (documentId: string) => Promise<void>;
  getSignedURL: (params: IGetSignedURL) => Promise<any>;
  uploadDirectoryFile: (file: File, directory: string) => Promise<void>;
  downloadDocument: (params: IGetSignedURL) => Promise<any>;
  deleteFile: ({
    body: { key, bucket, action },
  }: DeleteFileParams) => Promise<void>;
  generatePartialReport: (params: any) => Promise<any>;
  generatePartialEnergyReport: (params: any) => Promise<any>;
  getBulkEditFile: (fileId: string) => Promise<void>;
  generateDeliverables: (params: any) => Promise<any>;

  // Loading States
  isListingDocuments: boolean;
  isUploadingFile: boolean;
  isGettingBulkEditFile: boolean;
  isDownloadingDocument: boolean;
  isUploadingDirectoryFile: boolean;
  isGettingSignedURL: boolean;
  isDeletingFile: boolean;
  isGeneratingPartialReport: boolean;
  isGeneratingPartialEnergyReport: boolean;
  isGeneratingDeliverables: boolean;

  errors: any;

  loading: boolean;
}

const FileContext = React.createContext<FileProviderType | null>(null);

export default FileContext;
