import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { useState, useEffect, useMemo } from "react";
import { XIcon, CheckCircleIcon } from "@heroicons/react/outline";
import { IntelligentImportContainer } from "../IntelligentImport";
import { W2UploadPage } from "./W2UploadPage";
import { useLazyListDocumentsLibraryQuery } from "../../../../api/fileAPI";
import { useCheckEmployeeLocationMutation } from "../../../../api/engagmentAPI";
import {
  useEncryptConnectCredentialsMutation,
  useGeneratePayrollConnectLinkMutation,
} from "../../../../api/sendConnectAPI";
import { useGetCompanyQuery } from "../../../../api/companyAPI";
import { SprxLoading, SendConnectButton } from "../../../../ui-components";
import { useFinchConnect } from "react-finch-connect";
import { performClick } from "../TaxpayerProfile/utils";
import { useConnectAdditionalPayrollMutation } from "../../../../api/payrollConnectAPI";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const WageUpload = (props) => {
  const { engagementData, user } = props;
  const {
    companyID,
    data: { activeEngagementID },
  } = user;

  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(false);

  const { data: companyData, isLoading: isLoadingCompanyData } =
    useGetCompanyQuery(companyID);

  const [
    listDocumentsLibrary,
    { data: documentList, isLoading: isListingDocuments },
  ] = useLazyListDocumentsLibraryQuery();

  const [encryptConnectCredentials] = useEncryptConnectCredentialsMutation();

  const [generatePayrollConnectLink] = useGeneratePayrollConnectLinkMutation();

  const [connectAdditionalPayroll, { isLoading: isConnectingPayroll }] =
    useConnectAdditionalPayrollMutation();

  const [checkEmployeeLocation, { isLoading: isCheckingLocation }] =
    useCheckEmployeeLocationMutation();

  // Finch Callbacks
  const onError = ({ errorMessage }) => console.error(errorMessage);
  const onClose = () => console.log("User exited Finch Connect");
  const onSuccess = async ({ code }) => {
    const { engagementID } = engagementData;
    const connectPayrollResponse = await connectAdditionalPayroll({
      engagementID: engagementID,
      body: { code },
    });
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ connectPayrollResponse });

    const checkEmployeeResponse = await checkEmployeeLocation({
      engagementID: engagementID,
      body: {},
    });
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ checkEmployeeResponse });
  };

  const openFinch = useFinchConnect({
    clientId: "d3ccc21f-ef51-4545-bf23-d321c05b79e3",
    products: ["company", "directory", "individual", "employment", "payment", "pay_statement"],
    sandbox: process.env.REACT_APP_STAGE === "prod" ? false : true,
    onSuccess,
    onError,
    onClose,
  }).open;

  const onSendConnectSuccess = async (model) => {
    const engagementID = user.data.activeEngagementID;

    const encryptedCreds = await encryptConnectCredentials({
      type: "wages",
      engagementID: engagementID,
    });

    const generatePayrollResponse = await generatePayrollConnectLink({
      encryptedData: encryptedCreds.data,
      name: model.name,
      toEmail: model.email,
      baseURL: window.location.origin.replace(/(^\w+:|^)\/\//, ""),
    });
    if (process.env.REACT_APP_STAGE === "dev")
      console.log({ generatePayrollResponse });
  };

  // List Documents
  useEffect(() => {
    if (companyData) {
      const body = {
        format: true,
        bucket: companyData?.companyBucket?.name,
        key: `${activeEngagementID}/DocumentLibrary`,
      };
      listDocumentsLibrary({ body });
    }
  }, [
    activeEngagementID,
    companyData,
    isLoadingCompanyData,
    listDocumentsLibrary,
  ]);

  const actionCards = useMemo(() => {
    const loading = isListingDocuments || isLoadingCompanyData;
    const wagesConnectStatus =
      engagementData.status["portal-wages-connect"].complete;
    const hasW2Docs =
      documentList?.contents?.filter((doc) =>
        doc.tags.split(",").some((tag) => tag === "w2")
      ).length > 0;
    const hasImports =
      documentList?.contents
        ?.filter((doc) => doc.type === "Wages")
        ?.filter((doc) => {
          return doc.tags
            .split(",")
            .some((tag) => tag === "intelligent-import");
        }).length > 0;
    return [
      {
        id: 1,
        title: "Payroll Connect",
        description: "Connect directly to your payroll system.",
        icon: "assets/images/PayrollConnect_Icon.svg",
        tags: "payroll-connect",
        component: null,
        statusComponent:
          loading || isCheckingLocation || isConnectingPayroll ? (
            <SprxLoading h={4} w={4} />
          ) : wagesConnectStatus === true ? (
            <CheckCircleIcon className="flex-shrink-0 mt-1 h-4 w-4 text-sprxClientPortalDarkGreen" />
          ) : null,
      },
      {
        id: 2,
        title: "Send Payroll Connect",
        description:
          "Send a link to someone in your organization with proper payroll credentials.",
        icon: "assets/images/SendPayrollConnect_Icon.svg",
        tags: "send-payroll-connect",
        component: null,
        statusComponent:
          loading || isCheckingLocation || isConnectingPayroll ? (
            <SprxLoading />
          ) : wagesConnectStatus === true ? (
            <CheckCircleIcon className="flex-shrink-0 mt-1 h-4 w-4 text-sprxClientPortalDarkGreen" />
          ) : null,
      },
      {
        id: 3,
        title: "W2",
        description: "Upload PDF W2 forms.",
        expandedDescription:
          " Forms may take several minutes to process. Please check back shortly for an updated file listing.",
        icon: "assets/images/W2_Icon.svg",
        tags: "w2",
        statusComponent: loading ? (
          <SprxLoading />
        ) : hasW2Docs === true ? (
          <CheckCircleIcon className="flex-shrink-0 mt-1 h-4 w-4 text-sprxClientPortalDarkGreen" />
        ) : null,
        component: (
          <W2UploadPage
            engagementData={engagementData}
            user={user}
            documentList={documentList}
          />
        ),
      },
      {
        id: 4,
        title: "Intelligent Import",
        description: "Upload any spreadsheet file.",
        icon: "assets/images/Spreadsheet_Icon.svg",
        tags: "xlsx",
        statusComponent: loading ? (
          <SprxLoading />
        ) : hasImports === true ? (
          <CheckCircleIcon className="flex-shrink-0 mt-1 h-4 w-4 text-sprxClientPortalDarkGreen" />
        ) : null,
        component: (
          <IntelligentImportContainer
            engagementData={engagementData}
            user={user}
            type={"WAGES"}
          />
        ),
      },
    ];
  }, [
    documentList,
    engagementData,
    isCheckingLocation,
    isConnectingPayroll,
    isListingDocuments,
    isLoadingCompanyData,
    user,
  ]);

  return (
    <div className="grid grid-cols-1 gap-5 sm:grid-cols-2 sm:gap-6 lg:grid-cols-4 py-32 px-8 z-30">
      {actionCards.map((item) => (
        <motion.div
          key={item.id}
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            if (item.title === "Payroll Connect") {
              performClick("finch");
            } else if (item.title === "Send Payroll Connect") {
              performClick("send-finch");
            } else {
              setOpen(true);
              setSelectedID(item);
            }
          }}
          className={
            "flex flex-col w-full overflow-hidden rounded-lg shadow-xl border xl:min-w-270 xl:min-h-245 cursor-pointer"
          }
        >
          <motion.div
            className={classNames(
              "flex flex-1 flex-col justify-center items-center bg-white p-4"
            )}
          >
            <img
              className="xl:h-24 xl:w-24 h-16 w-16 my-4"
              src={item.icon}
              alt={item.title}
            />
            <motion.h3 className="xl:text-xl text-md text-center font-semibold text-gray-900">
              {item.title}
            </motion.h3>
            <motion.p className="mt-1 xl:text-sm text-xs text-center font-light tracking-tight text-text-gray-900">
              {item.description}
            </motion.p>
            <motion.div className="mt-1 xl:text-sm text-xs text-center font-light tracking-tight text-text-gray-900">
              {item.statusComponent}
            </motion.div>
          </motion.div>
        </motion.div>
      ))}
      <AnimatePresence>
        {open && selectedID && (
          <Dialog
            className="relative z-40"
            static
            layoutId={selectedID.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            as={motion.div}
            open={open}
            onClose={setOpen}
          >
            <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
            <motion.div className="fixed inset-0 z-10 overflow-y-auto">
              <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*Modal content */}
                <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-94vh min-w-95vw max-w-95vw">
                  <motion.div>
                    {/*Modal title, description, and close button */}
                    {true && (
                      <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                        <motion.button
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => {
                            setOpen(false);
                            setSelectedID(null);
                          }}
                        >
                          <XIcon className="h-6 w-6" aria-hidden="true" />
                        </motion.button>
                      </motion.div>
                    )}
                    <motion.div className="text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="text-3xl font-medium leading-6 text-gray-900 text-left p-4"
                      >
                        {selectedID.title}
                      </Dialog.Title>
                      <motion.div className="">
                        <motion.p className="text-sm text-gray-500 px-4 py-2 text-left">
                          {selectedID.description}{" "}
                          {selectedID?.expandedDescription}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                    {/*Modal main content */}
                    <motion.div className="mb-16">
                      {selectedID.component}
                    </motion.div>
                  </motion.div>
                </Dialog.Panel>
              </motion.div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
      <div hidden={true}>
        <motion.button
          id={"finch"}
          onClick={openFinch}
          className="bg-sprxGrey hover:bg-sprxTaxGray inline-flex items-center justify-center w-3/4 mt-4 px-3.5 py-2 border border-transparent text-xs leading-4 font-medium rounded-xl shadow-sm text-white focus:outline-none focus:ring-none"
        />
      </div>
      <SendConnectButton onSuccess={(model) => onSendConnectSuccess(model)}>
        <button id="send-finch" />
      </SendConnectButton>
    </div>
  );
};
