import { Visualizations } from "./Visualizations";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { VisualizationsLoading } from "./VisualizationsLoading";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";
import * as React from "react";

export const VisualizationsContainer = () => {
  const dataAPI = useDataArray();
  const commentsAPI = useComments();
  commentsAPI.setCommentTitle("Visualizations");

  if (dataAPI === null) return <VisualizationsLoading />;

  if (dataAPI.isDownloadingFile) return <VisualizationsLoading />;

  return (
    <div>
      <Visualizations
        wageData={dataAPI.wageData}
        supplyData={dataAPI.supplyData}
        contractData={dataAPI.contractData}
        computerLeaseData={dataAPI.computerLeaseData}
      />
    </div>
  );
};
