import {
  ArrowLeftIcon,
  ChatAlt2Icon,
  DownloadIcon,
} from "@heroicons/react/outline";
import { Engagement as TEngagement } from "../../types";
import Button from "../../../../ui-components/Button";
import { CommentsContainer } from "../../components";
import { useState } from "react";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";
import { CommentForm } from "../../components";
import { CertificationForm } from "../Engagement/CertificationForm";
import ReactPlayer from "react-player/lazy";
import { SiteVisitItem } from "../components";

interface EngagementProps {
  setActiveEngagement: (eng: TEngagement | undefined) => void;
  onDownloadFiles: () => Promise<void>;
  isDownloadingDocuments: boolean;
  onSubmit: () => Promise<void>;
  onAddComment: (arg0: { title: string; comment: string }) => Promise<void>;
  isCreatingComment: boolean;
  isSubmitting: boolean;
  videoUrl?: string;
  levels: any;
}

export const Engagement = ({
  setActiveEngagement,
  onDownloadFiles,
  isDownloadingDocuments,
  onSubmit,
  onAddComment,
  isCreatingComment,
  isSubmitting,
  videoUrl,
  levels,
}: EngagementProps) => {
  const [openSubmission, setOpenSubmission] = useState(false);
  const [openCreateComment, setOpenCreateComment] = useState(false);
  const [openThreadsView, setOpenThreadsView] = useState(false);

  const CommentFormProps = {
    id: 1,
    title: "Comment",
    description: "Add a comment.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentForm
        isCreatingComment={isCreatingComment}
        onSubmitForm={async (e: any) => {
          await onAddComment(e);
          setOpenCreateComment(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };

  const ThreadsProps = {
    id: 2,
    title: "Comment threads",
    description: "View comment threads.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CommentsContainer
        onClickAddCallback={() => setOpenCreateComment(true)}
        portalType="CERTIFICATION"
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };

  const SubmissionFormProps = {
    id: 3,
    title: "Submit",
    description: "Certify that our documents are correct.",
    extendedDescription: "If you have any questions, please reach out to us.",
    component: (
      <CertificationForm
        isSubmitting={isSubmitting}
        onSubmit={async () => {
          await onSubmit();
          setOpenSubmission(false);
        }}
      />
    ),
    hideCloseIcon: true,
    complete: false,
    isLoading: false,
  };
  return (
    <div className="h-auto">
      <div className="flex flex-col justify-start items-center mt-4 ml-4 h-full w-full">
        <div className="flex flex-row h-full items-center justify-between w-full">
          <button
            onClick={() => setActiveEngagement(undefined)}
            className="flex items-center justify-center h-10 w-10 pl-1 hover:bg-gray-100 transition-colors duration-200 ease-in-out"
          >
            <ArrowLeftIcon className="h-8 w-8" />
          </button>
          <div className="flex flex-row items-center">
            <Button
              secondary={true}
              type="button"
              onClick={async () => await onDownloadFiles()}
              buttonClassName="mr-4 my-4"
            >
              {isDownloadingDocuments ? (
                <div className="animate-pulse ml-2 my-2 h-2 bg-white rounded w-8"></div>
              ) : (
                <div className="flex flex-row justify-center w-full">
                  <DownloadIcon className="h-6 w-6" />
                </div>
              )}
            </Button>
            <Button
              buttonClassName="mr-4 my-4"
              onClick={() => setOpenThreadsView(true)}
              secondary={true}
            >
              <div className="flex flex-row justify-center w-full">
                <ChatAlt2Icon className="h-6 w-6 " />
              </div>
            </Button>

            <Button
              secondary={true}
              type="button"
              onClick={async () => setOpenSubmission(true)}
              buttonClassName="mr-4 my-4"
            >
              Submit
            </Button>
          </div>
          {/* <Button
                secondary={true}
                type="button"
                onClick={async () => await onDownloadSiteVisit()}
                buttonClassName="mr-4"
              >
                Download Site Visit
              </Button> */}
        </div>
        <div className="flex flex-col justify-between h-full w-full">
          <div className="flex flex-col w-full h-70vh justify-start items-center">
            {levels.length > 0 ? (
              <div className="flex flex-col w-full min-h-82vh justify-start items-center overflow-y-auto pr-4">
                {levels.map((level: any) => (
                  <SiteVisitItem level={level} />
                ))}
              </div>
            ) : (
              <div className="w-full h-full flex items-center justify-center ">
                <p>
                  SPRX hasn't uploaded any site visits for this engagement yet.
                  Please contact support if you suspect this isn't correct.
                </p>
              </div>
            )}
          </div>
        </div>
      </div>

      {/* Open Comment Threads Card  */}
      <OpenActionCard
        engagementData={null}
        markAsTracked={null}
        open={openCreateComment}
        setOpen={setOpenCreateComment}
        selectedID={CommentFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />

      {/* Create Comment Thread Card  */}

      <OpenActionCard
        open={openThreadsView}
        setOpen={setOpenThreadsView}
        selectedID={ThreadsProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={false}
      />

      {/* Submit Certification Card  */}
      <OpenActionCard
        open={openSubmission}
        setOpen={setOpenSubmission}
        selectedID={SubmissionFormProps}
        setSelectedID={() => null}
        closeVisible={true}
        setCloseVisible={() => null}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        small={true}
      />
    </div>
  );
};
