import { useState } from "react";
import { motion } from "framer-motion";
import { useDispatch } from "react-redux";
import { useUsers } from "../../../rtk/Users/useUsers";
import { AdminEngagementListLoading } from "../../AdminHome/components/AdminEngagementListLoading";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { setUserData } from "../../../auth/rtk/userSlice";
import { StarIcon } from "@heroicons/react/solid";
import { StarIcon as OutlinedStar } from "@heroicons/react/outline";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { DataSpace } from "../DataSpace";

export const DataSpacesList = ({
  dataSpaces,
  closeEngagement,
  companyName,
}: {
  dataSpaces: any;
  closeEngagement?: () => void;
  companyName: string;
}) => {
  const [filter, setFilter] = useState("");
  const { user } = useUsers();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [viewDataSpaceDetail, setViewDataSpaceDetail] = useState(false);

  const { getTaxUser, updateTaxUser } = useUsers();

  const activeDataSpace = dataSpaces?.find(
    (d) => d?.id === user?.data?.activeDataSpaceID,
  );

  const favoritedDataSpaces = dataSpaces?.filter(
    (d) =>
      user?.favorites?.DS?.includes(d?.id) && d?.id !== activeDataSpace?.id,
  );
  const renderedList = [
    ...([activeDataSpace] ?? []),
    ...([...favoritedDataSpaces] ?? []),
    ...dataSpaces
      ?.filter(
        (d) =>
          d?.id !== user?.data?.activeDataSpaceID &&
          !user?.favorites?.DS?.includes(d?.id),
      )
      ?.sort((a, b) => b?.createdAt - a?.createdAt),
  ];

  const onFavoriteEngagement = async (type, id) => {
    const userID = user.userID;
    let favorites = [
      ...(user?.favorites?.hasOwnProperty(type) ? user?.favorites[type] : []),
    ];

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      favorites.splice(index, 1);
    } else {
      favorites.push(id);
    }
    const body = {
      favorites: {
        ...(user?.favorites ?? []),
        [type]: favorites,
      },
    };
    const updateUserResponse = await updateTaxUser({ userID, body });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  const handleSetActiveClick = async (index: number, ds: any) => {
    setIsSettingActive(true);
    setEditIndex(index);
    const body = {
      activeDataSpaceID: ds.id,
      isUsingDataSpace: true,
      engagementType: "DS",
    };
    await updateTaxUser({
      userID: user.userID,
      body: body,
    });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
    setEditIndex(null);
    setIsSettingActive(false);
  };

  const handleFavoriteClick = async (index: number, dataSpaceID: string) => {
    setIsFavoriting(true);
    setEditIndex(index);
    await onFavoriteEngagement("DS", dataSpaceID);
    setEditIndex(null);
    setIsFavoriting(false);
  };

  if (activeDataSpace && viewDataSpaceDetail) {
    return <DataSpace setViewDataSpaceDetail={setViewDataSpaceDetail} />;
  }

  return (
    <div className={`flex-1 ${user.role === "client" ? "pt-6" : ""}`}>
      <div className="flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
        <div className="flex flex-row items-center">
          {closeEngagement ? (
            <button
              onClick={() => closeEngagement()}
              className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
            >
              <ArrowLeftIcon className="h-8 w-8" />
            </button>
          ) : null}
          <h1
            className={`${closeEngagement ? "ml-2" : ""} text-2xl font-semibold text-gray-900`}
          >
            {companyName} Data Spaces
          </h1>
        </div>
        <img
          style={{ width: 85, height: 36 }}
          src={"assets/images/logos/Logo@SVG.svg"}
          alt="SPRX.tax"
        />
      </div>
      <div className="">
        {/* Admin engagement table */}
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="items-between flex flex-row items-end justify-start">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Data Space Name</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Data Space Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Company Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          <span className="sr-only">Open</span>
                        </th>

                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Favorite</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {renderedList
                        ?.map((d) => ({ ...d }))
                        ?.filter((d) => {
                          const k = filter.toLowerCase();
                          return (
                            d?.dataSpaceName?.toLowerCase()?.includes(k) ||
                            false
                          );
                        })
                        ?.map((d, index) => (
                          <tr key={d.id}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {d.dataSpaceName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {d.companyName}
                            </td>
                            <td className="whitespace-nowrap py-4 px-4 text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                onClick={async () => {
                                  await handleSetActiveClick(index, d);
                                  setViewDataSpaceDetail(true);
                                }}
                              >
                                {isSettingActive && index === editIndex ? (
                                  <div className="w-full">
                                    <p className="h-2 w-16 animate-pulse rounded bg-sprxGrey"></p>
                                  </div>
                                ) : (
                                  "Open"
                                )}
                              </motion.button>
                            </td>

                            <td className="px-3 py-4">
                              {isFavoriting && index === editIndex ? (
                                <div className="w-full">
                                  <p className="h-2 w-6 animate-pulse rounded bg-sprxGrey"></p>
                                </div>
                              ) : (
                                <>
                                  {user?.favorites?.DS?.includes(d.id) ? (
                                    <StarIcon
                                      className={`h-7 w-7 align-middle text-sprxYellow`}
                                      onClick={async () => {
                                        await handleFavoriteClick(index, d.id);
                                      }}
                                    />
                                  ) : (
                                    <OutlinedStar
                                      className={`ml-0.5 h-6 w-6 align-middle text-sprxTaxLightGray`}
                                      onClick={async () => {
                                        await handleFavoriteClick(index, d.id);
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
