import { useRef, useCallback, useMemo, useEffect } from "react";
import { AgGridReact } from "ag-grid-react";
import { LicenseManager } from "ag-grid-enterprise";

import "ag-grid-enterprise";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-alpine.css";
import "./app.scss";
import { ModuleRegistry } from "@ag-grid-community/core";
import { ClientSideRowModelModule } from "@ag-grid-community/client-side-row-model";
import { GridChartsModule } from "@ag-grid-enterprise/charts";

import { SprxLoading } from "./index";

ModuleRegistry.registerModules([ClientSideRowModelModule, GridChartsModule]);

LicenseManager.setLicenseKey(process.env.REACT_APP_AG_GRID_KEY);

const DataTable = (props) => {
  const gridRef = useRef();
  const columnDef = useMemo(() => [...props.columns], [props.columns]);
  const gridOptions = useMemo(
    () => props?.gridOptions ?? {},
    [props.gridOptions]
  );

  const headerHeightGetter = () => {
    var columnHeaderTexts = [
      ...document.querySelectorAll(".ag-header-cell-text"),
    ];

    var clientHeights = columnHeaderTexts.map(
      (headerText) => headerText.clientHeight
    );

    var tallestHeaderTextHeight = Math.max(...clientHeights);

    return tallestHeaderTextHeight;
  };

  useEffect(() => {
    if (props.columns.length < 9) gridRef?.current?.api?.sizeColumnsToFit();
  }, [props.columns.length]);

  const onFirstDataRendered = useCallback(
    (params) => {
      if (props.columns.length < 9) {
        gridRef.current.api.sizeColumnsToFit();
      }

      var padding = 20;
      var height = headerHeightGetter() + padding;
      gridRef.current.api.setHeaderHeight(height);
      gridRef.current.api.resetRowHeights();
    },
    [props.columns.length]
  );

  const loadingOverlayComponent = useMemo(() => {
    return CustomLoadingOverlay;
  }, []);

  const loadingOverlayComponentParams = useMemo(() => {
    return {
      loadingMessage: "Loading Data...",
    };
  }, []);

  const noRowsOverlayComponent = useMemo(() => {
    return CustomNoRowsOverlay;
  }, []);

  const noRowsOverlayComponentParams = useMemo(() => {
    return {
      noRowsMessageFunc: () => "No records to display.",
    };
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      singleClickEdit: true,
      // enableRowGroup: true,
      // enablePivot: true,
      // pivot: true,
      enableValue: true,
      sortable: true,
      wrapText: props.wrapText ? props.wrapText : false,
      autoHeight: props.autoHeight ? props.autoHeight : false,
      headerComponentParams: {
        template:
          '<div class="ag-cell-label-container" role="presentation">' +
          '  <span ref="eMenu" class="ag-header-icon ag-header-cell-menu-button"></span>' +
          '  <div ref="eLabel" class="ag-header-cell-label" role="presentation">' +
          '    <span ref="eSortOrder" class="ag-header-icon ag-sort-order"></span>' +
          '    <span ref="eSortAsc" class="ag-header-icon ag-sort-ascending-icon"></span>' +
          '    <span ref="eSortDesc" class="ag-header-icon ag-sort-descending-icon"></span>' +
          '    <span ref="eSortNone" class="ag-header-icon ag-sort-none-icon"></span>' +
          '    <span ref="eText" class="ag-header-cell-text" role="columnheader" style="white-space: normal;"></span>' +
          '    <span ref="eFilter" class="ag-header-icon ag-filter-icon"></span>' +
          "  </div>" +
          "</div>",
      },
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div
      className="ag-theme-custom-react"
      style={{ height: "100%", width: "100%" }}
    >
      <AgGridReact
        enableRangeSelection={true}
        ref={gridRef}
        rowData={props.data}
        columnDefs={columnDef}
        defaultColDef={defaultColDef}
        onFirstDataRendered={onFirstDataRendered}
        loadingOverlayComponent={loadingOverlayComponent}
        loadingOverlayComponentParams={loadingOverlayComponentParams}
        noRowsOverlayComponent={noRowsOverlayComponent}
        noRowsOverlayComponentParams={noRowsOverlayComponentParams}
        rowSelection={"multiple"}
        onGridReady={() => (props?.onGridReady ? props?.onGridReady() : null)}
        detailCellRenderer={
          props.detailCellRenderer ? props.detailCellRenderer : null
        }
        onCellValueChanged={(params) => {
          props.onRowUpdate(params.data, params);
        }}
        masterDetail={props.masterDetail ? props.masterDetail : false}
        animateRows={true}
        suppressMultiRangeSelection={true}
        stopEditingWhenCellsLoseFocus={true}
        suppressScrollOnNewData={true}
        suppressCsvExport={true}
        suppressExcelExport={true}
        getRowId={(params) => params.data.ID}
        gridOptions={gridOptions}
      />
    </div>
  );
};

export default DataTable;

const CustomLoadingOverlay = (props) => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: "", height: "9%" }}
    >
      <i className="fas fa-hourglass-half">
        {props.loadingMessage}
        <div className="mt-2">
          <SprxLoading />
        </div>
      </i>
    </div>
  );
};

const CustomNoRowsOverlay = (props) => {
  return (
    <div
      className="ag-overlay-loading-center"
      style={{ backgroundColor: "", height: "9%" }}
    >
      <i className="far fa-frown"> {props.noRowsMessageFunc()}</i>
    </div>
  );
};
