import { useEffect, useState } from "react";
import { Comments } from "../../R&D/DeliverablesPortal/Comments/Comments";
import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { SprxLoading } from "../../../ui-components";
import { useEnergyComments } from "../../../rtk/Energy/Comments/useEnergyComments";
import { transposeComments } from "../../R&D/DeliverablesPortal/Comments/utils/transposeComments";
import { formatTimestamp } from "../../R&D/DeliverablesPortal/Comments/components/ActivityItem";
import { EmptyComments } from "../../R&D/DeliverablesPortal/Comments/EmptyComments";
import { useUsers } from "../../../rtk/Users/useUsers";

interface CommentsContainerProps {
  onClickAddCallback?: () => void;
  portalType: string;
}

export const CommentsContainer = ({
  onClickAddCallback,
  portalType,
}: CommentsContainerProps) => {
  const energyEngagementAPI = useEnergyEngagement();
  const commentsAPI = useEnergyComments();
  const usersAPI = useUsers();

  useEffect(() => {
    if (commentsAPI) commentsAPI.setCommentTitle("Comments");
  }, [commentsAPI]);

  if (
    !energyEngagementAPI ||
    !commentsAPI ||
    !usersAPI ||
    commentsAPI.isLoadingComments ||
    commentsAPI.isDeletingComment
  )
    return (
      <div className="flex flex-auto items-center justify-center h-72vh">
        <div className="flex items-center justify-center w-1/2">
          <SprxLoading />
        </div>
      </div>
    );

  const engagementCommentIDs = energyEngagementAPI?.engagement?.comments?.map(
    (c) => c.commentID
  );
  const threads = Array.isArray(commentsAPI.comments)
    ? commentsAPI.comments
        .map(transposeComments)
        .sort((a: any, b: any) => {
          const dateOne = new Date(a[0]?.date).valueOf();
          const dateTwo = new Date(b[0]?.date).valueOf();
          return dateTwo - dateOne;
        })
        .filter(
          (e) =>
            e !== null &&
            e[0].portalType === portalType &&
            engagementCommentIDs.includes(e[0].commentID)
        )
    : [];

  if (commentsAPI && !commentsAPI.isLoadingComments && threads.length === 0) {
    return (
      <EmptyComments
        onClickAddCallback={onClickAddCallback}
        height={"h-72vh"}
      />
    );
  }

  const panelItems = threads.map((thread: any) => {
    return {
      ...thread[0],
      commentID: thread[0].commentID,
      title: `${thread[0].title} - ${formatTimestamp(thread[0].date)}`,
      isResolved: thread[0].status === "RESOLVED",
    };
  });

  const openPanelItems = panelItems.filter((item: any) => !item.isResolved);
  const resolvedPanelItems = panelItems.filter((item: any) => item.isResolved);

  return (
    <Comments
      isAdmin={usersAPI.user.role === "admin"}
      threads={threads}
      onDeleteComment={commentsAPI.onDeleteComment}
      onAddNote={commentsAPI.onAddNote}
      isAddingNote={commentsAPI.isAddingNote}
      onResolveComment={commentsAPI.onResolveComment}
      isResolvingComment={commentsAPI.isResolvingComment}
      isDeletingComment={commentsAPI.isDeletingComment}
      openPanelItems={openPanelItems}
      resolvedPanelItems={resolvedPanelItems}
      onClickAddCallback={onClickAddCallback}
      height={"h-70vh"}
    />
  );
};
