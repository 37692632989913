import React from "react";
import { Fragment } from "react";
import { Dialog, Transition, Switch, Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/solid";
import _ from "lodash";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";

import { Button } from ".";

const schema = yup.object().shape({
  email: yup
    .string()
    .email("Please enter a valid email address")
    .required("You must enter a email"),
  name: yup.string().required("Please enter a name."),
  companyName: yup.string().required("Please enter a company name."),
  singleUse: yup.boolean(),
  expirationDate: yup.string().required("Please enter an expiration date."),
  selfManagedRate: yup.number().required().positive(),
  selfManagedEnabled: yup.boolean(),
  hybridRate: yup.number().required().positive(),
  hybridEnabled: yup.boolean(),
  sprxManagedRate: yup.number().required().positive(),
  sprxManagedEnabled: yup.boolean(),
});

const defaultValues = {
  email: "",
  name: "",
  companyName: "",
  singleUse: true,
  expirationDate: "",
  selfManagedRate: 6,
  selfManagedEnabled: true,
  hybridRate: 9,
  hybridEnabled: true,
  sprxManagedRate: 12,
  sprxManagedEnabled: true,
};

const SendDiscountCodeModal = (props) => {
  const { control, formState, handleSubmit } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  const onClickSend = async (model) => {
    props.onSuccess(model);
  };

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-md sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Send a Discount Code
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Enter the name, email address, and discount deatils of the
                      person you would like to send a discount code to.
                    </p>
                    <form
                      className="mt-5 sm:flex sm:items-center sm:flex-col"
                      onSubmit={handleSubmit((model) => onClickSend(model))}
                    >
                      <Controller
                        name="name"
                        control={control}
                        render={({ field }) => (
                          <div className="w-full sm:max-w-xs">
                            <label htmlFor="name" className="sr-only">
                              Name
                            </label>
                            <input
                              {...field}
                              type="text"
                              name="Name"
                              id="Name"
                              required
                              autoComplete="name"
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Jane Doe"
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="companyName"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-xs">
                            <label htmlFor="companyName" className="sr-only">
                              Company Name
                            </label>
                            <input
                              {...field}
                              type="text"
                              name="companyName"
                              id="companyName"
                              required
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="XYZ Co."
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="email"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-xs">
                            <label htmlFor="email" className="sr-only">
                              Email
                            </label>
                            <input
                              {...field}
                              type="email"
                              name="email"
                              id="email"
                              required
                              autoComplete="email"
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="you@example.com"
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="expirationDate"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-xs">
                            <label htmlFor="expirationDate" className="sr-only">
                              Expiration Date
                            </label>
                            <input
                              id={"expirationDate"}
                              {...field}
                              className={classNames(
                                "mt-2 form-input block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
                              )}
                              type={"text"}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => (e.target.type = "text")}
                              placeholder={"XX/XX/XXXX"}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="singleUse"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-xs">
                            <label htmlFor="singleUse" className="sr-only">
                              Single Use
                            </label>

                            <Switch.Group
                              as="div"
                              className="flex items-center justify-between"
                            >
                              <span className="flex-grow flex flex-col">
                                <Switch.Label
                                  as="span"
                                  className="text-sm text-left font-medium text-gray-900"
                                  passive
                                >
                                  Is code single use?
                                </Switch.Label>
                                <Switch.Description
                                  as="span"
                                  className="text-sm text-left text-gray-500"
                                >
                                  Turning this option on will only allow the
                                  code to be used one time.
                                </Switch.Description>
                              </span>
                              <Switch
                                checked={field.value}
                                onChange={field.onChange}
                                className={classNames(
                                  field.value
                                    ? "bg-sprxTaxBlue"
                                    : "bg-gray-200",
                                  "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
                                )}
                              >
                                <span
                                  aria-hidden="true"
                                  className={classNames(
                                    field.value
                                      ? "translate-x-5"
                                      : "translate-x-0",
                                    "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                  )}
                                />
                              </Switch>
                            </Switch.Group>
                          </div>
                        )}
                      />

                      <div className="pt-8">
                        <div>
                          <h3 className="text-md leading-6 font-medium text-gray-900">
                            Pricing
                          </h3>
                          <p className="mt-1 text-sm text-gray-500">
                            Use elements below to indicate the pricing rate and
                            availability.
                          </p>
                        </div>
                        <Disclosure as="div" className="mt-2">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-sprxGrey bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                                <span>Self Managed</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-blue-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="selfManagedRate"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="selfManagedRate"
                                            className="sr-only"
                                          >
                                            Self Managed Rate
                                          </label>
                                          <input
                                            {...field}
                                            type="number"
                                            name="selfManagedRate"
                                            id="selfManagedRate"
                                            required
                                            className="text-black shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="6%"
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>

                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="selfManagedEnabled"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="selfManagedEnabled"
                                            className="sr-only"
                                          >
                                            Self Managed Enabled
                                          </label>

                                          <Switch.Group
                                            as="div"
                                            className="flex items-center justify-between"
                                          >
                                            <span className="flex-grow flex flex-col">
                                              <Switch.Label
                                                as="span"
                                                className="text-sm text-left font-medium text-gray-900"
                                                passive
                                              >
                                                Is this pricing level enabled?
                                              </Switch.Label>
                                            </span>
                                            <Switch
                                              checked={field.value}
                                              onChange={field.onChange}
                                              className={classNames(
                                                field.value
                                                  ? "bg-sprxTaxBlue"
                                                  : "bg-gray-200",
                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
                                              )}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  field.value
                                                    ? "translate-x-5"
                                                    : "translate-x-0",
                                                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                )}
                                              />
                                            </Switch>
                                          </Switch.Group>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                        <Disclosure as="div" className="mt-2">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-sprxGrey bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                                <span>Hybrid</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-blue-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="hybridRate"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="hybridRate"
                                            className="sr-only"
                                          >
                                            Hybrid Rate
                                          </label>
                                          <input
                                            {...field}
                                            type="number"
                                            name="hybridRate"
                                            id="hybridRate"
                                            required
                                            className="text-black shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="9%"
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>

                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="hybridEnabled"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="hybridEnabled"
                                            className="sr-only"
                                          >
                                            Hybrid Enabled
                                          </label>

                                          <Switch.Group
                                            as="div"
                                            className="flex items-center justify-between"
                                          >
                                            <span className="flex-grow flex flex-col">
                                              <Switch.Label
                                                as="span"
                                                className="text-sm text-left font-medium text-gray-900"
                                                passive
                                              >
                                                Is this pricing level enabled?
                                              </Switch.Label>
                                            </span>
                                            <Switch
                                              checked={field.value}
                                              onChange={field.onChange}
                                              className={classNames(
                                                field.value
                                                  ? "bg-sprxTaxBlue"
                                                  : "bg-gray-200",
                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
                                              )}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  field.value
                                                    ? "translate-x-5"
                                                    : "translate-x-0",
                                                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                )}
                                              />
                                            </Switch>
                                          </Switch.Group>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                        <Disclosure as="div" className="mt-2">
                          {({ open }) => (
                            <>
                              <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-sprxGrey bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                                <span>SPRX Managed</span>
                                <ChevronDownIcon
                                  className={`${
                                    open ? "transform rotate-180" : ""
                                  } w-5 h-5 text-blue-500`}
                                />
                              </Disclosure.Button>
                              <Disclosure.Panel className="px-4 pt-4 pb-2 text-sm text-gray-500">
                                <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="sprxManagedRate"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="sprxManagedRate"
                                            className="sr-only"
                                          >
                                            SPRX Managed Rate
                                          </label>
                                          <input
                                            {...field}
                                            type="number"
                                            name="sprxManagedRate"
                                            id="sprxManagedRate"
                                            required
                                            className="text-black shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                                            placeholder="9%"
                                          />
                                        </div>
                                      )}
                                    />
                                  </div>

                                  <div className="sm:col-span-3">
                                    <Controller
                                      name="sprxManagedEnabled"
                                      control={control}
                                      render={({ field }) => (
                                        <div className="mt-4 w-full sm:max-w-xs">
                                          <label
                                            htmlFor="sprxManagedEnabled"
                                            className="sr-only"
                                          >
                                            SPRX Managed Enabled
                                          </label>

                                          <Switch.Group
                                            as="div"
                                            className="flex items-center justify-between"
                                          >
                                            <span className="flex-grow flex flex-col">
                                              <Switch.Label
                                                as="span"
                                                className="text-sm text-left font-medium text-gray-900"
                                                passive
                                              >
                                                Is this pricing level enabled?
                                              </Switch.Label>
                                            </span>
                                            <Switch
                                              checked={field.value}
                                              onChange={field.onChange}
                                              className={classNames(
                                                field.value
                                                  ? "bg-sprxTaxBlue"
                                                  : "bg-gray-200",
                                                "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none focus:ring-0 focus:ring-offset-0"
                                              )}
                                            >
                                              <span
                                                aria-hidden="true"
                                                className={classNames(
                                                  field.value
                                                    ? "translate-x-5"
                                                    : "translate-x-0",
                                                  "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                                                )}
                                              />
                                            </Switch>
                                          </Switch.Group>
                                        </div>
                                      )}
                                    />
                                  </div>
                                </div>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      </div>

                      <Button
                        secondary={true}
                        buttonClassName={classNames(
                          "mt-5 sm:mt-6 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-sprxTaxBlue text-base font-medium text-white hover:bg-sprxTaxDarkBlue focus:outline-none sm:text-sm",
                          _.isEmpty(dirtyFields) &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed",
                          !isValid &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed"
                        )}
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                      >
                        Send
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default SendDiscountCodeModal;
