import { useMemo, useState, useEffect } from "react";
import { Documentation } from "./Documentation";
import { getDocumentationTableFields } from "./utils";
import { formatDocumentKey } from "../../../utils/format";
import { useEnergyEngagement } from "../../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useFile } from "../../../../../rtk/R&D/File/useFile";
import { useCompanyData } from "../../../../../rtk/CompanyData/useCompanyData";
import { useUsers } from "../../../../../rtk/Users/useUsers";
import { FileActionButton } from "./components/FileActionButton";
import { SprxLoading } from "../../../../../ui-components";
import { CheckCircleIcon } from "@heroicons/react/outline";

const mapDocs = (d: any) => ({
  ...d,
  Download: "",
  Delete: "",
  extracting: !d?.extracted ?? false,
  ID: d?.name,
  summary: d?.summary ?? "",
});

export const DocumentationContainer = () => {
  const companyAPI = useCompanyData();
  const energyEngagementAPI = useEnergyEngagement();
  const fileAPI = useFile();
  const usersAPI = useUsers();
  const [hasUpdates, setHasUpdates] = useState(false);
  const [updatedDocuments, setUpdatedDocuments] = useState<any[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const engagementID: string =
    energyEngagementAPI?.engagement?.engagementID ?? "";
  const bucket: string = energyEngagementAPI?.engagement?.s3Bucket?.name ?? "";
  const documents: any[] = useMemo(
    () => energyEngagementAPI?.engagement?.documents ?? [],
    [energyEngagementAPI?.engagement?.documents],
  );

  // Polling for updated documents
  useEffect(() => {
    const interval = setInterval(async () => {
      if (!engagementID || !energyEngagementAPI) return;
      await energyEngagementAPI.getEngagement(engagementID);
      if (
        energyEngagementAPI.getEngagementData &&
        JSON.stringify(energyEngagementAPI.getEngagementData?.documents) !==
          JSON.stringify(documents) &&
        documents?.some((d) => !d.extracted)
      ) {
        alert("UPDATING");
        setUpdatedDocuments(
          energyEngagementAPI.getEngagementData?.documents ?? [],
        );
        setHasUpdates(true);
      }
    }, 5000); // Poll every 5 seconds

    return () => clearInterval(interval); // Cleanup on unmount
  }, [documents, energyEngagementAPI, engagementID]);

  const onDocumentUpdate = async (document: any) => {
    setHasUpdates(true);
    let updatedDocuments = documents.map((d) => ({ ...d }));
    const index = documents.findIndex((d) => d.name === document.name);
    updatedDocuments[index] = {
      ...updatedDocuments[index],
      ...document,
    };
    setUpdatedDocuments(updatedDocuments);
    const updateDocumentsPayload = {
      documents: updatedDocuments,
    };
    await energyEngagementAPI.updateEngagement({
      engagementID,
      body: updateDocumentsPayload,
    });
  };

  const onDocumentUpload = async (event: any) => {
    setIsUploading(true);
    const { files } = event.target;
    const uploadedBy = usersAPI.user.fullName;
    const uploadDate = new Date().toISOString();
    const uploadPromises = [...files]
      .map(async (file: any) => {
        if (documents.some((d) => d.name === file.name)) return null;
        const name = file.name;
        const arr = name.split(".");
        const format = arr[arr.length - 1];
        const key = formatDocumentKey(name, engagementID);
        const uploadParams = {
          file,
          action: "putObject",
          key,
          type: "energy",
          bucket,
        };
        await fileAPI.uploadFile(uploadParams);
        return {
          format,
          name,
          uploadedBy,
          uploadDate,
          relatedSystem: "Unassigned",
          fileType: file.type,
        };
      })
      .filter((p) => p !== null); // Remove nulls (files that already exist in documents
    const updateResponse = await Promise.all(uploadPromises);

    const updateEngagementDataPayload = {
      documents: [
        ...(energyEngagementAPI?.engagement?.documents ?? []),
        ...updateResponse,
      ],
    };
    await energyEngagementAPI.updateEngagement({
      engagementID,
      body: updateEngagementDataPayload,
    });
    setIsUploading(false);
    await energyEngagementAPI.digestDocuments({ engagementID });
  };

  const onDownloadDocument = async (doc: any) => {
    const key = formatDocumentKey(doc.name, engagementID);
    const body = { bucket, key, action: "getObject" };
    const response = await fileAPI.downloadDocument({ ...body });
    const url = response?.data?.url ?? "";

    try {
      const fileResponse = await fetch(url);
      if (!fileResponse.ok) throw new Error("Failed to download file");

      const blob = await fileResponse.blob();
      const blobUrl = window.URL.createObjectURL(blob);

      const link = document.createElement("a");
      link.href = blobUrl;
      link.download = key;
      document.body.appendChild(link);
      link.click();

      // Cleanup
      document.body.removeChild(link);
      window.URL.revokeObjectURL(blobUrl);
    } catch (error) {
      console.error("Error downloading document:", error);
    }
  };

  const onDeleteDocument = async (doc: any) => {
    // Delete Document
    const key: string = formatDocumentKey(doc.name, engagementID);
    const deleteFileParams = { bucket, key, action: "getObject" };
    await fileAPI.deleteFile({ body: deleteFileParams });

    // Update Engagement Data
    const newDocuments = documents.filter((d) => d.name !== doc.name);
    const updateEngagementDataPayload = {
      documents: newDocuments,
    };
    await energyEngagementAPI.updateEngagement({
      engagementID,
      body: updateEngagementDataPayload,
    });
  };

  const fields = [
    ...getDocumentationTableFields(),
    {
      headerName: "",
      field: "extracted",
      width: 64,
      // Employee Drill Down Button
      cellRenderer: (rowData: any) => {
        return rowData.value === true ? (
          <div className="flex h-full w-full items-center justify-center">
            <CheckCircleIcon className="h-5 w-5 text-green-500" />
          </div>
        ) : (
          <SprxLoading width="24px" height="24px" />
        );
      },
    },
    {
      headerName: "",
      field: "Download",
      width: 64,
      // Employee Drill Down Button
      cellRenderer: (rowData: any) => {
        return (
          <FileActionButton
            type={"DOWNLOAD"}
            onClick={async () => await onDownloadDocument(rowData.data)}
          />
        );
      },
    },
    {
      headerName: "",
      field: "Delete",
      width: 64,
      // Employee Drill Down Button
      cellRenderer: (rowData: any) => {
        return (
          <FileActionButton
            type={"DELETE"}
            onClick={async () => await onDeleteDocument(rowData.data)}
          />
        );
      },
    },
  ];

  if (!energyEngagementAPI || !fileAPI || !companyAPI || !usersAPI)
    return <div>loading...</div>;

  return (
    <Documentation
      fields={fields}
      documents={
        hasUpdates ? updatedDocuments.map(mapDocs) : documents.map(mapDocs)
      }
      onDocumentUpdate={onDocumentUpdate}
      onDocumentUpload={onDocumentUpload}
      onDeleteDocument={onDeleteDocument}
      onDownloadDocument={onDownloadDocument}
      isUploading={isUploading}
    />
  );
};
