import { ProfileForm } from "./ProfileForm";

function Profile() {
  return (
    <>
      <ProfileForm />
    </>
  );
}

export default Profile;
