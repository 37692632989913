import * as React from "react";
import { useFile } from "../../../../../rtk/R&D/File/useFile";
import { useEngagementData } from "../../../../../rtk/R&D/EngagementData/useEngagementData";
import { SprxLoading } from "../../../../../ui-components";
import { DocumentViewer } from "../components/DocumentViewer";

export const ExecutiveMemoContainer = () => {
  const [docUrl, setDocUrl] = React.useState<string>("");
  const hasFetchedUrl = React.useRef<boolean>(false);

  const fileAPI = useFile();
  const engagementDataAPI = useEngagementData();

  React.useEffect(() => {
    async function getURL(): Promise<void> {
      if (hasFetchedUrl.current) return; // Exit if URL has already been fetched
      hasFetchedUrl.current = true; // Mark as fetched

      const engagementData = engagementDataAPI.engagementData;

      const bucket: string = engagementData.s3Bucket?.name;
      const engagementID: string = engagementData.engagementID;
      const key: string = `${engagementID}/deliverables/Executive Memo.docx`;

      if (bucket && engagementID && key) {
        const body = {
          bucket,
          key,
          action: "getObject",
        };
        try {
          const signedUrlResponse = await fileAPI.downloadDocument(body);
          setDocUrl(signedUrlResponse.data.url);
        } catch (err) {
          console.error(err);
        }
      }
    }
    if (!engagementDataAPI || !fileAPI) return;
    getURL();
  }, [engagementDataAPI, fileAPI]); // Dependencies

  if (docUrl === "")
    return (
      <div className="flex justify-center items-center h-800px">
        <SprxLoading />
      </div>
    );

  return <DocumentViewer docUrl={docUrl} />;
};
