export const mergeBulkAllocationData = (data, uploadedData, type) => {
  // Merge Uploaded Data
  let hasNewItem = false;
  const tmpData = data.map((item) => {
    // Create Copy for Completions Additions
    let oldCopy: any = {};
    Object.assign(oldCopy, item);

    // Determine Location of New Item in Original Listing
    let tmp = {};
    const newIndex = uploadedData.findIndex((a) => a.ID === item.ID);
    if (newIndex === -1) return { ...item };

    // Isolate New Allocations
    const newItem = uploadedData[newIndex];
    const { ID, ...allocations } = newItem;

    // Format Allocations
    Object.keys(allocations).forEach((k) => {
      if (allocations[k] !== undefined && allocations[k] > 0) {
        tmp[k] = Math.round(Number(allocations[k]));

        // Handle Employee Project Allocations for Completions
        if (type === "Wages") {
          if (!oldCopy.hasOwnProperty("completions")) {
            oldCopy["completions"] = {};
          }
          if (!oldCopy.completions.hasOwnProperty(k)) {
            hasNewItem = true;
            oldCopy.completions = {
              ...oldCopy.completions,
              [`${k}`]: { isLoading: true },
            };
          }
        }
      }
    });
    return {
      ...oldCopy,
      ...tmp,
    };
  });
  return { tmpData, hasNewItem };
};
