import React, { useEffect } from "react";
import _ from "lodash";
import { classNames } from "../../R&D/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon } from "@heroicons/react/outline";
import {
  getCreateCompanyFormDefaultFields,
  getCreateCompanyFormShape,
} from "./util";
import { Button } from "../../../ui-components";
import { CreateCompanyModel } from "../types/company";
import { CreateFormWrapper } from "../components/CreateFormWrapper";

interface CreateCompanyFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreate: (model: CreateCompanyModel) => Promise<void>;
  isCreatingCompany: boolean;
}

export const CreateCompanyForm = ({
  open,
  setOpen,
  onCreate,
  isCreatingCompany,
}: CreateCompanyFormProps) => {
  const schema = getCreateCompanyFormShape();
  const defaultValues = getCreateCompanyFormDefaultFields();
  const { control, formState, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const onClickSend = async (model) => {
    onCreate(model);
  };

  return (
    <CreateFormWrapper open={open} setOpen={setOpen} type="Company">
      <form
        className="mt-5 sm:flex sm:flex-col sm:items-center"
        onSubmit={handleSubmit((model) => onClickSend(model))}
      >
        <Controller
          name="companyName"
          control={control}
          render={({ field }) => (
            <div className="w-full text-left sm:max-w-xs">
              <label htmlFor="name" className="text-left">
                Company Name <span className="text-sprxOrange">*</span>
              </label>
              <input
                {...field}
                type="text"
                name="Company Name"
                id="companyName"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                placeholder="Business Corp"
              />
            </div>
          )}
        />
        <Controller
          name="emailDomain"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full text-left sm:max-w-xs">
              <label htmlFor="emailDomain">
                Email Domain <span className="text-sprxOrange">*</span>
              </label>
              <input
                {...field}
                type="text"
                name="emailDomain"
                id="emailDomain"
                required
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                placeholder="outlook.com"
              />
            </div>
          )}
        />

        <motion.div className="mt-12 flex w-full flex-row items-end justify-end">
          <motion.button
            type="submit"
            data-test="Save & Close"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={_.isEmpty(dirtyFields) || !isValid}
            className={
              "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
              (!isValid
                ? "cursor-not-allowed bg-opacity-50"
                : " hover:bg-sprxClientPortalDarkBlue")
            }
          >
            {isCreatingCompany ? (
              <div className="flex h-5 w-full flex-col items-center justify-center">
                <p
                  className={"h-2 w-full animate-pulse rounded-md bg-gray-100"}
                ></p>
              </div>
            ) : (
              "Create"
            )}
          </motion.button>
        </motion.div>
      </form>
    </CreateFormWrapper>
  );
};
