export const translateImportTypeToListKey = (type) => {
  switch (type) {
    // Legacy Mappings
    case "Computer Lease Intelligent Import":
      return "computerLeaseList";
    case "Projects Intelligent Import":
      return "projectList";
    case "Contract Research Intelligent Import":
      return "crList";
    case "Supply Intelligent Import":
      return "supplyList";
    case "Wages Intelligent Import":
      return "wageList";

    // New Mappings
    case "Computer Lease":
      return "computerLeaseList";
    case "Computer":
      return "computerLeaseList";
    case "Projects":
      return "projectList";
    case "Contract Research":
      return "crList";
    case "Contract":
      return "crList";
    case "Supply":
      return "supplyList";
    case "Wages":
      return "wageList";
    default:
      return "";
  }
};
