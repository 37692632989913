import React from "react";
import SubHeading from "./SubHeading";
import classNames from "classnames";

const LaunchCard = (props) => {
  const { title, description, button, content } = props;
  return (
    <div
      className={classNames(
        "flex flex-grow min-h-launchCardHeight bg-white w-full overflow-hidden elevation-4 rounded-lg my-2 p-4",
        props.className && props.className,
        props.disabled &&
          "bg-gray-500 opacity-50 pointer-events-none cursor-not-allowed"
      )}
    >
      <div
        className={classNames(
          "flex flex-row justify-between align-center min-w-full h-full"
        )}
      >
        <div className={classNames("flex flex-col justify-start w-3/4 h-full")}>
          <div className={classNames("")}>
            <SubHeading className={classNames("font-serif text-xl text-left")}>
              {title}
            </SubHeading>
          </div>
          {description && (
            <div className={classNames("")}>
              <p
                className={classNames(
                  props.disabled ? "" : "text-gray-500",
                  "text-sm"
                )}
              >
                {description}
              </p>
            </div>
          )}

          {content && (
            <div className={classNames("flex flex-col")}>{content}</div>
          )}
        </div>
        <div
          className={classNames(
            "flex h-full items-center justify-center self-center"
          )}
        >
          {button}
        </div>
      </div>
    </div>
  );
};

export default LaunchCard;
