import { useData } from "../hooks/useData";
import { LoadingPill, Pill } from "./Pill";

export const CostsList = ({
  engagement,
  type,
}: {
  engagement: any;
  type: "QRES" | "costs" | "credits";
}) => {
  const costs = useData(engagement);
  const title = {
    QRES: "Qualified Research Expenses",
    costs: "Cost Totals",
    credits: "Credits",
  }[type];
  const key = { QRES: "qre", costs: "cost", credits: "credit" }[type];
  return (
    <div>
      <div className="text-lg font-bold">{title}</div>
      <div className="my-2 flex flex-row flex-wrap justify-start">
        {type === "credits"
          ? formatCreditList(engagement).map((item: any, index: number) => (
              <Pill key={index} title={item.title} value={item.credit} />
            ))
          : !costs
            ? Array(5)
                .fill(null)
                .map(() => <LoadingPill />)
            : Object.keys(costs).map((itemTitle: any, index: number) => (
                <Pill
                  key={index}
                  title={itemTitle}
                  value={costs[itemTitle][key]}
                />
              ))}
      </div>
    </div>
  );
};

const formatCreditList = (engagement: any) => {
  const federalCredit = engagement?.credit?.rdCredit ?? 0;
  const stateCredits = engagement.stateCredits
    .filter((s: any) => s?.form?.credit > 0)
    .map((s: any) => ({ credit: Math.round(s.form.credit), title: s.state }))
    .sort((a: any, b: any) => b.credit - a.credit);
  const totalCredit =
    stateCredits.reduce((acc, s) => acc + s.credit, 0) + federalCredit;
  return [
    {
      title: "Total",
      credit: Math.round(totalCredit),
    },
    {
      title: "Federal",
      credit: Math.round(federalCredit),
    },
    ...stateCredits,
  ];
};
