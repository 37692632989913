import React from "react";
import ReactDOM from "react-dom/client";
import * as Sentry from "@sentry/react";
import { BrowserTracing } from "@sentry/tracing";

import "./styles/index.css";
import App from "./app/App";

Sentry.init({
  dsn: "https://b8ee7fb3b925438eb069bfe9454b040c@o1271034.ingest.sentry.io/6462444",
  integrations: [new BrowserTracing()],
  tracesSampleRate: 1.0,
});

export function cssHasLoaded(theme) {
  const themeEl = document.createElement("div");

  document.body.appendChild(themeEl);

  try {
    themeEl.className = theme;

    const cellEl = document.createElement("div");

    cellEl.className = "ag-cell";

    themeEl.appendChild(cellEl);

    const computedStyle = window.getComputedStyle(cellEl);

    return parseFloat(computedStyle.paddingLeft) > 0;
  } finally {
    document.body.removeChild(themeEl);
  }
}

function initialise() {
  // if (cssHasLoaded("ag-theme-custom-react")) {
  // ReactDOM.render(
  //   // <React.StrictMode>
  //   <App />,
  //   // </React.StrictMode>,
  //   document.getElementById("root")
  // );
  const root = ReactDOM.createRoot(document.getElementById("root"));

  root.render(
    <React.StrictMode>
      <App />
    </React.StrictMode>
  );
  // } else {
  //   setTimeout(initialise, 100);
  //   console.log("error");
  // }
}

initialise();
