import * as React from "react";

interface InviteUserProps {
  onClickInvite: () => void;
}

export const InviteUser = ({ onClickInvite }: InviteUserProps) => {
  return (
    <div className="flex flex-grow items-center justify-end w-1/3 pl-8">
      <div className="flex flex-col justify-center items-center w-full py-4 rounded-2xl border shadow-xl h-12vh">
        <h1 className="text-xl text-center font-semibold text-gray-900">
          Invite a User
        </h1>
        <button
          type="button"
          className="inline-flex items-center justify-center w-1/2 mt-2 px-3.5 py-2 border border-transparent text-sm leading-4 font-medium rounded-xl shadow-sm text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none"
          onClick={async () => onClickInvite()}
        >
          Invite
        </button>
      </div>
    </div>
  );
};
