import React from "react";
import classNames from "classnames";
import { Switch } from "@headlessui/react";

const ResultsCard = (props) => {
  return (
    <ul
      className={classNames(
        "flex justify-center",
        props.className && props.className
      )}
    >
      <li className="bg-white w-10/12 elevation-1 rounded-md border border-gray-300 divide-y divide-gray-200">
        <div className="w-full flex items-center justify-between p-6 space-x-6">
          <div className=" flex-1 truncate">
            <div className="flex items-center space-x-3">
              <h3 className="text-gray-900 text-xl font-semibold truncate">
                {props.data.heading}
              </h3>
            </div>
            {props.switch ? (
              <Switch.Group as="div" className="flex items-center mt-2">
                <Switch.Label as="span" className="mr-3">
                  <span className="text-sm font-medium text-gray-900">
                    Monthly billing{" "}
                  </span>
                </Switch.Label>
                <Switch
                  checked={props.switchEnabled}
                  onChange={(val) => {
                    props.setSwitch(val);
                  }}
                  className={classNames(
                    props.switchEnabled ? "bg-blue-400" : "bg-gray-200",
                    "relative inline-flex flex-shrink-0 h-6 w-11 border-2 border-transparent rounded-full cursor-pointer transition-colors ease-in-out duration-200 focus:outline-none"
                  )}
                >
                  <span
                    aria-hidden="true"
                    className={classNames(
                      props.switchEnabled ? "translate-x-5" : "translate-x-0",
                      "pointer-events-none inline-block h-5 w-5 rounded-full bg-white shadow transform ring-0 transition ease-in-out duration-200"
                    )}
                  />
                </Switch>
                <Switch.Label as="span" className="ml-3">
                  <span className="text-sm font-medium text-gray-900">
                    One time payment{" "}
                  </span>
                  <span className="text-sm text-gray-500">(Save 5%)</span>
                </Switch.Label>
              </Switch.Group>
            ) : null}
          </div>
          <div className="flex flex-row items-center space-x-4">
            <img
              className="w-28 h-28 flex-shrink-0"
              src={props.data.imageUrl}
              alt=""
            />
            <div
              className={classNames(
                "flex text-xl font-semibold truncate items-center h-16",
                props.totalClassName && props.totalClassName
              )}
            >
              ${props.data.total}
            </div>
          </div>
        </div>
        <div>
          <div className="-mt-px flex divide-x divide-gray-200">
            <div className="w-0 flex-1 flex">
              <p className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center p-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg">
                {props.data.firstMessage}
              </p>
            </div>
            {props.data.secondMessage && (
              <div className="-ml-px w-0 flex-1 flex">
                <p className="relative -mr-px w-0 flex-1 inline-flex items-center justify-center p-4 text-xs text-gray-700 font-medium border border-transparent rounded-bl-lg">
                  {props.data.secondMessage}
                </p>
              </div>
            )}
          </div>
        </div>
      </li>
    </ul>
  );
};

export default ResultsCard;
