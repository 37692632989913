export const EngagementLoading = () => {
  return (
    <div className="h-auto">
      <div className="flex flex-col justify-start items-center mt-4 ml-4 h-full w-full">
        <div className="flex flex-row h-full items-center justify-between w-full">
          <button className="flex items-center justify-center h-10 w-10 pl-1 hover:bg-gray-100 transition-colors duration-200 ease-in-out"></button>

          <div className="flex flex-row items-center">
            <button className="mr-4 inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue">
              <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
            </button>
            <button className="mr-4 inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue">
              <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
            </button>

            <button className="mr-4 inline-flex justify-end items-end py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-sprxClientPortalBlue">
              <div className="animate-pulse mt-2 mb-2 h-2 bg-white rounded w-8"></div>
            </button>
          </div>
        </div>
        <div className="flex flex-col justify-between h-full w-full">
          <div className=" flex flex-col w-full h-70vh justify-evenly items-center">
            <div className="mt-4 flex items-center justify-center border shadow-lg w-full h-70vh">
              <div className="animate-pulse ml-2 h-2 bg-sprxGrey rounded w-1/5"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
