import { useState, useEffect } from "react";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import { useSurvey } from "../../../../rtk/R&D/Survey/useSurvey";
import { getReviewColumns } from "../utils";
import { useComments } from "../../../../rtk/R&D/Comments/useComments";
import { DataTable } from "../../../../ui-components";
import { isDev } from "../../utils";

export const SurveyCard = (props) => {
  const { isDownloadingFile, wageData, updateData } = useDataArray();

  const {
    encryptCredentials,
    generateSurveyLink,
    isEncryptingCredentials,
    isGeneratingLink,
  } = useSurvey();

  // const { onDownloadDocument, isGettingBulkEditFile } = useFile();

  const { engagementData } = useEngagementData();

  const [columnDef] = useState(getReviewColumns("Survey"));

  const commentsAPI = useComments();

  useEffect(() => {
    if (commentsAPI) commentsAPI.setCommentTitle("SME Survey");
  }, [commentsAPI]);

  const onSendSurveys = async () => {
    const { engagementID } = engagementData;

    const sendSurveyPromises = wageData.map(async (employee) => {
      // Check Email Validity
      const emailIsValid = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(employee?.email);
      if (!emailIsValid) return { ...employee, email: "" };

      // Check if Employee is Selected
      if (employee?.send !== true) return { ...employee };

      const status = employee?.surveyStatus;
      const hasEditedEmail = employee?.email !== employee?.originalEmail;
      if ((status === "sent" || status === "complete") && !hasEditedEmail)
        return { ...employee };

      const encryptedCreds = await encryptCredentials({
        departments: ["Engineering", "Production"],
        employeeID: employee.ID,
        engagementID: engagementID,
      });
      if (isDev) console.log({ encryptedCreds });

      const surveyResponse = await generateSurveyLink({
        encryptedData: encryptedCreds.data,
        name: employee["Employee Name"],
        toEmail: employee.email,
        baseURL: window.location.origin.replace(/(^\w+:|^)\/\//, ""),
      });
      if (isDev) console.log({ surveyResponse });

      return { ...employee, surveyStatus: "sent" };
    });

    const data = await Promise.all(sendSurveyPromises);
    await updateData(data, "Wages");
  };

  const onRowUpdate = async (newData) => {
    let tmpData = wageData.map((a) => ({ ...a }));
    const editIndex = tmpData.findIndex((o) => o.ID === newData.ID);
    const oldEmail = tmpData[editIndex]?.email ?? "";
    const hasEditedEmail = newData.email !== oldEmail;
    tmpData[tmpData.findIndex((o) => o.ID === newData.ID)] = {
      ...newData,
      surveyStatus: hasEditedEmail ? "" : newData.surveyStatus,
    };
    await updateData(tmpData, "Wages");
  };

  return (
    <div className="px-8 pb-8 z-40">
      <div className="flex flex-col w-full items-center justify-center">
        <div className="flex flex-row justify-end w-full">
          {/*<button
            className="mb-4 ml-2 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
            onClick={() => onDownloadDocument(getDownloadFileName("Survey"))}
          >
            {isGettingBulkEditFile ? (
              <div className="w-full">
                <p className="animate-pulse bg-white rounded h-2 w-16"></p>
              </div>
            ) : (
              "Download"
            )}
            </button>*/}
          <button
            className="ml-2 mb-4 px-4 py-2 rounded-md border border-transparent bg-sprxClientPortalLightBlue text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm"
            onClick={async () =>
              isDownloadingFile ? null : await onSendSurveys()
            }
          >
            {isDownloadingFile ||
            isEncryptingCredentials ||
            isGeneratingLink ? (
              <div className="w-full">
                <p className="animate-pulse bg-white rounded h-2 w-16"></p>
              </div>
            ) : (
              "Send Surveys"
            )}
          </button>
        </div>
        <div className="w-full h-1 min-h-70vh">
          <DataTable
            data={wageData}
            columns={columnDef}
            isLoading={false}
            onRowUpdate={async (newData) => onRowUpdate(newData)}
          />
        </div>
      </div>
    </div>
  );
};
