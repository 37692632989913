import { useEnergyEngagement } from "../../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useEnergyComments } from "../../../../rtk/Energy/Comments/useEnergyComments";
import { Reports } from "./Reports";
import { useState } from "react";
import { ReportsLoading } from "./components/ReportsLoading";

export const ReportsContainer = ({ setViewEngDetail }) => {
  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();
  const [isFinalizing, setIsFinalizing] = useState(false);
  const commentsAPI = useEnergyComments();

  const showLoading =
    !energyEngagementAPI ||
    !usersAPI ||
    energyEngagementAPI.engagementLoading ||
    usersAPI.isLoadingUser;

  if (showLoading) return <ReportsLoading />;

  const engagement = energyEngagementAPI.engagement;

  const onFinalizeDeliverables = async () => {
    setIsFinalizing(true);
    const body = {
      reports: { status: "COMPLETE", isDraft: !engagement.reports.isDraft },
    };
    const engagementID = engagement.engagementID;
    await energyEngagementAPI.updateEngagement({ engagementID, body });

    await energyEngagementAPI.generateEnergyDeliverables({
      engagementID,
      body,
    });
    setIsFinalizing(false);
  };

  return (
    <Reports
      setViewEngDetail={setViewEngDetail}
      engagement={engagement}
      user={usersAPI.user}
      onFinalizeDeliverables={onFinalizeDeliverables}
      isCreatingComment={commentsAPI.isCreatingComment}
      onCreateComment={commentsAPI.onCreateComment}
      isFinalizing={isFinalizing}
      isGeneratingDeliverables={
        energyEngagementAPI.generateEnergyDeliverablesData.isLoading
      }
    />
  );
};
