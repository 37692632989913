import * as animationData from "./loadingAnimation.json";
import Lottie from "lottie-react";

const SprxLoading = (props) => {
  const renderText = props?.renderText ? props.renderText : false;
  const height = props?.height ? props.height : "10%";
  const width = props?.width ? props.width : "10%";
  return (
    <div
      className={
        " min-h-full flex flex-col justify-center items-center content-center" +
        props?.rootClassname
      }
    >
      <Lottie
        animationData={animationData}
        loop={true}
        style={{ height: height, width: width, alignSelf: "center" }}
      />
      {renderText ? (
        <p className="ml-16 mt-6 text-center text-3xl font-extralight text-sprxBlue">
          Loading...
        </p>
      ) : null}
    </div>
  );
};

export const LoadingLottie = (props) => {
  return (
    <Lottie
      animationData={animationData}
      loop={true}
      style={{ height: props.height, width: props.width, alignSelf: "center" }}
    />
  );
};

export default SprxLoading;
