import React from "react";
import { Fragment } from "react";
import { Dialog, Transition } from "@headlessui/react";
import _ from "lodash";
import classNames from "classnames";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useEffect } from "react";
import { Button } from ".";

const schema = yup.object().shape({
  engagementName: yup.string().required("Please enter an engagement name."),
  clientName: yup.string().required("Please enter a client name."),
  clientEIN: yup.string().required("Please enter a client EIN."),
  taxYearStart: yup.string().required("Please enter a tax year start date."),
  taxYearEnd: yup.string().required("Please enter a tax year end date."),
  isRollover: yup.boolean(),
  engagement: yup.string(),
  entityName: yup.boolean(),
  entityEIN: yup.boolean(),
  entityType: yup.boolean(),
  pyQres: yup.boolean(),
  pyGrs: yup.boolean(),
  elections: yup.boolean(),
  wageQre: yup.boolean(),
  supplyQre: yup.boolean(),
  contractorQre: yup.boolean(),
  computerQre: yup.boolean(),
  projectQre: yup.boolean(),
});

const defaultValues = {
  engagementName: "",
  clientName: "",
  clientEIN: "",
  taxYearStart: "",
  taxYearEnd: "",
  isRollover: false,
  engagement: "",
  entityName: true,
  entityEIN: true,
  entityType: true,
  method: true,
  pyQres: true,
  pyGrs: true,
  elections: true,
  wageQre: true,
  supplyQre: true,
  computerQre: true,
  contractorQre: true,
  projectQre: true,
};

const CreateFirmEngagementModal = (props) => {
  const { engagements } = props;

  const { control, formState, handleSubmit, watch } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields } = formState;

  const onClickSend = async (model) => {
    props.onSuccess(model);
  };

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const isRollover = watch("isRollover");

  return (
    <Transition.Root show={props.open} as={Fragment}>
      <Dialog
        as="div"
        className="fixed z-50 inset-0 overflow-y-auto"
        onClose={props.setOpen}
      >
        <div className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0">
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div className="inline-block align-bottom bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-4xl sm:w-full sm:p-6">
              <div>
                <div className="text-center">
                  <Dialog.Title
                    as="h3"
                    className="text-lg leading-6 font-medium text-gray-900"
                  >
                    Create a New Engagement
                  </Dialog.Title>
                  <div className="mt-2">
                    <p className="text-sm text-gray-500">
                      Enter the details below to create a new SRPX R&D
                      Engagement.
                    </p>
                    <form
                      className="mt-5 sm:flex sm:items-center sm:flex-col"
                      onSubmit={handleSubmit((model) => onClickSend(model))}
                    >
                      <Controller
                        name="engagementName"
                        control={control}
                        render={({ field }) => (
                          <div className="w-full sm:max-w-3xl">
                            <label htmlFor="engagementName" className="sr-only">
                              Engagement Name
                            </label>
                            <input
                              {...field}
                              type="text"
                              name="Engagement Name"
                              id="engagementName"
                              required
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Engagement Name"
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="clientName"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-3xl">
                            <label htmlFor="clientName" className="sr-only">
                              Client Name
                            </label>
                            <input
                              {...field}
                              type="text"
                              name="clientName"
                              id="clientName"
                              required
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Client Name"
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="clientEIN"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-3xl">
                            <label htmlFor="clientEIN" className="sr-only">
                              Client EIN
                            </label>
                            <input
                              {...field}
                              type="text"
                              name="clientEIN"
                              id="clientEIN"
                              required
                              className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                              placeholder="Client EIN"
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="taxYearStart"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-3xl">
                            <label htmlFor="taxYearStart" className="sr-only">
                              Tax Year Start Date
                            </label>
                            <input
                              id={"taxYearStart"}
                              {...field}
                              className={classNames(
                                "form-input block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
                              )}
                              type={"text"}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => (e.target.type = "text")}
                              placeholder={"Tax Year Start Date"}
                            />
                          </div>
                        )}
                      />

                      <Controller
                        name="taxYearEnd"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-4 w-full sm:max-w-3xl">
                            <label htmlFor="taxYearEnd" className="sr-only">
                              Tax Year End Date
                            </label>
                            <input
                              id={"taxYearEnd"}
                              {...field}
                              className={classNames(
                                "form-input block w-full sm:text-sm border-gray-300 rounded-md focus:ring-transparent focus:border-gray-300 focus:outline-none"
                              )}
                              type={"text"}
                              onFocus={(e) => (e.target.type = "date")}
                              onBlur={(e) => (e.target.type = "text")}
                              placeholder={"Tax Year End Date"}
                            />
                          </div>
                        )}
                      />

                      <div className="space-y-8 divide-y divide-gray-200">
                        <Controller
                          name="isRollover"
                          control={control}
                          render={({ field }) => (
                            <div className="sm:col-span-6">
                              <div className="relative flex items-start mt-6">
                                <div className="flex items-center h-5">
                                  <input
                                    {...field}
                                    value={field.value}
                                    checked={field.value}
                                    id="isRollover"
                                    name="isRollover"
                                    type="checkbox"
                                    className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                  />
                                </div>
                                <div className="ml-3 text-sm">
                                  <label
                                    htmlFor="isRollover"
                                    className="font-medium text-gray-700"
                                  >
                                    Check this box if you would like to rollover
                                    an engagement
                                  </label>
                                </div>
                              </div>
                            </div>
                          )}
                        />
                        {isRollover === true ? (
                          <div className="my-4">
                            <div>
                              <Controller
                                name="engagement"
                                control={control}
                                render={({ field }) => (
                                  <div className="sm:col-span-3">
                                    <label
                                      htmlFor="entityType"
                                      className="block text-sm font-medium text-gray-700"
                                    >
                                      Engagement to Rollover
                                    </label>
                                    <div className="mt-1">
                                      <select
                                        {...field}
                                        id="engagement"
                                        name="engagement"
                                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base focus:border-gray-400 border-gray-300 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm rounded-md"
                                      >
                                        <option value={""}></option>
                                        {engagements.map((engagement) => {
                                          return (
                                            <option
                                              value={engagement.engagementID}
                                            >
                                              {engagement.engagementName}
                                            </option>
                                          );
                                        })}
                                      </select>
                                    </div>
                                  </div>
                                )}
                              />
                            </div>
                            <div className="">
                              <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                                <Controller
                                  name="entityName"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="entityName"
                                            name="entityName"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="entityName"
                                            className="font-medium text-gray-700"
                                          >
                                            Entity Name
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="entityEIN"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="entityEIN"
                                            name="entityEIN"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="entityEIN"
                                            className="font-medium text-gray-700"
                                          >
                                            Entity EIN
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="entityType"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="entityType"
                                            name="entityType"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="entityEIN"
                                            className="font-medium text-gray-700"
                                          >
                                            Corporate Structure
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="method"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="method"
                                            name="method"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="method"
                                            className="font-medium text-gray-700"
                                          >
                                            Computation Method
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="pyQres"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="pyQres"
                                            name="pyQres"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="pyQres"
                                            className="font-medium text-gray-700"
                                          >
                                            Prior Year Qualified Research
                                            Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="pyGrs"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="pyGrs"
                                            name="pyGrs"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="method"
                                            className="font-medium text-gray-700"
                                          >
                                            Prior Year Gross Receipts
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="elections"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="elections"
                                            name="elections"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="elections"
                                            className="font-medium text-gray-700"
                                          >
                                            Elections (Controlled Group, 280C,
                                            Acquisition, etc.)
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="wageQre"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="wageQre"
                                            name="wageQre"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="wageQre"
                                            className="font-medium text-gray-700"
                                          >
                                            Wage Qualified Research Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="supplyQre"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="supplyQre"
                                            name="supplyQre"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="wageQre"
                                            className="font-medium text-gray-700"
                                          >
                                            Supply Qualified Research Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="contractorQre"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="contractorQre"
                                            name="contractorQre"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="wageQre"
                                            className="font-medium text-gray-700"
                                          >
                                            Contractor Qualified Research
                                            Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="computerQre"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="computerQre"
                                            name="computerQre"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="computerQre"
                                            className="font-medium text-gray-700"
                                          >
                                            Computer Lease Qualified Research
                                            Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <Controller
                                  name="projectQre"
                                  control={control}
                                  render={({ field }) => (
                                    <div className="sm:col-span-6">
                                      <div className="relative flex items-start mt-6">
                                        <div className="flex items-center h-5">
                                          <input
                                            {...field}
                                            value={field.value}
                                            checked={field.value}
                                            id="projectQre"
                                            name="projectQre"
                                            type="checkbox"
                                            className="focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent h-4 w-4 text-sprxTaxBlue border-gray-300 rounded"
                                          />
                                        </div>
                                        <div className="ml-3 text-sm">
                                          <label
                                            htmlFor="projectQre"
                                            className="font-medium text-gray-700"
                                          >
                                            Project Qualified Research Expenses
                                          </label>
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                />
                                <div hidden={true}>
                                  <input
                                    id="submit"
                                    type="submit"
                                    className={classNames(
                                      "w-full md:mt-4 flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-sprxBlue hover:bg-blue-500 focus:outline-none",
                                      !isValid &&
                                        "bg-sprxGrey opacity-50 cursor-not-allowed"
                                    )}
                                  />
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      <Button
                        secondary={true}
                        buttonClassName={classNames(
                          "mt-5 sm:mt-6 inline-flex justify-center w-full rounded-md border border-transparent shadow-sm px-4 py-2 bg-sprxTaxBlue text-base font-medium text-white hover:bg-sprxTaxDarkBlue focus:outline-none sm:text-sm",
                          _.isEmpty(dirtyFields) &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed",
                          !isValid &&
                            "bg-sprxTaxBlue opacity-50 cursor-not-allowed"
                        )}
                        disabled={_.isEmpty(dirtyFields) || !isValid}
                      >
                        Create Engagement
                      </Button>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default CreateFirmEngagementModal;
