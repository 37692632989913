import { motion } from "framer-motion";
import * as React from "react";
import { classNames } from "../../utils";

const LoadingPulse = () => {
  return (
    <div className="flex h-full w-full animate-pulse items-center justify-center rounded-xl py-1">
      <div className="h-2 w-1/3 rounded-xl bg-white"></div>
    </div>
  );
};

export type ClosedActionCardProps = {
  item: any;
  setOpen: any;
  setSelectedID: any;
  setCloseVisible: any;
  isLoading?: boolean;
  openText?: string;
  completeText?: string;
  onClickOpen?: () => void;
};

export const ClosedActionCard = (props: ClosedActionCardProps) => {
  const {
    item,
    setOpen,
    setSelectedID,
    setCloseVisible,
    isLoading,
    completeText,
  } = props;

  const buttonCopy = item.complete
    ? completeText || "Make Changes"
    : props?.openText || "Start";

  return (
    <motion.div
      key={item.title}
      whileHover={{ scale: 1.01 }}
      layoutId={item.id}
      className={
        "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
      }
    >
      <motion.div
        className={classNames(
          item.complete && "bg-sprxClientPortalLightGreen",
          "flex flex-1 flex-col items-center justify-between bg-white p-4 pt-6",
        )}
      >
        <motion.h3 className="text-center font-semibold text-gray-900 xl:mb-6 xl:text-xl">
          {item.title}
        </motion.h3>
        <motion.p className="text-text-gray-900 mt-1 text-center text-xs font-light tracking-tight xl:text-sm">
          {item.description}
        </motion.p>
        <motion.button
          className={classNames(
            item.complete
              ? "bg-sprxGrey hover:bg-sprxTaxGray"
              : "bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
            "focus:ring-none mt-4 inline-flex w-3/4 items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none",
          )}
          data-test={`Open ${item.title}`}
          whileHover={{ scale: 1.1 }}
          whileTap={{ scale: 0.95 }}
          onClick={() => {
            if (props?.onClickOpen) {
              props.onClickOpen();
              return;
            }
            if (!isLoading) {
              setOpen(true);
              setSelectedID(item);
              if (item.title !== "Taxpayer Profile") {
                setCloseVisible(true);
              }
            }
          }}
        >
          {isLoading ? <LoadingPulse /> : buttonCopy}
        </motion.button>
      </motion.div>
    </motion.div>
  );
};
