import { Navigate, Outlet, useLocation } from "react-router-dom";
import { useAuth } from "./useAuth";
import EngagementDataProvider from "../rtk/R&D/EngagementData/EngagementDataProvider";
import CompanyDataProvider from "../rtk/CompanyData/CompanyDataProvider";
import FileProvider from "../rtk/R&D/File/FileProvider";
import ComputeProvider from "../rtk/R&D/Compute/ComputeProvider";
import UsersProvider from "../rtk/Users/UsersProvider";
import SurveyProvider from "../rtk/R&D/Survey/SurveyProvider";
import CommentsProvider from "../rtk/R&D/Comments/CommentsProvider";

// import CapitalizationProvider from "../rtk/Capitalization/CapitalizationProvider";

export const ProtectedRoute = () => {
  const { token } = useAuth();
  const location = useLocation();

  // Provide API to Authenticated Users
  return token ? (
    <UsersProvider>
      <EngagementDataProvider>
        <CompanyDataProvider>
          <ComputeProvider>
            <FileProvider>
              <SurveyProvider>
                <CommentsProvider>
                  <Outlet />
                </CommentsProvider>
              </SurveyProvider>
            </FileProvider>
          </ComputeProvider>
        </CompanyDataProvider>
      </EngagementDataProvider>
    </UsersProvider>
  ) : (
    <Navigate to="/login" replace state={{ from: location }} />
  );
};
