import { Disclosure } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/outline";

export const RequiredDocumentItem = ({ item, index }) => {
  const title = item?.title ?? "";
  const description = item?.description ?? "";

  // return null;

  return (
    <li
      className={`relative flex flex-col justify-between items-center my-1 bg-sprxClientPortalLightTeal rounded-lg`}
      key={index + "-required-document"}
    >
      <Disclosure>
        {({ open }) => {
          return (
            <>
              <Disclosure.Button
                className={
                  "flex w-full justify-between rounded-lg bg-sprxClientPortalLightTeal px-2 py-2 text-left text-sm font-medium text-black focus:outline-none focus-visible:ring focus-visible:ring-purple-500/75" +
                  (!open ? " hover:bg-sprxClientPortalDarkTeal" : "")
                }
              >
                <p className="text-sm font-lg text-black">{title}</p>
                <InformationCircleIcon
                  className="h-5 w-5 text-gray-500 ml-4 flex-shrink-0"
                  aria-hidden="true"
                />
              </Disclosure.Button>
              <Disclosure.Panel className="px-2 pb-2 text-sm text-black">
                {description}
              </Disclosure.Panel>
            </>
          );
        }}
      </Disclosure>
    </li>
  );
};
