import * as React from "react";

import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useFile } from "../../../rtk/R&D/File/useFile";
import { useUsers } from "../../../rtk/Users/useUsers";

import { ChatContainer } from "./Chat/ChatContainer";
import { VisualizationsContainer } from "./Visualizations/VisualizationsContainer";
import { DocumentsContainer } from "./Documents/DocumentsContainer";
import { ExecutiveReportContainer } from "./ExecutiveReport/ExecutiveReportContainer";
import { SurveyCard } from "../ReviewPortal/Survey";

import { ReviewGaugeColumn } from "../ReviewPortal/components/ReviewGaugeColumn";
import { ClosedActionCard } from "../UploadPortal/components/ClosedActionCard";
import { OpenActionCard } from "../../../ui-components/OpenActionCard";
import { WelcomeMessage } from "./components/WelcomeMessage";
import { DeliverablesPortalLoading } from "./components/DeliverablesPortalLoading";
import { InviteUser } from "./components/InviteUser";
import { AddUserForm } from "../../../ui-components/AddUserForm";

import ChatProvider from "../../../rtk/Chat/ChatProvider";
import { AddCommentForm } from "./components/AddComment";
import { CommentsContainer } from "./Comments/CommentsContainer";
import { FinalizeDeliverables } from "./components/FinalizeDeliverables";
import { HeaderContainer } from "./components/HeaderContainer";
import { ArrowLeftIcon } from "@heroicons/react/outline";
import { useEffect } from "react";
import { isDev } from "../utils";

export const DeliverablesPortal = ({ setActivePortalType }) => {
  const [selectedID, setSelectedID] = React.useState<any>(null);
  const [isFinalizing, setIsFinalizing] = React.useState<boolean>(false);
  const [closeVisible, setCloseVisible] = React.useState<boolean>(true);
  const [open, setOpen] = React.useState<boolean>(false);
  const [inviteOpen, setInviteOpen] = React.useState<boolean>(false);
  const [commentOpen, setCommentOpen] = React.useState<boolean>(false);
  const fileAPI = useFile();
  const engagementDataAPI = useEngagementData();
  const userAPI = useUsers();

  const isLoading =
    !fileAPI ||
    !engagementDataAPI ||
    !userAPI ||
    engagementDataAPI.isLoadingEngagementData ||
    userAPI.isListingUsers ||
    isFinalizing ||
    userAPI.isUpdatingUser;

  const isFinalized = engagementDataAPI?.engagementData?.status.hasOwnProperty(
    "is-finalized",
  )
    ? engagementDataAPI?.engagementData?.status["is-finalized"]
    : false;

  // Mark as Viewed By Client
  useEffect(() => {
    if (engagementDataAPI && userAPI) {
      engagementDataAPI.markAsTracked("COMPLETE", "reports-viewed");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onFinalize = async () => {
    setIsFinalizing(true);
    const body = {
      engagementID: engagementDataAPI.engagementData.engagementID,
      isDraft: isFinalized,
    };
    await fileAPI.generateDeliverables(body);

    const updateEngagementDataBody = {
      status: {
        ...engagementDataAPI.engagementData.status,
        "is-finalized": !isFinalized,
      },
      tracker: {
        ...engagementDataAPI.engagementData.tracker,
        "reports-complete": {
          status: isFinalized ? "INCOMPLETE" : "COMPLETE",
          uploadedBy: isFinalized ? "" : userAPI.user.fullName,
          lastUpdated: isFinalized ? "" : Date.now(),
        },
      },
    };
    await engagementDataAPI.updateEngagementData({
      engagementID: engagementDataAPI.engagementData.engagementID,
      body: updateEngagementDataBody,
    });
    await engagementDataAPI.onListEngagementsFromCompanyID();
    setIsFinalizing(false);
  };

  const cols = isFinalized ? "grid-cols-2" : "grid-cols-3";

  if (isLoading) return <DeliverablesPortalLoading />;

  const companyIDsDontMatch =
    userAPI.user.companyID !== engagementDataAPI.engagementData.companyID &&
    userAPI.user.role !== "admin";

  if (companyIDsDontMatch) {
    return (
      <div className="flex flex-1 items-center justify-center">
        <div className="text-center">
          <h1 className="text-2xl font-semibold">
            You are not authorized to view this page.
          </h1>
          <p className="text-gray-500">
            Please contact your administrator for access.
          </p>
        </div>
      </div>
    );
  }

  const COLS = [
    {
      id: 1,
      title: "Executive Report",
      key: "executive",
      description: "Take a look at your executive report.",
      extendedDescription: "",
      icon: null,
      component: <ExecutiveReportContainer />,
      hideCloseIcon: true,
      openText: "Open",
      statusTracker: true,
    },
    {
      id: 2,
      title: "Schedules",
      key: "schedules",
      description: "Your schedules are ready for review & download.",
      extendedDescription: "",
      icon: null,
      component: <DocumentsContainer />,
      hideCloseIcon: true,
      statusTracker: true,
    },
    {
      id: 3,
      title: "Data Visualizations",
      description: "Let's take a look at your qualified expense data.",
      extendedDescription: "",
      icon: null,
      component: <VisualizationsContainer />,
      hideCloseIcon: true,
    },
    {
      id: 4,
      title: "Chat",
      description: "Chat with your personal AI chat assistant.",
      extendedDescription: "",
      icon: null,
      component: (
        <ChatProvider>
          <ChatContainer
            knowledgeBaseID={
              engagementDataAPI?.engagementData?.integrations?.superpowered
                ?.knowledgeBaseId
            }
            engagementID={engagementDataAPI.engagementData.engagementID}
          />
        </ChatProvider>
      ),
      hideCloseIcon: true,
    },
  ];

  if (!isFinalized) {
    COLS.push({
      id: 5,
      title: "Comments",
      description: "Let's see what our experts have to say.",
      extendedDescription: "",
      icon: null,
      component: <CommentsContainer onClickAddCallback={() => {}} />,
      hideCloseIcon: true,
    });
    COLS.push({
      id: 6,
      title: "SME Survey",
      description: "If you are not sure, ask someone.",
      extendedDescription: "",
      component: <SurveyCard />,
      hideCloseIcon: false,
      icon: undefined,
    });
  }

  return (
    <main className="flex-1">
      <div className={`flex-1 ${userAPI.user.role === "client" ? "pt-6" : ""}`}>
        {/* Top bar and logo */}
        <div className="flex flex-row items-center justify-between px-4">
          <button
            onClick={() => {
              setActivePortalType();
            }}
            className="flex h-10 w-10 items-center justify-center pl-1 transition-colors duration-200 ease-in-out hover:bg-gray-100"
          >
            <ArrowLeftIcon className="h-8 w-8" />
          </button>
          <HeaderContainer />
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        {/* Main Content */}
        <div className="flex px-4">
          <div className="w-full">
            <div className="flex w-full flex-col justify-evenly lg:flex-row">
              <WelcomeMessage />
              <div className="mr-4 flex w-full flex-row justify-between py-2 lg:w-1/2">
                {userAPI.user.role === "admin" ? (
                  <FinalizeDeliverables
                    onClickFinalize={onFinalize}
                    isFinalized={isFinalized}
                  />
                ) : (
                  <div className="w-1/2"></div>
                )}
                <InviteUser onClickInvite={() => setInviteOpen(true)} />
              </div>
            </div>
            <div className="w-full pb-8 lg:grid lg:grid-cols-12 lg:gap-4">
              <div className="lg:col-span-3">
                <ReviewGaugeColumn height={"70vh"} />
              </div>
              <div className="col-span-9 flex w-full">
                <div
                  className={"grid w-full grid-cols-2 gap-4" + ` lg:${cols}`}
                >
                  {COLS.map((item) => (
                    <ClosedActionCard
                      key={`${item.title}-deliverables-action-card`}
                      item={item}
                      setOpen={setOpen}
                      openText={item?.openText ?? "Open"}
                      setSelectedID={setSelectedID}
                      setCloseVisible={setCloseVisible}
                      isLoading={engagementDataAPI.isLoadingEngagementData}
                    />
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <OpenActionCard
        engagementData={engagementDataAPI.engagementData}
        selectedID={selectedID}
        isUpdatingEngagementData={engagementDataAPI.isUpdatingEngagementData}
        trackerType="reports"
        open={open}
        setOpen={setOpen}
        setSelectedID={setSelectedID}
        closeVisible={closeVisible}
        setCloseVisible={setCloseVisible}
        setCommentOpen={isFinalized ? undefined : setCommentOpen}
        disableOnClickOutsideClose={commentOpen}
        secondaryPanel={
          <AddCommentForm open={commentOpen} setOpen={setCommentOpen} />
        }
        small={false}
        markAsTracked={engagementDataAPI.markAsTracked}
      />
      <AddUserForm
        open={inviteOpen}
        setOpen={setInviteOpen}
        engagementData={engagementDataAPI.engagementData}
        engagementType="RD"
      />
    </main>
  );
};
