export const EditEngagementLoadng = () => {
  return (
    <div className="">
      <div className="px-4 py-5 sm:px-6">
        <h3 className="text-lg leading-6 font-medium text-gray-900">
          Engagement Information
        </h3>
        <p className="mt-1 max-w-2xl text-sm text-gray-500">
          Engagement detail
        </p>
      </div>
      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
        <dl className="sm:divide-y sm:divide-gray-200">
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Engagement Name
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Engagement ID</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">Company Name</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Federal Credit
            </dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
              <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
            </dd>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">State Credit</dt>
            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-1">
              <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
            </dd>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">S3 Link</dt>
            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
          </div>

          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Engagement Data Table Entry
            </dt>
            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Executive Report
            </dt>
            <div className="animate-pulse bg-sprxGrey rounded h-2 w-"></div>
          </div>
          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
            <dt className="text-sm font-medium text-gray-500">
              Current Status
            </dt>
            <div className="grid grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-5 lg:grid-rows-4 col-span-3 w-full">
              {Array.from(Array(19))?.map((item, index) => (
                <div
                  key={index}
                  className="animate-pulse bg-sprxGrey rounded h-2 w-"
                ></div>
              ))}
            </div>
          </div>
        </dl>
      </div>
    </div>
  );
};
