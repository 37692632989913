import DataArrayProvider from "../../../rtk/R&D/DataArray/DataArrayProvider";
import { DeliverablesPortal } from "./DeliverablesPortal";

export const DeliverablesPortalContainer = ({ setActivePortalType }) => {
  return (
    <DataArrayProvider>
      <DeliverablesPortal setActivePortalType={setActivePortalType} />
    </DataArrayProvider>
  );
};
