import { useState, useMemo } from "react";
import { getStatusUpdateSlug, isDev } from "../../utils";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";
import * as React from "react";
import { ImportType } from "../../../../ui-components/FlatfileSpace";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { IntelligentImportChild } from "./IntelligentImportChild";

type IntelligentImportProps = {
  engagementData: any;
  user: any;
  type: ImportType;
};

export const IntelligentImportContainer: React.FC<IntelligentImportProps> = (
  props: IntelligentImportProps
) => {
  const { engagementData, user, type } = props;
  const { engagementID } = engagementData;

  const dataAPI = useDataArray();
  const engagementDataAPI = useEngagementData();

  const [showSpace, setShowSpace] = useState<boolean>(false);
  const [manualRefresh, setManualRefresh] = useState<boolean>(false);

  const data = useMemo(() => {
    if (dataAPI === null) return [];
    switch (type) {
      case "WAGES":
        return dataAPI.wageData;
      case "SUPPLY":
        return dataAPI.supplyData;
      case "COMPUTER":
        return dataAPI.computerLeaseData;
      case "CONTRACT":
        return dataAPI.contractData;
      case "PROJECTS":
        return dataAPI.projectData;
      default:
        return [];
    }
  }, [type, dataAPI]);

  const hasAccountingData = () => {
    if (dataAPI === null) return false;
    return (
      dataAPI.supplyData.length > 0 ||
      dataAPI.contractData.length > 0 ||
      dataAPI.computerLeaseData.length > 0
    );
  };

  if (dataAPI === null || engagementDataAPI === null) return null;

  // Delete Line Items Based off File Name
  const deleteCallback = async (fileName: string) => {
    const filteredData = data.filter((a: any) => {
      return a.filename !== fileName;
    });
    await dataAPI.updateData(filteredData, type);
    // Update Engagement Data iff Listing is Empty
    if (filteredData.length === 0) await updateStatus();
  };

  // Update Engagement Data Status
  const updateStatus = async () => {
    let status = {};
    Object.assign(status, engagementData.status);
    status[`portal-${getStatusUpdateSlug(type)}`] = {
      complete: false,
    };

    // Update Accounting Status iff Accounting Sub Type && No Data
    if (isAccounting(type)) {
      status["portal-accounting"] = {
        complete: hasAccountingData(),
      };
    }

    const updateEngagementDataResponse =
      await engagementDataAPI.updateEngagementData({
        engagementID: engagementData.engagementID,
        body: {
          ...engagementData,
          status,
        },
      });
    if (isDev) console.log({ updateEngagementDataResponse });
  };

  const onCloseCallback = async () => {
    setManualRefresh(true);
    setShowSpace(false);
    await dataAPI.refreshData(type);
    setManualRefresh(false);
  };

  const loading =
    dataAPI.isCreatingJSONDataFile ||
    dataAPI.isDownloadingFile ||
    engagementDataAPI.isUpdatingEngagementData;

  return (
    <IntelligentImportChild
      engagementData={engagementData}
      user={user}
      type={type}
      loading={loading}
      deleteCallback={deleteCallback}
      engagementID={engagementID}
      onCloseCallback={onCloseCallback}
      setShowSpace={setShowSpace}
      showSpace={showSpace}
      manualRefresh={manualRefresh}
    />
  );
};

function isAccounting(type: string) {
  return type === "SUPPLY" || type === "COMPUTER" || type === "CONTRACT";
}
