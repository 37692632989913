import { useEffect, useState, useMemo, useCallback, useRef } from "react";
import { useSelector } from "react-redux";
import {
  useCreateEnergyCommentMutation,
  useLazyGetEnergyCommentQuery,
  useUpdateEnergyCommentMutation,
  useDeleteEnergyCommentMutation,
} from "../../../api/energy/energyComments";
import { useEnergyEngagement } from "../Engagement/useEnergyEngagement";
import EnergyCommentsContext, {
  onCreateCommentParams,
} from "./EnergyCommentsContext";
import { isDev } from "../../../main/R&D/utils";

const EnergyCommentsProvider = ({ children }) => {
  // const hasLoadedComments = useRef(false);
  const [commentTitle, setCommentTitle] = useState("");
  const [isLoadingComments, setIsLoadingComments] = useState(false);
  const [comments, setComments] = useState([]);
  const [isResolvingComment, setIsResolvingComment] = useState(false);
  const [isAddingNote, setIsAddingNote] = useState(false);
  const [isCreatingComment, setIsCreatingComment] = useState(false);
  const [isDeletingComment, setIsDeletingComment] = useState(false);
  const user = useSelector(({ auth }: { auth: any }) => auth.user);
  const isLoadingUser = user?.data?.activeEngagementID === undefined;

  const energyEngagementAPI = useEnergyEngagement();

  const [createComment, createCommentResult] = useCreateEnergyCommentMutation();
  const [updateComment, updateCommentResult] = useUpdateEnergyCommentMutation();
  const [deleteComment, deleteCommentResult] = useDeleteEnergyCommentMutation();

  const [getComment, getCommentResult] = useLazyGetEnergyCommentQuery();

  const onDeleteComment = useCallback(
    async (commentID: string) => {
      setIsDeletingComment(true);
      const deleteCommentResponse = await deleteComment({ commentID });
      if (isDev) console.log({ deleteCommentResponse });
      if ("data" in deleteCommentResponse) {
        const updateEngagementDataBody = {
          comments: energyEngagementAPI.engagement.comments.filter(
            (comment: any) => comment.commentID !== commentID
          ),
        };
        const onUpdateEngagementData =
          await energyEngagementAPI.updateEngagement({
            engagementID: energyEngagementAPI.engagement.engagementID,
            body: updateEngagementDataBody,
          });
        if (isDev) console.log({ onUpdateEngagementData });

        // Remove comment
        const newComments = comments.filter(
          (comment: any) => comment.commentID !== commentID
        );
        setComments(newComments);
      }
      setIsDeletingComment(false);
    },
    [deleteComment, energyEngagementAPI, comments]
  );

  const onResolveComment = useCallback(
    async (commentID: string) => {
      setIsResolvingComment(true);
      const currentTime = new Date().toISOString();
      const updateCommentBody = {
        resolvedAt: currentTime,
        resolvedBy: user.fullName,
        status: "RESOLVED",
      };
      const updateCommentResponse = await updateComment({
        commentID,
        body: updateCommentBody,
      });
      if (isDev) console.log({ updateCommentResponse });
      // Replace comment
      const newComments = comments.map((comment: any) => {
        if (comment.commentID === commentID) {
          return {
            ...comment,
            ...updateCommentBody,
          };
        } else {
          return comment;
        }
      });
      setComments(newComments);
      setIsResolvingComment(false);
    },
    [comments, updateComment, user.fullName]
  );

  const onAddNote = useCallback(
    async (commentID: string, comment: string, isNew?: boolean) => {
      setIsAddingNote(true);
      const getCommentResponse = await getComment(commentID);
      if (isDev) console.log({ getCommentResponse });
      const note = {
        title: commentTitle,
        content: comment,
        createdBy: user.fullName,
        email: user.email,
        createdAt: new Date().toISOString(),
      };
      const currentTime = new Date().toISOString();
      const newNotes = [...(getCommentResponse.data?.notes ?? []), note];
      const updateCommentBody = {
        notes: newNotes,
        updatedAt: currentTime,
        uploadedBy: user.fullName,
        module: "ENERGY",
      };
      const updateCommentResponse = await updateComment({
        commentID,
        body: updateCommentBody,
      });
      if (isDev) console.log({ updateCommentResponse });

      // Replace / add comment
      if (isNew) {
        const newComments = [
          ...comments,
          {
            ...getCommentResponse.data,
            ...updateCommentBody,
          },
        ];
        setComments(newComments);
      } else {
        const newComments = comments.map((e: any) => {
          if (e.commentID === commentID) {
            return {
              ...e,
              ...updateCommentBody,
            };
          } else {
            return e;
          }
        });
        setComments(newComments);
      }
      setIsAddingNote(false);
    },
    [
      comments,
      commentTitle,
      getComment,
      updateComment,
      user.email,
      user.fullName,
    ]
  );

  const onCreateComment = useCallback(
    async ({ comment, title, role }: onCreateCommentParams) => {
      setIsCreatingComment(true);
      const currentTime = new Date().toISOString();
      const module = "ENERGY";
      const type = {
        admin: "REVIEW",
        modeler: "MODEL",
        client: "REVIEW",
        certifier: "CERTIFICATION",
      }[role ?? user.role];
      const createCommentBody = {
        engagementID: energyEngagementAPI.engagement.engagementID,
        companyID: energyEngagementAPI.engagement?.companyID,
        createdBy: user.fullName,
        createdAt: currentTime,
        title,
        module,
        type,
      };
      const createCommentResponse = await createComment(createCommentBody);
      if ("data" in createCommentResponse) {
        const commentID = createCommentResponse.data.comment.commentID;
        await onAddNote(commentID, comment, true);

        const updateEngagementDataBody = {
          comments: [
            ...(energyEngagementAPI?.engagement?.comments ?? []),
            {
              commentID,
              createdAt: currentTime,
              createdBy: user.fullName,
              title: commentTitle,
            },
          ],
        };
        await energyEngagementAPI.updateEngagement({
          engagementID: energyEngagementAPI.engagement.engagementID,
          body: updateEngagementDataBody,
        });
      } else {
        // TODO: Handle error
      }
      setIsCreatingComment(false);
    },
    [
      user.role,
      user.data.activeEnergyEngagementID,
      user.fullName,
      energyEngagementAPI,
      createComment,
      onAddNote,
      commentTitle,
    ]
  );

  // Load all comments onMount
  useEffect(() => {
    async function loadAllComments() {
      // if (hasLoadedComments.current) return;
      // hasLoadedComments.current = true;
      setIsLoadingComments(true);
      const existingComments = energyEngagementAPI.engagement?.comments ?? [];
      const getCommentResponses = await Promise.all(
        existingComments.map(async (comment: any) => {
          return await getComment(comment.commentID);
        })
      );
      if (isDev) console.log({ getCommentResponses });
      setComments(
        getCommentResponses
          .filter((e) => e.status === "fulfilled")
          .map((response) => response.data)
      );
      setIsLoadingComments(false);
    }
    if (energyEngagementAPI?.engagement) {
      loadAllComments();
    } else {
      setIsLoadingComments(false);
    }
  }, [energyEngagementAPI.engagement, getComment]);

  const value = useMemo(
    () => ({
      comments,
      getCommentResult,

      setCommentTitle: (title: string) => setCommentTitle(title),
      onCreateComment: async ({
        comment,
        title,
        role,
      }: onCreateCommentParams) =>
        await onCreateComment({ comment, title, role }),
      onDeleteComment: async (commentID: string) =>
        await onDeleteComment(commentID),
      onAddNote: async (commentID: string, comment: string) =>
        await onAddNote(commentID, comment),
      onResolveComment: async (commentID: string) =>
        await onResolveComment(commentID),

      isLoadingUser,
      isLoadingComments,
      isCreatingComment: isCreatingComment,
      isAddingNote: isAddingNote,
      isResolvingComment: isResolvingComment,
      isDeletingComment: isDeletingComment,
    }),
    [
      comments,
      getCommentResult,
      setCommentTitle,
      onCreateComment,
      onDeleteComment,
      onAddNote,
      onResolveComment,
      isLoadingUser,
      isLoadingComments,
      isCreatingComment,
      isAddingNote,
      isResolvingComment,
      isDeletingComment,
    ]
  );

  return (
    <EnergyCommentsContext.Provider value={value}>
      {children}
    </EnergyCommentsContext.Provider>
  );
};
export default EnergyCommentsProvider;
