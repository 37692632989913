export const types = [
  {
    key: 1,
    name: "Contract Research",
  },
  {
    key: 2,
    name: "Computer Lease",
  },
  {
    key: 3,
    name: "Supply",
  },
  {
    key: 4,
    name: "Not Applicable",
  },
];
