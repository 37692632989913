import { useState, useEffect } from "react";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog, Switch } from "@headlessui/react";
import { CheckIcon, PencilIcon, XIcon } from "@heroicons/react/outline";
import { formatter, getStateCredit } from "../utils";
import { useSelector } from "react-redux";
import {
  useUpdateEngagementMutation,
  useGenerateDeliverablesMutation,
} from "../../../api/engagmentAPI";
// import { useDownloadDocumentMutation } from "../../../api/fileAPI";
import { isDev, isProd, isStaging } from "../../R&D/utils";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useGetCompanyQuery } from "../../../api/companyAPI";
import Select from "react-select";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

export const EditEngagementForm = (props) => {
  const {
    markAsTracked,
    updateEngagementData,
    reassignCompany,
    isReassigningCompany,
    isLoadingEngagementData,
  } = useEngagementData();

  const { open, setOpen, loading, engagementData } = props;
  const user = useSelector(({ auth }) => auth.user);
  const [status, setStatus] = useState({});
  const [isEditingName, setIsEditingName] = useState(false);
  const [isEditingFeeType, setIsEditingFeeType] = useState(false);
  const [isEditingFeeValue, setIsEditingFeeValue] = useState(false);
  const [isEditingAssignedCompany, setIsEditingAssignedCompany] =
    useState(false);
  const [name, setName] = useState("");
  const [feeType, setFeeType] = useState("");
  const [fee, setFee] = useState(0);
  const [assignedCompany, setAssignedCompany] = useState({});
  const { companies, isListingCompanies } = useCompanyData();
  const [generateDeliverables] = useGenerateDeliverablesMutation();
  const [updateEngagement] = useUpdateEngagementMutation();
  const { data: companyData, isLoading: isLoadingCompanyData } =
    useGetCompanyQuery(engagementData?.companyID);
  const defaultCompanyValue = {
    label: companyData?.companyName,
    value: companyData?.companyID,
  };
  useEffect(() => {
    if (engagementData?.status) {
      const parsedStatus = Object.keys(engagementData?.status)
        .filter((key) => key.includes("portal"))
        .reduce((obj, key) => {
          return Object.assign(obj, {
            [key]: engagementData?.status[key],
          });
        }, {});
      setStatus(parsedStatus);
    }
    setName(engagementData?.engagementName);
    setFee(engagementData?.tracker?.fee?.value);
    setFeeType(engagementData?.tracker?.fee?.type);
    setAssignedCompany({
      label: companyData?.companyName,
      value: companyData?.companyID,
    });
  }, [engagementData, companyData]);

  const generateDocuments = async () => {
    const body = { engagementID: engagementData.engagementID, isDraft: true };
    const updateEngagementBody = {
      tracker: {
        ...engagementData.tracker,
        "review-complete": {
          status: "COMPLETE",
          uploadedBy: user.fullName,
          lastUpdated: Date.now(),
        },
      },
    };
    await generateDeliverables(body);
    await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: updateEngagementBody,
    });
  };

  // const downloadDocuments = async () => {
  //   const bucket = engagementData?.s3Bucket?.name;
  //   const key = `${engagementData.engagementID}/deliverables/${engagementData.engagementName}_Deliverables.zip`;
  //   const body = { bucket, key, action: "getObject" };
  //   const response = await downloadDocument({ ...body });
  //   window.open(response.data.url, "_blank");
  // };

  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-40"
          static
          layoutId={1234}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <Dialog.Panel className="relative min-h-64vh min-w-64vw transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                <motion.div className="">
                  {/*Modal title, description, and close button */}
                  <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  {loading ? (
                    <div className="">
                      <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Engagement Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Engagement detail
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement ID
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                            </dd>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Client Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                            </dd>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Federal Credit
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              State Credit
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                            </dd>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              S3 Link
                            </dt>
                            <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Data Table Entry
                            </dt>
                            <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Executive Report
                            </dt>
                            <div className="w- h-2 animate-pulse rounded bg-sprxGrey"></div>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Current Status
                            </dt>
                            <div className="col-span-3 grid w-full grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-5 lg:grid-rows-4">
                              {Array.from(Array(19))?.map((item, index) => (
                                <div
                                  key={index}
                                  className="w- h-2 animate-pulse rounded bg-sprxGrey"
                                ></div>
                              ))}
                            </div>
                          </div>
                        </dl>
                      </div>
                    </div>
                  ) : (
                    <div className="">
                      <div className="px-4 py-5 sm:px-6">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                          Engagement Information
                        </h3>
                        <p className="mt-1 max-w-2xl text-sm text-gray-500">
                          Engagement detail
                        </p>
                      </div>
                      <div className="border-t border-gray-200 px-4 py-5 sm:p-0">
                        <dl className="sm:divide-y sm:divide-gray-200">
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Name
                            </dt>
                            {isEditingName ? (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <input
                                  type="text"
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  className="rounded-lg border border-gray-300 p-1 pl-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <button
                                  onClick={async () => {
                                    setIsEditingName(false);
                                    await updateEngagement({
                                      engagementID: engagementData.engagementID,
                                      body: { engagementName: name },
                                    });
                                    await updateEngagementData({
                                      engagementID: engagementData.engagementID,
                                      body: { engagementName: name },
                                    });
                                  }}
                                >
                                  <CheckIcon className="ml-4 h-6 w-6 text-green-500" />
                                </button>
                              </div>
                            ) : (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {name}
                                </dd>
                                <button onClick={() => setIsEditingName(true)}>
                                  <PencilIcon className="ml-4 h-4 w-4 text-blue-500" />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Client Name
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {engagementData?.companyName}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Assigned Company
                            </dt>
                            {isEditingAssignedCompany ? (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <div className="mt-1 mb-4 w-48">
                                  <Select
                                    {...assignedCompany.label}
                                    defaultValue={defaultCompanyValue}
                                    onChange={(val) => setAssignedCompany(val)}
                                    options={companies?.map((company) => ({
                                      value: company.companyID,
                                      label: company.companyName,
                                    }))}
                                    styles={{
                                      menuList: (provided) => ({
                                        ...provided,
                                        maxHeight: "300px",
                                        overflowY: "auto",
                                      }),
                                      option: (provided) => ({
                                        ...provided,
                                        fontSize: "14px",
                                      }),
                                    }}
                                  />
                                </div>
                                <button
                                  onClick={async () => {
                                    setIsEditingAssignedCompany(false);
                                    await reassignCompany({
                                      engagementID: engagementData.engagementID,
                                      body: {
                                        currentCompanyID:
                                          engagementData?.companyID,
                                        newCompanyID: assignedCompany.value,
                                      },
                                    });
                                  }}
                                >
                                  <CheckIcon className="ml-4 h-6 w-6 text-green-500" />
                                </button>
                              </div>
                            ) : (
                              <div className="justfy-start flex w-full flex-row items-center">
                                {isReassigningCompany ||
                                isLoadingCompanyData ? (
                                  <p className="h-2 w-1/4 animate-pulse rounded-md bg-sprxGrey"></p>
                                ) : (
                                  <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                    {assignedCompany?.label}
                                  </dd>
                                )}

                                <button
                                  onClick={() =>
                                    setIsEditingAssignedCompany(true)
                                  }
                                >
                                  <PencilIcon className="ml-4 h-4 w-4 text-blue-500" />
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Federal Credit
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                              {engagementData?.credit?.rdCredit
                                ? formatter.format(
                                    Math.round(
                                      engagementData?.credit?.rdCredit,
                                    ),
                                  )
                                : "N/A"}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              State Credit
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:col-span-1 sm:mt-0">
                              {formatter.format(
                                Math.round(getStateCredit(engagementData)),
                              )}
                            </dd>
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Fee Type
                            </dt>
                            {isEditingFeeType ? (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <select
                                  {...feeType}
                                  id="feeType"
                                  name="feeType"
                                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                                  onChange={(e) => setFeeType(e.target.value)}
                                >
                                  <option value="none">Select an Option</option>
                                  {["Fixed", "Percentage"].map((type) => {
                                    return (
                                      <option
                                        value={type.toUpperCase()}
                                        key={type}
                                      >
                                        {type}
                                      </option>
                                    );
                                  })}
                                </select>
                                <button
                                  onClick={async () => {
                                    setIsEditingFeeType(false);

                                    await updateEngagementData({
                                      engagementID: engagementData.engagementID,
                                      body: {
                                        tracker: {
                                          ...engagementData.tracker,
                                          fee: {
                                            ...engagementData.tracker.fee,
                                            type: feeType,
                                          },
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <CheckIcon className="ml-4 h-6 w-6 text-green-500" />
                                </button>
                              </div>
                            ) : (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {feeType ? feeType : "N/A"}
                                </dd>
                                <button
                                  onClick={() => setIsEditingFeeType(true)}
                                >
                                  <PencilIcon className="ml-4 h-4 w-4 text-blue-500" />
                                </button>
                              </div>
                            )}
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Fee{" "}
                              {feeType === "PERCENTAGE"
                                ? "Percentage"
                                : "Value"}
                            </dt>
                            {isEditingFeeValue ? (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <input
                                  type="number"
                                  value={fee}
                                  onChange={(e) => setFee(e.target.value)}
                                  className="rounded-lg border border-gray-300 p-1 pl-2 text-sm focus:border-transparent focus:outline-none focus:ring-2 focus:ring-blue-500"
                                />
                                <button
                                  onClick={async () => {
                                    setIsEditingFeeValue(false);

                                    await updateEngagementData({
                                      engagementID: engagementData.engagementID,
                                      body: {
                                        tracker: {
                                          ...engagementData.tracker,
                                          fee: {
                                            ...engagementData.tracker.fee,
                                            value: fee,
                                          },
                                        },
                                      },
                                    });
                                  }}
                                >
                                  <CheckIcon className="ml-4 h-6 w-6 text-green-500" />
                                </button>
                              </div>
                            ) : (
                              <div className="justfy-start flex w-full flex-row items-center">
                                <dd className="mt-1 text-sm text-gray-900 sm:col-span-2 sm:mt-0">
                                  {fee ? fee : "N/A"}
                                </dd>
                                <button
                                  onClick={() => setIsEditingFeeValue(true)}
                                >
                                  <PencilIcon className="ml-4 h-4 w-4 text-blue-500" />
                                </button>
                              </div>
                            )}
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              S3 Link
                            </dt>
                            <a
                              target="_blank"
                              className="text-blue-400 underline"
                              href={`https://s3.console.aws.amazon.com/s3/buckets/${
                                engagementData?.s3Bucket?.name
                              }?region=us-east-2&prefix=${
                                engagementData?.s3Bucket?.path.split("/")[1]
                              }/`}
                              rel="noreferrer"
                            >
                              {isReassigningCompany ||
                              isLoadingEngagementData ||
                              loading ? (
                                <p className="h-2 w-1/4 animate-pulse rounded-md bg-sprxGrey"></p>
                              ) : (
                                engagementData?.s3Bucket?.name
                              )}
                            </a>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Engagement Data Table Entry
                            </dt>
                            <a
                              target="_blank"
                              className="text-blue-400 underline"
                              href={`https://us-east-2.console.aws.amazon.com/dynamodbv2/home?region=us-east-2#edit-item?table=${
                                isDev
                                  ? "ant"
                                  : isProd
                                    ? "prod"
                                    : isStaging
                                      ? "staging"
                                      : ""
                              }-sprx-tax-api-rd-engagementData&itemMode=2&pk=${
                                engagementData.engagementID
                              }&sk&route=ROUTE_ITEM_EXPLORER`}
                              rel="noreferrer"
                            >
                              {`${engagementData?.engagementID}`}
                            </a>
                          </div>

                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Executive Report
                            </dt>
                            <div className="w-1/3">
                              <button
                                type="button"
                                className="inline-flex w-20 items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={async () => await generateDocuments()}
                              >
                                Generate
                              </button>
                            </div>
                            {/*<div className="w-1/3">
                              <button
                                type="button"
                                className="inline-flex w-20 items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={async () => await downloadDocuments()}
                              ></button>
                            </div>*/}
                          </div>
                          <div className="py-4 sm:grid sm:grid-cols-3 sm:gap-4 sm:py-5 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Current Status
                            </dt>
                            <div className="col-span-3 grid w-full grid-cols-1 gap-1 sm:grid-cols-2 sm:gap-2 lg:grid-cols-5 lg:grid-rows-4">
                              {Object.keys(status).map((key) => (
                                <Switch.Group
                                  as="div"
                                  className="flex items-center"
                                  key={key}
                                >
                                  <Switch
                                    checked={status[key]?.complete}
                                    onChange={async () => {
                                      const body = {
                                        status: {
                                          ...engagementData.status,
                                          [key]: {
                                            complete: !status[key]?.complete,
                                          },
                                        },
                                      };

                                      await updateEngagementData({
                                        engagementID:
                                          engagementData.engagementID,
                                        body,
                                      });

                                      if (
                                        key === "portal-submitted" ||
                                        key === "portal-deliverables"
                                      ) {
                                        const trackerKey =
                                          key === "portal-submitted"
                                            ? "upload-complete"
                                            : "review-complete";
                                        const updateVal =
                                          engagementData.tracker[trackerKey]
                                            ?.status === "INCOMPLETE"
                                            ? "COMPLETE"
                                            : "INCOMPLETE";
                                        await markAsTracked(
                                          updateVal,
                                          trackerKey,
                                        );
                                      }
                                    }}
                                    className={classNames(
                                      status[key]?.complete
                                        ? "bg-indigo-600"
                                        : "bg-gray-200",
                                      "relative inline-flex h-6 w-11 flex-shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus:ring-2 focus:ring-indigo-600 focus:ring-offset-2",
                                    )}
                                  >
                                    <span
                                      aria-hidden="true"
                                      className={classNames(
                                        status[key]?.complete
                                          ? "translate-x-5"
                                          : "translate-x-0",
                                        "pointer-events-none inline-block h-5 w-5 transform rounded-full bg-white shadow ring-0 transition duration-200 ease-in-out",
                                      )}
                                    />
                                  </Switch>
                                  <Switch.Label as="span" className="ml-3">
                                    <p className="text-sm font-medium text-gray-900">
                                      {key.substr(7)}
                                    </p>
                                  </Switch.Label>
                                </Switch.Group>
                              ))}
                            </div>
                          </div>

                          {/*<div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Throw Sentry Error
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <button
                                type="button"
                                className="inline-flex w-20 items-center justify-center rounded-md border border-transparent bg-sprxClientPortalRed px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={async () => {
                                  throw Error("This is for Sentry testing.");
                                }}
                              >
                                Error
                              </button>
                            </dd>
                          </div>
                          <div className="py-4 sm:py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                            <dt className="text-sm font-medium text-gray-500">
                              Run Migrations (FOR SYS ADMIN USE)
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 sm:col-span-2">
                              <button
                                type="button"
                                className="inline-flex w-20 items-center justify-center rounded-md border border-transparent bg-sprxClientPortalRed px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                onClick={async () => {
                                  await migrateUsers();
                                }}
                              >
                                Migrate
                              </button>
                            </dd>
                          </div>*/}
                        </dl>
                      </div>
                    </div>
                  )}
                </motion.div>
              </Dialog.Panel>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
