import axios from "axios";
import { Cookies } from "react-cookie";

import config from "./_config";

import { baseAPI } from "./baseAPI";

const cookies = new Cookies();

const loginAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/login",
            data: arg,
          });

          if (result.status === 200) {
            if (result.headers["session-id"]) {
              cookies.set("session-id", result.headers["session-id"]);
            }

            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          console.error(`Login Error: ${JSON.stringify(err)}`);
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    getLoginCode: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/get-login-code",
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    loginWithCode: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/submit-login-code",
            data: arg,
          });

          if (result.status === 200) {
            if (result.headers["session-id"]) {
              cookies.set("session-id", result.headers["session-id"]);
            }

            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          console.error(`Login Error: ${JSON.stringify(err)}`);
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    logout: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const token = cookies.get("session-id");

        const result = await axios({
          method: "POST",
          url: config.apiGateway.URL + "/logout",
          data: arg,
          headers: {
            "session-id": token,
          },
        });

        if (result.status === 200) {
          cookies.remove("session-id");

          return { data: result.data };
        }
      },
      invalidatesTags: (result, error, { id }) => [{ type: "User", id }],
    }),
    signUp: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/sign-up",
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    confirmSignUp: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/confirm-sign-up",
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    resetPassword: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/reset-password",
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    confirmPasswordReset: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/confirm-password-reset",
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    getSession: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "GET",
            url: config.apiGateway.URL + `/session/${arg.sessionID}`,
            data: arg,
          });

          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    getSSOLoginURL: builder.mutation({
      query: (body) => {
        return {
          url: `/sso-authorization-url`,
          method: "POST",
          body: body,
        };
      },
    }),
    exchangeSSOToken: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        try {
          const result = await axios({
            method: "POST",
            url: config.apiGateway.URL + "/exchange-sso-token",
            data: arg,
          });

          if (result.status === 200) {
            if (result.headers["session-id"]) {
              cookies.set("session-id", result.headers["session-id"]);
            }

            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
  }),
  overrideExisting: false,
});

export const {
  useLoginMutation,
  useGetLoginCodeMutation,
  useLoginWithCodeMutation,
  useLogoutMutation,
  useSignUpMutation,
  useConfirmSignUpMutation,
  useResetPasswordMutation,
  useConfirmPasswordResetMutation,
  useGetSessionMutation,
  useGetSSOLoginURLMutation,
  useExchangeSSOTokenMutation,
} = loginAPI;
