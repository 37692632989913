export const getDownloadFileName = (type) => {
  switch (type) {
    /* OLD ENUMS */
    case "Survey":
      return "SME Email Template.xlsx";
    case "Wages":
      return "Wage Review Template.xlsx";
    case "Supply":
      return "Supply Review Template.xlsx";
    case "Contract Research":
      return "CR Review Template.xlsx";
    case "Computer Lease":
      return "Comp Lease Review Template.xlsx";
    case "Wages Projects":
      return "Employees Projects.xlsx";
    case "Supply Projects":
      return "Supply Projects.xlsx";
    case "Contract Research Projects":
      return "Contractors Projects.xlsx";
    case "Computer Lease Projects":
      return "Computer Lease Projects.xlsx";

    /* NEW ENUMS */

    // WAGE ENUMS
    case "WAGES":
      return "Wage Review Template.xlsx";
    case "WAGES_REVIEW":
      return "Wage Review Template.xlsx";
    case "WAGES_ALLOCATIONS":
      return "Employees Projects.xlsx";

    // SUPPLY ENUMS
    case "SUPPLY":
      return "Supply Review Template.xlsx";
    case "SUPPLY_REVIEW":
      return "Supply Review Template.xlsx";
    case "SUPPLY_ALLOCATIONS":
      return "Supply Projects.xlsx";

    // CONTRACT ENUMS
    case "CONTRACT":
      return "CR Review Template.xlsx";
    case "CONTRACT_REVIEW":
      return "CR Review Template.xlsx";
    case "CONTRACT_ALLOCATIONS":
      return "Contractors Projects.xlsx";

    // COMPUTER ENUMS
    case "COMPUTER":
      return "Comp Lease Review Template.xlsx";
    case "COMPUTER_REVIEW":
      return "Comp Lease Review Template.xlsx";
    case "COMPUTER_ALLOCATIONS":
      return "Computer Lease Projects.xlsx";

    // SURVEY ENUMS
    case "SURVEY":
      return "SME Email Template.xlsx";
    default:
      throw Error("getDownloadFileName: invalid type");
  }
};
