import React from "react";

export const Tag = ({ name, color }: { name: string; color: string }) => (
  <span
    className={`px-2 py-1 text-sm font-semibold rounded-full ${
      color === "green"
        ? "bg-green-200 text-green-800"
        : color === "blue"
        ? "bg-blue-200 text-blue-800"
        : color === "yellow"
        ? "bg-yellow-200 text-yellow-800"
        : color === "red"
        ? "bg-red-200 text-red-800"
        : "bg-gray-200 text-gray-800"
    }`}
  >
    {name}
  </span>
);
