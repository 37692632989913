import { Fragment, useState, useRef } from "react";
import { Dialog, Transition } from "@headlessui/react";
import {
  XIcon,
  CloudDownloadIcon,
  RefreshIcon,
  CloudUploadIcon,
} from "@heroicons/react/outline";
import classNames from "classnames";

const ModalCard = (props) => {
  const [open, setOpen] = useState(true);
  let completeButtonRef = useRef(null);

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog
        as="div"
        static
        className="fixed z-50 inset-0 overflow-x-scroll"
        initialFocus={completeButtonRef}
        open={open}
        onClose={() => {
          if (props.close && !props.tour) {
            props.close();
            setOpen(false);
          }
        }}
      >
        <div
          ref={completeButtonRef}
          className="flex items-end justify-center min-h-screen pt-4 px-4 pb-20 text-center sm:block sm:p-0"
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          {/* This element is to trick the browser into centering the modal contents. */}
          <span
            className="hidden sm:inline-block sm:align-middle sm:h-screen"
            aria-hidden="true"
          >
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            enterTo="opacity-100 translate-y-0 sm:scale-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100 translate-y-0 sm:scale-100"
            leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
          >
            <div
              className={classNames(
                props.className && props.className,
                  "h-50vh min-w-50vw",
                "inline-block bg-white rounded-lg px-4 pt-5 pb-4 text-left overflow-hidden shadow-xl transform transition-all sm:align-middle sm:max-w-lg sm:w-full sm:p-6"
              )}
            >
              {props.update && (
                <div className="hidden sm:block absolute top-0 right-12 pt-4 pr-8">
                  <button
                    data-tip="Save"
                    type="button"
                    className="update-review bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      props.update();
                    }}
                  >
                    <span className="sr-only">Save</span>
                    <RefreshIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {props.import && (
                <div className="hidden sm:block absolute top-0 right-12 pt-4 pr-12">
                  <button
                    data-tip="Export"
                    type="button"
                    className="export-review bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      props.import();
                    }}
                  >
                    <span className="sr-only">Export</span>
                    <CloudUploadIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {props.export && (
                <div className="hidden sm:block absolute top-0 right-8 pt-4 pr-4">
                  <button
                    data-tip="Export"
                    type="button"
                    className="export-review bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      props.export();
                    }}
                  >
                    <span className="sr-only">Export</span>
                    <CloudDownloadIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {props.close && (
                <div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                  <button
                    type="button"
                    className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                    onClick={() => {
                      props.close();
                      setOpen(false);
                    }}
                  >
                    <span className="sr-only">Close</span>
                    <XIcon className="h-6 w-6" aria-hidden="true" />
                  </button>
                </div>
              )}
              {props.title && (
                <div className="sm:flex sm:items-start">
                  <div className="w-full mt-3 text-center sm:mt-0 sm:text-left">
                    <Dialog.Title
                      as="h3"
                      className="text-center text-lg leading-6 font-medium text-gray-900"
                    >
                      {props.title}
                    </Dialog.Title>
                    <div className="mt-2">
                      <p className="text-center text-md text-gray-500">
                        {props.subtitle}
                      </p>
                    </div>
                  </div>
                </div>
              )}
              <div className="w-full h-30vh mt-4 p-2">
                {props.mainContent}
              </div>
              <div className="inset-x-6 bottom-2 justify-between sm:flex sm:flex-row-reverse">
                {props.bottomContent}
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

export default ModalCard;
