import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { motion } from "framer-motion";
import _ from "lodash";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { Engagement } from "../../R&D/types/engagement";
import { setUserData } from "../../../auth/rtk/userSlice";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import {
  getCreateEngagementFormDefaultFields,
  getCreateEngagementFormShape,
} from "./util";

import {
  useGetTaxUserMutation,
  useUpdateTaxUserMutation,
} from "../../../api/userAPI";

import {
  useCreateEngagementDataMutation,
  useCreateEngagementMutation,
} from "../../../api/engagmentAPI";
import { useUsers } from "../../../rtk/Users/useUsers";
import { CreateFormWrapper } from "../components/CreateFormWrapper";
import Select from "react-select";

function classNames(...classes: string[]) {
  return classes.filter(Boolean).join(" ");
}

interface CreateEngagementFormProps {
  user: any;
  open: boolean;
  setOpen: (open: boolean) => void;
  engagements: Engagement[];
  companyIdProp?: string;
}

export const CreateEngagementForm = ({
  open,
  setOpen,
  user,
  engagements,
  companyIdProp,
}: CreateEngagementFormProps) => {
  const dispatch = useDispatch();
  const schema = getCreateEngagementFormShape();
  const defaultValues = getCreateEngagementFormDefaultFields();

  const { companies, getUpdatedCompanyData, updateCompany } = useCompanyData();

  const { users } = useUsers();

  const { control, formState, handleSubmit, watch, setValue } = useForm({
    mode: "onChange",
    defaultValues,
    resolver: yupResolver(schema),
  });
  const { isValid, errors, dirtyFields } = formState;

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const isRollover = watch("isRollover");
  const feeType = watch("feeType");

  const [createEngagement, { isLoading: isCreatingEngagement }] =
    useCreateEngagementMutation();

  const [createEngagementData, { isLoading: isCreatingEngagementData }] =
    useCreateEngagementDataMutation();

  const [getTaxUser] = useGetTaxUserMutation();

  const [updateTaxUser] = useUpdateTaxUserMutation();

  const [loading, setLoading] = useState(false);

  const onSubmit = async (model: any) => {
    setLoading(true);

    let companyID: string;

    if (companyIdProp !== undefined) {
      companyID = companyIdProp;
    } else {
      companyID = companies.filter((co) => {
        return co.companyName === model.companyName.value;
      })[0]?.companyID;
    }

    if (companyID === undefined) {
      console.error("Invalid company");
      return;
    }

    const rolloverOptions = {
      isRollover: model.isRollover,
      engagement: model.engagement,
      entityName: model.entityName,
      entityEIN: model.entityEIN,
      entityType: model.entityType,
      pyQres: model.pyQres,
      pyGrs: model.pyGrs,
      elections: model.elections,
      wageQre: model.wageQre,
      method: model.method,
      supplyQre: model.supplyQre,
      contractorQre: model.contractorQre,
      computerQre: model.computerQre,
      projectQre: model.projectQre,
    };

    const updatedCompanyData = await getUpdatedCompanyData({
      companyID,
    });

    const newEngagement: any = await createEngagement({
      companyID,
      companyName: model.clientName,
      engagementFees: 0,
      engagementName: model.engagementName,
      engagementType: "6",
      taxYearEnd: model.taxYearEnd,
      taxYearStart: model.taxYearStart,
      createdBy: user.companyID,
    });
    const now = new Date();
    const oneYearFromNow = new Date(now.setFullYear(now.getFullYear() + 1));
    const dueDate = model?.dueDate
      ? new Date(model.dueDate).toLocaleDateString()
      : oneYearFromNow.toLocaleDateString();
    await createEngagementData({
      companyID,
      engagementID: newEngagement.data.params.engagementID,
      companyName: newEngagement.data.params.companyName,
      companyEIN: model.clientEIN,
      bucketName: updatedCompanyData.data.companyBucket.name,
      engagementFees: newEngagement.data.params.engagementFees,
      engagementName: newEngagement.data.params.engagementName,
      engagementType: newEngagement.data.params.engagementType,
      taxYearEnd: newEngagement.data.params.taxYearEnd,
      taxYearStart: newEngagement.data.params.taxYearStart,
      dueDate: dueDate,
      salesRepID: model.salesRepID.value,
      salesRepName: model.salesRepID.label,
      rolloverOptions,
      feeType: model.feeType,
      feeValue: model.feeValue,
      CPAName: model.CPAName,
    });

    let engagements = [...updatedCompanyData.data.engagements];

    let newEng = {
      engagementID: newEngagement.data.params.engagementID,
      engagementName: newEngagement.data.params.engagementName,
    };

    engagements.push(newEng);

    const companyBody = {
      engagements: engagements,
    };

    const updateCompanyStatus = await updateCompany({
      companyID,
      body: companyBody,
    });

    // TODO: Discuss whether this is necessary
    if (updateCompanyStatus.data.status === true) {
      const body = {
        activeEngagementID: newEngagement.data.params.engagementID,
        isUsingDataSpace: false,
        engagementType: "RD",
      };

      await updateTaxUser({ userID: user.userID, body: body });

      const updatedUser: any = await getTaxUser(user.userID);

      dispatch(setUserData(updatedUser.data));

      setLoading(false);
      setOpen(false);
    }
  };

  return (
    <CreateFormWrapper open={open} setOpen={setOpen} type="RD Engagement">
      <form
        className="sm:flex sm:flex-col sm:items-center"
        onSubmit={handleSubmit((model) => onSubmit(model))}
      >
        <motion.div className="flex w-full flex-col items-center">
          <motion.div className="flex w-full flex-row justify-between">
            {companyIdProp === undefined ? (
              <Controller
                name="companyName"
                control={control}
                render={({ field }) => (
                  <div className="mt-4 mr-2 w-full text-left sm:max-w-3xl">
                    <label htmlFor="companyName" className="text-left">
                      Company Name <span className="text-sprxOrange">*</span>
                    </label>

                    <div className="mt-1">
                      <Select
                        {...field}
                        onChange={(val) => setValue("companyName", val as any)}
                        options={companies.map((company) => ({
                          value: company.companyName,
                          label: company.companyName,
                        }))}
                        styles={{
                          menuList: (provided) => ({
                            ...provided,
                            maxHeight: "300px",
                            overflowY: "auto",
                          }),
                          option: (provided) => ({
                            ...provided,
                            fontSize: "14px",
                          }),
                        }}
                      />
                    </div>
                  </div>
                )}
              />
            ) : null}
            <Controller
              name="salesRepID"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="salesRepID" className="text-left">
                    Sales Representative{" "}
                    <span className="text-sprxOrange">*</span>
                  </label>
                  <div className="mt-1">
                    <Select
                      {...field}
                      onChange={(val) => setValue("salesRepID", val as any)}
                      options={users
                        ?.filter((u) => u.role === "sales")
                        ?.map((u) => {
                          return { value: u.userID, label: u.fullName };
                        })}
                      styles={{
                        menuList: (provided) => ({
                          ...provided,
                          maxHeight: "300px",
                          overflowY: "auto",
                        }),
                        option: (provided) => ({
                          ...provided,
                          fontSize: "14px",
                        }),
                      }}
                    />
                  </div>
                </div>
              )}
            />
          </motion.div>
          <motion.div className="flex w-full flex-row justify-between">
            <Controller
              name="engagementName"
              control={control}
              render={({ field }) => (
                <div className="mt-4 mr-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="engagementName" className="text-left">
                    Engagement Name <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    {...field}
                    type="text"
                    name="Engagement Name"
                    id="engagementName"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                    placeholder="Engagement Name"
                  />

                  {errors.hasOwnProperty("engagementName") ? (
                    <p className="pl-2 text-sprxOrange">
                      Invalid Engagement Name
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="CPAName"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="clientName">CPA Name</label>
                  <input
                    {...field}
                    type="text"
                    name="CPAName"
                    id="CPAName"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                    placeholder="CPA Name"
                  />
                  {errors.hasOwnProperty("CPAName") ? (
                    <p className="pl-2 text-sprxOrange">Invalid CPA Name</p>
                  ) : null}
                </div>
              )}
            />
          </motion.div>

          <motion.div className="flex w-full flex-row justify-between">
            <Controller
              name="clientName"
              control={control}
              render={({ field }) => (
                <div className="mt-4 mr-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="clientName">
                    Client Name <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    {...field}
                    type="text"
                    name="clientName"
                    id="clientName"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                    placeholder="Client Name"
                  />
                  {errors.hasOwnProperty("clientName") ? (
                    <p className="pl-2 text-sprxOrange">Invalid Client Name</p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="clientEIN"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="clientEIN">
                    Client EIN <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    {...field}
                    type="text"
                    name="clientEIN"
                    id="clientEIN"
                    required
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                    placeholder="Client EIN"
                  />
                  {errors.hasOwnProperty("clientEIN") ? (
                    <p className="pl-2 text-sprxOrange">Invalid EIN</p>
                  ) : null}
                </div>
              )}
            />
          </motion.div>
          <motion.div className="flex w-full flex-row justify-between">
            <Controller
              name="taxYearStart"
              control={control}
              render={({ field }) => (
                <div className="mt-4 mr-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="taxYearStart">
                    Tax Year Start Date{" "}
                    <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    id={"taxYearStart"}
                    {...field}
                    className={classNames(
                      "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                    )}
                    type={"text"}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    placeholder={"Tax Year Start Date"}
                  />
                  {errors.hasOwnProperty("taxYearStart") ? (
                    <p className="pl-2 text-sprxOrange">
                      Invalid Tax Year Start
                    </p>
                  ) : null}
                </div>
              )}
            />

            <Controller
              name="taxYearEnd"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="taxYearEnd">
                    Tax Year End Date <span className="text-sprxOrange">*</span>
                  </label>
                  <input
                    id={"taxYearEnd"}
                    {...field}
                    className={classNames(
                      "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                    )}
                    type={"text"}
                    onFocus={(e) => (e.target.type = "date")}
                    onBlur={(e) => (e.target.type = "text")}
                    placeholder={"Tax Year End Date"}
                  />
                  {errors.hasOwnProperty("taxYearEnd") ? (
                    <p className="pl-2 text-sprxOrange">Invalid Tax Year End</p>
                  ) : null}
                </div>
              )}
            />
          </motion.div>

          <motion.div className="flex w-full flex-row justify-between">
            <Controller
              name="feeType"
              control={control}
              render={({ field }) => (
                <div className="mt-4 mr-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="feeType">Fee Type</label>
                  <select
                    {...field}
                    id="feeType"
                    name="feeType"
                    className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                  >
                    <option value="none">Select an Option</option>
                    {["Fixed", "Percentage"].map((type) => {
                      return (
                        <option value={type.toUpperCase()} key={type}>
                          {type}
                        </option>
                      );
                    })}
                  </select>

                  {errors.hasOwnProperty("feeType") ? (
                    <p className="pl-2 text-sprxOrange">Invalid Fee Type</p>
                  ) : null}
                </div>
              )}
            />
            <Controller
              name="feeValue"
              control={control}
              render={({ field }) => (
                <div className="mt-4 ml-2 w-full text-left sm:max-w-3xl">
                  <label htmlFor="feeValue">
                    Fee {feeType === "FIXED" ? "Value" : "Percentage"}
                  </label>
                  <input
                    id={"feeValue"}
                    {...field}
                    className={classNames(
                      "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                    )}
                    type="number"
                    placeholder={"Fee Value"}
                  />
                  {errors.hasOwnProperty("feeValue") ? (
                    <p className="pl-2 text-sprxOrange">Invalid Fee Value</p>
                  ) : null}
                </div>
              )}
            />
          </motion.div>

          <Controller
            name="dueDate"
            control={control}
            render={({ field }) => (
              <div className="mt-4 w-full text-left sm:max-w-3xl">
                <label htmlFor="dueDate">Due Date</label>
                <input
                  id={"dueDate"}
                  {...field}
                  className={classNames(
                    "form-input block w-full rounded-md border-gray-300 focus:border-gray-300 focus:outline-none focus:ring-transparent sm:text-sm",
                  )}
                  type={"text"}
                  onFocus={(e) => (e.target.type = "date")}
                  onBlur={(e) => (e.target.type = "text")}
                  placeholder={"Due Date"}
                />
                {errors.hasOwnProperty("dueDate") ? (
                  <p className="pl-2 text-sprxOrange">Invalid Due Date</p>
                ) : null}
              </div>
            )}
          />

          <div className="flex flex-col items-center space-y-8 divide-y divide-gray-200">
            <Controller
              name="isRollover"
              control={control}
              render={({ field }) => (
                <div className="sm:col-span-6">
                  <div className="relative mt-6 flex items-start">
                    <div className="flex h-5 items-center">
                      <input
                        {...field}
                        value={field.value as any}
                        checked={field.value}
                        id="isRollover"
                        name="isRollover"
                        type="checkbox"
                        className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                      />
                    </div>
                    <div className="ml-3 text-sm">
                      <label
                        htmlFor="isRollover"
                        className="font-medium text-gray-700"
                      >
                        Check this box if you would like to rollover an
                        engagement
                      </label>
                    </div>
                  </div>
                </div>
              )}
            />
            {isRollover === true ? (
              <div className="my-4">
                <div>
                  <Controller
                    name="engagement"
                    control={control}
                    render={({ field }) => (
                      <div className="sm:col-span-3">
                        <label
                          htmlFor="entityType"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Engagement to Rollover
                        </label>
                        <div className="mt-1">
                          <select
                            {...field}
                            id="engagement"
                            name="engagement"
                            className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          >
                            <option value={""}></option>
                            {engagements.map((engagement) => {
                              return (
                                <option value={engagement.engagementID}>
                                  {engagement.engagementName}
                                </option>
                              );
                            })}
                          </select>
                        </div>
                      </div>
                    )}
                  />
                </div>
                <div className="">
                  <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
                    <Controller
                      name="entityName"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="entityName"
                                name="entityName"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="entityName"
                                className="font-medium text-gray-700"
                              >
                                Entity Name
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="entityEIN"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="entityEIN"
                                name="entityEIN"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="entityEIN"
                                className="font-medium text-gray-700"
                              >
                                Entity EIN
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="entityType"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="entityType"
                                name="entityType"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="entityEIN"
                                className="font-medium text-gray-700"
                              >
                                Corporate Structure
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="method"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="method"
                                name="method"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="method"
                                className="font-medium text-gray-700"
                              >
                                Computation Method
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="pyQres"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="pyQres"
                                name="pyQres"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="pyQres"
                                className="font-medium text-gray-700"
                              >
                                Prior Year Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="pyGrs"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="pyGrs"
                                name="pyGrs"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="method"
                                className="font-medium text-gray-700"
                              >
                                Prior Year Gross Receipts
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="elections"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="elections"
                                name="elections"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="elections"
                                className="font-medium text-gray-700"
                              >
                                Elections (Controlled Group, 280C, Acquisition,
                                etc.)
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="wageQre"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="wageQre"
                                name="wageQre"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="wageQre"
                                className="font-medium text-gray-700"
                              >
                                Wage Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="supplyQre"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="supplyQre"
                                name="supplyQre"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="wageQre"
                                className="font-medium text-gray-700"
                              >
                                Supply Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="contractorQre"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="contractorQre"
                                name="contractorQre"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="wageQre"
                                className="font-medium text-gray-700"
                              >
                                Contractor Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="computerQre"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="computerQre"
                                name="computerQre"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="computerQre"
                                className="font-medium text-gray-700"
                              >
                                Computer Lease Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <Controller
                      name="projectQre"
                      control={control}
                      render={({ field }) => (
                        <div className="sm:col-span-6">
                          <div className="relative mt-6 flex items-start">
                            <div className="flex h-5 items-center">
                              <input
                                {...field}
                                value={field.value as any}
                                checked={field.value}
                                id="projectQre"
                                name="projectQre"
                                type="checkbox"
                                className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                              />
                            </div>
                            <div className="ml-3 text-sm">
                              <label
                                htmlFor="projectQre"
                                className="font-medium text-gray-700"
                              >
                                Project Qualified Research Expenses
                              </label>
                            </div>
                          </div>
                        </div>
                      )}
                    />
                    <div hidden={true}>
                      <input
                        id="submit"
                        type="submit"
                        className={classNames(
                          "flex w-full justify-center rounded-md border border-transparent bg-sprxBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none md:mt-4",
                          !isValid &&
                            "cursor-not-allowed bg-sprxGrey opacity-50",
                        )}
                      />
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
          <motion.div className="mt-6 flex w-full flex-row items-end justify-end">
            <motion.button
              type="submit"
              data-test="Save & Close"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              disabled={_.isEmpty(dirtyFields) || !isValid}
              className={
                "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
                (!isValid
                  ? "cursor-not-allowed bg-opacity-50"
                  : " hover:bg-sprxClientPortalDarkBlue")
              }
            >
              {isCreatingEngagement || isCreatingEngagementData || loading ? (
                <div className="flex h-5 w-full flex-col items-center justify-center">
                  <p
                    className={
                      "h-2 w-full animate-pulse rounded-md bg-gray-100"
                    }
                  ></p>
                </div>
              ) : (
                "Create"
              )}
            </motion.button>
          </motion.div>
        </motion.div>
      </form>
    </CreateFormWrapper>
  );
};
