export const mapTags = (tracker: any, status: any) => {
  switch (status) {
    case "Upload":
      return [
        {
          name: "Wages",
          color:
            tracker["upload-wages"]?.status === "COMPLETE" ? "green" : "red",
          uploadedBy: tracker["upload-wages"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-wages"]?.lastUpdated ?? undefined,
        },
        {
          name: "Projects",
          color:
            tracker["upload-projects"]?.status === "COMPLETE" ? "green" : "red",
          uploadedBy: tracker["upload-projects"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-projects"]?.lastUpdated ?? undefined,
        },
        {
          name: "Elections",
          color:
            tracker["upload-elections"]?.status === "COMPLETE"
              ? "green"
              : "red",
          uploadedBy: tracker["upload-elections"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-elections"]?.lastUpdated ?? undefined,
        },
        {
          name: "Supply",
          color:
            tracker["upload-supply"]?.status === "COMPLETE" ? "green" : "gray",
          uploadedBy: tracker["upload-supply"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-supply"]?.lastUpdated ?? undefined,
        },
        {
          name: "Computers",
          color:
            tracker["upload-computer"]?.status === "COMPLETE"
              ? "green"
              : "gray",
          uploadedBy: tracker["upload-computer"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-computer"]?.lastUpdated ?? undefined,
        },
        {
          name: "Contracts",
          color:
            tracker["upload-contract"]?.status === "COMPLETE"
              ? "green"
              : "gray",
          uploadedBy: tracker["upload-contract"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["upload-contract"]?.lastUpdated ?? undefined,
        },
      ];
    case "Review":
      return [
        {
          name: "QRE",
          color:
            tracker["review-qre"]?.status === "COMPLETE"
              ? "green"
              : tracker["review-qre"]?.status === "IN_PROGRESS"
              ? "yellow"
              : "red",
          uploadedBy: tracker["review-qre"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["review-qre"]?.lastUpdated ?? undefined,
        },
        {
          name: "Allocations",
          color:
            tracker["review-allocation"]?.status === "COMPLETE"
              ? "green"
              : tracker["review-allocation"]?.status === "IN_PROGRESS"
              ? "yellow"
              : "red",
          uploadedBy: tracker["review-allocation"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["review-allocation"]?.lastUpdated ?? undefined,
        },
      ];
    case "Validation":
      return [
        {
          name: "Viewed",
          color:
            tracker["reports-viewed"]?.status === "COMPLETE" ? "green" : "gray",
          uploadedBy: tracker["reports-viewed"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["reports-viewed"]?.lastUpdated ?? undefined,
        },
        {
          name: "Executive Report",
          color:
            tracker["reports-executive"]?.status === "COMPLETE"
              ? "green"
              : "gray",
          uploadedBy: tracker["reports-executive"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["reports-executive"]?.lastUpdated ?? undefined,
        },
        {
          name: "Schedules",
          color:
            tracker["reports-schedules"]?.status === "COMPLETE"
              ? "green"
              : "gray",
          uploadedBy: tracker["reports-schedules"]?.uploadedBy ?? undefined,
          lastUpdated: tracker["reports-schedules"]?.lastUpdated ?? undefined,
        },
      ];
    default:
      return [];
  }
};
