export const ChatHistoryStyleOverride = () => {
  return (
    <style>
      {`
        .message-bubble {
          position: relative;
        }

        /* Tail for message from the user */
        .user-message::before {
          content: '';
          position: absolute;
          bottom: 0px;
          right: -10px; /* Adjust as needed */
          width: 20px; /* Width of the wisp */
          height: 20px; /* Height of the wisp */
          background-color: #3182ce; /* Tailwind blue-500 */
          clip-path: polygon(0 100%, 50% 0, 100% 100%, 50% 50%);
          border-radius: 6px;
        }

        /* Tail for message from the other party */
        .other-message::before {
          content: '';
          position: absolute;
          bottom: 0;
          left: -10px; /* Adjust as needed */
          width: 20px; /* Width of the wisp */
          height: 20px; /* Height of the wisp */
          background-color: #e2e8f0; /* Tailwind gray-200 */
          clip-path: polygon(0 100%, 50% 0, 100% 100%, 50% 50%);
          border-radius: 6px;
        }
        `}
    </style>
  );
};
