import { useEffect, useState, Suspense, useMemo } from "react";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompute } from "../../../rtk/R&D/Compute/useCompute";
import { useUsers } from "../../../rtk/Users/useUsers";
import { determineRelevantStates } from "./utils";
import { ClosedActionCard } from "../UploadPortal/components";
import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { classNames, currencyFormatter } from "../utils";
import { XIcon } from "@heroicons/react/outline";
import { StateForms } from "./components/StateForms";
import {
  StateCreditsWelcomeMessage,
  QreBanner,
  LoadingState,
} from "./components";
import { useDataArray } from "../../../rtk/R&D/DataArray/useDataArray";
import { sumQreByState } from "./utils";

const buildStateComponentArray = ({ relevantStates, showAll }) => {
  return Object.keys(StateForms)
    .map((state, index) => {
      const stateObject = relevantStates.find((st) => st.state === state);
      if (stateObject === undefined && !showAll) return null;
      const credit =
        stateObject?.form?.credit === undefined
          ? 0
          : isNaN(stateObject?.form?.credit)
            ? 0
            : stateObject?.form?.credit;
      return {
        item: state,
        id: index,
        title: state,
        description: currencyFormatter.format(credit),
        extendedDescription:
          "Fill out the survey below to calculate your credit",
        complete: false,
        hideCloseIcon: true,
      };
    })
    .filter((a) => a !== null);
};

export const StateCredits = () => {
  const { engagementData, isLoadingEngagementData, isUpdatingEngagementData } =
    useEngagementData();
  const { user, isGettingUser } = useUsers();
  const { isComputingStateCredits } = useCompute();
  const [showAll, setShowAll] = useState(false);
  const [relevantStates, setRelevantStates] = useState([]);
  const [isFindingRelevantStates, setIsLoadingRelevantStates] = useState(false);
  const [isSumming, setIsSumming] = useState(false);
  const [open, setOpen] = useState(false);
  const [selectedID, setSelectedID] = useState(null);
  const [closeVisible, setCloseVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const {
    wageData,
    supplyData,
    contractData,
    computerLeaseData,
    projectData,
    isDownloadingFile,
  } = useDataArray();

  const qreSumObject = useMemo(() => {
    setIsSumming(true);
    const wage = sumQreByState(selectedID?.item, wageData);
    const supply = sumQreByState(selectedID?.item, supplyData);
    const contract = sumQreByState(selectedID?.item, contractData);
    const computer = sumQreByState(selectedID?.item, computerLeaseData);
    const project = sumQreByState(selectedID?.item, projectData);

    const stateObject = engagementData?.stateCredits?.find(
      (st) => st.state === selectedID?.title,
    );

    const credit = isNaN(stateObject?.form?.credit)
      ? 0
      : stateObject?.form?.credit;
    setIsSumming(false);

    return {
      wage,
      supply,
      contract,
      computer,
      project,
      credit,
      total: wage + supply + contract + computer + project,
    };
  }, [
    selectedID?.item,
    selectedID?.title,
    wageData,
    supplyData,
    contractData,
    computerLeaseData,
    projectData,
    engagementData?.stateCredits,
  ]);

  // Find Relevant States for Credit Computation
  useEffect(() => {
    setIsLoadingRelevantStates(true);
    if (!isLoadingEngagementData)
      setRelevantStates(
        determineRelevantStates({
          stateCredits: engagementData.stateCredits,
          wageData,
          supplyData,
          contractData,
          computerLeaseData,
        }),
      );
    setIsLoadingRelevantStates(false);
  }, [
    computerLeaseData,
    contractData,
    engagementData,
    isLoadingEngagementData,
    supplyData,
    wageData,
  ]);

  // TODO: Add loading state
  const stateComponentArray = useMemo(
    () =>
      buildStateComponentArray({
        relevantStates,
        showAll,
      }).filter((item) => item !== null),
    [relevantStates, showAll],
  );

  const isPartiallyLoading =
    isLoadingEngagementData ||
    isFindingRelevantStates ||
    isUpdatingEngagementData ||
    isComputingStateCredits ||
    isSumming ||
    isDownloadingFile ||
    isGettingUser ||
    !engagementData?.stateCredits;

  useEffect(() => {
    if (!isPartiallyLoading) {
      setTimeout(() => {
        setIsLoading(false);
      }, 400);
    } else {
      setIsLoading(true);
    }
  }, [isPartiallyLoading]);

  const LazyLoadedComponent = useMemo(() => {
    const noCreditState =
      Object.keys(StateForms).indexOf(selectedID?.title) === -1;
    const title = noCreditState ? "Other" : selectedID?.title;
    const component = StateForms[title];
    if (component === undefined) {
      return <div>Loading...</div>;
    } else {
      return component;
    }
  }, [selectedID?.title]);

  return (
    <main className="flex-1 overflow-auto">
      <div className="py-6">
        {/* <div className="flex flex-row items-center justify-between mx-auto max-w-7xl px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            {engagementData?.engagementName} State Credits
          </h1>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div> */}
        <div className="w-full px-4">
          <StateCreditsWelcomeMessage
            onClick={() => setShowAll(!showAll)}
            showAll={showAll}
            name={user.data.displayName}
            stateCount={relevantStates.length}
            loading={isLoading && selectedID?.title === undefined}
          />
          <div className="max-h-60vh grid w-full grid-cols-1 gap-4 overflow-y-auto py-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4">
            <div className="col-span-4 flex w-full">
              <div className="grid w-full grid-cols-2 gap-4 sm:grid-cols-2 sm:gap-5 lg:grid-cols-4">
                {isLoading && selectedID?.title === undefined
                  ? Array.from(Array(8)).map((item, index) => (
                      <motion.div
                        key={`upload-loading-item-${index}`}
                        className={
                          "flex w-full flex-col overflow-hidden rounded-lg border shadow-xl 2xl:min-w-270"
                        }
                      >
                        <motion.div
                          className={classNames(
                            "flex flex-1 flex-col items-center justify-center bg-white px-4 xl:py-4",
                          )}
                        >
                          <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                          <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                          <div className="mt-2 mb-2 h-2 w-2/3 animate-pulse rounded bg-sprxGrey"></div>
                          <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
                          <motion.button
                            className={classNames(
                              "animate-pulse bg-sprxClientPortalLightBlue hover:bg-sprxClientPortalDarkBlue",
                              "focus:ring-none mt-4 inline-flex w-full items-center justify-center rounded-xl border border-transparent px-3.5 py-2 text-xs font-medium leading-4 text-white shadow-sm focus:outline-none xl:w-3/4",
                            )}
                          ></motion.button>
                        </motion.div>
                      </motion.div>
                    ))
                  : stateComponentArray.map((e, index) => {
                      return (
                        <ClosedActionCard
                          key={e.id}
                          item={e}
                          open={open}
                          setOpen={setOpen}
                          selectedID={selectedID}
                          setSelectedID={setSelectedID}
                          closeVisible={closeVisible}
                          setCloseVisible={setCloseVisible}
                        />
                      );
                    })}
              </div>
            </div>
          </div>
        </div>
        <div></div>
      </div>
      {/*Modal component */}
      <AnimatePresence>
        {open && selectedID && (
          <Dialog
            className="relative z-40"
            static
            layoutId={selectedID.id}
            initial={{ opacity: 0 }}
            animate={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            as={motion.div}
            open={open}
            onClose={setOpen}
          >
            <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />

            <motion.div className="fixed inset-0 z-10 overflow-y-auto">
              <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
                {/*Modal content */}
                <Dialog.Panel className="relative min-h-94vh min-w-95vw max-w-95vw transform overflow-hidden rounded-lg bg-white px-4 text-left shadow-xl transition-all">
                  <motion.div>
                    {/*Modal title, description, and close button */}
                    {closeVisible && (
                      <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                        <motion.button
                          type="button"
                          whileHover={{ scale: 1.05 }}
                          whileTap={{ scale: 0.95 }}
                          className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                          onClick={() => {
                            setOpen(false);
                            setSelectedID(null);
                            setCloseVisible(false);
                          }}
                        >
                          <XIcon
                            className="h-6 w-6"
                            aria-hidden={selectedID.hideCloseIcon}
                          />
                        </motion.button>
                      </motion.div>
                    )}

                    <motion.div className="text-center sm:mt-5">
                      <Dialog.Title
                        as="h3"
                        className="p-4 text-left text-3xl font-medium leading-6 text-gray-900"
                      >
                        {selectedID.title}
                      </Dialog.Title>
                      <motion.div className="">
                        <motion.p className="px-4 py-2 text-left text-sm text-gray-500">
                          {selectedID?.extendedDescription}
                        </motion.p>
                      </motion.div>
                    </motion.div>
                    <motion.div className=" ">
                      {/*Modal main content */}
                      <Suspense fallback={<LoadingState />}>
                        <LazyLoadedComponent
                          state={selectedID.title}
                          isLoading={isLoading}
                          QreBanner={
                            <QreBanner
                              total={qreSumObject.total}
                              wage={qreSumObject.wage}
                              supply={qreSumObject.supply}
                              contract={qreSumObject.contract}
                              computer={qreSumObject.computer}
                              credit={qreSumObject.credit}
                              isLoading={isLoading}
                            />
                          }
                        />
                      </Suspense>
                    </motion.div>
                  </motion.div>
                </Dialog.Panel>
              </motion.div>
            </motion.div>
          </Dialog>
        )}
      </AnimatePresence>
    </main>
  );
};
