import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import _ from "lodash";
import { XIcon } from "@heroicons/react/outline";
import { useMemo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "../../../ui-components";
import { isDev, classNames } from "../../R&D/utils";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";

const getDefaultEditCompanyValues = (company) => {
  return {
    companyName: company?.companyName ?? "",
  };
};

const schema = yup.object().shape({
  companyName: yup.string().required("Please enter a name."),
});

export const EditCompanyForm = ({ open, setOpen, company }) => {
  const companyAPI = useCompanyData();

  const { control, formState, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultEditCompanyValues(company);
    }, [company]),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getDefaultEditCompanyValues(company));
    return () => {
      reset(getDefaultEditCompanyValues(company));
    };
  }, [company, reset]);

  const { errors, isValid, dirtyFields } = formState;

  const companyName = watch("companyName");

  const onSubmit = async (model) => {
    const { companyID } = company;

    const body = {
      companyName: model.companyName,
    };
    const updateCompanyResponse = await companyAPI.updateCompany({
      companyID,
      body,
    });
    if (isDev) console.log({ updateCompanyResponse });
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div
          className="relative z-40"
          layoutId={"1234"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0 min-w-1/3">
              {/*Modal content */}
              <motion.div className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-30vh w-1/3">
                <motion.div>
                  {/*Modal title, description, and close button */}
                  <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <form
                    className="mt-5 sm:flex sm:items-center sm:flex-col w-full"
                    onSubmit={handleSubmit((model) => onSubmit(model))}
                  >
                    <motion.div className="flex flex-col text-center sm:mt-5 w-full items-center justify-center">
                      <h3 className="text-3xl font-medium leading-6 text-gray-900 text-left p-4">
                        Edit Company
                      </h3>
                      <motion.div className="flex flex-col justify-center items-center w-full">
                        <Controller
                          name="companyName"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 w-full sm:max-w-xs text-left">
                              <label htmlFor="companyName">Company Name</label>
                              <input
                                {...field}
                                type="text"
                                name="companyName"
                                id="companyName"
                                required
                                autoComplete="companyName"
                                className="shadow-sm focus:ring-sprxTaxBlue focus:border-sprxTaxBlue block w-full sm:text-sm border-gray-300 rounded-md"
                                placeholder="Company Name"
                              />
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("companyName") ? (
                          <p className=" pl-2 text-sprxOrange">Invalid name</p>
                        ) : null}
                        <motion.div className="flex flex-row w-full justify-end items-end mt-6">
                          <motion.button
                            type="submit"
                            data-test="Save & Close"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            className={
                              "inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-none sm:text-sm" +
                              (!isValid
                                ? "bg-opacity-50 cursor-not-allowed"
                                : " hover:bg-sprxClientPortalDarkBlue")
                            }
                          >
                            {companyAPI.isUpdatingCompany ? (
                              <div className="flex flex-col justify-center items-center h-5 w-full ">
                                <p
                                  className={
                                    "animate-pulse h-2 w-full bg-gray-100 rounded-md"
                                  }
                                ></p>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </motion.button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </form>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
