import React, { useState } from "react";
import { FixedSizeList as List } from "react-window";

// Assuming each item in your array looks something like this
interface Item {
  ID: string; // Unique identifier
  // Other properties...
  [key: string]: any;
}

// CustomTBody is a custom element that is used to wrap the rows in the virtualized list
const CustomTBody = React.forwardRef<
  HTMLTableSectionElement,
  React.HTMLProps<HTMLTableSectionElement>
>(({ children, ...rest }, ref) => (
  <tbody ref={ref} {...rest}>
    {children}
  </tbody>
));
CustomTBody.displayName = "CustomTBody";

// VirtualizedListLayout is a custom element that is used to wrap the virtualized list
const VirtualizedListLayout = ({ children }) => {
  return (
    <div className="mt-8 flow-root">
      <div className="-my-2 overflow-x-auto">
        <div className="inline-block min-w-full py-2 px-4 align-middle">
          <div
            className="min-h-70vh overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg"
            id="virtualized-list-layout-parent"
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};

// VirtualizedListHeaders is a custom element that supplies the table with headers
interface VirtualizedListHeadersProps {
  headers: string[];
  selectAll: boolean;
  handleSelectAll: () => void;
}

const VirtualizedListHeaders: React.FC<VirtualizedListHeadersProps> = ({
  headers,
  selectAll,
  handleSelectAll,
}) => {
  const width = "w-1/" + headers.length;
  return (
    <div
      className="sticky top-0 flex bg-gray-100"
      id="virtualized-list-headers"
    >
      <div
        className={`ml-3 flex w-12 flex-row py-3.5 pl-32 text-left text-sm font-semibold text-gray-900 sm:pl-0`}
      >
        <div className={"pr-3 text-sm text-gray-500"}>
          <input
            type="checkbox"
            checked={selectAll}
            onChange={() => handleSelectAll()}
          />
        </div>
      </div>
      {headers.map((header: string) => {
        return (
          <div
            key={header}
            className={
              "py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-0 " +
              width
            }
          >
            {header}
          </div>
        );
      })}
    </div>
  );
};

// Row component for the virtualized list
interface RowProps {
  index: number;
  style: React.CSSProperties;
  data: {
    items: Item[];
    keys: string[];
    isChecked: (id: string) => boolean;
    handleCheckboxChange: (id: string) => void;
  };
}

const Row: React.FC<RowProps> = ({ index, style, data }) => {
  const { items, handleCheckboxChange, isChecked, keys } = data;
  const item = items[index];
  if (item === undefined) return null;
  const width = "w-1/" + keys.length;
  const checked = isChecked(item.ID);
  return (
    <div className={"flex flex-row"} style={style}>
      <div
        className={"whitespace-nowrap px-3 py-2 text-sm text-gray-500" + width}
      >
        <input
          type="checkbox"
          checked={checked}
          onChange={() => handleCheckboxChange(item.ID)}
        />
      </div>
      {keys.map((key) => {
        let val = item[key];
        if (key === "qre") {
          val = Math.round(val).toLocaleString("en-US", {
            style: "currency",
            currency: "USD",
            maximumFractionDigits: 0,
          });
        }
        return (
          <div key={key} className={"px-3 py-2 text-sm text-gray-500 " + width}>
            {val}
          </div>
        );
      })}
    </div>
  );
};

interface SearchBarProps {
  filter: string;
  setFilter: (filter: string) => void;
}

const SearchBar: React.FC<SearchBarProps> = ({ filter, setFilter }) => {
  return (
    <div className="mt-4 flex flex-col sm:mt-0">
      <p className={"mb-2 text-sm font-bold"}>Search</p>
      <input
        type="text"
        className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
        onChange={(e) => {
          setFilter(e.target.value);
        }}
        value={filter}
      />
    </div>
  );
};

interface DownloadButtonProps {
  checkedItems: Record<string, boolean>;
  onClickDownload: () => void;
  isLoading?: boolean;
}

const DownloadButton: React.FC<DownloadButtonProps> = ({
  checkedItems,
  onClickDownload,
  isLoading,
}) => {
  return (
    <div className="flex h-10 flex-row">
      <div className="px-4 py-2 text-sm font-medium text-gray-700">
        {Object.values(checkedItems).filter((item) => item).length} items
        selected
      </div>
      <button
        disabled={isLoading}
        onClick={onClickDownload}
        className="ml-4 inline-flex w-24 items-center rounded-md border border-transparent bg-indigo-600 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-indigo-700 focus:outline-none"
      >
        {isLoading ? (
          <div className="ml-2 mt-2 mb-2 h-2 w-12 animate-pulse rounded bg-white"></div>
        ) : (
          "Download"
        )}
      </button>
    </div>
  );
};

// Main Component
interface EmployeeActivitiesProps {
  items: Item[];
  keys: object;
  onClickDownload: (items?: string[]) => void;
  isLoading?: boolean;
}

export const VirtualizedList: React.FC<EmployeeActivitiesProps> = ({
  items,
  keys,
  onClickDownload,
  isLoading,
}) => {
  const [checkedItems, setCheckedItems] = useState<Record<string, boolean>>({});
  const [selectAll, setSelectAll] = useState(false);
  const [filter, setFilter] = useState("");

  const handleCheckboxChange = (id: string) => {
    setCheckedItems((prevState) => ({
      ...prevState,
      [id]: !prevState[id],
    }));
  };

  const handleSelectAll = () => {
    const newCheckedItems = {};
    items.forEach((item) => (newCheckedItems[item.ID] = !selectAll));
    setCheckedItems(newCheckedItems);
    setSelectAll(!selectAll);
  };

  const isChecked = (id: string) => checkedItems[id] === true;

  return (
    <div>
      <div className="flex items-center justify-between px-4 pt-4">
        <SearchBar filter={filter} setFilter={setFilter} />
        <DownloadButton
          onClickDownload={() => {
            let selectedItems = [];
            for (const [key, value] of Object.entries(checkedItems)) {
              if (value === true) selectedItems.push(key);
            }
            onClickDownload(selectedItems);
          }}
          checkedItems={checkedItems}
          isLoading={isLoading}
        />
      </div>
      <VirtualizedListLayout>
        <VirtualizedListHeaders
          headers={Object.values(keys)}
          selectAll={selectAll}
          handleSelectAll={() => handleSelectAll()}
        />
        <List
          height={640}
          itemCount={items.length}
          itemSize={50}
          width={"100%"}
          itemData={{
            items: items.filter((item) => {
              for (const key of Object.keys(keys)) {
                if (
                  item[key]
                    .toString()
                    .toLowerCase()
                    .includes(filter.toLowerCase())
                )
                  return true;
              }
              return false;
            }),
            checkedItems,
            keys: Object.keys(keys),
            isChecked,
            handleCheckboxChange,
          }}
        >
          {Row}
        </List>
      </VirtualizedListLayout>
    </div>
  );
};
