import { DataArrayProvider } from "../../../rtk";
import { StateCredits } from "./StateCredits";

export const StateCreditsContainer = () => {
  return (
    <DataArrayProvider>
      <StateCredits />
    </DataArrayProvider>
  );
};
