import {
  ImportType,
  NamespaceType,
} from "../../../../ui-components/FlatfileSpace";
import * as constants from "../../constants/data";

export const getNamespaceType = (type: ImportType): NamespaceType => {
  switch (type) {
    case constants.WAGES:
      return constants.WAGES_NAMESPACE;
    case constants.WAGES_REVIEW:
      return constants.WAGES_REVIEW_NAMESPACE;
    case constants.WAGES_ALLOCATIONS:
      return constants.ALLOCATIONS_NAMESPACE;

    case constants.CONTRACT:
      return constants.CONTRACT_NAMESPACE;
    case constants.CONTRACT_REVIEW:
      return constants.CONTRACT_REVIEW_NAMESPACE;
    case constants.CONTRACT_ALLOCATIONS:
      return constants.ALLOCATIONS_NAMESPACE;

    case constants.SUPPLY:
      return constants.SUPPLY_NAMESPACE;
    case constants.SUPPLY_REVIEW:
      return constants.SUPPLY_REVIEW_NAMESPACE;
    case constants.SUPPLY_ALLOCATIONS:
      return constants.ALLOCATIONS_NAMESPACE;

    case constants.COMPUTER:
      return constants.COMPUTER_NAMESPACE;
    case constants.COMPUTER_REVIEW:
      return constants.COMPUTER_REVIEW_NAMESPACE;
    case constants.COMPUTER_ALLOCATIONS:
      return constants.ALLOCATIONS_NAMESPACE;

    case constants.PROJECTS:
      return constants.PROJECTS_NAMESPACE;
    case constants.PROJECTS_REVIEW:
      return constants.PROJECTS_REVIEW_NAMESPACE;

    default:
      throw Error("Invalid import type");
  }
};
