import { FlagIcon } from "./FlagIcon";
import { Tag } from "./Tag";
import { motion } from "framer-motion";
import { OpenActionCard } from "../../../../ui-components/OpenActionCard";
import { useState } from "react";
import { ClockIcon, LinkIcon } from "@heroicons/react/outline";
import { ExpandedTrackerCardContainer } from "../ExpandedTrackerCard/ExpandedTrackerCardContainer";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useUsers } from "../../../../rtk/Users/useUsers";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../../auth/rtk/userSlice";

export const EngagementCard: React.FC<{ engagement: any }> = ({
  engagement,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const engagementDataAPI = useEngagementData();
  const dispatch = useDispatch();
  const usersAPI = useUsers();
  const user = usersAPI.user;
  const isSalesUser = user.role === "sales";

  const handleSetActiveClick = async () => {
    const body = {
      activeEngagementID: engagement.engagementID,
      engagementType: "RD",
    };
    await usersAPI.updateTaxUser({
      userID: user.userID,
      body: body,
    });
    const updatedUser = await usersAPI.getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  return (
    <div className="relative z-10">
      <motion.div
        className="relative mb-2 rounded-lg border bg-white p-4 shadow-md"
        whileHover={{ scale: 1.02 }}
        onClick={() => setIsOpen(true)}
      >
        {engagement.flagColor ? (
          <FlagIcon
            color={
              engagement.flagColor === "yellow"
                ? "#FFD700"
                : engagement.flagColor === "blue"
                  ? "#4169E1"
                  : "#FF0000"
            }
          />
        ) : null}
        <h3 className="pr-2 text-lg font-semibold">{engagement.name}</h3>
        {engagement.status !== "Upload" ? (
          <p className="my-2 text-lg font-bold">
            ${Math.round(engagement?.amount ?? 0).toLocaleString()}
          </p>
        ) : (
          <div className="my-2" />
        )}
        {engagement.tags.length > 0 ? (
          <div className="mb-2 flex flex-wrap gap-2">
            {engagement.tags.map((tag, index) => (
              <Tag key={index} {...tag} />
            ))}
          </div>
        ) : null}
        {engagement.reports && (
          <div className="flex flex-wrap gap-2">
            {engagement.reports.map((report, index) => (
              <span
                key={index}
                className="rounded bg-gray-100 px-2 py-1 text-xs"
              >
                {report}
              </span>
            ))}
          </div>
        )}
      </motion.div>
      <OpenActionCard
        open={isOpen}
        setOpen={setIsOpen}
        selectedID={{
          title: (
            <div className="flex flex-row items-center">
              {!isSalesUser ? (
                <button
                  onClick={async () => {
                    await handleSetActiveClick();
                    // Open "./"
                    window.open(`./`, "_blank");
                  }}
                >
                  <LinkIcon className="mr-4 h-8 w-8" />
                </button>
              ) : null}
              <p className="mr-4">{engagement.name}</p>
              {engagement.flagColor ? (
                <ClockIcon
                  className="ml-2 h-8 w-8"
                  style={{
                    color:
                      engagement.flagColor === "yellow"
                        ? "#FFD700"
                        : engagement.flagColor === "blue"
                          ? "#4169E1"
                          : "#FF0000",
                  }}
                />
              ) : null}
            </div>
          ),
          description: {
            Upload: "This engagement is being uploaded.",
            Review: "This engagement is currently under review.",
            Validation: "This engagement is currently under validation.",
            Completed: "This engagement has been completed.",
          }[engagement.status],
          component: <ExpandedTrackerCardContainer engagement={engagement} />,
        }}
        setSelectedID={() => {}}
        closeVisible={true}
        setCloseVisible={() => {}}
        setCommentOpen={undefined}
        disableOnClickOutsideClose={false}
        secondaryPanel={false}
        medium={true}
        hideEngagementButton={
          <motion.div className="absolute top-0 right-10 hidden pt-4 pr-4 sm:block">
            <motion.button
              type="button"
              whileHover={{ scale: 1.05 }}
              whileTap={{ scale: 0.95 }}
              className="rounded-xl bg-gray-100 px-2 text-center text-gray-400 hover:bg-gray-200 hover:text-gray-500 focus:outline-none"
              onClick={async () => {
                await engagementDataAPI?.updateEngagementData({
                  engagementID: engagement.engagementID,
                  body: {
                    tracker: {
                      ...engagement.tracker,
                      hidden: engagement.status === "Hidden" ? false : true,
                    },
                  },
                });
                setIsOpen(false);
              }}
            >
              {engagementDataAPI?.isUpdatingEngagementData ? (
                <div>
                  <div
                    className={`my-2 h-2 animate-pulse rounded bg-sprxGrey w-${
                      engagement.status === "Hidden" ? "12" : "8"
                    }`}
                  ></div>
                </div>
              ) : engagement.status === "Hidden" ? (
                "unhide"
              ) : (
                "hide"
              )}
            </motion.button>
          </motion.div>
        }
      />
    </div>
  );
};
