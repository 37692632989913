import React, { useEffect, useMemo, useState } from "react";
import _ from "lodash";
import { classNames } from "../../R&D/utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon } from "@heroicons/react/outline";
import { Button } from "../../../ui-components";
import Select from "react-select";
import { states } from "../../../ui-components";
import { getCreateUserFormShape, getCreateUserFormDefaultFields } from "./util";
import { Company } from "../types/company";
import { User } from "../types/user";
import { Engagement } from "../../R&D/types/engagement";
import { CreateUserModel } from "../types/user";
import { CreateFormWrapper } from "../components/CreateFormWrapper";

const USER_ROLES = [
  "client",
  "partner",
  "modeler",
  "certifier",
  "sales",
  "admin",
];

interface CreateUserFormProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onCreate: (model: any) => Promise<void>;
  companies: Company[];
  engagements: Engagement[];
  user: User;
  isAdmin: boolean;
  isCreatingUser: boolean;
  company: any;
}

export const CreateUserForm = ({
  open,
  setOpen,
  onCreate,
  companies,
  engagements,
  user,
  isAdmin,
  isCreatingUser,
  company,
}: CreateUserFormProps) => {
  const schema = getCreateUserFormShape();
  const { control, formState, handleSubmit, setValue, watch } = useForm({
    mode: "onChange",
    defaultValues: useMemo(() => {
      return getCreateUserFormDefaultFields();
    }, []),
    resolver: yupResolver(schema),
  });

  const { errors } = formState;
  const { isValid, dirtyFields } = formState;
  const roleType = watch("role");

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const onClickSend = async (model: CreateUserModel) => {
    const displayName = model.fullName.split(" ")[0];

    let companyName: string = "";
    let engagementType: string = "";
    if (model.role === "certifier") {
      companyName = "Certifiers";
      engagementType = "EN";
    } else if (model.role === "modeler") {
      companyName = "Modelers";
      engagementType = "EN";
    } else {
      companyName = company?.companyName;
    }
    const companyID = companies.find(
      (c) => c.companyName === companyName,
    ).companyID;

    onCreate({
      ...model,
      companyName: companyName,
      states: model.states,
      engagementType,
      displayName,
      companyID: companyID,
      baseURL: "portal.sprx.tax",
    });
  };

  return (
    <CreateFormWrapper
      open={open}
      setOpen={setOpen}
      type={`User for ${company?.companyName}`}
    >
      <form
        noValidate
        className="sm:flex sm:flex-col sm:items-center"
        onSubmit={handleSubmit((model) => onClickSend(model))}
      >
        <Controller
          name="fullName"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="fullName">
                Name <span className="text-sprxOrange">*</span>
              </label>
              <input
                {...field}
                type="text"
                name="fullName"
                id="fullName"
                required
                autoComplete="fullName"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                placeholder="Jane Doe"
              />
              {errors?.fullName && (
                <p className="pl-2 text-sprxOrange">Please enter a name</p>
              )}
            </div>
          )}
        />
        <Controller
          name="role"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="role">
                User Role <span className="text-sprxOrange">*</span>
              </label>
              <select
                {...field}
                id="role"
                name="role"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
              >
                <option value="" disabled>
                  Select an Option
                </option>
                {USER_ROLES.map((role) => {
                  return (
                    <option value={role} key={role}>
                      {role}
                    </option>
                  );
                })}
              </select>
              {errors?.role && (
                <p className="pl-2 text-sprxOrange">Please select a role</p>
              )}
            </div>
          )}
        />
        {roleType === "certifier" ? (
          <Controller
            name="states"
            control={control}
            render={({ field }) => (
              <div className="mt-4 w-full sm:max-w-xs">
                <label htmlFor="states">
                  States <span className="text-sprxOrange">*</span>
                </label>

                <div className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm">
                  <Select
                    {...field}
                    onChange={(val) => setValue("states", val as any)}
                    options={states.map((s) => ({
                      value: s.value,
                      label: s.label,
                    }))}
                    isMulti
                  />
                  {errors?.states && (
                    <p className="pl-2 text-sprxOrange">Invalid States</p>
                  )}
                </div>
              </div>
            )}
          />
        ) : (
          ""
        )}
        <Controller
          name="email"
          control={control}
          render={({ field }) => (
            <div className="mt-4 w-full sm:max-w-xs">
              <label htmlFor="email">
                Email <span className="text-sprxOrange">*</span>
              </label>
              <input
                {...field}
                type="email"
                name="email"
                id="email"
                required
                autoComplete="email"
                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                placeholder="you@example.com"
              />
              {errors?.email && (
                <p className="pl-2 text-sprxOrange">Please enter an email</p>
              )}
            </div>
          )}
        />

        <motion.div className="mt-12 flex w-full flex-row items-end justify-end">
          <motion.button
            type="submit"
            data-test="Save & Close"
            whileHover={{ scale: 1.05 }}
            whileTap={{ scale: 0.95 }}
            disabled={_.isEmpty(dirtyFields) || !isValid}
            className={
              "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
              (!isValid
                ? "cursor-not-allowed bg-opacity-50"
                : " hover:bg-sprxClientPortalDarkBlue")
            }
          >
            {isCreatingUser ? (
              <div className="flex h-5 w-full flex-col items-center justify-center">
                <p
                  className={"h-2 w-full animate-pulse rounded-md bg-gray-100"}
                ></p>
              </div>
            ) : (
              "Create"
            )}
          </motion.button>
        </motion.div>
      </form>
    </CreateFormWrapper>
  );
};
