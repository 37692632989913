export const ACTIVITIES = [
  "Ideation sessions",
  "Define requirements",
  "Design tasks",
  "Design review",
  "Build prototypes or models",
  "Testing prototypes or models",
  "Program software",
  "Testing software",
  "Laboratory testing",
  "Record observations",
  "Attend design meetings",
  "Supervise research activities",
  "Purchase materials / supplies",
  "Engineering activities",
];
