import { useCallback, useEffect, useState } from "react";
import { useDownloadFileMutation } from "../../../../api/fileAPI";
import { translateImportTypeToFileName } from "../../utils";
import { getCostKey } from "../../utils";

export const useData = (engagement: any) => {
  const [QRES, setQRES] = useState<any>({});

  const [downloadFile, { isLoading: isDownloadingFile }] =
    useDownloadFileMutation();

  const getParams = useCallback(
    (type: string, action: string) => {
      if (engagement === undefined) return;
      const { s3Bucket, engagementID } = engagement;
      const bucket = s3Bucket.name;
      const fileSlug = translateImportTypeToFileName(type);
      const key = `${engagementID}/uploadFiles/${engagementID}${fileSlug}.json`;

      return {
        bucket,
        action,
        key,
        type: "application/json",
      };
    },
    [engagement]
  );

  useEffect(() => {
    let isMounted = true;
    const abortController = new AbortController();
    async function getData(body: any) {
      if (isMounted === false) return [];
      try {
        const response = await downloadFile({
          ...body,
          signal: abortController.signal,
        });
        if (!("data" in response)) return [];
        return response.data;
      } catch (err) {
        return [];
      }
    }

    async function fetchData() {
      const items = ["Wages", "Supply", "Contract Research", "Computer Lease"];
      for (const item of items) {
        if (!isMounted) break;
        try {
          const data = await getData(getParams(item, "getObject"));
          if (!isMounted) break;
          const cost = data.reduce(
            (acc: any, curr: any) => acc + Math.round(curr[getCostKey(item)]),
            0
          );
          const qre = data.reduce(
            (acc: any, curr: any) => acc + Math.round(curr?.qre ?? 0),
            0
          );
          setQRES((prev: any) => ({ ...prev, [item]: { cost, qre } }));
        } catch (err) {
          console.error(err);
        }
      }
    }

    fetchData();

    return () => {
      isMounted = false;
      abortController.abort();
      setQRES({});
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (isDownloadingFile) return undefined;

  return {
    Total: {
      cost: Object.values(QRES).reduce((a: any, b: any) => a + b.cost, 0),
      qre: Object.values(QRES).reduce((a: any, b: any) => a + b.qre, 0),
    },
    ...QRES,
  };
};
