import { motion } from "framer-motion";
import { useEffect, useState } from "react";
import { CreateUserForm } from "../AdminHome/Users/CreateUserForm";
import { ConfirmationModal } from "./ConfirmationModal";
import { useDeleteUserMutation } from "../../api/userAPI";
import { EditUserForm } from "../AdminHome/Users/EditUserForm";
import { useCompanyData } from "../../rtk/CompanyData/useCompanyData";
import { useEngagementData } from "../../rtk/R&D/EngagementData/useEngagementData";
import { useEnergyEngagement } from "../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../rtk/Users/useUsers";
import { isDev } from "../R&D/utils";
import { ListViewLoading } from "../../ui-components/ListViewLoading";
import { useDataSpaces } from "../../rtk/DataSpaces/useDataSpaces";
import { User, CreateUserModel } from "../AdminHome/types/user";

const filterRelevantUsers = (users, user) => {
  switch (user.role) {
    case "client":
      return [
        ...users?.filter((u) => {
          return u.companyID === user.companyID;
        }),
      ];
    case "modeler":
      return [
        ...users?.filter((u) => {
          return u.companyID === user.companyID;
        }),
      ];
    case "certifier":
      return [
        ...users?.filter((u) => {
          return u.companyID === user.companyID;
        }),
      ];
    case "partner":
      return users?.filter((u) => {
        return (
          u.companyID === user.companyID &&
          u.data.activeEngagementID === user.data.activeEngagementID
        );
      });
    case "admin":
      return users;
    default:
      console.error("Undefined user role");
      return [];
  }
};

export const TeamPage = () => {
  const { user, users, createUser, isListingUsers, isCreatingUser } =
    useUsers();
  const { dataSpace, isGettingDataSpace } = useDataSpaces();

  const {
    engagementData,
    isLoadingEngagementData,
    engagementList,
    isListingEngagements,
  } = useEngagementData();

  const energyEngagementAPI = useEnergyEngagement();

  const { companies, isListingCompanies } = useCompanyData();
  const [filter, setFilter] = useState<string>("");
  const [filteredUsers, setFilteredUsers] = useState<User[]>([]);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [open, setOpen] = useState<boolean>(false);
  const [openEdit, setOpenEdit] = useState<boolean>(false);
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  const [deleteUser] = useDeleteUserMutation();
  useEffect(() => {
    const filteredUsers = user.role === "admin" ? getUsersForAdmin() : users;
    setFilteredUsers(filterRelevantUsers(filteredUsers, user));
  }, [users, engagementData, user]);

  const getUsersForAdmin = () => {
    const type = user.engagementType;
    switch (type) {
      case "DS":
        return users?.filter((u) => u.companyID === dataSpace?.companyID);
      case "EN":
        return users?.filter(
          (u) => u.companyID === energyEngagementAPI?.engagement?.companyID,
        );
      default:
        return users?.filter((u) => u.companyID === engagementData?.companyID);
    }
  };

  const onCreate = async (body: CreateUserModel) => {
    const createUserRespone = await createUser({ body });
    if (isDev) console.log({ createUserRespone });
    setOpen(false);
  };

  const onConfirmDelete = async () => {
    const userToDelete = filteredUsers[editIndex];
    const { userID } = userToDelete;
    const deleteResponse = await deleteUser({ userID });
    if (isDev) console.log({ deleteResponse });
    setConfirmOpen(false);
    setEditIndex(-1);
  };

  const isLoading =
    isListingUsers ||
    isLoadingEngagementData ||
    isListingEngagements ||
    user === undefined ||
    isListingCompanies ||
    isGettingDataSpace;

  if (isLoading) {
    return <ListViewLoading />;
  }

  const getTitle = () => {
    if (user?.isUsingDataSpace === true || user?.engagementType === "DS")
      return `${dataSpace.companyName} Team Members`;
    else if (user?.engagementType === "EN")
      return `${energyEngagementAPI?.engagement?.companyName} Team Members`;
    return `${engagementData.companyName} Team Members`;
  };

  const getCompany = () => {
    if (user?.isUsingDataSpace === true || user?.engagementType === "DS") {
      return companies.find((c) => c.companyID === dataSpace.companyID);
    } else if (user?.engagementType === "EN") {
      return companies.find(
        (c) => c.companyID === energyEngagementAPI?.engagement?.companyID,
      );
    }
    return companies.find((c) => c.companyID === engagementData?.companyID);
  };

  return (
    <>
      <main className="h-100vh flex-1">
        <div className="flex-1">
          <div className="mt-2 py-4">
            {/*Top bar and logo */}
            <div className="mx-auto flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
              <h1 className="text-2xl font-semibold text-gray-900">
                {`${getTitle()}`}
              </h1>
              <img
                style={{ width: 85, height: 36 }}
                src={"assets/images/logos/Logo@SVG.svg"}
                alt="SPRX.tax"
              />
            </div>
            {/* Team member table */}
            <div className="mt-2 px-4 sm:px-6 lg:px-8">
              <div className="items-between flex flex-row items-end justify-between">
                <div className="mt-4 flex flex-col sm:mt-0">
                  <p className={"mb-2 text-sm font-bold"}>Search User</p>
                  <motion.input
                    type="text"
                    className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                    onChange={(e) => {
                      setFilter(e.target.value);
                    }}
                    value={filter}
                  />
                </div>
                <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
                  {user?.role === "partner" || user?.role === "admin" ? (
                    <motion.button
                      type="button"
                      className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                      whileHover={{ scale: 1.1 }}
                      whileTap={{ scale: 0.95 }}
                      onClick={() => setOpen(true)}
                    >
                      Add user
                    </motion.button>
                  ) : null}
                </div>
              </div>
              <div className="mt-8 flow-root">
                <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
                  <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                    <div className="max-h-160 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                      <table className="min-w-full divide-y divide-gray-300">
                        <thead className="sticky top-0 bg-gray-50">
                          <tr>
                            <th
                              scope="col"
                              className="py-3.5 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-6"
                            >
                              Name
                            </th>
                            <th
                              scope="col"
                              className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                            >
                              Email
                            </th>
                            <th
                              scope="col"
                              className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                            >
                              Role
                            </th>
                            {/* <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Edit</span>
                            </th>
                            <th
                              scope="col"
                              className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                            >
                              <span className="sr-only">Delete</span>
                            </th> */}
                          </tr>
                        </thead>
                        <tbody className="divide-y divide-gray-200 bg-white">
                          {filteredUsers?.length === 0 ? (
                            <tr>
                              <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                No Users Found
                              </td>
                            </tr>
                          ) : (
                            <>
                              {" "}
                              {filteredUsers
                                ?.filter((person) => {
                                  const k = filter.toLowerCase();
                                  return person?.fullName
                                    ?.toLowerCase()
                                    .includes(k);
                                })
                                ?.map((person, index) => (
                                  <tr key={person.email}>
                                    <td className="w-full max-w-0 py-4 pl-4 pr-3 text-sm font-medium text-gray-900 sm:w-auto sm:max-w-none sm:pl-6">
                                      {person.fullName}
                                      <dl className="font-normal lg:hidden">
                                        <dt className="sr-only sm:hidden">
                                          Email
                                        </dt>
                                        <dd className="mt-1 truncate text-gray-500 sm:hidden">
                                          {person.email}
                                        </dd>
                                      </dl>
                                    </td>
                                    <td className="hidden px-3 py-4 text-sm text-gray-500 sm:table-cell">
                                      {person.email}
                                    </td>
                                    <td className="px-3 py-4 text-sm text-gray-500">
                                      {person.role}
                                    </td>
                                    {/* {user?.role === "admin" ? (
                                  <td className="py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                                    <motion.button
                                      className="text-sprxClientPortalLightBlue hover:text-sprxClientPortalDarkBlue"
                                      whileHover={{ scale: 1.05 }}
                                      whileTap={{ scale: 0.95 }}
                                      onClick={async () => {
                                        setEditIndex(index);
                                        setOpenEdit(true);
                                      }}
                                    >
                                      Edit
                                      <span className="sr-only">
                                        , {person.fullName}
                                      </span>
                                    </motion.button>
                                  </td>
                                ) : null} */}
                                  </tr>
                                ))}
                            </>
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </main>
      <ConfirmationModal
        onConfirm={onConfirmDelete}
        onReject={() => {
          setEditIndex(-1);
          setConfirmOpen(false);
        }}
        open={confirmOpen}
        setOpen={setConfirmOpen}
        title={"Delete User"}
        description={"Are you sure you want to delete this user?"}
        confirmButtonText={"Delete"}
        rejectButtonText={"Cancel"}
      />
      <CreateUserForm
        isCreatingUser={isCreatingUser}
        user={user}
        open={open}
        setOpen={setOpen}
        onCreate={onCreate}
        engagements={engagementList}
        company={getCompany()}
        companies={companies}
        isAdmin={false}
      />
      <EditUserForm
        open={openEdit}
        setOpen={setOpenEdit}
        user={filteredUsers?.length > 0 ? filteredUsers[editIndex] : {}}
      />
    </>
  );
};
