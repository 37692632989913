import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { useUsers } from "../../../rtk/Users/useUsers";
import { OperationsPortal } from "./OperationsPortal";
import { SprxLoading } from "../../../ui-components";

export const OperationsPortalContainer = ({ setViewEngDetail }) => {
  const energyEngagementAPI = useEnergyEngagement();
  const usersAPI = useUsers();

  if (
    !energyEngagementAPI ||
    !usersAPI ||
    energyEngagementAPI.engagementLoading
  ) {
    return (
      <div className="flex h-72vh flex-auto items-center justify-center">
        <div className="flex w-1/2 items-center justify-center">
          <SprxLoading />
        </div>
      </div>
    );
  }

  return (
    <OperationsPortal
      engagement={energyEngagementAPI.engagement}
      setViewEngDetail={setViewEngDetail}
    />
  );
};
