import React, { useState } from "react";
import { useCompanyData } from "../../../../rtk/CompanyData/useCompanyData";
import { useUsers } from "../../../../rtk/Users/useUsers";

import { Selector } from "../../../../ui-components/Selector";
interface FilterProps {
  setFilter: (value: any) => void;
  filter: any;
}

export const Filter = ({ setFilter, filter }: FilterProps) => {
  const [salesRepFilter, setSalesRepFilter] = useState<string>("");
  const [companyFilter, setCompanyFilter] = useState<string>("");
  const companyAPI = useCompanyData();
  const usersAPI = useUsers();
  const dateFilters = ["Created Date", "Due Date"];
  const renderedCompanies = companyAPI?.companies
    ?.filter((company: any) => {
      return company.companyName
        .toLowerCase()
        .includes(companyFilter.toLowerCase());
    })
    .slice(0, 5);

  // Push user to the top of the list if they are a sales rep
  const { user, users } = usersAPI;
  const isSalesRep = user?.role === "sales";
  const renderedUsers = [
    ...(isSalesRep ? [user] : []),
    ...users
      ?.filter((otherRep: any) => {
        if (otherRep.userID === user.userID) return false;
        return (
          otherRep?.fullName
            ?.toLowerCase()
            .includes(salesRepFilter.toLowerCase()) &&
          otherRep?.role === "sales"
        );
      })
      .slice(0, isSalesRep ? 4 : 5),
  ];

  const onSelectDatesFilter = (option) => {
    setFilter({ ...filter, datesFilterType: option });
  };
  return (
    <div className="flex flex-col items-start justify-start">
      <Selector
        options={dateFilters}
        selectedOption={filter.datesFilterType}
        onSelectClick={onSelectDatesFilter}
      />
      <div className="flex flex-row items-center my-2">
        <p className="mr-2 w-10 text-sm">Start</p>
        <input
          type="date"
          value={filter.dates.start}
          className="p-1 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={(e) => {
            setFilter((prev: any) => ({
              ...prev,
              dates: {
                ...filter.dates,
                start: e.target.value,
              },
            }));
          }}
        />
      </div>
      <div className="flex flex-row items-center my-2">
        <p className="mr-2 w-10 text-sm">End</p>
        <input
          type="date"
          value={filter.dates.end}
          className="p-1 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={(e) => {
            setFilter((prev: any) => ({
              ...prev,
              dates: {
                ...filter.dates,
                end: e.target.value,
              },
            }));
          }}
        />
      </div>

      <div className="w-full border-t border-gray-300 my-1"></div>
      <>
        <p className="font-bold my-2">Sales Representative</p>
        <input
          type="text"
          placeholder="Name"
          className="p-1 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
          onChange={(e) => setSalesRepFilter(e.target.value)}
          value={salesRepFilter}
        />
        <div className="my-2">
          {renderedUsers?.map((rep: any) => (
            <FilterCheckbox
              key={rep.userID}
              id={rep.userID}
              name={rep.fullName}
              checked={filter.reps.includes(rep.userID)}
              onChange={(e) => {
                // Remove the rep from the filter if it is already in the filter
                if (filter.reps.includes(rep.userID)) {
                  setFilter((prev: any) => ({
                    ...prev,
                    reps: filter.reps.filter((f: any) => f !== rep.userID),
                  }));
                } else {
                  setFilter((prev: any) => ({
                    ...prev,
                    reps: [...filter.reps, rep.userID],
                  }));
                }
              }}
            />
          ))}
        </div>
        <div className="w-full border-t border-gray-300 my-1"></div>
      </>
      <p className="font-bold my-2">Company</p>
      <input
        type="text"
        placeholder="Name"
        className="p-1 border rounded-lg border-gray-300 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        onChange={(e) => setCompanyFilter(e.target.value)}
        value={companyFilter}
      />
      <div className="my-2">
        {renderedCompanies?.map((company: any) => (
          <FilterCheckbox
            key={company.companyID}
            id={company.companyID}
            name={company.companyName}
            checked={filter.companies.includes(company.companyID)}
            onChange={(e) => {
              // Remove the company from the filter if it is already in the filter
              if (filter.companies.includes(company.companyID)) {
                setFilter((prev: any) => ({
                  ...prev,
                  companies: filter.companies.filter(
                    (f: any) => f !== company.companyID
                  ),
                }));
              } else {
                setFilter((prev: any) => ({
                  ...prev,
                  companies: [...filter.companies, company.companyID],
                }));
              }
            }}
          />
        ))}
      </div>
    </div>
  );
};

export const FilterCheckbox = ({
  id,
  name,
  checked,
  onChange,
}: {
  id: string;
  name: string;
  checked: boolean;
  onChange: (e: any) => void;
}) => (
  <div className="flex items-center">
    <input
      type="checkbox"
      id={id}
      name={name}
      checked={checked}
      className="h-4 w-4 text-indigo-600 border-gray-300 rounded "
      onChange={onChange}
    />
    <label htmlFor={id} className="ml-2 block text-sm text-gray-900">
      {name}
    </label>
  </div>
);
