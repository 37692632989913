export const formatImportFileName = (metadata, userName) => {
    let filename = metadata?.$meta?.filename?.includes("#")
      ? metadata?.$meta?.filename.substr(
          0,
          metadata?.$meta?.filename?.lastIndexOf("#")
        )
      : metadata?.$meta?.filename;
    
    if (filename === undefined){
        filename = `${userName} ${new Date()
            .toLocaleDateString()
            .split("/")
            .join("-")}.csv`;
    };
    return filename;
};