import { Dispatch, SetStateAction } from "react";
import { ISpace, useSpace } from "@flatfile/react";
import { Cookies } from "react-cookie";
import config from "../api/_config";
import * as React from "react";
import "./FlatfileSpace.css";

const flatfileEnv: string = process.env.REACT_APP_FLATFILE_ENV || "";
const flatfilePk: string = process.env.REACT_APP_FLATFILE_KEY || "";
const cookies = new Cookies();

const spaceProps: ISpace = {
  name: "Embedded Space",
  publishableKey: flatfilePk,
  environmentId: flatfileEnv,
};

const Space = ({
  engagementID,
  companyName,
  namespace,
  type,
  name,
  onCloseCallback,
}: {
  type: ImportType;
  namespace: NamespaceType;
  companyName: string;
  engagementID: string;
  name: string;
  setShowSpace: Dispatch<SetStateAction<boolean>>;
  onCloseCallback?: () => Promise<void>;
}) => {
  const space = useSpace({
    ...spaceProps,
    displayAsModal: true,
    spaceBody: {
      name: "Intelligent Import",
      namespace,
    },
    userInfo: {
      companyName,
      name,
      companyId: engagementID,
      userId: type,
    },
    sidebarConfig: {
      showSidebar: false,
    },
    themeConfig: {
      root: {
        primaryColor: "blue",
      },
      sidebar: {
        logo: "https://images.ctfassets.net/hjneo4qi4goj/gL6Blz3kTPdZXWknuIDVx/7bb7c73d93b111ed542d2ed426b42fd5/flatfile.svg",
      },
    },
    closeSpace: {
      operation: "submitActionFg",
      onClose: async () => {
        if (onCloseCallback) onCloseCallback();
      },
    },
  });
  return space;
};

type FlatfileSpaceProps = {
  showSpace: boolean;
  setShowSpace: Dispatch<SetStateAction<boolean>>;
  namespace: NamespaceType;
  engagementID: string;
  companyName: string;
  type: ImportType;
  name: string;
  onCloseCallback: () => Promise<void>;
};

export const FlatfileSpace: any = (props: FlatfileSpaceProps): any => {
  const {
    showSpace,
    setShowSpace,
    namespace,
    engagementID,
    companyName,
    type,
    name,
    onCloseCallback,
  } = props;

  if (!showSpace) return null;

  if (flatfileEnv === "" || flatfilePk === "") {
    console.error("Flatfile environment variables not set!!");
    return null;
  }

  const sessionID = cookies.get("session-id");
  const URL = config.apiGateway.URL;
  const formattedName = `${name}%20${sessionID}%20${URL}`;

  //   position: fixed;
  return (
    <div className="z-1000 w-100% absolute inset-0 h-100vh">
      <Space
        namespace={namespace}
        setShowSpace={setShowSpace}
        engagementID={engagementID}
        companyName={companyName}
        type={type}
        name={formattedName}
        onCloseCallback={onCloseCallback}
      />
    </div>
  );
};

// Types

export type SUPPLY_REVIEW = "SUPPLY_REVIEW";
export type SUPPLY_ALLOCATIONS = "SUPPLY_ALLOCATIONS";
export type SUPPLY = "SUPPLY";
export type SUPPLY_NAMESPACE = "supply";
export type SUPPLY_REVIEW_NAMESPACE = "supply_review";

export type COMPUTER = "COMPUTER";
export type COMPUTER_REVIEW = "COMPUTER_REVIEW";
export type COMPUTER_ALLOCATIONS = "COMPUTER_ALLOCATIONS";
export type COMPUTER_NAMESPACE = "computer";
export type COMPUTER_REVIEW_NAMESPACE = "computer_review";

export type CONTRACT = "CONTRACT";
export type CONTRACT_REVIEW = "CONTRACT_REVIEW";
export type CONTRACT_ALLOCATIONS = "CONTRACT_ALLOCATIONS";
export type CONTRACT_NAMESPACE = "contract";
export type CONTRACT_REVIEW_NAMESPACE = "contract_review";

export type WAGES = "WAGES";
export type WAGES_REVIEW = "WAGES_REVIEW";
export type WAGES_ALLOCATIONS = "WAGES_ALLOCATIONS";
export type WAGES_NAMESPACE = "wages";
export type WAGES_REVIEW_NAMESPACE = "wages_review";

export type PROJECTS = "PROJECTS";
export type PROJECTS_REVIEW = "PROJECTS_REVIEW";
export type PROJECTS_REVIEW_NAMESPACE = "projects_review";
export type PROJECTS_NAMESPACE = "project";

export type DYNAMIC_NAMESPACE = "dynamic";

export type ImportType =
  | SUPPLY
  | COMPUTER
  | CONTRACT
  | WAGES
  | PROJECTS
  | PROJECTS_REVIEW
  | SUPPLY_REVIEW
  | SUPPLY_ALLOCATIONS
  | COMPUTER_REVIEW
  | COMPUTER_ALLOCATIONS
  | CONTRACT_REVIEW
  | CONTRACT_ALLOCATIONS
  | WAGES_REVIEW
  | WAGES_ALLOCATIONS;

export type NamespaceType =
  | SUPPLY_REVIEW_NAMESPACE
  | SUPPLY_NAMESPACE
  | COMPUTER_NAMESPACE
  | COMPUTER_REVIEW_NAMESPACE
  | CONTRACT_NAMESPACE
  | CONTRACT_REVIEW_NAMESPACE
  | WAGES_NAMESPACE
  | WAGES_REVIEW_NAMESPACE
  | PROJECTS_NAMESPACE
  | PROJECTS_REVIEW_NAMESPACE
  | DYNAMIC_NAMESPACE;
