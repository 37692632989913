import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { SurveyChat } from "./SurveyChat";
import { SurveyLogin } from "./SurveyLogin";
import { SurveyComplete } from "./SurveyComplete";
import { useSurvey } from "../../../rtk/R&D/Survey/useSurvey";
import Lottie from "lottie-react";
import * as animationData from "../../../auth/loadingAnimation.json";
import { SprxLoading } from "../../../ui-components";

const validateData = (data) => {
  return (
    data.hasOwnProperty("companyName") &&
    data.hasOwnProperty("employeeName") &&
    data.hasOwnProperty("jobTitle") &&
    data.hasOwnProperty("qPercentage") &&
    data.hasOwnProperty("projectNames") &&
    data.hasOwnProperty("surveyStatus")
  );
};

const SurveyLanding = () => {
  const params = useParams();
  const [isAuthenticating, setIsAuthenticating] = useState(false);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [data, setData] = useState({});

  const {
    authenticate,
    error,
    isAuthed,
    isDownloadingFile,
    getRelevantDataPoints,
    isUploading,
  } = useSurvey();

  // Authenticate & Load Data via Provider
  const onAuthenticate = async (password) => {
    setIsAuthenticating(true);
    await authenticate({
      surveyId: params.surveyId,
      password: password,
    });
    setIsAuthenticating(false);
  };

  useEffect(() => {
    if (isAuthed && !isDownloadingFile) {
      const dataTmp = getRelevantDataPoints();
      if (!validateData(dataTmp)) return;
      setData(dataTmp);
      setDataLoaded(true);
    }
  }, [isAuthed, isDownloadingFile, getRelevantDataPoints]);

  // Loading
  if (isAuthed && !validateData(data) && !isUploading)
    return (
      <div className="bg-gradient-login flex min-h-screen flex-col content-center justify-center">
        <SprxLoading />
      </div>
    );

  if (data.surveyStatus === "complete") {
    return <SurveyComplete />;
  }

  if (isAuthed && dataLoaded)
    return (
      <SurveyChat
        companyName={data.companyName}
        employeeName={data.employeeName}
        jobTitle={data.jobTitle}
        qPercentage={data.qPercentage}
        projectNames={data.projectNames}
      />
    );

  return (
    <div className="bg-gradient-login flex min-h-screen flex-col content-center justify-center">
      <SurveyLogin
        onAuthenticate={onAuthenticate}
        isAuthenticating={isAuthenticating}
        params={params}
        error={error}
      />
    </div>
  );
};

export default SurveyLanding;
