import axios from "axios";

import { Cookies } from "react-cookie";

import config from "./_config";

import { baseAPI } from "./baseAPI";

const cookies = new Cookies();

const companyAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createCompany: builder.mutation({
      query: (body) => ({
        url: `/company`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error) => [{ type: "CompanyList" }],
    }),
    getCompany: builder.query({
      query: (companyID) => ({
        url: `/companies/${companyID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Company", id }],
    }),
    getAdminCompany: builder.mutation({
      query: (companyID) => ({
        url: `/companies/${companyID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [{ type: "Company", id }],
    }),
    getUpdatedCompany: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const token = cookies.get("session-id");
        try {
          const result = await axios({
            method: "GET",
            url: config.apiGateway.TAXURL + `/companies/${arg.companyID}`,
            headers: {
              "session-id": token,
            },
          });
          if (result.status === 200) {
            return { data: result.data };
          }
        } catch (axiosError) {
          let err = axiosError;
          return {
            error: { status: err.response?.status, data: err.response?.data },
          };
        }
      },
    }),
    listCompanies: builder.query({
      query: () => ({
        url: `/list-companies`,
        method: "GET",
      }),
      providesTags: (result, error) => [{ type: "CompanyList" }],
    }),
    updateCompany: builder.mutation({
      query: ({ companyID, body }) => ({
        url: `/companies/${companyID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { companyID }) => [
        { type: "Company", companyID },
        { type: "CompanyList" },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCompanyMutation,
  useGetCompanyQuery,
  useLazyGetCompanyQuery,
  useListCompaniesQuery,
  useGetUpdatedCompanyMutation,
  useUpdateCompanyMutation,
  useGetAdminCompanyMutation,
} = companyAPI;
