import { Engagement, EngagementStatus } from "../types";
import { EngagementCard } from "./EngagementCard";
// import { Filter } from "./Filter";
// import { FilterContainer } from "./FilterContainer";
// import { useState } from "react";
// import { FilterIcon } from "@heroicons/react/outline";
// import { FilterIcon as SolidFilterIcon } from "@heroicons/react/solid";

const isInDateRange = (date: string, start: string, end: string) => {
  const dateObj = new Date(date);
  const startObj = new Date(start);
  let endObj = new Date(end);
  endObj.setDate(endObj.getDate() + 1);
  const isAfterStart = dateObj >= startObj;
  const isBeforeEnd = dateObj <= endObj;
  return isAfterStart && isBeforeEnd;
};

export const applyFilter = (engagements: any[], filter: any) => {
  const noRepsSelected = filter.reps.length === 0;
  const noCompaniesSelected = filter.companies.length === 0;
  return engagements.filter((engagement) => {
    // Check date match
    let isDateMatch = false;
    let filterDate: any;

    if (filter.datesFilterType === "Created Date") {
      filterDate = new Date(engagement.createdAt);
    } else if (filter.datesFilterType === "Due Date") {
      filterDate = engagement.tracker?.dueDate
        ? new Date(engagement.tracker?.dueDate)
        : "";
    }

    isDateMatch = filterDate
      ? isInDateRange(
          filterDate.toISOString(),
          filter.dates.start,
          filter.dates.end,
        )
      : false;

    if (noRepsSelected && noCompaniesSelected && isDateMatch) return true;
    const salesMatch = filter.reps.some(
      (f: any) => engagement?.salesRep?.id === f,
    );
    if (salesMatch && noCompaniesSelected && isDateMatch) return true;
    const companyMatch = filter.companies.some(
      (f: any) => engagement?.companyID === f,
    );
    if (companyMatch && noRepsSelected && isDateMatch) return true;
    if (salesMatch && companyMatch && isDateMatch) return true;
    return false;
  });
};

export const Column: React.FC<{
  title: EngagementStatus;
  engagements: Engagement[];
  isLast: boolean;
}> = ({ title, engagements, isLast }) => {
  // const [filter, setFilter] = useState<string[]>([]);
  // const filteredEngagements = applyFilter(engagements, filter);
  return (
    <div className="flex h-90vh flex-1 flex-col p-2">
      <div className="flex h-full flex-col">
        <div className="mb-4 flex flex-row items-center justify-start">
          <h2 className="mr-4 flex flex-row items-center justify-start">
            <p className="text-xl font-bold">{title}</p>
          </h2>
        </div>
        <div className="hide-scrollbar flex flex-col space-y-4 overflow-y-auto p-2">
          {engagements?.map((engagement) => (
            <EngagementCard key={engagement.id} engagement={engagement} />
          ))}
        </div>
      </div>
    </div>
  );
};
