//RD engagements list
import { motion } from "framer-motion";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { CreateEngagementForm } from "./CreateEngagementForm";
import { EditEngagementForm } from "./EditEngagementForm";
import { AdminEngagementListLoading } from "../components/AdminEngagementListLoading";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useUsers } from "../../../rtk/Users/useUsers";
import { StarIcon as OutlinedStar } from "@heroicons/react/outline";
import { StarIcon } from "@heroicons/react/solid";

export const AdminEngagementList = () => {
  const { user } = useUsers();
  const dispatch = useDispatch();
  const [open, setOpen] = useState<boolean>(false);
  const [filter, setFilter] = useState<string>("");
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editIndex, setEditIndex] = useState<number | null>(null);
  const [loadingEngData, setLoadingEngData] = useState<boolean>(true);
  const [isFavoriting, setIsFavoriting] = useState(false);
  const [isSettingActive, setIsSettingActive] = useState(false);

  const {
    lazyEngagementData,
    engagementList,
    lazyGetEngagementData,
    isLazyLoadingEngagementData,
    isLoadingEngagementList,
  } = useEngagementData();
  const { getTaxUser, updateTaxUser } = useUsers();

  if (isLoadingEngagementList) {
    return <AdminEngagementListLoading />;
  }

  const activeEngagement = engagementList?.find(
    (eng) => eng?.engagementID === user?.data?.activeEngagementID,
  );

  const favoritedEngagements = engagementList?.filter(
    (eng) =>
      user?.favorites?.RD?.includes(eng?.engagementID) &&
      eng?.engagementID !== activeEngagement?.engagementID,
  );

  const renderedList = [
    ...([activeEngagement] ?? []),
    ...([...favoritedEngagements] ?? []),
    ...engagementList
      ?.filter(
        (eng) =>
          eng?.engagementID !== user?.data?.activeEngagementID &&
          !user?.favorites?.RD?.includes(eng?.engagementID),
      )
      ?.sort((a, b) => b?.createdAt - a?.createdAt),
  ];

  const onFavoriteEngagement = async (type, id) => {
    const userID = user.userID;
    let favorites = [
      ...(user?.favorites?.hasOwnProperty(type) ? user?.favorites[type] : []),
    ];

    if (favorites.includes(id)) {
      const index = favorites.indexOf(id);
      favorites.splice(index, 1);
    } else {
      favorites.push(id);
    }
    const body = {
      favorites: {
        ...(user?.favorites ?? []),
        [type]: favorites,
      },
    };
    const updateUserResponse = await updateTaxUser({ userID, body });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  const handleSetActiveClick = async (index: number, eng: any) => {
    setIsSettingActive(true);
    setEditIndex(index);
    const body = {
      activeEngagementID: eng.engagementID,
      isUsingDataSpace: false,
      engagementType: "RD",
    };
    await updateTaxUser({
      userID: user.userID,
      body: body,
    });
    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
    setEditIndex(null);
    setIsSettingActive(false);
  };

  const handleFavoriteClick = async (index: number, engagementID: string) => {
    setIsFavoriting(true);
    setEditIndex(index);
    await onFavoriteEngagement("RD", engagementID);
    setEditIndex(null);
    setIsFavoriting(false);
  };

  return (
    <div className="flex-1">
      <div className="">
        {/* Admin engagement table */}
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="items-between flex flex-row items-end justify-between">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search Engagement</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <motion.button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpen(true)}
              >
                Create Engagement
              </motion.button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Engagement Name
                        </th>
                        <th
                          scope="col"
                          className="px-3 py-3.5 text-left text-sm font-semibold text-gray-900"
                        >
                          Client Name
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Edit</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Set Active</span>
                        </th>
                        <th
                          scope="col"
                          className="relative py-3.5 pl-3 pr-4 sm:pr-6"
                        >
                          <span className="sr-only">Favorite</span>
                        </th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {renderedList
                        ?.map((a) => ({ ...a }))
                        ?.filter((a) => {
                          const k = filter.toLowerCase();
                          return (
                            a?.engagementName?.toLowerCase()?.includes(k) ||
                            false
                          );
                        })
                        ?.map((eng, index) => (
                          <tr
                            key={eng.engagementID}
                            className={
                              eng?.engagementID ===
                              user?.data?.activeEngagementID
                                ? "bg-gray-100"
                                : ""
                            }
                          >
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {eng.engagementName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {eng.companyName}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkGreen hover:text-green-400"
                                onClick={async () => {
                                  setLoadingEngData(true);
                                  setEditOpen(true);
                                  await lazyGetEngagementData(eng.engagementID);
                                  setLoadingEngData(false);
                                }}
                              >
                                Edit
                              </motion.button>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-sprxClientPortalLightBlue"
                                onClick={async () => {
                                  await handleSetActiveClick(index, eng);
                                }}
                              >
                                {isSettingActive && index === editIndex ? (
                                  <div className="w-full">
                                    <p className="h-2 w-16 animate-pulse rounded bg-sprxGrey"></p>
                                  </div>
                                ) : (
                                  "Set Active"
                                )}
                              </motion.button>
                            </td>
                            <td className="px-3 py-4">
                              {isFavoriting && index === editIndex ? (
                                <div className="w-full">
                                  <p className="h-2 w-6 animate-pulse rounded bg-sprxGrey"></p>
                                </div>
                              ) : (
                                <>
                                  {user?.favorites?.RD?.includes(
                                    eng.engagementID,
                                  ) ? (
                                    <StarIcon
                                      className={`h-7 w-7 align-middle text-sprxYellow`}
                                      onClick={async () => {
                                        await handleFavoriteClick(
                                          index,
                                          eng.engagementID,
                                        );
                                      }}
                                    />
                                  ) : (
                                    <OutlinedStar
                                      className={`ml-0.5 h-6 w-6 align-middle text-sprxTaxLightGray`}
                                      onClick={async () => {
                                        await handleFavoriteClick(
                                          index,
                                          eng.engagementID,
                                        );
                                      }}
                                    />
                                  )}
                                </>
                              )}
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>

          <EditEngagementForm
            open={editOpen}
            setOpen={setEditOpen}
            user={user}
            engagementData={lazyEngagementData}
            loading={loadingEngData}
            updatingEngData={isLazyLoadingEngagementData}
          />

          <CreateEngagementForm
            open={open}
            setOpen={setOpen}
            user={user}
            engagements={engagementList}
          />
        </div>
      </div>
    </div>
  );
};
