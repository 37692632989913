import CryptoJS from "crypto-js";
import { Cookies } from "react-cookie";

import { baseAPI } from "./baseAPI";

const cookies = new Cookies();

const sendConnectAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    encryptConnectCredentials: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const encryptedObject = CryptoJS.AES.encrypt(
          JSON.stringify({ arg }),
          process.env.REACT_APP_CLIENT_SIDE_ENCRYPT_KEY
        ).toString();

        return { data: encryptedObject };
      },
    }),
    decryptConnectServerCredentials: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const decodedURI = decodeURIComponent(arg.cred);

        const decryptedObject = JSON.parse(
          CryptoJS.AES.decrypt(decodedURI, arg.password).toString(
            CryptoJS.enc.Utf8
          )
        );
        return { data: decryptedObject };
      },
    }),
    setConnectSessionID: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        cookies.set("session-id", arg.sessionID);
        cookies.set("connectUser", true);

        return { data: { status: true } };
      },
    }),
    removeConnectSessionID: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        cookies.remove("session-id");
        cookies.remove("connectUser");

        return { data: { status: true } };
      },
    }),
    generatePayrollConnectLink: builder.mutation({
      query: (body) => ({
        url: `/rd/generate-payroll-connect-link`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    connectSentPayroll: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/connect-payroll/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    connectSentAdditionalPayroll: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/connect-additional-payroll/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    checkConnectEmployeeLocation: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/check-employee-location/${engagementID}`,
        method: "POST",
        body: body,
      }),
    }),
    getConnectEngagementData: builder.mutation({
      query: (engagementID) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "ConnectEngagementData", id },
      ],
    }),
    updateConnectEngagementData: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "ConnectEngagementData", engagementID },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useEncryptConnectCredentialsMutation,
  useDecryptConnectServerCredentialsMutation,
  useGeneratePayrollConnectLinkMutation,
  useRefreshConnectCredentialsMutation,
  useGetConnectEngagementDataMutation,
  useUpdateConnectEngagementDataMutation,
  useConnectSentPayrollMutation,
  useConnectSentAdditionalPayrollMutation,
  useCheckConnectEmployeeLocationMutation,
  useSetConnectSessionIDMutation,
  useRemoveConnectSessionIDMutation,
} = sendConnectAPI;
