import React, { useState } from "react";
import classNames from "classnames";

export const SurveyLogin = (props) => {
  const { onAuthenticate, isAuthenticating, params } = props;

  const [password, setPassword] = useState("");
  return (
    <div className="bg-gradient-login flex min-h-screen flex-col justify-center py-12 sm:px-6 lg:px-8">
      <div className="text-center sm:mx-auto sm:w-full sm:max-w-md">
        <img
          className="mx-auto h-20 w-auto"
          src="assets/images/White@2x.png"
          alt="SPRX.tax"
        />
        <h2 className="mt-6 text-center text-3xl font-extralight text-white">
          SME Survey
        </h2>
      </div>
      <div className="mt-8 sm:mx-auto sm:w-full sm:max-w-md">
        <div className="bg-white py-8 px-4 shadow sm:rounded-lg sm:px-10">
          <div className={"flex flex-col items-center"}>
            <div className="w-full">
              <div className="text-center">
                <p> Enter your login code below.</p>
                <div className="mt-8">
                  <input
                    // type="password"
                    type="text"
                    name="password"
                    id="password"
                    readOnly={
                      typeof params.surveyId === "undefined" || isAuthenticating
                        ? true
                        : false
                    }
                    className="block w-full rounded-md border-gray-500 shadow-sm focus:border-gray-500 focus:outline-none focus:ring-transparent sm:text-sm"
                    value={password}
                    onChange={(event) => setPassword(event.target.value)}
                  />
                </div>
                <div>
                  <button
                    id="login-submit"
                    type="submit"
                    data-test="login-submit"
                    className={classNames(
                      "mt-8 flex w-full justify-center rounded-md border border-transparent bg-sprxBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-blue-500 focus:outline-none",
                    )}
                    disabled={isAuthenticating}
                    onClick={() => onAuthenticate(password.trim())}
                  >
                    {isAuthenticating ? (
                      <div className="flex w-full flex-row">
                        <div className="flex w-full flex-row items-center justify-center">
                          <p className="h-2 w-20 animate-pulse rounded bg-white py-2"></p>
                        </div>
                      </div>
                    ) : (
                      "Sign in"
                    )}
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
