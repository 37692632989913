import CryptoJS from "crypto-js";
import axios from "axios";
import { Cookies } from "react-cookie";

import { baseAPI } from "./baseAPI";

const cookies = new Cookies();

const surveyAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createSurveyJSONDataFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const file = new Blob([JSON.stringify(arg)], {
          type: "application/json",
        });
        return { data: file };
      },
    }),
    encryptCredentials: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const encryptedObject = CryptoJS.AES.encrypt(
          JSON.stringify({ arg }),
          process.env.REACT_APP_CLIENT_SIDE_ENCRYPT_KEY
        ).toString();

        return { data: encryptedObject };
      },
    }),
    generateSurveyLink: builder.mutation({
      query: (body) => ({
        url: `/rd/generate-survey-link`,
        method: "POST",
        body: body,
      }),
    }),
    decryptServerCredentials: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const decodedURI = decodeURIComponent(arg.surveyId);
        const decryptedObject = JSON.parse(
          CryptoJS.AES.decrypt(decodedURI, arg.password).toString(
            CryptoJS.enc.Utf8
          )
        );
        return { data: decryptedObject };
      },
    }),
    setSurveySessionID: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        cookies.set("session-id", arg.sessionID);
        cookies.set("smeUser", true);
        return { data: { status: true } };
      },
    }),
    removeSurveySessionID: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        cookies.remove("session-id");
        cookies.remove("smeUser");

        return { data: { status: true } };
      },
    }),
    getSurveyEngagementData: builder.mutation({
      query: (engagementID) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "GET",
      }),
      providesTags: (result, error, id) => [
        { type: "SurveyEngagementData", id },
      ],
    }),
    calculateSurveyRDCredit: builder.mutation({
      query: ({ engagementID }) => ({
        url: `/rd/compute-rd-credit/${engagementID}`,
        method: "POST",
      }),
    }),
    calculateSurveyStateCredit: builder.mutation({
      query: (body) => ({
        url: `/rd/compute-state-rd-credit`,
        method: "POST",
        body: body,
      }),
    }),
    createSurveys: builder.mutation({
      query: (body) => ({
        url: `/rd/create-surveys`,
        method: "POST",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "EngagementData", engagementID },
      ],
    }),
    updateSurveyEngagementData: builder.mutation({
      query: ({ engagementID, body }) => ({
        url: `/rd/engagement-data/${engagementID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { engagementID }) => [
        { type: "SurveyEngagementData", engagementID },
      ],
    }),
    uploadSurveyFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: {
            key: arg.key,
            bucket: arg.bucket,
            action: "putObject",
            type: arg.type,
          },
        });

        const options = {
          headers: {
            "Content-Type": arg.type,
          },
        };

        const signedRequest = signedURL.data.data.signedRequest;

        const itemURL = signedURL.data.data.url;

        const uploadResults = await axios.put(signedRequest, arg.file, options);

        if (uploadResults.statusText === "OK") {
          let fileObj = {
            results: uploadResults,
            url: itemURL,
            taxYear: arg.taxYear,
          };

          return { data: fileObj };
        }
      },
    }),
    downloadSurveyFile: builder.mutation({
      queryFn: async (arg, queryApi, extraOptions, baseQuery) => {
        const signedURL = await baseQuery({
          url: `/requestSignedURL`,
          method: "POST",
          body: arg,
        });
        const response = await (
          await fetch(signedURL.data.data.signedRequest)
        ).json();
        return { data: response };
      },
    }),
    uploadLineItem: builder.mutation({
      query: (body) => ({
        url: `/rd/upload-item`,
        method: "POST",
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateSurveyJSONDataFileMutation,
  useEncryptCredentialsMutation,
  useGenerateSurveyLinkMutation,
  useDecryptServerCredentialsMutation,
  useGetSurveyEngagementDataMutation,
  useUpdateSurveyEngagementDataMutation,
  useDownloadSurveyFileMutation,
  useUploadSurveyFileMutation,
  useCalculateSurveyRDCreditMutation,
  useCalculateSurveyStateCreditMutation,
  useSetSurveySessionIDMutation,
  useRemoveSurveySessionIDMutation,
  useUploadLineItemMutation,
} = surveyAPI;
