import { useEffect, useState } from "react";
import { SprxLoading } from "../../../../ui-components";
import { translateImportTypeToFileName } from "../../utils";
import { useDownloadFileMutation } from "../../../../api/fileAPI";
import GaugeChart from "react-gauge-chart";

export const ReviewGaugeColumn = (props) => {
  const { completePercentage, engagementData } = props;
  const [wageTotal, setWageTotal] = useState(0);
  const [supplyTotal, setSupplyTotal] = useState(0);
  const [contractTotal, setContractTotal] = useState(0);
  const [computerTotal, setComputerTotal] = useState(0);

  const [downloadFile, { isLoading: isDownloadingFile }] =
    useDownloadFileMutation();

  useEffect(() => {
    const { s3Bucket, engagementID } = engagementData;

    async function getData(body) {
      try {
        const response = await downloadFile({ ...body });
        return response?.data ? response.data : [];
      } catch (err) {
        console.error(err);
        return [];
      }
    }

    const bucket = s3Bucket.name;

    const wageKey = `${engagementID}/uploadFiles/${engagementID}${translateImportTypeToFileName(
      "Wages"
    )}.json`;
    const wageDataParams = { bucket, key: wageKey, action: "getObject" };
    getData(wageDataParams)
      .then((data) => {
        const total = data
          .map((a) => (a?.qre ? a.qre : 0))
          .reduce((a, acc) => a + acc, 0);
        setWageTotal(total);
      })
      .catch((err) => console.error(err));

    const contractKey = `${engagementID}/uploadFiles/${engagementID}${translateImportTypeToFileName(
      "Contract Research"
    )}.json`;

    const contractDataParams = {
      bucket,
      key: contractKey,
      action: "getObject",
    };
    getData(contractDataParams)
      .then((data) => {
        const total = data
          .map((a) => (a?.qre ? a.qre : 0))
          .reduce((a, acc) => a + acc, 0);
        setContractTotal(total);
      })
      .catch((err) => console.error(err));

    const computerKey = `${engagementID}/uploadFiles/${engagementID}${translateImportTypeToFileName(
      "Computer Lease"
    )}.json`;
    const computerParams = { bucket, key: computerKey, action: "getObject" };

    getData(computerParams)
      .then((data) => {
        const total = data
          .map((a) => (a?.qre ? a.qre : 0))
          .reduce((a, acc) => a + acc, 0);
        setComputerTotal(total);
      })
      .catch((err) => console.error(err));

    const supplyKey = `${engagementID}/uploadFiles/${engagementID}${translateImportTypeToFileName(
      "Supply"
    )}.json`;
    const supplyDataParams = { bucket, key: supplyKey, action: "getObject" };

    getData(supplyDataParams)
      .then((data) => {
        const total = data
          .map((a) => (a?.qre ? a.qre : 0))
          .reduce((a, acc) => a + acc, 0);
        setSupplyTotal(total);
      })
      .catch((err) => console.error(err));
  }, [downloadFile, engagementData]);

  return (
    <div className="col-span-1 flex flex-col w-full">
      <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg  bg-green-200 min-h-88vh">
        <div className="flex flex-1 flex-col justify-between bg-white p-6">
          <h1 className="text-xl text-center font-semibold text-gray-900 ">
            Progress
          </h1>
          <GaugeChart
            id="gauge-chart1"
            className={"flex justify-center items-center mt-4 max-w-xl"}
            animate={true}
            arcsLength={[
              completePercentage / 100,
              1 - completePercentage / 100,
            ]}
            colors={["#5BE12C", "#DFF6FF"]}
            needleColor={"#3355FF"}
            needleBaseColor={"#3355FF"}
            nrOfLevels={1}
            hideText={true}
            percent={completePercentage / 100}
          />
          <div className="relative mt-4">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-gray-600" />
            </div>
          </div>
          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Wage Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? (
                  <SprxLoading />
                ) : (
                  `$${Math.round(Number(wageTotal)).toLocaleString()}`
                )}
              </dd>
            </div>
          </div>
          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Supply Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? (
                  <SprxLoading />
                ) : (
                  `$${Math.round(Number(supplyTotal)).toLocaleString()}`
                )}
              </dd>
            </div>
          </div>

          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Computer Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? (
                  <SprxLoading />
                ) : (
                  `$${Math.round(Number(computerTotal)).toLocaleString()}`
                )}
              </dd>
            </div>
          </div>

          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Total Contract Cost
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? (
                  <SprxLoading />
                ) : (
                  `$${Math.round(Number(contractTotal)).toLocaleString()}`
                )}
              </dd>
            </div>
          </div>

          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                Federal Credit
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? (
                  <SprxLoading />
                ) : (
                  `$${Math.round(
                    Number(engagementData.credit.rdCredit)
                  ).toLocaleString()}`
                )}
              </dd>
            </div>
          </div>
          <div className="flex flex-col w-full overflow-hidden rounded-lg shadow-lg mt-4">
            <div className="overflow-hidden rounded-lg bg-sprxClientPortalLightBlue p-2">
              <dt className="truncate text-xs font-medium text-white">
                State Credit
              </dt>
              <dd className="mt-1 text-sm font-semibold tracking-tight text-white">
                {isDownloadingFile ? <SprxLoading /> : `$${0}`}
              </dd>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
