import * as React from "react";

interface ChatContextInterface {
  chatID: string;

  isCreatingChat: boolean;
  isCreatingChatError: boolean;
  createChatError: Error | null;
  isChatCreated: boolean;

  isDeletingChat: boolean;
  isDeletingChatError: boolean;
  deleteChatError: Error | null;
  isChatDeleted: boolean;

  isQueryingChat: boolean;
  isQueryingChatError: boolean;
  queryChatError: Error | null;
  isChatQueried: boolean;

  onCreateChat: (knowledgeBaseId: string) => Promise<void>;
  onDeleteChat: () => Promise<void>;
  onQueryChat: (message: string) => Promise<any>;

  setEngagementID: (engagementID: string) => void;
}

const ChatContext = React.createContext<ChatContextInterface | null>(null);

export default ChatContext;
