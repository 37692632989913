export const getCurrentStatus = (statuses: any) => {
  let currentStatus;
  let flag = false;
  statusOrder.forEach((key) => {
    if (statuses[key].status === "notStarted" && !flag && key !== "states") {
      currentStatus = key;
      flag = true;
    }
  });
  return currentStatus;
};

export const updateStatus = (status: any, newCurrentStatus: any) => {
  let flag = false;
  let statuses = { ...status };
  statusOrder.forEach((key) => {
    if (key === newCurrentStatus) {
      statuses[key] = { locked: false, status: "notStarted" };
      flag = true;
    } else {
      if (flag === false) {
        statuses[key] = { locked: true, status: "complete" };
      } else {
        statuses[key] = { locked: true, status: "notStarted" };
      }
    }
  });
  return statuses;
};

export const getStateCredit = (engagementData: any) => {
  let stCredit = 0;
  if (engagementData?.stateCredits) {
    engagementData?.stateCredits.forEach((state) => {
      if (state.completed && state.selected && state.credit?.stateRDCredit) {
        stCredit += Number(state.credit.stateRDCredit);
      }
    });
  }
  return stCredit;
};

export const statusOrder = [
  "eligibility",
  "upload",
  "states",
  "calculate",
  "payment",
  "review",
  "deliverables",
];

export const qreDownloads = {
  "Wage Data": "wageList",
  "Project Data": "projectList",
  "Supply Data": "supplyList",
  "Computer Lease Data": "computerLeaseList",
  "Contract Data": "crList",
};

export var formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
});
