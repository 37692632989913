export const formatAllocationEditForSubmission = (data) => {
  return {
    allocationLetter: {
      email: data.email,
      clientEmail: data.clientEmail,
      phoneNumber: data.phoneNumber,
      buildingCost: data.buildingCost,
      ownershipPercentage: data.ownershipPercentage,
      submittedDate: data.submittedDate,
      sentDate: data.sentDate,
      clientName: data.clientName,
      transcript: data.transcript,
    },
    buildingProfile: {
      buildingName: data.buildingName,
      ownerName: data.ownerName,
      buildingCost: data.buildingCost,
    },
  };
};
