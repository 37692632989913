import * as yup from "yup";

export const getModelSubmissionFormShape = () => {
  return yup.object().shape({
    efficiencyPercentage: yup
      .number()
      .moreThan(-1)
      .required("Percentage is required."),
    isAccurate: yup
      .boolean()
      .isTrue()
      .required("Please confirm the model is accurate."),
    hvacEfficiency: yup.number().moreThan(-1),
    lightingEfficiency: yup.number().moreThan(-1),
    envelopeEfficiency: yup.number().moreThan(-1),
  });
};
