import * as yup from "yup";

export const getCreateUserFormShape = () => {
  return yup.object().shape({
    email: yup
      .string()
      .email("Please enter a valid email address")
      .required("You must enter a email"),
    fullName: yup.string().required("Please enter a name."),
    role: yup.string().required("Please add a role"),
    states: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Value is required"),
        label: yup.string().required("Label is required"),
      }),
    ),
  });
};
