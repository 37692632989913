import { EngagementList } from "./EngagementList";
import { useState, useEffect } from "react";
import { useEnergyEngagement } from "../../../rtk/Energy/Engagement/useEnergyEngagement";
import { EngagementListLoading } from "./EngagementListLoading";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useDispatch } from "react-redux";
import { setUserData } from "../../../auth/rtk/userSlice";
import { useEnergyComments } from "../../../rtk/Energy/Comments/useEnergyComments";
import { StudyListEmpty } from "./StudyListEmpty";
import { useListCertificationEngagementsQuery } from "../../../api/energy/engagementAPI";

interface activeEnagement {
  [key: string]: any;
}

interface EngagementListContainerProps {
  setActiveEngagement: (eng: activeEnagement) => void;
  type: string;
}

export const CertifierEngagementListContainer = ({
  setActiveEngagement,
  type,
}: EngagementListContainerProps) => {
  const energyEngagementAPI = useEnergyEngagement();
  const userAPI = useUsers();

  const dispatch = useDispatch();
  const [filter, setFilter] = useState("");
  const [isSettingActive, setIsSettingActive] = useState(false);
  const [currentTab, setCurrentTab] = useState({
    label: "Ready",
    value: "Ready",
  });

  const { data: certifierEngs, isLoading: isLoadingCertifierEngs } =
    useListCertificationEngagementsQuery({
      userID: userAPI.user.userID,
    });

  if (!energyEngagementAPI || isLoadingCertifierEngs || !certifierEngs)
    return <EngagementListLoading />;

  const filteredEngagements = certifierEngs?.filter(
    (engagement: any) =>
      engagement.name.toLowerCase().includes(filter.toLowerCase()) ||
      engagement.companyName.toLowerCase().includes(filter.toLowerCase()),
  );

  const onSetEngagementActive = async (engagement: any): Promise<void> => {
    setIsSettingActive(true);
    const body = {
      activeEnergyEngagementID: engagement.engagementID,
      engagementType: "EN",
      isUsingDataSpace: false,
    };
    const userID = userAPI.user.userID;
    const updateUserReponse = await userAPI.updateTaxUser({
      userID: userID,
      body: body,
    });

    const updatedUser = await userAPI.getTaxUser(userID); // Grab this from updateTaxUser
    dispatch(setUserData(updatedUser.data));
    setActiveEngagement(engagement);
    setIsSettingActive(false);
  };

  return (
    <EngagementList
      user={userAPI.user}
      engagements={filteredEngagements?.map((eng: any) => {
        const status = eng?.certification?.status;
        const assignedDate = eng?.certification?.assignedDate;
        return {
          ...eng,
          assignedDate,
          status: {
            NOT_READY: "Not Ready",
            READY: "Ready",
            COMPLETE: "Complete",
          }[status],
        };
      })}
      setFilter={setFilter}
      filter={filter}
      setActiveEngagement={onSetEngagementActive}
      isSettingActive={isSettingActive}
    />
  );
};
