import * as React from "react";

export interface onCreateCommentParams {
  title: string;
  comment: string;
  role?: "admin" | "modeler" | "client" | "certifier";
}

interface CommentsContextProps {
  isLoadingUser: boolean;
  comments: any[];
  setCommentTitle: (title: string) => void;
  onCreateComment: ({
    comment,
    title,
    role,
  }: onCreateCommentParams) => Promise<void>;
  onAddNote: (commentID: string, comment: string) => Promise<void>;
  onDeleteComment: (commentID: string) => Promise<void>;
  onResolveComment: (commentID: string) => Promise<void>;
  getCommentResult: any;
  isLoadingComments: boolean;
  isAddingNote: boolean;
  isDeletingComment: boolean;
  isResolvingComment: boolean;
  isCreatingComment: boolean;
}

const defaultCommentsContext: any = {
  setCommentTitle: () => {},
  onCreateComment: async ({
    title,
    comment,
    role,
  }: onCreateCommentParams) => {},
  onAddNote: async (arg: string, arg2: string) => {},
  onDeleteComment: async (arg: string) => {},
  onResolveComment: async (arg: string) => {},
  getCommentResult: {},
  comments: [],
  isLoadingUser: true,
  isLoadingComments: true,
  isAddingNote: false,
  isDeletingComment: false,
  isResolvingComment: false,
  isCreatingComment: false,
};

const EnergyCommentsContext = React.createContext<CommentsContextProps>(
  defaultCommentsContext
);
export default EnergyCommentsContext;
