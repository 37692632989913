import { castUploadTypes, getCostKey } from "../../utils";
import { v4 as uuidv4 } from "uuid";
import { getID } from ".";

export const mergeBulkEditData = (oldData, newData, type) => {
  // Update Old Items w/ Newly Imported Fields
  // ** Important that we retain calulated fields
  const crFactor = type === "Contract Research" ? 0.65 : 1;
  let mergedData = oldData.map((oldItem) => {
    const index = newData.findIndex((e) => e.ID === oldItem.ID);
    if (index === -1) return oldItem;

    newData[index].qPercentage = newData[index].qPercentage * 100;

    let qre =
      Number(newData[index][getCostKey(type)]) *
      (Number(newData[index].qPercentage) / 100) *
      crFactor;

    if (newData[index].qPercentage >= 80) {
      qre = Number(newData[index][getCostKey(type)]) * crFactor;
    }
    const formattedItemOldItem = castUploadTypes(oldItem);
    const formattedNewItem = castUploadTypes(newData[index]);
    return {
      ...formattedItemOldItem,
      ...formattedNewItem,
      userProvided: true,
      qre,
    };
  });

  // Determine Which Items Are New & Append
  const mergedDataIDs = mergedData.map((item) => item.ID);
  const idKey = getID(type);
  const visibleIDKey = idKey.split(" ")[1] + " " + idKey.split(" ")[2];
  const newItems = newData
    .filter((item) => {
      return mergedDataIDs.indexOf(item.ID) === -1;
    })
    .map((e) => {
      const formattedItem = castUploadTypes(e);
      if (formattedItem.qPercent < 1) {
        formattedItem.qPercentage = formattedItem.qPercent * 100;
      }
      const ID = uuidv4();
      let qre =
        Number(formattedItem[getCostKey(type)]) *
        (Number(formattedItem.qPercentage) / 100) *
        crFactor;
      return {
        ...formattedItem,
        ID,
        qre,
        [idKey]: ID,
        [visibleIDKey]: ID,
        userProvided: true,
      };
    });
  return [...mergedData, ...newItems];
};
