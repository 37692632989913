import * as React from "react";
import { DocumentViewer } from "../components/DocumentViewer";
import { useFile } from "../../../../../rtk/R&D/File/useFile";
import { useEngagementData } from "../../../../../rtk/R&D/EngagementData/useEngagementData";
import { SprxLoading } from "../../../../../ui-components";
import { useComments } from "../../../../../rtk/R&D/Comments/useComments";

export const StateCreditsContainer = () => {
  const [docUrl, setDocUrl] = React.useState<string>("");
  const hasFetchedUrl = React.useRef<boolean>(false);

  const fileAPI = useFile();
  const engagementDataAPI = useEngagementData();
  const commentsAPI = useComments();

  const totalStateCredit =
    engagementDataAPI?.engagementData?.stateCredits
      ?.map((stateCredit: any) => stateCredit?.form?.credit ?? 0)
      .reduce((a: number, b: number) => a + b, 0) || 0;

  React.useEffect(() => {
    async function getURL(): Promise<void> {
      commentsAPI.setCommentTitle("State Credits");
      if (hasFetchedUrl.current) return; // Exit if URL has already been fetched
      hasFetchedUrl.current = true; // Mark as fetched

      const engagementData = engagementDataAPI.engagementData;

      const bucket: string = engagementData.s3Bucket?.name;
      const engagementID: string = engagementData.engagementID;
      const key: string = `${engagementID}/deliverables/State Forms.xlsx`;

      if (bucket && engagementID && key) {
        const body = {
          bucket,
          key,
          action: "getObject",
        };
        try {
          const signedUrlResponse = await fileAPI.downloadDocument(body);
          setDocUrl(signedUrlResponse.data.url);
        } catch (err) {
          console.error(err);
        }
      }
    }
    if (!engagementDataAPI || !fileAPI) return;
    getURL();
  }, [engagementDataAPI, fileAPI, commentsAPI]); // Dependencies

  if (docUrl === "")
    return (
      <div className="flex justify-center items-center h-800px">
        <SprxLoading />
      </div>
    );

  if (totalStateCredit === 0)
    return (
      <div className="flex justify-center items-center h-800px">
        <h2 className="text-xl text-gray-400">
          No state credits have been calculated for this engagement. If that
          seems incorrect, please contact an SPRX team member.
        </h2>
      </div>
    );

  return <DocumentViewer docUrl={docUrl} />;
};
