import * as yup from "yup";

export const getCreateDataSpaceFormShape = () => {
  return yup.object().shape({
    companyID: yup.object().shape({
      value: yup.string().required("Value is required"),
      label: yup.string().required("Label is required"),
    }),
    companyName: yup.string().required("Please enter a company name."),
    dataSpaceName: yup.string().required("Please enter a company name."),
    startYear: yup.number().required("Please add a starting year."),
    endYear: yup.number().required("Please add an ending year."),
    type: yup.string().required("Please select a type."),
  });
};
