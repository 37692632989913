import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";
import { useMemo, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { Button } from "../../../ui-components";
import { isDev, classNames } from "../../R&D/utils";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import Select from "react-select";
import { states } from "../../../ui-components";
import _ from "lodash";
import { useDataSpaces } from "../../../rtk/DataSpaces/useDataSpaces";

const getDefaultEditDataSpaceValues = (dataSpace) => {
  return {
    dataSpaceName: dataSpace?.dataSpaceName,
    companyName: dataSpace?.companyName,
    companyID: dataSpace?.companyID,
  };
};

const schema = yup.object().shape({
  dataSpaceName: yup.string().required("Please enter a name."),
  companyName: yup.string().required("Please enter a client"),
  companyID: yup.string().required("Please select a company"),
});

export const EditDataSpaceForm = ({
  open,
  setOpen,
  user,
  dataSpace,
  //   isLoadingDataSpace,
  //   isUpdatingDataSpace,
}) => {
  const companyAPI = useCompanyData();

  const { updateTaxUser, isUpdatingUser } = useUsers();
  const { updateDataSpace, isUpdatingDataSpace } = useDataSpaces();

  const { control, formState, handleSubmit, reset, setValue, watch } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultEditDataSpaceValues(dataSpace);
    }, [dataSpace]),
    resolver: yupResolver(schema),
  });

  useEffect(() => {
    reset(getDefaultEditDataSpaceValues(dataSpace));
    return () => {
      reset(getDefaultEditDataSpaceValues(dataSpace));
    };
  }, [dataSpace, reset]);

  const { errors, isValid, dirtyFields } = formState;

  const onSubmit = async (model) => {
    const { id } = dataSpace;

    const body = {
      companyName: model.companyName,
      dataSpaceName: model.dataSpaceName,
      companyID: model.companyID,
    };
    const updateDataSpaceResponse = await updateDataSpace({ id, body });
    if (isDev) console.log({ updateDataSpaceResponse });
    setOpen(false);
  };

  return (
    <AnimatePresence>
      {open && (
        <motion.div>
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="min-w-1/3 flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <motion.div className="relative min-h-30vh w-1/3 transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all">
                <motion.div>
                  {/*Modal title, description, and close button */}
                  <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <form
                    className="mt-5 w-full sm:flex sm:flex-col sm:items-center"
                    onSubmit={handleSubmit((model) => onSubmit(model))}
                  >
                    <motion.div className="flex w-full flex-col items-center justify-center text-center sm:mt-5">
                      <h3 className="p-4 text-left text-3xl font-medium leading-6 text-gray-900">
                        Edit Data Space
                      </h3>
                      <motion.div className="flex w-full flex-col items-center justify-center">
                        <Controller
                          name="dataSpaceName"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="dataSpaceName">
                                Data Space Name
                              </label>
                              <input
                                {...field}
                                type="text"
                                name="dataSpaceName"
                                id="dataSpaceName"
                                required
                                autoComplete="dataSpaceName"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                                placeholder="Jane Doe"
                              />
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("dataSpaceName") ? (
                          <p className="pl-2 text-sprxOrange">Invalid name</p>
                        ) : null}
                        <Controller
                          name="companyName"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="companyName">Client Name</label>
                              <input
                                {...field}
                                type="text"
                                name="companyName"
                                id="companyName"
                                required
                                autoComplete="companyName"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                                placeholder="Jane Doe"
                              />
                            </div>
                          )}
                        />
                        {errors.hasOwnProperty("companyName") ? (
                          <p className="pl-2 text-sprxOrange">
                            Invalid Client Name
                          </p>
                        ) : null}

                        <Controller
                          name="companyID"
                          control={control}
                          render={({ field }) => (
                            <div className="mt-4 mb-4 w-full text-left sm:max-w-xs">
                              <label htmlFor="companyID">
                                Assigned Company
                              </label>
                              <select
                                {...field}
                                id="companyID"
                                name="companyID"
                                className="block w-full rounded-md border-gray-300 shadow-sm focus:border-sprxTaxBlue focus:ring-sprxTaxBlue sm:text-sm"
                              >
                                <option value="none" disabled>
                                  Select an Option
                                </option>
                                {!companyAPI ||
                                companyAPI?.isListingCompanies === true
                                  ? null
                                  : companyAPI?.companies?.map((company) => {
                                      return (
                                        <option
                                          value={company.companyID}
                                          key={company.companyID}
                                        >
                                          {company.companyName}
                                        </option>
                                      );
                                    })}
                              </select>
                            </div>
                          )}
                        />

                        <motion.div className="mt-6 flex w-full flex-row items-end justify-end">
                          <motion.button
                            type="submit"
                            data-test="Save & Close"
                            whileHover={{ scale: 1.05 }}
                            whileTap={{ scale: 0.95 }}
                            disabled={_.isEmpty(dirtyFields) || !isValid}
                            className={
                              "focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm" +
                              (!isValid
                                ? "cursor-not-allowed bg-opacity-50"
                                : " hover:bg-sprxClientPortalDarkBlue")
                            }
                          >
                            {isUpdatingDataSpace ? (
                              <div className="flex h-5 w-full flex-col items-center justify-center">
                                <p
                                  className={
                                    "h-2 w-full animate-pulse rounded-md bg-gray-100"
                                  }
                                ></p>
                              </div>
                            ) : (
                              "Save"
                            )}
                          </motion.button>
                        </motion.div>
                      </motion.div>
                    </motion.div>
                  </form>
                </motion.div>
              </motion.div>
            </motion.div>
          </motion.div>
        </motion.div>
      )}
    </AnimatePresence>
  );
};
