import { LinkIcon } from "@heroicons/react/outline";

interface Level {
  rootKey: string;
  paths: { url: string }[];
  notes: { note: string }[];
}

export const SiteVisitItem = ({ level }: { level: Level }) => {
  return (
    <div className="w-full p-4 bg-white shadow-md border rounded-lg mb-4 ">
      <h3 className="text-xl font-bold mb-2">{level.rootKey}</h3>
      <div className="flex flex-row items-center mb-4">
        <p className="text-lg font-semibold mt-1">Walkthroughs:</p>
        <SiteVisitLinks links={level.paths.map((path: any) => path.url)} />
      </div>
      <div>
        <p className="text-lg font-semibold mb-1">Notes:</p>
        <SiteVisitNotes notes={level.notes.map((note: any) => note.note)} />
      </div>
    </div>
  );
};

const SiteVisitNotes = ({ notes }: { notes: string[] }) => {
  return (
    <div className="space-y-2">
      {notes.map((note, index) => (
        <p key={index} className="p-2 bg-gray-100 rounded">
          {note}
        </p>
      ))}
    </div>
  );
};

const SiteVisitLinks = ({ links }: { links: string[] }) => {
  return (
    <div className="space-y-2">
      {links.map((link, index) => (
        <div key={index} className="mx-2 inline-block">
          <div className="flex flex-row items-center px-3 py-1 bg-blue-100 text-blue-800 rounded-full hover:bg-blue-200">
            <a
              target="_blank"
              rel="noreferrer"
              href={link}
              className="text-blue-800"
            >
              Path {index + 1}
            </a>
            <LinkIcon className="h-4 w-4 inline-block ml-1" />
          </div>
        </div>
      ))}
    </div>
  );
};
