export const getCreateEngagementFormDefaultFields = () => {
  return {
    companyName: "",
    engagementName: "",
    clientName: "",
    clientEIN: "",
    CPAName: "",
    taxYearStart: "",
    taxYearEnd: "",
    dueDate: "",
    feeType: "",
    feeValue: 0,
    isRollover: false,
    engagement: "",
    entityName: true,
    entityEIN: true,
    entityType: true,
    method: true,
    pyQres: true,
    pyGrs: true,
    elections: true,
    wageQre: true,
    supplyQre: true,
    computerQre: true,
    contractorQre: true,
    projectQre: true,
    salesRepID: "",
  };
};
