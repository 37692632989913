import {
  FilterIcon,
  SearchIcon,
  ViewBoardsIcon,
} from "@heroicons/react/outline";
import { ViewBoardsIcon as SolidViewBoardIcon } from "@heroicons/react/solid";
import { FilterIcon as SolidFilterIcon } from "@heroicons/react/solid";
import { FilterContainer } from "./FilterContainer";
import { Filter, FilterCheckbox } from "./Filter";
import { EngagementStatus } from "../types";
import { motion } from "framer-motion";
import { SprxLoading } from "../../../../ui-components";

const columns: EngagementStatus[] = [
  "Hidden",
  "Upload",
  "Review",
  "Validation",
  "Completed",
];

export const SearchBar = ({
  setQuery,
  setFilter,
  filter,
  isQuerying,
  masterFilter,
  setMasterFilter,
}: {
  setQuery: (str: string) => void;
  setFilter: (str: EngagementStatus[]) => void;
  filter: EngagementStatus[];
  isQuerying: boolean;
  masterFilter: string[];
  setMasterFilter: (str: string[]) => void;
}) => (
  <div className="relative z-20 mb-4 pl-6 flex items-center justify-start">
    <div className="relative w-2/6">
      <span className="absolute inset-y-0 left-0 pl-3 flex items-center">
        <FilterContainer
          content={
            <motion.div className="z-30">
              <p className="font-bold mb-2">Columns</p>
              {columns.map((column, index) => (
                <div className="my-1" key={`search-bar-column-${index}`}>
                  <FilterCheckbox
                    key={index}
                    id={column}
                    name={column}
                    checked={filter.includes(column)}
                    onChange={() => {
                      if (filter.includes(column)) {
                        setFilter(filter.filter((f) => f !== column));
                      } else {
                        setFilter([...filter, column]);
                      }
                    }}
                  />
                </div>
              ))}
            </motion.div>
          }
          activeIcon={<SolidViewBoardIcon className="w-6 h-6" />}
          inactiveIcon={<ViewBoardsIcon className="w-6 h-6 text-gray-400" />}
          filter={[]}
          shift="2/3"
          isLast={false}
        />
      </span>
      <input
        type="text"
        placeholder="Engagement Name"
        className="w-full p-2 pl-10 border rounded-xl border-gray-200 bg-gray-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-transparent"
        onChange={(e) => setQuery(e.target.value)}
      />
      <span className="absolute inset-y-0 right-0 pr-3 flex items-center pointer-events-none">
        {isQuerying ? (
          <div className="ml-2 w-6 h-6">
            <SprxLoading width={"100%"} height={"100%"} />
          </div>
        ) : (
          <SearchIcon className="w-6 h-6 text-gray-400" />
        )}
      </span>
    </div>
    {/*<span className="">
      <SprxLoading width="5%" height="5%" />
  </span>*/}
    {isQuerying || true ? null : null}
    <div className="ml-4">
      <FilterContainer
        filter={masterFilter}
        content={<Filter setFilter={setMasterFilter} filter={masterFilter} />}
        inactiveIcon={<FilterIcon className="h-5 w-5" aria-hidden="true" />}
        activeIcon={<SolidFilterIcon className="h-5 w-5" aria-hidden="true" />}
        shift={undefined}
        isLast={false}
      />
    </div>
  </div>
);
