import { SmallDivider } from "./ReviewGaugeColumn";
// import { useEngagementData } from "../../../rtk/EngagementData/useEngagementData";
export const ReviewGaugeColumnLoading = (props) => {
  /*const {
      engagementData,
      isGettingEngagementData
    } = useEngagementData();*/

  const hasBrPayments = false;
  /*(isGettingEngagementData) ? false : engagementData.creditEligibilityData?.completedAnalysis?.brPayment?.brPMT !== undefined &&
      engagementData.creditEligibilityData.completedAnalysis.brPayment.brPMT > 0;*/

  const hasEcPayments = false;
  /*(isGettingEngagementData) ? false : engagementData?.creditEligibilityData?.completedAnalysis?.ecPayment?.ecPMT !== undefined &&
      engagementData.creditEligibilityData.completedAnalysis.ecPayment.ecPMT > 0;*/

  return (
    <div className="mb-4 flex max-h-88vh w-full flex-col overflow-y-scroll rounded-lg border shadow-lg">
      <div
        className={`flex w-full flex-col overflow-y-scroll rounded-lg min-h-${props.height} max-h-${props.height} `}
      >
        <div className="flex w-full flex-1 flex-col justify-start bg-white p-6">
          <div className="flex flex-row items-center justify-center">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <div className="relative mt-4">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-sprxClientPortalDarkBlue" />
            </div>
          </div>
          {hasBrPayments ? (
            <div className="mt-4 flex flex-row items-center justify-between">
              <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
              <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            </div>
          ) : null}
          {hasEcPayments ? (
            <div className="mt-4 flex flex-row items-center justify-between">
              <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
              <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            </div>
          ) : null}
          {hasBrPayments || hasEcPayments ? <SmallDivider /> : null}
          <div className="mt-2 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <SmallDivider />
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
          <SmallDivider />
          <div className="mt-4 flex flex-row items-center justify-between">
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
            <div className="mt-2 mb-2 h-2 w-1/3 animate-pulse rounded bg-sprxGrey"></div>
          </div>
        </div>
      </div>
    </div>
  );
};
