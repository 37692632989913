import { baseAPI } from "./baseAPI";

const chatAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createChat: builder.mutation({
      query: (body) => ({
        url: `/chat`,
        method: "POST",
        body: body,
      }),
    }),
    deleteChat: builder.mutation({
      query: (body) => ({
        url: `/chat`,
        method: "DELETE",
        body: body,
      }),
    }),
    queryChat: builder.mutation({
      query: (body) => ({
        url: `/chat`,
        method: "PUT",
        body: body,
      }),
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateChatMutation,
  useDeleteChatMutation,
  useQueryChatMutation,
} = chatAPI;
