import React, { useState, useEffect, useRef } from "react";
import { classNames } from "../utils";
import {
  YesNoChoice,
  NumberInput,
  LoadingBubble,
  SurveyChatContainer,
  ProfilePhoto,
  MultiSelectComponent,
  NumericInputTable,
  YES_MESSAGE,
  NO_MESSAGE,
} from "./components";
import { DoneButton } from "./components";
import { TypeAnimation } from "react-type-animation";
import { hydrateQuestions, ACTIVITIES } from "./util";
import { useSurvey } from "../../../rtk/R&D/Survey/useSurvey";
const hasDuplicate = (chatHistory, messageBeingTyped) =>
  chatHistory.map((a) => a.text).includes(messageBeingTyped.text);

export const SurveyChat = (props) => {
  const { companyName, employeeName, jobTitle, qPercentage, projectNames } =
    props;

  const messageContainerRef = useRef(null);
  const [step, setStep] = useState(0);
  const [isTyping, setIsTyping] = useState(true);
  const [activities, setActivities] = useState([]);
  const [selectedOptions, setSelectedOptions] = useState({});
  const [projects, setProjects] = useState([]);
  const [qualifiedPercentage, setQualifiedPercentage] = useState("");
  const [inputType, setInputType] = useState("");
  const [chatHistory, setChatHistory] = useState([]);
  const [showComponent, setShowComponent] = useState(false);
  const [projectPercentages, setProjectPercentages] = useState({});
  const [messageBeingTyped, setMessageBeingTyped] = useState(null);
  const [userInput, setUserInput] = useState(null);

  const { updateEmployee } = useSurvey();

  const questions = hydrateQuestions({
    companyName,
    employeeName,
    jobTitle,
    qPercentage,
  });

  useEffect(() => {
    if (messageBeingTyped === null) {
      setMessageBeingTyped(questions[0]);
    }
  }, [questions, messageBeingTyped]);

  const handleOptionSelect = (selectedOption) => {
    const shallowCopy = { ...selectedOptions };
    shallowCopy[selectedOption] = !shallowCopy[selectedOption];
    setSelectedOptions(shallowCopy);
  };

  const handleNumericInputChange = (option, value) => {
    setProjectPercentages((prevValues) => ({
      ...prevValues,
      [option]: value,
    }));
  };

  useEffect(() => {
    if (step > 0) {
      messageContainerRef.current.scrollTop =
        messageContainerRef?.current?.scrollHeight;
    }
  }, [chatHistory, questions, step]);

  const updateEmployeeLineItem = async () => {
    await updateEmployee({
      qualifiedPercentage,
      activities,
      projectPercentages,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const agentMessage = { ...messageBeingTyped };
    const newMessage = { text: userInput, role: "user", type: "text" };
    const hasDuplicateMsg = hasDuplicate(chatHistory, messageBeingTyped);
    const newMessages = hasDuplicateMsg
      ? [...chatHistory, newMessage]
      : [...chatHistory, agentMessage, newMessage];
    setChatHistory(newMessages);

    const id = questions[step].id;
    switch (id) {
      case "activities":
        setActivities(selectedOptions);
        setSelectedOptions({});
        break;
      case "projects":
        setProjects(selectedOptions);
        break;
      case "qualified_percentage":
        if (step === 3 && userInput === NO_MESSAGE) break;
        if (step === 3 && userInput === YES_MESSAGE) {
          setQualifiedPercentage(qPercentage);
          break;
        }
        setQualifiedPercentage(userInput);
        break;
      default:
        break;
    }

    if (step === 7) updateEmployeeLineItem();

    // Iterate to Nex Question Based on User Input (Yes/No)
    const next = questions[step].next;
    const nextStep = next.hasOwnProperty("yes")
      ? userInput === YES_MESSAGE
        ? next.yes
        : next.no
      : questions[step].next;
    setShowComponent(false);
    setStep(nextStep);
    setInputType(questions[nextStep].type);
    setMessageBeingTyped(questions[nextStep]);
    setIsTyping(true);
  };

  useEffect(() => {
    const outerTimer = setTimeout(() => {
      setIsTyping(false);
      const timer = setTimeout(() => {
        // Bypass Duplicates, Check Type, Check Last
        const duplicateMsg = hasDuplicate(chatHistory, messageBeingTyped);
        const isText = messageBeingTyped.type === "text";
        const isLast = step === questions.length - 1;

        // Show Component IFF Type isnt Text
        if (isText) setShowComponent(false);

        // Move Automatically iff Type is Text
        if (!duplicateMsg && isText && !isLast) {
          setChatHistory((prevMessages) => [
            ...prevMessages,
            messageBeingTyped,
          ]);
          const newStep = questions[step].next;
          setStep(newStep);
          setInputType(questions[newStep].type);
          setMessageBeingTyped(questions[newStep]);
          setIsTyping(true);
        }
      }, 1500);
      return () => clearTimeout(timer);
    }, 2000);
    return () => clearTimeout(outerTimer);
  }, [messageBeingTyped, questions, step, chatHistory]);

  //
  const getDisabledCheckboxValues = (id) => {
    if (id === "activities") return activities;
    if (id === "projects") return projects;
  };

  const getCheckboxOptions = (id) => {
    if (id === "activities") return ACTIVITIES;
    if (id === "projects") return projectNames;
  };

  return (
    <SurveyChatContainer>
      <div
        ref={messageContainerRef}
        className="flex-1 overflow-y-auto overflow-x-hidden"
      >
        {/*Render Chat History*/}
        {chatHistory.length
          ? chatHistory.map((msg, i) => {
              return (
                <div
                  className={classNames(
                    "py-5 text-white",
                    msg.role === "assistant" && "bg-gray-700",
                  )}
                  key={i}
                >
                  <div className="flex max-w-full space-x-5 px-10">
                    <ProfilePhoto msg={msg} />
                    <p className="whitespace-pre-line pt-1 text-sm">
                      {msg.text}
                    </p>
                  </div>
                  {/*Show Component (Disabled) IFF Component Item*/}
                  {msg?.multiple ? (
                    <div className="flex max-w-full space-x-5 py-5 px-22">
                      {msg.type === "checkbox" ? (
                        <MultiSelectComponent
                          options={getCheckboxOptions(msg.id)}
                          handleOptionSelect={null}
                          values={getDisabledCheckboxValues(msg.id)}
                          disabled={true}
                        />
                      ) : null}
                      {msg.type === "number" ? (
                        <NumericInputTable
                          options={Object.keys(projects)}
                          handleInputChange={null}
                          values={projectPercentages}
                          disabled={true}
                        />
                      ) : null}
                    </div>
                  ) : null}
                </div>
              );
            })
          : null}
        {/* Render Message Bubble OR AI Agent Typing Current Message w/ Component iff Applicable */}
        {isTyping ? (
          <LoadingBubble />
        ) : (
          <div className={"bg-gray-700 py-5 text-white"}>
            <div className="flex max-w-full space-x-5 px-10">
              <ProfilePhoto msg={{ role: "assistant" }} />
              <TypeAnimation
                sequence={[
                  messageBeingTyped.text,
                  () => setShowComponent(true),
                ]}
                wrapper="p"
                className="whitespace-pre-line pt-1 text-sm"
                cursor={false}
                speed={90}
              />
            </div>
            {showComponent && messageBeingTyped.multiple === true ? (
              <div className="flex max-w-full space-x-5 py-5 px-22">
                {messageBeingTyped.type === "checkbox" ? (
                  <MultiSelectComponent
                    options={getCheckboxOptions(messageBeingTyped.id)}
                    handleOptionSelect={handleOptionSelect}
                    values={selectedOptions}
                    disabled={false}
                  />
                ) : null}
                {messageBeingTyped.type === "number" ? (
                  <NumericInputTable
                    options={Object.keys(projects)}
                    handleInputChange={handleNumericInputChange}
                    values={projectPercentages}
                    disabled={false}
                  />
                ) : null}
              </div>
            ) : null}
          </div>
        )}
      </div>
      <div className="rounded-b-lg bg-gray-700/50 text-sm text-gray-400">
        <form onSubmit={handleSubmit} className="flex space-x-5 p-5">
          <input
            className="hidden flex-1 border-none bg-transparent focus:border-none focus:outline-none focus:ring-0 disabled:cursor-not-allowed disabled:text-gray-300"
            type="text"
            value={messageBeingTyped}
          />
          {!isTyping && showComponent ? (
            <>
              {messageBeingTyped?.multiple ? (
                <DoneButton
                  setMessage={setUserInput}
                  copy={messageBeingTyped.copy}
                />
              ) : null}
              {inputType === "yesNo" ? (
                <YesNoChoice setMessage={setUserInput} />
              ) : null}
              {inputType === "number" && !messageBeingTyped?.multiple ? (
                <NumberInput setMessage={setUserInput} />
              ) : null}
            </>
          ) : null}
        </form>
      </div>
    </SurveyChatContainer>
  );
};
