import { BuildingProfile } from "../../../../types/buildingProfile";

export const getBuildingProfileDefaultFields = (
  buildingProfile: BuildingProfile
) => {
  return {
    squareFeet: buildingProfile.squareFeet || "",
    buildingType: buildingProfile.buildingType || "",
    address: buildingProfile.address || "",
    systemsRelated: buildingProfile.systemsRelated || [],
    state: buildingProfile.state || "",
    buildingCost: buildingProfile.buildingCost || 0,
    ownerName: buildingProfile.ownerName || "",
    buildingName: buildingProfile.buildingName || "",
    meetsPrevailingWageRequirements:
      buildingProfile.meetsPrevailingWageRequirements || false,
    meetsApprenticeshipReqirements:
      buildingProfile.meetsApprenticeshipReqirements || false,
  };
};
