import { PieChart, GaugeChart, TreeMapChart } from "./Charts";
import * as React from "react";
import {
  EmployeeLineItem,
  SupplyLineItem,
  ContractorLineItem,
  ComputerLeaseLineItem,
} from "../../types/RdData";
import { TabNavigator } from "../../../../ui-components/TabNavigator";
import { BubbleChart } from "./Charts/BubbleChart";

interface IVisualizationsProps {
  wageData: EmployeeLineItem[];
  supplyData: SupplyLineItem[];
  contractData: ContractorLineItem[];
  computerLeaseData: ComputerLeaseLineItem[];
}

export const Visualizations = ({
  wageData,
  supplyData,
  contractData,
  computerLeaseData,
}: IVisualizationsProps) => {
  const [currentTab, setCurrentTab] = React.useState({
    label: "Wages",
    value: "Wages",
  });
  return (
    <div className="h-full">
      <TabNavigator
        currentTab={currentTab}
        setCurrentTab={setCurrentTab}
        tabs={[
          { label: "Wages", value: "Wages" },
          { label: "Supplies", value: "Supplies" },
          { label: "Contracts", value: "Contracts" },
          {
            label: "Computer Lease",
            value: "Computer Lease",
          },
        ]}
      />
      {currentTab.value === "Wages" ? (
        <ChartContainer
          lineItems={wageData}
          currentTab={currentTab}
          chartProps={[
            {
              label: "Employee Department",
              value: "qre",
              title: "By Department",
            },
            {
              label: "Employee Location",
              value: "qre",
              title: "By Location",
            },
            {
              label: "Employee Job Title",
              value: "qre",
              title: "By Job Title",
            },
          ]}
        />
      ) : null}
      {currentTab.value === "Supplies" ? (
        <ChartContainer
          lineItems={supplyData}
          currentTab={currentTab}
          chartProps={[
            {
              label: "Supply Account Department",
              value: "qre",
              title: "By Department",
            },
            {
              label: "Supply Location",
              value: "qre",
              title: "By Location",
            },
            {
              label: "Supply Account Name",
              value: "qre",
              title: "By Account Name",
            },
          ]}
        />
      ) : null}
      {currentTab.value === "Contracts" ? (
        <ChartContainer
          lineItems={contractData}
          currentTab={currentTab}
          chartProps={[
            {
              label: "Contractor Department",
              value: "qre",
              title: "By Department",
            },
            {
              label: "Contractor Location",
              value: "qre",
              title: "By Location",
            },
            {
              label: "Contractor Name",
              value: "qre",
              title: "By Contractor Name",
            },
          ]}
        />
      ) : null}
      {currentTab.value === "Computer Lease" ? (
        <ChartContainer
          lineItems={computerLeaseData}
          currentTab={currentTab}
          chartProps={[
            {
              label: "Vendor Account Name",
              value: "qre",
              title: "By Department",
            },
            {
              label: "Vendor Location",
              value: "qre",
              title: "By Location",
            },
            {
              label: "Vendor Account Name",
              value: "qre",
              title: "By Vendor Name",
            },
          ]}
        />
      ) : null}
    </div>
  );
};

const ChartContainer = ({
  lineItems,
  chartProps,
  currentTab,
}: {
  lineItems: any;
  chartProps: any;
  currentTab?: any;
}) => {
  const qualifiedCost = lineItems.reduce((acc: any, curr: any) => {
    return acc + curr.qre;
  }, 0);

  const totalCost = lineItems.reduce((acc: any, curr: any) => {
    const costKey = Object.keys(curr).find(
      (key) => key.includes("Cost") || key.includes("W2"),
    );
    return acc + curr[costKey];
  }, 0);

  const unqualifiedCost = totalCost - qualifiedCost;

  const qreChartData = [
    {
      key: "Qualified",
      value: qualifiedCost,
    },
    {
      key: "Nonqualified",
      value: unqualifiedCost,
    },
  ];

  if (lineItems.length === 0)
    return (
      <div className="flex h-72vh items-center justify-center">
        <div className="">
          <div className="p-4">
            {" "}
            There was no {currentTab.label.toLowerCase()} data found. If this is
            incorrect, please contact your SPRX partner.
          </div>
        </div>
      </div>
    );

  return (
    <div className="container mx-auto p-4">
      <div className="grid w-full grid-cols-2 grid-rows-2 gap-4">
        <div className="rounded border p-4 shadow">
          <div className="flex min-w-full items-center justify-center">
            <TreeMapChart
              lineItems={lineItems}
              label={chartProps[0].label}
              value={chartProps[0].value}
              title={chartProps[0].title}
            />
          </div>
        </div>
        <div className="rounded border p-4 shadow">
          <div className="flex min-w-full items-center justify-center">
            <PieChart
              lineItems={lineItems}
              label={chartProps[1].label}
              value={chartProps[1].value}
              title={chartProps[1].title}
            />
          </div>
        </div>
        <div className="rounded border p-4 shadow">
          <div className="flex min-w-full items-center justify-center">
            <BubbleChart
              lineItems={lineItems}
              label={chartProps[2].label}
              value={chartProps[2].value}
              title={chartProps[2].title}
            />
          </div>
        </div>
        <div className="rounded border p-4 shadow">
          <div className="flex min-w-full items-center justify-center">
            <GaugeChart
              lineItems={qreChartData}
              label={"key"}
              value={"value"}
              title={"Qualified vs Nonqualified"}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
