import {
    getCostKey,
    castUploadTypes,
    appendID
} from ".";

const isContractResearch = (type) => type === "Contract Research Intelligent Import";

const hasQualifiedPercentage = (e) => e?.qPercentage && e.qPercentage !== "";

export const formatImportedData = (data, type, filename) => {
    const costKey = getCostKey(type.split(" ")[0]);
    const contractResearchFactor = isContractResearch(type) ? 0.65 : 1;
    return data.map((employee) => {
      const formattedData = castUploadTypes(employee);
      if (hasQualifiedPercentage(employee)) {
        let qre =
            (Number(employee.qPercentage) / 100) *
            Number(employee[costKey]) *
            contractResearchFactor;
        if (Number(employee.qPercentage) >= 80 && !isContractResearch) {
          qre = Number(employee[costKey]);
        }
        const rtrObject = {
          ...formattedData,
          qre,
          userProvided: true,
          filename,
        };
        return appendID(rtrObject, type);
      } else {
        const rtrObject = {
          ...formattedData,
          qre: 0,
          qPercentage: 0,
          userProvided: false,
          filename,
        };
        return appendID(rtrObject, type);
      }
    });
  };