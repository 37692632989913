import { Dialog } from "@headlessui/react";
import { motion, AnimatePresence } from "framer-motion";
import { XIcon } from "@heroicons/react/outline";

export const CreateFormWrapper = (props) => {
  const { open, setOpen, type } = props;
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-40"
          static
          layoutId={"energy-create-engagement-form"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <Dialog.Panel
                className={`relative min-h-40vh max-w-xs transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all ${
                  type === "RD Engagement" ? "min-w-50vw" : "min-w-25vw"
                }`}
              >
                <motion.div className="mt-16 flex h-full w-full flex-col justify-evenly">
                  {/*Modal title, description, and close button */}
                  <motion.div className="absolute top-0 right-0 hidden pt-4 pr-4 sm:block">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <h1 className="w-full text-center text-3xl font-bold">
                    Create {type}
                  </h1>
                  {props.children}
                </motion.div>
              </Dialog.Panel>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
