import { baseAPI } from "./baseAPI";

interface CreateCommentInput {
  engagementID: string;
  companyID: string;
  createdBy: string;
  createdAt: string;
  title: string;
}

interface UpdateCommentInput {
  commentID: string;
  title: string;
}

const commentsAPI = baseAPI.injectEndpoints({
  endpoints: (builder) => ({
    createComment: builder.mutation({
      query: (body: CreateCommentInput) => ({
        url: `/rd/comments`,
        method: "POST",
        body: body,
      }),
    }),
    getComment: builder.query({
      query: (commentID: string) => ({
        url: `/rd/comments/${commentID}`,
        method: "GET",
        body: {},
      }),
      providesTags: (result, error, id) => [{ type: "Comment", id }],
    }),
    updateComment: builder.mutation({
      query: ({ commentID, body }) => ({
        url: `/rd/comments/${commentID}`,
        method: "PUT",
        body: body,
      }),
      invalidatesTags: (result, error, { commentID }) => [
        { type: "Comment", commentID },
      ],
    }),
    deleteComment: builder.mutation({
      query: ({ commentID }) => ({
        url: `/rd/comments/${commentID}`,
        method: "DELETE",
        body: {},
      }),
      invalidatesTags: (result, error, { commentID }) => [
        { type: "Comment", commentID },
      ],
    }),
  }),
  overrideExisting: false,
});

export const {
  useCreateCommentMutation,
  useGetCommentQuery,
  useUpdateCommentMutation,
  useLazyGetCommentQuery,
  useDeleteCommentMutation,
} = commentsAPI;
