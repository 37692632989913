import { DownloadIcon } from "@heroicons/react/outline";

interface IExecutiveReportProps {
  docUrl: string;
  width?: string;
  height?: string;
}

export const DocumentViewer = ({
  docUrl,
  width,
  height,
}: IExecutiveReportProps) => {
  const onDownload = () => window.open(docUrl, "_blank");
  const viewerUrl = `https://view.officeapps.live.com/op/embed.aspx?src=${encodeURIComponent(
    docUrl
  )}`;

  return (
    <div className="flex w-full justify-center items-center p-8">
      <div className="relative" style={{ width: "60%", height: "720px" }}>
        {" "}
        {/* Set the container to the size of the iframe */}
        <iframe
          src={viewerUrl}
          width={width ?? "100%"} /* Set width to 100% of the parent */
          height={height ?? "100%"} /* Set height to 100% of the parent */
          title="docx-viewer"
          className="absolute" /* Position iframe absolutely to cover the parent div */
        />
        <button
          onClick={onDownload}
          className="absolute bottom-0 right-0 mb-4 mr-4 p-2 bg-blue-600 hover:bg-blue-700  text-white rounded-full shadow-lg"
          style={{ zIndex: 10 }} /* Ensure the button is above the iframe */
        >
          <DownloadIcon className="w-6 h-6" />
        </button>
      </div>
    </div>
  );
};
