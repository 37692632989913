import { IntercomProvider } from "react-use-intercom";
import { CookiesProvider } from "react-cookie";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import { Provider } from "react-redux";

import store from "../store";
import SPRXRouter from "./routes/SPRXRouter";

const INTERCOM_APP_ID = process.env.REACT_APP_INTERCOM_KEY;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE);

function App() {
  return (
    <IntercomProvider appId={INTERCOM_APP_ID}>
      <CookiesProvider>
        <Elements stripe={stripePromise}>
          <Provider store={store}>
            <SPRXRouter />
          </Provider>
        </Elements>
      </CookiesProvider>
    </IntercomProvider>
  );
}

export default App;
