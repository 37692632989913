const ConnectIntroductionHeader = (props) => {
  return (
    <div className="mb-4">
      <div className="flex flex-row items-center justify-center">
        <h3 className="mr-2 text-gray-900 text-4xl font-medium truncate text-center">
          {"Welcome to the"}
        </h3>
        <img
          style={{ width: 100, height: 66 }}
          src={"assets/images/logos/Logo@SVG.svg"}
          alt="SPRX.tax"
        />
        <h3 className="ml-2 text-gray-900 text-4xl font-medium truncate text-center">
          {"Data Connect Workflow"}
        </h3>
      </div>

      <p className="mb-2 text-md text-center text-gray-500">
        {
          "Please enter the password you recieved in your SPRX.tax Data Connect Workflow request."
        }
      </p>
    </div>
  );
};

export default ConnectIntroductionHeader;
