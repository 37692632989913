import { motion, AnimatePresence } from "framer-motion";
import { Dialog } from "@headlessui/react";
import { XIcon } from "@heroicons/react/outline";

import { Button } from "../../ui-components";

interface ConfirmationModelProps {
  open: boolean;
  setOpen: (open: boolean) => void;
  onConfirm: () => Promise<void>;
  onReject: () => void;
  title: string;
  description: string;
  confirmButtonText: string;
  rejectButtonText: string;
}

export const ConfirmationModal = ({
  open,
  setOpen,
  onConfirm,
  onReject,
  title,
  description,
  confirmButtonText,
  rejectButtonText,
}: ConfirmationModelProps) => {
  return (
    <AnimatePresence>
      {open && (
        <Dialog
          className="relative z-200"
          static
          layoutId={"1234"}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          as={motion.div}
          open={open}
          onClose={setOpen}
        >
          <motion.div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          <motion.div className="fixed inset-0 z-10 overflow-y-auto">
            <motion.div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              {/*Modal content */}
              <Dialog.Panel className="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 text-left shadow-xl transition-all min-h-30vh min-w-30vw">
                <motion.div>
                  {/*Modal title, description, and close button */}
                  <motion.div className="hidden sm:block absolute top-0 right-0 pt-4 pr-4">
                    <motion.button
                      type="button"
                      whileHover={{ scale: 1.05 }}
                      whileTap={{ scale: 0.95 }}
                      className="bg-white rounded-md text-gray-400 hover:text-gray-500 focus:outline-none"
                      onClick={() => setOpen(false)}
                    >
                      <XIcon className="h-6 w-6" aria-hidden={false} />
                    </motion.button>
                  </motion.div>
                  <motion.div className="text-center sm:mt-5">
                    <Dialog.Title
                      as="h3"
                      className="text-3xl font-medium leading-6 text-gray-900 text-left p-4"
                    >
                      {title}
                    </Dialog.Title>
                    <motion.div className="flex flex-col items-center justify-evenly">
                      <motion.p className="p-8">{description}</motion.p>
                      <motion.div className="w-full flex flex-row justify-evenly items-evenly">
                        <Button primary={true} onClick={() => onReject()}>
                          {rejectButtonText}
                        </Button>
                        <Button
                          secondary={true}
                          className="pl-4"
                          onClick={() => onConfirm()}
                        >
                          {confirmButtonText}
                        </Button>
                      </motion.div>
                    </motion.div>
                  </motion.div>
                </motion.div>
              </Dialog.Panel>
            </motion.div>
          </motion.div>
        </Dialog>
      )}
    </AnimatePresence>
  );
};
