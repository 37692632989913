export const NumericInputTable = (props) => {
  const { options, handleInputChange, values, disabled } = props;
  return (
   <div className="grid grid-cols-5 gap-4 mb-4">
      {options.map((option, index) => (
        <div
          key={index}
          className="bg-gray-800 rounded-lg p-4 flex flex-col items-center justify-center text-center"
        >
          <span className="text-sm whitespace-normal">{option}</span>
          <input
            type="number"
            className="bg-gray-700 text-white p-1 w-16 mt-2 rounded-md text-center focus:outline-none focus:ring-2 focus:ring-blue-300"
            value={values.hasOwnProperty(option) ? values[option] : 0}
            onChange={(e) => handleInputChange(option, e.target.value)}
            disabled={disabled}
          />
        </div>
      ))}
    </div>
  );
};