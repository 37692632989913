const ConnectComplete = () => {
  return (
    <div className={"flex flex-col items-center justify-center h-full"}>
      <div className="w-full">
        <div className="mb-4">
          <div className="flex flex-row items-center justify-center">
            <h6 className="mr-2 text-gray-900 text-2xl font-medium truncate text-center">
              {"Thanks for completing the"}
            </h6>
            <img
              style={{ width: 60, height: 60 }}
              src={"assets/images/logos/Logo@SVG.svg"}
              alt="SPRX.tax"
            />
            <h6 className="ml-2 text-gray-900 text-2xl font-medium truncate text-center">
              {"Data Connect Workflow"}
            </h6>
          </div>

          <p className="mb-2 text-md text-center text-gray-500">
            {"Please contact your team if this survey needs to be resubmitted."}
          </p>
        </div>
      </div>
    </div>
  );
};
export default ConnectComplete;
