export const castUploadTypes = (item) => {
  const numericLookup = [
    "Employee W2 Wages",
    "Supply Cost",
    "Contractor Cost",
    "Vendor Cost",
    "Project Cost",
  ];
  let formattedItem = {};
  Object.keys(item).forEach((key) => {
    if (numericLookup.indexOf(key) > -1 && typeof item[key] === "string") {
      let value = item[key].replace("$", "").replace(/,/g, "");
      formattedItem[key] = parseFloat(value);
    } else {
      formattedItem[key] = item[key];
    }
  });
  return formattedItem;
};
