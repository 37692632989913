import { useCompanyData } from "../../../rtk/CompanyData/useCompanyData";
import { motion } from "framer-motion";
import { useState } from "react";
import { AdminEngagementListLoading } from "../components/AdminEngagementListLoading";
import { CreateCompanyForm } from "./CreateCompanyForm";
import { isDev } from "../../R&D/utils";
import { CreateCompanyModel } from "../types/company";
import { EditCompanyForm } from "./EditCompanyForm";
import { CreateUserForm } from "../Users/CreateUserForm";
import { useUsers } from "../../../rtk/Users/useUsers";
import { useEngagementData } from "../../../rtk/R&D/EngagementData/useEngagementData";
export const CompaniesTable = () => {
  const { companies, isLoadingCompanies, createCompany, isCreatingCompany } =
    useCompanyData();
  const { user, createUser, isCreatingUser } = useUsers();

  const { engagementList } = useEngagementData();

  const [filter, setFilter] = useState<string>("");
  const [open, setOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editID, setEditID] = useState<number | null>(null);
  const [createUserOpen, setCreateUserOpen] = useState(false);

  const onCreateUser = async (body) => {
    const createUserRespone = await createUser({ body });
    if (isDev) console.log({ createUserRespone });
    // users.push();
    setCreateUserOpen(false);
  };

  const onCreate = async (model: CreateCompanyModel) => {
    const body = {
      ...model,
      engagements: [],
    };
    const createCompanyResponse = await createCompany(body);
    if (isDev) console.log({ createCompanyResponse });
    setOpen(false);
  };
  if (isLoadingCompanies || !companies) return <AdminEngagementListLoading />;
  return (
    <div className="flex-1">
      <div className="">
        {/* Admin engagement table */}
        <div className="mt-2 px-4 sm:px-6 lg:px-8">
          <div className="items-between flex flex-row items-end justify-between">
            <div className="mt-4 flex flex-col sm:mt-0">
              <p className={"mb-2 text-sm font-bold"}>Search Company</p>
              <motion.input
                type="text"
                className="rounded-md border border-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium shadow-sm focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                onChange={(e) => {
                  setFilter(e.target.value);
                }}
                value={filter}
              />
            </div>
            <div className="mt-4 sm:mt-0 sm:ml-16 sm:flex-none">
              <motion.button
                type="button"
                className="inline-flex items-center justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0 sm:w-auto"
                whileHover={{ scale: 1.1 }}
                whileTap={{ scale: 0.95 }}
                onClick={() => setOpen(true)}
              >
                Create Company
              </motion.button>
            </div>
          </div>
          <div className="mt-8 flow-root">
            <div className="-my-2 -mx-4 overflow-x-auto sm:-mx-6 lg:-mx-8">
              <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                <div className="max-h-128 overflow-y-auto shadow ring-1 ring-black ring-opacity-5 sm:rounded-lg">
                  <table className="min-w-full divide-y divide-gray-300">
                    <thead className="sticky top-0 bg-gray-50">
                      <tr>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Name
                        </th>

                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        >
                          Email Domain
                        </th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        ></th>
                        <th
                          scope="col"
                          className="hidden px-3 py-3.5 text-left text-sm font-semibold text-gray-900 sm:table-cell"
                        ></th>
                      </tr>
                    </thead>
                    <tbody className="h-16 divide-y divide-gray-200 bg-white">
                      {companies
                        ?.map((a) => ({ ...a }))
                        ?.sort((a, b) => b?.createdAt - a?.createdAt)
                        ?.filter((a) => {
                          const k = filter.toLowerCase();
                          return (
                            a?.companyName?.toLowerCase()?.includes(k) || false
                          );
                        })
                        ?.map((co) => (
                          <tr key={co.companyID}>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {co.companyName}
                            </td>
                            <td className="whitespace-nowrap px-3 py-4 text-sm text-gray-500">
                              {co.emailDomain}
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-green-400"
                                onClick={() => {
                                  setEditID(co.companyID);
                                  setCreateUserOpen(true);
                                }}
                              >
                                Add User
                              </motion.button>
                            </td>
                            <td className="whitespace-nowrap py-4 pl-3 pr-4 text-right text-sm font-medium sm:pr-6">
                              <motion.button
                                whileHover={{ scale: 1.1 }}
                                whileTap={{ scale: 0.95 }}
                                className="text-sprxClientPortalDarkBlue hover:text-green-400"
                                onClick={() => {
                                  setEditID(co.companyID);
                                  setEditOpen(true);
                                }}
                              >
                                Edit
                              </motion.button>
                            </td>
                          </tr>
                        ))}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <CreateUserForm
            isCreatingUser={isCreatingUser}
            user={user}
            open={createUserOpen}
            setOpen={setCreateUserOpen}
            onCreate={onCreateUser}
            engagements={engagementList}
            companies={companies}
            company={companies?.find((c) => c.companyID === editID)}
            isAdmin={true}
          />
          <CreateCompanyForm
            isCreatingCompany={isCreatingCompany}
            open={open}
            setOpen={setOpen}
            onCreate={onCreate}
          />
          <EditCompanyForm
            open={editOpen}
            setOpen={setEditOpen}
            company={companies?.find((c) => c.companyID === editID)}
          />
        </div>
      </div>
    </div>
  );
};
