export const ReviewGaugeColumnLoading = () => {
  return (
    <div className="col-span-1 flex flex-col w-full">
      <div
        className={`flex flex-col w-full overflow-y-scroll rounded-lg border shadow-lg min-h-70h max-h-70h`}
      >
        <div className="w-full flex flex-1 flex-col justify-start bg-white p-6">
          <h1 className="text-xl text-center font-semibold text-gray-900 ">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/2"></div>
          </h1>

          <div className="relative mt-4">
            <div
              className="absolute inset-0 flex items-center"
              aria-hidden="true"
            >
              <div className="w-full border-t border-sprxClientPortalDarkBlue" />
            </div>
          </div>

          <div className="mt-4 flex flex-row justify-between items-center">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
          </div>
          <div className="mt-4 flex flex-row justify-between items-center">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
          </div>
          <div className="mt-4 flex flex-row justify-between items-center">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
          </div>
          <SmallDivider />
          <div className="mt-4 flex flex-row justify-between items-center">
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
            <div className="animate-pulse mt-2 mb-2 h-2 bg-sprxGrey rounded w-1/3"></div>
          </div>
        </div>
      </div>
    </div>
  );
};

export const SmallDivider = () => (
  <div className="relative w-full my-4 z-1">
    <div
      className="inset-0 flex items-center justify-center"
      aria-hidden="true"
    >
      <div className="w-1/2 border-t border-sprxClientPortalDarkBlue" />
    </div>
  </div>
);
