import * as React from "react";
import { CheckCircleIcon } from "@heroicons/react/solid";
import classNames from "classnames";
import { DefaultPhoto } from "../../../../Profile/DefaultPhoto";

type ActivityItemType = any;

interface ActivityItemProps {
  activityItem: ActivityItemType;
}

const Photo = () => {
  return (
    <span className="z-10 relative mt-8 h-6 w-6 flex-none rounded-full bg-gray-100 ">
      <DefaultPhoto />
    </span>
  );
};

const SprxPhoto = () => {
  return (
    <span className="z-10 relative mt-8 h-6 w-6 flex-none rounded-full bg-gray-100 ">
      <img
        className="absolute h-full w-full rounded-full object-cover"
        src="https://images.unsplash.com/photo-1612835925427-5c1f3b7e6d1f?ixid=MnwxMjA3fDB8MHxzZWFyY2h8Mnx8c3ByeCUyMHRheHxlbnwwfHwwfHw%3D&ixlib=rb-1.2.1&w=1000&q=80"
        alt=""
      />
    </span>
  );
};

export function formatTimestamp(timestamp: any) {
  const date = new Date(timestamp);
  const options: Intl.DateTimeFormatOptions = {
    month: "short", // 'short', 'numeric', '2-digit', 'long', or 'narrow'
    day: "numeric", // 'numeric' or '2-digit'
    hour: "numeric", // 'numeric' or '2-digit'
    minute: "numeric", // 'numeric' or '2-digit'
    hour12: true,
  };
  return new Intl.DateTimeFormat("en-US", options).format(date);
}

export const ActivityItem = ({ activityItem }: ActivityItemProps) => {
  return (
    <div className="relative flex gap-x-4 ">
      <div
        className={classNames(
          activityItem.isLast ? "h-6" : "-bottom-6",
          "absolute left-0 top-0 flex w-6 justify-center"
        )}
      >
        <div className="w-px bg-gray-200" />
      </div>
      {activityItem.type === "commented" ? (
        <CommentItem activityItem={activityItem} />
      ) : (
        <NonCommentItem activityItem={activityItem} />
      )}
    </div>
  );
};

const CommentItem = ({ activityItem }: { activityItem: ActivityItemType }) => {
  return (
    <>
      <Photo />
      <div className="flex-auto min-w-0">
        <div className="rounded-md p-3 ring-1 ring-inset ring-gray-200 my-2">
          <div className="flex justify-between gap-x-4">
            <div className="py-0.5 text-xs leading-5 text-gray-500 truncate">
              <span className="font-medium text-gray-900">
                {activityItem.person.name}
              </span>{" "}
              commented
            </div>
            <time
              dateTime={activityItem.dateTime}
              className="flex-none py-0.5 text-xs leading-5 text-gray-500"
            >
              {formatTimestamp(activityItem.date)}
            </time>
          </div>
          <div className="overflow-hidden">
            <p className="text-sm leading-6 text-gray-500 whitespace-pre-line">
              {activityItem.comment}
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

const NonCommentItem = ({
  activityItem,
}: {
  activityItem: ActivityItemType;
}) => {
  return (
    <>
      <div className="relative flex h-6 w-6 flex-none items-center justify-center bg-white">
        {activityItem.type === "resolved" ? (
          <CheckCircleIcon
            className="z-10 h-6 w-6 text-indigo-600"
            aria-hidden="true"
          />
        ) : (
          <div className="h-1.5 w-1.5 rounded-full bg-gray-100 ring-1 ring-gray-300" />
        )}
      </div>
      <p className="flex-auto py-0.5 text-xs leading-5 text-gray-500 pr-8 pb-4">
        <span className="font-medium text-gray-900">
          {activityItem.person.name}
        </span>{" "}
        {activityItem.type} the comment thread about the{" "}
        <span className="font-medium text-gray-900">{activityItem.title}.</span>{" "}
      </p>
      <time
        dateTime={activityItem.dateTime}
        className="flex-none py-0.5 text-xs leading-5 text-gray-500 pr-2"
      >
        {formatTimestamp(activityItem.date)}
      </time>
    </>
  );
};
