import { useEffect, useState, useRef } from "react";
import { motion } from "framer-motion";
import { getDefaultProfileFormFields, getProfileFormShape } from "./utils";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { isDev, performClick } from "../R&D/utils";
import { DefaultPhoto } from "./DefaultPhoto";
import { useAuth } from "../../auth/useAuth";
import { useUsers } from "../../rtk/Users/useUsers";
import { useFile } from "../../rtk/R&D/File/useFile";
import { useEngagementData } from "../../rtk/R&D/EngagementData/useEngagementData";
import { setUserData } from "../../auth/rtk/userSlice";
import { useDispatch } from "react-redux";
import { UpdateProfileModel } from "../AdminHome/types/user";

export const ProfileForm = () => {
  const inputFileRef = useRef();
  const dispatch = useDispatch();
  const [authedImage, setAuthedImage] = useState<string>("");

  const { onLogout } = useAuth();

  const { engagementData, isLoadingEngagementData } = useEngagementData();

  const {
    user,
    isLoadingUser,
    getTaxUser,
    isGettingTaxUser,
    updateTaxUser,
    isUpdatingUser,
    inGodMode,
    setGodMode,
  } = useUsers();

  const { getSignedURL, isGettingSignedURL, uploadFile } = useFile();

  const bucket = engagementData?.s3Bucket?.name;
  const engagementID = engagementData?.engagementID;

  const { control, formState, handleSubmit } = useForm({
    mode: "onSubmit",
    defaultValues: getDefaultProfileFormFields(user),
    resolver: yupResolver(getProfileFormShape()),
  });
  const { isValid, errors } = formState;

  const onPhotoUpload = async (event) => {
    const file = event.target.files[0];
    let body: any = {
      engagementID,
      bucket,
      fileName: file.name,
      key: `photos/${user.userID}-${file.name}`,
      action: "putObject",
      file: file,
      type: file.type,
    };
    const uploadFileResponse = await uploadFile(body);
    if (isDev) console.log({ uploadFileResponse });

    const { userID } = user;
    body = {
      photoUrl: `photos/${user.userID}-${file.name}`,
    };
    const updateUserResponse = await updateTaxUser({ userID, body });
    if (isDev) console.log({ updateUserResponse });

    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  const onSubmit = async (model: UpdateProfileModel) => {
    const { userID } = user;
    const body = {
      fullName: [model.firstName, model.lastName].join(" "),
      email: model.email,
    };
    if (isDev) console.log({ body });

    const updateUserResponse = await updateTaxUser({ userID, body });
    if (isDev) console.log({ updateUserResponse });

    const updatedUser = await getTaxUser(user.userID);
    dispatch(setUserData(updatedUser.data));
  };

  useEffect(() => {
    async function getURL() {
      const body = {
        bucket,
        key: user?.photoUrl ?? "",
        expires: 86400,
        action: "getObject",
      };
      const signedURLResponse = await getSignedURL(body);
      return signedURLResponse;
    }
    if (user?.photoUrl !== undefined) {
      getURL().then((response) => {
        if (response?.data?.error || response?.error) return;
        setAuthedImage(response.data.data.signedRequest);
      });
    }
  }, [bucket, getSignedURL, user.photoUrl]);

  const loading =
    isLoadingUser ||
    isGettingTaxUser ||
    isUpdatingUser ||
    isGettingSignedURL ||
    isLoadingEngagementData;

  return (
    <main className="flex-1 lg:overflow-hidden">
      <div className="py-6">
        {/*Top bar and logo */}
        <div className="flex flex-row items-center justify-between mx-auto px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">Your Profile</h1>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
        {/* Profile card */}
        <div className="mx-auto px-4 sm:px-6 md:px-8 mt-8">
          <form
            className="space-y-6"
            onSubmit={handleSubmit((model) => onSubmit(model))}
          >
            <div className="bg-white px-4 py-5 shadow sm:rounded-lg sm:p-6">
              <div className="md:grid md:grid-cols-3 md:gap-6">
                <div className="md:col-span-1">
                  <h3 className="text-lg font-medium leading-6 text-gray-900">
                    Profile Information
                  </h3>
                  <p className="mt-1 text-sm text-gray-500">
                    Update your profile information here.
                  </p>
                </div>
                <div className="mt-5 md:col-span-2 md:mt-0">
                  <div className="grid grid-cols-6 gap-6">
                    <Controller
                      name="firstName"
                      control={control}
                      render={({ field }) => (
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="firstName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            First name
                          </label>
                          {loading ? (
                            <div className="w-full mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4">
                              <p className="animate-pulse bg-sprxGrey rounded h-2 mt-1 w-1/3"></p>
                            </div>
                          ) : (
                            <input
                              {...field}
                              type="text"
                              name="firstName"
                              id="firstName"
                              autoComplete="given-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          )}
                          {errors.hasOwnProperty("firstName") ? (
                            <p className=" pl-2 text-sprxOrange">
                              Invalid first name
                            </p>
                          ) : null}
                        </div>
                      )}
                    />
                    <Controller
                      name="lastName"
                      control={control}
                      render={({ field }) => (
                        <div className="col-span-6 sm:col-span-3">
                          <label
                            htmlFor="lastName"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Last name
                          </label>
                          {loading ? (
                            <div className="w-full mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4">
                              <p className="animate-pulse bg-sprxGrey rounded h-2 mt-1 w-1/3"></p>
                            </div>
                          ) : (
                            <input
                              {...field}
                              type="text"
                              name="lastName"
                              id="lastName"
                              autoComplete="family-name"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          )}
                          {errors.hasOwnProperty("lastName") && (
                            <p className=" pl-2 text-sprxOrange">
                              Invalid last name
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <Controller
                      name="email"
                      control={control}
                      render={({ field }) => (
                        <div className="col-span-6 sm:col-span-6">
                          <label
                            htmlFor="email"
                            className="block text-sm font-medium text-gray-700"
                          >
                            Email address
                          </label>
                          {loading ? (
                            <div className="w-full mt-1 block w-full rounded-md border-gray-500 shadow-md focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm p-4">
                              <p className="animate-pulse bg-sprxGrey rounded h-2 mt-1 w-1/3"></p>
                            </div>
                          ) : (
                            <input
                              {...field}
                              type="text"
                              name="email"
                              id="email"
                              autoComplete="email"
                              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-500 focus:ring-indigo-500 sm:text-sm"
                            />
                          )}
                          {errors.hasOwnProperty("email") && (
                            <p className=" pl-2 text-sprxOrange">
                              Invalid email
                            </p>
                          )}
                        </div>
                      )}
                    />
                    <div className="col-span-6 sm:col-span-6">
                      <label className="block text-sm font-medium text-gray-700">
                        Profile Photo
                      </label>
                      <div className="mt-1 flex items-center space-x-5">
                        <span className="inline-block h-12 w-12 overflow-hidden rounded-full bg-gray-100">
                          {user?.photoUrl &&
                          authedImage.length > 0 &&
                          !isGettingSignedURL ? (
                            <img alt="uploaded-profile" src={authedImage} />
                          ) : (
                            <DefaultPhoto />
                          )}
                        </span>
                        <button
                          onClick={() => performClick("photo-upload")}
                          className="rounded-md border border-gray-300 bg-white py-2 px-3 text-sm font-medium leading-4 text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-0 focus:ring-none focus:ring-offset-0"
                        >
                          Change
                        </button>
                        <input
                          ref={inputFileRef}
                          hidden={true}
                          type="file"
                          id="photo-upload"
                          accept=".png,.img,.jpg"
                          name="file"
                          onChange={(e) => onPhotoUpload(e)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="flex justify-end mt-8">
                <motion.button
                  type="submit"
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  disabled={!isValid}
                >
                  Save
                </motion.button>
                <motion.button
                  className="ml-3 inline-flex justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue py-2 px-4 text-sm font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none focus:ring-0 focus:ring-transparent focus:ring-offset-0"
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  onClick={() => onLogout(user)}
                >
                  Logout
                </motion.button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </main>
  );
};
