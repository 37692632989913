import { useEffect, useMemo } from "react";
import { motion } from "framer-motion";
import { Controller, useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import {
  getDefaultFormFields,
  getTaxpayerDetailFormShape,
  formatFormModelForSubmission,
} from "./utils";
import { TaxpayerProfileLoading } from "./components";
import { useEngagementData } from "../../../../rtk/R&D/EngagementData/useEngagementData";
import { useCompute } from "../../../../rtk/R&D/Compute/useCompute";
import { isDev } from "../../utils";
import { NumericFormat } from "react-number-format";

export const TaxpayerProfile = (props) => {
  const recomputeCredit = props?.recomputeCredit
    ? props.recomputeCredit
    : false;

  const {
    engagementData,
    isLoadingEngagementData,
    updateEngagementData,
    isUpdatingEngagementData,
  } = useEngagementData();

  const {
    computeTaxRDCredit,
    isComputingFederalCredit,
    computeTaxStateCredits,
    isComputingStateCredit,
  } = useCompute();

  const schema = getTaxpayerDetailFormShape();
  const { control, formState, handleSubmit, watch } = useForm({
    mode: "onSubmit",
    defaultValues: useMemo(() => {
      return getDefaultFormFields(engagementData);
    }, [engagementData]),
    resolver: yupResolver(schema),
  });

  const { isValid, dirtyFields, errors } = formState;

  if (!isValid) console.error("Form Validation Errors", errors);

  useEffect(() => {
    const subscription = watch((value, { name, type }) => {});
    return () => subscription.unsubscribe();
  }, [watch]);

  const watchMethod = watch("method");

  const onSubmit = async (model, dirtyFields) => {
    const body = formatFormModelForSubmission(
      model,
      engagementData,
      dirtyFields,
    );
    const updateTaxpayerProfileResponse = await updateEngagementData({
      engagementID: engagementData.engagementID,
      body: body,
    });
    if (isDev) console.log({ updateTaxpayerProfileResponse });

    if (recomputeCredit) {
      const computeFedCreditResponse = await computeTaxRDCredit();
      if (isDev) console.log({ computeFedCreditResponse });

      const computeStateCreditResponse = await computeTaxStateCredits();
      if (isDev) console.log({ computeStateCreditResponse });
    }

    props.onClose();
  };

  if (
    isLoadingEngagementData ||
    isUpdatingEngagementData ||
    isComputingFederalCredit ||
    isComputingStateCredit
  ) {
    return <TaxpayerProfileLoading />;
  }

  return (
    <form
      data-test="taxpayer-form"
      className=""
      onSubmit={handleSubmit((model) => onSubmit(model, dirtyFields))}
    >
      <div className="m-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-12">
        <Controller
          name="entityName"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-3" data-test="entity-name-div">
              <div className="flex flex-row">
                <label
                  htmlFor="entityName"
                  className="block text-sm font-medium text-gray-700"
                >
                  Entity Name
                </label>
                <p className="-mt-1 text-sprxClientPortalRed">*</p>
              </div>
              <div className="mt-1">
                <motion.input
                  {...field}
                  id="entityName"
                  data-test="entityName"
                  name="entityName"
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.entityName && (
                  <p className="pl-2 text-sprxOrange">Invalid entity name</p>
                )}
              </div>
            </div>
          )}
        />

        <Controller
          name="entityEIN"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-3">
              <div className="flex flex-row">
                <label
                  htmlFor="entityEIN"
                  className="block text-sm font-medium text-gray-700"
                >
                  Entity EIN
                </label>
                <p className="-mt-1 text-sprxClientPortalRed">*</p>
              </div>

              <div className="mt-1">
                <motion.input
                  {...field}
                  data-test="entityEIN"
                  id="entityEIN"
                  name="entityEIN"
                  type="text"
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.entityEIN && (
                  <p className="pl-2 text-sprxOrange">Invalid entity EIN</p>
                )}
              </div>
            </div>
          )}
        />

        <Controller
          name="entityType"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-3">
              <div className="flex flex-row">
                <label
                  htmlFor="entityType"
                  className="block text-sm font-medium text-gray-700"
                >
                  Entity Type
                </label>
                <p className="-mt-1 text-sprxClientPortalRed">*</p>
              </div>

              <div className="mt-1">
                <motion.select
                  {...field}
                  id="entityType"
                  name="entityType"
                  className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                  whileHover={{ scale: 1.01 }}
                >
                  <option></option>
                  <option value={"ccorp"}>C Corporation</option>
                  <option value={"scorp"}>S Corporation</option>
                  <option value={"partnership"}>Partnership</option>
                  <option value={"estate"}>Estate</option>
                  <option value={"trust"}>Trust</option>
                  <option value={"other"}>Other</option>
                </motion.select>
              </div>
              {errors?.entityType && (
                <p className="pl-2 text-sprxOrange">Invalid entity type</p>
              )}
            </div>
          )}
        />

        <Controller
          name="naicsCode"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-3">
              <div className="flex flex-row">
                <label
                  htmlFor="naicsCode"
                  className="block text-sm font-medium text-gray-700"
                >
                  NAICS Code
                </label>
                <p className="-mt-1 text-sprxClientPortalRed">*</p>
              </div>

              <div className="mt-1">
                <motion.input
                  {...field}
                  id="naicsCode"
                  name="naicsCode"
                  type="text"
                  className={
                    "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                  }
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.naicsCode && (
                  <p className="pl-2 text-sprxOrange">Invalid NAICS code</p>
                )}
              </div>
            </div>
          )}
        />

        <div className="sm:col-span-6">
          <Controller
            name="method"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-6">
                <label
                  htmlFor="method"
                  className="block text-sm font-medium text-gray-700"
                >
                  Computation Method
                </label>
                <div className="mt-1">
                  <motion.select
                    {...field}
                    id="method"
                    name="method"
                    className="mt-1 block w-full rounded-md border-gray-300 py-2 pl-3 pr-10 text-base focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    whileHover={{ scale: 1.01 }}
                  >
                    <option></option>
                    <option value={"regular"}>Regular Method</option>
                    <option value={"asc"}>ASC Method</option>
                    <option value={"both"}>Both</option>
                  </motion.select>
                </div>
                {errors?.method && (
                  <p className="pl-2 text-sprxOrange">
                    Invalid computation method
                  </p>
                )}
              </div>
            )}
          />

          {watchMethod === "asc" ? (
            <div className="mt-2 flex flex-row justify-between">
              <Controller
                name="ascPY1"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="ascPY1"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 1 QRE
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="ascPY1"
                          name="ascPY1"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.ascPY1 && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid ASC prior year
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }}
              />
              <Controller
                name="ascPY2"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="ascPY2"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 2 QRE
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="ascPY2"
                        name="ascPY2"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />
                      {errors?.ascPY2 && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid ASC prior year
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="ascPY3"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-2">
                    <label
                      htmlFor="ascPY3"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 3 QRE
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="ascPY3"
                        name="ascPY3"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />

                      {errors?.ascPY3 && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid ASC prior year
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          ) : null}

          {watchMethod === "regular" ? (
            <div className="mt-2 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
              <Controller
                name="FBPercentage"
                control={control}
                render={({ field }) => {
                  return (
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="FBPercentage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fixed Base Percentage
                      </label>
                      <div className="mt-1">
                        <motion.input
                          {...field}
                          id="FBPercentage"
                          name="FBPercentage"
                          type="number"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.FBPercentage && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid fixed base percentage
                          </p>
                        )}
                      </div>
                    </div>
                  );
                }}
              />
              <Controller
                name="py1GR"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="py1GR"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 1 Gross Receipts
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="py1GR"
                        name="py1GR"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />

                      {errors?.py1GR && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid gross receipts
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="py2GR"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="py2GR"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 2 Gross Receipts
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="py2GR"
                        name="py2GR"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />

                      {errors?.py2GR && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid gross receipts
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="py3GR"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="py3GR"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 3 Gross Receipts
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="py3GR"
                        name="py3GR"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />
                      {errors?.py3GR && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid gross receipts
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
              <Controller
                name="py4GR"
                control={control}
                render={({ field }) => (
                  <div className="sm:col-span-1">
                    <label
                      htmlFor="py4GR"
                      className="block text-sm font-medium text-gray-700"
                    >
                      Prior Year 4 Gross Receipts
                    </label>
                    <div className="mt-1">
                      <NumericFormat
                        {...field}
                        prefix="$"
                        thousandSeparator
                        id="py4GR"
                        name="py4GR"
                        className={
                          "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                        }
                        whileHover={{ scale: 1.01 }}
                      />
                      {errors?.py4GR && (
                        <p className="pl-2 text-sprxOrange">
                          Invalid gross receipts
                        </p>
                      )}
                    </div>
                  </div>
                )}
              />
            </div>
          ) : null}

          {watchMethod === "both" ? (
            <div>
              <p className="text-md mt-2 block border-b pb-2 text-center font-medium text-gray-700">
                Regular Credit Information
              </p>

              <div className="mt-8 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-4">
                <Controller
                  name="FBPercentage"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-4">
                      <label
                        htmlFor="FBPercentage"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Fixed Base Percentage
                      </label>
                      <div className="mt-1">
                        <motion.input
                          {...field}
                          id="FBPercentage"
                          name="FBPercentage"
                          type="number"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.FBPercentage && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid fixed base percentage
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="py1GR"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="py1GR"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 1 Gross Receipts
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="py1GR"
                          name="py1GR"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.py1GR && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid gross receipts
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="py2GR"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="py2GR"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 2 Gross Receipts
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="py2GR"
                          name="py2GR"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.py2GR && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid gross receipts
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="py3GR"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="py3GR"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 3 Gross Receipts
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="py3GR"
                          name="py3GR"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.py3GR && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid gross receipts
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
                <Controller
                  name="py4GR"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-1">
                      <label
                        htmlFor="py4GR"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 4 Gross Receipts
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="py4GR"
                          name="py4GR"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />
                        {errors?.py4GR && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid gross receipts
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
              <p className="text-md mt-8 block border-b pb-2 text-center font-medium text-gray-700">
                ASC Information
              </p>
              <div className="mt-4 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-6">
                <Controller
                  name="ascPY1"
                  control={control}
                  render={({ field }) => {
                    return (
                      <div className="sm:col-span-2">
                        <label
                          htmlFor="ascPY1"
                          className="block text-sm font-medium text-gray-700"
                        >
                          Prior Year 1 QRE
                        </label>
                        <div className="mt-1">
                          <NumericFormat
                            {...field}
                            prefix="$"
                            thousandSeparator
                            id="ascPY1"
                            name="ascPY1"
                            className={
                              "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                            }
                            whileHover={{ scale: 1.01 }}
                          />

                          {errors?.ascPY1 && (
                            <p className="pl-2 text-sprxOrange">
                              Invalid research expense
                            </p>
                          )}
                        </div>
                      </div>
                    );
                  }}
                />

                <Controller
                  name="ascPY2"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="ascPY2"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 2 QRE
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="ascPY2"
                          name="ascPY2"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />

                        {errors?.ascPY2 && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid research expense
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />

                <Controller
                  name="ascPY3"
                  control={control}
                  render={({ field }) => (
                    <div className="sm:col-span-2">
                      <label
                        htmlFor="ascPY3"
                        className="block text-sm font-medium text-gray-700"
                      >
                        Prior Year 3 QRE
                      </label>
                      <div className="mt-1">
                        <NumericFormat
                          {...field}
                          prefix="$"
                          thousandSeparator
                          id="ascPY3"
                          name="ascPY3"
                          className={
                            "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                          }
                          whileHover={{ scale: 1.01 }}
                        />

                        {errors?.ascPY3 && (
                          <p className="pl-2 text-sprxOrange">
                            Invalid research expense
                          </p>
                        )}
                      </div>
                    </div>
                  )}
                />
              </div>
            </div>
          ) : null}
        </div>

        <Controller
          name="280CElection"
          control={control}
          render={({ field }) => (
            <div className="border-r border-gray-300 pr-1 sm:col-span-2">
              <div className="relative mt-6 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="280CElection"
                    name="280CElection"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="280CElection"
                    className="font-medium text-gray-700"
                  >
                    280C Election
                  </label>
                  <p className="text-gray-500">
                    Is the client electing the reduced credit under section
                    280C?
                  </p>
                </div>
              </div>
              {errors.hasOwnProperty("280CElection") && (
                <p className="pl-7 text-sprxOrange">Invalid 280C election</p>
              )}
            </div>
          )}
        />

        <Controller
          name="QSB"
          control={control}
          render={({ field }) => {
            return (
              <div className="border-r border-gray-300 pr-1 sm:col-span-2">
                <div className="relative mt-6 flex items-start">
                  <div className="flex h-5 items-center">
                    <motion.input
                      {...field}
                      value={field.value}
                      checked={field.value}
                      id="QSB"
                      name="QSB"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                      whileHover={{ scale: 1.05 }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label htmlFor="QSB" className="font-medium text-gray-700">
                      Qualified Small Busniness
                    </label>
                    <p className="text-gray-500">
                      Is the client a Qualified Small Business?
                    </p>
                    {errors?.QSB && (
                      <p className="pl-2 text-sprxOrange">
                        Invalid QSB selection
                      </p>
                    )}
                  </div>
                </div>
              </div>
            );
          }}
        />

        <Controller
          name="controlledGroup"
          control={control}
          render={({ field }) => (
            <div className="pr-1 sm:col-span-2">
              <div className="relative mt-6 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="controlledGroup"
                    name="controlledGroup"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="controlledGroup"
                    className="font-medium text-gray-700"
                  >
                    Controlled Group
                  </label>
                  <p className="text-gray-500">
                    Is the taxpayer part of a controlled group?
                  </p>
                  {errors?.brBase && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid controlled group selection
                    </p>
                  )}
                  {/* 
                    Ask: Why did we get rid of this?

                    const watchCG = watch("controlledGroupFiling");
                    
                    {field.value === true ? (
                      <div className="mt-2">
                        <Controller
                          name="controlledGroupFiling"
                          control={control}
                          render={({ field }) => (
                            <div className="sm:col-span-2 pr-4">
                              <label
                                htmlFor="controlledGroupFiling"
                                className="block text-sm font-medium text-gray-700"
                              >
                                Please select how the entity will be filing the
                                R&D Credit.
                              </label>
                              <div className="mt-1">
                                <motion.select
                                  {...field}
                                  id="controlledGroupFiling"
                                  defaultValue="none"
                                  name="controlledGroupFiling"
                                  className="shadow-sm block w-full sm:text-sm focus:border-gray-400 border-gray-300 rounded-md focus:outline-none focus:ring-0 focus:ring-transparent"
                                  whileHover={{ scale: 1.01 }}
                                >
                                  <option value="none" disabled>
                                    Select an Option
                                  </option>
                                  <option value={"cgAllocation"}>
                                    Allocated to individual members
                                  </option>
                                  <option value={"indivdually"}>
                                    All members on a consolidated return
                                  </option>
                                </motion.select>
                                {watchCG === "cgAllocation" ? (
                                  <p className="block mt-2 text-sm font-light text-gray-700">
                                    Please proceed to the Advanced tab to
                                    allocate the credit to the controlled group.
                                  </p>
                                ) : null}
                              </div>
                            </div>
                          )}
                        />
                      </div>
                    ) : null} */}
                  {errors?.controlledGroupFiling && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid controlled group allocation selection
                    </p>
                  )}
                </div>
              </div>
            </div>
          )}
        />

        <Controller
          name="businessDescription"
          control={control}
          render={({ field }) => (
            <div className="sm:col-span-6">
              <label
                htmlFor="businessDescription"
                className="block text-sm font-medium text-gray-700"
              >
                Business Description
              </label>
              <div className="mt-1">
                <motion.textarea
                  {...field}
                  id="businessDescription"
                  name="businessDescription"
                  type="text"
                  placeholder="Please enter a short description of the taxpayer's business activities."
                  className="block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                  whileHover={{ scale: 1.01 }}
                />
                {errors?.businessDescription && (
                  <p className="pl-2 text-sprxOrange">
                    Invalid business description
                  </p>
                )}
              </div>
            </div>
          )}
        />

        <Controller
          name="BRP"
          control={control}
          render={({ field }) => (
            <div className="border-r border-gray-300 pr-2 sm:col-span-3">
              <div className="relative mt-6 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="BRP"
                    name="BRP"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="BRP" className="font-medium text-gray-700">
                    Basic Research Payments
                  </label>
                  <p className="text-gray-500">
                    Does the client make Qualified Basic Research Payments?
                  </p>
                  {errors?.BRP && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid research payments
                    </p>
                  )}
                  {field.value === true ? (
                    <div className="mt-2">
                      <Controller
                        name="brPMT"
                        control={control}
                        render={({ field }) => (
                          <div className="pr-4 sm:col-span-3">
                            <label
                              htmlFor="brPMT"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current Year Basic Research Payment Amount
                            </label>
                            <div className="mt-1">
                              <NumericFormat
                                {...field}
                                prefix="$"
                                thousandSeparator
                                id="brPMT"
                                name="brPMT"
                                className={
                                  "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                                }
                                whileHover={{ scale: 1.01 }}
                              />
                              {errors?.brPMT && (
                                <p className="pl-2 text-sprxOrange">
                                  Invalid current year research payments
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      />
                      <Controller
                        name="brBase"
                        control={control}
                        render={({ field }) => (
                          <div className="mt-2 pr-4 sm:col-span-3">
                            <label
                              htmlFor="brBase"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Basic Research Base Amount
                            </label>
                            <div className="mt-1">
                              <NumericFormat
                                {...field}
                                prefix="$"
                                thousandSeparator
                                id="brBase"
                                name="brBase"
                                className={
                                  "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                                }
                                whileHover={{ scale: 1.01 }}
                              />
                              {errors?.brBase && (
                                <p className="pl-2 text-sprxOrange">
                                  Invalid base year research payments
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        />

        <Controller
          name="ECP"
          control={control}
          render={({ field }) => (
            <div className="border-gray-300 pr-2 sm:col-span-3">
              <div className="relative mt-5 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="ECP"
                    name="ECP"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label htmlFor="ECP" className="font-medium text-gray-700">
                    Energy Consortia Payments
                  </label>
                  <p className="text-gray-500">
                    Does the client make payments to a Qualified Energy
                    Consortia?
                  </p>
                  {errors?.ECP && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid consortia selection
                    </p>
                  )}
                  {field.value === true ? (
                    <div className="mt-2">
                      <Controller
                        name="ecPMT"
                        control={control}
                        render={({ field }) => (
                          <div className="pr-4 sm:col-span-3">
                            <label
                              htmlFor="ecPMT"
                              className="block text-sm font-medium text-gray-700"
                            >
                              Current year Energy Consortia Payment
                            </label>
                            <div className="mt-1">
                              <NumericFormat
                                {...field}
                                prefix="$"
                                thousandSeparator
                                id="ecPMT"
                                name="ecPMT"
                                className={
                                  "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                                }
                                whileHover={{ scale: 1.01 }}
                              />
                              {errors?.ecPMT && (
                                <p className="pl-2 text-sprxOrange">
                                  Invalid consortia payments
                                </p>
                              )}
                            </div>
                          </div>
                        )}
                      />
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        />

        <Controller
          name="acquisition"
          control={control}
          render={({ field }) => {
            return (
              <div className="border-r border-gray-300 sm:col-span-4">
                <div className="relative mt-2 flex items-start">
                  <div className="flex h-5 items-center">
                    <motion.input
                      {...field}
                      value={field.value}
                      checked={field.value}
                      id="acquisition"
                      name="acquisition"
                      type="checkbox"
                      className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                      whileHover={{ scale: 1.05 }}
                    />
                  </div>
                  <div className="ml-3 text-sm">
                    <label
                      htmlFor="acquisition"
                      className="font-medium text-gray-700"
                    >
                      Acquisition
                    </label>
                    <p className="text-gray-500">
                      Did the taxpayer undergo an acquisition?
                    </p>
                    {errors?.acquisition && (
                      <p className="pl-2 text-sprxOrange">
                        Invalid acquisition selection
                      </p>
                    )}
                    {field.value === true ? (
                      <p className="mt-2 block text-sm font-light text-gray-700">
                        Please be sure to account for any acquisition
                        adjustments in the Computation Method information above.
                      </p>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          }}
        />

        <Controller
          name="disposition"
          control={control}
          render={({ field }) => (
            <div className="border-r border-gray-300 sm:col-span-4">
              <div className="relative mt-2 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="disposition"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="disposition"
                    className="font-medium text-gray-700"
                  >
                    Disposition
                  </label>
                  <p className="text-gray-500">
                    Did the taxpayer undergo a disposition?
                  </p>
                  {errors?.disposition && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid disposition selection
                    </p>
                  )}
                  {field.value === true ? (
                    <p className="mt-2 block text-sm font-light text-gray-700">
                      Please be sure to account for any disposition adjustments
                      in the Computation Method information above.
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        />

        <Controller
          name="shortYear"
          control={control}
          render={({ field }) => (
            <div className="border-gray-300 sm:col-span-4">
              <div className="relative mt-2 flex items-start">
                <div className="flex h-5 items-center">
                  <motion.input
                    {...field}
                    value={field.value}
                    checked={field.value}
                    id="shortYear"
                    name="shortYear"
                    type="checkbox"
                    className="h-4 w-4 rounded border-gray-300 text-sprxTaxBlue focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent"
                    whileHover={{ scale: 1.05 }}
                  />
                </div>
                <div className="ml-3 text-sm">
                  <label
                    htmlFor="shortYear"
                    className="font-medium text-gray-700"
                  >
                    Short Year
                  </label>
                  <p className="text-gray-500">
                    Does the taxpayer have a short year?
                  </p>
                  {errors?.shortYear && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid short year selection
                    </p>
                  )}
                  {field.value === true ? (
                    <p className="mt-2 block text-sm font-light text-gray-700">
                      Please be sure to account for any short year adjustments
                      in the Computation Method information above.
                    </p>
                  ) : null}
                </div>
              </div>
            </div>
          )}
        />

        <div className="flex flex-row space-x-12 sm:col-span-12">
          <Controller
            name="line8932"
            control={control}
            render={({ field }) => {
              return (
                <div className="sm:col-span-4">
                  <label
                    htmlFor="line8932"
                    className="block text-sm font-medium text-gray-700"
                  >
                    Portion of the credit from Form 8932, line 2, that is
                    attributable to wages that were also used to figure the
                    credit
                  </label>
                  <div className="mt-1">
                    <NumericFormat
                      {...field}
                      prefix="$"
                      thousandSeparator
                      id="line8932"
                      name="line8932"
                      className={
                        "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                      }
                      whileHover={{ scale: 1.01 }}
                    />

                    {errors?.line8932 && (
                      <p className="pl-2 text-sprxOrange">
                        Invalid line 8932 motion.input
                      </p>
                    )}
                  </div>
                </div>
              );
            }}
          />

          <Controller
            name="creditK1"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-4">
                <label
                  htmlFor="creditK1"
                  className="block text-sm font-medium text-gray-700"
                >
                  Credit for increasing research activities from Partnerships, S
                  corporations, Estates, and Trusts.
                </label>
                <div className="mt-1">
                  <NumericFormat
                    {...field}
                    prefix="$"
                    thousandSeparator
                    id="creditK1"
                    name="creditK1"
                    className={
                      "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    }
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.creditK1 && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid credit K1 selection
                    </p>
                  )}
                </div>
              </div>
            )}
          />

          <Controller
            name="beneficiariesCredit"
            control={control}
            render={({ field }) => (
              <div className="sm:col-span-4">
                <label
                  htmlFor="beneficiariesCredit"
                  className="block text-sm font-medium text-gray-700"
                >
                  Credit amount allocated to beneficiaries of an estate or
                  trust.
                </label>
                <div className="mt-1">
                  <NumericFormat
                    {...field}
                    prefix="$"
                    thousandSeparator
                    id="beneficiariesCredit"
                    name="beneficiariesCredit"
                    className={
                      "block w-full rounded-md border-gray-300 shadow-sm focus:border-gray-400 focus:outline-none focus:ring-0 focus:ring-transparent sm:text-sm"
                    }
                    whileHover={{ scale: 1.01 }}
                  />
                  {errors?.beneficiariesCredit && (
                    <p className="pl-2 text-sprxOrange">
                      Invalid credit beneficiaries motion.input
                    </p>
                  )}
                </div>
              </div>
            )}
          />
        </div>
      </div>
      <motion.div className="mb-4 flex w-full flex-row justify-end">
        <motion.button
          type="submit"
          data-test="Save & Close"
          whileHover={{ scale: 1.05 }}
          whileTap={{ scale: 0.95 }}
          className="focus:ring-none inline-flex w-1/5 justify-center rounded-md border border-transparent bg-sprxClientPortalLightBlue px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-sprxClientPortalDarkBlue focus:outline-none sm:text-sm"
        >
          Save & Close
        </motion.button>
      </motion.div>
    </form>
  );
};
