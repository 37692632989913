import { useEffect } from "react";
import {
  useDownloadDocumentMutation,
  useLazyListDocumentsLibraryQuery,
  useDeleteFileMutation,
} from "../../../../api/fileAPI";
import { isDev } from "../../utils";
import { DocumentLibraryTable, DocumentLibraryLoading } from "./components";
import { useUpdateEngagementDataMutation } from "../../../../api/engagmentAPI";
import { useDataArray } from "../../../../rtk/R&D/DataArray/useDataArray";

export const DocumentLibrary = (props) => {
  const { engagementData, user } = props;

  const activeEngagementID = user?.data?.activeEngagementID;

  const dataAPI = useDataArray();

  const [downloadDocument] = useDownloadDocumentMutation();

  const [deleteFile] = useDeleteFileMutation();

  const [
    listDocumentsLibrary,
    { data: documentList, isLoading: isListingDocuments },
  ] = useLazyListDocumentsLibraryQuery();

  const [updateEngagementData] = useUpdateEngagementDataMutation();

  // List Documents
  useEffect(() => {
    const body = {
      format: true,
      bucket: engagementData?.s3Bucket?.name,
      key: `${activeEngagementID}/DocumentLibrary`,
    };
    listDocumentsLibrary({ body });
  }, [
    activeEngagementID,
    engagementData?.s3Bucket?.name,
    listDocumentsLibrary,
  ]);

  const onDownloadDocument = async (item) => {
    const bucket = engagementData?.s3Bucket?.name;
    const key = item.key;
    const body = { bucket, key, action: "getObject" };
    const response = await downloadDocument({ ...body });
    const link = document.createElement("a");
    link.href = response?.data?.url ?? "";
    link.download = key; // This sets the default file name for the download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const getData = (type) => {
    switch (type) {
      case "Supply":
        return dataAPI.supplyData;
      case "Contract Research":
        return dataAPI.contractData;
      case "Computer Lease":
        return dataAPI.computerLeaseData;
      default:
        return [];
    }
  };

  const onDeleteDocument = async (item) => {
    let status = {};
    Object.assign(status, engagementData.status);
    const bucket = engagementData?.s3Bucket?.name;
    const { fileName } = item;

    // Delete Directory File
    const deleteBody = { bucket, key: item.key, action: "getObject" };
    await deleteFile({ body: deleteBody });

    // Remove Pertinent QRE Items if Applicable
    const qreDocuments = [
      "Supply",
      "Projects",
      "Computer Lease",
      "Contract Research",
      "Wages",
    ];
    if (qreDocuments.indexOf(item.type) > -1) {
      const existingData = getData(item.type);
      const filteredData = existingData.filter((a) => a.filename !== fileName);
      await dataAPI.updateData(filteredData, item.type);

      if (isAccounting(item.type)) {
        if (
          dataAPI.supplyData === 0 &&
          dataAPI.computerLeaseData === 0 &&
          dataAPI.contractData === 0
        ) {
          status[`portal-accounting`] = {
            complete: false,
          };
        }
      } else {
        if (filteredData.length === 0) {
          status[`portal-${item.type.toLowerCase()}`] = {
            complete: false,
          };
        }
      }

      const updateResponse = await updateEngagementData({
        engagementID: activeEngagementID,
        body: {
          status,
        },
      });
      if (isDev) console.log({ updateResponse });
    }

    // Documentation
    if (item.type === "Documentation") {
      const { documents } = engagementData.documentation;
      const filteredDocs = documents?.filter((a) => !a.key.includes(fileName));
      if (filteredDocs.length === 0) {
        status["portal-documentation"] = {
          complete: false,
        };
      }
      const documentationUpdateResponse = await updateEngagementData({
        engagementID: activeEngagementID,
        body: {
          documentation: filteredDocs,
          status,
        },
      });
      if (isDev) console.log({ documentationUpdateResponse });
    }
  };

  if (isListingDocuments) return <DocumentLibraryLoading />;

  return (
    <DocumentLibraryTable
      documents={documentList?.contents ? documentList.contents : []}
      onDownload={onDownloadDocument}
      onDelete={onDeleteDocument}
    />
  );
};

function isAccounting(type) {
  return (
    type === "Contract Research" ||
    type === "Computer Lease" ||
    type === "Supply"
  );
}
