//import { useCallback, useEffect, useState } from "react";
import { save } from "save-file";
import {
  useGenerateBulkEditFileMutation,
  useLazyListDocumentsLibraryQuery,
  useUploadFileMutation,
  useUploadDirectoryFileMutation,
  useDownloadDocumentMutation,
  useDeleteFileMutation,
  useGetSignedURLMutation,
  useGeneratePartialReportMutation,
  useGeneratePartialEnergyReportMutation,
} from "../../../api/fileAPI";
import { useGenerateDeliverablesMutation } from "../../../api/engagmentAPI";
import { useEngagementData } from "../EngagementData/useEngagementData";
import FileContext from "./FileContext";

const FileProvider = ({ children }) => {
  const { engagementData, isLoadingEngagementData } = useEngagementData();

  const [
    getSignedURL,
    { isLoading: isGettingSignedURL, error: getSignedURLError },
  ] = useGetSignedURLMutation();

  const [
    getBulkEditFile,
    { isLoading: isGettingBulkEditFile, error: getBulkEditFileError },
  ] = useGenerateBulkEditFileMutation();

  const [deleteFile, { isLoading: isDeletingFile, error: deleteFileError }] =
    useDeleteFileMutation();

  const [generateDeliverables, { isLoading: isGenratingDeleriverables }] =
    useGenerateDeliverablesMutation();

  const [
    downloadDocument,
    { isLoading: isDownloadingDocument, error: downloadDocumentError },
  ] = useDownloadDocumentMutation();

  const [
    listDocumentsLibrary,
    {
      data: documentList,
      isLoading: isListingDocuments,
      error: listDocumentsError,
    },
  ] = useLazyListDocumentsLibraryQuery();

  const [
    uploadDirectoryFile,
    { isLoading: isUploadingDirectoryFile, error: uploadDirectoryFileError },
  ] = useUploadDirectoryFileMutation();

  const [uploadFile, { isLoading: isUploadingFile, error: uploadFileError }] =
    useUploadFileMutation();

  const [
    generatePartialReport,
    { isLoading: isGeneratingPartialReport, error: generatePartialReportError },
  ] = useGeneratePartialReportMutation();

  const [
    generatePartialEnergyReport,
    {
      isLoading: isGeneratingPartialEnergyReport,
      error: generatePartialEnergyReportError,
    },
  ] = useGeneratePartialEnergyReportMutation();

  const onDownloadDocument = async (fileName) => {
    const res = await getBulkEditFile({
      engagementID: engagementData.engagementID,
      body: {
        templateName: fileName,
      },
    });
    console.log({ res });
    // Download Large Files Directly from s3
    if (res.data.hasOwnProperty("key")) {
      const key = res.data.key;
      const body = {
        bucket: engagementData?.s3Bucket?.name,
        key: key,
        action: "getObject",
      };
      const signedURL = await downloadDocument({ ...body });
      window.open(signedURL.data.url, "_blank");

      // Download File via Response Body
    } else {
      await save(res?.data?.file?.data, fileName);
    }
  };

  let errors = {};
  if (getSignedURLError) errors.getSignedURLError = getSignedURLError;
  if (getBulkEditFileError) errors.getBulkEditFileError = getBulkEditFileError;
  if (listDocumentsError) errors.listDocumentsError = listDocumentsError;
  if (uploadFileError) errors.uploadFileError = uploadFileError;
  if (uploadDirectoryFileError)
    errors.uploadDirectoryFileError = uploadDirectoryFileError;
  if (downloadDocumentError)
    errors.downloadDocumentError = downloadDocumentError;
  if (deleteFileError) errors.deleteFileError = deleteFileError;
  if (generatePartialReportError)
    errors.generatePartialReportError = generatePartialReportError;
  if (generatePartialEnergyReportError)
    errors.generatePartialEnergyReportError = generatePartialEnergyReportError;
  Object.keys(errors).length > 0 && console.log({ FileProvider: errors });

  const value = {
    // Data
    documentList,
    // Functions
    uploadFile,
    listDocumentsLibrary,
    onDownloadDocument,
    getSignedURL,
    uploadDirectoryFile,
    downloadDocument,
    deleteFile,
    generatePartialReport,
    generatePartialEnergyReport,
    generateDeliverables,
    // Loading States
    isListingDocuments,
    isUploadingFile,
    isGettingBulkEditFile,
    isDownloadingDocument,
    isUploadingDirectoryFile,
    isGettingSignedURL,
    isDeletingFile,
    isGeneratingPartialReport,
    isGeneratingPartialEnergyReport,
    isGenratingDeleriverables,

    errors,
    loading: isLoadingEngagementData || isGettingBulkEditFile,
  };

  return <FileContext.Provider value={value}>{children}</FileContext.Provider>;
};
export default FileProvider;
