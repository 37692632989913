import * as yup from "yup";

export const getEditDocumentFormShape = () => {
  return yup.object().shape({
    key: yup.string(),
    project: yup.array().of(
      yup.object().shape({
        value: yup.string().required("Value is required"),
        label: yup.string().required("Label is required"),
      })
    ),
    description: yup.string(),
    purpose: yup.string(),
    experimentation: yup.string(),
    uncertainty: yup.string(),
    nature: yup.string(),
    excerpt: yup.string(),
  });
};
