import { useState } from "react";
import { classNames } from "../R&D/utils";
import { AdminEngagementList } from "./RD/AdminEngagementList";
import { CompaniesTable } from "./Companies/CompaniesTable";
import { DataSpacesTable } from "./DataSpace/DataSpacesTable";
import { UsersTable } from "./Users/UsersTable";

import EnergyEngagementProvider from "../../rtk/Energy/Engagement/EnergyEngagementProvider";
import { EnergyEngagementListContainer } from "./Energy/EngagementListContainer";
const TABS = ["R&D", "Energy", "Users", "Companies", "Data Spaces"];

export const AdminHome = () => {
  const [currentTab, setCurrentTab] = useState<string>("R&D");
  return (
    <main className="flex-1">
      <div className="pt-6">
        {/*Top bar and logo */}
        <div className="flex flex-row items-center justify-between px-4 sm:px-6 md:px-8">
          <h1 className="text-2xl font-semibold text-gray-900">
            Admin Home | {currentTab}
          </h1>
          <img
            style={{ width: 85, height: 36 }}
            src={"assets/images/logos/Logo@SVG.svg"}
            alt="SPRX.tax"
          />
        </div>
      </div>
      <div className="flex flex-row justify-evenly w-full">
        <div className=" w-1/2">
          <div className="sm:hidden">
            <label htmlFor="tabs" className="sr-only">
              Select a tab
            </label>
            <select
              id="tabs"
              name="tabs"
              className="block w-full rounded-md border-gray-300 focus:border-indigo-500 focus:ring-indigo-500"
              defaultValue={currentTab}
            >
              {TABS.map((tab) => (
                <option key={tab}>{tab}</option>
              ))}
            </select>
          </div>
          <div className="hidden sm:block">
            <div className="border-b border-gray-200">
              <nav className="-mb-px flex" aria-label="Tabs">
                {TABS.map((tab) => (
                  <div
                    onClick={() => setCurrentTab(tab)}
                    key={tab}
                    className={classNames(
                      tab === currentTab
                        ? "border-indigo-500 text-indigo-600"
                        : "border-transparent text-gray-500 hover:border-gray-300 hover:text-gray-700",
                      "w-1/4 border-b-2 py-4 px-1 text-center text-sm font-medium"
                    )}
                  >
                    {tab}
                  </div>
                ))}
              </nav>
            </div>
          </div>
        </div>
      </div>
      {currentTab === "R&D" ? <AdminEngagementList /> : null}
      {currentTab === "Users" ? <UsersTable /> : null}

      {currentTab === "Companies" ? <CompaniesTable /> : null}
      {currentTab === "Data Spaces" ? <DataSpacesTable /> : null}
      {currentTab === "Energy" ? (
        <EnergyEngagementProvider>
          <EnergyEngagementListContainer />
        </EnergyEngagementProvider>
      ) : null}
    </main>
  );
};
